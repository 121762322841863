import React from 'react';
import DescriptionList from '../DescriptionList';
import { DefinitionItem } from '../DescriptionList/DescriptionListItem';

interface Props {
  list: DefinitionItem[];
  className?: string;
  classNameDT?: string;
  classNameDD?: string;
}

/**
 * Smalle Tile helper function to display a list of label and definition.
 */
function TileDescriptionList({ list, className, classNameDD, classNameDT }: Props): JSX.Element {
  return (
    <DescriptionList
      // we should reset the px as inside a tile there is already a padding
      classNameListItem='!px-0'
      classNameDD={classNameDD}
      classNameDT={classNameDT}
      className={className}
      list={list}
    />
  );
}

export default TileDescriptionList;

import { ApiErrorType } from 'api/ApiErrorParser';
import { TFunction } from 'i18next';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiPromiseError, ApiPromiseErrors, ApiPromises } from 'utilities/ApiPromises';

interface ReturnType {
  loadInternal: (apiPromises: ApiPromises) => void;
  loadingState: boolean;
  setLoadingState: Dispatch<SetStateAction<boolean>>;
  apiPromisesError: ApiPromiseErrors | undefined;
  errorInfo: ErrorInfo | undefined;
  setErrorInfo: Dispatch<SetStateAction<ErrorInfo | undefined>>;
  doSilentReload: boolean;
  canShowLoader: boolean;
}

interface ErrorInfo {
  errorType: ApiErrorType;
  httpStatusCode?: number;
  title: string;
  description: string;
  isFatal: boolean;
}

const errorTitle = (t: TFunction, errorType: ApiErrorType): string => {
  switch (errorType) {
    case ApiErrorType.ApplicationError:
      return t('error', 'Error');
    case ApiErrorType.AuthorizationError:
      return t('authorization-error', 'Authorization error');
    case ApiErrorType.CancelError:
      return t('cancellation-error', 'Request aborted');
    case ApiErrorType.NetworkError:
      return t('network-error', 'Network error');
  }
};

const errorInfoFromApiError = (t: TFunction, error: ApiPromiseError, isFatal: boolean): ErrorInfo => {
  let description = t('unknown-error-occurred', 'An unknown error occurred');
  if (error.error.errorType === ApiErrorType.NetworkError) {
    description = t('network-error-desc', 'Please check your internet connection.');
  } else {
    if (error.error.nonFieldErrorsStrings().length > 0) {
      description = error.error.nonFieldErrorsStrings().join(' ');
    }
  }
  return {
    errorType: error.error.errorType,
    title: errorTitle(t, error.error.errorType),
    description,
    isFatal,
  };
};

/**
 * Hook that will load the ApiPromises and set the loading state.
 */
const useLoadApiPromises = (): ReturnType => {
  const [loadingState, setLoadingState] = useState<boolean>(true);
  const [apiPromisesError, setApiPromisesError] = useState<ApiPromiseErrors | undefined>();
  const [errorInfo, setErrorInfo] = useState<ErrorInfo | undefined>();

  // When we already have an ApiPromises set, then the incoming ApiPromises is
  // a API reload. This doesn't require a full page loader but we can reload
  // silently.
  // doSilentReload will be set once to true, and never return back.
  const [doSilentReload, setDoSilentReload] = useState<boolean>(false);

  const { t } = useTranslation();

  // Fetch data for all api promises. We first wait on the essential non cached
  // promises. Then we can set the loading state to false. The user then can already
  // use the page while the cached data is being refresh.
  const loadInternal = useCallback(
    (apiPromises: ApiPromises) => {
      let errorIsSet = false;
      setLoadingState(true);
      // Fetch the non-cached data (full page loader).
      apiPromises
        .watchAll(true)
        .then(() => setErrorInfo(undefined))
        .catch(e => {
          const apiException = e as ApiPromiseErrors;
          if (apiException.errors.length) {
            // Get the most important error. (Errors are sorted).
            const mostImportantError = apiException.errors[0];
            setErrorInfo(errorInfoFromApiError(t, mostImportantError, true));
            errorIsSet = true;
          } else {
            console.error('ApiPromisesError thrown but it has no errors in it', e);
          }
        })
        .finally(() => {
          // Fetch the cached data while the user can already view the page.
          setLoadingState(false);
          setDoSilentReload(true);
          apiPromises
            .watchAll()
            .then(() => setApiPromisesError(undefined))
            .catch(e => {
              const apiException = e as ApiPromiseErrors;
              const mostImportantError = apiException.errors[0];
              if (!errorIsSet) {
                setErrorInfo(errorInfoFromApiError(t, mostImportantError, false));
              }
              setApiPromisesError(apiException);
            });
        });
    },
    [t],
  );

  return {
    loadInternal,
    loadingState,
    setLoadingState,
    apiPromisesError,
    errorInfo,
    setErrorInfo,
    doSilentReload,
    canShowLoader: loadingState && !doSilentReload,
  };
};

export default useLoadApiPromises;

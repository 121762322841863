// Returns the content type based on a file extension for image formats.
export const getImageContentType = (fileName: string): string => {
  const extension = fileName.split('.').pop() ?? '';
  switch (extension) {
    case 'jpeg':
    case 'jpg':
      return 'image/jpeg';
    case 'gif':
      return 'image/gif';
    case 'png':
      return 'image/png';
    case 'svg':
      return 'image/svg+xml';
    case 'tif':
    case 'tiff':
      return 'image/tiff';
    default:
      return 'image/*';
  }
};

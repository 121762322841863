import { Contact, HorseDetail, HorseLocation } from 'openapi';
import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

/**
 * This context is intended only for our Horse detail page (with all the tiles)
 */

interface Props {
  horseLocations?: HorseLocation[];
  horse?: HorseDetail;
  contacts?: Contact[];
  children: React.ReactNode;
}

type HorseDetailContextType = {
  horseLocations: Props['horseLocations'];
  setHorseLocations: Dispatch<SetStateAction<Props['horseLocations']>>;

  horse: Props['horse'];
  setHorse: Dispatch<SetStateAction<Props['horse']>>;

  contacts: Props['contacts'];
  setContacts: Dispatch<SetStateAction<Props['contacts']>>;
};

const HorseDetailContext = createContext<HorseDetailContextType>({
  horseLocations: undefined,
  setHorseLocations: () => console.warn('no HorseDetail provider'),

  horse: undefined,
  setHorse: () => console.warn('no HorseDetail provider'),

  contacts: undefined,
  setContacts: () => console.warn('no HorseDetail provider'),
});

export function useHorseDetailContext(): HorseDetailContextType {
  return useContext(HorseDetailContext);
}

export function HorseDetailProvider({ children }: Props): JSX.Element {
  const [horseLocations, setHorseLocations] = useState<Props['horseLocations']>();
  const [horse, setHorse] = useState<Props['horse']>();
  const [contacts, setContacts] = useState<Props['contacts']>();

  return (
    <HorseDetailContext.Provider value={{ horseLocations, setHorseLocations, horse, setHorse, contacts, setContacts }}>
      {children}
    </HorseDetailContext.Provider>
  );
}

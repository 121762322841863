import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { Role, RolesService } from 'openapi';
import { ResolveConfigOptions } from 'prettier';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onDeleted: (role: Role) => void;
  role: Role | undefined;
}

function DeleteRoleModal({ isVisible, onRequestCloseModal, role, onDeleted }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorParser<ResolveConfigOptions> | undefined>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const remove = async () => {
    if (!selectedOrganization || !role) return;
    setSubmitting(true);

    try {
      const promise = RolesService.rolesDestroy({
        organisationUid: selectedOrganization.uid,
        uid: role.uid,
      });
      await promise;

      // close the modal
      onRequestCloseModal();

      // fire the onDeleted callback
      onDeleted(role);
    } catch (error) {
      setApiError(new ApiErrorParser<Role>(error));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ActionModal
      open={isVisible}
      onClose={onRequestCloseModal}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: onRequestCloseModal,
        },
        {
          text: t('delete', 'Delete'),
          loading: submitting,
          variant: ButtonVariant.PrimaryDanger,
          onClick: remove,
        },
      ]}
      title={t('delete-role', 'Delete role')}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2  w-full'>{t('delete-role-desc', 'Are you sure you want to remove this role?')}</p>
    </ActionModal>
  );
}

export default DeleteRoleModal;

import Pedigree from 'components/Pedigree';
import { PageAction } from 'context/PageContext';
import useHorseDetail from 'hooks/UseHorseDetail';
import usePermissions from 'hooks/UsePermissions';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import { ModulePermissionsEnum } from 'openapi';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Tile } from 'ui/Layout/Tile';

interface Props {
  pedigreePageUrl: string;
}

function PedigreeTile({ pedigreePageUrl }: Props): JSX.Element {
  const { horse } = useHorseDetail();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { width } = useScreenSize();
  const { hasPermission } = usePermissions();

  // Define the action "Setup" when there is no pedigree defined
  const actions = useMemo((): PageAction[] | undefined => {
    if (!horse || !hasPermission(ModulePermissionsEnum.MANAGE_HORSES)) return;

    return [
      {
        text: t('view', 'View'),
        onClick: () => navigate(pedigreePageUrl),
      },
    ];
  }, [horse, hasPermission, t, navigate, pedigreePageUrl]);

  return (
    <Tile title={t('pedigree', 'Pedigree')} loading={horse === undefined} actions={actions}>
      {horse && (
        <Pedigree
          showLevels={width < ScreenSize['2xl'] ? 1 : 2}
          horse={horse}
          onClick={() => navigate(pedigreePageUrl)}
          entryClassName='font-light'
        />
      )}
    </Tile>
  );
}

export default PedigreeTile;

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `1` - black
 * * `2` - brown
 * * `3` - baybrown
 * * `4` - darkbrown
 * * `5` - chestnut
 * * `6` - grey
 * * `7` - strawberry
 * * `8` - piebald
 * * `9` - skewbald
 * * `10` - dun
 * * `11` - cream
 * * `12` - palomino
 * * `13` - appaloosa
 */
export enum ColorEnum {
  '_1' = 1,
  '_2' = 2,
  '_3' = 3,
  '_4' = 4,
  '_5' = 5,
  '_6' = 6,
  '_7' = 7,
  '_8' = 8,
  '_9' = 9,
  '_10' = 10,
  '_11' = 11,
  '_12' = 12,
  '_13' = 13,
}

import { zodResolver } from '@hookform/resolvers/zod';
import useFormError from 'api/hooks/useFormError';
import { InvoiceDetail, Payment, PaymentsService, PaymentStatusEnum, PaymentTypeEnum } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { RadioButtonGroupOption } from 'ui/Inputs/RadioGroupInput';
import { transformEmptyToUndefined } from 'utilities/zod';
import { useOrganization } from 'context/OrganizationContext';
import { ButtonVariant } from 'ui/Button';
import { DateInput, SelectInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { PaymentMethodToString } from './Helpers';
import ApiErrorParser from 'api/ApiErrorParser';

interface Props {
  invoice: InvoiceDetail;
  open: boolean;
  requestClose: (reload: boolean) => void;
}

// Return date of today in YYYY-MM-DD format.
const today = (): string => {
  return new Date().toISOString().substr(0, 10);
};

// Dialog for marking an invoice as paid. This means creating a new Payment linked
// to the invoice.
export default function MarkAsPaidModal({ invoice, open, requestClose }: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const schema = useMemo(() => {
    return schemas.Payment.pick({ type: true, date: true }).required({ type: true, date: true });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<Payment>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: { date: today() },
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const onSubmit = async (data: Payment) => {
    if (!selectedOrganization) {
      console.error('Cannot load invoice without a selected organization');
      return;
    }

    try {
      setApiError(undefined);
      await PaymentsService.paymentsCreate({
        organisationUid: selectedOrganization.uid,
        requestBody: {
          ...data,
          invoice: invoice.uid,
          amount: invoice.total_incl_vat,
          status: PaymentStatusEnum.PAID,
        },
      });
      requestClose(true);
    } catch (error) {
      setApiError(new ApiErrorParser<Payment>(error));
    }
  };

  const paymentMethodOptions = useMemo((): RadioButtonGroupOption[] => {
    const options: RadioButtonGroupOption[] = [];
    Object.values(PaymentTypeEnum).forEach(key => {
      switch (key) {
        case PaymentTypeEnum.BANK:
        case PaymentTypeEnum.CASH:
        case PaymentTypeEnum.CREDIT_CARD:
        case PaymentTypeEnum.PIN:
          options.push({ id: key, name: PaymentMethodToString(t, key) });
          break;
      }
    });
    return options;
  }, [t]);

  return (
    <PageModal open={open} width={PageModalWidth.Xs}>
      <PageModalTitle title={t('mark-invoice-as-paid', 'Mark invoice as paid')} onClose={() => requestClose(false)} />
      <PageModalContent>
        <ErrorSection errors={nonFieldErrors} />
        <form className='flex flex-col gap-4' noValidate={true} id='MarkAsPaidForm' onSubmit={handleSubmit(onSubmit)}>
          <SelectInput
            required={true}
            label={t('payment-method', 'Payment method')}
            options={paymentMethodOptions}
            error={fieldError('type')}
            nullableValue=''
            {...register('type', { setValueAs: transformEmptyToUndefined() })}
          />
          <DateInput control={control} required={true} label={t('payment-date', 'Payment date')} name='date' error={fieldError('date')} />
        </form>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            variant: ButtonVariant.Primary,
            text: t('mark-as-paid', 'Mark as paid'),
            type: 'submit',
            formId: 'MarkAsPaidForm',
            loading: isSubmitting,
          },
        ]}
      />
    </PageModal>
  );
}

import { ZodNullable, ZodObject, ZodOptional, ZodRawShape } from 'zod';

// small helper type that can make a generic required
type NoInfer<T> = [T][T extends unknown ? 0 : never];

// @todo Remove '----' once all yum validation is gone.
type EmptyValue = null | undefined | '' | '----';

// Use this function to transform the default empty value of an input field into
// another type. This is required for our sod validators.
// Use as follows:
//
// {...register('country', { setValueAs: transformEmpty(undefined) })}
//
// This will transform '' into undefined.
//eslint-disable-next-line
export const transformEmpty = (emptyValue: EmptyValue): any => {
  return transformValue('', emptyValue);
};

// Overload function to transform `''` to `undefined`.
//eslint-disable-next-line
export const transformEmptyToUndefined = (): any => {
  return transformValue('', undefined);
};

/**
 * Transform an empty value to the given EmptyValue type and parse the value to a Number
 *
 * @param emptyValue
 * @returns
 */
export const transformEmptyNumber = (emptyValue: EmptyValue) => {
  return (val: unknown): unknown => {
    // if the value is equal to the given emptyValue we return it.
    if (val === emptyValue) return val;

    return val === '' ? emptyValue : Number(val);
  };
};

/**
 * Transform a value (of any type or value) to the given EmptyValue type.
 */
export const transformValue = (exceptedVal: unknown, emptyValue: EmptyValue) => {
  return (val: unknown): unknown => (val === exceptedVal ? emptyValue : val);
};

/**
 * Check if the input is required (NOT optional or nullable)
 *
 * We used the noInfer type to make the generic required
 *
 * TODO find a way to better infer the type of the field
 * e.g. const zodInputIsRequired = <T extends ZodObject<ZodRawShape>>(schema: T, field: keyof z.infer<typeof schema>)
 */
export const zodInputIsRequired = <T extends object>(schema: ZodObject<ZodRawShape>, field: keyof NoInfer<T>): boolean => {
  // schema.shape should be indexed with a string field key.
  // we are sure that this is a string, but for typescript we need to add this extra check
  if (typeof field === 'string') {
    return !(schema.shape[field] === undefined || schema.shape[field] instanceof ZodOptional || schema.shape[field] instanceof ZodNullable);
  }

  return false;
};

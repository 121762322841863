import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { HorseGroup, HorsegroupsService, HorseOwner } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  onDeleted: () => void;
  group: HorseGroup | undefined;
}

function DeleteGroupModal({ isVisible, onRequestClose, group, onDeleted }: Props): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<HorseOwner> | undefined>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const removeGroup = async () => {
    if (!selectedOrganization || !group) return;

    try {
      const promise = HorsegroupsService.horsegroupsDestroy({
        organisationUid: selectedOrganization.uid,
        uid: group.uid,
      });
      await promise;

      // close the modal
      onRequestClose();

      // fire the onDeleted callback
      onDeleted();
    } catch (error) {
      setApiError(new ApiErrorParser<HorseGroup>(error));
    }
  };

  const closeModal = () => {
    setApiError(undefined);
    onRequestClose();
  };

  return (
    <ActionModal
      open={isVisible}
      onClose={closeModal}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: closeModal,
        },
        {
          text: t('delete', 'Delete'),
          variant: ButtonVariant.PrimaryDanger,
          onClick: removeGroup,
        },
      ]}
      title={t('delete-horse-group', 'Delete horse group')}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2  w-full'>{t('delete-horse-group-desc', 'Are you sure you want to remove this group?')}</p>
    </ActionModal>
  );
}

export default DeleteGroupModal;

import { Contact, GetHorsesFromRvo } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { table, tableHiddenHeaderSm, tableTbody, tableThead } from 'ui/Const';
import { PageModal } from 'ui/Modals';
import { PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import Row from './Row';
import { ErrorSection } from 'ui/Error';
import ApiErrorParser from 'api/ApiErrorParser';

interface Props {
  isVisible: boolean;
  locations: Contact[];
  onRequestCloseModal: () => void;
}

function RvoImportHorseLocationSelectionModal({ isVisible, onRequestCloseModal, locations }: Props): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<GetHorsesFromRvo> | undefined>();

  const { t } = useTranslation();

  return (
    <PageModal
      open={isVisible}
      width={PageModalWidth.Sm}
      onClosed={() => {
        setApiError(undefined);
      }}
    >
      <PageModalTitle
        title={t('rvo-import-location-selection', 'Choose a location to import the horse locations.')}
        onClose={onRequestCloseModal}
      />
      <PageModalContent>
        {apiError && <ErrorSection className='mb-4' errors={apiError} />}

        <p className='mb-4'>
          {t(
            'rvo-not-create-horses-desc',
            'When clicking the import button we fetch the locations from the RVO. In this process we also create new horses if they do not exist in EquineM.',
          )}
        </p>
        <table className={table}>
          <thead className={tableThead}>
            <tr className={tableHiddenHeaderSm}>
              <td className='w-12 md:w-14' />
              <td>{t('name', 'Name')}</td>
              <td className='w-16 text-center' />
            </tr>
          </thead>
          <tbody className={tableTbody}>
            {locations.map(location => (
              <Row authorizedStables={locations} key={location.uid} location={location} onError={error => setApiError(error)} />
            ))}
          </tbody>
        </table>
      </PageModalContent>
    </PageModal>
  );
}

export default RvoImportHorseLocationSelectionModal;

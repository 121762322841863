import { DownloadSimple, Pencil } from '@phosphor-icons/react';
import useApiPromises from 'api/hooks/useApiPromises';
import { AppRoutes, generateWebshopPath, WebshopRoutes } from 'AppRoutes';
import HorseUpdatePedigreeModal from 'components/Horses/HorseUpdatePedigreeModal';
import ImportFromHorseTelexModal from 'components/Horses/ImportFromHorseTelexModal';
import Pedigree from 'components/Pedigree';
import { useOrganization } from 'context/OrganizationContext';
import { PageAction } from 'context/PageContext';
import usePermissions from 'hooks/UsePermissions';
import { HorsesService, ModulePermissionsEnum } from 'openapi';
import { HorseDetail } from 'openapi/models/HorseDetail';
import { LoginType } from 'pages/Login';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import { ButtonVariant } from 'ui/Button';
import Page, { PageMaxWidth } from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import useModal from 'ui/Modals/UseModal';
import { ApiPromises } from 'utilities/ApiPromises';

interface Props {
  loginType: LoginType;
}

function HorsePedigree({ loginType }: Props): JSX.Element {
  const { selectedOrganization } = useOrganization();
  const [horse, setHorse] = useState<HorseDetail>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { uid, publicAccessUuid } = useParams();
  const { showModal: showUpdateModal, closeModal: closeUpdateModal, modalIsVisible: updateModalIsVisible } = useModal();
  const { showModal: showImportModal, closeModal: closeImportModal, modalIsVisible: importModalIsVisible } = useModal();
  const { t } = useTranslation();
  const location = useLocation();
  const { hasPermission } = usePermissions();
  const { loading } = useApiPromises({ apiPromises });

  // flag that indicate if we setup the modal
  const setupPedigree = location.hash.startsWith('#setup');
  const modalTitle = setupPedigree ? t('setup-pedigree', 'Setup pedigree') : t('update-pedigree', 'Update pedigree');

  const breadCrumbs = useMemo(() => {
    if (loginType === LoginType.Equinem) {
      return [AppRoutes.HorsesList, { ...AppRoutes.HorsesDetails, name: horse?.name ?? '' }];
    } else {
      return [
        {
          name: t('mares', 'Mares'),
          path: generateWebshopPath(WebshopRoutes.MaresList.path, publicAccessUuid ?? ''),
        },
        {
          name: horse?.name ?? '',
          path: generateWebshopPath(generatePath(WebshopRoutes.MareDetails.path, { uid: uid }), publicAccessUuid ?? ''),
        },
      ];
    }
  }, [horse, loginType, publicAccessUuid, uid, t]);

  const actions = useMemo((): PageAction[] | undefined => {
    if (hasPermission(ModulePermissionsEnum.MANAGE_HORSES)) {
      return [
        {
          onClick: showUpdateModal,
          text: t('edit', 'Edit'),
          buttonVariant: ButtonVariant.Default,
          icon: <Pencil />,
        },
        {
          onClick: showImportModal,
          text: t('horse-telex', 'Import from Horse-telex'),
          buttonVariant: ButtonVariant.Default,
          icon: <DownloadSimple />,
        },
      ];
    }
  }, [hasPermission, showImportModal, showUpdateModal, t]);

  /**
   * Load data from the api/cache
   */
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    if (!selectedOrganization) {
      return promises;
    }
    promises.appendSingle<HorseDetail>(
      'horse',
      () =>
        HorsesService.horsesRetrieve({
          organisationUid: selectedOrganization?.uid ?? '',
          uid: uid ?? '',
        }),
      setHorse,
    );

    setApiPromises(promises);

    return promises;
  }, [selectedOrganization, uid]);

  /**
   * Event that will be triggered when the modal is closing
   */
  const closePedigreeUpdateModal = () => {
    closeUpdateModal();
    // we need to update the acutal window/self.location instead of the React location object
    self.location.hash = '';
  };

  /**
   * Load from the api
   */
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  /**
   * Open the modal if we have the flag #setup set in the hash of the url
   */
  useEffect(() => {
    if (setupPedigree) {
      showUpdateModal();
    }
  }, [setupPedigree, showUpdateModal]);

  return (
    <Page title={t('pedigree', 'Pedigree')} breadCrumbs={breadCrumbs} actions={actions} loading={loading} maxWidth={PageMaxWidth.Tile}>
      <Tile noBoxOnMobile={true}>
        <Pedigree horse={horse} />
      </Tile>

      {horse && (
        <HorseUpdatePedigreeModal
          title={modalTitle}
          isVisible={updateModalIsVisible}
          closeModal={closePedigreeUpdateModal}
          horse={horse}
          onUpdate={updatedHorse => setHorse(updatedHorse)}
        />
      )}

      <ImportFromHorseTelexModal
        isVisible={importModalIsVisible}
        horse={horse}
        onRequestClose={closeImportModal}
        onImported={updatedHorse => setHorse(updatedHorse)}
      />
    </Page>
  );
}

export default memo(HorsePedigree);

import React from 'react';
import DescriptionListItem, { DefinitionItem, DescriptionListMode } from './DescriptionListItem';
import classNames from 'classnames';

interface Props {
  list: DefinitionItem[];
  className?: string;
  classNameListItem?: string;
  classNameDT?: string;
  classNameDD?: string;
  mode?: DescriptionListMode;
}

/**
 * List of label and definition. Can be used to display details of an object.
 */
function DescriptionList({ list, className, classNameListItem, classNameDT, classNameDD, mode }: Props): JSX.Element {
  return (
    <dl className={classNames('divide-y divide-gray-100', className)}>
      {list.map(item => (
        <DescriptionListItem
          key={item.term}
          className={classNameListItem}
          classNameDT={classNameDT}
          classNameDD={classNameDD}
          mode={mode}
          {...item}
        />
      ))}
    </dl>
  );
}

export default DescriptionList;

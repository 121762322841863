import { AppRoutes } from 'AppRoutes';
import classnames from 'classnames';
import { AppliedListFilter, ListFilterType } from 'components/Common/ListFilter';
import FilterWrapper, { FilterActions } from 'components/Common/ListFilter/FilterWrapper';
import useListFilter from 'components/Common/ListFilter/useListFilter';
import HorseLocationHistoryRow from 'components/Horses/HorseLocationHistoryRow';
import HorseSelectButton from 'components/Horses/HorseSelectButton';
import { useOrganization } from 'context/OrganizationContext';
import useRvoReportCount from 'hooks/UseRvoReportCount';
import { Contact, ContactsService, Horse, HorseLocation, HorselocationsService } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  defaultApiPageSize,
  table,
  tableHiddenColumnSm,
  tableHiddenHeaderMd,
  tableTbody,
  tableTheadTd,
  tableTheadTdSticky,
} from 'ui/Const';
import Page from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { ApiPromises } from 'utilities/ApiPromises';
import { activeOrganizationHorses } from 'utilities/ApiRequests';

function LocationHistory(): JSX.Element {
  const [horses, setHorses] = useState<Horse[]>();
  const [contacts, setContacts] = useState<Contact[]>();
  const [horseLocations, setHorseLocations] = useState<HorseLocation[]>([]);
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedOrganization, generateCacheKey } = useOrganization();
  const { reportCount, loadReportCount } = useRvoReportCount();

  /**
   * list only the RVO authorized contacts
   */
  const rvoAuthorizedContacts = useMemo(() => {
    return (contacts ?? [])?.filter(contact => contact.machtiging_rvo_gegeven);
  }, [contacts]);

  /**
   * A list of types we can filter by.
   */
  const filterTypes = useMemo((): ListFilterType[] | undefined => {
    if (!horses) {
      return undefined;
    }

    // simple horse filter by name
    const horseFilter: ListFilterType = {
      id: 'horse',
      name: t('horses', 'Horses'),
      options: horses.map(horse => ({ id: horse.uid, name: horse.name })),
    };

    return [horseFilter];
  }, [t, horses]);

  const { filters, setFilters } = useListFilter(filterTypes ?? []);

  /**
   * Get the selected horse based on the filter (url search query).
   */
  const selectedHorseUid = useMemo((): string | undefined => {
    const foundFilter = filters.find(filter => filter.type.id === 'horse');
    if (!foundFilter) {
      return undefined;
    }
    if (foundFilter.options.length !== 1) {
      return undefined;
    }

    return foundFilter.options[0].id;
  }, [filters]);

  /**
   * Get the selected horse based on the filter.
   */
  const selectedHorse = useMemo((): Horse | undefined => {
    if (!selectedHorseUid || !horses) return undefined;
    return horses.find(horse => horse.uid === selectedHorseUid);
  }, [horses, selectedHorseUid]);

  /**
   * Select a horse
   */
  const selectHorse = useCallback(
    (horse?: Horse) => {
      if (!filterTypes) {
        return;
      }

      const foundFilterType = filterTypes.find(f => f.id === 'horse');
      if (!foundFilterType) {
        // should never happen.
        console.error('Filter type not found');
        return;
      }

      // The selection is cleared.
      if (!horse) {
        setFilters([]);
        return;
      }

      const horseFilter: AppliedListFilter = {
        type: foundFilterType,
        options: [{ id: horse.uid, name: horse.name }],
      };
      setFilters([horseFilter]);
    },
    [filterTypes, setFilters],
  );

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    if (!selectedOrganization) {
      return promises;
    }

    // load the horse locations
    promises.setPaginated<HorseLocation>(
      'horse-location',
      apiPageNumber => {
        return HorselocationsService.horselocationsList({
          locationOrganisationUid: selectedOrganization.uid,
          page: apiPageNumber,
          pageSize: defaultApiPageSize,
          horseUid: selectedHorseUid,
          o: '-arrival_date',
        });
      },
      setHorseLocations,
      true,
    );

    // load the horses
    promises.appendListObj<Horse>('horses', setHorses, activeOrganizationHorses(selectedOrganization?.uid, generateCacheKey));

    // Load all contacts, including the removed once.
    promises.appendList<Contact>(
      'contacts',
      () =>
        ContactsService.contactsList({
          organisationUid: selectedOrganization.uid,
        }),
      setContacts,
      generateCacheKey('contacts'),
    );

    setApiPromises(promises);

    return promises;
  }, [selectedOrganization, generateCacheKey, selectedHorseUid]);

  /**
   * Show the RVO button if there are any authorized contacts
   */
  const rvoButton = useMemo((): FilterActions[] => {
    if (!rvoAuthorizedContacts?.length) return [];

    return [
      {
        badge: reportCount,
        text: t('rvo-report-list', 'RVO report list'),
        buttonCompress: false,
        icon: <></>,
        onClick: () => navigate(AppRoutes.HorsesRvoList.path),
      },
    ];
  }, [rvoAuthorizedContacts?.length, reportCount, t, navigate]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization, selectedHorseUid]); //eslint-disable-line

  /**
   * Load the report count
   */
  useEffect(() => {
    const promise = loadReportCount();
    return () => promise && promise.cancel();
  }, [loadReportCount]);

  return (
    <Page title={t('location-history', 'Location history')} loading={apiPromises}>
      <PullScrollWrapper apiPromises={apiPromises}>
        <Tile noBoxOnMobile={true}>
          {filterTypes && horses && (
            <FilterWrapper actions={rvoButton}>
              <HorseSelectButton selectedHorse={selectedHorse} horses={horses} horseSelected={selectHorse} />
            </FilterWrapper>
          )}

          <table className={table}>
            <thead>
              <tr className={tableHiddenHeaderMd}>
                <td className={classnames('w-10', tableTheadTdSticky)} />
                <td className={classnames(tableTheadTd, tableTheadTdSticky)}>{t('horse', 'Horse')}</td>
                <td className={classnames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnSm)}>{t('location', 'Location')}</td>
                <td className={classnames(tableTheadTd, tableTheadTdSticky)}>{t('arrival', 'Arrival')}</td>
                <td className={classnames(tableTheadTd, tableTheadTdSticky)}>{t('depature', 'Departure')}</td>
              </tr>
            </thead>
            <tbody className={tableTbody}>
              {horseLocations.map(horseLocation => {
                const horse = horses?.find(horse => horse.uid === horseLocation.horse_uid);
                if (horse) {
                  return (
                    <HorseLocationHistoryRow key={horseLocation.uid} contacts={contacts} horse={horse} horseLocation={horseLocation} />
                  );
                }
              })}
            </tbody>
          </table>
        </Tile>
      </PullScrollWrapper>
    </Page>
  );
}

export default LocationHistory;

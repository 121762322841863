import { File, Paperclip, Trash } from '@phosphor-icons/react';
import UploadFile from 'api/UploadFile';
import { useConfig } from 'context/ConfigContext';
import { useOrganization } from 'context/OrganizationContext';
import { ApiError, OrganisationService, PatchedOrganisation } from 'openapi';
import React, { useCallback, useState } from 'react';
import { Tile } from 'ui/Layout/Tile';
import useFileSelect, { SelectionType } from 'hooks/UseFileSelect';
import Button, { ButtonInternal, ButtonSize, ButtonVariant } from 'ui/Button';
import { useTranslation } from 'react-i18next';
import ApiErrorParser from 'api/ApiErrorParser';
import { ErrorSection } from 'ui/Error';
import useModal from 'ui/Modals/UseModal';
import { ActionModal } from 'ui/Modals';

// A tile for managing the terms and conditions for webshop orders.
export default function ShopTermsTile(): JSX.Element {
  const { selectedOrganizationDetails, selectedOrganizationUid, refresh } = useOrganization();
  const { config } = useConfig();
  const { t } = useTranslation();
  const [uploadingTerms, setUploadingTerms] = useState<boolean>(false);
  const [error, setError] = useState<string | ApiErrorParser<PatchedOrganisation> | undefined>();

  const {
    closeModal: closeRemoveTermsErrorModal,
    modalIsVisible: removeTermsErrorModalVisible,
    showModal: showRemoveTermsErrorModal,
  } = useModal();

  const { openDialog: openUploadTermsDialog } = useFileSelect({
    onFilesSelected: async (files: File[]) => {
      setUploadingTerms(true);
      setError(undefined);
      try {
        const file = files.at(0);
        if (!file) throw Error('One file must be selected');
        if (!selectedOrganizationUid) throw Error('No organization selected');
        // Required because we use vercel to upload the image.
        if (!config?.hasServerlessFunctions) throw Error('Serverless functions are not enabled.');

        // Upload the pdf to s3 via Vercel serverless function.
        const url = await UploadFile.uploadOrganizationShopTerms(await file.arrayBuffer(), selectedOrganizationUid, 'application/pdf');

        // Patch our organization with the logo image s3 url.
        const patchedOrganization: PatchedOrganisation = { shop_terms_conditions: url.toString() };
        await OrganisationService.rootPartialUpdate({ uid: selectedOrganizationUid, requestBody: patchedOrganization });

        // refresh the organization
        refresh();
      } catch (error) {
        if (error instanceof ApiError) {
          setError(new ApiErrorParser<PatchedOrganisation>(error));
        } else {
          setError((error as Error).message);
        }
      }
      setUploadingTerms(false);
    },
  });

  const uploadTerms = useCallback(() => {
    openUploadTermsDialog(SelectionType.Files, false, ['application/pdf']);
  }, [openUploadTermsDialog]);

  const removeTerms = async () => {
    try {
      const patchedOrganization: PatchedOrganisation = { shop_terms_conditions: null };
      await OrganisationService.rootPartialUpdate({ uid: selectedOrganizationUid ?? '', requestBody: patchedOrganization });
    } catch (error) {
      setError(new ApiErrorParser<PatchedOrganisation>(error));
    }
    refresh();
    closeRemoveTermsErrorModal();
  };

  return (
    <Tile title={t('terms-and-conditions', 'Terms and conditions')}>
      <div className='flex flex-col gap-2'>
        <p>
          {t(
            'terms-settings-desc',
            'The terms and conditions that need to be accepted on every webshop order. This should be a pdf document that can (optionally) be read by the customer.',
          )}
        </p>
        <ErrorSection errors={error} />
        {selectedOrganizationDetails?.shop_terms_conditions ? (
          <div className='rounded-lg border p-3 flex flex-row gap-4 items-center  grow'>
            <File weight='thin' size={42} />
            <div className='grow'>
              <p>{t('terms-and-conditions', 'Terms and conditions')}</p>
              <p className='text-gray-500 text-sm'>{t('pdf-document', 'Pdf document')}</p>
            </div>
            <a href={selectedOrganizationDetails?.shop_terms_conditions ?? ''} target={'_blank'} rel='noreferrer'>
              <ButtonInternal size={ButtonSize.Normal} variant={ButtonVariant.Default}>
                {t('open-terms-document', 'Open')}
              </ButtonInternal>
            </a>
            <Button
              icon={<Trash />}
              variant={ButtonVariant.Danger}
              onClick={() => {
                setError(undefined);
                showRemoveTermsErrorModal();
              }}
            >
              <span className='hidden md:inline'>{t('remove-terms-document', 'Remove')}</span>
            </Button>
          </div>
        ) : (
          <div className='rounded-lg border p-4 border-dashed flex flex-col gap-4 items-center'>
            <div>
              <p className='font-medium'>{t('no-file-selected', 'No file selected')}</p>
              <p className='text-sm text-gray-500 text-center'>{'(pdf)'}</p>
            </div>
            <Button loading={uploadingTerms} type='button' onClick={uploadTerms} icon={<Paperclip />}>
              {t('choose-file', 'Choose file')}
            </Button>
          </div>
        )}
      </div>
      <ActionModal
        open={removeTermsErrorModalVisible}
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: closeRemoveTermsErrorModal,
          },
          {
            text: t('remove', 'Remove'),
            variant: ButtonVariant.PrimaryDanger,
            onClick: removeTerms,
          },
        ]}
        title={t('webshop-remove-terms', 'Remove webshop terms and conditions')}
      >
        <ErrorSection errors={error} />
        <p className='mt-2 w-full'>{t('webshop-remove-terms-desc', 'Are you sure you want to remove the webshop terms and conditions?')}</p>
      </ActionModal>
    </Tile>
  );
}

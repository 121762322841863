import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { InvitationsService, SentInvitation } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  onSuccess: () => void;
  invitation: SentInvitation | undefined;
}

function ResendInvitationModal({ isVisible, onRequestClose, invitation, onSuccess }: Props): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<SentInvitation> | undefined>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  /**
   * Resend an invitation.
   */
  const resendInvitation = async () => {
    if (!selectedOrganization || !invitation) return;
    try {
      const promise = InvitationsService.invitationsResendInvitationPartialUpdate({
        inviterOrganisationUid: selectedOrganization?.uid ?? '',
        uuid: invitation.uuid,
      });

      await promise;

      // close the modal
      onRequestClose();
      // fire the onDeleted callback
      onSuccess();
    } catch (error) {
      setApiError(new ApiErrorParser<SentInvitation>(error));
    }
  };

  return (
    <ActionModal
      open={isVisible}
      onClose={onRequestClose}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: onRequestClose,
        },
        {
          text: t('resend', 'Resend'),
          variant: ButtonVariant.Primary,
          onClick: resendInvitation,
        },
      ]}
      title={t('resend-invitation', 'Resend invitation')}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2  w-full'>{t('resend-invitation-desc', 'Are you sure you want to resend this invitation to the user?')}</p>
    </ActionModal>
  );
}

export default ResendInvitationModal;

import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { Contact, ContactsService } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  onDeleted: () => void;
  contact: Contact;
}

function DeleteContactModal({ isVisible, onRequestClose, contact, onDeleted }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorParser<Contact> | undefined>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const removeContact = async () => {
    if (!selectedOrganization) return;
    setSubmitting(true);

    try {
      const promise = ContactsService.contactsDestroy({
        organisationUid: selectedOrganization.uid,
        uid: contact.uid,
      });
      await promise;

      // close the modal
      onRequestClose();

      // fire the onDeleted callback
      onDeleted();
    } catch (error) {
      setApiError(new ApiErrorParser<Contact>(error));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ActionModal
      open={isVisible}
      onClose={onRequestClose}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: onRequestClose,
        },
        {
          text: t('delete', 'Delete'),
          loading: submitting,
          variant: ButtonVariant.PrimaryDanger,
          onClick: removeContact,
        },
      ]}
      title={contact.user_uid ? t('delete-user', 'Delete user') : t('delete-contact', 'Delete contact')}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2  w-full'>
        {contact.user_uid
          ? t('delete-user-desc', 'Are you sure you want to remove this user?')
          : t('delete-contact-desc', 'Are you sure you want to remove this contact?')}
      </p>
    </ActionModal>
  );
}

export default DeleteContactModal;

import { zodResolver } from '@hookform/resolvers/zod';
import { PencilSimple } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { AccountService, PasswordChange } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { TextInput } from 'ui/Inputs';
import { Tile } from 'ui/Layout/Tile';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { transformEmptyToUndefined } from 'utilities/zod';

export default function PasswordForm(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const schema = useMemo(() => {
    return schemas.PasswordChange.refine(data => data.new_password1 === data.new_password2, {
      message: t('password-mismatch', "Passwords don't match"),
      path: ['new_password2'],
    });
  }, [t]);

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors: errors },
  } = useForm<PasswordChange>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      old_password: '',
      new_password1: '',
      new_password2: '',
    },
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const { showModal, closeModal, modalIsVisible } = useModal();

  const onSubmit = async (data: PasswordChange) => {
    setLoading(true);

    try {
      await AccountService.apiV5PasswordChangeCreate({ requestBody: data });
      onClose(); // close the modal
    } catch (error) {
      setApiError(new ApiErrorParser<PasswordChange>(error));
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    reset();
    closeModal();
    // clear the errors
    clearErrors();
    setApiError(undefined);
  };

  return (
    <Tile
      title={t('password', 'Password')}
      actions={[{ onClick: showModal, buttonVariant: ButtonVariant.Default, icon: <PencilSimple />, text: t('update', 'Update') }]}
    >
      <p>{t('password-form-info', 'Secure your account with a strong password')}</p>
      <div className='mt-4 flex flex-col'>
        <span>************</span>
        {/* When https://gitlab.qubis.nl/equinem/equinemcore/-/issues/94 has been added, we could enable this element */}
        {/* <span className='text-xs italic text-gray-500'>
          {t('', 'Last changed at: {{lastChanged}}', {
            lastChanged: user?.formatDate(new Date(), { dateStyle: 'full', timeStyle: 'medium' }),
          })}
        </span> */}
      </div>

      <PageModal
        open={modalIsVisible}
        parentElement='form'
        parentProps={{ id: 'updatePasswordForm', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={t('update-password', 'Update password')} onClose={onClose} />
        <PageModalContent>
          <div className='py-3 space-y-4'>
            <ErrorSection errors={nonFieldErrors} />
            <TextInput
              error={fieldError('old_password')}
              required={true}
              type={'password'}
              label={t('current-password', 'Current password')}
              {...register('old_password', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('new_password1')}
              required={true}
              type={'password'}
              label={t('new-password', 'New password')}
              {...register('new_password1', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('new_password2')}
              required={true}
              type={'password'}
              label={t('confirm-password', 'Confirm password')}
              {...register('new_password2', { setValueAs: transformEmptyToUndefined() })}
            />
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              onClick: onClose,
              variant: ButtonVariant.Default,
              type: 'button',
              text: t('cancel', 'Cancel'),
            },
            {
              loading: loading,
              variant: ButtonVariant.Primary,
              type: 'submit',
              formId: 'updatePasswordForm',
              text: t('save', 'Save'),
            },
          ]}
        />
      </PageModal>
    </Tile>
  );
}

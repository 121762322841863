import { AppRoutes } from 'AppRoutes';
import ApiErrorParser from 'api/ApiErrorParser';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import useUserInvitations from 'hooks/UseUserInvitations';
import { InvitationsService, ReceivedInvitation } from 'openapi';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';

interface Props {
  invitation: ReceivedInvitation;
}

/**
 * Confirm the invitation as a logged in user
 */
export default function ConfirmAsLoggedInUser({ invitation }: Props): JSX.Element {
  const { t } = useTranslation();
  const [apiError, setApiError] = useState<ApiErrorParser<ReceivedInvitation>>();
  const [submitting, setSubmitting] = useState<boolean>();

  const { accountDetails } = useAccount();
  const { refresh } = useOrganization();
  const navigate = useNavigate();
  const { loadInvitations } = useUserInvitations();

  /**
   * Accept the invitation
   */
  const acceptInvitation = async () => {
    setSubmitting(true);
    try {
      const promise = InvitationsService.apiV5InvitationsAcceptPartialUpdate({
        uuid: invitation.uuid,
      });

      await promise;

      // we also need to reload the organizations to show the correct list
      refresh();
      // and reload the invitations
      loadInvitations();

      // everything is done, we can navigate to the home page
      navigate(AppRoutes.Home.path);
    } catch (e) {
      setApiError(new ApiErrorParser<ReceivedInvitation>(e));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <ErrorSection className='my-2' errors={apiError} />

      <p className='my-2 text-gray-700'>
        <Trans
          i18nKey='invitation-confirm-as-logged-in-user-desc'
          defaults='Hi {{first_name}} {{last_name}}, you have been invited by <strong>{{inviter_first_name}} {{inviter_last_name}}</strong> to join the organization <strong>{{organisationName}}</strong>.'
          values={{
            organisationName: invitation?.inviter_organisation.name,
            first_name: accountDetails?.first_name,
            last_name: accountDetails?.last_name,
            inviter_first_name: invitation?.inviter.first_name,
            inviter_last_name: invitation?.inviter.last_name,
          }}
        />
      </p>

      <p className='my-2'>{t('invitation-accept-button-desc', 'By clicking on the button below you will join this organization.')}</p>

      <Button onClick={acceptInvitation} loading={submitting} variant={ButtonVariant.Primary} className='w-full'>
        {t('accept-invitation', 'Accept invitation')}
      </Button>
    </>
  );
}

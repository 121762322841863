import { zodResolver } from '@hookform/resolvers/zod';
import { Pencil, Plus, Trash } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { DaypartsService, DayPartStartTime } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button, { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { TextInput } from 'ui/Inputs';
import { DefinitionItem } from 'ui/Layout/DescriptionList/DescriptionListItem';
import { Tile } from 'ui/Layout/Tile';
import TileDescriptionList from 'ui/Layout/Tile/TileDescriptionList';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { ApiPromises } from 'utilities/ApiPromises';
import { transformEmptyToUndefined } from 'utilities/zod';
import { z } from 'zod';

const schema = z.object({
  items: z.array(schemas.DayPartStartTime),
});

type DayPartItems = z.infer<typeof schema>;

const defaults = (dayParts: DayPartStartTime[]): DayPartItems => {
  return { items: dayParts };
};

export default function SettingsCalendarTile(): JSX.Element {
  const [dayParts, setDayParts] = useState<DayPartStartTime[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const { t } = useTranslation();
  const { selectedOrganizationUid } = useOrganization();
  const [apiError, setApiError] = useState<ApiErrorParser<DayPartStartTime>>();

  const { showModal, closeModal, modalIsVisible } = useModal();
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    register,
    clearErrors,
    control,
  } = useForm<DayPartItems>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: dayParts ? defaults(dayParts) : undefined,
  });

  // Use a fieldArray for the invoice items.
  const { fields, append, remove } = useFieldArray({
    name: 'items',
    control,
  });

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganizationUid) {
      return promises;
    }
    promises.appendList<DayPartStartTime>(
      'dayparts',
      () =>
        DaypartsService.daypartsList({
          organisationUid: selectedOrganizationUid,
        }),
      setDayParts,
    );

    setApiPromises(promises);
    return promises;
  }, [selectedOrganizationUid]);

  /**
   * Close event for the modal
   */
  const onClose = () => {
    // clear the errors
    clearErrors();
    setApiError(undefined);
    closeModal();
  };

  /**
   * Submit event handler, update the data via the API for this organization
   */
  const onSubmit: SubmitHandler<DayPartItems> = async (data: DayPartItems) => {
    if (!selectedOrganizationUid || !dayParts) {
      return;
    }
    try {
      // Updated items
      for (const item of data.items) {
        const found = dayParts.find(dayPart => dayPart.uid === item.uid);
        if (!item.uid || !found || (found.start_time === item.start_time && found.name === item.name)) {
          continue;
        }
        await DaypartsService.daypartsPartialUpdate({
          organisationUid: selectedOrganizationUid,
          uid: found.uid,
          requestBody: { start_time: item.start_time, name: item.name },
        });
      }

      // Removed items
      const removedItems = dayParts.filter(
        dayPart => dayPart.uid && data.items.find(dataItem => dataItem.uid === dayPart.uid) === undefined,
      );
      for (const item of removedItems) {
        await DaypartsService.daypartsDestroy({ organisationUid: selectedOrganizationUid, uid: item.uid });
      }

      // Created items
      for (const item of data.items.filter(item => !item.uid)) {
        await DaypartsService.daypartsCreate({ organisationUid: selectedOrganizationUid, requestBody: item });
      }

      // close the modal
      onClose();
      loadApiData();
    } catch (error) {
      setApiError(new ApiErrorParser<DayPartStartTime>(error));
    }
  };

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo(() => {
    return [
      {
        onClick: showModal,
        text: t('edit', 'Edit'),
        buttonVariant: ButtonVariant.Default,
        icon: <Pencil />,
      },
    ];
  }, [showModal, t]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  // Reset the form to its defaults when the dialog gets opened.
  useEffect(() => {
    if (modalIsVisible) {
      reset(dayParts ? defaults(dayParts) : undefined);
    }
  }, [modalIsVisible, reset]); //eslint-disable-line

  const descriptionList = useMemo((): DefinitionItem[] => {
    const list: DefinitionItem[] = [];
    if (!dayParts) {
      return list;
    }
    list.push({
      term: t('day-parts-enabled', 'Use day parts'),
      definition: dayParts.length ?? 0 > 1 ? t('yes', 'Yes') : t('no', 'No'),
    });
    if (dayParts.length > 0) {
      const compactTimeStr = (time: string) => {
        const items = time.split(':');
        return `${items[0]}:${items[1]}`;
      };

      const dayPartTimeLabel = (dayPart: DayPartStartTime, index: number) => {
        const start = compactTimeStr(dayPart.start_time);
        let end = '23:59';
        if (index !== dayParts.length - 1) {
          end = compactTimeStr(dayParts[index + 1].start_time);
        }
        return `${start} - ${end}`;
      };

      list.push({
        term: t('day-parts', 'Day parts'),
        definition: (
          <ul className='list-disc'>
            {dayParts.map((dayPart, index) => (
              <li key={dayPart.uid}>
                {dayPart.name} {dayPartTimeLabel(dayPart, index)}
              </li>
            ))}
          </ul>
        ),
      });
    }
    return list;
  }, [dayParts, t]);

  return (
    <Tile title={t('planning-settings', 'Planning settings')} loading={apiPromises} actions={tileActions}>
      <TileDescriptionList classNameDT='sm:w-2/3 md:w-1/2 lg:w-1/3' list={descriptionList} />

      <PageModal
        open={modalIsVisible}
        parentElement='form'
        width={PageModalWidth.Xs}
        parentProps={{ id: 'saveDayPartSettings', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={t('update-day-part-settings', 'Update day part settings')} onClose={onClose} />
        <PageModalContent>
          <ErrorSection className='mb-4' errors={apiError} />
          <div className='space-y-4'>
            <p>
              {t(
                'day-part-settings-description',
                'Use day parts to divide your planning day into useful blocks. For example you could use Morning and Afternoon.',
              )}
            </p>
            {fields.map((dayPart, index) => {
              return (
                <div key={dayPart.uid} className='flex gap-2 items-end'>
                  <TextInput
                    className='grow'
                    required={true}
                    label={t('name', 'Name')}
                    error={get(errors, `items.[${index}].name`)?.message}
                    {...register(`items.${index}.name`)}
                  />

                  <TextInput
                    required={true}
                    readOnly={index === 0}
                    disabled={index === 0}
                    type='time'
                    label={t('start-time', 'Start time')}
                    error={get(errors, `items.[${index}].start_time`)?.message}
                    {...register(`items.${index}.start_time`, { setValueAs: transformEmptyToUndefined() })}
                  />

                  <button
                    onClick={e => {
                      e.preventDefault();
                      remove(index);
                    }}
                    disabled={index === 0}
                    className='rounded w-8 h-10 flex items-center justify-center hover:bg-red-600  disabled:hover:bg-inherit disabled:text-white hover:text-white focus:ring-0 focus:ring-offset-0'
                  >
                    <Trash />
                  </button>
                </div>
              );
            })}
            <div>
              <Button
                icon={<Plus />}
                onClick={e => {
                  e.preventDefault();
                  append({
                    uid: '',
                    start_time: '00:00',
                    name: '',
                  });
                }}
              >
                {t('add-day-part', 'Add day part')}
              </Button>
            </div>
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              onClick: onClose,
              variant: ButtonVariant.Default,
              type: 'button',
              text: t('cancel', 'Cancel'),
            },
            {
              formId: 'saveDayPartSettings',
              loading: isSubmitting,
              variant: ButtonVariant.Primary,
              type: 'submit',
              text: t('save', 'Save'),
            },
          ]}
        />
      </PageModal>
    </Tile>
  );
}

import { HorseDetail } from 'openapi/models/HorseDetail';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageModal } from 'ui/Modals';
import { PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import HorseDetails from './HorseDetails';

interface Props {
  horse: HorseDetail | undefined;
  open: boolean;
  closeModal: () => void;
}

/**
 * Basic detail modal, with just plain data to show
 *
 * This will be used for example when creating an horse and the horse exists.
 * In order to view the horse, we show this modal with the basic details.
 */
function HorseBasicDetailsModal({ open, closeModal, horse }: Props): JSX.Element {
  const { t } = useTranslation();

  if (!horse) {
    return <></>;
  }

  return (
    <PageModal open={open}>
      <PageModalTitle title={t('horse-basic-details', 'Horse basic details')} onClose={closeModal} />
      <PageModalContent>
        <HorseDetails className='py-2' horse={horse} />
      </PageModalContent>
    </PageModal>
  );
}

export default HorseBasicDetailsModal;

import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { HorseGroup, HorsegroupsService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { TextInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';

interface Props {
  group?: HorseGroup;
  isVisibile: boolean;
  onRequestClose: () => void;
  onSaved: (group: HorseGroup) => void;
}

function SaveGroupModal({ isVisibile, onRequestClose, group, onSaved }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();

  // Form validation
  const schema = useMemo(() => {
    return schemas.HorseGroup.pick({
      name: true,
    }).required();
  }, []);

  const defaultValues = useMemo((): Partial<HorseGroup> => {
    return {
      name: group?.name,
    };
  }, [group?.name]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm<HorseGroup>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Function invoked after the modal has been closed
   */
  const onClosed = () => {
    // clear the errors
    clearErrors();
    setApiError(undefined);
    reset();
  };

  /**
   * Define the page title based on the selected group
   */
  const pageTitle = useMemo(() => {
    return group ? t('edit-horse-group', 'Edit horse group') : t('add-horse-group', 'Add horse group');
  }, [group, t]);

  /**
   * Submit event handler, update the data via the API
   */
  const onSubmit: SubmitHandler<HorseGroup> = async (data: HorseGroup) => {
    if (!selectedOrganization) return;
    setSubmitting(true);

    try {
      let updatedgroup: HorseGroup;

      // update if we deal with an existing horse group, otherwise create a new one
      if (group) {
        const promise = HorsegroupsService.horsegroupsPartialUpdate({
          organisationUid: selectedOrganization.uid,
          uid: group.uid,
          requestBody: {
            name: data.name,
          },
        });
        updatedgroup = await promise;
      } else {
        const promise = HorsegroupsService.horsegroupsCreate({
          organisationUid: selectedOrganization.uid,
          requestBody: {
            name: data.name,
            default_id: null,
            uid: '',
          },
        });
        updatedgroup = await promise;
      }

      // fire the onSaved event
      onSaved(updatedgroup);

      // close the modal
      onRequestClose();
    } catch (error) {
      setApiError(new ApiErrorParser<HorseGroup>(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Reset the form when the default values change
   */
  useEffect(() => {
    if (!isVisibile) return;
    reset(defaultValues);
  }, [defaultValues, isVisibile, reset]);

  return (
    <PageModal
      open={isVisibile}
      parentElement='form'
      parentProps={{ id: 'createHorseOwner', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      onClosed={onClosed}
      width={PageModalWidth.Sm}
    >
      <PageModalTitle title={pageTitle} onClose={onRequestClose} />
      <PageModalContent>
        <ErrorSection className='mb-4' errors={nonFieldErrors} />

        <div className='py-3 space-y-4'>
          <TextInput
            label={t('name', 'Name')}
            error={fieldError('name')}
            required={zodInputIsRequired<HorseGroup>(schema, 'name')}
            {...register('name', { setValueAs: transformEmptyToUndefined() })}
          />
        </div>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            disabled: submitting,
            onClick: onRequestClose,
            variant: ButtonVariant.Default,
            type: 'button',
            text: t('cancel', 'Cancel'),
          },
          {
            formId: 'createHorseOwner',
            loading: submitting,
            variant: ButtonVariant.Primary,
            type: 'submit',
            text: t('save', 'Save'),
          },
        ]}
      />
    </PageModal>
  );
}

export default SaveGroupModal;

import React from 'react';

interface Props {
  // Progress bar width from 0 to 1.
  fractionDone: number;
}

export default function ProgressBar({ fractionDone }: Props): JSX.Element {
  return (
    <div className='w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700'>
      <div className='bg-blue-600 h-2.5 rounded-full transition-all' style={{ width: `${fractionDone * 100}%` }} />
    </div>
  );
}

import { updatedDiff } from 'deep-object-diff';

/**
 * Generate a diff object with the updated values
 *
 * @note we now typecast it to T, but this has been fixed in https://github.com/mattphillips/deep-object-diff/pull/98
 * @param originalObj
 * @param updatedObj
 * @returns an object with the updated values
 */
export function objectDiff<T extends object>(originalObj: T, updatedObj: T): Partial<T> {
  return updatedDiff(originalObj, updatedObj) as Partial<T>;
}

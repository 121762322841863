import { Horse as HorseIcon } from '@phosphor-icons/react';
import { Horse } from 'openapi';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize } from 'ui/Button';
import { table, tableTbody, tableTbodyTr } from 'ui/Const';
import { TextInput } from 'ui/Inputs';

interface Props {
  horses: Horse[];
  horseSelected: (horse?: Horse) => void;
  selectedHorse?: Horse;
}

// Select a horse. A list of horses with a search bar.
export default function HorseSelect({ horses, horseSelected, selectedHorse }: Props): JSX.Element {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>('');

  const searchResults = useMemo(() => {
    return horses.filter(horse => horse.name.toLowerCase().includes(searchValue.toLocaleLowerCase()));
  }, [searchValue, horses]);

  return (
    <div>
      <div className='hidden md:flex bg-neutral-50 p-4 rounded-b-lg gap-2'>
        <div className='grow'>
          <TextInput autoFocus={true} onChange={event => setSearchValue(event.target.value)} placeholder={t('search', 'Search') + '...'} />
        </div>
        {selectedHorse && (
          <Button
            onClick={() => {
              horseSelected(undefined);
            }}
          >
            {t('clear', 'Clear')}
          </Button>
        )}
      </div>
      <div className='block md:hidden text-right'>
        {selectedHorse && (
          <Button
            onClick={() => {
              horseSelected(undefined);
            }}
            size={ButtonSize.Small}
          >
            {t('clear', 'Clear')}
          </Button>
        )}
      </div>

      <table className={table}>
        <tbody className={tableTbody}>
          {searchResults.map(horse => {
            return (
              <tr
                className={tableTbodyTr}
                key={horse.uid}
                onClick={() => {
                  horseSelected(horse);
                }}
              >
                <td className='text-center w-10'>
                  <HorseIcon size={22} weight='light' className='inline' />
                </td>
                <td>{horse.name}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { Horse, HorsesService } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  onRequestCloseModal: () => void;
  isVisible: boolean;
  horseUid: string;
  onActivated: () => void;
}

function ActivateHorseModal({ onRequestCloseModal, isVisible, horseUid, onActivated }: Props): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<Horse[]> | undefined>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  /**
   * Re-activate the horse
   */
  const onActivate = async () => {
    try {
      const promise = HorsesService.horsesSetActiveCreate({
        organisationUid: selectedOrganization?.uid ?? '',
        uid: horseUid,
      });
      await promise;

      // fire the onActivated callback
      onActivated();

      // close the modal
      onRequestCloseModal();
    } catch (e) {
      setApiError(new ApiErrorParser<Horse>(e));
    }
  };

  return (
    <ActionModal
      open={isVisible}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: onRequestCloseModal,
        },
        {
          text: t('activate', 'Activate'),
          variant: ButtonVariant.Primary,
          onClick: onActivate,
        },
      ]}
      title={t('set-Horse-active-confirm-title', 'Activate horse')}
    >
      <>
        <ErrorSection errors={apiError} />
        <p>{t('set-horse-active-confirm-text', 'Are you sure you want to activate this horse?')}</p>
      </>
    </ActionModal>
  );
}

export default ActivateHorseModal;

import { CaretRight, ShoppingCart } from '@phosphor-icons/react';
import useApiPromises from 'api/hooks/useApiPromises';
import { AppRoutes } from 'AppRoutes';
import classNames from 'classnames';
import { shippingServiceType } from 'components/Breeding/Helpers';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { CategoriesService, Category, Contact, OrdersService, Product, ProductsService, SupplierOrder } from 'openapi';
import OrderListPaymentStatus from 'pages/breeding/OrderListPaymentStatus';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import Badge from 'ui/Badge';
import { BadgeSize } from 'ui/Badge/Badge';
import Button from 'ui/Button';
import { table, tableHiddenColumnMd, tableTbody, tableTbodyTr, tableThead, tableTheadTd } from 'ui/Const';
import { navBackToThisPage } from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import { ApiPromises } from 'utilities/ApiPromises';

interface Props {
  contact: Contact;
}

const SHOWED_COUNT = 10;

function OrdersTile({ contact }: Props): JSX.Element {
  const [orders, setOrders] = useState<SupplierOrder[]>();
  const [orderCount, setOrderCount] = useState<number>(0);
  const [categories, setCategories] = useState<Category[]>();
  const [products, setProducts] = useState<Product[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { loading } = useApiPromises({ apiPromises });

  const { formatDate } = useAccount();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  /**
   * Load data from the api/cache
   */
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganization) {
      return promises;
    }

    // load all orders
    promises.appendList<SupplierOrder>(
      'invoices',
      () =>
        OrdersService.ordersSuppliedList({
          supplierUid: selectedOrganization.uid,
          page: 1,
          pageSize: SHOWED_COUNT,
          customerUid: contact.uid,
          o: '-created_on,-id',
        }),
      setOrders,
      undefined,
      undefined,
      setOrderCount,
    );

    promises.appendList<Product>(
      'products',
      () =>
        ProductsService.productsList({
          organisationUid: selectedOrganization.uid,
        }),
      setProducts,
    );

    promises.appendList<Category>(
      'categories',
      () =>
        CategoriesService.categoriesList({
          organisationUid: selectedOrganization.uid,
        }),
      setCategories,
    );

    setApiPromises(promises);

    return promises;
  }, [contact.uid, selectedOrganization]);

  /**
   * Load from the api
   */
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // in case of no horses, we don't show the tile
  if (orderCount === 0) {
    return <></>;
  }

  return (
    <Tile title={t('orders', 'Orders')} className='mb-4' loading={loading}>
      <table className={table}>
        <thead className={tableThead}>
          <tr>
            <td className={classNames('w-10', tableHiddenColumnMd)} />
            <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('order-date', 'Date')}</td>
            <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('order-no', 'Order No.')}</td>
            <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>
              {t('requested-shipping-shipping-date', 'Shipping date (requested)')}
            </td>
            <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('payment', 'Payment')}</td>
            <td className={classNames('w-10', tableTheadTd, tableHiddenColumnMd)} />
          </tr>
        </thead>
        <tbody className={tableTbody}>
          {(orders ?? []).map(order => (
            <tr
              className={tableTbodyTr}
              key={order.uid}
              onClick={() => navigate(`${generatePath(AppRoutes.SemenOrderDetails.path, { uid: order.uid })}?${navBackToThisPage()}`)}
            >
              <td className={classNames('text-center w-10', tableHiddenColumnMd)}>
                <ShoppingCart size={24} weight='light' className='inline' />
              </td>
              <td className={tableHiddenColumnMd}>{order.created_on && formatDate(new Date(Date.parse(order.created_on)))}</td>
              <td className={tableHiddenColumnMd}>{order.uid}</td>
              <td className={tableHiddenColumnMd}>
                <span className='mr-1'>{order.shipping_date && formatDate(new Date(Date.parse(order.shipping_date)))}</span>
                <Badge size={BadgeSize.Normal}>{shippingServiceType(t, products ?? [], categories ?? [], order)}</Badge>
              </td>
              <td className={tableHiddenColumnMd}>
                <OrderListPaymentStatus order={order} />
              </td>
              <td className={classNames('w-10 text-center', tableHiddenColumnMd)}>
                <CaretRight size={22} weight='light' className='inline' />
              </td>

              <td className='md:hidden'>
                <div className='flex items-center px-2 gap-2'>
                  <ShoppingCart size={24} weight='light' className='inline' />
                  <div className='grow flex flex-col'>
                    <span className='line-clamp-1'>{order.uid}</span>
                    <span className='text-xs'>{order.created_on && formatDate(new Date(Date.parse(order.created_on)))}</span>
                  </div>
                  <div className='flex flex-col items-end gap-0.5'>
                    <OrderListPaymentStatus order={order} />
                  </div>
                  <CaretRight size={22} weight='light' className='inline' />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {orders && orderCount > SHOWED_COUNT && (
        <div className='mt-2 flex w-full justify-center'>
          <Button
            onClick={() =>
              navigate(`${generatePath(AppRoutes.SemenOrderList.path)}?search_contact_uid=${contact.uid}&${navBackToThisPage()}`)
            }
          >
            {t('show-all-n-invoices', 'Show all {{orderCount}} orders', { orderCount })}
          </Button>
        </div>
      )}
    </Tile>
  );
}

export default OrdersTile;

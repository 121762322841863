import classNames from 'classnames';
import PedigreeEntry from 'components/Pedigree/PedigreeEntry';
import PedigreeHierarchyList from 'components/Pedigree/PedigreeHierarchyList';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import { HorseDetail } from 'openapi';
import React from 'react';

interface Props {
  horse: HorseDetail | undefined;
  showLevels?: 1 | 2;
  onClick?: () => void;
  // The entry classname when <PedigreeEntry entryClassName={entryClassName} /> is used
  entryClassName?: string;
}

/**
 * The pedigree component shows the pedigree for a given horse.
 *
 * It is also responsive, meaning: it shows by default the pedigree horizontal
 * but on smaller screens it shows the pedigree vertical.
 *
 * It can show the levels for the horizontal view and will bypass the responsive
 * check, so it shows the pedigree at all time horizontal
 */
function Pedigree({ horse, showLevels, onClick, entryClassName }: Props): JSX.Element {
  const { width } = useScreenSize();

  if (!horse) {
    return <></>;
  }

  // show a hierarchy list when we are on a smaller screens
  // but only if we did not set the levels
  if (showLevels === undefined && width <= ScreenSize.md) {
    return (
      <div
        onClick={onClick}
        className={classNames({
          'cursor-pointer': onClick !== undefined,
        })}
      >
        <PedigreeHierarchyList
          classnameLi='pl-[50px] after:!top-[35px] last:before:!h-[35px]'
          sire={horse.sire}
          siresire={horse.siresire}
          siregrandsire1={horse.siregrandsire1}
          siregranddam1={horse.siregranddam1}
          siredam={horse.siredam}
          siregranddam2={horse.siregranddam2}
          siregrandsire2={horse.siregrandsire2}
          dam={horse.dam}
          damsire={horse.damsire}
          damgrandsire1={horse.damgrandsire1}
          damgranddam1={horse.damgranddam1}
          damdam={horse.damdam}
          damgrandsire2={horse.damgrandsire2}
          damgranddam2={horse.damgranddam2}
        />
      </div>
    );
  }

  if (showLevels === 1) {
    return (
      <div
        onClick={onClick}
        className={classNames({
          'cursor-pointer': onClick !== undefined,
        })}
      >
        <PedigreeEntry childName={horse.name} sire={horse.sire} dam={horse.dam} entryClassName={entryClassName} />
      </div>
    );
  }

  if (showLevels === 2) {
    return (
      <div
        onClick={onClick}
        className={classNames({
          'cursor-pointer': onClick !== undefined,
        })}
      >
        <PedigreeEntry
          childName={horse.name}
          sire={
            <PedigreeEntry
              childName={horse.sire}
              sire={horse.siresire}
              dam={horse.siredam}
              entryClassName={entryClassName}
              placeholder='no sire'
            />
          }
          dam={
            <PedigreeEntry
              childName={horse.dam}
              sire={horse.damsire}
              dam={horse.damdam}
              entryClassName={entryClassName}
              placeholder='no dam'
            />
          }
          entryClassName={entryClassName}
        />
      </div>
    );
  }

  return (
    <div
      onClick={onClick}
      className={classNames({
        'cursor-pointer': onClick !== undefined,
      })}
    >
      <PedigreeEntry
        childName={horse.name}
        sire={
          <PedigreeEntry
            childName={horse.sire}
            sire={
              <PedigreeEntry
                childName={horse.siresire}
                dam={horse.siregranddam1}
                sire={horse.siregrandsire1}
                entryClassName={entryClassName}
                placeholder='no sire'
              />
            }
            dam={
              <PedigreeEntry
                childName={horse.siredam}
                dam={horse.siregranddam2}
                sire={horse.siregrandsire2}
                entryClassName={entryClassName}
                placeholder='no dam'
              />
            }
            entryClassName={entryClassName}
            placeholder='no sire'
          />
        }
        dam={
          <PedigreeEntry
            childName={horse.dam}
            sire={
              <PedigreeEntry
                childName={horse.damsire}
                dam={horse.damgranddam1}
                sire={horse.damgrandsire1}
                entryClassName={entryClassName}
                placeholder='no sire'
              />
            }
            dam={
              <PedigreeEntry
                childName={horse.damdam}
                dam={horse.damgranddam2}
                sire={horse.damgrandsire2}
                entryClassName={entryClassName}
                placeholder='no dam'
              />
            }
            entryClassName={entryClassName}
            placeholder='no dam'
          />
        }
        entryClassName={entryClassName}
      />
    </div>
  );
}

export default Pedigree;

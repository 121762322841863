import { CacheKey, PaginatedList } from 'api/ApiCache';
import ApiErrorParser, { ApiErrorParserBase, ApiErrorType } from 'api/ApiErrorParser';
import { CancelablePromise } from 'openapi';
import { Dispatch, SetStateAction } from 'react';

export interface ApiPromiseError {
  id: string;
  hasDataFromCache: boolean;
  error: ApiErrorParserBase;
}

export interface ApiPromiseErrors {
  errors: ApiPromiseError[];
}

const cacheKeyToString = (cacheKey: CacheKey): string => {
  return `${cacheKey.userUid}/${cacheKey.organizationUid}/${cacheKey.name}`;
};

// A class to collect promises to provide a centralized result in api promises.
// This is mainly used in the Page component.
export class ApiPromises {
  private promises = new Map<string, CancelablePromise<any>>(); //eslint-disable-line
  private paginatedApiPromise: PaginatedApiPromiseBase | undefined;
  private fetchAheadPaginatedApiPage: CancelablePromise<any> | undefined; //eslint-disable-line
  private openApiPromises = new Map<string, ApiPromiseBase>();

  // Promise id's that have a cached version loaded. This helps us determine if
  // an api error for the id is fatal or not.
  private hasCacheIds: string[] = [];

  /**
   * In this class we can have one 'paginated promise'. This can be attached to
   * the `PullToScroll` wrapper. This makes it easy to fetch paginated results.
   * @param id Unique identifier for this open api request.
   * @param promiseCallback The OpenApi service promise. Should return a Promise for the given page.
   * @param setState Is called with the result of the promise.
   * @param reportAsEmptyList Set to true if an empty result of the promise should toggle the hasEmptyListResult getter.
   */
  public setPaginated<T extends object>(
    id: string,
    promiseCallback: (page: number) => CancelablePromise<PaginatedList<T>>,
    setState: Dispatch<SetStateAction<T[] | undefined>> | Dispatch<SetStateAction<T[]>>,
    reportAsEmptyList?: boolean,
  ): void {
    this.paginatedApiPromise = new PaginatedApiPromise<T>(id, setState, promiseCallback, reportAsEmptyList);
    // We fetch ahead one page. This result can be added to the promises and thus
    // the error handling. This way we can see if the first page gives an error,
    // then if so, do the proper error handling.
    this.fetchAheadPaginatedApiPage = this.paginatedApiPromise.nextPage();
    this.promises.set(id, this.fetchAheadPaginatedApiPage);
  }

  /**
   * Add an OpenApi promise for an array of T
   * @param id Unique identifier for this open api request.
   * @param promiseCallback The OpenApi service promise.
   * @param setState Is called with the result of the promise.
   * @param cacheKey Optional cache key if we want to cache the result.
   * @param reportAsEmptyList Set to true if an empty result of the promise should toggle the hasEmptyListResult getter.
   * @param setCount a setState for the count of the list, if the promise returns a paginated list.
   */
  public appendList<T extends object>(
    id: string,
    promiseCallback: () => CancelablePromise<PaginatedList<T>> | CancelablePromise<T[]>,
    setState: Dispatch<SetStateAction<T[] | undefined>> | Dispatch<SetStateAction<T[]>>,
    cacheKey?: CacheKey,
    reportAsEmptyList?: boolean,
    setCount?: Dispatch<SetStateAction<number | undefined>> | Dispatch<SetStateAction<number>>,
  ): void {
    if (this.promises.has(id)) {
      throw Error(`Promise with id ${id} is already added to the ApiPromises.`);
    }

    const apiPromise = new ListApiPromise<T>(id, promiseCallback, setState, reportAsEmptyList, cacheKey, setCount);
    if (apiPromise.hasCacheLoaded) {
      this.hasCacheIds.push(id);
    }
    this.promises.set(id, apiPromise.load());
    this.openApiPromises.set(id, apiPromise);
  }

  /**
   * Overload method for appendList. Which can easier be used by predefined api requests.
   */
  public appendListObj<T extends object>(
    id: string,
    setState: Dispatch<SetStateAction<T[] | undefined>> | Dispatch<SetStateAction<T[]>>,
    params: {
      promiseCallback: () => CancelablePromise<PaginatedList<T>> | CancelablePromise<T[]>;
      cacheKey?: CacheKey;
    },
  ): void {
    this.appendList<T>(id, params.promiseCallback, setState, params.cacheKey);
  }

  /**
   * Add an OpenApi promise for a single result. Not an array of results.
   * @param id Unique identifier for this open api request.
   * @param promiseCallback The OpenApi service promise.
   * @param setState Is called with the result of the promise.
   */
  public appendSingle<T extends object>(
    id: string,
    promiseCallback: () => CancelablePromise<T>,
    setState: Dispatch<SetStateAction<T | undefined>> | Dispatch<SetStateAction<T>>,
  ): void {
    if (this.promises.has(id)) {
      throw Error(`Promise with id ${id} is already added to the ApiPromises.`);
    }

    const apiPromise = new SingleApiPromise<T>(id, promiseCallback, setState);
    this.promises.set(id, apiPromise.load());
    this.openApiPromises.set(id, apiPromise);
  }

  public cancel(): void {
    for (const [, promise] of this.promises) {
      promise.cancel();
    }
  }

  // Returns true if one of the given promises has `reportAsEmptyList` set to true
  // and the result of that promise is an empty list.
  public get hasEmptyListResult(): boolean {
    if (this.paginatedApiPromise && this.paginatedApiPromise.emptyList) {
      return true;
    }
    for (const [, apiPromise] of this.openApiPromises) {
      if (apiPromise.emptyList) {
        return true;
      }
    }
    return false;
  }

  // Waits for all the promises to be resolved. Throws a ApiPromiseErrors on
  // failure. Errors in the ApiPromiseErrors object are sorted by severity.
  public async watchAll(nonCachedOnly = false): Promise<void> {
    const errors: { id: string; hasDataFromCache: boolean; error: ApiErrorParserBase }[] = [];
    for (const [id, promise] of this.promises) {
      if (nonCachedOnly && this.hasCacheIds.includes(id)) {
        continue;
      }
      try {
        await promise;
      } catch (e) {
        const error = new ApiErrorParser(e);
        if (error.errorType !== ApiErrorType.CancelError) {
          errors.push({ id, error: error, hasDataFromCache: this.hasCacheIds.includes(id) });
        }
      }
    }
    if (errors.length === 0) {
      return;
    }
    errors.sort((a, b) => {
      if (a.hasDataFromCache !== b.hasDataFromCache) {
        return a.hasDataFromCache ? -1 : 1;
      }
      if (a.error.errorType < b.error.errorType) {
        return -1;
      }
      if (a.error.errorType > b.error.errorType) {
        return 1;
      }
      return 0;
    });
    const grouped: ApiPromiseErrors = { errors };
    throw grouped;
  }

  // Returns true if we have a paginated promise set.
  public get isUsingPagination(): boolean {
    return this.paginatedApiPromise !== undefined;
  }

  public async loadMore(): Promise<void> {
    if (this.paginatedApiPromise) {
      if (this.fetchAheadPaginatedApiPage) {
        // If we have a fetch ahead result available, then return that.
        const copy = this.fetchAheadPaginatedApiPage;
        this.fetchAheadPaginatedApiPage = undefined;
        return copy;
      }
      return this.paginatedApiPromise.nextPage();
    }
    console.error('Trying to do a loadMore without a paginatedApiPromise');
  }
  public canFetchMore(): boolean {
    if (this.paginatedApiPromise) {
      return this.paginatedApiPromise.canFetchMore();
    }
    console.error('Trying to do a canFetchMore without a paginatedApiPromise');
    return false;
  }

  public refresh(): void {
    const promises = new Map<string, CancelablePromise<any>>(); //eslint-disable-line
    if (this.paginatedApiPromise) {
      const promise = this.paginatedApiPromise.refresh();
      this.fetchAheadPaginatedApiPage = promise;
      promises.set(this.paginatedApiPromise.id, promise);
    }
    for (const [id, apiPromise] of this.openApiPromises) {
      promises.set(id, apiPromise.refresh());
    }
    this.promises = promises;
  }
}

interface ApiPromiseBase {
  refresh: () => CancelablePromise<any>; //eslint-disable-line
  emptyList: boolean; // Returns true if we have an empty list result and reportAsEmptyList was set to true.
}
interface PaginatedApiPromiseBase extends ApiPromiseBase {
  id: string;
  nextPage: () => CancelablePromise<any>; //eslint-disable-line
  canFetchMore: () => boolean;
}

// A helper class for paginated promises.
class PaginatedApiPromise<T> implements PaginatedApiPromiseBase {
  private pageNumber = 0;
  private allPagesLoaded = false;
  private emptyResult: boolean | undefined = undefined;

  constructor(
    public readonly id: string,
    private setState: Dispatch<SetStateAction<T[] | undefined>> | Dispatch<SetStateAction<T[]>>,
    private apiRequest: (page: number) => CancelablePromise<PaginatedList<T>>,
    public readonly reportAsEmptyList: boolean = false,
  ) {}

  public get emptyList(): boolean {
    if (!this.reportAsEmptyList) {
      return false;
    }
    return this.emptyResult ?? false;
  }

  public canFetchMore(): boolean {
    return !this.allPagesLoaded;
  }

  public nextPage(): CancelablePromise<PaginatedList<T>> {
    if (this.allPagesLoaded) {
      throw Error('All pages have been loaded');
    }
    this.pageNumber += 1;
    const promise = this.apiRequest(this.pageNumber);
    promise
      .then(result => {
        if (this.pageNumber === 1) {
          if (result.count === 0) {
            this.emptyResult = true;
          }
          this.setState(result.results);
        } else {
          this.setState((prevState?: T[]) => {
            if (!prevState) {
              return result.results;
            }
            return result.results ? [...prevState, ...result.results] : prevState;
          });
        }

        if (!result.next) {
          this.allPagesLoaded = true;
        }
      })
      .catch(() => {
        // Nothing to catch here.
      });
    return promise;
  }

  // Full reload from page 1.
  public refresh(): CancelablePromise<PaginatedList<T>> {
    this.pageNumber = 0;
    this.allPagesLoaded = false;
    this.setState([]);
    return this.nextPage();
  }
}

class ListApiPromise<T> implements ApiPromiseBase {
  public readonly hasCacheLoaded: boolean = false;
  private emptyResult: boolean | undefined = undefined;

  constructor(
    public readonly id: string,
    private apiRequest: () => CancelablePromise<PaginatedList<T>> | CancelablePromise<T[]>,
    private setState: Dispatch<SetStateAction<T[] | undefined>> | Dispatch<SetStateAction<T[]>>,
    public readonly reportAsEmptyList: boolean = false,
    private cacheKey?: CacheKey,
    private setCount?: Dispatch<SetStateAction<number | undefined>> | Dispatch<SetStateAction<number>>,
  ) {
    // Set the cached data into the given setState function
    if (cacheKey && setState) {
      const key = cacheKeyToString(cacheKey);
      const cachedData = sessionStorage?.getItem(key);
      if (cachedData) {
        try {
          console.log(`Loading api data from cache with key ${key}`);
          const data = JSON.parse(cachedData);
          setState(data);
          if (data.length > 0) {
            this.hasCacheLoaded = true;
          }
        } catch (e) {
          console.error(`Failed to load cached api data with key ${key}. Resetting cache.`);
          sessionStorage?.removeItem(key);
        }
      }
    }
  }

  public get emptyList(): boolean {
    if (!this.reportAsEmptyList) {
      return false;
    }
    return this.emptyResult ?? false;
  }

  public refresh(): CancelablePromise<PaginatedList<T>> | CancelablePromise<T[]> {
    return this.load();
  }

  public load(): CancelablePromise<PaginatedList<T>> | CancelablePromise<T[]> {
    const promise = this.apiRequest();
    promise
      .then(data => {
        if ('count' in data && 'results' in data) {
          const res = <PaginatedList<T>>data;
          if (!Array.isArray(res.results)) {
            console.error('Sanity check failed. `results` field is not of array type');
            return;
          }
          if (!Number.isInteger(res.count)) {
            console.error('Sanity check failed. `count` field is not of number type');
            return;
          }
          if (this.cacheKey) {
            sessionStorage?.setItem(cacheKeyToString(this.cacheKey), JSON.stringify(res.results));
          }
          if (data.count === 0) {
            this.emptyResult = true;
          }
          this.setState(res.results);
          this.setCount?.(res.count);
        } else if (Array.isArray(data)) {
          if (this.cacheKey) {
            sessionStorage?.setItem(cacheKeyToString(this.cacheKey), JSON.stringify(data));
          }
          if (data.length === 0) {
            this.emptyResult = true;
          }
          this.setState(data);
        } else {
          console.error(`Failed to set state on ApiPromise finished for id '${this.id}'.`);
        }
      })
      .catch(() => {
        // No need to handle. The promises are grouped returned in the watch method.
      });
    return promise;
  }
}

class SingleApiPromise<T> implements ApiPromiseBase {
  constructor(
    public readonly id: string,
    private apiRequest: () => CancelablePromise<T>,
    private setState: Dispatch<SetStateAction<T | undefined>> | Dispatch<SetStateAction<T>>,
  ) {}

  public refresh(): CancelablePromise<T> {
    return this.load();
  }

  // This field is not applicable for single api promise requests. Only for list requests.
  public get emptyList(): boolean {
    return false;
  }

  public load(): CancelablePromise<T> {
    const promise = this.apiRequest();
    promise
      .then(data => this.setState(data))
      .catch(() => {
        // No need to handle. The promises are grouped returned in the watch method.
      });
    return promise;
  }
}

import React, { createContext, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useConfig } from './ConfigContext';
import { useLocation } from 'react-router-dom';

interface Props {
  remoteGitHash?: string;
  localGitHash?: string;
  children: React.ReactNode;
}

type DeploymentContextType = {
  remoteGitHash?: string;
  localGitHash?: string;
};

interface GitHash {
  commitHash: string;
}

export enum DeploymentPlatform {
  Web,
  iOS,
  Android,
}

const DeploymentContext = createContext<DeploymentContextType>({
  remoteGitHash: undefined,
  localGitHash: undefined,
});

export function useDeploymentContext(): DeploymentContextType {
  return useContext(DeploymentContext);
}

/**
 * Context for information about the local and remote version of this app.
 */
export function DeploymentProvider({ children }: Props): JSX.Element {
  const { config } = useConfig();

  // The git hash known at Vercel
  const [remoteGitHash, setRemoteGitHash] = useState<string>();

  // The local git hash which is running right now.
  const [localGitHash, setLocalGitHash] = useState<string>();

  const location = useLocation();

  const platform = useMemo((): DeploymentPlatform => {
    // TODO: Capacitor.getPlatform() === 'web', 'ios', 'android'
    return DeploymentPlatform.Web;
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    fetch('/commit-hash.json', { method: 'GET', signal: abortController.signal })
      .then(response => {
        if (!response.ok) {
          console.error('Failed to get local git hash');
          return;
        }
        response
          .json()
          .then(json => setLocalGitHash((json as GitHash).commitHash))
          .catch(e => console.error('Failed to get local git hash', e));
      })
      .catch(e => {
        if (!abortController.signal.aborted) {
          console.error('Failed to get local git hash', e);
        }
      });
    return () => abortController.abort();
  }, []);

  const getRemoteGitHash = useCallback(async () => {
    try {
      const response = await fetch('/api/version', { method: 'GET' });
      if (!response.ok) {
        console.error('Failed to get git hash from vercel api');
        return;
      }
      const result = (await response.json()) as GitHash;
      setRemoteGitHash(result.commitHash);
    } catch (e) {
      console.error('Failed to get remote Vercel git hash');
    }
  }, [setRemoteGitHash]);

  // Refresh the remote git hash
  useEffect(() => {
    if (!config?.hasServerlessFunctions) {
      console.log('No serverless functions, cannot check for remote deployment updates.');
      return;
    }

    // First run after 5 seconds. No need to get it directly,
    // we're already pretty busy at the initial page load.
    const timeout = setTimeout(() => getRemoteGitHash(), 5000);

    // Then refresh every hour.
    const interval = setInterval(
      () => getRemoteGitHash(),
      60 * 60 * 1000, // Every hour
    );

    // Cleanup
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [config, getRemoteGitHash]);

  // Do a full page reload when we change routes and an update is available.
  useLayoutEffect(() => {
    if (platform !== DeploymentPlatform.Web) {
      // Only reload when we're at web.
      return;
    }
    const updateAvailable = localGitHash !== undefined && remoteGitHash !== undefined && localGitHash !== remoteGitHash;
    if (!location.state && updateAvailable) {
      window.location.reload(); // refresh the browser
    }
  }, [location]); // eslint-disable-line

  return <DeploymentContext.Provider value={{ remoteGitHash, localGitHash }}>{children}</DeploymentContext.Provider>;
}

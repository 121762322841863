import React, { ReactNode } from 'react';
import { Control, FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import Toggle from 'ui/Toggle';
import classNames from 'classnames';

/**
 * Complex type that extend some magic types from React Form hooks and include own fields
 *
 * See --> https://github.com/orgs/react-hook-form/discussions/7851#discussioncomment-2219298
 */
interface Props<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>
  extends UseControllerProps<TFieldValues, TName> {
  name: TName; // override from UseControllerProps and make it required
  control: Control<TFieldValues>; // override from UseControllerProps and make it required
  label?: string;
  hint?: ReactNode;
  error?: string;
  required?: boolean;
  className?: string;
}

/**
 * Create a Toggle input
 */
function TogggleInput<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  label,
  hint,
  error,
  required,
  className,
  ...props
}: Props<TFieldValues, TName>): JSX.Element {
  const { field } = useController({
    name: props.name,
    control: props.control,
    defaultValue: props.defaultValue,
    rules: props.rules,
    shouldUnregister: props.shouldUnregister,
  });

  return (
    <div className={className}>
      {label && (
        <label className='block text-sm font-medium leading-4 text-gray-600'>
          {label} {required && '*'}
        </label>
      )}

      <Toggle className='my-1' active={field.value} onToggle={active => field.onChange(active)} />

      {hint && <label className='block text-xs leading-3 text-gray-500 mt-1'>{hint}</label>}
      {error && (
        <label
          className={classNames('block text-xs leading-3 text-red-500 transition-all duration-200', {
            'h-auto opacity-100  mt-1': !!error,
            'h-0 opacity-0': !error,
          })}
        >
          {error || ''}
        </label>
      )}
    </div>
  );
}

export default TogggleInput;

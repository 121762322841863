import { useCallback, useMemo, useState } from 'react';
import { CancelablePromise, ModulePermissionsEnum, ModulePermissionsList, RolesService } from 'openapi';
import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';

interface ReturnType {
  isAdmin: boolean;
  permissions: Set<ModulePermissionsEnum>;
  hasPermission: (permissions: ModulePermissionsEnum[] | ModulePermissionsEnum) => boolean;
  loading: boolean;
  error: ApiErrorParser<ModulePermissionsList> | undefined;
  loadPermissions: () => CancelablePromise<ModulePermissionsList[]> | undefined;
  canAccessStable: (stableUid: string) => boolean;
}

const usePermissions = (): ReturnType => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<ApiErrorParser<ModulePermissionsList> | undefined>();

  const { selectedOrganization, setPermissions, permissions } = useOrganization();

  /**
   * load permissions
   */
  const loadPermissions = useCallback((): CancelablePromise<ModulePermissionsList[]> | undefined => {
    if (selectedOrganization?.uid) {
      setLoading(true);

      const promise = RolesService.rolesModulePermissionsList({
        organisationUid: selectedOrganization?.uid ?? '',
      });
      promise
        .then(modulePermissions => {
          // get only the permissions for the roles where the current user is a member of
          const updatedPermissions = new Set<ModulePermissionsEnum>();
          for (const role of selectedOrganization.me.roles) {
            const modulePermission = modulePermissions.find(modulePermission => modulePermission.uid === role.uid);
            if (modulePermission) {
              for (const permission of modulePermission.module_permissions) {
                updatedPermissions.add(permission.id as ModulePermissionsEnum);
              }
            }
          }

          setPermissions(updatedPermissions);
          setApiError(undefined);
        })
        .catch(e => {
          if (!promise.isCancelled) {
            setApiError(new ApiErrorParser<ModulePermissionsList>(e));
          }
        });
      return promise;
    }
  }, [selectedOrganization, setPermissions]);

  /**
   * Determine if the current user is an admin by checking if the user has the role 'administrator'
   */
  const isAdmin = useMemo((): boolean => {
    return selectedOrganization?.me.roles.find(role => role.default_id === 1) !== undefined;
  }, [selectedOrganization?.me.roles]);

  /**
   * Determine if the current user has a specific permission or is an admin
   */
  const hasPermission = useCallback(
    (givenPermissions: ModulePermissionsEnum[] | ModulePermissionsEnum) => {
      if (Array.isArray(givenPermissions)) {
        return isAdmin || givenPermissions.some(p => permissions.has(p));
      }

      return isAdmin || permissions.has(givenPermissions);
    },
    [isAdmin, permissions],
  );

  /**
   * Determine if the current user can access the given Stable
   */
  const canAccessStable = useCallback(
    (stableUid: string): boolean => {
      return !!selectedOrganization?.me.stables.some(stable => stable.uid === stableUid);
    },
    [selectedOrganization?.me.stables],
  );

  return {
    isAdmin,
    permissions,
    hasPermission,
    loadPermissions,
    loading,
    error: apiError,
    canAccessStable,
  };
};

export default usePermissions;

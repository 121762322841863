import classNames from 'classnames';
import React from 'react';

interface Props {
  badge: number;
  className?: string;
}

function ButtonBadge({ badge, className }: Props): JSX.Element {
  if (badge === 0) return <></>;

  return (
    <div
      className={classNames(
        'w-6 h-6 rounded-full bg-blue-600 focus:ring-blue-300 text-white text-xs flex items-center justify-center',
        className,
      )}
    >
      {badge}
    </div>
  );
}

export default ButtonBadge;

import ApiErrorParser from 'api/ApiErrorParser';
import * as _Jimp from 'jimp';
import { ApiError } from 'openapi';
import React, { useState } from 'react';
import { Area } from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import { Alert } from 'ui/Alert';
import { getImageContentType } from 'utilities/ContentType';
import { Severity } from 'utilities/severity';
import { ButtonVariant } from '../../../ui/Button';
import { PageModal } from '../../../ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from '../../../ui/Modals/PageModal';
import useModal from '../../../ui/Modals/UseModal';
import ImageCrop from '.';

// The following is required otherwise image cropping will fail. See:
// https://github.com/jimp-dev/jimp/issues/1194 for details.
// eslint-disable-next-line
// @ts-ignore
const Jimp = typeof self !== 'undefined' ? self.Jimp || _Jimp : _Jimp;
interface Props {
  modalTitle: string;
  onCropComplete: (file: ArrayBuffer, mimeType: string) => Promise<void>;
}

/**
 * This hook implement cropping a file via the following way.
 * 1. select a file
 * 2. Let the user crop/zoom image
 * 3. Cut out the image (using Jimp)
 * 4. Call the async onCropComplete callback
 * 5. Close the dialog
 */
function useImageCrop({ modalTitle, onCropComplete }: Props): {
  upload: () => void;
  imageModal: JSX.Element;
} {
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageFile, setImageFile] = useState<File>();
  const [area, setArea] = useState<Area>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const { closeModal, modalIsVisible, showModal } = useModal();
  const { t } = useTranslation();

  /**
   * Create a hidden input element and trigger the resize modal on select
   */
  const createInputElement = () => {
    const element = document.createElement('input');
    element.type = 'file';
    element.id = 'UseImageCrop';
    element.accept = 'image/*';
    element.hidden = true;
    element.multiple = false;

    element.onchange = () => {
      if (element !== null && element.files) {
        // If no image selected, bail out
        if (!/^image\//.test(element.files[0].type)) {
          alert(`File ${element.files[0].name} is not an image.`);
        } else {
          setImageUrl(URL.createObjectURL(element.files[0]));
          setImageFile(element.files[0]);
          showModal();
        }

        // remove element
        element.remove();
      }
    };
    document.body.appendChild(element);

    element.click();
  };

  const crop = async (file: File, croppedAreaPixels: Area): Promise<Buffer> => {
    const image = await Jimp.read(Buffer.from(await file.arrayBuffer()));
    image.crop(croppedAreaPixels.x, croppedAreaPixels.y, croppedAreaPixels.width, croppedAreaPixels.height);
    return image.getBufferAsync(Jimp.MIME_JPEG);
  };

  const submit = async (): Promise<void> => {
    if (!imageFile) return;
    if (area) {
      await onCropComplete(await crop(imageFile, area), Jimp.MIME_JPEG);
    } else {
      await onCropComplete(await imageFile.arrayBuffer(), getImageContentType(imageFile.name));
    }
  };

  /**
   * Submit event handler
   */
  const onSubmit = () => {
    if (imageFile) {
      setSubmitting(true);
      submit()
        .then(() => {
          console.info('Successfully saved the image');
          setError(undefined);
          if (imageUrl) {
            URL.revokeObjectURL(imageUrl);
            setImageFile(undefined);
            setImageUrl(undefined);
          }
          closeModal();
        })
        .catch(e => {
          let message = 'unknown error';
          if (e instanceof ApiError) {
            const apiError = new ApiErrorParser(e);
            message = apiError.nonFieldErrorsStrings().join(', ');
          } else if (e instanceof Error) {
            message = e.message;
          }
          setError(message);
          console.error('Failed to save the image', e);
        })
        .finally(() => setSubmitting(false));
    }
  };

  return {
    upload: createInputElement,
    imageModal: (
      <PageModal open={modalIsVisible} width={PageModalWidth.Xs}>
        <PageModalTitle title={modalTitle} onClose={closeModal} />
        <PageModalContent>
          <div className='py-3 space-y-4'>
            {imageUrl && <ImageCrop className='h-96' imageUrl={imageUrl} onCropComplete={res => setArea(res)} />}
            {error && <Alert message={`${t('failed-to-set-image', 'Failed to upload the image.')} ${error}`} severity={Severity.Danger} />}
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              onClick: closeModal,
              variant: ButtonVariant.Default,
              type: 'button',
              text: t('cancel', 'Cancel'),
            },
            {
              onClick: onSubmit,
              variant: ButtonVariant.Primary,
              loading: submitting,
              type: 'submit',
              text: t('save', 'Save'),
            },
          ]}
        />
      </PageModal>
    ),
  };
}

export default useImageCrop;

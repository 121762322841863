import { CacheKey, PaginatedList } from 'api/ApiCache';
import { CancelablePromise, Contact, ContactsService, Horse, HorsesService } from 'openapi';

interface PredefinedApiListRequest<T> {
  promiseCallback: () => CancelablePromise<PaginatedList<T>> | CancelablePromise<T[]>;
  cacheKey?: CacheKey;
}

/**
 * Predefined request for all non-hidden organization contacts.
 */
export const activeContacts = (
  organizationUid: string,
  generateCacheKey: (name: string) => CacheKey,
): PredefinedApiListRequest<Contact> => {
  return {
    promiseCallback: () => {
      return ContactsService.contactsList({
        organisationUid: organizationUid,
        hidden: false,
      });
    },
    cacheKey: generateCacheKey('active-contacts'),
  };
};

/**
 * Predefined request for all non-hidden organization horses.
 */
export const activeOrganizationHorses = (
  organizationUid: string,
  generateCacheKey?: (name: string) => CacheKey,
): PredefinedApiListRequest<Horse> => {
  return {
    promiseCallback: () => {
      return HorsesService.horsesList({
        organisationUid: organizationUid,
        hidden: false,
        hasStable: true,
        o: 'name',
      });
    },
    cacheKey: generateCacheKey ? generateCacheKey('active-organization-horses') : undefined,
  };
};

/**
 * Predefined request for all organization horses. Including the hidden/archived onces.
 */
export const organizationHorses = (
  organizationUid: string,
  generateCacheKey?: (name: string) => CacheKey,
): PredefinedApiListRequest<Horse> => {
  return {
    promiseCallback: () => {
      return HorsesService.horsesList({
        organisationUid: organizationUid,
        hasStable: true,
        o: 'name',
      });
    },
    cacheKey: generateCacheKey ? generateCacheKey('organization-horses') : undefined,
  };
};

import { DotsThreeVertical, DownloadSimple, Info, MapPin, TrashSimple } from '@phosphor-icons/react';
import classNames from 'classnames';
import { AppliedListFilter, ListFilterType } from 'components/Common/ListFilter';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import useListFilter from 'components/Common/ListFilter/useListFilter';
import HorseSelectButton from 'components/Horses/HorseSelectButton';
import RvoDeleteReport from 'components/Horses/RvoDeleteReport';
import RvoReportModal from 'components/Horses/RvoReportModal';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { ArrivalOrDepartureEnum, Contact, ContactsService, Horse, HorseLocationMutation, HorselocationsService } from 'openapi';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  defaultApiPageSize,
  table,
  tableHiddenColumnLg,
  tableHiddenColumnMd,
  tableHiddenColumnXl,
  tableHiddenHeaderMd,
  tableTbody,
  tableTbodyTr,
  tableTbodyTrNoClick,
  tableThead,
  tableTheadTd,
} from 'ui/Const';
import Page from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import useModal from 'ui/Modals/UseModal';
import { ApiPromises } from 'utilities/ApiPromises';
import { organizationHorses } from 'utilities/ApiRequests';
import { getLocationName } from 'utilities/Contact';
import RvoImportHorseLocationSelectionModal from 'components/Horses/RvoImportHorseLocationSelectionModal';
import Button, { ButtonVariant } from 'ui/Button';
import IndeterminateCheckbox from 'ui/IndeterminateCheckbox';
import { AppRoutes } from 'AppRoutes';
import { PageAction } from 'context/PageContext';
import Tooltip from 'ui/Tooltip';
import useSelection from 'hooks/UseSelection';
import DropdownMenu from 'ui/DropdownMenu';

function RvoList(): JSX.Element {
  const [selectedHorseLocationMutations, setSelectedHorseLocationMutations] = useState<Map<string, HorseLocationMutation>>(
    new Map<string, HorseLocationMutation>(),
  );
  const [selectedHorseLocationMutation, setSelectedHorseLocationMutation] = useState<HorseLocationMutation | undefined>();
  const [selectedHorse, setSelectedHorse] = useState<Horse | undefined>();
  const [horses, setHorses] = useState<Horse[]>();
  const [contacts, setContacts] = useState<Contact[]>();
  const [horseLocationMutations, setHorseLocationMutations] = useState<HorseLocationMutation[]>([]);
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { t } = useTranslation();
  const { selectedOrganization, generateCacheKey } = useOrganization();
  const { formatDate } = useAccount();
  const { closeModal: closeReportModal, modalIsVisible: reportModalIsVisible, showModal: showReportModal } = useModal();
  const { closeModal: closeDeleteModal, modalIsVisible: deleteModalIsVisible, showModal: showDeleteModal } = useModal();
  const { closeModal: closeImportModal, modalIsVisible: importModalIsVisible, showModal: showImportModal } = useModal();

  const breadCrumbs = useMemo(() => [AppRoutes.HorsesLocationHistory], []);

  /**
   * A list of types we can filter by.
   */
  const filterTypes = useMemo((): ListFilterType[] | undefined => {
    if (!horses) {
      return undefined;
    }

    // simple horse filter by name
    const horseFilter: ListFilterType = {
      id: 'horse',
      name: t('horses', 'Horses'),
      options: horses.map(horse => ({ id: horse.uid, name: horse.name })),
    };

    return [horseFilter];
  }, [t, horses]);

  const { filters, setFilters } = useListFilter(filterTypes ?? []);

  /**
   * Callback when the user click the toggle button to select all projects
   */
  const selectAll = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedHorseLocationMutations(prevState => {
      if (event.target && event.target.checked) {
        for (const horseLocation of horseLocationMutations) {
          const key = `${horseLocation.uid}-${horseLocation.arrival_or_departure}`;
          prevState.set(key, horseLocation);
        }
        return new Map(Array.from([...prevState]));
      }

      return new Map();
    });
  };

  // load the hook for the selection
  const {
    element: selectionElement,
    enable: enableSelection,
    enabled: selectionEnabled,
    disable: disableSelection,
  } = useSelection({
    selectedItems: selectedHorseLocationMutations.size,
    totalItems: horseLocationMutations.length,
    actions: [
      {
        text: t('delete', 'Delete'),
        disabled: selectedHorseLocationMutations.size === 0,
        onClick: showDeleteModal,
        buttonVariant: ButtonVariant.Danger,
      },
    ],
    onCancel: () => setSelectedHorseLocationMutations(new Map()),
    onSelectAll: selectAll,
  });

  /**
   * Get the selected horse based on the filter (url search query).
   */
  const selectedHorseUid = useMemo((): string | undefined => {
    const foundFilter = filters.find(filter => filter.type.id === 'horse');
    if (!foundFilter) {
      return undefined;
    }
    if (foundFilter.options.length !== 1) {
      return undefined;
    }

    return foundFilter.options[0].id;
  }, [filters]);

  /**
   * Get the selected horse based on the filter.
   */
  const selectedHorseByFilter = useMemo((): Horse | undefined => {
    if (!selectedHorseUid || !horses) return undefined;
    return horses.find(horse => horse.uid === selectedHorseUid);
  }, [horses, selectedHorseUid]);

  /**
   * Filter the RVO authorized contacts
   */
  const rvoAuthorizedContacts = useMemo(() => {
    return (contacts ?? []).filter(contact => contact.machtiging_rvo_gegeven);
  }, [contacts]);

  /**
   * Select a horse
   */
  const selectHorse = useCallback(
    (horse?: Horse) => {
      if (!filterTypes) {
        return;
      }

      const foundFilterType = filterTypes.find(f => f.id === 'horse');
      if (!foundFilterType) {
        // should never happen.
        console.error('Filter type not found');
        return;
      }

      // The selection is cleared.
      if (!horse) {
        setFilters([]);
        return;
      }

      const horseFilter: AppliedListFilter = {
        type: foundFilterType,
        options: [{ id: horse.uid, name: horse.name }],
      };
      setFilters([horseFilter]);
    },
    [filterTypes, setFilters],
  );

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    if (!selectedOrganization) {
      return promises;
    }

    promises.setPaginated<HorseLocationMutation>(
      'horse-rvo-location',
      page => {
        return HorselocationsService.horselocationsMutationsWithoutReportList({
          locationOrganisationUid: selectedOrganization.uid,
          page,
          pageSize: defaultApiPageSize,
          horseUid: selectedHorseUid,
        });
      },
      setHorseLocationMutations,
    );

    // load the horses
    promises.appendListObj<Horse>('horses', setHorses, organizationHorses(selectedOrganization?.uid, generateCacheKey));

    // Load all contacts, including the removed once.
    promises.appendList<Contact>(
      'contacts',
      () =>
        ContactsService.contactsList({
          organisationUid: selectedOrganization.uid,
        }),
      setContacts,
      generateCacheKey('contacts'),
    );
    setApiPromises(promises);
    return promises;
  }, [selectedOrganization, generateCacheKey, selectedHorseUid]);

  /**
   * Get the horse name
   */
  const getHorseName = useCallback(
    (horseUid: string) => {
      return horses?.find(horse => horse.uid === horseUid)?.name ?? '<unknown>';
    },
    [horses],
  );

  const openModal = useCallback(
    (horseLocationMutation: HorseLocationMutation) => {
      setSelectedHorse(horses?.find(horse => horse.uid === horseLocationMutation.horse_uid));
      setSelectedHorseLocationMutation(horseLocationMutation);
      showReportModal();
    },
    [horses, showReportModal],
  );

  const openDeleteModal = useCallback(
    (horseLocationMutation: HorseLocationMutation) => {
      setSelectedHorse(horses?.find(horse => horse.uid === horseLocationMutation.horse_uid));
      setSelectedHorseLocationMutation(horseLocationMutation);
      showDeleteModal();
    },
    [horses, showDeleteModal],
  );

  /**
   * Callback when the user click the toggle button to select all projects
   */
  const select = (horseLocation: HorseLocationMutation) => {
    setSelectedHorseLocationMutations(prevState => {
      const key = `${horseLocation.uid}-${horseLocation.arrival_or_departure}`;
      if (prevState.has(key)) {
        prevState.delete(key);
      } else {
        prevState.set(key, horseLocation);
      }

      return new Map(Array.from([...prevState]));
    });
  };

  /**
   * Return the context items for the dropdown menu
   */
  const contextItems = useCallback(
    (horseLocationMutation: HorseLocationMutation) => {
      return [
        [
          {
            element: t('remove', 'Remove'),
            className: 'text-red-600',
            onClick: () => openDeleteModal(horseLocationMutation),
          },
        ],
      ];
    },
    [openDeleteModal, t],
  );

  /**
   * Import should be a page action
   */
  const pageAction = useMemo((): PageAction[] => {
    if (selectionEnabled) return [];
    return [
      {
        text: t('remove-reports', 'Remove reports'),
        icon: <TrashSimple />,
        buttonVariant: ButtonVariant.Danger,
        onClick: enableSelection,
      },
      {
        text: t('import-from-rvo', 'Import from RVO'),
        icon: <DownloadSimple />,
        buttonVariant: ButtonVariant.Default,
        onClick: showImportModal,
      },
    ];
  }, [enableSelection, selectionEnabled, showImportModal, t]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization, selectedHorseUid]); //eslint-disable-line

  return (
    <Page title={t('rvo-report-list', 'RVO report list')} loading={apiPromises} breadCrumbs={breadCrumbs} actions={pageAction}>
      {selectionElement}
      <Tile noBoxOnMobile={true}>
        {filterTypes && horses && (
          <FilterWrapper>
            <HorseSelectButton selectedHorse={selectedHorseByFilter} horses={horses} horseSelected={selectHorse} />
          </FilterWrapper>
        )}
        <table className={table}>
          <thead className={tableThead}>
            <tr className={tableHiddenHeaderMd}>
              <td className={classNames('w-10', tableTheadTd)} />
              <td className={classNames(tableTheadTd)}>{t('horse', 'Horse')}</td>
              <td className={classNames(tableTheadTd, tableHiddenColumnXl)}>{t('stable', 'Stable')}</td>
              <td className={classNames(tableTheadTd)}>{t('location', 'Location')}</td>
              <td className={classNames(tableTheadTd)}>{t('type', 'Type')}</td>
              <td className={classNames(tableTheadTd, tableHiddenColumnLg)}>{t('date', 'Date')}</td>
              <td className={classNames(tableTheadTd)}>
                <div className='flex gap-x-1 items-center'>
                  {t('days-left', 'Days left')}
                  <Tooltip content={<>{t('rvo-days-left-desc', 'The days you have left before you need to report this change to RVO')}</>}>
                    <Info />
                  </Tooltip>
                </div>
              </td>
              <td />
            </tr>
          </thead>
          <tbody className={tableTbody}>
            {horseLocationMutations.map(horseLocationMutation => (
              <tr
                className={selectionEnabled ? tableTbodyTr : tableTbodyTrNoClick}
                onClick={() => {
                  if (selectionEnabled) {
                    select(horseLocationMutation);
                  }
                }}
                key={`${horseLocationMutation.uid}-${horseLocationMutation.arrival_or_departure}`}
              >
                <td className={classNames('text-center w-10', tableHiddenColumnMd)}>
                  {selectionEnabled ? (
                    <IndeterminateCheckbox
                      className='justify-center'
                      checked={selectedHorseLocationMutations.has(
                        `${horseLocationMutation.uid}-${horseLocationMutation.arrival_or_departure}`,
                      )}
                      onChange={() => {
                        // as we have a parent onClick, we can ignore this onChange
                        // however, to avoid React Warnings we still add this noop function
                      }}
                      name={`${horseLocationMutation.uid}-${horseLocationMutation.arrival_or_departure}`}
                    />
                  ) : (
                    <MapPin size={22} weight='light' className='inline' />
                  )}
                </td>
                <td className={tableHiddenColumnMd}>{getHorseName(horseLocationMutation.horse_uid)}</td>
                <td className={tableHiddenColumnXl}>{getLocationName(horseLocationMutation.location_uid, contacts)}</td>
                <td className={tableHiddenColumnMd}>
                  {horseLocationMutation.arrival_or_departure === ArrivalOrDepartureEnum.ARRIVAL
                    ? getLocationName(horseLocationMutation.origin_uid, contacts)
                    : getLocationName(horseLocationMutation.destination_uid, contacts)}
                </td>

                <td className={tableHiddenColumnMd}>
                  {horseLocationMutation.arrival_or_departure === ArrivalOrDepartureEnum.ARRIVAL
                    ? t('arrival', 'Arrival')
                    : t('departure', 'Departure')}
                </td>
                <td className={tableHiddenColumnLg}>
                  {horseLocationMutation.arrival_or_departure === ArrivalOrDepartureEnum.ARRIVAL
                    ? formatDate(horseLocationMutation.arrival_date)
                    : formatDate(horseLocationMutation.departure_date ?? '')}
                </td>
                <td className={tableHiddenColumnMd}>{horseLocationMutation.days_left_for_registration}</td>
                <td className={tableHiddenColumnMd} align='right'>
                  {!selectionEnabled && (
                    <>
                      <div className='flex gap-x-0.5 justify-end items-center mr-3'>
                        <Button variant={ButtonVariant.Default} onClick={() => openModal(horseLocationMutation)} compress={true}>
                          {t('report', 'Report')}
                        </Button>
                        <DropdownMenu menuPlacement='right-end' menuItems={contextItems(horseLocationMutation)}>
                          <button className='px-2'>
                            <DotsThreeVertical size={24} weight='bold' className='inline' />
                          </button>
                        </DropdownMenu>
                      </div>
                    </>
                  )}
                </td>
                <td className='md:hidden px-2 py-3 space-y-1'>
                  <div className='flex gap-2 items-center'>
                    {selectionEnabled && (
                      <IndeterminateCheckbox
                        className='justify-center ml-1'
                        checked={selectedHorseLocationMutations.has(
                          `${horseLocationMutation.uid}-${horseLocationMutation.arrival_or_departure}`,
                        )}
                        onChange={() => select(horseLocationMutation)}
                        name={`${horseLocationMutation.uid}-${horseLocationMutation.arrival_or_departure}`}
                      />
                    )}
                    <div className='flex justify-between w-full'>
                      <div>
                        <div className='flex justify-between gap-x-2'>
                          <p>
                            {getHorseName(horseLocationMutation.horse_uid)}{' '}
                            <span className='font-light'>({getLocationName(horseLocationMutation.location_uid, contacts)})</span>
                          </p>
                        </div>
                        <div className='flex gap-x-2 text-sm'>
                          {horseLocationMutation.arrival_or_departure === ArrivalOrDepartureEnum.ARRIVAL ? (
                            <>
                              {t('arrival', 'Arrival')}: {formatDate(horseLocationMutation.arrival_date)}
                            </>
                          ) : (
                            <>
                              {t('departure', 'Departure')}: {formatDate(horseLocationMutation.arrival_date)}
                            </>
                          )}
                          <p>
                            {t('days-left', 'Days left')}
                            {': '}
                            {horseLocationMutation.days_left_for_registration}
                          </p>
                        </div>
                      </div>
                      {!selectionEnabled && (
                        <div className='flex gap-x-0.5 justify-end items-center mr-3'>
                          <Button variant={ButtonVariant.Default} onClick={() => openModal(horseLocationMutation)} compress={true}>
                            {t('report', 'Report')}
                          </Button>
                          <DropdownMenu menuPlacement='right-end' menuItems={contextItems(horseLocationMutation)}>
                            <button className='px-2'>
                              <DotsThreeVertical size={24} weight='bold' className='inline' />
                            </button>
                          </DropdownMenu>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <RvoReportModal
          horse={selectedHorse}
          isVisible={reportModalIsVisible}
          horseLocationMutation={selectedHorseLocationMutation}
          contacts={contacts}
          onRequestCloseModal={closeReportModal}
          onClosed={() => {
            setSelectedHorse(undefined);
            setSelectedHorseLocationMutation(undefined);
            setSelectedHorseLocationMutations(new Map());
          }}
          onSuccess={loadApiData}
        />

        <RvoDeleteReport
          isVisible={deleteModalIsVisible}
          onRequestCloseModal={() => {
            closeDeleteModal();
            setSelectedHorse(undefined);
            setSelectedHorseLocationMutation(undefined);
            setSelectedHorseLocationMutations(new Map());
            disableSelection();
          }}
          onSuccess={loadApiData}
          horseLocationMutations={
            selectedHorseLocationMutation ? [selectedHorseLocationMutation] : Array.from(selectedHorseLocationMutations.values())
          }
        />

        <RvoImportHorseLocationSelectionModal
          isVisible={importModalIsVisible}
          onRequestCloseModal={closeImportModal}
          locations={rvoAuthorizedContacts}
        />
      </Tile>
    </Page>
  );
}

export default RvoList;

import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { VaccinationRule, VaccinationrulesService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { TextInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';

interface Props {
  vaccinationRule?: VaccinationRule;
  isVisibile: boolean;
  onRequestClose: () => void;
  onSaved: (vaccinationRule: VaccinationRule) => void;
}

const schema = schemas.PatchedVaccinationRule.omit({ uid: true, organisation_uid: true });

function SaveVaccinationModal({ isVisibile, onRequestClose, vaccinationRule, onSaved }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();

  const defaultValues = useMemo((): Partial<VaccinationRule> => {
    return {
      name: vaccinationRule?.name,
      months_between_vaccination: vaccinationRule?.months_between_vaccination,
      number_of_days_not_competing_after_vaccination: vaccinationRule?.number_of_days_not_competing_after_vaccination,
      optional_extra_days: vaccinationRule?.optional_extra_days,
    };
  }, [
    vaccinationRule?.name,
    vaccinationRule?.months_between_vaccination,
    vaccinationRule?.number_of_days_not_competing_after_vaccination,
    vaccinationRule?.optional_extra_days,
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm<VaccinationRule>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Function invoked after the modal has been closed
   */
  const onClosed = () => {
    // clear the errors
    clearErrors();
    setApiError(undefined);
    reset();
  };

  /**
   * Define the page title based on the selected Rule
   */
  const pageTitle = useMemo(() => {
    return vaccinationRule ? t('edit-vaccination-rule', 'Edit vaccination rule') : t('add-vaccination-rule', 'Add vaccination rule');
  }, [t, vaccinationRule]);

  /**
   * Submit event handler, update the data via the API
   */
  const onSubmit: SubmitHandler<VaccinationRule> = async (data: VaccinationRule) => {
    if (!selectedOrganization) return;
    setSubmitting(true);

    try {
      let updatedRule: VaccinationRule;

      // update if we deal with an existing rule, otherwise create a new one
      if (vaccinationRule) {
        const promise = VaccinationrulesService.vaccinationrulesPartialUpdate({
          organisationUid: selectedOrganization.uid,
          uid: vaccinationRule.uid,
          requestBody: {
            name: data.name,
            months_between_vaccination: data.months_between_vaccination,
            number_of_days_not_competing_after_vaccination: data.number_of_days_not_competing_after_vaccination,
            optional_extra_days: data.optional_extra_days,
          },
        });
        updatedRule = await promise;
      } else {
        const promise = VaccinationrulesService.vaccinationrulesCreate({
          organisationUid: selectedOrganization.uid,
          requestBody: {
            name: data.name,
            months_between_vaccination: data.months_between_vaccination,
            number_of_days_not_competing_after_vaccination: data.number_of_days_not_competing_after_vaccination,
            optional_extra_days: data.optional_extra_days,

            // TODO @see https://gitlab.qubis.nl/equinem/equinemcore/-/issues/433
            organisation_uid: selectedOrganization.uid,

            // UID is not required for creation but TS is complaining
            // therefore we need to cast it
          } as VaccinationRule,
        });
        updatedRule = await promise;
      }

      // fire the onSaved event
      onSaved(updatedRule);

      // close the modal
      onRequestClose();
    } catch (error) {
      setApiError(new ApiErrorParser<VaccinationRule>(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Reset the form when the default values change
   */
  useEffect(() => {
    if (!isVisibile) return;
    reset(defaultValues);
  }, [defaultValues, isVisibile, reset]);

  return (
    <PageModal
      open={isVisibile}
      parentElement='form'
      parentProps={{ id: 'saveVaccinationRule', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      onClosed={onClosed}
      width={PageModalWidth.Sm}
    >
      <PageModalTitle title={pageTitle} onClose={onRequestClose} />
      <PageModalContent>
        <ErrorSection className='mb-4' errors={nonFieldErrors} />

        <div className='py-3 space-y-5'>
          <TextInput
            label={t('name', 'Name')}
            error={fieldError('name')}
            required={zodInputIsRequired<VaccinationRule>(schema, 'name')}
            {...register('name', { setValueAs: transformEmptyToUndefined() })}
          />

          <div>
            <label className='block text-sm font-medium leading-4 text-gray-600 mb-2 mt-6'>
              {t('period-between-vaccination', 'Period between vaccination')} *
            </label>
            <div className='flex items-center gap-x-4'>
              <TextInput
                className='min-w-0 w-full'
                type='number'
                error={fieldError('months_between_vaccination')}
                required={zodInputIsRequired<VaccinationRule>(schema, 'months_between_vaccination')}
                postText={t('months', 'months')}
                {...register('months_between_vaccination', { valueAsNumber: true })}
              />

              <TextInput
                className='min-w-0 w-full'
                type='number'
                error={fieldError('optional_extra_days')}
                required={zodInputIsRequired<VaccinationRule>(schema, 'optional_extra_days')}
                postText={t('days', 'days')}
                {...register('optional_extra_days', { valueAsNumber: true })}
              />
            </div>
          </div>

          <TextInput
            type='number'
            label={t('number-of-days-not-competing-after-vaccination', 'Number of days not competing after vaccination')}
            error={fieldError('number_of_days_not_competing_after_vaccination')}
            required={zodInputIsRequired<VaccinationRule>(schema, 'number_of_days_not_competing_after_vaccination')}
            postText={t('days', 'days')}
            {...register('number_of_days_not_competing_after_vaccination', { valueAsNumber: true })}
          />
        </div>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            disabled: submitting,
            onClick: onRequestClose,
            variant: ButtonVariant.Default,
            type: 'button',
            text: t('cancel', 'Cancel'),
          },
          {
            formId: 'saveVaccinationRule',
            loading: submitting,
            variant: ButtonVariant.Primary,
            type: 'submit',
            text: t('save', 'Save'),
          },
        ]}
      />
    </PageModal>
  );
}

export default SaveVaccinationModal;

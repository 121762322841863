/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatchedVATPercentageExternalProviderId } from '../models/PatchedVATPercentageExternalProviderId';
import type { VATPercentageExternalProviderId } from '../models/VATPercentageExternalProviderId';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class VatpercentageexternalprovideridsService {
  /**
   * Stores the external_ids for vat_percentages
   * @returns VATPercentageExternalProviderId
   * @throws ApiError
   */
  public static vatpercentageexternalprovideridsList({
    vatPercentageOrganisationUid,
    o,
  }: {
    vatPercentageOrganisationUid: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
  }): CancelablePromise<Array<VATPercentageExternalProviderId>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{vat_percentage__organisation__uid}/vatpercentageexternalproviderids',
      path: {
        'vat_percentage__organisation__uid': vatPercentageOrganisationUid,
      },
      query: {
        'o': o,
      },
    });
  }
  /**
   * Stores the external_ids for vat_percentages
   * @returns VATPercentageExternalProviderId
   * @throws ApiError
   */
  public static vatpercentageexternalprovideridsCreate({
    vatPercentageOrganisationUid,
    requestBody,
  }: {
    vatPercentageOrganisationUid: string,
    requestBody: VATPercentageExternalProviderId,
  }): CancelablePromise<VATPercentageExternalProviderId> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{vat_percentage__organisation__uid}/vatpercentageexternalproviderids',
      path: {
        'vat_percentage__organisation__uid': vatPercentageOrganisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Stores the external_ids for vat_percentages
   * @returns VATPercentageExternalProviderId
   * @throws ApiError
   */
  public static vatpercentageexternalprovideridsRetrieve({
    uid,
    vatPercentageOrganisationUid,
  }: {
    uid: string,
    vatPercentageOrganisationUid: string,
  }): CancelablePromise<VATPercentageExternalProviderId> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{vat_percentage__organisation__uid}/vatpercentageexternalproviderids/{uid}',
      path: {
        'uid': uid,
        'vat_percentage__organisation__uid': vatPercentageOrganisationUid,
      },
    });
  }
  /**
   * Stores the external_ids for vat_percentages
   * @returns VATPercentageExternalProviderId
   * @throws ApiError
   */
  public static vatpercentageexternalprovideridsUpdate({
    uid,
    vatPercentageOrganisationUid,
    requestBody,
  }: {
    uid: string,
    vatPercentageOrganisationUid: string,
    requestBody: VATPercentageExternalProviderId,
  }): CancelablePromise<VATPercentageExternalProviderId> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{vat_percentage__organisation__uid}/vatpercentageexternalproviderids/{uid}',
      path: {
        'uid': uid,
        'vat_percentage__organisation__uid': vatPercentageOrganisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Stores the external_ids for vat_percentages
   * @returns VATPercentageExternalProviderId
   * @throws ApiError
   */
  public static vatpercentageexternalprovideridsPartialUpdate({
    uid,
    vatPercentageOrganisationUid,
    requestBody,
  }: {
    uid: string,
    vatPercentageOrganisationUid: string,
    requestBody?: PatchedVATPercentageExternalProviderId,
  }): CancelablePromise<VATPercentageExternalProviderId> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{vat_percentage__organisation__uid}/vatpercentageexternalproviderids/{uid}',
      path: {
        'uid': uid,
        'vat_percentage__organisation__uid': vatPercentageOrganisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Stores the external_ids for vat_percentages
   * @returns void
   * @throws ApiError
   */
  public static vatpercentageexternalprovideridsDestroy({
    uid,
    vatPercentageOrganisationUid,
  }: {
    uid: string,
    vatPercentageOrganisationUid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{vat_percentage__organisation__uid}/vatpercentageexternalproviderids/{uid}',
      path: {
        'uid': uid,
        'vat_percentage__organisation__uid': vatPercentageOrganisationUid,
      },
    });
  }
}

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Scroll to the top of the page on every page change.
export default function ScrollToTop(): JSX.Element {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <></>;
}

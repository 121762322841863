import React, { useEffect, useState } from 'react';
import Skeleton from 'ui/Layout/Skeleton';
import classNames from 'classnames';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import UsePermissionWidths from 'hooks/UsePermissionWidths';

const LABEL_WIDTHS = ['w-36', 'w-32', 'w-40', 'w-44', 'w-56'];
const HEIGHT = 'h-6';
const NUM_ROWS = 10;

function PermissionsSkeleton(): JSX.Element {
  const [visibleCount, setVisibleCount] = useState<number>(3);

  const { columnWidth, labelWidth } = UsePermissionWidths();
  const { width } = useScreenSize();

  const getRandomWidth = () => {
    return LABEL_WIDTHS[Math.floor(Math.random() * LABEL_WIDTHS.length)];
  };

  // small helper that creates an array of actual numbers
  const fillArray = (length: number) => Array.from({ length }, (_, index) => index);

  /**
   * Set the items to show based on the screen size
   */
  useEffect(() => {
    if (width > ScreenSize.lg) {
      setVisibleCount(3);
    } else if (width > ScreenSize.md) {
      setVisibleCount(2);
    } else {
      setVisibleCount(1);
    }
  }, [width]);

  return (
    <div className='p-5 space-y-5'>
      {fillArray(NUM_ROWS).map(index => (
        <div key={index} className='flex gap-x-3 '>
          <div className='grow' style={{ width: labelWidth }}>
            <Skeleton className={classNames(HEIGHT, getRandomWidth())} />
          </div>

          <div style={{ width: columnWidth }} className='flex justify-center'>
            <Skeleton className={classNames(HEIGHT, 'w-14')} />
          </div>
          {visibleCount >= 2 && (
            <div style={{ width: columnWidth }} className='flex justify-center'>
              <Skeleton className={classNames(HEIGHT, 'w-14')} />
            </div>
          )}
          {visibleCount >= 3 && (
            <div style={{ width: columnWidth }} className='flex justify-center'>
              <Skeleton className={classNames(HEIGHT, 'w-14')} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default PermissionsSkeleton;

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HorseOwner } from '../models/HorseOwner';
import type { PaginatedHorseOwnerList } from '../models/PaginatedHorseOwnerList';
import type { PatchedHorseOwner } from '../models/PatchedHorseOwner';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class HorseownersService {
  /**
   * CRUD a horse's ownership by linking one of the organisation's horses to one of the organisation's contacts.
   * @returns PaginatedHorseOwnerList
   * @throws ApiError
   */
  public static horseownersList({
    horseOrganisationUid,
    contactUid,
    horseUid,
    o,
    ownerSinceGte,
    ownerSinceLte,
    page,
    pageSize,
  }: {
    horseOrganisationUid: string,
    contactUid?: string,
    horseUid?: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    ownerSinceGte?: string,
    ownerSinceLte?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
  }): CancelablePromise<PaginatedHorseOwnerList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{horse__organisation__uid}/horseowners',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
      },
      query: {
        'contact__uid': contactUid,
        'horse__uid': horseUid,
        'o': o,
        'owner_since__gte': ownerSinceGte,
        'owner_since__lte': ownerSinceLte,
        'page': page,
        'page_size': pageSize,
      },
    });
  }
  /**
   * CRUD a horse's ownership by linking one of the organisation's horses to one of the organisation's contacts.
   * @returns HorseOwner
   * @throws ApiError
   */
  public static horseownersCreate({
    horseOrganisationUid,
    requestBody,
  }: {
    horseOrganisationUid: string,
    requestBody: HorseOwner,
  }): CancelablePromise<HorseOwner> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{horse__organisation__uid}/horseowners',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * CRUD a horse's ownership by linking one of the organisation's horses to one of the organisation's contacts.
   * @returns HorseOwner
   * @throws ApiError
   */
  public static horseownersRetrieve({
    horseOrganisationUid,
    uid,
  }: {
    horseOrganisationUid: string,
    uid: string,
  }): CancelablePromise<HorseOwner> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{horse__organisation__uid}/horseowners/{uid}',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * CRUD a horse's ownership by linking one of the organisation's horses to one of the organisation's contacts.
   * @returns HorseOwner
   * @throws ApiError
   */
  public static horseownersUpdate({
    horseOrganisationUid,
    uid,
    requestBody,
  }: {
    horseOrganisationUid: string,
    uid: string,
    requestBody: HorseOwner,
  }): CancelablePromise<HorseOwner> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{horse__organisation__uid}/horseowners/{uid}',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * CRUD a horse's ownership by linking one of the organisation's horses to one of the organisation's contacts.
   * @returns HorseOwner
   * @throws ApiError
   */
  public static horseownersPartialUpdate({
    horseOrganisationUid,
    uid,
    requestBody,
  }: {
    horseOrganisationUid: string,
    uid: string,
    requestBody?: PatchedHorseOwner,
  }): CancelablePromise<HorseOwner> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{horse__organisation__uid}/horseowners/{uid}',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * CRUD a horse's ownership by linking one of the organisation's horses to one of the organisation's contacts.
   * @returns void
   * @throws ApiError
   */
  public static horseownersDestroy({
    horseOrganisationUid,
    uid,
  }: {
    horseOrganisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{horse__organisation__uid}/horseowners/{uid}',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'uid': uid,
      },
    });
  }
}

import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { DetailedHTMLProps, useEffect, useRef } from 'react';

interface Props {
  indeterminate?: boolean;
  className?: string;
  name: string;
}

export default function IndeterminateCheckbox({
  indeterminate,
  className = '',
  disabled,
  ...rest
}: Props & DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>): JSX.Element {
  const ref = useRef<HTMLInputElement>(null!); //eslint-disable-line
  const id = nanoid();

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]); // eslint-disable-line

  return (
    <label
      className={classNames('flex items-center h-full', className, {
        'cursor-pointer': !disabled,
        'cursor-not-allowed': disabled,
      })}
      htmlFor={id}
    >
      <input
        id={id}
        type='checkbox'
        ref={ref}
        className={classNames('cursor-pointer', className, {
          'cursor-pointer': !disabled,
          'cursor-not-allowed': disabled,
        })}
        disabled={disabled}
        {...rest}
      />
    </label>
  );
}

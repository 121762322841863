import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { Contact, ContactsService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { TextInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { objectDiff } from 'utilities/compareObject';
import { transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';

interface Props {
  contact?: Contact;
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onUpdated: (contact: Contact) => void;
}

function UpdateLocationModal({ isVisible, onRequestCloseModal, onUpdated, contact }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();

  // Form validation
  const schema = useMemo(() => {
    return schemas.Contact.pick({
      business_name: true,
      UBN: true,
    }).required({
      // make the business_name required for a company contact
      business_name: true,
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Contact>({
    resolver: zodResolver(schema),
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Submit handler
   */
  const onSubmit = async (data: Contact) => {
    if (!selectedOrganization || !contact) return;

    setSubmitting(true);

    try {
      // generate a diff so we can send only the changed data
      const updatedFields = objectDiff(contact, data);

      const updateContactPromise = ContactsService.contactsPartialUpdate({
        organisationUid: selectedOrganization.uid,
        uid: contact.uid,
        requestBody: updatedFields,
      });

      const updatedContact = await updateContactPromise;

      // fire the onUpdated event
      onUpdated(updatedContact);

      // close the modal
      onRequestCloseModal();
    } catch (error: unknown) {
      setApiError(new ApiErrorParser(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * event that will be fired after the modal has been closed
   */
  const onClosed = () => {
    setSubmitting(false);
    setApiError(undefined);
  };

  /**
   * Event that will be fired when the user closes the modal
   */
  const onClose = () => {
    onRequestCloseModal();
    setSubmitting(false);
    setApiError(undefined);
  };

  /**
   * Update the value of the modal with the contact data
   */
  useEffect(() => {
    reset(contact);
  }, [contact, reset, isVisible]);

  return (
    <PageModal open={isVisible} onClosed={onClosed}>
      <PageModalTitle title={t('update-location', 'Update location')} onClose={onClose} />
      <PageModalContent>
        <form className='w-full space-y-6' id='updateContactForm' noValidate={true} onSubmit={handleSubmit(onSubmit)}>
          <ErrorSection className='mb-4' errors={nonFieldErrors} />

          <div className='grid gap-3 grid-cols-1'>
            <TextInput
              error={fieldError('business_name')}
              required={true}
              label={t('name', 'Name')}
              {...register('business_name', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('UBN')}
              required={zodInputIsRequired<Contact>(schema, 'UBN')}
              label={t('UBN', 'UBN')}
              {...register('UBN')}
            />
          </div>
        </form>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            loading: submitting,
            variant: ButtonVariant.Primary,
            text: t('save', 'Save'),
            type: 'submit',
            formId: 'updateContactForm',
          },
        ]}
      />
    </PageModal>
  );
}

export default UpdateLocationModal;

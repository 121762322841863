import { Barn, DotsThreeVertical, House, Warning } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { Contact, ContactsService, CountryEnum } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AvatarIcon, AvatarSize } from 'ui/Avatar';
import Badge from 'ui/Badge';
import { ButtonVariant } from 'ui/Button';
import { tableTbodyTrNoClick } from 'ui/Const';
import { ActionModal } from 'ui/Modals';
import useModal from 'ui/Modals/UseModal';
import { AllColors } from 'utilities/colors';
import ConnectRvoModal from '../ConnectRvoModal';
import DeleteRvoModal from '../DeleteRvoModal';
import DropdownMenu, { DropdownMenuItemArray } from 'ui/DropdownMenu';
import RvoImportHorseLocationModal from '../../Horses/RvoImportHorseLocationModal';
import { contactAddress, contactName } from 'utilities/Contact';
import { AppRoutes } from 'AppRoutes';
import { generatePath, useNavigate } from 'react-router-dom';
import DeleteLocationModal from '../DeleteLocationModal';
import { Country } from 'hooks/UseCountries/countries.data';

interface Props {
  location: Contact;
  onDeleted?: () => void;
  onContactUpdated?: () => void;
  countries: Country[];
}

function Row({ location, onDeleted, onContactUpdated, countries }: Props): JSX.Element {
  const [isAuthorized, setIsAuthorized] = useState<boolean>();
  const [rvoError, setRvoError] = useState<string | undefined>();

  const { closeModal: closeErrorModal, modalIsVisible: errorModalIsVisible, showModal: showErrorModal } = useModal();
  const { closeModal: closeDeleteRvoModal, modalIsVisible: deleteRvoModalIsVisible, showModal: showDeleteRvoModal } = useModal();
  const { closeModal: closeAuthModal, modalIsVisible: authModalIsVisible, showModal: showAuthModal } = useModal();
  const { closeModal: closeImportModal, modalIsVisible: importModalIsVisible, showModal: showImportModal } = useModal();
  const { modalIsVisible: deleteModalIsVisible, closeModal: closeDeleteModal, showModal: showDeleteModal } = useModal();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedOrganization } = useOrganization();

  const isStable = location.stable_location_uid !== null;

  /**
   * Check if the contact is authorized
   */
  const checkAuthorization = useCallback(
    (organizationUid: string) => {
      const promise = ContactsService.contactsCheckAuthorizationRvoCreate({
        organisationUid: organizationUid,
        uid: location.uid,
      });

      promise
        .then(() => setIsAuthorized(true))
        .catch((e: unknown) => {
          if (!promise.isCancelled) {
            const errors = new ApiErrorParser(e);
            const firstError = errors.nonFieldErrors()[0];
            setIsAuthorized(firstError.code === 'invalid' ? false : true);

            if (firstError.code.startsWith('IRM-')) {
              setRvoError(firstError.message);
            }
          }
        });

      return promise;
    },
    [location.uid],
  );

  /**
   * Build the dropdown with RVO actions included if needed
   */
  const dropdownMenuItems = useMemo((): DropdownMenuItemArray[] => {
    let rvoItems: DropdownMenuItemArray = [];
    let defaultItems: DropdownMenuItemArray = [
      {
        element: t('view-edit', 'View / Edit'),
        onClick: () => navigate(generatePath(AppRoutes.LocationDetail.path, { contactUid: location.uid })),
      },
    ];

    if (isAuthorized === true)
      [
        (rvoItems = [
          {
            element: t('import-horse-location-history', 'Import location history'),
            onClick: showImportModal,
          },
          {
            element: t('disconnect-from-rvo', 'Disconnection from RVO'),
            onClick: showDeleteRvoModal,
          },
        ]),
      ];

    // If the contact is not authorized, add the connect with RVO button
    if (isAuthorized === false) {
      defaultItems = [
        ...defaultItems,
        {
          element: t('connect-with-rvo', 'Connect with RVO'),
          onClick: showAuthModal,
        },
      ];
    }

    return [[...defaultItems], [...rvoItems], [{ element: t('remove', 'Remove'), onClick: showDeleteModal, className: 'text-red-600' }]];
  }, [isAuthorized, location.uid, navigate, showAuthModal, showDeleteModal, showDeleteRvoModal, showImportModal, t]);

  /**
   * On load, check if the contact is authorized
   */
  useEffect(() => {
    // do this only for a NL location
    if (!selectedOrganization || location.country !== CountryEnum.NL) return;

    const promise = checkAuthorization(selectedOrganization.uid);
    return () => promise.cancel();
  }, [checkAuthorization, location.country, selectedOrganization]);

  return (
    <>
      <tr className={tableTbodyTrNoClick} key={location.uid}>
        <td className='text-center w-12 md:w-14'>
          <AvatarIcon size={AvatarSize.Small} icon={isStable ? <Barn /> : <House />} uuid={location.uid} />
        </td>
        <td>
          <div className='flex gap-x-2 items-center'>
            {location.business_name || contactName(location)}
            {isAuthorized === true && !rvoError && <Badge>{t('rvo-connected', 'RVO connected')}</Badge>}
            {isAuthorized === true && rvoError && (
              <button onClick={showErrorModal}>
                <Badge color={AllColors.Red} className='inline-flex cursor-pointer items-center gap-x-1'>
                  <Warning />
                  {t('connected', 'Connected')}
                </Badge>
              </button>
            )}
          </div>
          <p className='hidden md:block text-gray-500 text-sm'>{contactAddress(location, countries).join(', ')}</p>
        </td>

        <td align='right'>
          <div className='flex justify-end gap-x-3'>
            <DropdownMenu menuPlacement='right' menuItems={dropdownMenuItems}>
              <div className='w-full h-full text-center cursor-pointer pt-2 pb-1'>
                <DotsThreeVertical size={24} weight='bold' className='inline' />
              </div>
            </DropdownMenu>
          </div>

          <ActionModal
            open={errorModalIsVisible}
            title={t('rvo-error-modal-title', 'RVO error')}
            actions={[{ text: t('close', 'Close'), variant: ButtonVariant.Default, onClick: closeErrorModal }]}
          >
            <p className='mt-2'>{rvoError}</p>
          </ActionModal>

          <RvoImportHorseLocationModal
            location={location}
            isVisible={importModalIsVisible}
            onRequestCloseModal={closeImportModal}
            onSuccess={() => {
              console.log('to be implemented');
            }}
          />

          <ConnectRvoModal
            isVisible={authModalIsVisible}
            onRequestCloseModal={closeAuthModal}
            onSuccess={() => {
              if (selectedOrganization) {
                setIsAuthorized(undefined);
                checkAuthorization(selectedOrganization.uid);
              }
            }}
            stable={location}
            onContactUpdated={onContactUpdated}
          />

          <DeleteRvoModal
            isVisible={deleteRvoModalIsVisible}
            onRequestCloseModal={closeDeleteRvoModal}
            onSuccess={() => {
              if (selectedOrganization) {
                setIsAuthorized(undefined);
                checkAuthorization(selectedOrganization.uid);
              }
            }}
            stable={location}
          />

          <DeleteLocationModal
            isVisible={deleteModalIsVisible}
            onRequestClose={closeDeleteModal}
            onDeleted={() => onDeleted?.()}
            location={location}
          />
        </td>
      </tr>
    </>
  );
}

export default Row;

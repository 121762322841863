import classNames from 'classnames';
import UsePermissionWidths from 'hooks/UsePermissionWidths';
import { ModulePermissionsEnum, Role } from 'openapi';
import React, { useMemo } from 'react';
import IndeterminateCheckbox from 'ui/IndeterminateCheckbox';

interface PermissionKeyVal {
  name: string;
  label: string;
}

interface Props {
  slideDirection: 'left' | 'right' | undefined;
  currentIndex: number;
  visibleCount: number;
  roles: Role[];
  availablePermissions: PermissionKeyVal[];
  label: string;
  defaultSelectedPermissions: Map<string, Set<string>>;
  stagedPermissions: Map<string, Set<string>>;
  onSelectedPermissionsChange: (roleUid: string, selectedPermissions: Set<string>) => void;
}

function PermissionPerModule({
  slideDirection,
  currentIndex,
  visibleCount,
  roles,
  availablePermissions,
  label,
  defaultSelectedPermissions,
  onSelectedPermissionsChange,
  stagedPermissions,
}: Props): JSX.Element {
  const { arrowWidth, columnWidth, labelWidth } = UsePermissionWidths();

  /**
   * Remove the permissions that are not available in the module
   */
  const defaultSelectedForModule = useMemo(() => {
    const map = new Map<string, Set<string>>();
    defaultSelectedPermissions.forEach((selectedPermissions, roleUid) => {
      const newSet = new Set<string>();
      selectedPermissions.forEach(permission => {
        if (availablePermissions.find(p => p.name === permission)) {
          newSet.add(permission);
        }
      });
      map.set(roleUid, newSet);
    });

    return map;
  }, [availablePermissions, defaultSelectedPermissions]);

  /**
   * Remove the permissions that are not available in the module
   */
  const stagedSelectedForModule = useMemo(() => {
    const map = new Map<string, Set<string>>();
    stagedPermissions.forEach((selectedPermissions, roleUid) => {
      const newSet = new Set<string>();
      selectedPermissions.forEach(permission => {
        if (availablePermissions.find(p => p.name === permission)) {
          newSet.add(permission);
        }
      });
      map.set(roleUid, newSet);
    });

    return map;
  }, [availablePermissions, stagedPermissions]);

  /**
   * Toggle the state of the permission for the role
   */
  const toggle = (roleUid: string, permissionName: string) => {
    let permissions: Set<string>;
    if (stagedSelectedForModule.has(roleUid)) {
      permissions = stagedSelectedForModule.get(roleUid) ?? new Set();
      if (permissions.has(permissionName)) {
        permissions.delete(permissionName);
      } else {
        permissions?.add(permissionName);
      }
    } else {
      permissions = new Set([permissionName]);
    }

    onSelectedPermissionsChange(roleUid, permissions);
  };

  /**
   * Select all permissions
   */
  const selectAll = (roleUid: string) => {
    let permissions: Set<string>;
    permissions = stagedSelectedForModule.get(roleUid) ?? new Set();

    // If all permissions are selected, then we deselect all
    // otherwise we select all
    if (availablePermissions.length === permissions.size) {
      // View horses is a permission that cannot be changed
      // its always selected in the API so we disable it here as well but still
      // show it as selected as reference
      permissions = new Set([ModulePermissionsEnum.VIEW_HORSES]);
    } else {
      permissions = new Set(availablePermissions.map(permission => permission.name));
    }

    onSelectedPermissionsChange(roleUid, permissions);
  };

  const isSelectedPermission = (roleUid: string, permissionName: string) => {
    const selectedPermissions = stagedSelectedForModule.get(roleUid);
    return selectedPermissions?.has(permissionName);
  };

  const isChangedPerPermission = (roleUid: string, permissionName: string) => {
    const foundInSelected = Boolean(stagedSelectedForModule.get(roleUid)?.has(permissionName));
    const foundInGivenSelected = Boolean(defaultSelectedForModule.get(roleUid)?.has(permissionName));
    return foundInGivenSelected !== foundInSelected;
  };

  return (
    <>
      <div key={label} className='flex hover:bg-gray-100 bg-gray-100 md:bg-gray-50'>
        <div style={{ width: labelWidth }} className={classNames('font-bold text-sm sm:text-base py-2 grow shrink-0 pl-1')}>
          {label}
        </div>
        <div className={arrowWidth} />
        {roles.map((role, index) => {
          const selectedPermissions = stagedSelectedForModule.get(role.uid);
          return (
            <div
              key={role.name}
              style={{ width: columnWidth }}
              className={classNames('hidden text-center items-center font-semibold py-2 animate-duration-300', {
                'animate-slideInRight': slideDirection === 'left',
                'animate-slideInLeft': slideDirection === 'right',

                '!block': index >= currentIndex && index < currentIndex + visibleCount,
              })}
            >
              <IndeterminateCheckbox
                name='toggleAll'
                className='justify-center'
                checked={availablePermissions.length === selectedPermissions?.size}
                indeterminate={
                  selectedPermissions && selectedPermissions.size > 0 && availablePermissions.length !== selectedPermissions.size
                }
                onChange={() => selectAll(role.uid)}
              />
            </div>
          );
        })}
        <div className={arrowWidth} />
      </div>

      {availablePermissions.map(permission => (
        <div key={permission.name} className='flex hover:bg-gray-100'>
          <div style={{ width: labelWidth }} className={classNames('text-sm sm:text-base py-2 grow shrink-0 pl-1')}>
            {permission.label}
          </div>
          <div className={arrowWidth} />
          {roles.map((role, index) => {
            const isSelected = isSelectedPermission(role.uid, permission.name);
            const changed = isChangedPerPermission(role.uid, permission.name);
            return (
              <div
                key={role.name}
                style={{ width: columnWidth }}
                className={classNames('hidden text-center items-center font-semibold py-2 animate-duration-300', {
                  '!bg-orange-200/20': changed,
                  'animate-slideInRight': slideDirection === 'left',
                  'animate-slideInLeft': slideDirection === 'right',

                  '!block': index >= currentIndex && index < currentIndex + visibleCount,
                })}
              >
                <IndeterminateCheckbox
                  checked={isSelected === true}
                  // View horses is a permission that cannot be changed
                  // its always selected in the API so we disable it here as well but still
                  // show it as selected as reference
                  disabled={permission.name === ModulePermissionsEnum.VIEW_HORSES}
                  name={role.uid}
                  className='justify-center'
                  onChange={() => toggle(role.uid, permission.name)}
                />
              </div>
            );
          })}
          <div className={arrowWidth} />
        </div>
      ))}
    </>
  );
}

export default PermissionPerModule;

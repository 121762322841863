import { useDeploymentContext } from 'context/DeploymentContext';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface ReturnType {
  // Git hashes for local and remote.
  remoteGitHash?: string;
  localGitHash?: string;
  remoteGitHashShort?: string;
  localGitHashShort?: string;

  // Descriptive text about the deployment status.
  deploymentInfoText: string;

  // Returns true if there is a new deployment available.
  updateAvailable: boolean;
}

export default function useDeployment(): ReturnType {
  const { remoteGitHash, localGitHash } = useDeploymentContext();
  const { t } = useTranslation();

  const deploymentInfoText = useMemo(() => {
    if (!localGitHash) {
      return t('unknown-local-deployment', 'Unknown application deployment.');
    }
    if (!remoteGitHash) {
      return t('unknown-remote-deployment', 'At deployment {{deployment}}', {
        deployment: localGitHash.slice(0, 7),
      });
    }
    if (remoteGitHash !== localGitHash) {
      return t('mismatch-remote-deployment', 'At deployment {{deployment}}, deployment update available.', {
        deployment: localGitHash.slice(0, 7),
      });
    }
    if (remoteGitHash === localGitHash) {
      return t('match-remote-deployment', 'At deployment {{deployment}}, no updates available.', { deployment: localGitHash.slice(0, 7) });
    }
    return '';
  }, [remoteGitHash, localGitHash, t]);

  return {
    remoteGitHash,
    localGitHash,
    remoteGitHashShort: remoteGitHash ? remoteGitHash.slice(0, 7) : undefined,
    localGitHashShort: localGitHash ? localGitHash.slice(0, 7) : undefined,
    deploymentInfoText,
    updateAvailable: localGitHash !== undefined && remoteGitHash !== undefined && localGitHash !== remoteGitHash,
  };
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OAuth2Token } from '../models/OAuth2Token';
import type { OrganisationOAuth } from '../models/OrganisationOAuth';
import type { Redirect } from '../models/Redirect';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OauthService {
  /**
   * This is view that is called on callback from the provider.
   *
   * It is using GET, since it is a callback url and that is how OAuth is set up.
   * @returns void
   * @throws ApiError
   */
  public static apiV5AuthorizeRetrieve({
    provider,
  }: {
    provider: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/{provider}/authorize',
      path: {
        'provider': provider,
      },
      errors: {
        302: `No response body`,
      },
    });
  }
  /**
   * View to build a full authorize callback uri and sets state in the session.
   *
   * In the javascript client you should check response.redirected. If true, open the redirect url (in a new window)
   *
   * The callback url will be of the form: /api/v5/{provider}/authorize
   * @returns Redirect
   * @throws ApiError
   */
  public static apiV5OauthLoginCreate({
    requestBody,
  }: {
    requestBody: OrganisationOAuth,
  }): CancelablePromise<Redirect> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/oauth/login',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns OAuth2Token
   * @throws ApiError
   */
  public static oauthTokensList({
    organisationUid,
    o,
  }: {
    organisationUid: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
  }): CancelablePromise<Array<OAuth2Token>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/oauth/tokens',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'o': o,
      },
    });
  }
  /**
   * @returns OAuth2Token
   * @throws ApiError
   */
  public static oauthTokensRetrieve({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<OAuth2Token> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/oauth/tokens/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public static oauthTokensDestroy({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{organisation__uid}/oauth/tokens/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
}

import { TFunction } from 'i18next';
import { VATPercentageVatLevelEnum } from 'openapi/models/VATPercentageVatLevelEnum';

export const VATPercentageVatLevelToString = (t: TFunction, level: VATPercentageVatLevelEnum): string => {
  switch (level) {
    case VATPercentageVatLevelEnum.ZERO:
      return t('vat-level-zero', 'Zero');
    case VATPercentageVatLevelEnum.SUPER_REDUCED:
      return t('vat-level-super-reduced', 'Super reduced');
    case VATPercentageVatLevelEnum.SECOND_REDUCED:
      return t('vat-level-second-reduced', 'Second reduced');
    case VATPercentageVatLevelEnum.REDUCED:
      return t('vat-level-reduced', 'Reduced');
    case VATPercentageVatLevelEnum.STANDARD:
      return t('vat-level-standard', 'Standard');
  }
};

import { Switch as HeadlessUiSwitch } from '@headlessui/react';
import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import { Spinner } from '../Loading';
import { SpinnerSize } from '../Loading/Spinner';

interface Props {
  onClick: () => void;
  loading?: boolean;
  checked?: boolean;
  srLabel?: string;
}

function Switch({ checked: givenChecked, srLabel, loading, onClick }: Props): JSX.Element {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (givenChecked === undefined) {
      return;
    }

    setEnabled(givenChecked);
  }, [givenChecked]);

  return (
    <HeadlessUiSwitch checked={enabled} onChange={setEnabled} as={Fragment}>
      {({ checked }: { checked: boolean }) => (
        <button
          onClick={onClick}
          className={classNames('relative inline-flex h-6 w-11 items-center rounded-full', {
            'bg-blue-600': checked,
            'bg-gray-200': !checked,
            '!bg-gray-300': loading,
          })}
        >
          {srLabel && <span className='sr-only'>{srLabel}</span>}
          <span
            className={classNames('inline-block h-4 w-4 transform rounded-full bg-white transition', {
              'translate-x-6': checked,
              '!translate-x-[14px]': loading,
              'translate-x-1': !checked,
            })}
          >
            {loading && <Spinner size={SpinnerSize.XSmall} />}
          </span>
        </button>
      )}
    </HeadlessUiSwitch>
  );
}

export default Switch;

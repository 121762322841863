import React from 'react';

interface Props {
  children: React.ReactNode;
}

function Label({ children }: Props): JSX.Element {
  return <label className={'text-sm font-semibold text-gray-600'}>{children}</label>;
}

export default Label;

import classNames from 'classnames';
import React, { useState } from 'react';
import { InvoiceSender } from 'utilities/Contact';

interface Props {
  organization?: InvoiceSender;
  onLogoLoaded?: () => void;
}

/**
 * Logo on an invoice
 */
export default function InvoiceSenderLogoBlock({ organization, onLogoLoaded }: Props): JSX.Element {
  const [logoError, setLogoError] = useState<boolean>(false);

  if (!organization?.logo) {
    onLogoLoaded?.();
    return <></>;
  }

  return (
    <img
      onLoad={onLogoLoaded}
      onError={() => {
        console.error('Failed to load logo for invoice', organization?.logo);
        onLogoLoaded?.();
        setLogoError(true);
      }}
      src={organization.logo}
      className={classNames('absolute w-64 h-24 object-contain object-left-top', { hidden: logoError })}
    />
  );
}

import { Book, DotsThreeVertical, Plus } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useApiPromises from 'api/hooks/useApiPromises';
import classNames from 'classnames';
import { useOrganization } from 'context/OrganizationContext';
import {
  CategoriesService,
  Category,
  OAuth2Token,
  OauthService,
  ProviderEnum,
  PSTypeEnum,
  VATPercentage,
  VatpercentagesService,
} from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { table, tableHiddenColumnMd, tableHiddenHeaderSm, tableTbody, tableTbodyTrNoClick, tableThead, tableTheadTd } from 'ui/Const';
import DropdownMenu from 'ui/DropdownMenu';
import { ErrorSection } from 'ui/Error';
import { Tile } from 'ui/Layout/Tile';
import { ActionModal } from 'ui/Modals';
import useModal from 'ui/Modals/UseModal';
import { ApiPromises } from 'utilities/ApiPromises';
import { integrationName } from 'utilities/Integrations';
import SaveCategoryModal from './SaveCategoryModal';

export default function CategorySettingsTile(): JSX.Element {
  const [categories, setCategories] = useState<Category[]>();
  const [vatPercentages, setVatPercentages] = useState<VATPercentage[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const { t } = useTranslation();
  const { loading: loadingApiPromises } = useApiPromises({ apiPromises });
  const { selectedOrganization } = useOrganization();
  const [integrations, setIntegrations] = useState<OAuth2Token[]>();
  const { showModal: showSaveCategoryModal, closeModal: closeSaveCategoryModal, modalIsVisible: saveCategoryModalIsVisible } = useModal();
  const {
    showModal: showRemoveCategoryModal,
    closeModal: closeRemoveCategoryModal,
    modalIsVisible: removeCategoryModalIsVisible,
  } = useModal();
  const [edit, setEdit] = useState<Category>();
  const [removeCategory, setRemoveCategory] = useState<Category>();
  const [removeCategoryDialogError, setRemoveCategoryDialogError] = useState<ApiErrorParser<void> | undefined>();

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    if (!selectedOrganization) {
      return promises;
    }
    promises.appendList<Category>(
      'categories',
      () =>
        CategoriesService.categoriesList({
          organisationUid: selectedOrganization.uid,
          o: 'p_s_type,name',
        }),
      setCategories,
    );
    promises.appendList<VATPercentage>(
      'vat-percentages',
      () => {
        return VatpercentagesService.vatpercentagesList({
          organisationUid: selectedOrganization.uid,
          o: 'country',
          isActive: true,
        });
      },
      setVatPercentages,
      undefined,
      true,
    );
    promises.appendList<OAuth2Token>(
      'integrations',
      () => {
        return OauthService.oauthTokensList({ organisationUid: selectedOrganization?.uid ?? '' });
      },
      setIntegrations,
    );
    setApiPromises(promises);
    return promises;
  }, [selectedOrganization]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  const tileActions = useMemo(() => {
    return [
      {
        onClick: () => {
          setEdit(undefined);
          showSaveCategoryModal();
        },
        buttonVariant: ButtonVariant.Default,
        icon: <Plus />,
        text: t('add-category', 'Add category'),
      },
    ];
  }, [t, showSaveCategoryModal]);

  const pSTypeEnumToString = useCallback(
    (type: PSTypeEnum) => {
      switch (type) {
        case PSTypeEnum.PURCHASE: {
          return t('purchases', 'Purchases');
        }
        case PSTypeEnum.SALE: {
          return t('sales', 'Sales');
        }
      }
    },
    [t],
  );

  const getLedger = (provider: ProviderEnum, category: Category): string => {
    switch (provider) {
      case ProviderEnum.MONEYBIRD:
        return category.moneybird_ledger_code ? category.moneybird_ledger_code : '-';
      case ProviderEnum.EXACTNL:
        return category.exactnl_ledger_code ? category.exactnl_ledger_code : '-';
      case ProviderEnum.YUKI:
        return category.yuki_ledger_code ? category.yuki_ledger_code : '-';
    }
  };

  const bookkeepingProviders = useMemo((): ProviderEnum[] => {
    return Object.values(ProviderEnum).filter(key => integrations?.find(integration => integration.name === key));
  }, [integrations]);

  return (
    <Tile title={t('categories', 'Categories')} loading={loadingApiPromises} actions={tileActions}>
      <p className='mb-2'>{t('categories-desc', 'Configure your income and expense categories for reporting purposes.')}</p>
      <table className={table}>
        <thead className={tableThead}>
          <tr className={tableHiddenHeaderSm}>
            <td className='w-10' />

            <td className={tableTheadTd}>{t('name', 'Name')}</td>
            <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('financial-category-type', 'Category type')}</td>
            {bookkeepingProviders.map(provider => (
              <td key={provider} className={classNames(tableTheadTd, tableHiddenColumnMd)}>
                <span>{integrationName(t, provider)}</span> {t('financial-ledger', 'ledger')}
              </td>
            ))}
            <td className='w-10 md:hidden' />
          </tr>
        </thead>
        <tbody className={tableTbody}>
          {(categories ?? []).map(category => (
            <tr className={tableTbodyTrNoClick} key={category.uid}>
              <td className='text-center w-10'>
                <Book size={22} weight='light' className='inline' />
              </td>
              <td>{category.name}</td>
              <td>{category.p_s_type ? pSTypeEnumToString(category.p_s_type) : ''}</td>
              {bookkeepingProviders.map(provider => (
                <td key={provider}>{getLedger(provider, category)}</td>
              ))}
              <td className='w-14 pr-2'>
                <DropdownMenu
                  menuPlacement='bottom-end'
                  menuItems={[
                    [
                      {
                        element: t('edit', 'Edit'),
                        onClick: () => {
                          setEdit(category);
                          showSaveCategoryModal();
                        },
                      },
                      {
                        element: t('remove', 'Remove'),
                        className: 'text-red-600',
                        isDisabled: category.default !== null && category.default !== undefined,
                        onClick: () => {
                          setRemoveCategory(category);
                          showRemoveCategoryModal();
                        },
                      },
                    ],
                  ]}
                >
                  <div className='w-full text-right cursor-pointer'>
                    <DotsThreeVertical size={24} weight='bold' className='inline' />
                  </div>
                </DropdownMenu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <SaveCategoryModal
        enabledBookkeepingIntegrations={bookkeepingProviders}
        vatPercentages={vatPercentages ?? []}
        onSaved={loadApiData}
        existingCategory={edit}
        visible={saveCategoryModalIsVisible}
        closeModal={closeSaveCategoryModal}
        onClosed={() => {
          setEdit(undefined);
        }}
      />

      <ActionModal
        open={removeCategoryModalIsVisible}
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: () => {
              closeRemoveCategoryModal();
              setRemoveCategoryDialogError(undefined);
            },
          },
          {
            text: t('remove', 'Remove'),
            variant: ButtonVariant.PrimaryDanger,
            onClick: async () => {
              try {
                await CategoriesService.categoriesDestroy({
                  organisationUid: selectedOrganization?.uid ?? '',
                  uid: removeCategory?.uid ?? '',
                });
              } catch (e) {
                setRemoveCategoryDialogError(new ApiErrorParser(e));
              }
            },
          },
        ]}
        title={t('remove-category-confirm-title', 'Remove category')}
      >
        <>
          <ErrorSection errors={removeCategoryDialogError} />
          <p>
            {t('remove-category-confirm-text', 'Are you sure you want to remove the "{{category_name}}" category?', {
              category_name: removeCategory?.name,
            })}
          </p>
        </>
      </ActionModal>
    </Tile>
  );
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ModulePermissions } from '../models/ModulePermissions';
import type { ModulePermissionsList } from '../models/ModulePermissionsList';
import type { PatchedRole } from '../models/PatchedRole';
import type { Role } from '../models/Role';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RolesService {
  /**
   * Gives back the possible roles.
   * @returns Role
   * @throws ApiError
   */
  public static rolesList({
    organisationUid,
  }: {
    organisationUid: string,
  }): CancelablePromise<Array<Role>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/roles',
      path: {
        'organisation__uid': organisationUid,
      },
    });
  }
  /**
   * Gives back the possible roles.
   * @returns Role
   * @throws ApiError
   */
  public static rolesCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody?: Role,
  }): CancelablePromise<Role> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/roles',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Gives back the possible roles.
   * @returns Role
   * @throws ApiError
   */
  public static rolesRetrieve({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<Role> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/roles/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * Gives back the possible roles.
   * @returns Role
   * @throws ApiError
   */
  public static rolesUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: Role,
  }): CancelablePromise<Role> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/roles/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Gives back the possible roles.
   * @returns Role
   * @throws ApiError
   */
  public static rolesPartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedRole,
  }): CancelablePromise<Role> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/roles/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Gives back the possible roles.
   * @returns void
   * @throws ApiError
   */
  public static rolesDestroy({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{organisation__uid}/roles/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * This action will override the role's exising permissions with those that correspond to the module permission.
   * @returns ModulePermissions
   * @throws ApiError
   */
  public static rolesModulePermissionsUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody: ModulePermissions,
  }): CancelablePromise<ModulePermissions> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/roles/{uid}/module_permissions',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Returns the module permissions of the admin, which by definition are all permissions.
   * @returns ModulePermissionsList
   * @throws ApiError
   */
  public static rolesAllModulePermissionsList({
    organisationUid,
  }: {
    organisationUid: string,
  }): CancelablePromise<Array<ModulePermissionsList>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/roles/all_module_permissions',
      path: {
        'organisation__uid': organisationUid,
      },
    });
  }
  /**
   * Gives back the possible roles.
   * @returns ModulePermissionsList
   * @throws ApiError
   */
  public static rolesModulePermissionsList({
    organisationUid,
  }: {
    organisationUid: string,
  }): CancelablePromise<Array<ModulePermissionsList>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/roles/module_permissions',
      path: {
        'organisation__uid': organisationUid,
      },
    });
  }
}

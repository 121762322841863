import { AppRoutes } from 'AppRoutes';
import ApiErrorParser from 'api/ApiErrorParser';
import SplashWrapper, { WrapperStyle } from 'components/Common/Splash/Wrapper';
import ConfirmAsLoggedInUser from 'components/Users/AcceptInvitation/ConfirmAsLoggedInUser';
import SignupForInvitation from 'components/Users/AcceptInvitation/SignupForInvitation';
import { useAccount } from 'context/AccountContext';
import { CancelablePromise, InvitationsService, ReceivedInvitation } from 'openapi';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button, { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';

export default function ConfirmInvitation(): JSX.Element {
  const [apiErrorInvitation, setApiErrorInvitation] = useState<ApiErrorParser<ReceivedInvitation> | undefined>(undefined);
  const [invitation, setInvitation] = useState<ReceivedInvitation | null>();

  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { accountDetails, logout } = useAccount();
  const navigate = useNavigate();

  /**
   * Load the invitation
   */
  const loadInvitation = useCallback((): CancelablePromise<ReceivedInvitation> => {
    const promise = InvitationsService.apiV5InvitationsRetrieve({
      uuid: searchParams.has('invitation_uuid') ? (searchParams.get('invitation_uuid') as string) : '',
      // TODO https://gitlab.qubis.nl/equinem/equinemcore/-/issues/290
      // status: 'pending',
    });

    promise
      .then(response => setInvitation(response))
      .catch(e => !promise.isCancelled && setApiErrorInvitation(new ApiErrorParser<ReceivedInvitation>(e)));

    return promise;
  }, [searchParams]);

  /**
   * Load the invitation on mount
   */
  useEffect(() => {
    const promise = loadInvitation();
    return () => promise.cancel();
  }, [loadInvitation]);

  // render the error that occurred while loading the invitation from the API
  if (apiErrorInvitation) {
    return (
      <SplashWrapper styleType={WrapperStyle.Anonymous} title={t('invitation-error-title', 'Invitation error')}>
        <ErrorSection errors={apiErrorInvitation} className='my-4' />
        <p>{t('invitation-error-message', 'Due to the following errors we cannot load the invitation.')}</p>

        <Button onClick={() => navigate(accountDetails ? AppRoutes.Home.path : AppRoutes.Login.path)} className='mt-6 w-full'>
          {t('return-to-home', 'Return to home')}
        </Button>
      </SplashWrapper>
    );
  }

  // already logged in BUT this one IS NOT for the user
  if (
    invitation &&
    accountDetails &&
    invitation.accepted_on === null &&
    invitation.declined_on === null &&
    accountDetails.email !== invitation.invitee_email
  ) {
    return (
      <SplashWrapper styleType={WrapperStyle.Anonymous} title={t('invitation-error-invalid-invitation', 'Invalid invitation')}>
        <p className='mt-4'>
          <Trans
            i18nKey='invitation-error-not-for-current-user-message'
            defaults='It seems that this invitation cannot be used because the invitation is not for the current logged in user <strong>{{currentUser}}</strong>.<br /><br /> In order to accept this invitation, please logout the current user <0>{{currentUser}}</0> and login with the correct user.'
            values={{
              currentUser: `${accountDetails.first_name} ${accountDetails.last_name}`,
            }}
          />
        </p>
        <Button variant={ButtonVariant.Primary} onClick={logout} className='mt-4 w-full'>
          {t('logout', 'Logout')}
        </Button>
      </SplashWrapper>
    );
  }

  // already logged in and this invitation IS for the logged in user
  if (
    invitation &&
    accountDetails &&
    invitation.accepted_on === null &&
    invitation.declined_on === null &&
    accountDetails.email === invitation.invitee_email
  ) {
    return (
      <SplashWrapper styleType={WrapperStyle.Anonymous} title={t('accept-invitation', 'Accept invitation')}>
        <ConfirmAsLoggedInUser invitation={invitation} />
      </SplashWrapper>
    );
  }

  // Not logged in, not accepted nor declined
  if (invitation && invitation.accepted_on === null && invitation.declined_on === null) {
    return (
      <SplashWrapper styleType={WrapperStyle.Equinem} title={t('invitation-request', 'Invitation request')}>
        <SignupForInvitation invitation={invitation} />
      </SplashWrapper>
    );
  }

  return <></>;
}

import { Placement } from '@floating-ui/react';
import { Popover, Transition } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import React, { Fragment, ReactNode, useRef } from 'react';
import classNames from 'classnames';

interface Props {
  children?: ReactNode;
  menuPlacement?: Placement;
  content: ReactNode;
  buttonAs?: 'div' | 'button';
  buttonClassName?: string;
  offset?: number; // the offset of the menu
  arrow?: boolean;
}

const timeoutDuration = 120;

/**
 * Small tooltip component that shows a tooltip on hover.
 *
 * currently it trigger an error in safari when the position should repositioned, see https://github.com/ycs77/headlessui-float/issues/119
 */
export default function Tooltip({
  children,
  content,
  menuPlacement,
  buttonAs,
  buttonClassName,
  offset = 10,
  arrow = true,
}: Props): JSX.Element {
  const triggerRef = useRef<HTMLButtonElement>();
  const timeOutRef = useRef<NodeJS.Timeout>();

  const handleEnter = (isOpen: boolean) => {
    clearTimeout(timeOutRef.current);
    !isOpen && triggerRef.current?.click();
  };

  const handleLeave = (isOpen: boolean) => {
    timeOutRef.current = setTimeout(() => {
      isOpen && triggerRef.current?.click();
    }, timeoutDuration);
  };

  return (
    <Popover className='relative'>
      {({ open }) => (
        <Float
          middleware={({ referenceEl }) => {
            triggerRef.current = referenceEl.current as HTMLButtonElement;
            return [];
          }}
          autoUpdate={true}
          arrow={arrow}
          composable={true}
          portal={true}
          autoPlacement={menuPlacement === undefined}
          placement={menuPlacement}
          offset={offset}
        >
          <Float.Reference>
            <Popover.Button
              onMouseEnter={() => handleEnter(open)}
              onMouseLeave={() => handleLeave(open)}
              className={classNames(buttonClassName, 'outline-none')}
              as={buttonAs}
            >
              {children}
            </Popover.Button>
          </Float.Reference>

          <Float.Content>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Popover.Panel className='px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm dark:bg-gray-700'>
                <Float.Arrow className='absolute z-0 bg-gray-900 w-5 h-5 rotate-45 border border-gray-900' />
                <div className='relative z-10 text-sm'>{content}</div>
              </Popover.Panel>
            </Transition>
          </Float.Content>
        </Float>
      )}
    </Popover>
  );
}

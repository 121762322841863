import { Contact, Role, Stable } from 'openapi';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tile } from 'ui/Layout/Tile';
import DescriptionList from 'ui/Layout/DescriptionList';
import { Pencil } from '@phosphor-icons/react';
import { DefinitionItem } from 'ui/Layout/DescriptionList/DescriptionListItem';

interface Props {
  contact: Contact;
  roles?: Role[];
  stables?: Stable[];
  onRequestUpdate: () => void;
}

export default function UserPermissionDetails({ contact, roles = [], stables = [], onRequestUpdate }: Props): JSX.Element {
  const { t } = useTranslation();

  // Convert the list of UIDs to actual names
  const stablesList = useMemo(() => {
    return contact.stables?.map(stableUid => {
      const exist = stables?.find(stable => stable.uid === stableUid);
      return exist ? exist.location.business_name : stableUid;
    });
  }, [contact.stables, stables]);

  const generalDescriptionList = useMemo(() => {
    const list: DefinitionItem[] = [];
    list.push({
      term: t('stables', 'Stables'),
      definition: (
        <ul>
          {stablesList?.map(stableName => (
            <li className='list-disc list-inside' key={stableName}>
              {stableName}
            </li>
          ))}
        </ul>
      ),
    });

    list.push({
      term: t('roles', 'Roles'),
      definition: (
        <ul>
          {contact.roles?.map(roleUid => {
            const roleName = roles.find(role => role.uid === roleUid)?.name ?? t('unknown-role', 'Unknown role');
            return (
              <li className='list-disc list-inside' key={roleUid}>
                {roleName}
              </li>
            );
          })}
        </ul>
      ),
    });

    return list;
  }, [contact, stablesList, t, roles]);

  return (
    <Tile
      title={t('permissions', 'Permissions')}
      className='mb-4'
      actions={[
        {
          icon: <Pencil />,
          text: t('edit', 'Edit'),
          onClick: onRequestUpdate,
        },
      ]}
    >
      <DescriptionList list={generalDescriptionList} />
    </Tile>
  );
}

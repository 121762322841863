import UseCountries from 'hooks/UseCountries';
import { InvoiceDetail } from 'openapi';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { contactAddress, InvoiceSender } from 'utilities/Contact';

interface Props {
  organization?: InvoiceSender;
  invoice?: InvoiceDetail;
}

function InvoiceSenderBlock({ organization, invoice }: Props): JSX.Element {
  // Translate to the language of the invoice.
  const { t: translate, i18n } = useTranslation();
  const t = invoice ? i18n.getFixedT(invoice.language) : translate;
  const { countries } = UseCountries();

  if (!organization) {
    return <></>;
  }
  return (
    <div className='text-right'>
      <p className='font-medium'>{organization.name}</p>
      {contactAddress(organization, countries).map(item => (
        <p key={item}>{item}</p>
      ))}
      {organization.phone_number && <p>{organization.phone_number}</p>}
      {organization.invoice_mail_address && <p className='mt-2'>{organization.invoice_mail_address}</p>}
      <div className='mt-2 inline-flex flex-col gap-1'>
        {organization.bank_code && (
          <p>
            <span className='font-medium text-xs print:text-sm md:text-sm pr-1'>{t('invoice-bank-name', 'Bank name')}</span>
            <span>{organization.bank_name}</span>
          </p>
        )}
        {organization.bank_code && (
          <p>
            <span className='font-medium text-xs print:text-sm md:text-sm pr-1'>{t('invoice-bank-code', 'Bank code')}</span>
            <span>{organization.bank_code}</span>
          </p>
        )}
        <p>
          <span className='font-medium text-xs print:text-sm md:text-sm pr-1'>{t('invoice-account-number', 'Account No')}</span>
          {organization.account_number && <span>{organization.account_number}</span>}
          {!organization.account_number && <span className='opacity-50'>{'XXXXXXXX'}</span>}
        </p>
        {organization.vat_number && (
          <p>
            <span className='font-medium'>{t('invoice-vat', 'Vat')}</span>
            <span>{organization.vat_number}</span>
          </p>
        )}
      </div>
    </div>
  );
}
export default InvoiceSenderBlock;

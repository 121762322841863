import { cachedPaginatedApiData } from 'api/ApiCache';
import ApiErrorParser from 'api/ApiErrorParser';
import Picklist from 'components/Breeding/Picklist';
import PickSemenOrder from 'components/Breeding/PickSemenOrder';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { PageAction, usePage } from 'context/PageContext';
import { addDays, isToday } from 'date-fns';
import useDayNavigationFilter from 'hooks/UseDayNavigationFilter';
import {
  CancelablePromise,
  CategoriesService,
  Category,
  Contact,
  ContactsService,
  Horse,
  HorsesService,
  OrdersService,
  PaginatedProductList,
  PaginatedSupplierOrderList,
  Product,
  ProductsService,
  SemenTypeEnum,
  SupplierOrder,
} from 'openapi';
import { PaginatedContactList } from 'openapi/models/PaginatedContactList';
import { PaginatedHorseList } from 'openapi/models/PaginatedHorseList';
import { SexEnum } from 'openapi/models/SexEnum';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { formatDate } from 'utilities/date.utilities';

export default function PicklistPage(): JSX.Element {
  const { t } = useTranslation();
  const [horses, setHorses] = useState<Horse[]>();
  const [productCategories, setProductCategories] = useState<Category[]>();
  const { selectedOrganization, generateCacheKey: getCacheId } = useOrganization();
  const [contacts, setContacts] = useState<Contact[]>();
  const [pickOrder, setPickOrder] = useState<SupplierOrder | undefined>();
  const [pickOrderModalOpen, setPickOrderModalOpen] = useState<boolean>(false);
  const [orders, setOrders] = useState<SupplierOrder[]>();
  const [nonPickedOlderOrders, setNonPickedOlderOrders] = useState<SupplierOrder[]>();
  const [products, setProducts] = useState<Product[]>();
  // When the selected day is undefined then it's 'today'.
  const [selectedDay, setSelectedDay] = useState<Date | undefined>();
  const { setApiError } = usePage();
  const { formatDate: formatUserDate } = useAccount();
  // Todo: Implement useNow() hook to refresh 'today' after midnight.

  const selectedDayString = selectedDay ? formatUserDate(selectedDay) : t('today', 'Today');

  const { element: dayNavigationFilterElement } = useDayNavigationFilter({
    onPrevDay: () =>
      setSelectedDay(prevState => {
        const newDate = addDays(prevState ?? new Date(), -1);
        return isToday(newDate) ? undefined : newDate;
      }),
    onNextDay: () =>
      setSelectedDay(prevState => {
        const newDate = addDays(prevState ?? new Date(), 1);
        return isToday(newDate) ? undefined : newDate;
      }),
    onSelectDate: date => setSelectedDay(isToday(date) ? undefined : date),
    selectDate: selectedDay ?? new Date(),
  });

  const stallions = useMemo(() => {
    if (!horses) return [];
    return horses.filter(horse => horse.sex === SexEnum._1);
  }, [horses]);

  const stallionProducts = useMemo(() => {
    if (!products) return [];
    return products.filter(product => product.stallion !== undefined);
  }, [products]);

  const isLoading = useMemo((): boolean => {
    return !(horses && contacts && orders && products && nonPickedOlderOrders);
  }, [orders, contacts, horses, products, nonPickedOlderOrders]);

  const frozenOrders = useMemo((): SupplierOrder[] => {
    if (!orders) {
      return [];
    }
    let result = orders;
    // Add the non-picked overdue orders when in the 'today' view.
    if (!selectedDay && nonPickedOlderOrders) {
      result = result.concat(nonPickedOlderOrders);
    }
    return (
      result?.filter(order => {
        return (order.order_items ?? []).filter(item => item.semen_type === SemenTypeEnum.FROZEN).length > 0;
      }) ?? []
    );
  }, [orders, nonPickedOlderOrders, selectedDay]);

  const freshOrders = useMemo((): SupplierOrder[] => {
    if (!orders) {
      return [];
    }
    let result = orders;
    // Add the non-picked overdue orders when in the 'today' view.
    if (!selectedDay && nonPickedOlderOrders) {
      result = result.concat(nonPickedOlderOrders);
    }
    return (
      result?.filter(order => {
        return (order.order_items ?? []).filter(item => item.semen_type === SemenTypeEnum.FRESH).length > 0;
      }) ?? []
    );
  }, [orders, nonPickedOlderOrders, selectedDay]);

  const pageActions = useMemo((): PageAction[] => {
    return [];
  }, []);

  // Load the stallion semen products from api
  const loadProducts = useCallback((): CancelablePromise<PaginatedProductList> => {
    const promise: CancelablePromise<PaginatedProductList> = ProductsService.productsList({
      organisationUid: selectedOrganization?.uid ?? '',
      // @todo: Maybe already filter here on semen stallion products
    });

    promise
      .then(res => {
        setProducts(res.results);
        setApiError(undefined);
      })
      .catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<PaginatedProductList>(e));
        }
      });
    return promise;
  }, [selectedOrganization, setApiError]);

  // Load the orders from api
  const loadOrders = useCallback((): CancelablePromise<PaginatedSupplierOrderList> => {
    const promise: CancelablePromise<PaginatedSupplierOrderList> = OrdersService.ordersSuppliedList({
      supplierUid: selectedOrganization?.uid ?? '',
      o: '-created_on,-id',
      shippingDate: selectedDay ? formatDate(selectedDay) : formatDate(new Date()),
    });

    promise
      .then(res => {
        setOrders(res.results);
        setApiError(undefined);
      })
      .catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<SupplierOrder>(e));
        }
      });
    return promise;
  }, [selectedOrganization, setApiError, selectedDay]);

  // Load the orders from api that are not picked. We use this orders in the
  // today-view so we always keep reminded of non-picked orders. The orders in
  // this list are older then today.
  const loadNonPickedOrders = useCallback((): CancelablePromise<PaginatedSupplierOrderList> => {
    const yesterday = new Date(Date.now() - 86400000); // that is: 24 * 60 * 60 * 1000
    const promise: CancelablePromise<PaginatedSupplierOrderList> = OrdersService.ordersSuppliedList({
      supplierUid: selectedOrganization?.uid ?? '',
      o: '-created_on,-id',
      isPicked: false,
      shippingDateLte: formatDate(yesterday),
    });

    promise
      .then(res => {
        setNonPickedOlderOrders(res.results);
        setApiError(undefined);
      })
      .catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<SupplierOrder>(e));
        }
      });
    return promise;
  }, [selectedOrganization, setApiError]);

  // Load the horses from api and/or cache
  const loadHorses = useCallback(
    (disableCache: boolean): CancelablePromise<PaginatedHorseList> => {
      const promise = HorsesService.horsesList({
        organisationUid: selectedOrganization?.uid ?? '',
        onUnknownLocation: false,
      });
      promise
        .then(() => setApiError(undefined))
        .catch(e => {
          if (!promise.isCancelled) {
            setApiError(new ApiErrorParser<PaginatedHorseList>(e), horses === undefined);
          }
        });
      cachedPaginatedApiData<Horse>(getCacheId('horses'), promise, setHorses, disableCache);
      return promise;
    },
    [selectedOrganization, horses, getCacheId, setApiError],
  );

  // Load the contacts from api and/or cache
  const loadContacts = useCallback(
    (disableCache: boolean): CancelablePromise<PaginatedContactList> => {
      const promise = ContactsService.contactsList({
        organisationUid: selectedOrganization?.uid ?? '',
      });
      promise.catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<PaginatedContactList>(e), contacts === undefined);
        }
      });
      cachedPaginatedApiData<Contact>(getCacheId('contacts'), promise, setContacts, disableCache);
      return promise;
    },
    [selectedOrganization, contacts, getCacheId, setApiError],
  );

  const loadCategories = useCallback((): CancelablePromise<Category[]> => {
    const promise = CategoriesService.categoriesList({
      organisationUid: selectedOrganization?.uid ?? '',
    });
    promise
      .then(res => setProductCategories(res))
      .catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<Category[]>(e));
        }
      });
    return promise;
  }, [selectedOrganization, setApiError]);

  // Load the semen stallion products
  useEffect(() => {
    // We need to have a selected organization.
    if (selectedOrganization) {
      const promise = loadProducts();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // Load the orders
  useEffect(() => {
    // We need to have a selected organization.
    if (selectedOrganization) {
      const promise = loadOrders();
      return () => promise.cancel();
    }
  }, [selectedOrganization, selectedDay]); //eslint-disable-line

  // Load the orders that are older then today and are not yet picked.
  useEffect(() => {
    // We need to have a selected organization.
    if (selectedOrganization) {
      const promise = loadNonPickedOrders();
      return () => promise.cancel();
    }
  }, [selectedOrganization, selectedDay]); //eslint-disable-line

  useEffect(() => {
    // We need to have a selected organization.
    if (selectedOrganization) {
      const promise = loadContacts(false);
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // Load the horses
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadHorses(false);
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // Load the product categories
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadCategories();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  const pick = (order: SupplierOrder) => {
    setPickOrder(order);
    setPickOrderModalOpen(true);
  };

  return (
    <Page title={`${t('semen-orders-picklist-title', 'Picklist for')} ${selectedDayString}`} loading={isLoading} actions={pageActions}>
      <PullScrollWrapper onRefresh={() => Promise.all([loadOrders(), loadContacts(true), loadHorses(true)])}>
        <Tile noBoxOnMobile={true}>
          <FilterWrapper listFilterTypes={undefined}>{dayNavigationFilterElement}</FilterWrapper>
          <Picklist
            products={stallionProducts}
            stallions={stallions}
            productCategories={productCategories ?? []}
            freshOrders={freshOrders}
            frozenOrders={frozenOrders}
            contacts={contacts ?? []}
            pick={pick}
          />
        </Tile>
      </PullScrollWrapper>

      <PickSemenOrder
        products={stallionProducts}
        stallions={stallions}
        productCategories={productCategories ?? []}
        order={pickOrder}
        open={pickOrderModalOpen}
        onClose={(reload: boolean) => {
          if (reload) {
            loadOrders();
            loadNonPickedOrders();
          }
          setPickOrderModalOpen(false);
        }}
      />
    </Page>
  );
}

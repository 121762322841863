import classNames from 'classnames';
import { useAccount } from 'context/AccountContext';
import React from 'react';

export interface Props {
  day: Date;

  // Highlight the day. Useful when it's today.
  highlight?: boolean;
  className?: string;

  // Width of the item
  columnHeaderWidth?: number;
}

/**
 * Small component that displays a day. This can be used in the day and multi day calendars.
 *   +--------+
 *   |   DO   |
 *   |    7   |
 *   +--------+
 *
 */
export function DayGridHeader({ day, highlight, className, columnHeaderWidth }: Props): JSX.Element {
  const { formatDateIntl } = useAccount();
  return (
    <div
      style={{ width: columnHeaderWidth }}
      className={classNames('select-none flex flex-col items-center text-white/80 md:text-black/50 gap-1', className)}
    >
      {/* On desktop we show two chars for the weekday */}
      <p className={classNames('hidden md:block text-xs uppercase', { 'text-white md:text-blue-500': highlight })}>
        {formatDateIntl(day, { weekday: 'short' })}
      </p>
      {/* On mobile we show just one char for the week day */}
      <p className={classNames('block: md:hidden text-xs uppercase', { 'text-white md:text-blue-500': highlight })}>
        {formatDateIntl(day, { weekday: 'narrow' })}
      </p>
      <div
        className={classNames('rounded-full text-lg w-7 h-7 md:text-2xl md:w-10 md:h-10 flex items-center justify-center', {
          'bg-white/90 text-primary md:text-white md:bg-blue-500': highlight,
        })}
      >
        <p>{day.getDate()}</p>
      </div>
    </div>
  );
}

import React from 'react';
import Spinner, { SpinnerSize } from './Spinner';

interface Props {
  text?: string;
}

function LoadingSection({ text }: Props): JSX.Element {
  return (
    <div className='w-full h-full flex items-center justify-center'>
      <div className='flex flex-col flex items-center gap-4'>
        <Spinner size={SpinnerSize.Normal} />
        <span className='text-sm text-gray-500'>{text}</span>
      </div>
    </div>
  );
}
export default LoadingSection;

import React, { useMemo } from 'react';
import { Contact } from 'openapi';
import { contactName } from 'utilities/Contact';
import { generatePath, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'AppRoutes';

interface Props {
  contactUid?: string | null;
  contacts?: Contact[];
}

/**
 * A simple nav link to a contact. Displaying the contact name as text.
 */
export default function ContactLink({ contactUid, contacts }: Props): JSX.Element {
  const { t } = useTranslation();
  const contact = useMemo(() => {
    return contacts?.find(contact => contact.uid === contactUid || contact.user_uid === contactUid);
  }, [contactUid, contacts]);

  const name = () => {
    if (contactUid === null) {
      return <>{t('not-provided', 'Not provided')}</>;
    }
    if (!contact) {
      return t('not-found', 'Not found');
    }
    return contactName(contact);
  };

  if (!contacts) {
    // Still loading
    return <></>;
  }
  if (!contactUid) {
    return <>{'-'}</>;
  }
  return (
    <NavLink className='text-blue-500' to={generatePath(AppRoutes.ContactDetails.path, { uid: contact?.uid ?? '' })}>
      {name()}
    </NavLink>
  );
}

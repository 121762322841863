import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SelectList, { WrappedComboboxProps } from 'ui/Inputs/SelectList';
import { FloatProps } from '@headlessui-float/react';
import { Studbook } from './Studbook';

/**
 * Complex type that extend some magic types from React Form hooks and include own fields
 *
 * See --> https://github.com/orgs/react-hook-form/discussions/7851#discussioncomment-2219298
 */
interface Props<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> {
  name: TName; // override from UseControllerProps and make it required
  control: Control<TFieldValues>; // override from UseControllerProps and make it required
  label?: string;
  hint?: string;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  placement?: FloatProps['placement'];
  className?: string;
  studbookOptions: Studbook[];
  // if we pass a setValueAs function, we can transform the value that will be set to the control.setValue
  // just like the setValueAs from the ReactFromHook setValueAs
  setValueAs?: (value: string | null | undefined) => string | null | undefined;
}

/**
 * Contact Select field create a filtered select input. It is also possible to create a new contact from this context
 */
function StudbookInputSelect<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  hint,
  error,
  required,
  disabled,
  placement,
  className,
  studbookOptions: givenStudbookOptions,
  name,
  control,
  setValueAs,
}: Props<TFieldValues, TName>): JSX.Element {
  const [studbookOptions, setStudbookOptions] = useState<WrappedComboboxProps<Studbook>[]>([]);

  const { t } = useTranslation();

  const textFilter = (studbooks: Studbook[], textFilter: string): Studbook[] => {
    if (!textFilter || textFilter === '') {
      return studbooks;
    }
    return studbooks.filter(studbook => {
      if (textFilter) {
        const search = textFilter.toLowerCase();
        if (studbook.name.toLowerCase().includes(search)) {
          return true;
        }

        if (studbook.code.toLowerCase().includes(search)) {
          return true;
        }
      }
      return false;
    });
  };

  /**
   * Update the contact state if changed from the paren
   */
  useEffect(() => {
    setStudbookOptions([{ items: givenStudbookOptions as Studbook[] }]);
  }, [givenStudbookOptions]);

  return (
    <>
      <SelectList<Studbook, TFieldValues, TName>
        className={className}
        placement={placement}
        required={required}
        hint={hint}
        error={error}
        label={label}
        options={studbookOptions}
        control={control}
        name={name}
        disabled={disabled}
        idField='code'
        onFilter={(query, items) => textFilter(items ?? [], query)}
        notFoundLabel={t('studbook-not-found', 'Studbook not found')}
        displayInput={studbook => studbook?.name ?? ''}
        displayOption={(studbook, selected) => (
          <>
            <span
              className={classNames('block truncate', {
                'font-medium': selected,
                'font-normal': !selected,
              })}
            >
              {studbook.name}
            </span>
          </>
        )}
        setValueAs={setValueAs}
      />
    </>
  );
}

export default StudbookInputSelect;

import { zodResolver } from '@hookform/resolvers/zod';
import { Pencil } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { Organisation, OrganisationService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { SelectInput, TextInput } from 'ui/Inputs';
import { Tile } from 'ui/Layout/Tile';
import TileDescriptionList from 'ui/Layout/Tile/TileDescriptionList';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { getCurrencyByCode, getCurrencyOptionList } from 'utilities/Currencies';
import { transformEmptyNumber, zodInputIsRequired } from 'utilities/zod';
import { z } from 'zod';

// get the formatted currency options
const currencyOptions = getCurrencyOptionList();

export default function InvoiceTile(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const { selectedOrganizationDetails, refresh } = useOrganization();

  /**
   * Define the default values
   */
  const defaultValues = useMemo(() => {
    return {
      currency: selectedOrganizationDetails?.currency,
      company_registration_number: selectedOrganizationDetails?.company_registration_number,
      also_send_invoice_to_email: selectedOrganizationDetails?.also_send_invoice_to_email,
      bank_name: selectedOrganizationDetails?.bank_name,
      account_number: selectedOrganizationDetails?.account_number,
      bank_code: selectedOrganizationDetails?.bank_code,
      vat_number: selectedOrganizationDetails?.vat_number,
      invoice_first_due_interval: selectedOrganizationDetails?.invoice_first_due_interval,
      // TODO enable when we want to support this.
      // invoice_footer_1: selectedOrganization?.invoice_footer_1,
    };
  }, [selectedOrganizationDetails]);

  /**
   * Schema for the form
   */
  const schema = useMemo(() => {
    return schemas.Organisation.pick({
      currency: true,
      company_registration_number: true,
      also_send_invoice_to_email: true,
      bank_name: true,
      account_number: true,
      bank_code: true,
      vat_number: true,
      invoice_first_due_interval: true,
      // TODO enable when we want to support this.
      // invoice_footer_1: true,
    }).extend({
      // We cannot use the current .email() schema as this will fail to parse an empty string
      // See https://github.com/colinhacks/zod/issues/2513#issuecomment-1732405993
      also_send_invoice_to_email: z.union([z.literal(''), z.string().email()]),
    });
  }, []);

  const { showModal, closeModal, modalIsVisible } = useModal();
  const {
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    register,
  } = useForm<Organisation>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Close event for the modal
   */
  const onClosed = () => {
    reset(defaultValues);
    clearErrors();
    setApiError(undefined);
  };

  /**
   * Submit event handler, update the data via the API for this organization
   */
  const onSubmit: SubmitHandler<Organisation> = async (data: Organisation) => {
    if (!selectedOrganizationDetails) return;
    setLoading(true);

    try {
      await OrganisationService.rootPartialUpdate({ uid: selectedOrganizationDetails.uid, requestBody: data });
      // refresh the organization
      refresh();

      // close the modal
      closeModal();
    } catch (error) {
      setApiError(new ApiErrorParser<Organisation>(error));
    }
    setLoading(false);
  };

  /**
   * Get and format the currency
   */
  const currency = useMemo(() => {
    const curr = getCurrencyByCode(selectedOrganizationDetails?.currency);
    return curr ? `${curr.name} (${curr.symbol})` : '-';
  }, [selectedOrganizationDetails?.currency]);

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo(() => {
    return [
      {
        onClick: showModal,
        text: t('edit', 'Edit'),
        buttonVariant: ButtonVariant.Default,
        icon: <Pencil />,
      },
    ];
  }, [showModal, t]);

  /**
   * Set the default values when the modal is opened
   */
  useEffect(() => {
    if (modalIsVisible) {
      reset(defaultValues);
    }
  }, [defaultValues, modalIsVisible, reset]);

  return (
    <Tile title={t('invoice', 'Invoice')} loading={!selectedOrganizationDetails} actions={tileActions}>
      <TileDescriptionList
        list={[
          {
            term: t('currency', 'Currency'),
            definition: currency,
          },
          {
            term: t('vat-number', 'VAT number'),
            definition: selectedOrganizationDetails?.vat_number || '-',
          },
          {
            term: t('company-registration-number', 'Company registration number'),
            definition: selectedOrganizationDetails?.company_registration_number || '-',
          },
          {
            term: t('also-send-invoice-to-email', 'Also send invoice to email'),
            definition: selectedOrganizationDetails?.also_send_invoice_to_email || '-',
          },
          {
            term: t('bank-name', 'Bank name'),
            definition: selectedOrganizationDetails?.bank_name || '-',
          },
          {
            term: t('bank-account-number', 'Bank account number'),
            definition: selectedOrganizationDetails?.account_number || '-',
          },
          {
            term: t('bank-code', 'Bank code (BIC)'),
            definition: selectedOrganizationDetails?.bank_code || '-',
          },
          {
            term: t('invoice-first-due-interval', 'Invoice payment deadline'),
            definition: `${selectedOrganizationDetails?.invoice_first_due_interval} ${t('days', 'days')}` || '-',
          },
          // TODO enable when we want to support this.
          // {
          //   term: t('invoice-footer', 'Invoice footer'),
          //   definition: selectedOrganization?.invoice_footer_1 || '-',
          // },
        ]}
      />

      <PageModal
        open={modalIsVisible}
        parentElement='form'
        parentProps={{ id: 'updateInvoice', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
        width={PageModalWidth.Sm}
        onClosed={onClosed}
      >
        <PageModalTitle title={t('update-invoice-settings', 'Update invoice settings')} onClose={closeModal} />
        <PageModalContent>
          <ErrorSection className='mb-4' errors={nonFieldErrors} />

          <div className='space-y-4'>
            <SelectInput
              error={fieldError('currency')}
              required={zodInputIsRequired<Organisation>(schema, 'currency')}
              options={currencyOptions}
              nullable={true}
              nullableValue=''
              label={t('currency', 'Currency')}
              {...register('currency')}
            />

            <TextInput
              error={fieldError('company_registration_number')}
              required={zodInputIsRequired<Organisation>(schema, 'company_registration_number')}
              label={t('company_registration_number', 'Company registration number')}
              {...register('company_registration_number')}
            />
            <TextInput
              error={fieldError('also_send_invoice_to_email')}
              required={zodInputIsRequired<Organisation>(schema, 'also_send_invoice_to_email')}
              hint={t('invoice-mail-address-copy-hint', 'The mail address where a copy of the invoice mail is sent to.')}
              label={t('invoice-mail-address-copy', 'Invoice mail address (copy)')}
              {...register('also_send_invoice_to_email')}
            />
            <TextInput
              error={fieldError('vat_number')}
              required={zodInputIsRequired<Organisation>(schema, 'vat_number')}
              label={t('vat-number', 'VAT number')}
              {...register('vat_number')}
            />
            <TextInput
              error={fieldError('bank_name')}
              required={zodInputIsRequired<Organisation>(schema, 'bank_name')}
              label={t('bank-name', 'Bank name')}
              {...register('bank_name')}
            />
            <TextInput
              error={fieldError('account_number')}
              required={zodInputIsRequired<Organisation>(schema, 'account_number')}
              label={t('bank-account-number', 'Bank account number')}
              {...register('account_number')}
            />
            <TextInput
              error={fieldError('bank_code')}
              required={zodInputIsRequired<Organisation>(schema, 'bank_code')}
              label={t('bank-code', 'Bank code (BIC)')}
              {...register('bank_code')}
            />
            <TextInput
              error={fieldError('invoice_first_due_interval')}
              required={zodInputIsRequired<Organisation>(schema, 'invoice_first_due_interval')}
              label={t('invoice-first-due-interval', 'Invoice payment deadline')}
              {...register('invoice_first_due_interval', { setValueAs: transformEmptyNumber(undefined) })}
              postText={t('days', 'days')}
            />
            {/*
              TODO enable when we want to support this.
            <TextAreaInput
              error={fieldError('invoice_footer_1')}
              required={zodInputIsRequired<Organisation>(schema, 'invoice_footer_1')}
              label={t('invoice-footer', 'Invoice footer')}
              {...register('invoice_footer_1')}
            /> */}
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              onClick: closeModal,
              variant: ButtonVariant.Default,
              type: 'button',
              text: t('cancel', 'Cancel'),
            },
            {
              formId: 'updateInvoice',
              loading: loading,
              variant: ButtonVariant.Primary,
              type: 'submit',
              text: t('save', 'Save'),
            },
          ]}
        />
      </PageModal>
    </Tile>
  );
}

import { PaperPlaneTilt, DotsThreeVertical } from '@phosphor-icons/react';
import { Contact, Role, SentInvitation } from 'openapi';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useModal from 'ui/Modals/UseModal';
import { Tile } from 'ui/Layout/Tile';
import { table, tableHiddenColumnSm, tableHiddenHeaderSm, tableTbody, tableTbodyTrNoClick, tableThead, tableTheadTd } from 'ui/Const';
import { useAccount } from 'context/AccountContext';
import DropdownMenu from 'ui/DropdownMenu';
import DeleteInvitationModal from './DeleteInvitationModal';
import ResendInvitationModal from './ResendInvitationModal';
import ContactLink from 'components/Contacts/ContactLink';

interface Props {
  contacts?: Contact[];
  roles?: Role[];
  invitations?: SentInvitation[];
  onResendInvitation?: () => void;
  onDeleteInvitation?: () => void;
}

export default function InvitationList({
  contacts = [],
  roles = [],
  invitations,
  onResendInvitation,
  onDeleteInvitation,
}: Props): JSX.Element {
  const [selectedInvitation, setSelectedInvitation] = useState<SentInvitation>();

  const { t } = useTranslation();
  const { formatDateTime } = useAccount();

  const {
    closeModal: closeDeleteInvitationModal,
    modalIsVisible: createDeleteInvitationModalIsVisible,
    showModal: showDeleteInvitationModal,
  } = useModal();
  const {
    closeModal: closeResendInvitationModal,
    modalIsVisible: createResendInvitationModalIsVisible,
    showModal: showResendInvitationModal,
  } = useModal();

  /**
   * Load the loadInvitations
   */
  const resendInvitation = useCallback(
    (invitation: SentInvitation) => {
      setSelectedInvitation(invitation);
      showResendInvitationModal();
    },
    [showResendInvitationModal],
  );

  /**
   * Delete an invitation.
   */
  const deleteInvitation = useCallback(
    (invitation: SentInvitation) => {
      setSelectedInvitation(invitation);
      showDeleteInvitationModal();
    },
    [showDeleteInvitationModal],
  );

  /**
   * Render the name of the contact that is attached to the invitation or the email address if the contact is not found.
   */
  const renderName = (invitation: SentInvitation) => {
    const foundContact = contacts.find(contact => contact.uid === invitation.contact_uid);

    if (foundContact) {
      return (
        <div>
          <ContactLink contactUid={invitation.contact_uid} contacts={contacts} />
          <div className='text-xs text-gray-500'>{invitation.invitee_email}</div>
        </div>
      );
    }

    return <>{invitation.invitee_email}</>;
  };

  const renderRoles = (invitation: SentInvitation): string[] => {
    const foundContact = contacts.find(contact => contact.uid === invitation.contact_uid);

    if (foundContact) {
      return (foundContact.roles ?? []).filter(role => role !== null);
    }

    return invitation.roles;
  };

  return (
    <>
      <Tile loading={!invitations} title={t('invitations', 'Invitations')} noBoxOnMobile={true}>
        <table className={table}>
          <thead className={tableThead}>
            <tr className={tableHiddenHeaderSm}>
              <td className='w-10' />
              <td className={tableTheadTd}>{t('email', 'Email')}</td>
              <td className={tableTheadTd}>{t('created-on', 'Created on')}</td>
              <td className={tableTheadTd}>{t('sent-on', 'Sent on')}</td>
              <td className={tableTheadTd}>{t('role', 'Role')}</td>
              <td className='w-10' />
            </tr>
          </thead>
          <tbody className={tableTbody}>
            {invitations?.map(invitation => (
              <tr className={tableTbodyTrNoClick} key={invitation.uuid}>
                <td className='text-center w-10'>
                  <PaperPlaneTilt size={22} weight='light' className='inline' />
                </td>
                <td className='break-all'>{renderName(invitation)}</td>
                <td className={tableHiddenColumnSm}>{formatDateTime(new Date(Date.parse(invitation.created_on)))}</td>
                <td className={tableHiddenColumnSm}>
                  {invitation.sent_on ? formatDateTime(new Date(Date.parse(invitation.sent_on))) : 'n/a'}
                </td>
                <td>
                  {renderRoles(invitation)
                    .map(id => roles.find(role => role.uid === id)?.name)
                    .join(', ')}
                </td>
                <td className='w-10 text-center'>
                  <DropdownMenu
                    menuPlacement='bottom-end'
                    menuItems={[
                      [
                        { element: t('resend', 'Resend'), onClick: () => resendInvitation(invitation) },
                        {
                          element: t('remove', 'Remove'),
                          onClick: () => deleteInvitation(invitation),
                          className: 'text-red-600',
                        },
                      ],
                    ]}
                  >
                    <div className='w-full h-full text-center cursor-pointer pt-2 pb-1'>
                      <DotsThreeVertical size={24} weight='bold' className='inline' />
                    </div>
                  </DropdownMenu>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Tile>

      <ResendInvitationModal
        invitation={selectedInvitation}
        isVisible={createResendInvitationModalIsVisible}
        onSuccess={() => {
          setSelectedInvitation(undefined);
          onResendInvitation?.();
        }}
        onRequestClose={closeResendInvitationModal}
      />

      <DeleteInvitationModal
        invitation={selectedInvitation}
        isVisible={createDeleteInvitationModalIsVisible}
        onSuccess={() => {
          setSelectedInvitation(undefined);
          onDeleteInvitation?.();
        }}
        onRequestClose={closeDeleteInvitationModal}
      />
    </>
  );
}

import { CacheKey } from 'api/ApiCache';
import { ModulePermissionsEnum, Organisation, OrganisationList, OrganisationService } from 'openapi';
import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { BrowserStorageKeys } from '../api/UserSession';
import { useAccount } from './AccountContext';
import { useConfig } from './ConfigContext';

export enum Module {
  FEEDING = 'FEED',
  INVOICING = 'INVOICE',
  HR = 'HR',
}

interface Props {
  children: React.ReactNode;
}

type OrganizationContextType = {
  selectedOrganizationUid: string | undefined;
  selectedOrganization: OrganisationList | undefined;
  selectedOrganizationDetails: Organisation | undefined;
  setSelectedOrganization: (orgUid: string) => void;
  organizations: OrganisationList[];
  loading: boolean;
  error: string | undefined;
  refresh: () => void;
  generateCacheKey: (name: string) => CacheKey;
  rvoReportCount: number;
  setRvoReportCount: Dispatch<SetStateAction<number>>;
  permissions: Set<ModulePermissionsEnum>;
  setPermissions: Dispatch<SetStateAction<Set<ModulePermissionsEnum>>>;
};

const OrganizationContext = createContext<OrganizationContextType>({
  selectedOrganizationUid: undefined,
  selectedOrganization: undefined,
  selectedOrganizationDetails: undefined,
  setSelectedOrganization: () => console.warn('No Organization provider'),
  organizations: [],
  loading: false,
  error: undefined,
  refresh: () => console.warn('No Organization provider'),
  generateCacheKey: (): CacheKey => {
    throw Error('No Organization provider');
  },
  rvoReportCount: 0,
  setRvoReportCount: () => console.warn('No Organization provider'),
  permissions: new Set(),
  setPermissions: () => console.warn('No Organization provider'),
});

export function useOrganization(): OrganizationContextType {
  return useContext(OrganizationContext);
}

export function OrganizationProvider({ children }: Props): JSX.Element {
  const [selectedOrganizationUid, setSelectedOrganizationUid] = useState<string | undefined>();
  const [organizations, setOrganizations] = useState<OrganisationList[]>();
  const [selectedOrganizationDetails, setSelectedOrganizationDetails] = useState<Organisation>();
  const [selectedOrganization, setSelectedOrganization] = useState<OrganisationList>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { config } = useConfig();
  const { session: user, accountDetails } = useAccount();
  const [rvoReportCount, setRvoReportCount] = useState<OrganizationContextType['rvoReportCount']>(0);
  const [permissions, setPermissions] = useState<OrganizationContextType['permissions']>(new Set());

  /**
   * A convenience function for generating a CacheId which can be used as
   * storage key for caching api data.
   * @param name A unique name that is used as key. I.e. 'horses' or 'contacts'.
   */
  const generateCacheKey = useCallback(
    (name: string): CacheKey => {
      if (!selectedOrganizationUid) {
        throw Error('No organization selected when trying to build a CacheId');
      }
      if (!accountDetails) {
        throw Error('No logged in user when trying to build a CacheId');
      }
      return {
        userUid: accountDetails.uid,
        organizationUid: selectedOrganizationUid,
        name,
      };
    },
    [accountDetails, selectedOrganizationUid],
  );

  /**
   * Load the selected organization details
   */
  const loadSelectedOrganization = useCallback(
    async (organizationUid: string) => {
      if (!organizations) {
        console.error('Trying to get the selected organization but organizations are not yet loaded.');
        return;
      }

      try {
        const orgPromise = OrganisationService.rootRetrieve({ uid: organizationUid });
        setSelectedOrganizationDetails(await orgPromise);
      } catch (e) {
        setError('Unable to load selected organization');
      }
    },
    [organizations, setSelectedOrganizationDetails],
  );

  const refresh = useCallback(async () => {
    if (!config) {
      setError('Config is not set');
      return;
    }
    if (!user) {
      setError('User is not logged in');
      return;
    }
    setLoading(true);

    try {
      const promise = OrganisationService.rootList({});

      const results: OrganisationList[] = await promise;
      const localStorageOrgUid: string | null = localStorage?.getItem(BrowserStorageKeys.SelectedOrganization);
      let selectedOrg: OrganisationList | undefined = undefined;
      if (localStorageOrgUid) {
        selectedOrg = results.find(org => org.uid === localStorageOrgUid);
      } else if (accountDetails?.preferred_organisation) {
        // set the preferred organization as selected
        selectedOrg = results.find(org => org.uid === accountDetails.preferred_organisation);
      }
      if (!selectedOrg && results.length > 0) {
        selectedOrg = results[0];
      }

      // we set the selected organization in local storage if it is not set
      // we are doing this so it will stick to the current choosen one, even when the user
      // change the preferred organization
      // On logout, this will be cleared and on login it will be set again, with the preferred organization or the first one
      if (selectedOrg) {
        localStorage?.setItem(BrowserStorageKeys.SelectedOrganization, selectedOrg.uid);
      }

      setOrganizations(results);
      setSelectedOrganization(selectedOrg);
      setSelectedOrganizationUid(selectedOrg?.uid);
    } catch (e) {
      setError('Unable to load organizations');
    } finally {
      setLoading(false);
    }
  }, [config, user]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeSelectedOrganization = useCallback(
    (orgUid: string) => {
      if (!organizations) {
        throw Error('Trying to select organization but organizations not yet loaded');
      }
      const found = organizations.find(org => org.uid === orgUid);
      if (!found) {
        throw Error('Trying to select organization but it is not found in the organization list');
      }
      localStorage?.setItem(BrowserStorageKeys.SelectedOrganization, orgUid);
      setSelectedOrganizationUid(orgUid);
      setSelectedOrganization(found);
    },
    [organizations],
  );

  useEffect(() => {
    if (user) {
      refresh();
    }
  }, [user, refresh]);

  /**
   * Load the selected organization details when the selectedOrganizationUid changes
   */
  useEffect(() => {
    if (selectedOrganizationUid) {
      loadSelectedOrganization(selectedOrganizationUid);
    }
  }, [loadSelectedOrganization, selectedOrganizationUid]);

  return (
    <OrganizationContext.Provider
      value={{
        organizations: organizations ?? [],
        selectedOrganizationUid,
        selectedOrganization,
        selectedOrganizationDetails,
        error,
        loading,
        refresh,
        setSelectedOrganization: changeSelectedOrganization,
        generateCacheKey,
        rvoReportCount,
        setRvoReportCount,
        permissions,
        setPermissions,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
}

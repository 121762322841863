import { CheckSquareOffset } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { ChangeEvent, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonVariant } from 'ui/Button';
import IndeterminateCheckbox from 'ui/IndeterminateCheckbox';

export interface SelectionAction {
  icon?: ReactNode;
  text: string;
  disabled?: boolean;
  buttonVariant?: ButtonVariant;
  onClick?: () => void;
}

interface Props {
  selectedItems: number;
  totalItems?: number;
  actions?: SelectionAction[];
  onCancel: () => void;
  onSelectAll?: (e: ChangeEvent<HTMLInputElement>) => void;
  label?: {
    singular: string;
    plural: string;
    noResult: string;
  };
}

interface ReturnType {
  element: ReactNode;
  triggerAddToSelectionError: (errorMessage?: string) => void;
  enabled: boolean;
  enable: () => void;
  disable: () => void;
}

function useSelection({ selectedItems, actions, onCancel, label, totalItems, onSelectAll }: Props): ReturnType {
  const [enabled, setEnabled] = useState<boolean>(false);
  const [addToSelectionError, setAddToSelectionError] = useState<boolean>(false);
  const [addToSelectionErrorMessage, setAddToSelectionErrorMessage] = useState<string>();

  const { t } = useTranslation();

  if (!label) {
    label = {
      singular: t('1-item-selected', '1 item selected'),
      plural: t('n-items-selected', '{{n}} items selected'),
      noResult: t('no-items-selected', 'no items selected'),
    };
  }

  /**
   * Trigger the error message for adding to selection
   */
  const triggerAddToSelectionError = (errorMessage?: string) => {
    setAddToSelectionError(true);
    if (errorMessage) {
      setAddToSelectionErrorMessage(errorMessage);
    }

    setTimeout(() => {
      setAddToSelectionError(false);
      setAddToSelectionErrorMessage(undefined);
    }, 2000);
  };

  /**
   * Build the selection hint text
   */
  const selectionHintText = useMemo((): string => {
    if (addToSelectionErrorMessage) {
      return addToSelectionErrorMessage;
    }

    if (selectedItems > 1) {
      return label.plural.replace('{{n}}', selectedItems.toString());
    } else if (selectedItems === 1) {
      return label.singular;
    }

    return label.noResult;
  }, [addToSelectionErrorMessage, label.noResult, label.plural, label.singular, selectedItems]);

  /**
   * Render the element
   */
  const element = useMemo(() => {
    if (enabled === false) return <></>;
    return (
      <div className='animate-fadeIn animate-faster sticky top-12 md:top-0 pt-safe-offset-2 md:pt-safe-offset-6 z-10 px-1 md:px-0 mb-2 bg-gray-50'>
        <div
          className={classNames('transition-colors text-white rounded-xl p-3 flex gap-2 items-center', {
            'bg-blue-700': !addToSelectionError,
            'bg-red-700': addToSelectionError,
          })}
        >
          {totalItems !== undefined ? (
            <IndeterminateCheckbox
              onChange={onSelectAll}
              name='root'
              checked={totalItems === selectedItems}
              indeterminate={selectedItems > 0 && totalItems > selectedItems}
            />
          ) : (
            <CheckSquareOffset className='shrink-0' size={20} />
          )}
          <p className='grow'>{selectionHintText}</p>

          {actions?.map(action => (
            <Button
              compress={true}
              disabled={action.disabled}
              key={action.text}
              onClick={action.onClick}
              icon={action.icon}
              variant={action.buttonVariant}
              type='button'
            >
              {action.text}
            </Button>
          ))}

          <Button
            compress={true}
            onClick={() => {
              onCancel();
              setEnabled(false);
            }}
            type='button'
          >
            {t('cancel', 'Cancel')}
          </Button>
        </div>
      </div>
    );
  }, [actions, addToSelectionError, enabled, onCancel, onSelectAll, selectedItems, selectionHintText, t, totalItems]);

  return {
    element,
    triggerAddToSelectionError,
    enabled,
    enable: () => setEnabled(true),
    disable: () => setEnabled(false),
  };
}

export default useSelection;

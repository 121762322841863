import { PencilSimple, TrashSimple } from '@phosphor-icons/react';
import { cachedPaginatedApiData } from 'api/ApiCache';
import ApiErrorParser from 'api/ApiErrorParser';
import { AppRoutes } from 'AppRoutes';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { usePage } from 'context/PageContext';
import {
  CancelablePromise,
  Horse,
  HorseDetail,
  HorsesService,
  PaginatedHorseList,
  SexEnum,
  StallionMount,
  StallionMountDetail,
  StallionmountsService,
} from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import DescriptionList from 'ui/Layout/DescriptionList';
import Page, { navBackToThisPage, PageMaxWidth } from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import { ActionModal } from 'ui/Modals';
import { NavLink, generatePath } from 'react-router-dom';
import SaveStallionMount from 'components/Breeding/SaveStallionMount';
import useModal from 'ui/Modals/UseModal';

// A page that shows the details of a specific stallion mount. It also has to
// actions to remove or edit the mount.
export default function StallionMountDetailsPage(): JSX.Element {
  const { t } = useTranslation();
  const [stallion, setStallion] = useState<HorseDetail>();
  const { selectedOrganization, generateCacheKey: getCacheId } = useOrganization();
  const [horses, setHorses] = useState<Horse[]>();
  const { formatDate, formatDateTime } = useAccount();
  const { closeModal: closeUpdateMountModal, modalIsVisible: updateMountModalIsVisible, showModal: showUpdateMountModal } = useModal();
  const { setApiError } = usePage();
  const [mount, setMount] = useState<StallionMountDetail>();
  const { uid } = useParams();
  const { closeModal: closeRemoveMountDialog, modalIsVisible: updateRemoveMountDialog, showModal: showRemoveMountDialog } = useModal();
  const { closeModal: closeDestroyFreshModal, modalIsVisible: destroyFreshModalIsVisible, showModal: showDestroyFreshModal } = useModal();
  const {
    closeModal: closeDestroyFrozenModal,
    modalIsVisible: destroyFrozenModalIsVisible,
    showModal: showDestroyFrozenModal,
  } = useModal();

  const [removeMountDialogError, setRemoveMountDialogError] = useState<ApiErrorParser<void> | undefined>();
  const [destroyFreshDialogError, setDestroyFreshDialogError] = useState<ApiErrorParser<StallionMount> | undefined>();
  const [destroyFrozenDialogError, setDestroyFrozenDialogError] = useState<ApiErrorParser<StallionMount> | undefined>();
  const navigate = useNavigate();

  // Load the mount from api
  const loadMount = useCallback((): CancelablePromise<StallionMountDetail> => {
    const promise = StallionmountsService.stallionmountsRetrieve({
      stallionOrganisationUid: selectedOrganization?.uid ?? '',
      uid: uid ?? '',
    });
    promise
      .then(result => {
        setApiError(undefined);
        setMount(result);
      })
      .catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<StallionMountDetail>(e));
        }
      });
    return promise;
  }, [selectedOrganization, setApiError, uid]);

  // Load the horses from api and/or cache
  const loadHorses = useCallback(
    (disableCache: boolean): CancelablePromise<PaginatedHorseList> => {
      const promise = HorsesService.horsesList({
        organisationUid: selectedOrganization?.uid ?? '',
        onUnknownLocation: false,
      });
      promise
        .then(() => setApiError(undefined))
        .catch(e => {
          if (!promise.isCancelled) {
            setApiError(new ApiErrorParser<Horse[]>(e), horses === undefined);
          }
        });
      cachedPaginatedApiData<Horse>(getCacheId('horses'), promise, setHorses, disableCache);
      return promise;
    },
    [selectedOrganization, horses, getCacheId, setApiError],
  );

  // Load the stallion. Do it in a separate call to ensure we have the right one.
  const loadStallion = useCallback(
    (stallionUid: string): CancelablePromise<HorseDetail> => {
      const promise = HorsesService.horsesRetrieve({
        organisationUid: selectedOrganization?.uid ?? '',
        uid: stallionUid,
      });
      promise
        .then(result => {
          setApiError(undefined);
          setStallion(result);
        })
        .catch(e => {
          if (!promise.isCancelled) {
            setApiError(new ApiErrorParser<HorseDetail>(e));
          }
        });
      return promise;
    },
    [selectedOrganization, setApiError],
  );

  // Load the mount
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadMount();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // Load the horses
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadHorses(false);
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // Load the stallion
  useEffect(() => {
    if (selectedOrganization && mount && !stallion) {
      const promise = loadStallion(mount.stallion);
      return () => promise.cancel();
    }
  }, [selectedOrganization, mount]); //eslint-disable-line

  const actions = useMemo(() => {
    return [
      {
        onClick: showUpdateMountModal,
        text: t('edit', 'Edit'),
        icon: <PencilSimple />,
      },
      {
        onClick: showRemoveMountDialog,
        text: t('remove-mount', 'Remove mount'),
        buttonVariant: ButtonVariant.Danger,
        icon: <TrashSimple />,
      },
    ];
  }, [t, showRemoveMountDialog, showUpdateMountModal]);

  // Remove a mount and navigate back to the mount list.
  const removeMount = async (): Promise<void> => {
    const promise = StallionmountsService.stallionmountsDestroy({
      stallionOrganisationUid: selectedOrganization?.uid ?? '',
      uid: uid ?? '',
    });
    try {
      await promise;
      closeRemoveMountDialog();
      navigate(AppRoutes.MountList.path);
    } catch (e) {
      setRemoveMountDialogError(new ApiErrorParser(e));
    }
  };

  const destroyRemainingFreshPortions = async (): Promise<void> => {
    try {
      await StallionmountsService.stallionmountsDestroyFreshPartialUpdate({
        stallionOrganisationUid: selectedOrganization?.uid ?? '',
        uid: uid ?? '',
      });
      await loadMount();
      closeDestroyFreshModal();
    } catch (e) {
      setDestroyFreshDialogError(new ApiErrorParser<StallionMount>(e));
    }
  };

  const destroyRemainingFrozenStraws = async (): Promise<void> => {
    try {
      await StallionmountsService.stallionmountsDestroyFrozenPartialUpdate({
        stallionOrganisationUid: selectedOrganization?.uid ?? '',
        uid: uid ?? '',
      });
      await loadMount();
      closeDestroyFrozenModal();
    } catch (e) {
      setDestroyFrozenDialogError(new ApiErrorParser<StallionMount>(e));
    }
  };

  const breadCrumbs = useMemo(() => [AppRoutes.MountList], []);
  return (
    <Page title={`${t('mount', 'Mount')} ${mount?.uid ?? ''}`} breadCrumbs={breadCrumbs} maxWidth={PageMaxWidth.Tile} actions={actions}>
      <Tile title={t('general', 'General')}>
        {mount && (
          <DescriptionList
            list={[
              { term: t('mount-identifier', 'Mount identifier'), definition: mount.uid },
              {
                term: t('stallion', 'Stallion'),
                definition: (
                  <NavLink
                    className='text-blue-500'
                    to={{
                      pathname: generatePath(AppRoutes.HorsesDetails.path, { uid: stallion?.uid ?? '' }),
                      search: navBackToThisPage().toString(),
                    }}
                  >
                    {stallion?.name}
                  </NavLink>
                ),
              },
              { term: t('collection-date', 'Collection date'), definition: formatDate(mount.collection_date ?? '') },
              { term: t('volume', 'Volume'), definition: mount.volume + ' ml' },
              { term: t('concentration', 'Concentration'), definition: `${mount.concentration} ${t('million-per-ml', 'million/ml')}` },
              { term: t('moving-cells-percentage', 'Moving cells percentage'), definition: mount.moving_cells_percentage + '%' },
              {
                term: t('portions-fresh', 'Portions fresh'),
                definition: (
                  <div className='flex flex-col gap-2'>
                    <p>{`${mount.portions_fresh} (${mount.portions_fresh_available} ${t('in-stock', 'in stock')})`}</p>
                    {(mount.portions_fresh_available ?? 0) > 0 && (
                      <Button variant={ButtonVariant.Danger} onClick={showDestroyFreshModal}>
                        {t('destroy-remaining-stock', 'Destroy remaining stock')}
                      </Button>
                    )}
                    {(mount.portions_fresh_destroyed_amount ?? 0) > 0 && mount.portions_fresh_destroyed_on && (
                      <p className='text-rose-500'>
                        {t(
                          'fresh-portions-destroyed-on',
                          '{{portions_fresh_destroyed_amount}} portions destroyed on {{portions_fresh_destroyed_on}}.',
                          {
                            portions_fresh_destroyed_amount: mount.portions_fresh_destroyed_amount,
                            portions_fresh_destroyed_on: formatDateTime(new Date(mount.portions_fresh_destroyed_on)),
                          },
                        )}
                      </p>
                    )}
                  </div>
                ),
              },
              {
                term: t('straws-frozen', 'Straws frozen'),
                definition: (
                  <div className='flex flex-col gap-2'>
                    <p>{`${mount.straws_frozen} (${mount.straws_frozen_available} ${t('in-stock', 'in stock')})`}</p>
                    {(mount.straws_frozen_available ?? 0) > 0 && (
                      <Button variant={ButtonVariant.Danger} onClick={showDestroyFrozenModal}>
                        {t('destroy-remaining-stock', 'Destroy remaining stock')}
                      </Button>
                    )}
                    {(mount.straws_frozen_destroyed_amount ?? 0) > 0 && mount.straws_frozen_destroyed_on && (
                      <p className='text-rose-500'>
                        {t(
                          'frozen-straws-destroyed-on',
                          '{{straws_frozen_destroyed_amount}} straws destroyed on {{straws_frozen_destroyed_on}}.',
                          {
                            straws_frozen_destroyed_amount: mount.straws_frozen_destroyed_amount,
                            straws_frozen_destroyed_on: formatDateTime(new Date(mount.straws_frozen_destroyed_on)),
                          },
                        )}
                      </p>
                    )}
                  </div>
                ),
              },
              { term: t('storage-container', 'Storage container'), definition: mount.storage_container },
              { term: t('storage-canister', 'Storage canister'), definition: mount.storage_canister },
              { term: t('straw-color', 'Straw color'), definition: (mount.NIFA_straw_color ?? '').toString().replaceAll('_', ' ') },
            ]}
          />
        )}
      </Tile>
      {mount && horses && (
        <SaveStallionMount
          stallions={horses.filter(horse => horse.sex === SexEnum._1)}
          existingMount={mount}
          open={updateMountModalIsVisible}
          onRequestClose={(reload: boolean) => {
            if (reload) loadMount();
            closeUpdateMountModal();
          }}
        />
      )}
      <ActionModal
        open={updateRemoveMountDialog}
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: closeRemoveMountDialog,
          },
          {
            text: t('remove', 'Remove'),
            variant: ButtonVariant.PrimaryDanger,
            onClick: removeMount,
          },
        ]}
        title={t('remove-mount-confirm-title', 'Remove mount')}
      >
        <>
          <ErrorSection errors={removeMountDialogError} />
          <p>{t('remove-mount-confirm-text', 'Are you sure you want to remove this stallion mount?')}</p>
        </>
      </ActionModal>
      <ActionModal
        open={destroyFreshModalIsVisible}
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: closeDestroyFreshModal,
          },
          {
            text: t('destroy', 'Destroy'),
            variant: ButtonVariant.PrimaryDanger,
            onClick: destroyRemainingFreshPortions,
          },
        ]}
        title={t('destroy-fresh-confirm-title', 'Destroy remaining fresh stock')}
      >
        <ErrorSection errors={destroyFreshDialogError} />
        <p>{t('destroy-fresh-confirm-text', 'Are you sure you want to destroy the remaining fresh stock for this stallion mount?')}</p>
      </ActionModal>
      <ActionModal
        open={destroyFrozenModalIsVisible}
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: closeDestroyFrozenModal,
          },
          {
            text: t('destroy', 'Destroy'),
            variant: ButtonVariant.PrimaryDanger,
            onClick: destroyRemainingFrozenStraws,
          },
        ]}
        title={t('destroy-frozen-confirm-title', 'Destroy remaining frozen stock')}
      >
        <ErrorSection errors={destroyFrozenDialogError} />
        <p>{t('destroy-frozen-confirm-text', 'Are you sure you want to destroy the remaining frozen stock for this stallion mount?')}</p>
      </ActionModal>
    </Page>
  );
}

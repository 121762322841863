import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

function ListItem({ item }: { item: string }): JSX.Element {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={classNames('px-4 py-3 rounded-md shadow-md cursor-pointer')} onClick={() => setOpen(!open)}>
      {/*Just Some basic transitions on click event. Item expansion with transition and color change.*/}
      {/*With this we can see how smooth the transition is when we click on the item*/}
      {/*Also we can see the response on click event when item expands*/}

      <div>
        {item} {t('benchmark.listitem.title', 'This item should expand/collapse smoothly on click')}
      </div>
      <div
        className={classNames('h-0 mt-2 overflow-y-hidden transition-all animate-pulse duration-200', {
          ['h-[200px] bg-indigo-500 text-white p-4 rounded-md']: open,
          ['bg-white text-indigo-500 p-0']: !open,
        })}
      >
        <p className={'animate-bounce'}>{t('benchmark.listitem.bounce', 'This should bounce smoothly')}</p>
      </div>
    </div>
  );
}
export default function Benchmark(): JSX.Element {
  const [itemCount, setItemCount] = useState(10);
  const { t } = useTranslation();

  const items = useMemo(() => {
    return Array.from(Array(itemCount)).map((item, index) => `Item ${index + 1}`);
  }, [itemCount]);
  return (
    <div className={'p-2 space-y-2'}>
      <h1>{t('benchmark.transition.title', 'Transition Test')}</h1>
      {/*Use this input to change the number of items*/}
      <input value={itemCount} onChange={e => setItemCount(parseInt(e.target.value))} type={'number'} />
      {items.map(item => (
        <ListItem key={item} item={item} />
      ))}
    </div>
  );
}

import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
  classname?: string;
  classnameLi?: string;
  sire: ReactNode;
  siresire: ReactNode;
  siregrandsire1: ReactNode;
  siregranddam1: ReactNode;
  siredam: ReactNode;
  siregranddam2: ReactNode;
  siregrandsire2: ReactNode;
  dam: ReactNode;
  damsire: ReactNode;
  damgrandsire1: ReactNode;
  damgranddam1: ReactNode;
  damdam: ReactNode;
  damgrandsire2: ReactNode;
  damgranddam2: ReactNode;
}

/**
 * This component create a hierarchy list from top to bottom.
 */
function PedigreeHierarchyList({
  classname,
  classnameLi,
  sire,
  siresire,
  siregrandsire1,
  siregranddam1,
  siredam,
  siregranddam2,
  siregrandsire2,
  dam,
  damsire,
  damgrandsire1,
  damgranddam1,
  damdam,
  damgrandsire2,
  damgranddam2,
}: Props): JSX.Element {
  // the classnames so we have a nice hierarchy with lines
  // this way the user sees the hierarchy via the lines we draw
  const ClassnameLi = classNames(
    'relative pl-10 leading-[2em] pt-5',
    'before:content-[""] before:absolute before:top-0 before:border-r before:border-b before:h-full',
    'after:content-[""] after:absolute after:block after:border-t after:h-full',
    'after:top-[60px] last:before:h-[60px]',
    'after:left-[10px] before:left-[10px]',
    'after:w-8',
    classnameLi,
  );

  /**
   * Wrap the node in a <span /> if it is a string
   */
  const wrapSpanIfNeeded = (node: ReactNode, placeholder: string) => {
    if (typeof node === 'string') {
      return (
        <span
          className={classNames({
            'font-thin': !node,
          })}
        >
          {node || `<${placeholder}>`}
        </span>
      );
    }

    return node;
  };

  return (
    <div className={classNames('flex flex-col sm:flex-row gap-10 justify-between', classname)}>
      <div className='grow'>
        {wrapSpanIfNeeded(sire, 'no sire')}
        <ul>
          <li className={ClassnameLi}>
            {wrapSpanIfNeeded(siresire, 'no sire')}
            <ul>
              <li className={ClassnameLi}>{wrapSpanIfNeeded(siregrandsire1, 'no sire')}</li>
              <li className={ClassnameLi}>{wrapSpanIfNeeded(siregranddam1, 'no dam')}</li>
            </ul>
          </li>
          <li className={ClassnameLi}>
            {wrapSpanIfNeeded(siredam, 'no dam')}
            <ul>
              <li className={ClassnameLi}>{wrapSpanIfNeeded(siregrandsire2, 'no sire')}</li>
              <li className={ClassnameLi}>{wrapSpanIfNeeded(siregranddam2, 'no dam')}</li>
            </ul>
          </li>
        </ul>
      </div>
      <div className='grow'>
        {wrapSpanIfNeeded(dam, 'no dam')}
        <ul>
          <li className={ClassnameLi}>
            {wrapSpanIfNeeded(damsire, 'no sire')}
            <ul>
              <li className={ClassnameLi}>{wrapSpanIfNeeded(damgrandsire1, 'no sire')}</li>
              <li className={ClassnameLi}>{wrapSpanIfNeeded(damgranddam1, 'no dam')}</li>
            </ul>
          </li>
          <li className={ClassnameLi}>
            {wrapSpanIfNeeded(damdam, 'no dam')}
            <ul>
              <li className={ClassnameLi}>{wrapSpanIfNeeded(damgrandsire2, 'no sire')}</li>
              <li className={ClassnameLi}>{wrapSpanIfNeeded(damgranddam2, 'no dam')}</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default PedigreeHierarchyList;

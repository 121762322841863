import { areIntervalsOverlapping, format } from 'date-fns';

/**
 * Formats a Date object into a "YYYY-MM-DD" string.
 *
 * @param {Date} date - The date object to be formatted.
 * @returns {string} - The formatted date string in "YYYY-MM-DD" format.
 *
 * @example
 * const today = new Date();
 * console.log(formatDate(today));  // Outputs date in YYYY-MM-DD format
 */
export function formatDate(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}

/**
 * Return date of today in YYYY-MM-DD format.
 * @returns
 */
export const today = (): string => {
  return format(new Date(), 'yyyy-MM-dd');
};

/**
 * Check if the given date is today.
 * @deprecated use the date-fn.isToday instead
 * @see https://date-fns.org/v3.6.0/docs/isToday
 */
export const isToday = (date: Date): boolean => {
  const today = new Date();
  return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
};

/**
 * Go back in time by the given number of days.
 * @param date
 * @param days
 * @returns
 * @deprecated use the date-fn.subDays instead
 * @see https://date-fns.org/v3.6.0/docs/subDays
 */
export const substractDays = (date: Date, days: number): Date => {
  const newDate = new Date(date);

  newDate.setDate(newDate.getDate() - days);
  return newDate;
};

/**
 * Go forward in time by the given number of days.
 *
 * @param date
 * @param days
 * @returns
 * @deprecated use the date-fn.addDays instead
 * @see https://date-fns.org/v3.6.0/docs/addDays
 */
export const addDays = (date: Date, days: number): Date => {
  const newDate = new Date(date);

  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// Returns true if the event is (partially) part of provided day.
export const dayOverlap = (event: { startTime: Date; endTime: Date }, day: Date): boolean => {
  const endOfDay = new Date(day);
  endOfDay.setHours(23, 59, 59, 999);
  // Just to be sure, set the date to the begin of the day
  day.setHours(0, 0, 0, 0);
  return areIntervalsOverlapping({ start: day, end: endOfDay }, { start: event.startTime, end: event.endTime });
};

/*
 * Make a hh:mm notation for seconds within a day.
 * Eg. 3600 will result in 01:00.
 */
export const secondsToHHMM = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

export const dateToHHMM = (date: Date): string => {
  return format(date, 'HH:mm');
};

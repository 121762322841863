/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `IC` - inconsistent
 * * `DG` - final
 * * `VG` - provisional
 * * `IT` - withdrawn
 * * `ID` - submitted
 * * `HE` - recovered
 * * `` - ---
 */
export enum ReportedHorseRelocationStatusEnum {
  IC = 'IC',
  DG = 'DG',
  VG = 'VG',
  IT = 'IT',
  ID = 'ID',
  HE = 'HE',
}

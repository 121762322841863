import React from 'react';
import { AvatarSize } from '.';
import Base from './Base';

interface Props {
  size?: AvatarSize;
  initials: string;
  color?: string;
  uuid?: string; // Generates a color from this string
  disabled?: boolean;
}

// A circle with a max of 2 initials in it.
export default function Initials({ initials, color, size = AvatarSize.Normal, uuid, disabled }: Props): JSX.Element {
  return (
    <Base color={color} size={size} uuid={uuid} disabled={disabled}>
      {initials.slice(0, 2)}
    </Base>
  );
}

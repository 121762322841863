import React, { ReactNode } from 'react';

interface Props {
  iconUrl?: string;
  title?: string;
  description?: string;
  buttons?: ReactNode;
}

function IntegrationRow({ iconUrl, title, description, buttons }: Props): JSX.Element {
  return (
    <li className='flex gap-2 items-top border-b pb-4 last:border-b-0'>
      <div className='mt-1 shrink-0 w-10 h-10 rounded bg-neutral-200'>
        {iconUrl && <img className='rounded' src={iconUrl} alt={title} />}
      </div>
      <div className='grow flex flex-col md:flex-row'>
        <div className='grow'>
          <p>{title}</p>
          <p className='pb-2 md:pb-0 pr-2 text-sm text-gray-500'>{description}</p>
        </div>
        {buttons}
      </div>
    </li>
  );
}

export default IntegrationRow;

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `CLEAR` - clear
 * * `PASTEL_GRAY` - Pastel gray
 * * `YELLOW` - Yellow
 * * `PASTEL_YELLOW` - Pastel yellow
 * * `PUTTY` - Putty
 * * `PASTEL_ORANGE` - Pastel orange
 * * `SALMON` - Salmon
 * * `PASTEL_RED` - Pastel red
 * * `PINK` - Pink
 * * `RED` - Red
 * * `PASTEL_BLUE` - Pastel blue
 * * `PURPLE` - Purple
 * * `PASTEL_PISTACHIO` - Pastel pistachio
 * * `GREEN` - Green
 * * `PASTEL_GREEN` - Pastel green
 * * `PISTACHIO_GREEN` - Pistachio green
 * * `` - ---
 */
export enum NIFAStrawColorEnum {
  CLEAR = 'CLEAR',
  PASTEL_GRAY = 'PASTEL_GRAY',
  YELLOW = 'YELLOW',
  PASTEL_YELLOW = 'PASTEL_YELLOW',
  PUTTY = 'PUTTY',
  PASTEL_ORANGE = 'PASTEL_ORANGE',
  SALMON = 'SALMON',
  PASTEL_RED = 'PASTEL_RED',
  PINK = 'PINK',
  RED = 'RED',
  PASTEL_BLUE = 'PASTEL_BLUE',
  PURPLE = 'PURPLE',
  PASTEL_PISTACHIO = 'PASTEL_PISTACHIO',
  GREEN = 'GREEN',
  PASTEL_GREEN = 'PASTEL_GREEN',
  PISTACHIO_GREEN = 'PISTACHIO_GREEN',
}

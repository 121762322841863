/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `KI` - KI (self-pregnancy)
 * * `ET` - ET
 * * `ICSI` - ICSI
 */
export enum UsageTypeEnum {
  KI = 'KI',
  ET = 'ET',
  ICSI = 'ICSI',
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `REGULAR` - Regular
 * * `PICK_UP` - Pick up
 * * `SAME_DAY_DELIVERY` - Same day delivery
 * * `NEXT_DAY_DELIVERY` - Next day delivery
 * * `SUNDAY_HOLIDAY_DELIVERY` - Sunday and holiday delivery
 */
export enum ShippingServiceTypeEnum {
  REGULAR = 'REGULAR',
  PICK_UP = 'PICK_UP',
  SAME_DAY_DELIVERY = 'SAME_DAY_DELIVERY',
  NEXT_DAY_DELIVERY = 'NEXT_DAY_DELIVERY',
  SUNDAY_HOLIDAY_DELIVERY = 'SUNDAY_HOLIDAY_DELIVERY',
}

import ApiErrorParser from 'api/ApiErrorParser';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

interface ReturnType<T extends FieldValues> {
  fieldError: (key: keyof T) => string | undefined;
  nonFieldErrors: string[];
  setApiError: Dispatch<SetStateAction<ApiErrorParser<T> | undefined>>;
  hasApiError: boolean;
}

// Use this hook for form validator. It will automatically combine api and zod
// errors. Use the `fieldError` function to attach an error to an input field.
// Use the `nonFieldErrors` for all generic errors.
function useFormError<T extends FieldValues>(schema: z.ZodType, formErrors: FieldErrors<T>): ReturnType<T> {
  const [apiError, setApiError] = useState<ApiErrorParser<T> | undefined>();
  const [requestedFields, setRequestedFields] = useState<(keyof T)[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setRequestedFields([]);
  }, [schema]);

  const fieldError = useCallback(
    (key: keyof T): string | undefined => {
      if (!requestedFields.includes(key)) {
        setRequestedFields(prev => [...prev, key]);
      }
      if (formErrors && formErrors?.[key]?.message) {
        return formErrors?.[key]?.message?.toString();
      }
      return apiError?.fieldError(key);
    },
    [formErrors, apiError, requestedFields],
  );

  const nonFieldErrors = useMemo((): string[] => {
    // get zod object keys recursively
    const zodKeys = <T extends z.ZodTypeAny>(schema: T): string[] => {
      // make sure schema is not null or undefined
      if (schema === null || schema === undefined) return [];
      // check if schema is nullable or optional
      if (schema instanceof z.ZodNullable || schema instanceof z.ZodOptional) return zodKeys(schema.unwrap());
      // check if schema is an array
      if (schema instanceof z.ZodArray) return zodKeys(schema.element);
      // check if schema is an object
      if (schema instanceof z.ZodObject) {
        // get key/value pairs from schema
        const entries = Object.entries(schema.shape);
        return entries.map(entry => entry[0]);
      }
      // return empty array
      return [];
    };

    let messages: string[] = [];

    if (formErrors) {
      for (const zodKey of zodKeys(schema)) {
        const found = requestedFields.find(field => field === zodKey);
        if (!found) {
          const errorText = formErrors?.[zodKey]?.message?.toString() ?? t('unknown-error', 'Unknown error');
          messages.push(`${zodKey.toString()}: ${errorText}`);
        }
      }
    }
    if (apiError) {
      messages = messages.concat(apiError.nonFieldErrorsStrings());
    }
    return messages;
  }, [requestedFields, apiError, schema, formErrors, t]);

  return {
    fieldError,
    nonFieldErrors,
    setApiError,
    hasApiError: apiError !== undefined,
  };
}

export default useFormError;

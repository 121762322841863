import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import PedigreeHierarchyList from 'components/Pedigree/PedigreeHierarchyList';
import { useOrganization } from 'context/OrganizationContext';
import { HorseDetail, HorsesService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { TextInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { zodInputIsRequired } from 'utilities/zod';

interface Props {
  title: string;
  horse: HorseDetail;
  isVisible: boolean;
  closeModal: () => void;
  onUpdate: (horse: HorseDetail) => void;
}

function HorseUpdatePedigreeModal({ title: modalTitle, horse, isVisible, closeModal, onUpdate }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();

  // Form validation
  const schema = useMemo(() => {
    return schemas.HorseDetail.pick({
      sire: true,
      siresire: true,
      siredam: true,
      siregrandsire1: true,
      siregranddam1: true,
      siregrandsire2: true,
      siregranddam2: true,
      dam: true,
      damsire: true,
      damdam: true,
      damgrandsire1: true,
      damgranddam1: true,
      damgrandsire2: true,
      damgranddam2: true,
    });
  }, []);

  // Construct the default values
  const defaultValues = useMemo((): Partial<HorseDetail> => {
    return {
      sire: horse.sire,
      siresire: horse.siresire,
      siredam: horse.siredam,
      siregrandsire1: horse.siregrandsire1,
      siregranddam1: horse.siregranddam1,
      siregrandsire2: horse.siregrandsire2,
      siregranddam2: horse.siregranddam2,
      dam: horse.dam,
      damsire: horse.damsire,
      damdam: horse.damdam,
      damgrandsire1: horse.damgrandsire1,
      damgranddam1: horse.damgranddam1,
      damgrandsire2: horse.damgrandsire2,
      damgranddam2: horse.damgranddam2,
    };
  }, [horse]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm<HorseDetail>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Close event for the modal
   */
  const onClose = (resetForm = true) => {
    closeModal();
    if (resetForm) {
      reset(defaultValues);
    }
  };

  /**
   * Function invoked after the modal has been closed
   */
  const onClosed = () => {
    // clear the errors
    clearErrors();
    setApiError(undefined);
  };

  /**
   * Submit event handler, update the data via the API for this user
   */
  const onSubmit: SubmitHandler<HorseDetail> = async (data: HorseDetail) => {
    if (!horse || !selectedOrganization) return;
    setSubmitting(true);
    try {
      const updateHorsePromise = HorsesService.horsesPartialUpdate({
        organisationUid: selectedOrganization.uid,
        uid: horse.uid,
        requestBody: data,
      });
      const updatedHorse = await updateHorsePromise;

      onUpdate(updatedHorse);

      closeModal();
    } catch (error) {
      setApiError(new ApiErrorParser<HorseDetail>(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Update the values when we open the modal
   */
  useEffect(() => {
    if (isVisible) {
      reset(defaultValues);
    }
  }, [defaultValues, isVisible, reset]);

  return (
    <PageModal
      open={isVisible}
      parentElement='form'
      parentProps={{ id: 'updateHorse', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      onClosed={onClosed}
    >
      <PageModalTitle title={modalTitle} onClose={onClose} />
      <PageModalContent>
        <ErrorSection className='mb-4' errors={nonFieldErrors} />

        <PedigreeHierarchyList
          sire={
            <TextInput
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'sire')}
              error={fieldError('sire')}
              label={t('sire', 'Sire')}
              {...register('sire')}
            />
          }
          siresire={
            <TextInput
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'siresire')}
              error={fieldError('siresire')}
              label={t('siresire', 'Sire sire')}
              {...register('siresire')}
            />
          }
          siregrandsire1={
            <TextInput
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'siregrandsire1')}
              error={fieldError('siregrandsire1')}
              label={t('siregrandsire', 'Sire grandsire')}
              {...register('siregrandsire1')}
            />
          }
          siregranddam1={
            <TextInput
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'siregranddam1')}
              error={fieldError('siregranddam1')}
              label={t('siregranddam', 'Sire granddam')}
              {...register('siregranddam1')}
            />
          }
          siredam={
            <TextInput
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'siredam')}
              error={fieldError('siredam')}
              label={t('siredam', 'Sire dam')}
              {...register('siredam')}
            />
          }
          siregranddam2={
            <TextInput
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'siregranddam2')}
              error={fieldError('siregranddam2')}
              label={t('siregranddam', 'Sire granddam')}
              {...register('siregranddam2')}
            />
          }
          siregrandsire2={
            <TextInput
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'siregrandsire2')}
              error={fieldError('siregrandsire2')}
              label={t('siregrandsire', 'Sire grandsire')}
              {...register('siregrandsire2')}
            />
          }
          dam={
            <TextInput
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'dam')}
              error={fieldError('dam')}
              label={t('dam', 'Dam')}
              {...register('dam')}
            />
          }
          damsire={
            <TextInput
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'damsire')}
              error={fieldError('damsire')}
              label={t('damsire', 'Dam sire')}
              {...register('damsire')}
            />
          }
          damgrandsire1={
            <TextInput
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'damgrandsire1')}
              error={fieldError('damgrandsire1')}
              label={t('damgrandsire', 'Dam grandsire')}
              {...register('damgrandsire1')}
            />
          }
          damgranddam1={
            <TextInput
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'damgranddam1')}
              error={fieldError('damgranddam1')}
              label={t('damgranddam', 'Dam granddam')}
              {...register('damgranddam1')}
            />
          }
          damdam={
            <TextInput
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'damdam')}
              error={fieldError('damdam')}
              label={t('damdam', 'Dam dam')}
              {...register('damdam')}
            />
          }
          damgrandsire2={
            <TextInput
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'damgrandsire2')}
              error={fieldError('damgrandsire2')}
              label={t('damgrandsire', 'Dam grandsire')}
              {...register('damgrandsire2')}
            />
          }
          damgranddam2={
            <TextInput
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'damgranddam2')}
              error={fieldError('damgranddam2')}
              label={t('damgranddam', 'Dam granddam')}
              {...register('damgranddam2')}
            />
          }
        />
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            disabled: submitting,
            onClick: onClose,
            variant: ButtonVariant.Default,
            type: 'button',
            text: t('cancel', 'Cancel'),
          },
          {
            formId: 'updateHorse',
            loading: submitting,
            variant: ButtonVariant.Primary,
            type: 'submit',
            text: t('save', 'Save'),
          },
        ]}
      />
    </PageModal>
  );
}

export default HorseUpdatePedigreeModal;

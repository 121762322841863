import { ApiErrorParserBase, ApiErrorType } from 'api/ApiErrorParser';
import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from '../ui/Button';

export interface BreadCrumb {
  name: string;
  path: string;
}

export interface PageAction {
  icon?: ReactNode;
  text: string;
  disabled?: boolean;
  buttonVariant?: ButtonVariant;
  onClick?: () => void;
  // when the button is flagged a primary,
  // we will show it as plus (+) icon in the footer
  // TODO We should allow multiple items that can be showed via the dropDownMenu (slide in from bottom)
  isMobileAddAction?: boolean;
}

interface PageError {
  title: string;
  description: string;
  isFatal: boolean;
}

interface Props {
  title?: string;
  children: React.ReactNode;
  breadCrumbs?: BreadCrumb[];
  actions?: PageAction[];
  error?: PageError;
}

type PageContextType = {
  title: string | undefined;
  setTitle: (title: string | undefined) => void;
  breadCrumbs: BreadCrumb[];
  setBreadCrumbs: (breadCrumbs: BreadCrumb[]) => void;
  actions: PageAction[];
  setActions: (actions: PageAction[]) => void;
  error: PageError | undefined;
  setError: (pageError: PageError | undefined) => void;
  setApiError: (pageError: ApiErrorParserBase | undefined, isFatal?: boolean) => void;
};

const PageContext = createContext<PageContextType>({
  title: undefined,
  setTitle: () => console.warn('no page context provider'),
  breadCrumbs: [],
  setBreadCrumbs: () => console.warn('no page context provider'),
  actions: [],
  setActions: () => console.warn('no page context provider'),
  error: undefined,
  setError: () => console.warn('no page context provider'),
  setApiError: () => console.warn('no page context provider'),
});

export function usePage(): PageContextType {
  return useContext(PageContext);
}

// The page context communicates between content pages and the navigation.
export function PageProvider({ children, title: givenTitle, breadCrumbs: givenCrumbs, actions: givenActions }: Props): JSX.Element {
  const { t } = useTranslation();
  const [title, setTitle] = useState<string | undefined>(givenTitle);
  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumb[]>(givenCrumbs ?? []);
  const [actions, setActions] = useState<PageAction[]>(givenActions ?? []);
  const [error, setErrorInternal] = useState<PageError>();

  const setError = useCallback((error?: PageError) => {
    setErrorInternal(error);
  }, []);

  const setApiError = useCallback(
    (error?: ApiErrorParserBase, isFatal?: boolean) => {
      if (!error) {
        setErrorInternal(undefined);
        return;
      }
      let title = t('error', 'Error');
      switch (error.errorType) {
        case ApiErrorType.ApplicationError:
          title = t('application-error', 'Application error');
          break;
        case ApiErrorType.NetworkError:
          title = t('network-error', 'Network error');
          break;
        case ApiErrorType.CancelError:
          title = t('cancel-error', 'Cancel error');
          break;
      }
      setErrorInternal({
        title,
        description: error.nonFieldErrorsStrings().join(' ') ?? t('unknown-error', 'Unknown error'),
        isFatal: isFatal ?? true,
      });
    },
    [t],
  );

  return (
    <PageContext.Provider value={{ title, setTitle, breadCrumbs, setBreadCrumbs, actions, setActions, error, setError, setApiError }}>
      {children}
    </PageContext.Provider>
  );
}

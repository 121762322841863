import { Check, Link } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import classNames from 'classnames';
import { useOrganization } from 'context/OrganizationContext';
import { OauthService, OrganisationOAuth } from 'openapi';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { PageModal } from 'ui/Modals';
import { ActionProps, PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { ButtonVariant } from '../../ui/Button';
import { IntegrationDetailSetup } from './Integrations';

interface Props {
  visible: boolean;
  integration?: IntegrationDetailSetup;
  onRequestCloseModal: () => void;
}

export default function SetupIntegrationModal({ visible, onRequestCloseModal, integration }: Props): JSX.Element {
  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();
  const [apiError, setApiError] = useState<ApiErrorParser<OrganisationOAuth> | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // To indicate that we've opened an external window to setup the connection.
  const [newWindowOpened, setNewWindowOpened] = useState<boolean>(false);

  const setupIntegration = useCallback(async () => {
    const requestBody: OrganisationOAuth = {
      organisation_uid: selectedOrganization?.uid ?? '',
      provider_name: integration?.id.toString() ?? '',
    };
    setIsSubmitting(true);
    const promise = OauthService.apiV5OauthLoginCreate({ requestBody });
    setIsSubmitting(true);
    setApiError(undefined);
    try {
      // We open a new browser window to setup the connection. It's important
      // to not redirect the current page because we will leave the app. When
      // the connection is successful then the provider will redirect to
      // https://{frontend_url}/p/oauth2-callback-success?provider={name}
      window.open((await promise).redirect_uri, 'popUpWindow', `width=800,height=800`);
      setNewWindowOpened(true);
    } catch (e) {
      setApiError(new ApiErrorParser<OrganisationOAuth>(e));
    }
    setIsSubmitting(false);
  }, [selectedOrganization, integration]);

  const actions = useMemo((): ActionProps[] => {
    if (newWindowOpened) {
      return [
        {
          variant: ButtonVariant.Primary,
          text: t('close', 'Close'),
          onClick: onRequestCloseModal,
        },
      ];
    } else {
      return [
        {
          loading: isSubmitting,
          variant: ButtonVariant.Primary,
          text: t('authorize-and-connect', 'Authorize and connect'),
          onClick: setupIntegration,
        },
      ];
    }
  }, [t, isSubmitting, newWindowOpened, setupIntegration, onRequestCloseModal]);

  const closed = () => {
    setApiError(undefined);
    setNewWindowOpened(false);
  };

  return (
    <>
      <PageModal open={visible} width={PageModalWidth.Xs} onClosed={closed}>
        <PageModalTitle title={`${integration?.title} ${t('integration', 'integration')}`} onClose={onRequestCloseModal} />
        <PageModalContent>
          <div className='h-full flex flex-col'>
            <ErrorSection errors={apiError} />
            <div className='h-full flex flex-col items-center justify-center grow gap-10 md:mt-10'>
              <div className='flex w-full items-center justify-center gap-6'>
                <img className='rounded-lg w-16 h-16' src={integration?.iconUrl ?? ''} alt={integration?.title ?? ''} />
                <hr className='w-8 md:w-16' />
                <div className={classNames('rounded-full', { 'p-2 border-2 border-emerald-500 text-emerald-500': integration?.success })}>
                  {integration?.success ? <Check size={30} /> : <Link size={30} />}
                </div>
                <hr className='w-8 md:w-16' />
                <img className='rounded-lg w-16 h-16' src='/icons/icon-120.png' alt='Equinem' />
              </div>
              {integration?.success === true ? (
                <>
                  <p className='text-lg font-medium mb-2'>
                    {t('setup-integration-success', 'Successfully connected to')} {integration.title}.
                  </p>
                </>
              ) : (
                <p>
                  {t(
                    'setup-integration-desc',
                    'In order to connect, the integration will have to authorize us. This will open a new window. Please follow the steps in that window to complete integration with Equinem.',
                  )}
                </p>
              )}
            </div>
          </div>
        </PageModalContent>
        <PageModalActions actions={actions} />
      </PageModal>
    </>
  );
}

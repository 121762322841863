import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { useDatePicker, DPDay } from '@rehookify/datepicker';
import classNames from 'classnames';
import React, { useCallback } from 'react';

// Simple helper to style a class.
const getDayClassName = (className: string, { selected, inCurrentMonth, now }: DPDay) =>
  classNames(className, {
    'bg-white bg-opacity-25': selected && !now,
    'opacity-0': !inCurrentMonth,
    'bg-white text-primary': now || (selected && now),
  });

interface Props {
  selectedDate: Date;
  onSelectedDateChanged: (date: Date) => void;
}

/**
 * An inline date picker. This date picker is mainly used in the calendar views for mobile devices.
 */
export default function DatePicker({ selectedDate, onSelectedDateChanged }: Props): JSX.Element {
  const onDatesChange = (dates: Date[]) => {
    if (onSelectedDateChanged) {
      onSelectedDateChanged(dates[0] ?? new Date());
    }
  };

  const {
    data: { calendars, weekDays },
    propGetters: { dayButton },
  } = useDatePicker({
    selectedDates: [selectedDate],
    onDatesChange,
    offsetDate: selectedDate,
    calendar: {
      startDay: 1,
    },
    locale: {
      day: 'numeric',
      weekday: 'narrow',
    },
  });

  const { days } = calendars[0];

  // Navigate x amount of months forward or backwards.
  const addMonth = useCallback(
    (amount: number) => {
      onSelectedDateChanged(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + amount, 1));
    },
    [selectedDate, onSelectedDateChanged],
  );

  return (
    <div className='text-white space-y-2'>
      <div className='flex justify-between px-6 pb-4'>
        <button className='bg-white p-2 rounded-full text-primary' onClick={() => addMonth(-1)}>
          <CaretLeft />
        </button>
        <button className='bg-white p-2 rounded-full text-primary' onClick={() => addMonth(1)}>
          <CaretRight />
        </button>
      </div>
      <div className='grid grid-cols-7 gap-y-2'>
        {weekDays.map((day, index) => (
          <p key={day + index} className='text-xs text-center opacity-50'>
            {day}
          </p>
        ))}
      </div>
      <div className='grid grid-cols-7 space-y-2 place-items-center'>
        {days.map(day => (
          <button
            key={day.$date.toString()}
            className={getDayClassName('w-6 h-6 rounded-full text-xs text-center', day)}
            {...dayButton(day)}
          >
            {day.day}
          </button>
        ))}
      </div>
    </div>
  );
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivityType } from '../models/ActivityType';
import type { PatchedActivityType } from '../models/PatchedActivityType';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ActivitytypesService {
  /**
   * @returns ActivityType
   * @throws ApiError
   */
  public static activitytypesList({
    organisationUid,
    o,
  }: {
    organisationUid: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
  }): CancelablePromise<Array<ActivityType>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/activitytypes',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'o': o,
      },
    });
  }
  /**
   * @returns ActivityType
   * @throws ApiError
   */
  public static activitytypesCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody?: ActivityType,
  }): CancelablePromise<ActivityType> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/activitytypes',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns ActivityType
   * @throws ApiError
   */
  public static activitytypesRetrieve({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<ActivityType> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/activitytypes/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * @returns ActivityType
   * @throws ApiError
   */
  public static activitytypesUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: ActivityType,
  }): CancelablePromise<ActivityType> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/activitytypes/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns ActivityType
   * @throws ApiError
   */
  public static activitytypesPartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedActivityType,
  }): CancelablePromise<ActivityType> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/activitytypes/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public static activitytypesDestroy({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{organisation__uid}/activitytypes/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
}

import { CaretRight } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { ReactNode, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import ButtonBadge from 'ui/Button/ButtonBadge';
import { AppRoutes } from 'AppRoutes';
import useRvoReportCount from 'hooks/UseRvoReportCount';
import { SubMenuItemConfig } from '../Layout';

const iconSize = '25px';
const itemHeight = '38px';

function SubMenuItem({
  path,
  name,
  active,
  setOpen,
}: {
  name: string;
  path: string;
  active: boolean;
  setOpen: (open: boolean) => void;
}): JSX.Element {
  const navigate = useNavigate();
  const { width } = useScreenSize();
  const { reportCount } = useRvoReportCount();
  return (
    <div
      className={classNames(
        'overflow-x-hidden mx-1 rounded items-center flex justify-between duration-200 transition-all cursor-pointer hover:bg-neutral-200',
        {
          'bg-neutral-200': active,
        },
      )}
      style={{ height: itemHeight }}
      onClick={() => {
        if (width <= ScreenSize.sm) {
          setOpen(false);
        }
        navigate(path);
      }}
    >
      <div className={classNames('w-1 rounded ml-1 h-7', { 'bg-neutral-400': active })} />
      <div className={'mr-3 ml-2 flex items-center justify-center'} style={{ height: iconSize, width: iconSize }} />
      <div className={'flex-1 text-sm flex items-center justify-between'}>
        {name}
        {path === AppRoutes.HorsesLocationHistory.path && <ButtonBadge badge={reportCount} className='mr-1' />}
      </div>
    </div>
  );
}

// The menu item that shows on hover when the sidebar is collapsed.
function HoverSubMenuItem({ path, name, active }: { name: string; path: string; active: boolean }): JSX.Element {
  const navigate = useNavigate();

  return (
    <div
      className={classNames(
        'px-2 mx-1 rounded items-center flex justify-between duration-200 transition-all cursor-pointer hover:bg-neutral-50 hover:text-primary whitespace-nowrap',
        {
          'bg-neutral-50 text-primary': active,
          'text-white': !active,
        },
      )}
      style={{ height: itemHeight }}
      onClick={() => navigate(path)}
    >
      <p>{name}</p>
    </div>
  );
}

export default function MenuItem({
  menuOpened,
  path,
  name,
  icon,
  subMenu = [],
  menuLocation,
  setOpen,
}: {
  menuOpened: boolean;
  name: string;
  path: string;
  icon: ReactNode;
  subMenu?: SubMenuItemConfig[];
  menuLocation: 'top' | 'bottom';
  setOpen: (open: boolean) => void;
}): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const id = `menu-item-${path?.replace('/', '-')}`;
  const { width } = useScreenSize();

  // TODO: This logic needs to be tested for dynamic URLs
  const subMenuOptions = useMemo(() => {
    return subMenu
      .filter(item => item.isEnabled)
      .map(subMenuItem => {
        // First remove the query string of the path. This way we can determine
        // if we need to select it.
        let submenuPath = subMenuItem.path;
        const queryParamIndex = submenuPath.indexOf('?');
        if (queryParamIndex !== -1) {
          submenuPath = submenuPath.slice(0, queryParamIndex);
        }
        return {
          ...subMenuItem,
          active: location.pathname.startsWith(submenuPath),
        };
      });
  }, [location.pathname, subMenu]);

  const active = useMemo(() => {
    setExpanded(location.pathname.startsWith(path));
    return location.pathname.startsWith(path);
  }, [location, path]);

  const showParentActiveStatus = useMemo(() => {
    return (!subMenuOptions.length || !menuOpened) && active;
  }, [menuOpened, subMenuOptions, active]);

  const handleClick = () => {
    if (subMenuOptions?.length) {
      if (width < ScreenSize.md) {
        // On mobile devices we just open the menu. We do this because we want
        // the user to select a child menu item.
        setExpanded(!expanded);
      } else {
        // On devices were the menu is always visible we just navigate to the
        // first item.
        navigate(subMenuOptions[0].path);
      }
    } else {
      navigate(path);
      if (width < ScreenSize.md) {
        setOpen(false);
      }
    }
  };
  return (
    <div className='group relative'>
      {!menuOpened && subMenuOptions.length > 0 && (
        <div
          className={classNames(
            'absolute hidden md:group-hover:block ml-sidebar-hover-menu-left rounded-xl bg-primary px-2 py-4 shadow-2xl',
            { '-bottom-3': menuLocation === 'bottom', '-mt-4': menuLocation === 'top' },
          )}
        >
          <div className='space-y-1'>
            {subMenuOptions.map((subMenuItem, key) => (
              <HoverSubMenuItem key={key} {...subMenuItem} />
            ))}
          </div>
        </div>
      )}
      <div
        className={classNames(
          'mt-1 m-1 rounded items-center flex justify-between duration-200 transition-all gap-2 cursor-pointer hover:bg-neutral-200',
          {
            'bg-neutral-200': showParentActiveStatus,
            'pl-5': menuOpened,
            'pl-6': !menuOpened,
          },
        )}
        style={{ height: itemHeight }}
        onClick={handleClick}
      >
        <div style={{ height: iconSize, width: iconSize }}>{icon}</div>
        {menuOpened && (
          <div className={classNames('flex-1 flex flex-row text-sm items-center justify-between pr-2')}>
            <span>{name}</span>
            {subMenuOptions.length > 0 && (
              <CaretRight size={18} className={classNames('text-neutral-600 transition-all duration-400', { 'rotate-90': expanded })} />
            )}
          </div>
        )}
      </div>
      <div
        className={classNames('relative transition-all duration-200', {
          'opacity-0 overflow-hidden': !menuOpened || !expanded || subMenu.length === 0,
        })}
        style={{ height: expanded && menuOpened ? `${document.getElementById(id)?.offsetHeight}px` : 0 }}
      >
        <div id={id} className='space-y-1'>
          {subMenuOptions.map((subMenuItem, key) => (
            <SubMenuItem setOpen={setOpen} key={key} {...subMenuItem} />
          ))}
        </div>
      </div>
    </div>
  );
}

import { useCallback, useEffect, useRef, useState } from 'react';

// See https://tailwindcss.com/docs/responsive-design for the sizes
export enum ScreenSize {
  'xs' = 475,
  'sm' = 640,
  'md' = 768,
  'lg' = 1024,
  'xl' = 1280,
  '2xl' = 1536,
  '3xl' = 1750,
}

interface WindowSize {
  width: number;
  height: number;
}

/**
 * Custom hook to get the window screen size
 */
export default function useScreenSize(delay = 200): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const timer = useRef<NodeJS.Timeout>();

  /**
   * use a debounce function to avoid a sluggy UI
   */
  const handleSize = useCallback(() => {
    // clear the previous timer
    clearTimeout(timer.current);

    // create a new timer
    timer.current = setTimeout(() => {
      setWindowSize(prevState => {
        // avoid rerenders by setting only the state when something has changed
        // otherwise we just return the current state
        if (prevState.height !== window.innerHeight || prevState.width !== window.innerWidth) {
          return {
            width: window.innerWidth,
            height: window.innerHeight,
          };
        }

        return prevState;
      });
    }, delay);
  }, [delay]);

  /**
   * Listen to the resize on the window object
   */
  useEffect(() => {
    window.addEventListener('resize', handleSize);
    handleSize();
    return () => window.removeEventListener('resize', handleSize);
  }, [handleSize]);

  return windowSize;
}

import { useEffect, useState } from 'react';

interface ReturnType {
  now: Date;
}

interface Props {
  interval?: number; // mili sec refresh rate
}

/**
 * Returns the current date refreshed every minute.
 */
export default function useRefreshingNow(props?: Props): ReturnType {
  const [now, setNow] = useState<Date>(new Date());

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        setNow(new Date());
      },
      props?.interval ?? 60 * 1000, // Refresh once every minute by default.
    );
    return () => clearInterval(intervalId);
  }, [props]);

  return {
    now,
  };
}

import { Pencil } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import UploadFile from 'api/UploadFile';
import { useConfig } from 'context/ConfigContext';
import { useOrganization } from 'context/OrganizationContext';
import useFileSelect, { SelectionType } from 'hooks/UseFileSelect';
import { ApiError, OrganisationService, PatchedOrganisation } from 'openapi';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { Tile } from 'ui/Layout/Tile';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';
import mime from 'mime';

interface Props {
  className?: string;
}

/**
 * Tile for showing and editing the company logo
 */
export default function LogoTile({ className }: Props): JSX.Element {
  const { selectedOrganizationUid, selectedOrganizationDetails, refresh } = useOrganization();
  const { config } = useConfig();
  const { t } = useTranslation();
  const [previewLoading, setPreviewLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  // Reset the loading state of the image when the org changes.
  useEffect(() => {
    if (!selectedOrganizationDetails) {
      setPreviewLoading(true);
    }
    if (selectedOrganizationDetails && !selectedOrganizationDetails.logo) {
      setPreviewLoading(false);
    }
  }, [selectedOrganizationDetails]);

  const { openDialog } = useFileSelect({
    onFilesSelected: (files: File[]) => {
      if (files.length === 0) {
        return;
      }
      const file = files[0];
      setError(undefined);
      const contentTypeFromName = mime.getType(file.name);
      if (!contentTypeFromName) {
        setError('Failed to get content type from filename');
        return;
      }
      uploadLogo(file.arrayBuffer(), contentTypeFromName).catch(e => {
        if (e instanceof ApiError) {
          const apiError = new ApiErrorParser<void>(e);
          setError(apiError.nonFieldErrors().join(', '));
        }
        setError((e as Error).message);
      });
    },
  });

  const uploadLogo = async (imageData: Promise<ArrayBuffer>, mimeType: string) => {
    if (!selectedOrganizationUid) return;

    // Required because we use vercel to upload the image.
    if (!config?.hasServerlessFunctions) throw Error('Serverless functions are not enabled.');

    // Upload the image to s3 via Vercel serverless function.
    const url = await UploadFile.uploadOrganizationLogo(await imageData, selectedOrganizationUid, mimeType);

    // Patch our organization with the logo image s3 url.
    const patchedOrganization: PatchedOrganisation = { logo: url.toString() };
    const promise = OrganisationService.rootPartialUpdate({ uid: selectedOrganizationUid, requestBody: patchedOrganization });

    // update
    await promise;

    // refresh the list of organizations
    refresh();
  };

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo(() => {
    return [
      {
        onClick: () => openDialog(SelectionType.Files, false, ['image/jpeg', 'image/png']),
        text: t('edit', 'Edit'),
        buttonVariant: ButtonVariant.Default,
        icon: <Pencil />,
      },
    ];
  }, [openDialog, t]);

  return (
    <Tile title={t('logo', 'Logo')} className={className} actions={tileActions}>
      <div className='flex flex-col gap-4'>
        <ErrorSection errors={error} />
        <div className='w-64 h-28 border relative'>
          {selectedOrganizationDetails?.logo ? (
            <img
              src={selectedOrganizationDetails.logo}
              onLoad={() => setPreviewLoading(false)}
              className='w-full h-full object-contain object-left-top border-none'
            />
          ) : (
            <div className='w-full h-full flex items-center justify-center text-gray-500 text-sm'>{t('no-logo-set', 'No logo set')}</div>
          )}
          {previewLoading && (
            <div className='absolute bottom-2 left-2'>
              <Spinner size={SpinnerSize.Small} />
            </div>
          )}
        </div>
      </div>
    </Tile>
  );
}

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../AppRoutes';

export default function Home(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    // Redirecting to Activities Planning untill we have a home screen in the applicaiton
    navigate(AppRoutes.HorsesList.path);
  }, [navigate]);
  return (
    <div className={''}>
      <p>{t('home.title', 'Home')}</p>
      <p>{t('home.welcome', 'Welcome to Equinem')}</p>
    </div>
  );
}

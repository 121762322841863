const API_V2_BASE_URL = '/api/v2/';

export const ApiV2 = {
  Users: `${API_V2_BASE_URL}users/`,
  Roles: `${API_V2_BASE_URL}roles/`,
};

export const Api = {
  Emails: `/accounts/email/`,
};

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DayPartStartTime } from '../models/DayPartStartTime';
import type { PatchedDayPartStartTime } from '../models/PatchedDayPartStartTime';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DaypartsService {
  /**
   * Retrieve, update or delete an stable day session instance.
   * @returns DayPartStartTime
   * @throws ApiError
   */
  public static daypartsList({
    organisationUid,
  }: {
    organisationUid: string,
  }): CancelablePromise<Array<DayPartStartTime>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/dayparts',
      path: {
        'organisation__uid': organisationUid,
      },
    });
  }
  /**
   * Retrieve, update or delete an stable day session instance.
   * @returns DayPartStartTime
   * @throws ApiError
   */
  public static daypartsCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody: DayPartStartTime,
  }): CancelablePromise<DayPartStartTime> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/dayparts',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Retrieve, update or delete an stable day session instance.
   * @returns DayPartStartTime
   * @throws ApiError
   */
  public static daypartsRetrieve({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<DayPartStartTime> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/dayparts/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * Retrieve, update or delete an stable day session instance.
   * @returns DayPartStartTime
   * @throws ApiError
   */
  public static daypartsUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody: DayPartStartTime,
  }): CancelablePromise<DayPartStartTime> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/dayparts/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Retrieve, update or delete an stable day session instance.
   * @returns DayPartStartTime
   * @throws ApiError
   */
  public static daypartsPartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedDayPartStartTime,
  }): CancelablePromise<DayPartStartTime> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/dayparts/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Retrieve, update or delete an stable day session instance.
   * @returns void
   * @throws ApiError
   */
  public static daypartsDestroy({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{organisation__uid}/dayparts/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
}

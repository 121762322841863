import { ModulePermissionsList } from 'openapi';

interface Permission {
  name: string;
  label: string;
}

export interface PermissionsPerModule {
  moduleName: string;
  moduleId: string;
  permissions: Permission[];
}

/**
 * The Permission parser class is responsible for handling the different states of the permissions without relying on the API.
 *
 * It receives the available permissions and the permissions from the API via the ModulePermissionsList type.
 * It then parses the permissions into a more usable format and keeps track of the staged permissions.
 *
 * You can reset (.resetStaged()) or commit (.commitStaged()) the staged permissions to the current selected permissions
 * so it reflects the change without having to call the API. With the .updatedStagedPermissions() you can get the permissions that are being updated.
 */
export class PermissionParser {
  // holds the available permissions per module
  availableModulePermissions: PermissionsPerModule[] = [];

  // holds the permissions per module per role
  rolePermissions: Map<string, Set<string>> = new Map();

  // holds both staged and current permissions
  stagedRolePermissions: Map<string, Set<string>> = new Map();

  // holds only the permissions that are being updated
  updatedStagedPermissions: Map<string, Set<string>> = new Map();

  constructor(availableModulePermissions: ModulePermissionsList, modulePermissions: ModulePermissionsList[]) {
    this.availableModulePermissions = this.parsePermission(availableModulePermissions);
    this.rolePermissions = this.parsePermissions(modulePermissions);
    this.stagedRolePermissions = this.parsePermissions(modulePermissions);
  }

  /**
   * Parse the permissions per module to a Map of Module that includes a Map of Roles that includes a Set of permissions
   * @param modulePermissions
   * @returns
   */
  private parsePermissions(modulePermissions: ModulePermissionsList[]): Map<string, Set<string>> {
    const roleMap = new Map<string, Set<string>>();

    for (const modulePermission of modulePermissions) {
      const roleUid = modulePermission.uid;
      const find = roleMap.get(roleUid);
      if (find) {
        for (const permission of modulePermission.module_permissions) {
          find.add(permission.id);
        }
      } else {
        roleMap.set(roleUid, new Set(modulePermission.module_permissions.map(val => val.id)));
      }
    }

    return roleMap;
  }

  /**
   * Update the staged permissions
   *
   * @param roleUid
   * @param newPermissions the new permissions that are being updated
   * @param targetPermissions this are the permissions can be updated
   */
  updateStagedPermissions(roleUid: string, newPermissions: Set<string>, targetPermissions: Permission[]): void {
    const find = this.stagedRolePermissions.get(roleUid);
    if (find) {
      for (const permission of targetPermissions) {
        find.delete(permission.name);
      }
      newPermissions.forEach(permission => {
        find.add(permission);
      });
      this.stagedRolePermissions.set(roleUid, find);
    } else {
      this.stagedRolePermissions.set(roleUid, newPermissions);
    }

    // Update the list of just the updated permissions
    this.updatedStagedPermissions = this.getStagedPermissions();
  }

  /**
   * Return the roles that has actual changes
   */
  private getStagedPermissions(): Map<string, Set<string>> {
    const changedPermissions = new Map();
    const changedRoles = new Set<string>();

    // find out which roles have changes
    this.stagedRolePermissions.forEach((stagedPermissions, key) => {
      const permissions = this.rolePermissions.get(key);
      if (permissions) {
        // perform a diff check to see if the permissions have changed
        if (stagedPermissions.symmetricDifference(permissions).size > 0) {
          changedRoles.add(key);
        }
      }
    });

    // get the full set of permissions for the changed roles
    changedRoles.forEach(roleUid => {
      const find = this.stagedRolePermissions.get(roleUid);
      if (find) {
        changedPermissions.set(roleUid, find);
      }
    });

    return changedPermissions;
  }

  /**
   * Parse the available permissions to a list of permissions per module
   *
   * @param modulePermissions
   * @returns
   */
  private parsePermission(modulePermissions: ModulePermissionsList): PermissionsPerModule[] {
    const permissions: PermissionsPerModule[] = [];

    modulePermissions.module_permissions.forEach(val => {
      const find = permissions.find(permission => permission.moduleId === val.module.id);
      if (!find) {
        permissions.push({
          moduleName: val.module.name,
          moduleId: val.module.id,
          permissions: [
            {
              name: val.id,
              label: val.name,
            },
          ],
        });
      } else {
        find.permissions.push({
          name: val.id,
          label: val.name,
        });
      }
    });

    return permissions;
  }

  /**
   * Reset the staged permissions to the current selected permissions
   */
  resetStaged(): void {
    this.stagedRolePermissions = new Map();
    this.updatedStagedPermissions = new Map();

    // reset the staged permissions with the current selected
    this.rolePermissions.forEach((value, key) => {
      this.stagedRolePermissions.set(key, new Set(value));
    });
  }

  /**
   * Commit the staged changes to the current selected permissions
   */
  commitStaged(): void {
    this.rolePermissions = new Map();
    this.updatedStagedPermissions = new Map();

    // copy the staged permissions to the current selected
    this.stagedRolePermissions.forEach((value, key) => {
      this.rolePermissions.set(key, new Set(value));
    });
  }
}

import { useEffect, useState } from 'react';
import { ApiPromises } from 'utilities/ApiPromises';

interface ReturnType {
  loading: boolean;
}

interface Props {
  apiPromises?: ApiPromises;
}

// Simple hook for loading feedback of ApiPromises class.
const useApiPromises = ({ apiPromises }: Props): ReturnType => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!apiPromises) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const promise = apiPromises.watchAll();
    promise
      .catch(() => {
        // No need to catch here. But we need this catch handler otherwise the
        // promise will throw and causes React to crash.
      })
      .finally(() => {
        setLoading(false);
      });
  }, [apiPromises]);

  return {
    loading,
  };
};

export default useApiPromises;

import { iLanguagesData, LANGUAGES_DATA } from './languages.data';

function languageById(id: string | undefined | null): iLanguagesData | undefined {
  if (!id) return undefined;
  return LANGUAGES_DATA.find(country => country.id.toLowerCase() === id.toLowerCase());
}

function languageByName(name: string | undefined): iLanguagesData | undefined {
  if (name === undefined) return undefined;
  return LANGUAGES_DATA.find(country => country.name.toLowerCase() === name.toLowerCase());
}

export { languageById, languageByName, LANGUAGES_DATA };
export type { iLanguagesData };

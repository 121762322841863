import React, { useEffect, useRef, useState } from 'react';
import PermissionPerModule from './PermissionsPerModule';
import { ModulePermissions, ModulePermissionsList, Role, RolesService } from 'openapi';
import classNames from 'classnames';
import { PermissionParser, PermissionsPerModule } from './PermissionParser';
import Button, { ButtonVariant } from 'ui/Button';
import { useTranslation } from 'react-i18next';
import { CaretLeft, CaretRight, DotsThreeVertical } from '@phosphor-icons/react';
import { useOrganization } from 'context/OrganizationContext';
import ApiErrorParser from 'api/ApiErrorParser';
import { ErrorSection } from 'ui/Error';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import SaveRoleModal from './SaveRoleModal';
import useModal from 'ui/Modals/UseModal';
import DropdownMenu from 'ui/DropdownMenu';
import DeleteRoleModal from './DeleteRoleModal';
import UsePermissionWidths from 'hooks/UsePermissionWidths';

interface Props {
  roles: Role[];
  availablePermissions: ModulePermissionsList | undefined;
  permissionList: ModulePermissionsList[] | undefined;
  // flag to show the last item in the list
  // mostly used when we have a new role added to the list
  showLast?: boolean;
  onRoleSaved?: (role: Role) => void;
  onRoleDeleted?: (role: Role) => void;
  onPermissionSaved?: () => void;
}

function Permissions({
  roles,
  availablePermissions: givenAvailablePermissions,
  permissionList,
  showLast,
  onRoleSaved,
  onRoleDeleted,
  onPermissionSaved,
}: Props): JSX.Element {
  const [visibleCount, setVisibleCount] = useState<number>(3);
  const [apiError, setApiError] = useState<ApiErrorParser<ModulePermissions[]> | undefined>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState<'left' | 'right' | undefined>('left');
  const [stagedPermissions, setStagedPermissions] = useState<Map<string, Set<string>>>(new Map());
  const [selectedPermissions, setSelectedPermissions] = useState<Map<string, Set<string>>>(new Map());
  const [updatedStagedPermissions, setUpdatedStagedPermissions] = useState<Map<string, Set<string>>>(new Map());
  const [availablePermissions, setAvailablePermissions] = useState<PermissionsPerModule[]>([]);
  const [selectedRole, setSelectedRole] = useState<Role>();
  const [disableNavButton, setDisableNavButton] = useState<boolean>(false);

  const modulePermissionParser = useRef<PermissionParser>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  const { width } = useScreenSize();
  const { closeModal: closeSaveModal, modalIsVisible: saveModalIsVisible, showModal: showSaveModal } = useModal();
  const { closeModal: closeDeleteModal, modalIsVisible: deleteModalIsVisible, showModal: showDeleteModal } = useModal();
  const { arrowWidth, columnWidth, labelWidth } = UsePermissionWidths();

  const maxItems = roles.length;

  /**
   * Save per role the permissions
   */
  const saveChanges = async () => {
    if (selectedOrganization && modulePermissionParser.current) {
      try {
        for (const [roleUid, permissions] of updatedStagedPermissions) {
          await RolesService.rolesModulePermissionsUpdate({
            organisationUid: selectedOrganization.uid,
            uid: roleUid,
            requestBody: {
              module_permissions: Array.from(permissions),
            } as ModulePermissions,
          });
        }

        // We commit the staged permissions after we have sent the request
        // this we can avoid reloading the data from the api
        modulePermissionParser.current.commitStaged();
        setSelectedPermissions(modulePermissionParser.current.rolePermissions);
        setUpdatedStagedPermissions(modulePermissionParser.current.updatedStagedPermissions);
        onPermissionSaved?.();
      } catch (e) {
        setApiError(new ApiErrorParser<ModulePermissions>(e));
      }
    }
  };

  /**
   * Clear the changes
   */
  const resetChanges = () => {
    if (modulePermissionParser.current && givenAvailablePermissions && permissionList) {
      modulePermissionParser.current.resetStaged();
      setStagedPermissions(modulePermissionParser.current.stagedRolePermissions);
      setUpdatedStagedPermissions(modulePermissionParser.current.updatedStagedPermissions);
    }
  };

  /**
   * Parse the permissions with a helper class and set the states so React can update the stuff
   */
  useEffect(() => {
    if (givenAvailablePermissions && permissionList) {
      const parser = new PermissionParser(givenAvailablePermissions, permissionList);
      setSelectedPermissions(parser.rolePermissions);
      setStagedPermissions(parser.stagedRolePermissions);
      setAvailablePermissions(parser.availableModulePermissions);
      modulePermissionParser.current = parser;
    }
  }, [givenAvailablePermissions, permissionList]);

  /**
   * We use a timeout to reset the slide direction after the animation is done as we use CSS animations
   */
  useEffect(() => {
    // disable the nav button to prevent double clicks
    setDisableNavButton(true);
    const timeout = setTimeout(() => {
      setSlideDirection(undefined);
      setDisableNavButton(false);
    }, 100);

    return () => clearTimeout(timeout);
  }, [slideDirection]);

  /**
   * If we want to show the last item in the list, we set the current index to the last item
   * Mostly used when we have a new role added to the list
   */
  useEffect(() => {
    if (showLast) {
      setCurrentIndex(maxItems - visibleCount);
    }
  }, [maxItems, showLast, visibleCount]);

  /**
   * Set the items to show based on the screen size
   */
  useEffect(() => {
    if (width > ScreenSize['3xl']) {
      setVisibleCount(6);
    } else if (width > ScreenSize['2xl']) {
      setVisibleCount(5);
    } else if (width > ScreenSize.xl) {
      setVisibleCount(4);
    } else if (width > ScreenSize.lg) {
      setVisibleCount(2);
    } else if (width > ScreenSize.md) {
      setVisibleCount(1);
    } else {
      setVisibleCount(1);
    }
  }, [width]);

  return (
    <>
      <div className='px-2 sm:px-5 sm:pt-2'>
        <ErrorSection className='mb-4' errors={apiError} />

        <div className='sticky z-10 bg-gray-50 md:bg-white top-14 md:top-0 pt-2 select-none'>
          {updatedStagedPermissions.size > 0 && (
            <div className='animate-fadeIn animate-faster sticky top-12 md:top-0 pt-safe-offset-2 md:pt-safe-offset-6 z-10 px-1 md:px-0 mb-2'>
              <div className={classNames('transition-colors text-white rounded-xl p-3 flex gap-2 items-center bg-blue-700')}>
                <p className='grow'>{t('there-are-permission-changes', 'There are pending changes')}</p>

                <Button onClick={saveChanges} compress={true} variant={ButtonVariant.Success} type='button'>
                  {t('save', 'Save')}
                </Button>

                <Button onClick={resetChanges} compress={true} type='button'>
                  {t('cancel', 'Cancel')}
                </Button>
              </div>
            </div>
          )}

          <div className='bg-gray-50 md:bg-white flex flex-nowrap border-b'>
            <div style={{ width: labelWidth }} className={classNames('py-2 grow shrink-0 pl-1')}>
              &nbsp;
            </div>
            <div className={classNames('flex justify-center items-center', arrowWidth)}>
              {currentIndex > 0 && (
                <button
                  onClick={() => {
                    if (disableNavButton) return;
                    setCurrentIndex(prevState => prevState - 1);
                    setSlideDirection('right');
                  }}
                >
                  <CaretLeft size={24} />
                </button>
              )}
            </div>
            {roles.map((role, index) => (
              <div
                key={role.uid}
                style={{ width: columnWidth }}
                className={classNames(
                  'select-none hidden flex-col font-semibold py-2 px-4 animate-duration-300 leading-5 border-gray-100 border-l',
                  {
                    'animate-slideInRight': slideDirection === 'left',
                    'animate-slideInLeft': slideDirection === 'right',
                    'border-r': currentIndex + visibleCount === index + 1,

                    '!flex': index >= currentIndex && index < currentIndex + visibleCount,
                  },
                )}
              >
                <div className='w-full flex justify-between'>
                  <span className='font-light text-sm'>{t('role', 'Role').toLowerCase()}</span>
                  <DropdownMenu
                    buttonClassName=''
                    menuPlacement='bottom-end'
                    menuItems={[
                      [
                        {
                          element: t('edit', 'Edit'),
                          onClick: () => {
                            setSelectedRole(role);
                            showSaveModal();
                          },
                        },
                      ],
                      [
                        {
                          element: t('remove', 'Remove'),
                          className: 'text-red-600',
                          // we cannot remove the default roles marked by a default_id
                          isDisabled: role.default_id !== null,
                          onClick: () => {
                            setSelectedRole(role);
                            showDeleteModal();
                          },
                        },
                      ],
                    ]}
                  >
                    <button>
                      <DotsThreeVertical size={22} />
                    </button>
                  </DropdownMenu>
                </div>
                <div className='w-full truncate'>{role.name}</div>
              </div>
            ))}
            <div className={classNames('flex justify-center items-center', arrowWidth)}>
              {currentIndex + visibleCount < maxItems && (
                <button
                  onClick={() => {
                    if (disableNavButton) return;
                    setCurrentIndex(prevState => prevState + 1);
                    setSlideDirection('left');
                  }}
                >
                  <CaretRight size={24} />
                </button>
              )}
            </div>
          </div>
        </div>

        {availablePermissions.map(mod => (
          <PermissionPerModule
            slideDirection={slideDirection}
            currentIndex={currentIndex}
            visibleCount={visibleCount}
            key={mod.moduleId}
            availablePermissions={mod.permissions}
            defaultSelectedPermissions={selectedPermissions}
            stagedPermissions={stagedPermissions}
            label={mod.moduleName}
            roles={roles}
            onSelectedPermissionsChange={(roleUid, newPermissions) => {
              if (modulePermissionParser.current) {
                modulePermissionParser.current.updateStagedPermissions(roleUid, newPermissions, mod.permissions);
                setStagedPermissions(new Map(modulePermissionParser.current.stagedRolePermissions));
                setUpdatedStagedPermissions(new Map(modulePermissionParser.current.updatedStagedPermissions));
              }
            }}
          />
        ))}
      </div>

      <SaveRoleModal
        role={selectedRole}
        onSaved={role => onRoleSaved?.(role)}
        isVisible={saveModalIsVisible}
        onRequestCloseModal={() => {
          setSelectedRole(undefined);
          closeSaveModal();
        }}
      />

      <DeleteRoleModal
        isVisible={deleteModalIsVisible}
        onDeleted={role => onRoleDeleted?.(role)}
        onRequestCloseModal={() => {
          setSelectedRole(undefined);
          closeDeleteModal();
        }}
        role={selectedRole}
      />
    </>
  );
}

export default Permissions;

import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import useRvoReportCount from 'hooks/UseRvoReportCount';
import { Contact, ContactsService } from 'openapi';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onSuccess?: () => void;
  stable: Contact;
}

function DeleteRvoModal({ stable, isVisible, onRequestCloseModal, onSuccess }: Props): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<void> | undefined>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  const { loadReportCount } = useRvoReportCount();

  const deleteRvoAuthorization = useCallback(async () => {
    if (!selectedOrganization) return;
    const promise = ContactsService.contactsAuthorizeRvoDestroy({
      organisationUid: selectedOrganization.uid,
      uid: stable.uid,
    });

    try {
      await promise;
      onSuccess?.();
      onRequestCloseModal();

      // also load the report Count so we can show the badge in the menu
      loadReportCount();
    } catch (e) {
      setApiError(new ApiErrorParser<void>(e));
    }
  }, [loadReportCount, onRequestCloseModal, onSuccess, selectedOrganization, stable.uid]);

  return (
    <ActionModal
      open={isVisible}
      title={t('delete-rvo-authorization', 'Delete RVO authorization')}
      actions={[
        { text: t('close', 'Close'), variant: ButtonVariant.Default, onClick: onRequestCloseModal },
        { text: t('delete', 'Delete'), variant: ButtonVariant.Danger, onClick: deleteRvoAuthorization },
      ]}
    >
      {apiError && <ErrorSection errors={apiError} />}

      <p className='mt-2'>{t('delete-rvo-authorization-message', 'Are you sure you want to delete the RVO authorization?')}</p>
    </ActionModal>
  );
}

export default DeleteRvoModal;

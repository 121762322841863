import React from 'react';
import { useTranslation } from 'react-i18next';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { ApiPromises } from 'utilities/ApiPromises';
import { Spinner } from './Loading';
import { SpinnerSize } from './Loading/Spinner';

// Makes one field of the type optional.
type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [P in K]?: T[P] };

// A bit of generic magic. But what is does: It takes the PullToRefresh props,
// then adds the `apiPromises` argument. And makes the `onRefresh` prop optional.
type Props = MakeOptional<Parameters<typeof PullToRefresh>[0] & { apiPromises?: ApiPromises }, 'onRefresh'>;

/**
 * Pull to refresh and infinite scroll. It wraps react-simple-pull-to-refresh
 * with some sane defaults for EquineM.
 */
export default function PullScrollWrapper({ apiPromises, onRefresh, ...props }: Props): JSX.Element {
  const { t } = useTranslation();

  // We can pass in an ApiPromises class. This sets the data fetch methods accordingly.
  if (apiPromises) {
    if (apiPromises.isUsingPagination) {
      onRefresh = async () => {
        apiPromises.refresh();
        await apiPromises.loadMore();
      };
      props.canFetchMore = apiPromises.canFetchMore();
      props.onFetchMore = () => apiPromises.loadMore();
    } else {
      onRefresh = async () => {
        apiPromises.refresh();
        await apiPromises.watchAll();
      };
      props.canFetchMore = false;
    }
  }

  // For convenience we have an empty onRefresh implementation. This way we don't
  // need to do hard `apiPromises === undefined` checks at the places where the
  // PullScrollWrapper is used.
  if (!onRefresh) {
    onRefresh = async () => {
      // Empty placeholder
    };
  }
  // props.api
  return (
    <PullToRefresh
      onRefresh={onRefresh}
      // Disable when not on a touch device
      isPullable={window.matchMedia('(pointer: coarse)').matches}
      // Nice loaders for refreshing and pulling
      pullingContent={<p className='text-center py-4 px-2'>{t('pull-down-to-refresh', 'Pull down to refresh')}</p>}
      refreshingContent={
        <div className='flex justify-center p-4'>
          <Spinner size={SpinnerSize.XSmall} />
        </div>
      }
      {...props}
    />
  );
}

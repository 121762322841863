export interface iLanguagesData {
  id: string;
  name: string;
}

export const LANGUAGES_DATA: iLanguagesData[] = [
  {
    id: 'EN',
    name: 'English',
  },
  {
    id: 'EN-GB',
    name: 'British English',
  },
  {
    id: 'CS',
    name: 'Czech',
  },
  {
    id: 'DE',
    name: 'Deutsch',
  },
  {
    id: 'NL',
    name: 'Nederlands',
  },
  {
    id: 'IT',
    name: 'Italiano',
  },
  {
    id: 'CN',
    name: '中文',
  },
];

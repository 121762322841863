import { Popover, Transition } from '@headlessui/react';
import { CaretDown, Horse as HorseIcon } from '@phosphor-icons/react';
import { Horse } from 'openapi';
import { SexEnum } from 'openapi/models/SexEnum';
import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonInternal, ButtonSize, ButtonVariant } from 'ui/Button';
import { ActionModal } from 'ui/Modals';
import HorseSelect from './HorseSelect';

interface Props {
  horses: Horse[];
  horseSelected: (horse?: Horse) => void;
  selectedHorse?: Horse;
  stallionsOnly?: boolean;
}

// A button that show a dropdown (or modal) of the HorseSelect.
export default function HorseSelectButton({ horses, horseSelected, stallionsOnly, selectedHorse }: Props): JSX.Element {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const filtered = useMemo(() => {
    let res = horses;
    if (stallionsOnly) {
      res = horses.filter(horse => horse.sex === SexEnum._1);
    }
    return res;
  }, [horses, stallionsOnly]);

  const title = useMemo(() => {
    return stallionsOnly ? t('select-stallion', 'Select a stallion') : t('select-horse', 'Select a horse');
  }, [stallionsOnly, t]);

  const buttonText = useMemo(() => {
    const text = stallionsOnly ? t('choose-stallion', 'Choose a stallion') : t('choose-horse', 'Choose a horse');
    return `<${text}>`;
  }, [stallionsOnly, t]);

  return (
    <>
      <div className='block md:hidden'>
        <Button
          size={ButtonSize.Normal}
          onClick={() => {
            setIsOpen(true);
          }}
          variant={ButtonVariant.Default}
          icon={<HorseIcon />}
        >
          {selectedHorse && <span>{selectedHorse.name}</span>}
          {!selectedHorse && <span className='text-neutral-500'>{buttonText}</span>}
          <CaretDown className='ml-2 inline' />
        </Button>
        <ActionModal actions={[]} title={title} open={isOpen} onClose={() => setIsOpen(false)}>
          <HorseSelect
            horses={filtered}
            horseSelected={(horse?: Horse) => {
              horseSelected(horse);
              setIsOpen(false);
            }}
            selectedHorse={selectedHorse}
          />
        </ActionModal>
      </div>

      <div className='hidden md:block'>
        <Popover>
          {({ close }) => (
            <>
              <Popover.Button className={'!outline-none'}>
                <ButtonInternal compress={true} size={ButtonSize.Normal} variant={ButtonVariant.Default} icon={<HorseIcon />}>
                  {selectedHorse && <span>{selectedHorse.name}</span>}
                  {!selectedHorse && <span className='text-neutral-500'>{buttonText}</span>}
                  <CaretDown className='ml-2 inline' />
                </ButtonInternal>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter='transition-opacity ease-out duration-200'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='transition-opacity ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Popover.Panel className='absolute z-50 bg-white border rounded-lg mt-1 shadow-lg'>
                  <div className='h-96 w-96 overflow-scroll'>
                    <HorseSelect
                      horses={filtered}
                      horseSelected={(horse?: Horse) => {
                        horseSelected(horse);
                        close();
                      }}
                      selectedHorse={selectedHorse}
                    />
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </>
  );
}

import React from 'react';
import { Alert } from '../../ui/Alert';
import Article from '../../ui/Article';
import { Severity } from '../../utilities/severity';

function AlertShowcase(): JSX.Element {
  return (
    <Article title='Alerts' caption='Alerts with different severity levels'>
      {[Severity.Success, Severity.Danger, Severity.Info, Severity.Warning].map((severity: Severity, index) => {
        return (
          <div key={index} className={'py-2'}>
            <Alert title={'This is the title'} message={`This is the alert for severity ${severity}`} severity={severity} />
          </div>
        );
      })}
    </Article>
  );
}
export default AlertShowcase;

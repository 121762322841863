import { CheckCircle, WarningCircle } from '@phosphor-icons/react';
import { ErrorDetails } from 'api/ApiErrorParser';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Button, { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';

// A 'dead-end' page that show that the oauth integration is successfully
// connected or had an issue.
export default function PublicIntegrationResult(): JSX.Element {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const hasError = useMemo((): boolean => {
    return searchParams.get('error') !== null;
  }, [searchParams]);

  const error = useMemo((): string | undefined => {
    const queryParamError = searchParams.get('error');
    if (!queryParamError) {
      return undefined;
    }
    try {
      const jsonErr = JSON.parse(queryParamError);
      const arr = jsonErr['errors']['non_field_errors'] as ErrorDetails[];
      if (arr.length > 0) {
        return arr[0].message;
      } else {
        throw Error('Could not parse error from query params: ' + queryParamError);
      }
    } catch (e) {
      console.error(e, queryParamError);
      return t('unknown-error', 'Unknown error');
    }
  }, [searchParams, t]);

  return (
    <div className='h-full flex items-center justify-center'>
      <div className='w-64 flex flex-col items-center gap-4'>
        {hasError ? (
          <>
            <div className='text-rose-700'>
              <WarningCircle size={150} />
            </div>
            <ErrorSection errors={error} />
            <p className='text-center'>
              {t('integration-connection-failure', 'There has been an error while trying to connect to your integration.')}
            </p>
          </>
        ) : (
          <>
            <div className='text-green-700'>
              <CheckCircle size={150} />
            </div>
            <p className='text-center'>
              {t('integration-connection-success', 'Your integration is successfully connected. You may close this window.')}
            </p>
          </>
        )}
        <Button onClick={window.close} variant={ButtonVariant.Primary}>
          {t('close-window', 'Close window')}
        </Button>
      </div>
    </div>
  );
}

import ApiErrorParser from 'api/ApiErrorParser';
import SplashWrapper, { WrapperStyle } from 'components/Common/Splash/Wrapper';
import { AccountService, ApiError, RestAuthDetail } from 'openapi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Alert } from 'ui/Alert';
import { ErrorSection } from 'ui/Error';
import { LoadingSection } from 'ui/Loading';
import { Severity } from 'utilities/severity';

// A page that is opened from the email-verify email. This page can be used for
// webshop and app. Therefor it's anonymous and has no login button/link.
export default function VerifyEmail(): JSX.Element {
  const { t } = useTranslation();
  const [apiError, setApiError] = useState<ApiErrorParser<RestAuthDetail> | undefined>();
  const [success, setSuccess] = useState<boolean | undefined>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const promise = AccountService.apiV5VerifyEmailCreate({
      requestBody: {
        key: searchParams.get('token') ?? '',
      },
    });
    promise
      .then(() => setSuccess(true))
      .catch(e => {
        setSuccess(false);
        setApiError(new ApiErrorParser<RestAuthDetail>(e));
        if (e instanceof ApiError && e.status === 404) {
          setNotFound(true);
        }
      })
      .finally(() => setSubmitting(false));

    return () => promise.cancel();
  }, []); //eslint-disable-line

  return (
    <SplashWrapper styleType={WrapperStyle.Anonymous} title={t('verify-email', 'Verify your email address')}>
      {submitting && (
        <div className='my-12'>
          <LoadingSection />
        </div>
      )}
      {success === true && (
        <>
          <Alert severity={Severity.Success} message={t('verify-email-success', 'We have verified your email.')} />
          <p className='my-4 text-gray-700'>{t('you-may-close-window', 'You may close this window.')}</p>
        </>
      )}
      {success === false && notFound === false && (
        <>
          <p className='my-4 text-gray-700'>{t('verify-email-failed', 'We have failed to verify your email. Please contact us.')}</p>
          <ErrorSection className='my-2' errors={apiError} />
        </>
      )}
      {success === false && notFound === true && (
        <>
          <p className='my-4 text-gray-700'>
            {t('verify-email-not-found-extra', 'Please contact us if you are not able to login with your email address.')}
          </p>
          <Alert severity={Severity.Warning} message={t('verify-email-not-found', 'Not found, your email might already be verified.')} />
          <p className='my-4 text-gray-700'>{t('you-may-close-window', 'You may close this window.')}</p>
        </>
      )}
    </SplashWrapper>
  );
}

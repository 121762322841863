import ApiErrorParser from 'api/ApiErrorParser';
import { LoginResult } from 'api/UserSession';
import { AppRoutes } from 'AppRoutes';
import { useAccount } from 'context/AccountContext';
import { ApiService } from 'openapi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorSection } from 'ui/Error';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';
import SplashWrapper, { WrapperStyle } from '../components/Common/Splash/Wrapper';

/**
 * The page where Microsoft SSO redirects to after a successfull OIDC login.
 */
export default function MicrosoftOidcLoginResponse(): JSX.Element {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const { loginWithJwtTokens } = useAccount();
  const navigate = useNavigate();
  const [apiError, setApiError] = useState<ApiErrorParser<LoginResult> | undefined>(undefined);
  const [loggingIn, setLoggingIn] = useState<boolean>(true);

  useEffect(() => {
    if (hash.length === 0) {
      return;
    }
    const searchParams = new URLSearchParams(hash.substring(1, hash.length));
    const access_token = searchParams.get('access_token');
    if (!access_token) {
      return;
    }
    const promise = ApiService.apiV5OauthUserMicrosoftLoginCreate({ requestBody: { access_token } });
    promise
      .then(response => {
        // TODO: This needs to be cased because the result type from OpenAPI is
        // of the wrong format. See https://gitlab.qubis.nl/equinem/equinemcore/-/issues/320
        // Login
        loginWithJwtTokens(response.access, response.refresh)
          .then(() => navigate(AppRoutes.Home.path))
          .catch(e => setApiError(new ApiErrorParser<void>(e)));
      })
      .catch(e => setApiError(new ApiErrorParser<LoginResult>(e)))
      .finally(() => setLoggingIn(false));

    return () => promise.cancel();
  }, [hash, navigate, loginWithJwtTokens]);

  return (
    <SplashWrapper title={t('microsoft-login', 'Login at Microsoft')} styleType={WrapperStyle.Equinem}>
      <div className='space-y-4 mt-4'>
        {loggingIn && <Spinner size={SpinnerSize.Large} />}
        {!loggingIn && apiError !== undefined && (
          <p>{t('login-microsoft-success', 'Successfully logged in using Microsoft account. Please wait, you are being redirect.')}</p>
        )}
        {!loggingIn && apiError === undefined && <p>{t('login-microsoft-fail', 'Failed to login using your Microsoft account.')}</p>}
        <ErrorSection errors={apiError} />
      </div>
    </SplashWrapper>
  );
}

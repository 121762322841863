import { Config } from './Config';
import { UserSession } from './UserSession';

/**
 * `IApiErrorData<T>`
 *
 * A utility type for representing potential validation errors for a form defined by the structure `T`.
 *
 * The type mirrors the structure of `T`. However, every leaf node (final field) is an array of strings,
 * where each string represents a distinct error message for that field.
 *
 * @template T The type that represents the structure of the form data.
 *
 * @example
 * ```typescript
 * interface User {
 *     firstName: string;
 *     profile: {
 *         city: string;
 *     };
 * }
 *
 * const errors: IApiErrorData<User> = {
 *     firstName: [{message: "First name is required", code: 'invalid'}],
 *     profile: {
 *         city: [{message: "City is required", code: 'invalid'}],
 *     }
 * };
 * ```
 */
export type IApiErrorData<T> = {
  [P in keyof T]: T[P] extends object ? IApiErrorData<T[P]> : { errors: string[] } | { code: string; message: string }[];
};
export class ApiError<T> extends Error {
  constructor(
    public message: string,
    public errorObject: T | Record<string, IApiErrorData<T>>,
  ) {
    super(message);
    // This line is necessary for the error stack trace to work correctly
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
class ApiClient {
  constructor(
    private session: UserSession | undefined,
    private config: Config | undefined,
  ) {}

  // This is for API Calls which need to send json data.
  // Authorised is the variable which will add authorization header to the request
  private jsonHeaders(authorised: boolean): HeadersInit {
    const _headers: HeadersInit = {
      'Content-Type': 'application/json',
    };
    if (this.session && authorised) {
      _headers['Authorization'] = `Bearer ${this.session?.authToken}`;
    }
    return _headers;
  }
  // This is for API Calls which need to send form data,
  // urlEncoded is the variable which will change the content type of the request from multipart/form-data to application/x-www-form-urlencoded
  // Authorised is the variable which will add authorization header to the request

  private formHeaders(authorised: boolean, urlEncoded?: boolean): HeadersInit {
    const _headers: HeadersInit = {
      'Content-Type': urlEncoded ? 'application/x-www-form-urlencoded' : 'multipart/form-data',
      Accept: 'application/json',
    };
    if (this.session && authorised) {
      _headers['Authorization'] = `Bearer ${this.session?.authToken}`;
    }
    return _headers;
  }

  // TODO: Implement query params
  async getJson<T>(urlPath: string): Promise<T> {
    if (!this.config) {
      throw new Error('Api Client not initialized with config');
    }
    const response = await fetch(`${this.config.apiUrl}${urlPath}`, {
      method: 'GET',
      headers: this.jsonHeaders(false),
    });
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
    return response.json();
  }
  // TODO: Implement query params
  async getAuthenticatedJson<T>(urlPath: string, signal?: AbortSignal): Promise<T> {
    if (!this.config) {
      throw new Error('Api Client not initialized with config');
    }
    if (!this.session) {
      throw new Error('Api Client not initialized with user session');
    }
    const response = await fetch(`${this.config.apiUrl}${urlPath}`, {
      method: 'GET',
      headers: this.jsonHeaders(true),
      signal,
    });
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
    return response.json();
  }

  async postJson<T>(urlPath: string, body: object): Promise<T> {
    if (!this.config) {
      throw new ApiError('Api Client not initialized with config', {});
    }
    const response: Response = await fetch(`${this.config?.apiUrl}${urlPath}`, {
      method: 'POST',
      headers: this.jsonHeaders(false),
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      if (response.body) {
        const d = await response.json();
        throw new ApiError('Request failed with status', d?.errors);
      } else {
        throw new ApiError(`Request failed with status ${response.status}`, {});
      }
    }
    return response.json();
  }
  async postAuthenticatedJson<T, TError>(urlPath: string, body: object): Promise<T> {
    if (!this.config) {
      throw new ApiError('Api Client not initialized with config', {});
    }
    const response = await fetch(`${this.config?.apiUrl}${urlPath}`, {
      method: 'POST',
      headers: this.jsonHeaders(true),
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      if (response.body) {
        const d = await response.json();
        throw new ApiError<TError>('Request failed with status', d?.errors || d?.form?.fields || d?.data);
      } else {
        throw new ApiError(`Request failed with status ${response.status}`, {});
      }
    }
    return response.json();
  }
  async postAuthenticated<T, TError>(urlPath: string, body: string, options?: { urlEncoded?: boolean }): Promise<T> {
    if (!this.config) {
      throw new ApiError('Api Client not initialized with config', {});
    }
    const response = await fetch(`${this.config?.apiUrl}${urlPath}`, {
      method: 'POST',
      headers: this.formHeaders(true, options?.urlEncoded),
      body: body,
    });
    if (!response.ok) {
      if (response.body) {
        const d = await response.json();
        throw new ApiError<TError>('Request failed with status', d?.errors || d?.form?.fields || d?.data);
      } else {
        throw new ApiError(`Request failed with status ${response.status}`, {});
      }
    }
    return response.json();
  }
  async patch<T>(urlPath: string, body: object): Promise<T> {
    if (!this.config) {
      throw new ApiError('Api Client not initialized with config', {});
    }
    const response: Response = await fetch(`${this.config?.apiUrl}${urlPath}`, {
      method: 'PATCH',
      headers: this.jsonHeaders(false),
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      if (response.body) {
        const d = await response.json();
        throw new ApiError('Request failed with status', d?.errors);
      } else {
        throw new ApiError(`Request failed with status ${response.status}`, {});
      }
    }
    return response.json();
  }

  /**
   * This method sends a PATCH request.
   * @template T The expected return type of the request.
   * @template TError The expected error structure if the request fails.
   *
   * @returns {Promise<T>} A promise that resolves with the parsed JSON response.
   *
   * @throws {ApiError<TError>} If the response is not ok (status code is not in the 200-299 range), an `ApiError` will be thrown.
   * If the response has a body, the `errors` field of the response will be attached to the `ApiError`.
   * Otherwise, a generic message with the status code will be included in the `ApiError`.
   **/
  async patchAuthenticated<T, TError>(urlPath: string, body: object): Promise<T> {
    if (!this.config) {
      throw new ApiError('Api Client not initialized with config', {});
    }
    const response = await fetch(`${this.config?.apiUrl}${urlPath}`, {
      method: 'PATCH',
      headers: this.jsonHeaders(true),
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      if (response.body) {
        const d = await response.json();
        throw new ApiError<TError>('Request failed with status', d?.errors);
      } else {
        throw new ApiError(`Request failed with status ${response.status}`, {});
      }
    }
    return response.json();
  }
  async getOptionsJsonAuthenticated<T, TError>(urlPath: string): Promise<T> {
    if (!this.config) {
      throw new ApiError('Api Client not initialized with config', {});
    }
    const response = await fetch(`${this.config?.apiUrl}${urlPath}`, {
      method: 'OPTIONS',
      headers: this.jsonHeaders(true),
    });
    if (!response.ok) {
      if (response.body) {
        const d = await response.json();
        throw new ApiError<TError>('Request failed with status', d?.errors);
      } else {
        throw new ApiError(`Request failed with status ${response.status}`, {});
      }
    }
    return response.json();
  }
}
export default ApiClient;

import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { InvitationsService, ReceivedInvitation } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  onSuccess: () => void;
  invitation: ReceivedInvitation | undefined;
}

function DeclineInvitationModal({ isVisible, onRequestClose, invitation, onSuccess }: Props): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<ReceivedInvitation> | undefined>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const declineInvitation = async () => {
    if (!selectedOrganization) return;
    try {
      const promise = InvitationsService.apiV5InvitationsDeclinePartialUpdate({
        uuid: invitation?.uuid ?? '',
        requestBody: { declined_reason: 'Declined by the user' },
      });
      await promise;

      // close the modal
      onRequestClose();
      // fire the onSuccess callback
      onSuccess();
      // clear the error
      setApiError(undefined);
    } catch (error) {
      setApiError(new ApiErrorParser<ReceivedInvitation>(error));
    }
  };

  return (
    <ActionModal
      open={isVisible}
      onClose={onRequestClose}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: onRequestClose,
        },
        {
          text: t('decline', 'Decline'),
          variant: ButtonVariant.Danger,
          onClick: declineInvitation,
        },
      ]}
      title={t('decline-invitation', 'Decline invitation')}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2 w-full'>{t('decline-invitation-desc', 'Are you sure you want to decline this invitation?')}</p>
    </ActionModal>
  );
}

export default DeclineInvitationModal;

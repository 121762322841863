import { CaretRight } from '@phosphor-icons/react';
import classnames from 'classnames';
import { Contact, Role } from 'openapi';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../AppRoutes';
import { AvatarInitials, AvatarSize } from '../../ui/Avatar';
import {
  table,
  tableHiddenColumnLg,
  tableHiddenColumnMd,
  tableHiddenColumnSm,
  tableHiddenHeaderSm,
  tableTbody,
  tableTbodyTr,
  tableThead,
  tableTheadTd,
} from '../../ui/Const';
import { contactInitials, contactName } from 'utilities/Contact';

interface Props {
  contacts?: Contact[];
  roles?: Role[];
  stables?: Contact[];
}

function UserList({ contacts = [], roles = [], stables = [] }: Props): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <table className={table}>
      <thead className={tableThead}>
        <tr className={tableHiddenHeaderSm}>
          <td className='w-12 md:w-14' />
          <td className={tableTheadTd}>{t('name', 'Name')}</td>
          <td className={classnames(tableTheadTd, tableHiddenColumnMd)}>{t('email-address', 'Email address')}</td>
          <td className={classnames(tableTheadTd, tableHiddenColumnSm)}>{t('phone-number', 'Phone number')}</td>
          <td className={classnames(tableTheadTd, tableHiddenColumnMd)}>{t('role', 'Role')}</td>
          <td className={classnames(tableTheadTd, tableHiddenColumnLg)}>{t('stable', 'Stable')}</td>
          <td className='w-10 md:hidden' />
        </tr>
      </thead>
      <tbody className={tableTbody}>
        {contacts.map(contact => {
          return (
            <tr
              className={tableTbodyTr}
              key={contact.uid}
              onClick={() => {
                navigate(generatePath(AppRoutes.AdminUserDetails.path, { uid: contact.uid }));
              }}
            >
              <td className='text-center w-12 md:w-14'>
                <AvatarInitials size={AvatarSize.Small} initials={contactInitials(contact)} uuid={contact.uid} />
              </td>
              <td>
                <span className='mr-2'>{contactName(contact)}</span>
              </td>
              <td className={tableHiddenColumnMd}>{contact.email}</td>
              <td className={tableHiddenColumnSm}>{contact.phone_number}</td>
              <td className={tableHiddenColumnMd}>
                {(contact.roles ?? []).map(id => roles.find(role => role.uid === id)?.name).join(', ')}
              </td>
              <td className={tableHiddenColumnLg}>
                {(contact.stables ?? []).map(id => stables.find(stable => stable.stable_location_uid === id)?.business_name).join(', ')}
              </td>
              <td className={classnames('w-10 text-center md:hidden')}>
                <CaretRight size={22} weight='light' className='inline' />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
export default UserList;

import React from 'react';

interface Props {
  title: string;
  caption?: string;
  children: React.ReactNode;
}

function Article({ title, caption, children }: Props): JSX.Element {
  return (
    <article className='my-8'>
      <div className='mb-6 flex flex-col gap-1'>
        <h1 className='text-xl font-semibold text-gray-800'>{title}</h1>
        {caption && <p className={'text-gray-600'}>{caption}</p>}
      </div>
      {children}
    </article>
  );
}

export default Article;

import { WarningCircle } from '@phosphor-icons/react';
import classNames from 'classnames';
import UseCountries from 'hooks/UseCountries';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { contactAddress, contactName, InvoiceReceiver } from 'utilities/Contact';

interface Props {
  receiver?: InvoiceReceiver;
}

function InvoiceReceiverBlock({ receiver }: Props): JSX.Element {
  const { t } = useTranslation();
  const { countries } = UseCountries();

  const contactNameLines = useMemo(() => {
    if (!receiver) {
      return t('invoice-fake-name-receiver', 'Name receiver');
    }
    const name = contactName(receiver);
    if (!name) {
      return `<${t('nameless-contact', 'Nameless contact')}>`;
    }
    return name;
  }, [receiver, t]);

  const contactAddressLines = useMemo((): string | undefined => {
    let addressLines: string[] = [];
    if (receiver) {
      addressLines = contactAddress(receiver, countries);
    } else {
      addressLines.push(t('invoice-fake-address', 'Address 1234'));
      addressLines.push(t('invoice-fake-postcode-and-city', '1212 XX, City'));
    }
    if (addressLines.length === 0) {
      return undefined;
    }
    return addressLines.join('\n');
  }, [countries, receiver, t]);

  return (
    <div className={classNames('text-black whitespace-pre-wrap', { contact: 'text-opacity-50' })}>
      {contactNameLines}
      <br />
      {contactAddressLines ? (
        contactAddressLines
      ) : (
        <div className='text-rose-400 flex gap-1 items-center'>
          <WarningCircle />
          <span className='text-xs md:text-sm'>{t('invoice-missing-address', 'Address details are missing')}</span>
        </div>
      )}
    </div>
  );
}
export default InvoiceReceiverBlock;

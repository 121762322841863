import { zodResolver } from '@hookform/resolvers/zod';
import { AppRoutes } from 'AppRoutes';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useAccount } from 'context/AccountContext';
import { AccountService, ReceivedInvitation, SignupWithInvitation } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button, { ButtonInternal, ButtonSize, ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { CheckboxInput, TextInput } from 'ui/Inputs';
import { transformEmptyToUndefined, transformValue } from 'utilities/zod';

interface Props {
  invitation: ReceivedInvitation;
}

export default function SignupForInvitation({ invitation }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { loginWithJwtTokens } = useAccount();

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const schema = useMemo(() => {
    return schemas.SignupWithInvitation.omit({
      invitation_uuid: true,
    })
      .refine(data => data.password1 === data.password2, {
        message: t('password-mismatch', "Passwords don't match"),
        path: ['password2'],
      })
      .refine(data => data.agreed_to_terms_and_privacy === true, {
        message: t('agree-terms-error', 'You must agree to the terms'),
        path: ['agreed_to_terms_and_privacy'],
      });
  }, [t]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupWithInvitation>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const onSubmit = async (data: SignupWithInvitation) => {
    if (!invitation || !invitation.invitee_email) return;

    const promise = AccountService.apiV5SignupWithInvitationCreate({
      requestBody: { ...data, invitation_uuid: invitation.uuid },
    });
    try {
      setSubmitting(true);
      const jwtTokens = await promise;

      // Login with the given jwt tokens.
      await loginWithJwtTokens(jwtTokens.access, jwtTokens.refresh);
      navigate(AppRoutes.Home.path);
    } catch (e) {
      setApiError(new ApiErrorParser<SignupWithInvitation>(e));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <p className='mt-8'>
        <Trans
          i18nKey='invitation-desc'
          defaults='You have been invited by <strong>{{first_name}} {{last_name}}</strong> to join the organization <strong>{{organisationName}}</strong>. In order to accept this invitation, please fill in the form below and create an account.'
          values={{
            organisationName: invitation?.inviter_organisation.name,
            first_name: invitation?.inviter.first_name,
            last_name: invitation?.inviter.last_name,
          }}
        />
      </p>

      <ErrorSection className='my-4' errors={nonFieldErrors} />
      <form noValidate={true} id='invitation-signup-form' className='flex flex-col space-y-4 my-4' onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          required={true}
          label={t('signup-first-name', 'First name')}
          {...register('first_name', { setValueAs: transformEmptyToUndefined() })}
          error={fieldError('first_name')}
        />
        <TextInput
          required={true}
          label={t('signup-last-name', 'Last name')}
          {...register('last_name', { setValueAs: transformEmptyToUndefined() })}
          error={fieldError('last_name')}
        />
        <TextInput
          required={true}
          type='password'
          label={t('signup-password1', 'Password')}
          {...register('password1', { setValueAs: transformEmptyToUndefined() })}
          error={fieldError('password1')}
        />
        <TextInput
          required={true}
          type='password'
          label={t('signup-password2', 'Password (again)')}
          {...register('password2', { setValueAs: transformEmptyToUndefined() })}
          error={fieldError('password2')}
        />
        <CheckboxInput
          required={true}
          {...register('agreed_to_terms_and_privacy', { setValueAs: transformValue(false, undefined) })}
          error={fieldError('agreed_to_terms_and_privacy')}
          labelElement={
            <span>
              {t('signup-agree-terms', 'Yes, I agree with the')}{' '}
              <a href={'https://equinem.com/legal/general-terms/'} target={'_blank'} className={'text-blue-500'} rel='noreferrer'>
                {t('signup-terms', 'terms and conditions')}
              </a>{' '}
              {t('signup-agree-terms-and', 'and the')}{' '}
              <a href={'https://equinem.com/privacy-policy/'} target={'_blank'} className={'text-blue-500'} rel='noreferrer'>
                {t('signup-terms-privacy', 'privacy policy')}
              </a>
              {'.'}
            </span>
          }
        />
      </form>

      <div className={'flex flex-col items-stretch gap-4'}>
        <Button form='invitation-signup-form' loading={submitting} variant={ButtonVariant.Primary} size={ButtonSize.Large}>
          {t('accept-invitation-signup', 'Accept and signup')}
        </Button>

        <div className='text-gray-400 text-sm gap-4 flex flex-row items-center'>
          <hr className='grow' />
          <span>{t('or', 'or')}</span>
          <hr className='grow' />
        </div>

        <Link className={'font-semibold rounded-lg'} to={`${AppRoutes.Login.path}?disable-signup#${location.pathname}${location.search}`}>
          <ButtonInternal variant={ButtonVariant.Default} size={ButtonSize.Large}>
            {t('login', 'Login')}
          </ButtonInternal>
        </Link>
      </div>
    </>
  );
}

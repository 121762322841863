import React, { ReactNode, useState } from 'react';
import Button, { ButtonVariant } from 'ui/Button';
import { ListFilterType } from '.';
import ListFilterBar from './ListFilterBar';

export interface FilterActions {
  icon: ReactNode;
  text: string;
  buttonVariant?: ButtonVariant;
  buttonCompress?: boolean;
  badge?: number;
  onClick?: () => Promise<void> | void; // When onClick returns a promise then the button will show its loader while busy.
}

interface Props {
  listFilterTypes?: ListFilterType[];
  actions?: FilterActions[];
  children: ReactNode;
}

// Button of the FilterWrapper
function ActionButton(action: FilterActions): JSX.Element {
  const [isBusy, setIsBusy] = useState<boolean>(false);
  return (
    <Button
      badge={action.badge}
      key={action.text}
      compress={action.buttonCompress}
      icon={action.icon}
      loading={isBusy}
      onClick={() => {
        if (action.onClick) {
          const onClickResult = action.onClick();
          if (onClickResult?.then) {
            setIsBusy(true);
            onClickResult.finally(() => setIsBusy(false));
          }
        }
      }}
      variant={action.buttonVariant}
    >
      {action.text}
    </Button>
  );
}

/**
 * A wrapper function that applies default classes and implement actions
 */
function FilterWrapper({ listFilterTypes, actions, children }: Props): JSX.Element {
  let elements = <div className='flex gap-2 grow'>{children}</div>;

  // if we defined actions, we should push the actions to the right
  if (actions) {
    elements = (
      <div className='flex gap-2 justify-between'>
        {elements}
        <div className='flex gap-x-1'>
          {actions.map(action => (
            <ActionButton {...action} key={action.text} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className='px-2 md:p-4 md:pb-8'>
      {elements}
      <ListFilterBar listFilterTypes={listFilterTypes ?? []} />
    </div>
  );
}

export default FilterWrapper;

import { PageAction, usePage } from 'context/PageContext';
import React, { ReactNode, useEffect } from 'react';

interface Props {
  children: ReactNode;
  actions?: PageAction[];
}

export function FixedPage({ children, actions }: Props): JSX.Element {
  const { setTitle, setBreadCrumbs, setActions, setApiError } = usePage();

  /**
   * On construct we should set the title, breadcrumb and actions
   */
  useEffect(() => {
    setActions(actions || []);
    setApiError(undefined);
    setTitle(undefined);
    setBreadCrumbs([]);
  }, [actions, setActions, setBreadCrumbs, setTitle, setApiError]);

  return <div className='md:pb-0 absolute inset-0 min-h-lvh pb-safe-offset-16'>{children}</div>;
}

import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useAccount } from 'context/AccountContext';
import { AccountService, PatchedAccount, Stable } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { SelectInput } from 'ui/Inputs';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { contactName } from 'utilities/Contact';

interface Props {
  isVisible: boolean;
  stables?: Stable[];
  onRequestClose: () => void;
}

const schema = schemas.PatchedAccount.pick({ preferred_stable: true });

export default function UpdatePreferredStableModal({ isVisible, stables, onRequestClose }: Props): JSX.Element {
  const { accountDetails, setAccountDetails } = useAccount();
  const { t } = useTranslation();

  const defaultValues = useMemo(() => {
    return {
      preferred_stable: accountDetails?.preferred_stable,
    };
  }, [accountDetails?.preferred_stable]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    clearErrors,
  } = useForm<PatchedAccount>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Close event for the modal
   */
  const onClosed = () => {
    reset(accountDetails);
    clearErrors();
    setApiError(undefined);
  };

  /**
   * Submit event handler, update the data via the API for this user
   */
  const onSubmit: SubmitHandler<PatchedAccount> = async (data: PatchedAccount) => {
    try {
      const promise = AccountService.apiV5AccountPartialUpdate({ requestBody: data });
      setAccountDetails(await promise);
      onRequestClose();
    } catch (e) {
      setApiError(new ApiErrorParser<PatchedAccount>(e));
    }
  };

  /**
   * Build the list of options for the organization select input
   */
  const stableOptions = useMemo((): OptionItemInterface[] => {
    return (
      stables?.map(stable => ({
        id: stable.uid,
        name: contactName(stable.location) ?? '',
      })) ?? []
    );
  }, [stables]);

  /**
   * Make sure we reset the form when the modal is opened
   */
  useEffect(() => {
    if (isVisible) {
      reset(defaultValues);
    }
  }, [defaultValues, isVisible, reset]);

  return (
    <PageModal
      open={isVisible}
      parentElement='form'
      parentProps={{ id: 'updatePreferredStable', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      width={PageModalWidth.Xs}
      onClosed={onClosed}
    >
      <PageModalTitle title={t('update-preferred-stable', 'Update preferred stable')} onClose={onRequestClose} />
      <PageModalContent>
        <ErrorSection errors={nonFieldErrors} />
        <div className='py-3 space-y-4'>
          <SelectInput
            nullable={true}
            nullableValue=''
            error={fieldError('preferred_stable')}
            options={stableOptions}
            label={t('preferred-stable', 'Preferred stable')}
            {...register('preferred_stable')}
          />
        </div>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            onClick: onRequestClose,
            variant: ButtonVariant.Default,
            type: 'button',
            text: t('cancel', 'Cancel'),
          },
          {
            loading: isSubmitting,
            variant: ButtonVariant.Primary,
            type: 'submit',
            formId: 'updatePreferredStable',
            text: t('save', 'Save'),
          },
        ]}
      />
    </PageModal>
  );
}

import { InvoiceFinalize, InvoicesService, ProviderEnum } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'context/OrganizationContext';
import { ButtonVariant } from 'ui/Button';
import { PageModal } from 'ui/Modals';
import { ActionProps, PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import ApiErrorParser from 'api/ApiErrorParser';
import { ErrorSection } from 'ui/Error';
import { integrationName } from 'utilities/Integrations';
import Spinner, { SpinnerSize } from 'ui/Loading/Spinner';

enum State {
  Idle,
  Busy,
  Failed,
  Finished,
  Aborted,
}

interface Props {
  invoiceUids?: string[];
  bookkeepingProviders: ProviderEnum[];
  open: boolean;
  onRequestClose: (reload: boolean) => void;
}

export default function SendToBookkeepingModal({ invoiceUids, bookkeepingProviders, open, onRequestClose }: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  const [apiError, setApiError] = useState<ApiErrorParser<InvoiceFinalize>>();
  const [state, setState] = useState<State>(State.Idle);
  const [progress, setProgress] = useState<number>(0);

  const sendToBookkeeping = useCallback(async () => {
    if (!invoiceUids) {
      console.error('Invoice ids array is undefined when trying to send invoices to bookkeeping');
      return;
    }
    if (!selectedOrganization?.uid) {
      console.error('No selected organization when trying to send invoices to bookkeeping');
      return;
    }
    setState(State.Busy);
    try {
      for (const uid of invoiceUids) {
        if (state !== State.Aborted) {
          await InvoicesService.invoicesSendToAccountingSoftwareCreate({
            organisationUid: selectedOrganization.uid,
            uid,
          });
          // Sleep 100 milisec between every request to give the api some air.
          await new Promise(resolve => setTimeout(resolve, 100));
          setProgress(progress + 1);
        }
      }
      setState(State.Finished);
    } catch (e) {
      setState(State.Failed);
      setApiError(new ApiErrorParser<InvoiceFinalize>(e));
    }
  }, [invoiceUids, selectedOrganization, progress, setApiError, state]);

  // Reset the form to its defaults when the dialog gets opened.
  useEffect(() => {
    if (open) {
      setState(State.Idle);
    }
  }, [open]); //eslint-disable-line

  const bookkeepingProvidersNameString = useMemo((): string => {
    if (bookkeepingProviders.length === 0) {
      return t('bookkeeping', 'bookkeeping');
    }
    return bookkeepingProviders.map(provider => integrationName(t, provider)).join(', ');
  }, [bookkeepingProviders, t]);

  const actions = useMemo((): ActionProps[] => {
    if (state === State.Idle) {
      return [
        {
          variant: ButtonVariant.Primary,
          text: t('send', 'Send'),
          type: 'button',
          onClick: () => sendToBookkeeping(),
        },
      ];
    } else if (state === State.Busy) {
      return [
        {
          variant: ButtonVariant.Default,
          text: t('abort', 'Abort'),
          type: 'button',
          onClick: () => {
            setState(State.Aborted);
            onRequestClose(true);
          },
        },
      ];
    } else {
      return [
        {
          variant: ButtonVariant.Primary,
          text: t('close', 'Close'),
          type: 'button',
          onClick: () => onRequestClose(true),
        },
      ];
    }
  }, [state, t, sendToBookkeeping, onRequestClose]);

  return (
    <PageModal
      open={open}
      parentElement='form'
      width={PageModalWidth.Xs}
      onClose={() => {
        setApiError(undefined);
        setProgress(0);
      }}
    >
      <PageModalTitle
        title={t('send-to-bookkeeping', 'Send to {{bookkeeping}}', { bookkeeping: bookkeepingProvidersNameString })}
        onClose={() => onRequestClose(false)}
      />
      <PageModalContent>
        <div className='py-2'>
          {state === State.Idle && (
            <p>
              {t(
                'send-to-bookkeeping-modal-hint',
                'You are about to send {{count}} invoices to {{bookkeeping}}. Click send to start sending to {{bookkeeping}}.',
                { count: invoiceUids?.length ?? 0, bookkeeping: bookkeepingProvidersNameString },
              )}
            </p>
          )}
          {state === State.Busy && (
            <div className='flex gap-2 flex-row items-center'>
              <Spinner size={SpinnerSize.XSmall} />
              <p>
                {t('sending-invoices', 'Sending invoices')} {progress} / {invoiceUids?.length ?? 0}.{' '}
                {t('dont-close-window', 'Please do not close this window.')}
              </p>
            </div>
          )}
          {state === State.Failed && <ErrorSection errors={apiError} />}
          {state === State.Finished && (
            <p>
              {t('send-to-bookkeeping-modal-finished', 'Successfully sent {{count}} invoices to {{bookkeeping}}.', {
                count: invoiceUids?.length ?? 0,
                bookkeeping: bookkeepingProvidersNameString,
              })}
            </p>
          )}
        </div>
      </PageModalContent>
      <PageModalActions actions={actions} />
    </PageModal>
  );
}

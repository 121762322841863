import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { Contact, ContactsService, Stable, StablesService } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  onDeleted: () => void;
  location: Contact;
}

function DeleteLocationModal({ isVisible, onRequestClose, location, onDeleted }: Props): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<Stable | Contact> | undefined>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const isStable = location.stable_location_uid !== null;

  const deleteLocation = async () => {
    if (!selectedOrganization) return;

    try {
      if (isStable) {
        await StablesService.stablesDestroy({
          organisationUid: selectedOrganization.uid,
          uid: location.stable_location_uid,
        });
      } else {
        await ContactsService.contactsDestroy({
          organisationUid: selectedOrganization.uid,
          uid: location.uid,
        });
      }

      // close the modal
      onRequestClose();

      // fire the onDeleted callback
      onDeleted();
    } catch (error) {
      setApiError(new ApiErrorParser<Stable | Contact>(error));
    }
  };

  return (
    <ActionModal
      open={isVisible}
      onClose={onRequestClose}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: onRequestClose,
        },
        {
          text: t('delete', 'Delete'),
          variant: ButtonVariant.PrimaryDanger,
          onClick: deleteLocation,
        },
      ]}
      title={isStable ? t('delete-stable', 'Delete stable') : t('delete-location', 'Delete location')}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2  w-full'>
        {isStable
          ? t('delete-stable-desc', 'Are you sure you want to delete this stable?')
          : t('delete-location-desc', 'Are you sure you want to delete this location?')}
      </p>
    </ActionModal>
  );
}

export default DeleteLocationModal;

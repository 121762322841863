/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedStallionMountList } from '../models/PaginatedStallionMountList';
import type { PatchedStallionMount } from '../models/PatchedStallionMount';
import type { StallionMount } from '../models/StallionMount';
import type { StallionMountDetail } from '../models/StallionMountDetail';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class StallionmountsService {
  /**
   * This is a bit ugly, but the NestedViewSetMixin requires a parent queryset.
   * And GetQuerySetForUser can not have a parent.
   * And we need to annotate the queryset.
   * So the order of this mixin is super important and it works. *PRAGMATISM*
   * @returns PaginatedStallionMountList
   * @throws ApiError
   */
  public static stallionmountsList({
    stallionOrganisationUid,
    collectionDate,
    collectionDateGt,
    collectionDateGte,
    collectionDateLt,
    collectionDateLte,
    o,
    page,
    pageSize,
    portionsFreshGte,
    portionsFreshLte,
    portionsFreshAvailableGte,
    stallionUid,
    stallionUidIn,
    strawsFrozenGte,
    strawsFrozenLte,
    strawsFrozenAvailableGte,
  }: {
    stallionOrganisationUid: string,
    collectionDate?: string,
    collectionDateGt?: string,
    collectionDateGte?: string,
    collectionDateLt?: string,
    collectionDateLte?: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    portionsFreshGte?: number,
    portionsFreshLte?: number,
    /**
     * Portions fresh available is greater than or equal to
     */
    portionsFreshAvailableGte?: number,
    stallionUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    stallionUidIn?: Array<string>,
    strawsFrozenGte?: number,
    strawsFrozenLte?: number,
    /**
     * Frozen straws available is greater than or equal to
     */
    strawsFrozenAvailableGte?: number,
  }): CancelablePromise<PaginatedStallionMountList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{stallion__organisation__uid}/stallionmounts',
      path: {
        'stallion__organisation__uid': stallionOrganisationUid,
      },
      query: {
        'collection_date': collectionDate,
        'collection_date__gt': collectionDateGt,
        'collection_date__gte': collectionDateGte,
        'collection_date__lt': collectionDateLt,
        'collection_date__lte': collectionDateLte,
        'o': o,
        'page': page,
        'page_size': pageSize,
        'portions_fresh__gte': portionsFreshGte,
        'portions_fresh__lte': portionsFreshLte,
        'portions_fresh_available__gte': portionsFreshAvailableGte,
        'stallion__uid': stallionUid,
        'stallion__uid__in': stallionUidIn,
        'straws_frozen__gte': strawsFrozenGte,
        'straws_frozen__lte': strawsFrozenLte,
        'straws_frozen_available__gte': strawsFrozenAvailableGte,
      },
    });
  }
  /**
   * This is a bit ugly, but the NestedViewSetMixin requires a parent queryset.
   * And GetQuerySetForUser can not have a parent.
   * And we need to annotate the queryset.
   * So the order of this mixin is super important and it works. *PRAGMATISM*
   * @returns StallionMount
   * @throws ApiError
   */
  public static stallionmountsCreate({
    stallionOrganisationUid,
    requestBody,
  }: {
    stallionOrganisationUid: string,
    requestBody: StallionMount,
  }): CancelablePromise<StallionMount> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{stallion__organisation__uid}/stallionmounts',
      path: {
        'stallion__organisation__uid': stallionOrganisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * This is a bit ugly, but the NestedViewSetMixin requires a parent queryset.
   * And GetQuerySetForUser can not have a parent.
   * And we need to annotate the queryset.
   * So the order of this mixin is super important and it works. *PRAGMATISM*
   * @returns StallionMountDetail
   * @throws ApiError
   */
  public static stallionmountsRetrieve({
    stallionOrganisationUid,
    uid,
  }: {
    stallionOrganisationUid: string,
    uid: string,
  }): CancelablePromise<StallionMountDetail> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{stallion__organisation__uid}/stallionmounts/{uid}',
      path: {
        'stallion__organisation__uid': stallionOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * This is a bit ugly, but the NestedViewSetMixin requires a parent queryset.
   * And GetQuerySetForUser can not have a parent.
   * And we need to annotate the queryset.
   * So the order of this mixin is super important and it works. *PRAGMATISM*
   * @returns StallionMount
   * @throws ApiError
   */
  public static stallionmountsUpdate({
    stallionOrganisationUid,
    uid,
    requestBody,
  }: {
    stallionOrganisationUid: string,
    uid: string,
    requestBody: StallionMount,
  }): CancelablePromise<StallionMount> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{stallion__organisation__uid}/stallionmounts/{uid}',
      path: {
        'stallion__organisation__uid': stallionOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * This is a bit ugly, but the NestedViewSetMixin requires a parent queryset.
   * And GetQuerySetForUser can not have a parent.
   * And we need to annotate the queryset.
   * So the order of this mixin is super important and it works. *PRAGMATISM*
   * @returns StallionMount
   * @throws ApiError
   */
  public static stallionmountsPartialUpdate({
    stallionOrganisationUid,
    uid,
    requestBody,
  }: {
    stallionOrganisationUid: string,
    uid: string,
    requestBody?: PatchedStallionMount,
  }): CancelablePromise<StallionMount> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{stallion__organisation__uid}/stallionmounts/{uid}',
      path: {
        'stallion__organisation__uid': stallionOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * This is a bit ugly, but the NestedViewSetMixin requires a parent queryset.
   * And GetQuerySetForUser can not have a parent.
   * And we need to annotate the queryset.
   * So the order of this mixin is super important and it works. *PRAGMATISM*
   * @returns void
   * @throws ApiError
   */
  public static stallionmountsDestroy({
    stallionOrganisationUid,
    uid,
  }: {
    stallionOrganisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{stallion__organisation__uid}/stallionmounts/{uid}',
      path: {
        'stallion__organisation__uid': stallionOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * Mark all currently remaining *Fresh* portions as destroyed.
   * @returns StallionMount
   * @throws ApiError
   */
  public static stallionmountsDestroyFreshPartialUpdate({
    stallionOrganisationUid,
    uid,
  }: {
    stallionOrganisationUid: string,
    uid: string,
  }): CancelablePromise<StallionMount> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{stallion__organisation__uid}/stallionmounts/{uid}/destroy_fresh',
      path: {
        'stallion__organisation__uid': stallionOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * Mark all currently remaining *Frozen* straws as destroyed.
   * @returns StallionMount
   * @throws ApiError
   */
  public static stallionmountsDestroyFrozenPartialUpdate({
    stallionOrganisationUid,
    uid,
  }: {
    stallionOrganisationUid: string,
    uid: string,
  }): CancelablePromise<StallionMount> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{stallion__organisation__uid}/stallionmounts/{uid}/destroy_frozen',
      path: {
        'stallion__organisation__uid': stallionOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * Mark all currently remaining fresh portions *and* frozen straws as destroyed.
   * @returns StallionMount
   * @throws ApiError
   */
  public static stallionmountsDestroyRemainingPartialUpdate({
    stallionOrganisationUid,
    uid,
  }: {
    stallionOrganisationUid: string,
    uid: string,
  }): CancelablePromise<StallionMount> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{stallion__organisation__uid}/stallionmounts/{uid}/destroy_remaining',
      path: {
        'stallion__organisation__uid': stallionOrganisationUid,
        'uid': uid,
      },
    });
  }
}

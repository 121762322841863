import classNames from 'classnames';
import React from 'react';

interface Props {
  colorClassName?: string;
  rippleClassName?: string;
  count?: number;
  className?: string;
}

/**
 * Generate a cool ping animation e.g. for new stuff
 */
export default function PingAnimation({
  count,
  className,
  colorClassName = 'bg-blue-500',
  rippleClassName = 'bg-blue-400',
}: Props): JSX.Element {
  return (
    <div className={classNames('absolute flex h-2 w-2', className)}>
      <div
        className={classNames(
          'animate-twPing absolute inline-flex h-full w-full rounded-full opacity-75 animate-infinite',
          rippleClassName,
        )}
      />
      <div className={classNames('relative inline-flex rounded-full h-2 w-2 ', colorClassName)}>{count}</div>
    </div>
  );
}

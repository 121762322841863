/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `PURCHASE` - Purchase
 * * `SALE` - Sale
 */
export enum PSTypeEnum {
  PURCHASE = 'PURCHASE',
  SALE = 'SALE',
}

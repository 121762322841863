import { Info, MapPin } from '@phosphor-icons/react';
import classnames from 'classnames';
import { useAccount } from 'context/AccountContext';
import { Contact, Horse, HorseLocation, ReportedHorseRelocation, ReportedHorseRelocationStatusEnum, ReportTypeEnum } from 'openapi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tableHiddenColumnMd, tableTbodyTrNoClick } from 'ui/Const';
import useModal from 'ui/Modals/UseModal';
import { getLocationName } from 'utilities/Contact';
import RvoInfoModal from './RvoInfoModal';
import classNames from 'classnames';

interface Props {
  horseLocation: HorseLocation;
  horse: Horse;
  contacts: Contact[] | undefined;
}

function HorseLocationHistoryRow({ horseLocation, horse, contacts }: Props): JSX.Element {
  const [selectedReportedHorseRelocations, setSelectedReportedHorseRelocations] = useState<ReportedHorseRelocation[]>();
  const [arrivalReports, setArrivalReports] = useState<ReportedHorseRelocation[]>([]);
  const [departureReports, setDepartureReports] = useState<ReportedHorseRelocation[]>([]);

  const { formatDate } = useAccount();
  const { closeModal, modalIsVisible, showModal } = useModal();
  const { t } = useTranslation();

  /**
   * Flag to indicate if the horse has been reported as a success
   */
  const isSuccessArrival = arrivalReports.some(
    report =>
      report.status &&
      [ReportedHorseRelocationStatusEnum.DG, ReportedHorseRelocationStatusEnum.VG, ReportedHorseRelocationStatusEnum.ID].includes(
        report.status,
      ),
  );

  /**
   * Flag to indicate if the horse has been reported as a success
   */
  const isSuccessDeparture = departureReports.some(
    report =>
      report.status &&
      [ReportedHorseRelocationStatusEnum.DG, ReportedHorseRelocationStatusEnum.VG, ReportedHorseRelocationStatusEnum.ID].includes(
        report.status,
      ),
  );

  /**
   * Condition to check if the report is a departure
   */
  const isDeparture = (report: ReportedHorseRelocation) => {
    return [ReportTypeEnum._2, ReportTypeEnum._5, ReportTypeEnum._6, ReportTypeEnum._7].includes(report.report_type);
  };

  /**
   * Condition to check if the report is an arrival
   */
  const isArrival = (report: ReportedHorseRelocation) => {
    return [ReportTypeEnum._1, ReportTypeEnum._3, ReportTypeEnum._4].includes(report.report_type);
  };

  /**
   * Set the arrival and departure reports
   */
  useEffect(() => {
    setArrivalReports(horseLocation.reportedhorserelocation_set.filter(isArrival));
    setDepartureReports(horseLocation.reportedhorserelocation_set.filter(isDeparture));
  }, [horseLocation]);

  return (
    <>
      <tr className={tableTbodyTrNoClick} key={horseLocation.uid}>
        <td className={classnames('text-center w-10', tableHiddenColumnMd)}>
          <MapPin size={22} weight='light' className='inline' />
        </td>
        <td className={tableHiddenColumnMd}>{horse.name}</td>
        <td className={tableHiddenColumnMd}>{getLocationName(horseLocation.location_uid, contacts)}</td>
        <td className={tableHiddenColumnMd}>
          <div
            className={classNames('flex gap-x-2 items-center', {
              'text-green-700': isSuccessArrival,
            })}
          >
            {formatDate(horseLocation.arrival_date)}
            {arrivalReports.length > 0 && (
              <button
                onClick={() => {
                  setSelectedReportedHorseRelocations(arrivalReports);
                  showModal();
                }}
              >
                <Info />
              </button>
            )}
          </div>
        </td>
        <td className={tableHiddenColumnMd}>
          <div
            className={classNames('flex gap-x-2 items-center', {
              'text-green-700': isSuccessDeparture,
            })}
          >
            {horseLocation.departure_date ? formatDate(horseLocation.departure_date) : ''}
            {departureReports.length > 0 && (
              <button
                onClick={() => {
                  setSelectedReportedHorseRelocations(departureReports);
                  showModal();
                }}
              >
                <Info />
              </button>
            )}
          </div>
        </td>
        <td className='md:hidden px-2 py-3 space-y-1'>
          <div className='flex gap-x-2'>
            <span>{horse.name}</span>
            <span className='font-light'>({getLocationName(horseLocation.location_uid, contacts)})</span>
          </div>
          <div className='flex items-center gap-x-1 text-sm'>
            <span>
              {t('arrival', 'Arrival')}: {formatDate(horseLocation.arrival_date)}
            </span>
            {arrivalReports.length > 0 && (
              <button
                onClick={() => {
                  setSelectedReportedHorseRelocations(arrivalReports);
                  showModal();
                }}
              >
                <Info />
              </button>
            )}
            {horseLocation.departure_date && (
              <>
                <span>-</span>
                {t('depature', 'Departure')}:<span>{horseLocation.departure_date ? formatDate(horseLocation.departure_date) : ''}</span>
                {departureReports.length > 0 && (
                  <button
                    onClick={() => {
                      setSelectedReportedHorseRelocations(departureReports);
                      showModal();
                    }}
                  >
                    <Info />
                  </button>
                )}
              </>
            )}
          </div>

          <RvoInfoModal
            isVisible={modalIsVisible}
            onRequestCloseModal={closeModal}
            onClosed={() => setSelectedReportedHorseRelocations(undefined)}
            horse={horse}
            reportedHorseRelocations={selectedReportedHorseRelocations}
          />
        </td>
      </tr>
    </>
  );
}
export default HorseLocationHistoryRow;

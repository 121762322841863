import { ApiError, CancelError } from 'openapi';

export interface GenericError {
  detail: ErrorDetails;
}

export interface nonFieldError {
  non_field_errors: string[];
}

export interface ErrorDetails {
  message: string;
  code: string;
  raw?: unknown;
}

export enum ApiErrorType {
  CancelError, // We cancelled the request.
  NetworkError, // Error when something with the http fails.
  AuthorizationError, // A problem with permissions.
  ApplicationError, // The api replied with an error.
}

export interface ApiErrorParserBase {
  nonFieldErrorsStrings: () => string[];
  errorType: ApiErrorType;
  statusCode: number;
  method: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'OPTIONS' | 'HEAD' | 'PATCH';
  url: string;
}

// This class can be used when using the OpenApi generated services. It will
// try to structure the errors received from the server by field name. Errors
// that are not mapped by field name are piled in nonFieldErrors().
export default class ApiErrorParser<T> implements ApiErrorParserBase {
  private genericErrorMessage: string | undefined = undefined;
  private fieldErrors = new Map<string, ErrorDetails[]>();
  private requestedFields: Set<string> = new Set<string>();
  private _nonFieldErrors: Set<string> = new Set<string>();
  public readonly errorType: ApiErrorType;
  public readonly statusCode: number;
  public readonly method: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'OPTIONS' | 'HEAD' | 'PATCH';
  public readonly url: string;

  // Pass in the caught exception from the OpenApi generated service.
  constructor(e: unknown) {
    if (e instanceof CancelError) {
      // The request is cancelled by the user.
      this.errorType = ApiErrorType.CancelError;
      return;
    }

    // This is mainly a network error.
    if (e instanceof TypeError) {
      this.genericErrorMessage = e.message;
      this.errorType = ApiErrorType.NetworkError;
      return;
    }

    if (!(e instanceof ApiError)) {
      throw Error('Failed to parse api error. Exception is not of ApiError type');
    }

    this.errorType = e.status === 403 ? ApiErrorType.AuthorizationError : ApiErrorType.ApplicationError;

    this.statusCode = e.status;
    this.method = e.request.method;
    this.url = e.url;

    console.error(`Api error handled by ApiErrorParser for '${e.url}': ${e.status} -> '${e.message}'.`);

    // for example, for a 404 the error.body is just a string
    // so instead of parsing the body, we should just get the message
    // of the error and pass it through.
    if (typeof e.body === 'string') {
      this.genericErrorMessage = e.message;
      return;
    }

    if ('detail' in e.body) {
      // We assume that it's a generic error.
      const genErr: GenericError = e.body;
      this.genericErrorMessage = genErr.detail.message;
      return;
    }

    if ('errors' in e.body) {
      this._parseError(e.body.errors);
    }

    if ('non_field_errors' in e.body) {
      // We assume that it's a nonFieldError error.
      const genErr: nonFieldError = e.body;
      this._nonFieldErrors = new Set(genErr.non_field_errors);
    }
  }

  /**
   * Parse errors from the api and structure them
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _parseError(errors: any) {
    try {
      // We assume we received field errors.
      for (const property in errors) {
        try {
          const details: ErrorDetails[] = [];
          for (const errorDetail of errors[property]) {
            // we need to check if message and code are available
            // if not we need to check if it's an object and try to parse it
            // as that could be a nested error.
            if (errorDetail.message !== undefined) {
              details.push({
                message: errorDetail.message,
                code: errorDetail.code,
                raw: errorDetail,
              });
            } else if (typeof errorDetail === 'object') {
              this._parseError(errorDetail);
            }
          }

          if (details.length > 0) {
            this.fieldErrors.set(property, details);
          }
        } catch (exception) {
          console.error(`We received an unparsable error format from the api for field ${property}.`, exception);
          this.fieldErrors.set(property, [{ message: JSON.stringify(errors[property]), code: 'api-error-parsing' }]);
        }
      }
    } catch (exception) {
      this.genericErrorMessage = errors.toString();
      console.error('We received an unparsable error format from the api.', exception);
    }
  }

  /**
   * Get the errors that are not specifically related to a field of T. But also
   * the fields that are not requested by the `fieldError` method.
   * @returns ErrorDetails[]
   */
  public nonFieldErrors(): ErrorDetails[] {
    const res: ErrorDetails[] = [];
    if (this.genericErrorMessage) {
      // generic error does not include a code, so we add one
      res.push({ message: this.genericErrorMessage, code: 'generic-error' });
    }

    // get the errors from the field errors where the key
    // does not exists in the field list. e.g. they are not related
    // to a field
    this.fieldErrors.forEach((value, key) => {
      if (!this.requestedFields.has(key)) {
        // we also receive a `__all__` field if there is a error that is related
        // to all the fields. In this case, we remove the key and just show the
        // error message. Also mutate the key a little to make it better readable
        // for the user.

        let keyText = key;
        if (keyText.length > 1) {
          keyText = key.charAt(0).toUpperCase() + key.slice(1).replaceAll('_', ' ');
        }
        const parsedKey = ['__all__', 'non_field_errors'].includes(key) ? '' : `${keyText}: `;
        value
          .map(v => ({
            code: v.code,
            message: `${parsedKey}${v.message}`,
            raw: v.raw,
          }))
          .forEach(v => res.push(v));
      }
    });

    // get the nonFieldErrors
    // non_field_errors does not include a code, so we add one
    this._nonFieldErrors.forEach(val => res.push({ message: val, code: 'non-field-error' }));

    return res;
  }

  /**
   * return only an array of strings for the nonFieldErrors()
   * @returns string[]
   */
  public nonFieldErrorsStrings(): string[] {
    return this.nonFieldErrors().map(error => error.message);
  }

  /**
   * Get the error related to the field of T.
   *
   * @param key the fieldName you want to get the error for.
   * @param addToRequestFields by default we add the field to the requested fields. If you don't want this, set this to false.
   */
  public fieldError<K extends keyof T>(key: K, addToRequestFields = true): string | undefined {
    if (addToRequestFields) {
      this.requestedFields.add(key.toString());
    }

    const res = this.fieldErrors.get(key.toString());
    if (!res) {
      return undefined;
    }
    return res.map(error => error.message).join(' ');
  }
}

import { useCallback, useState } from 'react';
import { CancelablePromise, HorselocationsService, PaginatedHorseLocationMutationList } from 'openapi';
import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';

interface ReturnType {
  reportCount: number;
  loading: boolean;
  error: ApiErrorParser<PaginatedHorseLocationMutationList> | undefined;
  loadReportCount: () => CancelablePromise<PaginatedHorseLocationMutationList> | undefined;
}

const useRvoReportCount = (): ReturnType => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<ApiErrorParser<PaginatedHorseLocationMutationList> | undefined>();

  const { setRvoReportCount, rvoReportCount } = useOrganization();
  const { selectedOrganization } = useOrganization();

  /**
   * load the report count
   */
  const loadReportCount = useCallback((): CancelablePromise<PaginatedHorseLocationMutationList> | undefined => {
    if (selectedOrganization?.uid) {
      setLoading(true);

      // TODO, perhaps a single API call for this one to just get the count
      // See https://gitlab.qubis.nl/equinem/equinemcore/-/issues/381
      const promise = HorselocationsService.horselocationsMutationsWithoutReportList({
        locationOrganisationUid: selectedOrganization?.uid ?? '',
        pageSize: 1,
      });
      promise
        .then(res => {
          setRvoReportCount(res.count);

          setApiError(undefined);
        })
        .catch(e => {
          if (!promise.isCancelled) {
            setApiError(new ApiErrorParser<PaginatedHorseLocationMutationList>(e));
          }
        });
      return promise;
    }
  }, [selectedOrganization?.uid, setRvoReportCount]);

  return {
    reportCount: rvoReportCount,
    loading,
    loadReportCount,
    error: apiError,
  };
};

export default useRvoReportCount;

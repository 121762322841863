import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';

interface Props {
  active?: boolean;
  size?: 'sm' | 'base';
  onToggle?: (active: boolean) => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
}

export default function Toggle({ active = false, size = 'base', onToggle, disabled, loading, className }: Props): JSX.Element {
  const [toggleActive, setToggleActive] = useState<boolean>(active);
  const toggleName = `toggle_${nanoid()}`;

  /**
   * When the user toggled the checkbox
   */
  const onChange = (): void => {
    setToggleActive(prevState => !prevState);
    onToggle?.(!toggleActive);
  };

  useEffect(() => {
    setToggleActive(active);
  }, [active]);

  return (
    <>
      <div
        className={classNames('relative inline-block align-middle select-none duration-150 ease-in transition-all', className, {
          'w-10': size === 'base',
          'w-8': size === 'sm',
          '!w-6': loading && size === 'base',
          '!w-5': loading && size === 'sm',
          'cursor-not-allowed': loading,
          'before:animate-spin before:z-20 before:rounded-t-full before:border-transparent before:border-t-blue-600 before:border-4 before:w-full before:h-full before:absolute':
            loading,
        })}
      >
        <input
          disabled={disabled || loading}
          onChange={onChange}
          type='checkbox'
          name={toggleName}
          id={toggleName}
          className={classNames('absolute block rounded-full border-4 appearance-none bg-white transition-all', {
            'w-6 h-6': size === 'base',
            'w-5 h-5': size === 'sm',
            'right-0 border-blue-600': toggleActive,
            'border-gray-300': !toggleActive,
            'bg-blue-600/25': toggleActive && (disabled || loading),
            'cursor-pointer': !(disabled || loading),
            'cursor-not-allowed': disabled || loading,
          })}
        />
        <label
          htmlFor={toggleName}
          className={classNames('block overflow-hidden rounded-full', {
            'h-6': size === 'base',
            'h-5': size === 'sm',
            'bg-gray-300': !toggleActive,
            'bg-blue-600': toggleActive,
            'bg-blue-600/25': toggleActive && (disabled || loading),
            'cursor-pointer': !(disabled || loading),
            'cursor-not-allowed': disabled || loading,
          })}
        />
      </div>
    </>
  );
}

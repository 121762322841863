import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import useRvoReportCount from 'hooks/UseRvoReportCount';
import { ArrivalOrDepartureEnum, HorseLocationMutation, HorselocationsService } from 'openapi';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { CheckboxInput } from 'ui/Inputs';
import { ActionModal } from 'ui/Modals';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onSuccess: () => void;
  horseLocationMutations: HorseLocationMutation[];
}

function RvoDeleteReport({ isVisible, onRequestCloseModal, onSuccess, horseLocationMutations }: Props): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<void> | undefined>();
  const [showConfirmedError, setShowConfirmedError] = useState<boolean>();
  const [userConfirmed, setUserConfirmed] = useState<boolean>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  const { loadReportCount } = useRvoReportCount();

  const deleteReport = useCallback(async () => {
    if (!userConfirmed) {
      setShowConfirmedError(true);
      return;
    }

    try {
      for (const horseLocationMutation of horseLocationMutations) {
        if (horseLocationMutation?.arrival_or_departure === ArrivalOrDepartureEnum.DEPARTURE) {
          await HorselocationsService.horselocationsHideDepartureCreate({
            locationOrganisationUid: selectedOrganization?.uid ?? '',
            uid: horseLocationMutation?.uid ?? '',
          });
        } else {
          await HorselocationsService.horselocationsHideArrivalCreate({
            locationOrganisationUid: selectedOrganization?.uid ?? '',
            uid: horseLocationMutation?.uid ?? '',
          });
        }

        // Sleep 100 milisec between every request to give the api some air.
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      // reload the report count
      loadReportCount();

      // call the events
      onRequestCloseModal();
      onSuccess();
    } catch (e) {
      setApiError(new ApiErrorParser(e));
    }
  }, [horseLocationMutations, loadReportCount, onRequestCloseModal, onSuccess, selectedOrganization?.uid, userConfirmed]);

  /**
   * Handle the checkbox change
   */
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    // remove the error message if the user has checked the checkbox
    if (checked === true) {
      setShowConfirmedError(false);
    }

    setUserConfirmed(e.target.checked);
  };

  /**
   * Reset the states
   */
  useEffect(() => {
    if (isVisible) {
      setApiError(undefined);
      setShowConfirmedError(undefined);
      setUserConfirmed(undefined);
    }
  }, [isVisible]);

  return (
    <ActionModal
      open={isVisible}
      title={t('delete-rvo-report', 'Delete RVO report')}
      actions={[
        { text: t('close', 'Close'), variant: ButtonVariant.Default, onClick: onRequestCloseModal },
        { text: t('delete', 'Delete'), variant: ButtonVariant.Danger, onClick: deleteReport },
      ]}
    >
      {apiError && <ErrorSection errors={apiError} />}
      {showConfirmedError === true && <ErrorSection errors='You should confirm in order to submit this request' />}

      <p className='mt-2 mb-4'>
        {horseLocationMutations.length === 1 ? (
          <Trans
            i18nKey='delete-rvo-report-message-singular'
            defaults={`You are about to remove a report that should be logged to RVO. By deleting this report, you will no longer be able to report it to RVO, and the history in EquineM will be ahead of RVO.`}
          />
        ) : (
          <Trans
            i18nKey='delete-rvo-report-message-plural'
            defaults={`
        You are about to remove {{count}} report that should be logged to RVO. By deleting these report, you will no longer be able to report them to RVO, and the history in EquineM will be ahead of RVO.
        
       `}
            values={{ count: horseLocationMutations.length }}
          />
        )}
      </p>

      <p className='mb-4'>
        {t('rvo-delete-you-are-responsible', 'You are still responsible for reporting this horse to RVO manually if needed.')}
      </p>

      <CheckboxInput onChange={handleCheckboxChange} label={t('i-understand-this-action.', 'I understand this action.')} />
    </ActionModal>
  );
}

export default RvoDeleteReport;

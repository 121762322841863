import { IconContext } from '@phosphor-icons/react';
import React, { useMemo } from 'react';
import { AvatarSize } from '.';
import Base from './Base';

interface Props {
  size?: AvatarSize;
  icon: React.ReactNode;
  color?: string;
  uuid?: string; // Generates a color from this string
  disabled?: boolean;
}

// A circle with a phosphor icon inside.
export default function Icon({ icon, color, size = AvatarSize.Normal, uuid, disabled }: Props): JSX.Element {
  const iconSize = useMemo(() => {
    switch (size) {
      case AvatarSize.XSmall:
        return 18;
      case AvatarSize.Small:
        return 20;
      case AvatarSize.Normal:
        return 22;
      case AvatarSize.Large:
        return 25;
    }
  }, [size]);
  return (
    <Base color={color} size={size} uuid={uuid} disabled={disabled}>
      <IconContext.Provider
        value={{
          size: iconSize,
        }}
      >
        {icon}
      </IconContext.Provider>
    </Base>
  );
}

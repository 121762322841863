import React, { createContext, useContext, useState } from 'react';
import { Config } from '../api/Config';

interface Props {
  config?: Config;
  children: React.ReactNode;
}

type ConfigContextType = {
  config: Config | undefined;
  setConfig: (conf: Config) => void;
};

const ConfigContext = createContext<ConfigContextType>({
  config: undefined,
  setConfig: () => console.warn('no Config provider'),
});

export function useConfig(): ConfigContextType {
  return useContext(ConfigContext);
}

export function ConfigProvider({ children, config: givenConfig }: Props): JSX.Element {
  const [config, setConfig] = useState<Config | undefined>(givenConfig);
  return <ConfigContext.Provider value={{ config, setConfig }}>{children}</ConfigContext.Provider>;
}

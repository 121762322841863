import { zodResolver } from '@hookform/resolvers/zod';
import { Info } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import { Contact, ContactsService, InvoiceDetail, InvoiceFinalize, InvoicesService, PatchedContact } from 'openapi';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useOrganization } from '../../context/OrganizationContext';
import { Alert } from '../../ui/Alert';
import { ButtonVariant } from '../../ui/Button';
import { CheckboxInput, TextInput } from '../../ui/Inputs';
import { PageModal } from '../../ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from '../../ui/Modals/PageModal';
import { contactName } from '../../utilities/Contact';
import { Severity } from '../../utilities/severity';

interface Props {
  contact: Contact;
  invoice: InvoiceDetail;
  open: boolean;

  // The invoice is the created or updated invoice
  onRequestClose: (reload: boolean) => void;
}

const FinalizeInvoiceFormSchema = z.object({
  contact_email: z.string().optional(),
  send_email: z.boolean().optional(),
});

type FinalizeInvoiceForm = z.infer<typeof FinalizeInvoiceFormSchema>;

function FinalizeInvoice({ invoice, contact, open, onRequestClose }: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FinalizeInvoiceForm>({
    resolver: zodResolver(FinalizeInvoiceFormSchema),
    reValidateMode: 'onChange',
    defaultValues: {
      contact_email: contact.email,
      send_email: true,
    },
  });

  const watchSendEmail = watch('send_email');

  const onSubmit = async (data: FinalizeInvoiceForm) => {
    if (!selectedOrganization) {
      console.error('Cannot load invoice without a selected organization');
      return;
    }
    setErrorMessage(undefined);

    if (data.send_email && !contact.email) {
      // Patch email field of the contact
      // TODO better error handling
      try {
        await ContactsService.contactsPartialUpdate({
          organisationUid: selectedOrganization.uid,
          uid: contact.uid,
          requestBody: {
            email: data.contact_email,
          },
        });
      } catch (e) {
        const error = new ApiErrorParser<PatchedContact>(e);
        setErrorMessage(`${t('update-contact-failed', 'Unable to update the contact.')} ${error.nonFieldErrorsStrings().join(', ')}`);
      }
    }

    try {
      await InvoicesService.invoicesFinalizeCreate({
        organisationUid: selectedOrganization.uid ?? '',
        uid: invoice.uid,
        requestBody: {
          send_email: data.send_email ?? false,
          send_to_accounting_software: true,
        },
      });
      onRequestClose(true);
    } catch (e) {
      const error = new ApiErrorParser<InvoiceFinalize>(e);
      setErrorMessage(`${t('finalize-invoice-failed', 'Unable to finalize invoice.')} ${error.nonFieldErrorsStrings().join(', ')}`);
    }
  };

  return (
    <PageModal open={open} width={PageModalWidth.Sm}>
      <PageModalTitle title={t('finalize-invoice', 'Finalize invoice')} onClose={() => onRequestClose(false)} />
      <PageModalContent>
        <p>{t('send-invoice-subtext', 'This action will mark the invoice as finalized and ready for payment.')}</p>
        <form className='flex flex-col gap-4' noValidate={true} id='finalizeInvoice' onSubmit={handleSubmit(onSubmit)}>
          <CheckboxInput
            labelElement={<span className={'text-sm'}>{t('send-invoice-via-email', 'Send invoice via email')}</span>}
            {...register('send_email')}
          />
          {watchSendEmail && (
            <p className='text-sm'>
              <span>
                {t('invoice-sent-email-hint', 'An email will be sent to the customer containing the invoice and payment instructions.')}
              </span>
              {contact.email && (
                <span>
                  {t('invoice-customer-email-is-used', 'The email address that will be used is')} {contact.email}.
                </span>
              )}
            </p>
          )}
          {contact && !contact.email && watchSendEmail && (
            <div className='border rounded p-4'>
              <p className='text-sm font-medium mb-2'>
                <Info className='inline mb-1 text-lg' /> {t('set-contact-email', 'Set contact email address')}
              </p>
              <p className='text-sm'>
                {contactName(contact)}{' '}
                {t(
                  'invoice-customer-no-mail',
                  'has no email address set. Please provide the email address below so we can send the invoice.',
                )}
                <TextInput
                  error={errors.contact_email?.message}
                  placeholder={t('email-address', 'Email address')}
                  {...register('contact_email')}
                />
              </p>
            </div>
          )}
        </form>
        {errorMessage && (
          <div className='mt-4'>
            <Alert severity={Severity.Danger} message={errorMessage} />
          </div>
        )}
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            variant: ButtonVariant.Primary,
            text: watchSendEmail ? t('finalize-and-send', 'Finalize and send') : t('finalize', 'Finalize'),
            type: 'submit',
            formId: 'finalizeInvoice',
            loading: isSubmitting,
          },
        ]}
      />
    </PageModal>
  );
}
export default FinalizeInvoice;

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatchedVaccinationRule } from '../models/PatchedVaccinationRule';
import type { VaccinationRule } from '../models/VaccinationRule';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class VaccinationrulesService {
  /**
   * Viewset for VaccinationRules
   * @returns VaccinationRule
   * @throws ApiError
   */
  public static vaccinationrulesList({
    organisationUid,
  }: {
    organisationUid: string,
  }): CancelablePromise<Array<VaccinationRule>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/vaccinationrules',
      path: {
        'organisation__uid': organisationUid,
      },
    });
  }
  /**
   * Viewset for VaccinationRules
   * @returns VaccinationRule
   * @throws ApiError
   */
  public static vaccinationrulesCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody: VaccinationRule,
  }): CancelablePromise<VaccinationRule> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/vaccinationrules',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Viewset for VaccinationRules
   * @returns VaccinationRule
   * @throws ApiError
   */
  public static vaccinationrulesRetrieve({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<VaccinationRule> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/vaccinationrules/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * Viewset for VaccinationRules
   * @returns VaccinationRule
   * @throws ApiError
   */
  public static vaccinationrulesUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody: VaccinationRule,
  }): CancelablePromise<VaccinationRule> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/vaccinationrules/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Viewset for VaccinationRules
   * @returns VaccinationRule
   * @throws ApiError
   */
  public static vaccinationrulesPartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedVaccinationRule,
  }): CancelablePromise<VaccinationRule> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/vaccinationrules/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Viewset for VaccinationRules
   * @returns void
   * @throws ApiError
   */
  public static vaccinationrulesDestroy({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{organisation__uid}/vaccinationrules/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
}

import { DotsThreeVertical } from '@phosphor-icons/react';
import { ProviderEnum, VatCategoryEnum, VATPercentage, VATPercentageExternalProviderId } from 'openapi';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { tableHiddenColumnMd, tableTbodyTrNoClick } from 'ui/Const';
import classNames from 'classnames';
import DropdownMenu from 'ui/DropdownMenu';
import { useAccount } from 'context/AccountContext';
import Badge from 'ui/Badge';
import { AllColors } from 'utilities/colors';
import { VATPercentageVatLevelToString } from 'utilities/VATPercentageVatLevelEnumToString';

interface Props {
  vatPercentage: VATPercentage;
  bookkeepingProviders: ProviderEnum[];
  bookkeepingProvidersIds: VATPercentageExternalProviderId[];
  onEdit: (vatPercentage: VATPercentage) => void;
  onDelete: (vatPercentage: VATPercentage) => void;
}

export default function VatPercentagesListRow({
  vatPercentage,
  bookkeepingProviders,
  bookkeepingProvidersIds,
  onEdit,
  onDelete,
}: Props): JSX.Element {
  const { formatNumber, formatDate } = useAccount();
  const { t } = useTranslation();

  const startInFutureDate = useMemo((): string | undefined => {
    let startInFuture: string | undefined = undefined;
    if (vatPercentage.start_date && !vatPercentage.hidden) {
      const start = new Date(Date.parse(vatPercentage.start_date));
      const today = new Date();
      startInFuture = start > today ? formatDate(start) : undefined;
    }
    return startInFuture;
  }, [vatPercentage, formatDate]);

  // Get the ids/references for the vat percentage at the external provider.
  const providerIdString = useCallback(
    (provider: ProviderEnum) => {
      const extProviderId = bookkeepingProvidersIds?.find(
        providerId => providerId.provider === provider && providerId.vat_percentage_uid === vatPercentage.uid,
      );
      const ids: string[] = [];
      if (extProviderId?.external_provider_purchases_id) {
        ids.push(extProviderId.external_provider_purchases_id);
      }
      if (
        extProviderId?.external_provider_sales_id &&
        extProviderId?.external_provider_sales_id !== extProviderId?.external_provider_purchases_id
      ) {
        ids.push(extProviderId.external_provider_sales_id);
      }
      return ids.length > 0 ? ids.join(' / ') : '-';
    },
    [bookkeepingProvidersIds, vatPercentage],
  );

  const vatPercentageName = useCallback(
    (vatPercentage: VATPercentage) => {
      switch (vatPercentage.vat_category) {
        case VatCategoryEnum.G:
          return `0% ${t('vat-export', 'Export')}`;
        case VatCategoryEnum.AE:
          return `0% ${t('vat-reverse-charge', 'Reverse-charge')}`;
        default:
          return `${formatNumber(Number(vatPercentage.percentage))}%`;
      }
    },
    [t, formatNumber],
  );

  return (
    <tr className={tableTbodyTrNoClick} key={vatPercentage.uid}>
      <td className='pl-3 w-2/3'>
        <div className='inline-flex gap-1'>
          <p>{vatPercentageName(vatPercentage)}</p>
          {vatPercentage.is_active && <Badge color={AllColors.Green}>{t('active', 'Active')}</Badge>}
          {startInFutureDate !== undefined && <Badge color={AllColors.Blue}>{`${t('active-at', 'Active at')} ${startInFutureDate}`}</Badge>}
        </div>
      </td>
      <td>{vatPercentage.vat_level ? VATPercentageVatLevelToString(t, vatPercentage.vat_level) : ''}</td>
      {bookkeepingProviders.map(provider => {
        return (
          <td key={provider} className={classNames(tableHiddenColumnMd)}>
            {providerIdString(provider)}
          </td>
        );
      })}
      <td className='w-14 pr-2'>
        <DropdownMenu
          menuPlacement='bottom-end'
          menuItems={[
            [
              { element: t('edit', 'Edit'), onClick: () => onEdit(vatPercentage) },
              { element: t('remove', 'Remove'), onClick: () => onDelete(vatPercentage), className: 'text-red-600' },
            ],
          ]}
        >
          <div className='w-full h-full text-center cursor-pointer pt-2 pb-1'>
            <DotsThreeVertical size={24} weight='bold' className='inline' />
          </div>
        </DropdownMenu>
      </td>
    </tr>
  );
}

import { Plus } from '@phosphor-icons/react';
import CreateContactModal from 'components/Contacts/CreateContactModal';
import CreateStableModal from 'components/Locations/CreateStableModal';
import LocationTable from 'components/Locations/LocationTable';
import { useOrganization } from 'context/OrganizationContext';
import { PageAction } from 'context/PageContext';
import { Contact, ContactsService } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import Page, { PageMaxWidth } from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import useModal from 'ui/Modals/UseModal';
import { ApiPromises } from 'utilities/ApiPromises';

function ManageLocations(): JSX.Element {
  const [contacts, setContacts] = useState<Contact[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { modalIsVisible: createModalIsVisible, closeModal: closeCreateModal, showModal: showCreateModal } = useModal();
  const { closeModal: closeSaveContactModal, modalIsVisible: saveContactModalIsVisible, showModal: showSaveContactModal } = useModal();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  /**
   * Load data from the api/cache
   */
  const loadApiData = useCallback((organisationUid: string): ApiPromises => {
    const promises = new ApiPromises();

    promises.appendListObj<Contact>('contacts', setContacts, {
      promiseCallback: () => {
        return ContactsService.contactsList({
          organisationUid,
          hidden: false,
          isUser: false,
        });
      },
    });

    setApiPromises(promises);
    return promises;
  }, []);

  /**
   * List the locations that are not stables and have no stable location uid
   */
  const otherLocations = useMemo(() => {
    return (contacts ?? []).filter(contact => contact.external_location && contact.stable_location_uid === null);
  }, [contacts]);

  /**
   * List only the stables
   */
  const stableContacts = useMemo(() => {
    return contacts?.filter(contact => contact.stable_location_uid !== null);
  }, [contacts]);

  const pageActionsCreateStable = useMemo((): PageAction[] => {
    return [
      {
        text: t('add', 'Add'),
        isMobileAddAction: true,
        icon: <Plus />,
        buttonVariant: ButtonVariant.Default,
        onClick: showCreateModal,
      },
    ];
  }, [showCreateModal, t]);

  const tileActionsCreateExternalLocation = useMemo((): PageAction[] => {
    return [
      {
        text: t('add', 'Add'),
        isMobileAddAction: true,
        icon: <Plus />,
        buttonVariant: ButtonVariant.Default,
        onClick: showSaveContactModal,
      },
    ];
  }, [showSaveContactModal, t]);

  /**
   * Load from the api
   */
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData(selectedOrganization.uid);
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  return (
    <Page title={t('manage-locations', 'Manage locations')} maxWidth={PageMaxWidth.Tile} loading={apiPromises}>
      <div className='space-y-10'>
        <Tile title={t('stables', 'Stables')} loading={!stableContacts} actions={pageActionsCreateStable}>
          {stableContacts && contacts && (
            <LocationTable
              locations={stableContacts}
              onDeleted={() => selectedOrganization && loadApiData(selectedOrganization.uid)}
              onContactUpdated={() => selectedOrganization && loadApiData(selectedOrganization.uid)}
            />
          )}
        </Tile>

        <Tile
          title={t('pastures-and-other-locations', 'Pastures and other locations')}
          loading={!otherLocations}
          actions={tileActionsCreateExternalLocation}
        >
          {otherLocations.length > 0 && contacts && (
            <LocationTable
              locations={otherLocations}
              onDeleted={() => selectedOrganization && loadApiData(selectedOrganization.uid)}
              onContactUpdated={() => selectedOrganization && loadApiData(selectedOrganization.uid)}
            />
          )}

          {otherLocations.length === 0 && (
            <p className='italic'>{t('no-pastures-and-other-locations', 'There are no other pastures and / or other location created')}</p>
          )}
        </Tile>
      </div>

      <CreateStableModal
        isVisible={createModalIsVisible}
        onSaved={() => selectedOrganization && loadApiData(selectedOrganization.uid)}
        onRequestCloseModal={closeCreateModal}
      />

      <CreateContactModal
        onSaved={() => selectedOrganization && loadApiData(selectedOrganization.uid)}
        isVisible={saveContactModalIsVisible}
        onRequestCloseModal={closeSaveContactModal}
        isMyExternalLocation={true}
      />
    </Page>
  );
}

export default ManageLocations;

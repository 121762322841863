import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { Contact, HorsesService, GetHorsesFromRvo } from 'openapi';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import PageModal, { ActionProps, PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onSuccess: () => void;
  location: Contact | undefined;
}
function RvoImportHorseLocationModal({ isVisible, onRequestCloseModal, onSuccess, location }: Props): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorParser<GetHorsesFromRvo> | undefined>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  /**
   * Submit handler
   */
  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    const promise = HorsesService.horsesGetHorsesFromRvoCreate({
      organisationUid: selectedOrganization?.uid ?? '',
      requestBody: {
        // only send the location UID if it is not the stable location
        location_uid: location?.stable_location_uid === null ? location?.uid : undefined,
        create_new: true,
      },
    });

    try {
      await promise;
      onSuccess();
      onRequestCloseModal();
    } catch (e) {
      setApiError(new ApiErrorParser(e));
    } finally {
      setIsSubmitting(false);
    }
  }, [location, onRequestCloseModal, onSuccess, selectedOrganization?.uid]);

  /**
   * Render for both success and the default state the actions
   */
  const actions = useMemo((): ActionProps[] => {
    return [
      {
        loading: isSubmitting,
        variant: ButtonVariant.Primary,
        text: t('import', 'Import'),
        type: 'submit',
        onClick: onSubmit,
      },
    ];
  }, [isSubmitting, onSubmit, t]);

  return (
    <PageModal
      open={isVisible}
      onClosed={() => {
        setApiError(undefined);
      }}
    >
      <PageModalTitle title={t('rvo-import-horse-locations-modal-title', 'Import horse locations')} onClose={onRequestCloseModal} />
      <PageModalContent>
        <div className='mt-2 space-y-4'>
          <ErrorSection errors={apiError} />

          <p>
            {t(
              'rvo-import-horse-location-modal-desc',
              'You are about to import the horse locations from the RVO. In this process we will create horses that are not yet created in Equinem. Are you sure you want to continue?',
            )}
          </p>
        </div>
      </PageModalContent>
      <PageModalActions actions={actions} />
    </PageModal>
  );
}

export default RvoImportHorseLocationModal;

import React, { useCallback, useEffect, useState } from 'react';
import { useOrganization } from 'context/OrganizationContext';
import { CancelablePromise, PickedStallionMount, StallionMountDetail, StallionmountsService } from 'openapi';
import { useTranslation } from 'react-i18next';
import { generatePath, NavLink } from 'react-router-dom';
import { AppRoutes } from 'AppRoutes';
import { navBackToThisPage } from 'ui/Layout/Page';

interface PickProps {
  pickInfo: Array<PickedStallionMount>;
}

/**
 * A small component that shows the pick status of a semen order. When it's
 * picked it will create a list of stallion mounts where to user can navigate to.
 */
export default function PickStatus({ pickInfo }: PickProps): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  const [relatedMounts, setRelatedMounts] = useState<StallionMountDetail[]>();

  // Load the vat percentages from api and/or cache
  const loadMounts = useCallback((): CancelablePromise<StallionMountDetail>[] => {
    const mountsPromises = pickInfo.map(info => {
      const promise = StallionmountsService.stallionmountsRetrieve({
        stallionOrganisationUid: selectedOrganization?.uid ?? '',
        uid: info.stallion_mount,
      });
      promise.catch(e => {
        if (!promise.isCancelled) {
          console.error('Mount not loaded from api', e);
        }
      });

      return promise;
    });
    Promise.all(mountsPromises)
      .then(setRelatedMounts)
      .catch(e => console.error(e));
    return mountsPromises;
  }, [selectedOrganization, pickInfo]);

  useEffect(() => {
    // We need to have a selected organization.
    if (selectedOrganization) {
      const promises = loadMounts();
      return () => promises.forEach(promise => promise.cancel());
    }
  }, [selectedOrganization]); //eslint-disable-line

  if (pickInfo.length === 0) {
    return <p>{t('order-not-picked', 'Not picked')}</p>;
  }
  return (
    <ul>
      {pickInfo.map(info => {
        const mount = (relatedMounts ?? []).find(mnt => mnt.uid === info.stallion_mount);
        if (mount) {
          return (
            <li key={info.stallion_mount}>
              {info.amount}
              {' × '}
              {t('from-mount', 'from mount')}{' '}
              <NavLink
                className='text-blue-500'
                to={{
                  pathname: generatePath(AppRoutes.MountDetails.path, { uid: info.stallion_mount }),
                  search: navBackToThisPage().toString(),
                }}
              >
                {mount.collection_date}
              </NavLink>
            </li>
          );
        } else {
          <li key={info.stallion_mount}>
            {info.amount}
            {' × '}
            {t('from-mount', 'from mount')}{' '}
            <NavLink
              className='text-blue-500'
              to={{
                pathname: generatePath(AppRoutes.MountDetails.path, { uid: info.stallion_mount }),
                search: navBackToThisPage().toString(),
              }}
            >
              {info.stallion_mount}
            </NavLink>
          </li>;
        }
      })}
    </ul>
  );
}

import { SignOut } from '@phosphor-icons/react';
import { useAccount } from 'context/AccountContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonVariant } from 'ui/Button';
import { Tile } from 'ui/Layout/Tile';

export default function LogoutTile(): JSX.Element {
  const { t } = useTranslation();
  const { logout, accountDetails } = useAccount();

  return (
    <Tile title={t('logout', 'Logout')}>
      <p className='mb-4'>
        {t('logout-desc-1', 'You are logged in as')} {accountDetails?.email}
        {'. '}
        {t('logout-desc-2', 'Click the button below to logout of this account.')}
      </p>
      <Button variant={ButtonVariant.Danger} icon={<SignOut />} onClick={logout}>
        {t('logout', 'Logout')}
      </Button>
    </Tile>
  );
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `arrival` - arrival
 * * `departure` - departure
 */
export enum ArrivalOrDepartureEnum {
  ARRIVAL = 'arrival',
  DEPARTURE = 'departure',
}

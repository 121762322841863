import React from 'react';
import { useTranslation } from 'react-i18next';
import Article from '../../ui/Article';
import Button, { ButtonSize, ButtonVariant } from '../../ui/Button';
import { infoToast, successToast } from '../../utilities/toast-manager';

function ToastShowcase(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Article title='Toast Messages' caption='Toasts with different severity levels'>
      <div className='flex gap-2 items-center flex-wrap'>
        <Button onClick={() => successToast(t('toast.success', 'Success toast'))} size={ButtonSize.Normal} variant={ButtonVariant.Default}>
          {t('toast.success', 'Success toast')}
        </Button>
        <Button onClick={() => infoToast(t('toast.info', 'Info toast'))} size={ButtonSize.Normal} variant={ButtonVariant.Default}>
          {t('toast.info', 'Info toast')}
        </Button>
      </div>
    </Article>
  );
}
export default ToastShowcase;

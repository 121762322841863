import { ArrowSquareOut, DownloadSimple, Horse as HorseIcon, ShoppingCart } from '@phosphor-icons/react';
import { useOrganization } from 'context/OrganizationContext';
import {
  CancelablePromise,
  Contact,
  ContactsService,
  Horse,
  InvoiceDetail,
  PaginatedContactList,
  PaymentStatusEnum,
  ProviderEnum,
} from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, NavLink } from 'react-router-dom';
import { navBackToThisPage } from 'ui/Layout/Page';
import { integrationName } from 'utilities/Integrations';
import useInvoiceDownloader from '../../api/hooks/useInvoiceDownloader';
import { AppRoutes } from '../../AppRoutes';
import { useAccount } from '../../context/AccountContext';
import Badge, { BadgeSize } from '../../ui/Badge/Badge';
import Button, { ButtonSize, ButtonVariant } from '../../ui/Button';
import { contactName } from '../../utilities/Contact';
import { copyTextToClipboard } from '../../utilities/string.utility';
import { PaymentStatusToColor, PaymentStatusToString } from './Helpers';
import InvoiceNumber from './InvoiceNumber';

interface Props {
  invoice: InvoiceDetail;
  customer?: Contact;
  horses?: Horse[];
  requestMarkAsPaid: () => void;
  requestResendToBookkeeping: () => void;
}

function InvoiceMeta({ invoice, customer, horses, requestMarkAsPaid, requestResendToBookkeeping }: Props): JSX.Element {
  const { t } = useTranslation();

  const { selectedOrganization } = useOrganization();
  const { download, preparing: downloadPreparing } = useInvoiceDownloader();
  const { formatDate } = useAccount();
  const [createdBy, setCreatedBy] = useState<Contact>();

  const nameContact = useCallback(
    (contact?: Contact): string => {
      if (!contact) {
        return '';
      }
      return contactName(contact) ?? `<${t('nameless-contact', 'Nameless contact')}>`;
    },
    [t],
  );

  // Returns a list of bookkeeping providers to where this invoice has been sent.
  const bookkeeping = useMemo((): string[] => {
    const res: string[] = [];
    if (invoice.exactnl_invoice_id) {
      res.push(`${integrationName(t, ProviderEnum.EXACTNL) ?? 'exactnl'} ID: ${invoice.exactnl_entry_no}`);
    }
    if (invoice.moneybird_invoice_id) {
      res.push(`${integrationName(t, ProviderEnum.MONEYBIRD) ?? 'moneybird'}`);
    }
    if (invoice.yuki_invoice_id) {
      res.push(`${integrationName(t, ProviderEnum.YUKI) ?? 'yuki'}`);
    }
    return res;
  }, [invoice, t]);

  const publicUrl = useMemo((): string => {
    if (!invoice || !invoice.public_access_uuid) {
      return '';
    }
    const url = new URL(generatePath(AppRoutes.PublicInvoice.path, { publicAccessUuid: invoice.public_access_uuid }), window.location.href);
    return url.toString();
  }, [invoice]);

  const publicUrlTruncated = useMemo((): string => {
    const maxLength = 30;
    return publicUrl.length > maxLength ? publicUrl.substring(0, maxLength) + '...' : publicUrl;
  }, [publicUrl]);

  const invoiceStatus = useMemo(() => {
    if (invoice.draft) {
      return t('in-draft', 'In draft');
    }
    return `${t('finalized', 'Finalized')} (${t('finalized-at', 'at')} ${
      invoice.finalized_on ? formatDate(new Date(invoice.finalized_on)) : 'unknown date'
    })`;
  }, [invoice, t, formatDate]);

  // Get a list of related horses found in the horse field of the invoice items.
  const relatedHorses = useMemo(() => {
    if (!horses || !invoice || !invoice.items) {
      return [];
    }
    const horseIds = invoice.items.filter(i => i.horse_uid !== undefined && i.horse_uid !== null).map(i => i.horse_uid) as string[];
    const found: Horse[] = [];
    for (const horseId of horseIds) {
      if (found.find(horse => horse.uid === horseId)) {
        continue; // Duplicate, already in the array.
      }
      const horse = horses.find(h => h.uid === horseId);
      if (horse) {
        found.push(horse);
      } else {
        console.error(`Cannot find horse with id ${horseId} to fill invoice related horses.`);
      }
    }
    return found;
  }, [invoice, horses]);

  /**
   * Load the user whom created the invoice.
   */
  useEffect(() => {
    const abortController = new AbortController();
    if (!createdBy && selectedOrganization) {
      const promise: CancelablePromise<PaginatedContactList> = ContactsService.contactsList({
        organisationUid: selectedOrganization.uid,
        userUid: invoice.created_by_uid,
      });
      abortController.signal.onabort = () => promise.cancel();
      promise
        .then(result => {
          if (result.count !== 1) {
            console.error(`Trying to find the user that created to order. But result count is ${result.count}.`);
          } else {
            setCreatedBy(result.results[0]);
          }
        })
        .catch(e => {
          // TODO
          console.error(e);
        });
    }
    return () => abortController.abort();
  }, [selectedOrganization, createdBy, invoice]);

  return (
    <>
      <dl className='mb-4'>
        <dt className='font-medium mt-2 text-sm mb-1'>{t('invoice-number', 'Invoice number')}</dt>
        <dd>
          <InvoiceNumber invoice={invoice} />
        </dd>
        <div className='flex flex-col md:flex-row gap-2 mt-2 pt-2 border-t'>
          <div className='grow'>
            <dt className='font-medium text-sm mb-1'>{t('invoice-status', 'Invoice status')}</dt>
            <dd>{invoiceStatus}</dd>
          </div>
          {invoice.finalized_on && invoice.public_access_uuid && (
            <Button
              icon={<DownloadSimple />}
              loading={downloadPreparing}
              variant={ButtonVariant.Default}
              onClick={() => download(invoice.public_access_uuid ?? '')}
            >
              {t('download-invoice-pdf', 'Download Invoice (pdf)')}
            </Button>
          )}
        </div>
        <dt className='font-medium mt-2 pt-2 border-t text-sm mb-1'>{t('invoice-language', 'Invoice language')}</dt>

        <dd>{invoice.language}</dd>
        <dt className='font-medium mt-2 pt-2 border-t text-sm mb-1'>{t('contact', 'Contact')}</dt>
        <dd>
          <NavLink
            className='text-blue-500'
            to={{
              pathname: generatePath(AppRoutes.ContactDetails.path, { uid: customer?.uid ?? '' }),
              search: navBackToThisPage().toString(),
            }}
          >
            {nameContact(customer)}
          </NavLink>
        </dd>

        {invoice.order && (
          <>
            <dt className='font-medium mt-2 pt-2 border-t text-sm mb-1'>{t('related-order', 'Related order')}</dt>
            <dd>
              <NavLink
                className='text-blue-500 inline-flex items-center gap-1 mt-1'
                to={{
                  pathname: generatePath(AppRoutes.SemenOrderDetails.path, { uid: invoice.order.uid }),
                  search: navBackToThisPage().toString(),
                }}
              >
                <ShoppingCart /> {invoice.order.uid}
              </NavLink>
            </dd>
          </>
        )}

        {!invoice.order && (
          <>
            <dt className='font-medium mt-2 pt-2 border-t text-sm mb-1'>{t('related-horses', 'Related horses')}</dt>
            <dd>
              {relatedHorses.length === 0 && (
                <ul>
                  <li>-</li>
                </ul>
              )}
              <ul>
                {relatedHorses.map(horse => (
                  <li key={horse.uid}>
                    <NavLink
                      className='text-blue-500 inline-flex items-center gap-1 mt-1'
                      to={{
                        pathname: generatePath(AppRoutes.HorsesDetails.path, { uid: horse.uid }),
                        search: navBackToThisPage().toString(),
                      }}
                    >
                      <HorseIcon /> {horse?.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </dd>
          </>
        )}
        {createdBy && (
          <>
            <dt className='font-medium mt-2 pt-2 border-t text-sm mb-1'>{t('created-by', 'Created by')}</dt>
            <dd>
              {createdBy.first_name} {createdBy.last_name}
            </dd>
          </>
        )}
        <dt className='font-medium mt-2 pt-2 border-t text-sm mb-1'>{t('created-at', 'Created at')}</dt>
        <dd>{formatDate(new Date(invoice.created_on))}</dd>
        {invoice.finalized_on && (
          <>
            <div className='flex flex-row gap-2 mt-2 pt-2 border-t'>
              <div className='grow'>
                <dt className='font-medium text-sm mb-1'>{t('payments-status', 'Payment status')}</dt>
                <dd>
                  <Badge size={BadgeSize.Normal} color={PaymentStatusToColor(invoice.payment_status)}>
                    {PaymentStatusToString(t, invoice.payment_status)}
                  </Badge>
                </dd>
              </div>
              {invoice.payment_status !== PaymentStatusEnum.PAID && (
                <Button onClick={requestMarkAsPaid} variant={ButtonVariant.Default}>
                  {t('mark-as-paid', 'Mark as paid')}
                </Button>
              )}
            </div>
            <div className='flex flex-row gap-2 mt-2 pt-2 border-t'>
              <div className='grow'>
                <dt className='font-medium text-sm mb-1'>{t('payments-url', 'Payment url')}</dt>
                <dd>
                  <a className='text-blue-500 inline-flex items-center gap-1 mt-1' href={publicUrl} rel='noreferrer' target='_blank'>
                    {publicUrlTruncated}
                  </a>
                </dd>
              </div>
              <Button onClick={() => copyTextToClipboard(publicUrl)} variant={ButtonVariant.Default}>
                {t('copy', 'Copy')}
              </Button>
            </div>
          </>
        )}
        <dt className='font-medium mt-2 pt-2 border-t text-sm mb-1'>{t('sent-to-bookkeeping', 'Sent to bookkeeping software')}</dt>
        {bookkeeping.length > 0 ? (
          <dd className='flex flex-col items-start gap-2'>
            <span>{`${t('yes', 'Yes')} (${bookkeeping.join(', ')})`}</span>
            {invoice.exactnl_entry_no && (
              <div>
                <span>{`${t('exactnl-invoice-ref', 'Reference')}: `}</span>
                <a
                  target='_blank'
                  rel='noreferrer'
                  className='text-blue-500 inline-flex items-center gap-1 mt-1'
                  href={invoice.exactnl_url ?? ''}
                >
                  <span>{invoice.exactnl_entry_no}</span>
                  <ArrowSquareOut />
                </a>
              </div>
            )}
            <Button size={ButtonSize.Small} onClick={requestResendToBookkeeping}>
              {t('resend-to-bookkeeping', 'Resend to bookkeeping')}
            </Button>
          </dd>
        ) : (
          <dd>{t('no', 'No')}</dd>
        )}
      </dl>
    </>
  );
}
export default InvoiceMeta;

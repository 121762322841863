let hasFailed = false;

const optImportLocale = (lang: string) => {
  try {
    const dir = 'locales';
    const res = require(`../${dir}/${lang}/translation.json`);
    return res;
  } catch (e) {
    hasFailed = true;
    return {};
  }
};

const de = optImportLocale('de');
const en = optImportLocale('en');
const es = optImportLocale('es');
const fi = optImportLocale('fi');
const fr = optImportLocale('fr');
const it = optImportLocale('it');
const nl = optImportLocale('nl');

export { de, en, es, fi, fr, it, nl, hasFailed };

import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { VatCategoryEnum, VATPercentage, VatpercentagesService } from 'openapi';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { ButtonVariant } from '../../ui/Button';
import { CheckboxInput } from '../../ui/Inputs';
import { z } from 'zod';
import Fieldset from 'ui/Fieldset';

const SaveSpecialVatSchema = z.object({
  enableReverseChargeVat: z.boolean(),
  enableExportVat: z.boolean(),
});

type SaveSpecialVat = z.infer<typeof SaveSpecialVatSchema>;

interface Props {
  visible: boolean;
  onRequestClose: (reload: boolean) => void;
  onClosed?: () => void;
  vatPercentages: VATPercentage[];
}

// A modal to enable or disable VAT Export and VAT Reverse-charging.
export default function SaveSpecialVatPercentageModal({ visible, onRequestClose, onClosed, vatPercentages }: Props): JSX.Element {
  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();

  const existingReverseChargeVatPercentage = useMemo((): VATPercentage | undefined => {
    return vatPercentages.find(vp => vp.is_active && vp.vat_category === VatCategoryEnum.AE);
  }, [vatPercentages]);

  const existingExportVatPercentage = useMemo((): VATPercentage | undefined => {
    return vatPercentages.find(vp => vp.is_active && vp.vat_category === VatCategoryEnum.G);
  }, [vatPercentages]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<SaveSpecialVat>({
    resolver: zodResolver(SaveSpecialVatSchema),
    reValidateMode: 'onChange',
    defaultValues: {
      enableReverseChargeVat: existingReverseChargeVatPercentage !== undefined,
      enableExportVat: existingExportVatPercentage !== undefined,
    },
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(SaveSpecialVatSchema, errors);

  const closed = () => {
    setApiError(undefined);
    reset({
      enableReverseChargeVat: existingReverseChargeVatPercentage !== undefined,
      enableExportVat: existingExportVatPercentage !== undefined,
    });
    onClosed?.();
  };

  /**
   * Submit handler
   */
  const onSubmit = async (data: SaveSpecialVat) => {
    if (!selectedOrganization) {
      console.error('selectedOrganization is not defined');
      return;
    }

    const addRevCharge = !existingReverseChargeVatPercentage && data.enableReverseChargeVat;
    const addExport = !existingExportVatPercentage && data.enableExportVat;
    const rmRevCharge = existingReverseChargeVatPercentage && !data.enableReverseChargeVat;
    const rmExport = existingExportVatPercentage && !data.enableExportVat;
    if ((addRevCharge && rmRevCharge) || (addExport && rmExport)) {
      console.error('Encountered bug where add/rm rev-change or export are both set.');
      return;
    }

    try {
      if (addRevCharge) {
        await VatpercentagesService.vatpercentagesCreate({
          organisationUid: selectedOrganization.uid,
          requestBody: {
            percentage: '0',
            vat_category: VatCategoryEnum.AE, // Reverse-changing
          } as VATPercentage,
        });
      }
      if (addExport) {
        await VatpercentagesService.vatpercentagesCreate({
          organisationUid: selectedOrganization.uid,
          requestBody: {
            percentage: '0',
            vat_category: VatCategoryEnum.G, // Export
          } as VATPercentage,
        });
      }
      if (rmRevCharge) {
        await VatpercentagesService.vatpercentagesDestroy({
          organisationUid: selectedOrganization.uid,
          uid: existingReverseChargeVatPercentage.uid,
        });
      }
      if (rmExport) {
        await VatpercentagesService.vatpercentagesDestroy({
          organisationUid: selectedOrganization.uid,
          uid: existingExportVatPercentage.uid,
        });
      }
      onRequestClose(true);
    } catch (error) {
      setApiError(new ApiErrorParser<VATPercentage>(error));
    }
  };

  return (
    <>
      <PageModal open={visible} width={PageModalWidth.Xs} onClosed={closed}>
        <PageModalTitle title={t('edit-special-vat-percentage', 'Edit special VAT percentages')} onClose={() => onRequestClose(false)} />
        <PageModalContent>
          <ErrorSection errors={nonFieldErrors} />
          <form className='space-y-4 grow mb-4' noValidate={true} id='SaveSpecialVatPercentageForm' onSubmit={handleSubmit(onSubmit)}>
            <Fieldset legend={t('reverse-charge-vat', 'Reverse-charge VAT')} className='space-y-4 pt-0'>
              <CheckboxInput
                {...register('enableReverseChargeVat')}
                error={fieldError('enableReverseChargeVat')}
                label={t('enable-reverse-charge-checkbox', 'Enable reverse-charging VAT')}
              />
            </Fieldset>
            <Fieldset legend={t('export-vat', 'Export VAT')} className='space-y-4 pt-0'>
              <CheckboxInput
                {...register('enableExportVat')}
                error={fieldError('enableExportVat')}
                label={t('enable-export-checkbox', 'Enable export VAT')}
              />
            </Fieldset>
          </form>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              loading: isSubmitting,
              variant: ButtonVariant.Primary,
              text: t('save', 'Save'),
              type: 'submit',
              formId: 'SaveSpecialVatPercentageForm',
            },
          ]}
        />
      </PageModal>
    </>
  );
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from 'ui/Layout';
import Permissions from 'components/Permissions';
import { ModulePermissionsList, Role, RolesService } from 'openapi';
import { ApiPromises } from 'utilities/ApiPromises';
import { useOrganization } from 'context/OrganizationContext';
import { Tile } from 'ui/Layout/Tile';
import { Plus } from '@phosphor-icons/react';
import { ButtonVariant } from 'ui/Button';
import useModal from 'ui/Modals/UseModal';
import SaveRoleModal from 'components/Permissions/SaveRoleModal';
import { PageAction } from 'context/PageContext';
import PermissionsSkeleton from 'components/Permissions/PermissionsSkeleton';

export default function PermissionsPage(): JSX.Element {
  const [roles, setRoles] = useState<Role[]>();
  const [permissionList, setPermissionList] = useState<ModulePermissionsList[]>();
  const [availablePermissions, setAvailablePermissions] = useState<ModulePermissionsList[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const [showLast, setShowLast] = useState<boolean>(false);

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  const { closeModal, modalIsVisible, showModal } = useModal();

  const pageActions = useMemo((): PageAction[] => {
    return [
      {
        text: t('add-role', 'Add role'),
        icon: <Plus />,
        isMobileAddAction: true,
        buttonVariant: ButtonVariant.Primary,
        onClick: showModal,
      },
    ];
  }, [showModal, t]);

  // Load data from the api
  const loadApiData = useCallback(
    (reloadRolesAndPermissions?: boolean): ApiPromises => {
      const promises = new ApiPromises();
      if (!selectedOrganization) {
        return promises;
      }

      promises.appendList<Role>(
        'roles',
        () =>
          RolesService.rolesList({
            organisationUid: selectedOrganization.uid,
          }),
        setRoles,
      );

      promises.appendList<ModulePermissionsList>(
        'permissionList',
        () =>
          RolesService.rolesModulePermissionsList({
            organisationUid: selectedOrganization.uid,
          }),
        setPermissionList,
      );

      if (!reloadRolesAndPermissions) {
        promises.appendList<ModulePermissionsList>(
          'availablePermissions',
          () =>
            RolesService.rolesAllModulePermissionsList({
              organisationUid: selectedOrganization.uid,
            }),
          setAvailablePermissions,
        );
      }

      setApiPromises(promises);

      return promises;
    },
    [selectedOrganization],
  );

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // get only the active roles and filter out the admin role
  const activeRoles = (roles ?? []).filter(role => role.default_id !== 1).filter(role => role.hidden === false);

  return (
    <Page title={t('permissions', 'Permissions')} loading={apiPromises} actions={pageActions}>
      <Tile noBoxOnMobile={true}>
        {!roles && <PermissionsSkeleton />}
        {roles && (
          <Permissions
            availablePermissions={availablePermissions?.[0]}
            permissionList={permissionList}
            roles={activeRoles}
            showLast={showLast}
            onRoleSaved={role => {
              // update state before it reload the data from the api
              setRoles(prevState => (prevState ? [...prevState, role] : [role]));
              // reload from the api
              loadApiData(true);
            }}
            onRoleDeleted={role => {
              // update state before it reload the data from the api
              setRoles(prevState => (prevState ? prevState.filter(r => r.uid !== role.uid) : []));
              // reload from the api
              loadApiData(true);
            }}
            onPermissionSaved={() => loadApiData(true)}
          />
        )}
      </Tile>

      <SaveRoleModal
        onSaved={() => {
          setShowLast(true);
          loadApiData(true);
        }}
        isVisible={modalIsVisible}
        onRequestCloseModal={closeModal}
      />
    </Page>
  );
}

import { useOrganization } from 'context/OrganizationContext';
import { Contact, ContactsService, Role, RolesService, Stable, StablesService } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useModal from 'ui/Modals/UseModal';
import { AppRoutes } from 'AppRoutes';
import ContactDetails from 'components/Contacts/ContactDetails';
import Page, { PageMaxWidth } from 'ui/Layout/Page';
import { getFullName } from 'utilities/string.utility';
import { PageAction } from 'context/PageContext';
import UpdateAddressModal from 'components/Contacts/Modals/UpdateAddressModal';
import UpdateGeneralModal from 'components/Contacts/Modals/UpdateGeneralModal';
import UpdateBusinessModal from 'components/Contacts/Modals/UpdateBusinessModal';
import { Trash } from '@phosphor-icons/react';
import { ButtonVariant } from 'ui/Button';
import DeleteContactModal from 'components/Contacts/Modals/DeleteContactModal';
import UserPermissionDetails from 'components/Contacts/UserPermissionDetails';
import { ApiPromises } from 'utilities/ApiPromises';
import UpdatePermissionsModal from 'components/Contacts/Modals/UpdatePermissionsModal';

export default function UserDetailsPage(): JSX.Element {
  const [contact, setContact] = useState<Contact>();
  const [roles, setRoles] = useState<Role[]>();
  const [stables, setStables] = useState<Stable[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { selectedOrganizationUid, generateCacheKey } = useOrganization();
  const { uid } = useParams();
  const breadCrumbs = useMemo(() => [AppRoutes.AdminUsersList], []);
  const { modalIsVisible: addressModalIsVisible, closeModal: closeAddressModal, showModal: showAddressModal } = useModal();
  const { modalIsVisible: generalModalIsVisible, closeModal: closeGeneralModal, showModal: showGeneralModal } = useModal();
  const { modalIsVisible: businessModalIsVisible, closeModal: closeBusinessModal, showModal: showBusinessModal } = useModal();
  const { modalIsVisible: deleteModalIsVisible, closeModal: closeDeleteModal, showModal: showDeleteModal } = useModal();
  const { modalIsVisible: permissionsModalIsVisible, closeModal: closePermissionsModal, showModal: showPermissionsModal } = useModal();

  const navigate = useNavigate();

  // set the page title based on the contact info
  const pageTitle = useMemo((): string => {
    if (!contact) return '';

    // personal contact
    if (contact && contact.first_name && contact.last_name) {
      return getFullName(contact);
    }

    // business contact
    return contact.business_name ?? 'Details';
  }, [contact]);

  /**
   * Render the page actions
   */
  const pageActions = useMemo((): PageAction[] => {
    return [
      {
        icon: <Trash />,
        text: 'Delete',
        buttonVariant: ButtonVariant.Danger,
        onClick: showDeleteModal,
      },
    ];
  }, [showDeleteModal]);

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    if (!selectedOrganizationUid || !uid) {
      return promises;
    }

    promises.appendSingle('user', () => ContactsService.contactsRetrieve({ organisationUid: selectedOrganizationUid, uid }), setContact);

    promises.appendList<Role>(
      'roles',
      () =>
        RolesService.rolesList({
          organisationUid: selectedOrganizationUid,
        }),
      setRoles,
      generateCacheKey('roles'),
    );

    promises.appendList<Stable>(
      'stables',
      () =>
        StablesService.stablesList({
          organisationUid: selectedOrganizationUid,
        }),
      setStables,
      generateCacheKey('stables'),
    );

    setApiPromises(promises);
    return promises;
  }, [selectedOrganizationUid, generateCacheKey, uid]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganizationUid && uid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid, uid]); //eslint-disable-line

  return (
    <Page title={pageTitle} actions={pageActions} breadCrumbs={breadCrumbs} loading={apiPromises} maxWidth={PageMaxWidth.Tile}>
      {contact && (
        <>
          <ContactDetails
            contact={contact}
            onRequestUpdateAddress={showAddressModal}
            onRequestUpdateBusiness={showBusinessModal}
            onRequestUpdateGeneral={showGeneralModal}
          />
          <UserPermissionDetails stables={stables} contact={contact} roles={roles} onRequestUpdate={showPermissionsModal} />
          <UpdatePermissionsModal
            isVisible={permissionsModalIsVisible}
            onRequestCloseModal={closePermissionsModal}
            contact={contact}
            onUpdated={updatedContact => setContact(updatedContact)}
            stables={stables}
            roles={roles}
          />
          <UpdateAddressModal
            isVisible={addressModalIsVisible}
            onRequestCloseModal={closeAddressModal}
            contact={contact}
            onUpdated={updatedContact => setContact(updatedContact)}
          />
          <UpdateGeneralModal
            isVisible={generalModalIsVisible}
            onRequestCloseModal={closeGeneralModal}
            contact={contact}
            onUpdated={updatedContact => setContact(updatedContact)}
          />
          <UpdateBusinessModal
            isVisible={businessModalIsVisible}
            onRequestCloseModal={closeBusinessModal}
            contact={contact}
            onUpdated={updatedContact => setContact(updatedContact)}
          />
          <DeleteContactModal
            isVisible={deleteModalIsVisible}
            onRequestClose={closeDeleteModal}
            onDeleted={() => navigate(AppRoutes.AdminUsersList.path)}
            contact={contact}
          />
        </>
      )}
    </Page>
  );
}

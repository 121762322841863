import ApiErrorParser from 'api/ApiErrorParser';
import classNames from 'classnames';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import useRvoReportCount from 'hooks/UseRvoReportCount';
import {
  Horse,
  ReportedHorseRelocation,
  ReportedhorserelocationsService,
  ReportedHorseRelocationStatusEnum,
  ReportTypeEnum,
} from 'openapi';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'ui/Badge';
import Button, { ButtonSize, ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import DescriptionList from 'ui/Layout/DescriptionList';
import { DescriptionListMode } from 'ui/Layout/DescriptionList/DescriptionListItem';
import Spinner, { SpinnerSize } from 'ui/Loading/Spinner';
import { ActionModal } from 'ui/Modals';
import useModal from 'ui/Modals/UseModal';

interface Props {
  report: ReportedHorseRelocation;
  horse: Horse;
}

function Report({ report: givenReport, horse }: Props): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<ReportedHorseRelocation> | undefined>();
  const [report, setReport] = useState(givenReport);
  const [checkingStatus, setCheckingStatus] = useState(false);

  const { closeModal: closeDeleteModal, modalIsVisible: deleteModalIsVisible, showModal: showDeleteModal } = useModal();
  const { t } = useTranslation();
  const { formatDate } = useAccount();
  const { selectedOrganization } = useOrganization();
  const { loadReportCount } = useRvoReportCount();

  /**
   * * `1` - arrival
   * * `2` - departure
   * * `3` - import
   * * `4` - born
   * * `5` - dead
   * * `6` - born dead
   * * `7` - export
   */
  const reportTypeMap = {
    [ReportTypeEnum._1]: t('arrival', 'Arrival'),
    [ReportTypeEnum._2]: t('departure', 'Departure'),
    [ReportTypeEnum._3]: t('import', 'Import'),
    [ReportTypeEnum._4]: t('born', 'Born'),
    [ReportTypeEnum._5]: t('dead', 'Dead'),
    [ReportTypeEnum._6]: t('born-dead', 'Born dead'),
    [ReportTypeEnum._7]: t('export', 'Export'),
  };

  /**
   * * `IC` - inconsistent
   * * `DG` - final
   * * `VG` - provisional
   * * `IT` - withdrawn
   * * `ID` - submitted
   * * `HE` - recovered
   */
  const reportStatusMap = {
    [ReportedHorseRelocationStatusEnum.IC]: t('inconsistent', 'Inconsistent'),
    [ReportedHorseRelocationStatusEnum.DG]: t('final', 'Final'),
    [ReportedHorseRelocationStatusEnum.VG]: t('provisional', 'Provisional'),
    [ReportedHorseRelocationStatusEnum.IT]: t('withdrawn', 'Withdrawn'),
    [ReportedHorseRelocationStatusEnum.ID]: t('submitted', 'Submitted'),
    [ReportedHorseRelocationStatusEnum.HE]: t('recovered', 'Recovered'),
  };

  /**
   * Run a status check, the API will handle the status and update the report if needed
   */
  const checkStatus = useCallback(() => {
    setCheckingStatus(true);
    const promise = ReportedhorserelocationsService.reportedhorserelocationsCheckStatusCreate({
      horseOrganisationUid: selectedOrganization?.uid ?? '',
      uid: report.uid,
    });

    promise
      .then(response => setReport(response))
      .catch(e => {
        if (promise.isCancelled) return;
        setApiError(new ApiErrorParser<ReportedHorseRelocation>(e));
        setCheckingStatus(false);
      })
      .finally(() => {
        if (promise.isCancelled) return;
        setCheckingStatus(false);
      });

    return promise;
  }, [report.uid, selectedOrganization?.uid]);

  /**
   * Send a request to the API to delete the report
   */
  const deleteReport = useCallback(async () => {
    const promise = ReportedhorserelocationsService.reportedhorserelocationsWithdrawReportCreate({
      horseOrganisationUid: selectedOrganization?.uid ?? '',
      uid: report.uid,
    });

    try {
      await promise;
      closeDeleteModal();
      // check the status again
      checkStatus();
      // refetch the mutation endpoint to update the count
      loadReportCount();
    } catch (e) {
      setApiError(new ApiErrorParser<ReportedHorseRelocation>(e));
    }
  }, [checkStatus, closeDeleteModal, loadReportCount, report.uid, selectedOrganization?.uid]);

  /**
   * When the modal is visible, we should check the status with the API
   */
  useEffect(() => {
    const promise = checkStatus();
    return () => promise.cancel();
  }, [checkStatus]);

  /**
   * Update the report when the given report changes
   */
  useEffect(() => {
    setReport(givenReport);
  }, [givenReport]);

  return (
    <>
      <div>
        {apiError && <ErrorSection errors={apiError} />}
        <header className='flex bg-gray-50 p-2 items-center'>
          <h3 className='flex items-center gap-x-2 w-full text-lg font-bold'>
            {report.meldingnummer} {report.status && <Badge>{reportStatusMap[report.status]}</Badge>}
          </h3>
          {!checkingStatus && ReportedHorseRelocationStatusEnum.IT !== report.status && (
            <Button onClick={showDeleteModal} className='ml-auto' variant={ButtonVariant.Danger} size={ButtonSize.Small}>
              {t('withdraw-report', 'Withdraw report')}
            </Button>
          )}
        </header>
        <div className='px-2'>
          <DescriptionList
            mode={DescriptionListMode.Compact}
            list={[
              {
                term: t('report-type', 'Report type'),
                definition: reportTypeMap[report.report_type] ?? '-',
              },
              {
                term: t('horse', 'Horse'),
                definition: horse?.name ?? '-',
              },
              {
                term: t('status', 'Status'),
                definition: (
                  <div className='flex gap-x-2 items-center'>
                    <span
                      className={classNames({
                        italic: checkingStatus,
                      })}
                    >
                      {report.status ? reportStatusMap[report.status] ?? '-' : '-'}
                    </span>
                    {checkingStatus && <Spinner size={SpinnerSize.XSmall} />}
                  </div>
                ),
              },
              {
                term: t('status-desc', 'Status description'),
                definition: report.statusOms || '-',
              },
              {
                term: t('date', 'Date'),
                definition: formatDate(report.date),
              },
              {
                term: t('report-number', 'Report number'),
                definition: report.meldingnummer ?? '-',
              },
            ]}
          />
        </div>
      </div>

      <ActionModal
        open={deleteModalIsVisible}
        title={t('withdraw-rvo-report', 'Withdraw RVO report')}
        actions={[
          { text: t('close', 'Close'), variant: ButtonVariant.Default, onClick: closeDeleteModal },
          { text: t('withdraw', 'Withdraw'), variant: ButtonVariant.Danger, onClick: deleteReport },
        ]}
      >
        {apiError && <ErrorSection errors={apiError} />}

        <p className='mt-2'>{t('withdraw-rvo-authorization-message', 'Are you sure you want to withdraw this report?')}</p>
      </ActionModal>
    </>
  );
}

export default Report;

import classNames from 'classnames';
import React from 'react';

interface Props {
  className: string;
  rounded?: boolean;
  animated?: boolean;
  style?: React.CSSProperties;
}

/**
 * Basic skeleton component
 *
 * In order to use this, you need to make sure you add a w-[] and a h-[] class.
 *
 * <Skeleton className='h-4 w-36' />
 *
 * For a round/circle you can use
 *
 * <Skeleton rounded={true} className='h-32 w-32' />
 */
export default function Skeleton({ className, rounded, animated = true, style }: Props): JSX.Element {
  return (
    <div
      style={style}
      className={classNames('bg-gray-300', className, {
        'animate-skeleton': animated,
        'rounded-sm': !rounded,
        'rounded-full': rounded,
      })}
    />
  );
}

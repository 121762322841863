import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from 'ui/Layout';
import { HorseGroup, HorsegroupsService, VaccinationRule, VaccinationrulesService } from 'openapi';
import useApiPromises from 'api/hooks/useApiPromises';
import { ApiPromises } from 'utilities/ApiPromises';
import { useOrganization } from 'context/OrganizationContext';
import { PageMaxWidth } from 'ui/Layout/Page';
import HorseGroupTile from 'components/Horses/GroupTile';
import VaccinationRulesTile from 'components/Horses/VaccinationRulesTile';

export default function HorseSettings(): JSX.Element {
  const [groups, setGroups] = useState<HorseGroup[]>();
  const [vaccinationRules, setVaccinationRules] = useState<VaccinationRule[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  useApiPromises({ apiPromises });
  const { t } = useTranslation();
  const { selectedOrganizationUid, generateCacheKey } = useOrganization();

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    if (!selectedOrganizationUid) {
      return promises;
    }

    promises.appendList<HorseGroup>(
      'horse-groups',
      () =>
        HorsegroupsService.horsegroupsList({
          organisationUid: selectedOrganizationUid,
        }),
      setGroups,
      generateCacheKey('horse-groups'),
    );

    promises.appendList<VaccinationRule>(
      'vaccination-rules',
      () =>
        VaccinationrulesService.vaccinationrulesList({
          organisationUid: selectedOrganizationUid,
        }),
      setVaccinationRules,
      generateCacheKey('vacination-rules'),
    );

    setApiPromises(promises);
    return promises;
  }, [selectedOrganizationUid, generateCacheKey]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  return (
    <Page title={t('horse-settings', 'Horse settings')} maxWidth={PageMaxWidth.Tile} loading={apiPromises}>
      <div className='space-y-10'>
        <HorseGroupTile
          groups={groups}
          onSaved={savedGroup => {
            setGroups(prevState => {
              if (!prevState) return prevState;
              const index = prevState.findIndex(group => group.uid === savedGroup.uid);

              // new group, insert it
              if (index === -1) {
                return [...prevState, savedGroup];
              }

              // otherwise update the existing group
              prevState[index] = savedGroup;
              return [...prevState];
            });
          }}
          onDeleted={loadApiData}
        />

        <VaccinationRulesTile
          vaccinatinoRules={vaccinationRules}
          onDeleted={loadApiData}
          onSaved={savedVaccinationRule => {
            setVaccinationRules(prevState => {
              if (!prevState) return prevState;
              const index = prevState.findIndex(rule => rule.uid === savedVaccinationRule.uid);

              // new group, insert it
              if (index === -1) {
                return [savedVaccinationRule, ...prevState];
              }

              // otherwise update the existing group
              prevState[index] = savedVaccinationRule;
              return [...prevState];
            });
          }}
        />
      </div>
    </Page>
  );
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderItemStallionMountPicker } from '../models/OrderItemStallionMountPicker';
import type { PaginatedPurchaserOrderList } from '../models/PaginatedPurchaserOrderList';
import type { PaginatedSupplierOrderList } from '../models/PaginatedSupplierOrderList';
import type { PatchedPurchaserOrderUpdate } from '../models/PatchedPurchaserOrderUpdate';
import type { PatchedSupplierOrderDetail } from '../models/PatchedSupplierOrderDetail';
import type { PurchaserOrderDetail } from '../models/PurchaserOrderDetail';
import type { PurchaserOrderUpdate } from '../models/PurchaserOrderUpdate';
import type { SupplierOrderDetail } from '../models/SupplierOrderDetail';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OrdersService {
  /**
   * Creates the relation linking this semen order line and the stallion mount that was picked after validating:
   * - the stallion_mounts can be picked by this user
   * - the total requested amount from the corresponding mount is available
   * - the total requested amount corresponds to the required amount of this order_item
   *
   * You should pick the entire order_item in one request. You can supply multiple stallion_mount and amount
   * tuples, but the tuple should be unique.
   * @returns OrderItemStallionMountPicker
   * @throws ApiError
   */
  public static ordersSuppliedSemenorderitemsPickstallionmountsCreate({
    orderSupplierUid,
    orderUid,
    uid,
    requestBody,
  }: {
    orderSupplierUid: string,
    orderUid: string,
    uid: string,
    requestBody: OrderItemStallionMountPicker,
  }): CancelablePromise<OrderItemStallionMountPicker> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{order__supplier__uid}/orders/supplied/{order__uid}/semenorderitems/{uid}/pickstallionmounts',
      path: {
        'order__supplier__uid': orderSupplierUid,
        'order__uid': orderUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Unpick a previously picked Oderitem, in case the wrong stallion mount was picked
   * @returns void
   * @throws ApiError
   */
  public static ordersSuppliedSemenorderitemsUnpickstallionmountsDestroy({
    orderSupplierUid,
    orderUid,
    uid,
  }: {
    orderSupplierUid: string,
    orderUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{order__supplier__uid}/orders/supplied/{order__uid}/semenorderitems/{uid}/unpickstallionmounts',
      path: {
        'order__supplier__uid': orderSupplierUid,
        'order__uid': orderUid,
        'uid': uid,
      },
    });
  }
  /**
   * Orders endpoint intended for horse breeders or anyone that wants to POST an order directly at a stallion keeper.
   * The organisation in the path corresponds to the purchaser's organisation (typically the HB). The stallion keeper's
   * organisation is reflected in the 'supplier' field and show's the organisation's public_access_uuid.
   * (See `supplied-orders` for order management at the stallion keeper.)
   *
   * For the Customer and Horse fields two instances are made: one for the supplier and one for the purchaser.
   * The former are linked to the order, yet this endpoint will expect and provide the purchaser's variants
   * (both uid and nested objects).
   *
   * The PATCH endpoint allows the horse breeder to set or change the purchaser organisation (to one of his own). All
   * other fields are ignored.
   * @returns PaginatedPurchaserOrderList
   * @throws ApiError
   */
  public static ordersPurchasedList({
    purchaserUid,
    o,
    orderNumber,
    orderNumberIn,
    page,
    pageSize,
    paymentStatus,
    paymentStatusIn,
    shippingDate,
    shippingDateGt,
    shippingDateGte,
    shippingDateLt,
    shippingDateLte,
    supplierPublicAccessUuid,
    supplierPublicAccessUuidIn,
    uid,
    uidIn,
  }: {
    purchaserUid: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    orderNumber?: string,
    /**
     * Multiple values may be separated by commas.
     */
    orderNumberIn?: Array<string>,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    /**
     * * `OPEN` - Open
     * * `PROCESSING` - Processing
     * * `PAID` - Paid
     */
    paymentStatus?: 'OPEN' | 'PAID' | 'PROCESSING',
    /**
     * Multiple values may be separated by commas.
     */
    paymentStatusIn?: Array<string>,
    shippingDate?: string,
    shippingDateGt?: string,
    shippingDateGte?: string,
    shippingDateLt?: string,
    shippingDateLte?: string,
    supplierPublicAccessUuid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    supplierPublicAccessUuidIn?: Array<string>,
    uid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    uidIn?: Array<string>,
  }): CancelablePromise<PaginatedPurchaserOrderList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{purchaser__uid}/orders/purchased',
      path: {
        'purchaser__uid': purchaserUid,
      },
      query: {
        'o': o,
        'order_number': orderNumber,
        'order_number__in': orderNumberIn,
        'page': page,
        'page_size': pageSize,
        'payment_status': paymentStatus,
        'payment_status__in': paymentStatusIn,
        'shipping_date': shippingDate,
        'shipping_date__gt': shippingDateGt,
        'shipping_date__gte': shippingDateGte,
        'shipping_date__lt': shippingDateLt,
        'shipping_date__lte': shippingDateLte,
        'supplier__public_access_uuid': supplierPublicAccessUuid,
        'supplier__public_access_uuid__in': supplierPublicAccessUuidIn,
        'uid': uid,
        'uid__in': uidIn,
      },
    });
  }
  /**
   * Orders endpoint intended for horse breeders or anyone that wants to POST an order directly at a stallion keeper.
   * The organisation in the path corresponds to the purchaser's organisation (typically the HB). The stallion keeper's
   * organisation is reflected in the 'supplier' field and show's the organisation's public_access_uuid.
   * (See `supplied-orders` for order management at the stallion keeper.)
   *
   * For the Customer and Horse fields two instances are made: one for the supplier and one for the purchaser.
   * The former are linked to the order, yet this endpoint will expect and provide the purchaser's variants
   * (both uid and nested objects).
   *
   * The PATCH endpoint allows the horse breeder to set or change the purchaser organisation (to one of his own). All
   * other fields are ignored.
   * @returns PurchaserOrderDetail
   * @throws ApiError
   */
  public static ordersPurchasedCreate({
    purchaserUid,
    requestBody,
  }: {
    purchaserUid: string,
    requestBody: PurchaserOrderDetail,
  }): CancelablePromise<PurchaserOrderDetail> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{purchaser__uid}/orders/purchased',
      path: {
        'purchaser__uid': purchaserUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Orders endpoint intended for horse breeders or anyone that wants to POST an order directly at a stallion keeper.
   * The organisation in the path corresponds to the purchaser's organisation (typically the HB). The stallion keeper's
   * organisation is reflected in the 'supplier' field and show's the organisation's public_access_uuid.
   * (See `supplied-orders` for order management at the stallion keeper.)
   *
   * For the Customer and Horse fields two instances are made: one for the supplier and one for the purchaser.
   * The former are linked to the order, yet this endpoint will expect and provide the purchaser's variants
   * (both uid and nested objects).
   *
   * The PATCH endpoint allows the horse breeder to set or change the purchaser organisation (to one of his own). All
   * other fields are ignored.
   * @returns PurchaserOrderDetail
   * @throws ApiError
   */
  public static ordersPurchasedRetrieve({
    purchaserUid,
    uid,
  }: {
    purchaserUid: string,
    uid: string,
  }): CancelablePromise<PurchaserOrderDetail> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{purchaser__uid}/orders/purchased/{uid}',
      path: {
        'purchaser__uid': purchaserUid,
        'uid': uid,
      },
    });
  }
  /**
   * Orders endpoint intended for horse breeders or anyone that wants to POST an order directly at a stallion keeper.
   * The organisation in the path corresponds to the purchaser's organisation (typically the HB). The stallion keeper's
   * organisation is reflected in the 'supplier' field and show's the organisation's public_access_uuid.
   * (See `supplied-orders` for order management at the stallion keeper.)
   *
   * For the Customer and Horse fields two instances are made: one for the supplier and one for the purchaser.
   * The former are linked to the order, yet this endpoint will expect and provide the purchaser's variants
   * (both uid and nested objects).
   *
   * The PATCH endpoint allows the horse breeder to set or change the purchaser organisation (to one of his own). All
   * other fields are ignored.
   * @returns PurchaserOrderUpdate
   * @throws ApiError
   */
  public static ordersPurchasedUpdate({
    purchaserUid,
    uid,
    requestBody,
  }: {
    purchaserUid: string,
    uid: string,
    requestBody: PurchaserOrderUpdate,
  }): CancelablePromise<PurchaserOrderUpdate> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{purchaser__uid}/orders/purchased/{uid}',
      path: {
        'purchaser__uid': purchaserUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Orders endpoint intended for horse breeders or anyone that wants to POST an order directly at a stallion keeper.
   * The organisation in the path corresponds to the purchaser's organisation (typically the HB). The stallion keeper's
   * organisation is reflected in the 'supplier' field and show's the organisation's public_access_uuid.
   * (See `supplied-orders` for order management at the stallion keeper.)
   *
   * For the Customer and Horse fields two instances are made: one for the supplier and one for the purchaser.
   * The former are linked to the order, yet this endpoint will expect and provide the purchaser's variants
   * (both uid and nested objects).
   *
   * The PATCH endpoint allows the horse breeder to set or change the purchaser organisation (to one of his own). All
   * other fields are ignored.
   * @returns PurchaserOrderUpdate
   * @throws ApiError
   */
  public static ordersPurchasedPartialUpdate({
    purchaserUid,
    uid,
    requestBody,
  }: {
    purchaserUid: string,
    uid: string,
    requestBody?: PatchedPurchaserOrderUpdate,
  }): CancelablePromise<PurchaserOrderUpdate> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{purchaser__uid}/orders/purchased/{uid}',
      path: {
        'purchaser__uid': purchaserUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Orders endpoint intended for stallion keepers: for entering orders made over different channels (phone email)
   * from customers that want to order horse semen (typically horse breeders).
   * (See `purchased-orders` for order management at the horse breeder.)
   *
   * Available orders are looked up through the order's customer's organization.
   *
   * When POSTing an Order:
   * - You should supply a contact uid for the customer (requester and receiver are optional). These contact's
   * organizations should be available to the requesting user (typically they are the same contact). When the
   * receiver is omitted backend will assume that the receiver contact is the same as the customer contact.
   * - You can (and should) supply embedded order_items.
   * The order item's stallion_mount picking can be done over
   * `/api/v5/organisations/{order__customer__organisation__uid}/suppliedorders/{order__uid}/orderitems/{uid}/pickstallionmounts`
   * once the order is made.
   * - You don't need to care about read-only fields.
   * @returns PaginatedSupplierOrderList
   * @throws ApiError
   */
  public static ordersSuppliedList({
    supplierUid,
    createdOnAfter,
    createdOnBefore,
    customerUid,
    customerUidIn,
    isPicked,
    o,
    orderNumber,
    orderNumberIn,
    page,
    pageSize,
    paymentStatus,
    paymentStatusIn,
    receiverUid,
    receiverUidIn,
    requesterUid,
    requesterUidIn,
    shippingDate,
    shippingDateGt,
    shippingDateGte,
    shippingDateLt,
    shippingDateLte,
    uid,
    uidIn,
  }: {
    supplierUid: string,
    createdOnAfter?: string,
    createdOnBefore?: string,
    customerUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    customerUidIn?: Array<string>,
    /**
     * The order is picked when it has no pickable (default or breeding type) order items left without a 'picked_on' value.
     */
    isPicked?: boolean,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    orderNumber?: string,
    /**
     * Multiple values may be separated by commas.
     */
    orderNumberIn?: Array<string>,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    /**
     * * `OPEN` - Open
     * * `PROCESSING` - Processing
     * * `PAID` - Paid
     */
    paymentStatus?: 'OPEN' | 'PAID' | 'PROCESSING',
    /**
     * Multiple values may be separated by commas.
     */
    paymentStatusIn?: Array<string>,
    receiverUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    receiverUidIn?: Array<string>,
    requesterUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    requesterUidIn?: Array<string>,
    shippingDate?: string,
    shippingDateGt?: string,
    shippingDateGte?: string,
    shippingDateLt?: string,
    shippingDateLte?: string,
    uid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    uidIn?: Array<string>,
  }): CancelablePromise<PaginatedSupplierOrderList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{supplier__uid}/orders/supplied',
      path: {
        'supplier__uid': supplierUid,
      },
      query: {
        'created_on_after': createdOnAfter,
        'created_on_before': createdOnBefore,
        'customer__uid': customerUid,
        'customer__uid__in': customerUidIn,
        'is_picked': isPicked,
        'o': o,
        'order_number': orderNumber,
        'order_number__in': orderNumberIn,
        'page': page,
        'page_size': pageSize,
        'payment_status': paymentStatus,
        'payment_status__in': paymentStatusIn,
        'receiver__uid': receiverUid,
        'receiver__uid__in': receiverUidIn,
        'requester__uid': requesterUid,
        'requester__uid__in': requesterUidIn,
        'shipping_date': shippingDate,
        'shipping_date__gt': shippingDateGt,
        'shipping_date__gte': shippingDateGte,
        'shipping_date__lt': shippingDateLt,
        'shipping_date__lte': shippingDateLte,
        'uid': uid,
        'uid__in': uidIn,
      },
    });
  }
  /**
   * Orders endpoint intended for stallion keepers: for entering orders made over different channels (phone email)
   * from customers that want to order horse semen (typically horse breeders).
   * (See `purchased-orders` for order management at the horse breeder.)
   *
   * Available orders are looked up through the order's customer's organization.
   *
   * When POSTing an Order:
   * - You should supply a contact uid for the customer (requester and receiver are optional). These contact's
   * organizations should be available to the requesting user (typically they are the same contact). When the
   * receiver is omitted backend will assume that the receiver contact is the same as the customer contact.
   * - You can (and should) supply embedded order_items.
   * The order item's stallion_mount picking can be done over
   * `/api/v5/organisations/{order__customer__organisation__uid}/suppliedorders/{order__uid}/orderitems/{uid}/pickstallionmounts`
   * once the order is made.
   * - You don't need to care about read-only fields.
   * @returns SupplierOrderDetail
   * @throws ApiError
   */
  public static ordersSuppliedCreate({
    supplierUid,
    requestBody,
  }: {
    supplierUid: string,
    requestBody: SupplierOrderDetail,
  }): CancelablePromise<SupplierOrderDetail> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{supplier__uid}/orders/supplied',
      path: {
        'supplier__uid': supplierUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Orders endpoint intended for stallion keepers: for entering orders made over different channels (phone email)
   * from customers that want to order horse semen (typically horse breeders).
   * (See `purchased-orders` for order management at the horse breeder.)
   *
   * Available orders are looked up through the order's customer's organization.
   *
   * When POSTing an Order:
   * - You should supply a contact uid for the customer (requester and receiver are optional). These contact's
   * organizations should be available to the requesting user (typically they are the same contact). When the
   * receiver is omitted backend will assume that the receiver contact is the same as the customer contact.
   * - You can (and should) supply embedded order_items.
   * The order item's stallion_mount picking can be done over
   * `/api/v5/organisations/{order__customer__organisation__uid}/suppliedorders/{order__uid}/orderitems/{uid}/pickstallionmounts`
   * once the order is made.
   * - You don't need to care about read-only fields.
   * @returns SupplierOrderDetail
   * @throws ApiError
   */
  public static ordersSuppliedRetrieve({
    supplierUid,
    uid,
  }: {
    supplierUid: string,
    uid: string,
  }): CancelablePromise<SupplierOrderDetail> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{supplier__uid}/orders/supplied/{uid}',
      path: {
        'supplier__uid': supplierUid,
        'uid': uid,
      },
    });
  }
  /**
   * Orders endpoint intended for stallion keepers: for entering orders made over different channels (phone email)
   * from customers that want to order horse semen (typically horse breeders).
   * (See `purchased-orders` for order management at the horse breeder.)
   *
   * Available orders are looked up through the order's customer's organization.
   *
   * When POSTing an Order:
   * - You should supply a contact uid for the customer (requester and receiver are optional). These contact's
   * organizations should be available to the requesting user (typically they are the same contact). When the
   * receiver is omitted backend will assume that the receiver contact is the same as the customer contact.
   * - You can (and should) supply embedded order_items.
   * The order item's stallion_mount picking can be done over
   * `/api/v5/organisations/{order__customer__organisation__uid}/suppliedorders/{order__uid}/orderitems/{uid}/pickstallionmounts`
   * once the order is made.
   * - You don't need to care about read-only fields.
   * @returns SupplierOrderDetail
   * @throws ApiError
   */
  public static ordersSuppliedUpdate({
    supplierUid,
    uid,
    requestBody,
  }: {
    supplierUid: string,
    uid: string,
    requestBody: SupplierOrderDetail,
  }): CancelablePromise<SupplierOrderDetail> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{supplier__uid}/orders/supplied/{uid}',
      path: {
        'supplier__uid': supplierUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Orders endpoint intended for stallion keepers: for entering orders made over different channels (phone email)
   * from customers that want to order horse semen (typically horse breeders).
   * (See `purchased-orders` for order management at the horse breeder.)
   *
   * Available orders are looked up through the order's customer's organization.
   *
   * When POSTing an Order:
   * - You should supply a contact uid for the customer (requester and receiver are optional). These contact's
   * organizations should be available to the requesting user (typically they are the same contact). When the
   * receiver is omitted backend will assume that the receiver contact is the same as the customer contact.
   * - You can (and should) supply embedded order_items.
   * The order item's stallion_mount picking can be done over
   * `/api/v5/organisations/{order__customer__organisation__uid}/suppliedorders/{order__uid}/orderitems/{uid}/pickstallionmounts`
   * once the order is made.
   * - You don't need to care about read-only fields.
   * @returns SupplierOrderDetail
   * @throws ApiError
   */
  public static ordersSuppliedPartialUpdate({
    supplierUid,
    uid,
    requestBody,
  }: {
    supplierUid: string,
    uid: string,
    requestBody?: PatchedSupplierOrderDetail,
  }): CancelablePromise<SupplierOrderDetail> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{supplier__uid}/orders/supplied/{uid}',
      path: {
        'supplier__uid': supplierUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Orders endpoint intended for stallion keepers: for entering orders made over different channels (phone email)
   * from customers that want to order horse semen (typically horse breeders).
   * (See `purchased-orders` for order management at the horse breeder.)
   *
   * Available orders are looked up through the order's customer's organization.
   *
   * When POSTing an Order:
   * - You should supply a contact uid for the customer (requester and receiver are optional). These contact's
   * organizations should be available to the requesting user (typically they are the same contact). When the
   * receiver is omitted backend will assume that the receiver contact is the same as the customer contact.
   * - You can (and should) supply embedded order_items.
   * The order item's stallion_mount picking can be done over
   * `/api/v5/organisations/{order__customer__organisation__uid}/suppliedorders/{order__uid}/orderitems/{uid}/pickstallionmounts`
   * once the order is made.
   * - You don't need to care about read-only fields.
   * @returns void
   * @throws ApiError
   */
  public static ordersSuppliedDestroy({
    supplierUid,
    uid,
  }: {
    supplierUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{supplier__uid}/orders/supplied/{uid}',
      path: {
        'supplier__uid': supplierUid,
        'uid': uid,
      },
    });
  }
  /**
   * The order shows the version of the *customer* at the time the order was made.
   * By using this action that version will be updated to the most recent.
   *
   * Note: Everything added to the body is ignored.
   * @returns SupplierOrderDetail
   * @throws ApiError
   */
  public static ordersSuppliedUpdateCustomerContactPartialUpdate({
    supplierUid,
    uid,
    requestBody,
  }: {
    supplierUid: string,
    uid: string,
    requestBody?: PatchedSupplierOrderDetail,
  }): CancelablePromise<SupplierOrderDetail> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{supplier__uid}/orders/supplied/{uid}/update_customer_contact',
      path: {
        'supplier__uid': supplierUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * The order shows the version of the *receiver* at the time the order was made.
   * By using this action that version will be updated to the most recent.
   *
   * Note: Everything added to the body is ignored.
   * @returns SupplierOrderDetail
   * @throws ApiError
   */
  public static ordersSuppliedUpdateReceiverContactPartialUpdate({
    supplierUid,
    uid,
    requestBody,
  }: {
    supplierUid: string,
    uid: string,
    requestBody?: PatchedSupplierOrderDetail,
  }): CancelablePromise<SupplierOrderDetail> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{supplier__uid}/orders/supplied/{uid}/update_receiver_contact',
      path: {
        'supplier__uid': supplierUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}

import { zodResolver } from '@hookform/resolvers/zod';
import { Pencil } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useAccount } from 'context/AccountContext';
import { Account, AccountService, PatchedAccount } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { DateInput, SelectInput, TextInput } from 'ui/Inputs';
import { Tile } from 'ui/Layout/Tile';
import TileDescriptionList from 'ui/Layout/Tile/TileDescriptionList';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { objectDiff } from 'utilities/compareObject';
import { formatDate } from 'utilities/date.utilities';
import { Title, titleToString } from 'utilities/string.utility';
import { transformEmpty } from 'utilities/zod';

export default function PersonalInformationForm(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const { accountDetails, setAccountDetails } = useAccount();

  const schema = useMemo(() => {
    return schemas.Account.omit({
      color: true,
      not_searchable: true,
      preferred_organisation: true,
      preferred_horse_group: true,
      language: true,
      last_activity: true,
      disable_help_popup_portal: true,
      disable_help_popup_app: true,
      avatar_file: true,
      date_joined: true,
      username: true,
      agreed_to_terms_and_privacy: true,
      email: true,
      lookup: true,
      uid: true,
      address_line1: true,
      address_line2: true,
      address_line3: true,
      city: true,
      state: true,
      postcode: true,
      country: true,
    });
  }, []);

  const { showModal, closeModal, modalIsVisible } = useModal();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    control,
  } = useForm<Account>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: accountDetails,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Close event for the modal
   */
  const onClose = (resetForm = true) => {
    if (resetForm) {
      reset(accountDetails);
    }
    // clear the errors
    clearErrors();
    setApiError(undefined);
    closeModal();
  };

  /**
   * Submit event handler, update the data via the API for this user
   */
  const onSubmit: SubmitHandler<PatchedAccount> = async (data: PatchedAccount) => {
    if (!accountDetails) return;

    if (data?.date_of_birth) {
      data.date_of_birth = formatDate(new Date(data?.date_of_birth));
    }

    // generate a diff of the form data and the profile
    // so we can send only the changed data
    const updatedFields = objectDiff<Account>(accountDetails, data as Account);

    setLoading(true);

    try {
      setAccountDetails(await AccountService.apiV5AccountPartialUpdate({ requestBody: updatedFields }));
      onClose(false); // close the modal
    } catch (error) {
      setApiError(new ApiErrorParser<PatchedAccount>(error));
    }

    setLoading(false);
  };

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo(() => {
    return [
      {
        onClick: showModal,
        text: t('edit', 'Edit'),
        buttonVariant: ButtonVariant.Default,
        icon: <Pencil />,
      },
    ];
  }, [showModal, t]);

  return (
    <Tile title={t('personal', 'Personal')} loading={!accountDetails} actions={tileActions}>
      <TileDescriptionList
        list={[
          { term: t('person-title', 'Title'), definition: accountDetails?.title || '-' },
          { term: t('initials', 'Initials'), definition: accountDetails?.initials || '-' },
          { term: t('first-name', 'First name'), definition: accountDetails?.first_name || '-' },
          { term: t('last-name', 'Last name'), definition: accountDetails?.last_name || '-' },
          {
            term: t('date-of-birth', 'Date of birth'),
            definition: accountDetails?.date_of_birth ? formatDate(new Date(accountDetails?.date_of_birth)) : '-',
          },
          { term: t('phone-number', 'Phone number'), definition: accountDetails?.phone_number || '-' },
        ]}
      />

      <PageModal
        open={modalIsVisible}
        parentElement='form'
        parentProps={{ id: 'updateAccount', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={t('update-profile', 'Update profile')} onClose={onClose} />
        <PageModalContent>
          <ErrorSection errors={nonFieldErrors} />
          <div className='space-y-4'>
            <TextInput error={fieldError('first_name')} required={true} label={t('first-name', 'First name')} {...register('first_name')} />
            <TextInput error={fieldError('last_name')} required={true} label={t('last-name', 'Last name')} {...register('last_name')} />
            <TextInput
              hint={t('initials.hint', 'Max three initials')}
              error={fieldError('initials')}
              type={'text'}
              required={false}
              label={t('initials', 'Initials')}
              {...register('initials')}
            />
            <SelectInput
              error={fieldError('title')}
              options={Object.keys(Title).map(key => ({ id: key, name: titleToString(key as Title, t) }))}
              nullable={true}
              nullableValue=''
              label={t('person-title', 'Title')}
              {...register('title', { setValueAs: transformEmpty(undefined) })}
            />

            <DateInput
              control={control}
              error={fieldError('date_of_birth')}
              required={false}
              label={t('date-of-birth', 'Date of birth')}
              name='date_of_birth'
            />

            <TextInput
              required={false}
              error={fieldError('phone_number')}
              type={'tel'}
              label={t('phone-number', 'Phone number')}
              {...register('phone_number')}
            />
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              onClick: onClose,
              variant: ButtonVariant.Default,
              type: 'button',
              text: t('cancel', 'Cancel'),
            },
            {
              formId: 'updateAccount',
              loading: loading,
              variant: ButtonVariant.Primary,
              type: 'submit',
              text: t('save', 'Save'),
            },
          ]}
        />
      </PageModal>
    </Tile>
  );
}

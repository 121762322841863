import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { InvitationsService, ReceivedInvitation } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  onSuccess: () => void;
  invitation: ReceivedInvitation | undefined;
}

function AcceptInvitationModal({ isVisible, onRequestClose, invitation, onSuccess }: Props): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<ReceivedInvitation> | undefined>();

  const { t } = useTranslation();
  const { refresh } = useOrganization();

  const acceptInvitation = async () => {
    if (!invitation) return console.warn('No invitation found');
    try {
      const promise = InvitationsService.apiV5InvitationsAcceptPartialUpdate({
        uuid: invitation.uuid,
      });
      await promise;

      // we also need to reload the organizations to show the correct list
      refresh();

      // close the modal
      onRequestClose();
      // fire the onSuccess callback
      onSuccess();
      // clear the error
      setApiError(undefined);
    } catch (error) {
      setApiError(new ApiErrorParser<ReceivedInvitation>(error));
    }
  };

  return (
    <ActionModal
      open={isVisible}
      onClose={onRequestClose}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: onRequestClose,
        },
        {
          text: t('accept', 'Accept'),
          variant: ButtonVariant.Primary,
          onClick: acceptInvitation,
        },
      ]}
      title={t('accept-invitation', 'Accept invitation')}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2  w-full'>{t('accept-invitation-desc', 'Are you sure you want to accept this invitation?')}</p>
    </ActionModal>
  );
}

export default AcceptInvitationModal;

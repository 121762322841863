import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import useWebshop from 'api/hooks/useWebshop';

/**
 * Html meta header fields to use in the Equinem App
 */
export function EquinemMetaHeader(): JSX.Element {
  return (
    <Helmet>
      <meta name='theme-color' content='#00243F' />
      <link rel='manifest' href='/manifest.json' />
      <link rel='icon' href='/icons/icon-120.png' />
      <link rel='shortcut icon' sizes='196x196' href='/icons/icon-196.png' />
      <link rel='shortcut icon' sizes='120x120' href='/icons/icon-120.png' />
      <link rel='shortcut icon' sizes='512x512' href='/icons/icon-512.png' />
      <meta name='apple-mobile-web-app-capable' content='yes' />
      <meta name='apple-mobile-web-app-status-bar-style' content='black-translucent' />
      <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon-180x180.png' />
      <title>{'EquineM'}</title>
    </Helmet>
  );
}

/**
 * Html meta header fields to use in the webshop
 */
export function WebshopMetaHeader(): JSX.Element {
  const { webshopOrganization } = useWebshop();
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>{webshopOrganization ? `${webshopOrganization.name} | Shop` : t('shop', 'Shop')}</title>
    </Helmet>
  );
}

import LogoutTile from 'components/Profile/LogoutTile';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageMaxWidth } from 'ui/Layout/Page';
import EmailForm from 'components/Profile/EmailForm';
import GeneralPreferencesTitle from 'components/Profile/GeneralPreferencesTile';
import PasswordForm from 'components/Profile/PasswordForm';
import PersonalInformationForm from 'components/Profile/PersonalInformationForm';
import { useAccount } from 'context/AccountContext';
import { Page } from 'ui/Layout';
import { LoadingSection } from 'ui/Loading';
import ProfileAvatarForm from 'components/Profile/ProfileAvatarForm';
import AddressForm from 'components/Profile/AddressForm';
import UserInvitationsAlert from 'components/Profile/UserInvitations/UserInvitationsAlert';
import useDeployment from 'hooks/UseDepoyment';

export default function ProfilePageWrapper(): JSX.Element {
  const { accountDetails } = useAccount();
  const { t } = useTranslation();
  const { deploymentInfoText } = useDeployment();

  return (
    <Page title={t('profilePage.title', 'Profile Information')} maxWidth={PageMaxWidth.Tile}>
      {accountDetails?.uid ? (
        <div className='space-y-4'>
          <UserInvitationsAlert />
          <div className='flex flex-col-reverse lg:flex-row gap-4'>
            <PersonalInformationForm />
            <ProfileAvatarForm className='lg:w-[400px]' />
          </div>
          <EmailForm />

          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <AddressForm />
            <PasswordForm />
            <GeneralPreferencesTitle />
            <LogoutTile />
          </div>
        </div>
      ) : (
        <LoadingSection />
      )}
      <p className='text-xs text-gray-400 text-center mt-6'>{deploymentInfoText}</p>
    </Page>
  );
}

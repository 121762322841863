import { CaretRight, Horse as HorseIcon } from '@phosphor-icons/react';
import useApiPromises from 'api/hooks/useApiPromises';
import { AppRoutes } from 'AppRoutes';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { Contact, Horse, HorseOwner, HorseownersService, HorsesService } from 'openapi';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import Badge from 'ui/Badge';
import Button from 'ui/Button';
import { table, tableTbody, tableTbodyTr, tableThead, tableTheadTd } from 'ui/Const';
import { navBackToThisPage } from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import { ApiPromises } from 'utilities/ApiPromises';
import { AllColors } from 'utilities/colors';

interface Props {
  contact: Contact;
}

const SHOWED_HORSE_COUNT = 10;

function OwnedHorseListTile({ contact }: Props): JSX.Element {
  const [horses, setHorses] = useState<Horse[]>();
  const [horseOwners, setHorseOwners] = useState<HorseOwner[]>();
  const [horsesCount, setHorsesCount] = useState<number>(0);
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { loading } = useApiPromises({ apiPromises });

  const { formatDate, formatNumber } = useAccount();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  /**
   * Load data from the api/cache
   */
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganization) {
      return promises;
    }

    // load all horseoweners
    promises.appendList<HorseOwner>(
      'horseowners',
      () =>
        HorseownersService.horseownersList({
          horseOrganisationUid: selectedOrganization?.uid ?? '',
          contactUid: contact.uid,
          pageSize: SHOWED_HORSE_COUNT,
        }),
      setHorseOwners,
    );

    // load all horses that are owned by this contact
    promises.appendList<Horse>(
      'owned-horses',
      () =>
        HorsesService.horsesList({
          organisationUid: selectedOrganization?.uid ?? '',
          ownerUid: contact.uid,
          hidden: false,
        }),
      setHorses,
      undefined,
      undefined,
      setHorsesCount,
    );

    setApiPromises(promises);

    return promises;
  }, [contact.uid, selectedOrganization]);

  /**
   * Load from the api
   */
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  /**
   * Get a single horse by UID.
   */
  const getHorse = (horseUid: string): Horse | undefined => {
    return horses?.find(horse => horse.uid === horseUid);
  };

  // in case of no horses, we don't show the tile
  if (horsesCount === 0) {
    return <></>;
  }

  return (
    <Tile title={t('horse-ownership', 'Horse ownership')} className='mb-4' loading={loading}>
      <p className='mb-4'>{t('horse-ownership-title-desc', 'The horses that are owned or partially owned by this contact.')}</p>

      <table className={table}>
        <thead className={tableThead}>
          <tr>
            <td className='w-10' />
            <td className={tableTheadTd}>{t('name', 'Name')}</td>
            <td className={tableTheadTd}>{t('since', 'Since')}</td>
            <td className={tableTheadTd}>{t('percentage', 'Percentage')}</td>
            <td className='w-10' />
          </tr>
        </thead>
        <tbody className={tableTbody}>
          {horseOwners?.map(owner => {
            const horse = getHorse(owner.horse_uid);
            return (
              <tr
                className={tableTbodyTr}
                key={owner.uid}
                onClick={() => navigate(`${generatePath(AppRoutes.HorsesDetails.path, { uid: owner.horse_uid })}?${navBackToThisPage()}`)}
              >
                <td className='text-center w-10'>
                  <HorseIcon size={22} weight='light' />
                </td>
                <td>
                  {horse?.name} {horse?.hidden && <Badge color={AllColors.Rose}>{t('inactive', 'Inactive')}</Badge>}
                </td>
                <td>{owner.owner_since ? formatDate(owner.owner_since) : '-'}</td>
                <td>{formatNumber(Number(owner.percentage))}%</td>
                <td className='w-10'>
                  <CaretRight />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {horseOwners && horseOwners.length > SHOWED_HORSE_COUNT && (
        <div className='mt-2 flex w-full justify-center'>
          <Button
            onClick={() =>
              navigate(`${generatePath(AppRoutes.HorsesList.path)}?owned=${contact.uid}&passive=no-stable&${navBackToThisPage()}`)
            }
          >
            {t('show-all-n-horses', 'Show all {{horsesCount}} horses', { horsesCount })}
          </Button>
        </div>
      )}
    </Tile>
  );
}

export default OwnedHorseListTile;

import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { HorseMedia, HorsemediaService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { TextInput } from 'ui/Inputs';
import PageModal, { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';

interface Props {
  visible: boolean;
  file?: HorseMedia;
  onSaved: () => void;
  requestClose: () => void;
  onClosed: () => void;
}

const schema = schemas.HorseMedia.pick({
  filename: true,
});

export default function RenameHorseFileModal({ file, visible, onSaved, requestClose, onClosed }: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<Partial<HorseMedia>>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: file,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const onSubmit = async (data: Partial<HorseMedia>) => {
    if (!file) {
      console.error('Failed to rename file. No file selected');
      return;
    }
    if (!selectedOrganization) {
      console.error('Failed to rename file. No organization selected');
      return;
    }
    try {
      await HorsemediaService.horsemediaPartialUpdate({
        horseOrganisationUid: selectedOrganization.uid,
        uid: file.uid,
        requestBody: { filename: data.filename },
      });
      onSaved();
      requestClose();
    } catch (error) {
      setApiError(new ApiErrorParser<HorseMedia>(error));
    }
  };

  // Set default values when the file is changed.
  useEffect(() => {
    reset(file);
  }, [file, reset]);

  return (
    <PageModal
      width={PageModalWidth.Xs}
      open={visible}
      onClosed={onClosed}
      parentElement='form'
      parentProps={{ id: 'RenameHorseFile', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
    >
      <PageModalTitle title={t('rename-file', 'Rename file')} onClose={requestClose} />
      <PageModalContent>
        <ErrorSection errors={nonFieldErrors} />
        <TextInput required={true} label={t('Name', 'Name')} {...register('filename')} error={fieldError('filename')} />
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            variant: ButtonVariant.Primary,
            type: 'submit',
            loading: isSubmitting,
            text: t('save', 'Save'),
            formId: 'RenameHorseFile',
          },
        ]}
      />
    </PageModal>
  );
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatchedServiceContact } from '../models/PatchedServiceContact';
import type { ServiceContact } from '../models/ServiceContact';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ServicecontactsService {
  /**
   * @returns ServiceContact
   * @throws ApiError
   */
  public static servicecontactsList({
    customerOrganisationUid,
    o,
  }: {
    customerOrganisationUid: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
  }): CancelablePromise<Array<ServiceContact>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{customer__organisation__uid}/servicecontacts',
      path: {
        'customer__organisation__uid': customerOrganisationUid,
      },
      query: {
        'o': o,
      },
    });
  }
  /**
   * @returns ServiceContact
   * @throws ApiError
   */
  public static servicecontactsCreate({
    customerOrganisationUid,
    requestBody,
  }: {
    customerOrganisationUid: string,
    requestBody: ServiceContact,
  }): CancelablePromise<ServiceContact> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{customer__organisation__uid}/servicecontacts',
      path: {
        'customer__organisation__uid': customerOrganisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns ServiceContact
   * @throws ApiError
   */
  public static servicecontactsRetrieve({
    customerOrganisationUid,
    uid,
  }: {
    customerOrganisationUid: string,
    uid: string,
  }): CancelablePromise<ServiceContact> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{customer__organisation__uid}/servicecontacts/{uid}',
      path: {
        'customer__organisation__uid': customerOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * @returns ServiceContact
   * @throws ApiError
   */
  public static servicecontactsUpdate({
    customerOrganisationUid,
    uid,
    requestBody,
  }: {
    customerOrganisationUid: string,
    uid: string,
    requestBody: ServiceContact,
  }): CancelablePromise<ServiceContact> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{customer__organisation__uid}/servicecontacts/{uid}',
      path: {
        'customer__organisation__uid': customerOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns ServiceContact
   * @throws ApiError
   */
  public static servicecontactsPartialUpdate({
    customerOrganisationUid,
    uid,
    requestBody,
  }: {
    customerOrganisationUid: string,
    uid: string,
    requestBody?: PatchedServiceContact,
  }): CancelablePromise<ServiceContact> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{customer__organisation__uid}/servicecontacts/{uid}',
      path: {
        'customer__organisation__uid': customerOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public static servicecontactsDestroy({
    customerOrganisationUid,
    uid,
  }: {
    customerOrganisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{customer__organisation__uid}/servicecontacts/{uid}',
      path: {
        'customer__organisation__uid': customerOrganisationUid,
        'uid': uid,
      },
    });
  }
}

import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AppliedListFilter, FilterOption, ListFilterType } from '.';

interface ReturnType {
  filters: AppliedListFilter[];
  setFilters: (filters: AppliedListFilter[]) => void;
}

// Get and set ListFilterTypes from the url search params
const useListFilter = (listFilterTypes: ListFilterType[]): ReturnType => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = useMemo((): AppliedListFilter[] => {
    const result: AppliedListFilter[] = [];
    for (const filterType of listFilterTypes) {
      const sp: string[] = searchParams.getAll(filterType.id);
      const foundOptions: FilterOption[] = [];
      if (sp.length > 0) {
        for (const option of filterType.options) {
          if (sp.includes(option.id)) {
            foundOptions.push(option);
          }
        }
        if (foundOptions.length > 0) {
          result.push({ type: filterType, options: foundOptions });
        }
      }
    }
    return result;
  }, [searchParams, listFilterTypes]);

  const setFilters = useCallback(
    (filters: AppliedListFilter[]) => {
      const params: URLSearchParams = new URLSearchParams();
      for (const f of filters) {
        for (const o of f.options) {
          params.append(f.type.id, o.id);
        }
      }

      setSearchParams(params);
    },
    [setSearchParams],
  );

  return {
    filters,
    setFilters,
  };
};

export default useListFilter;

import { InvoiceDetail, Organisation } from 'openapi';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { addDays } from 'utilities/date.utilities';

function InvoiceFooterBlock({ invoice, organization }: { invoice?: InvoiceDetail; organization?: Organisation }): JSX.Element {
  const { i18n, t: translate } = useTranslation();

  // Translate to the language of the invoice.
  const t = invoice ? i18n.getFixedT(invoice.language) : translate;

  const formatCurrency = useCallback(
    (amount: number): string => {
      if (isNaN(amount)) {
        return '-';
      }
      const locale = invoice?.language; // TODO: Is this good enough?
      return new Intl.NumberFormat(locale, { style: 'currency', currency: invoice?.currency ?? organization?.currency ?? 'EUR' }).format(
        amount,
      );
    },
    [invoice, organization],
  );

  const invoiceNr = useMemo(() => {
    if (invoice && invoice?.invoice_no) {
      return invoice?.invoice_no;
    } else {
      return t('invoice-number-concept', 'CONCEPT');
    }
  }, [invoice, t]);

  const invoiceDate = useMemo((): Date => {
    return invoice && invoice.date ? new Date(invoice.date) : new Date();
  }, [invoice]);

  const expDate = useMemo((): string => {
    let date: Date = new Date();
    if (invoice?.expiration_date) {
      date = new Date(invoice.expiration_date);
    } else {
      date = addDays(invoiceDate, invoice?.first_due_interval ?? organization?.invoice_first_due_interval ?? 0);
    }
    return new Intl.DateTimeFormat(invoice?.language).format(date);
  }, [invoice, organization, invoiceDate]);

  const totalInclVat = useMemo(() => {
    if (!invoice || !invoice.total_incl_vat) {
      return formatCurrency(0);
    }
    return formatCurrency(Number(invoice.total_incl_vat));
  }, [formatCurrency, invoice]);

  return (
    <p>
      {t(
        'invoice-payment-text-block',
        "We kindly request you the pay the amount of {{totalInclVat}} before {{expDate}} on our bank account using the following reference '{{invoiceNr}}'. For any questions you can reach us by phone or email.",
        {
          totalInclVat,
          expDate,
          invoiceNr,
        },
      )}
    </p>
  );
}
export default InvoiceFooterBlock;

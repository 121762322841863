import { useCallback, useState } from 'react';
import { CancelablePromise, InvitationsService, ReceivedInvitation } from 'openapi';
import ApiErrorParser from 'api/ApiErrorParser';
import { useInvitationContext } from 'context/InvitationContext';

interface ReturnType {
  invitations: ReceivedInvitation[] | undefined;
  loading: boolean;
  error: ApiErrorParser<ReceivedInvitation> | undefined;
  loadInvitations: () => CancelablePromise<ReceivedInvitation[]>;
}

const useUserInvitations = (): ReturnType => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<ApiErrorParser<ReceivedInvitation> | undefined>();

  const { setInvitations, invitations } = useInvitationContext();

  /**
   * Load the roles from API
   */
  const loadInvitations = useCallback((): CancelablePromise<ReceivedInvitation[]> => {
    setLoading(true);
    const promise = InvitationsService.apiV5InvitationsList({
      status: 'pending',
    });
    promise
      .then(res => {
        // Only update the state if the data has changed
        // otherwise we will get an infinite loop due to rerenders
        if (!invitations || res.length !== invitations.length) {
          setInvitations(res);
        }

        setApiError(undefined);
      })
      .catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<ReceivedInvitation[]>(e));
        }
      });
    return promise;
  }, [invitations, setInvitations]);

  return {
    invitations,
    loading,
    error: apiError,
    loadInvitations,
  };
};

export default useUserInvitations;

import { CaretRight, IconContext } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface TileRowProps {
  icon?: ReactNode;
  customIconElement?: JSX.Element;
  onClick?: () => void;
  as?: 'div' | 'li';
  className?: string;
  children: ReactNode;
}

function TileRow({ icon = <CaretRight />, customIconElement, onClick, as = 'div', className, children }: TileRowProps): JSX.Element {
  const wrapperClassname = classNames('border-b border-gray-100 last:border-0', {
    'hover:bg-gray-100': !!onClick && !customIconElement,
  });

  let body = (
    <div
      className={classNames('flex items-center py-4 group px-3', className, {
        'md:px-0': !onClick || customIconElement,
      })}
    >
      <div className='flex flex-col md:flex-row w-full'>{children}</div>

      {!customIconElement && onClick && (
        <button type='button' className='ml-auto md:invisible md:group-hover:visible'>
          <IconContext.Provider value={{ size: 18 }}>{icon}</IconContext.Provider>
        </button>
      )}

      {customIconElement}
    </div>
  );

  if (icon && onClick) {
    body = (
      <a
        className='block'
        href='#'
        onClick={e => {
          e.preventDefault();
          onClick();
        }}
      >
        {body}
      </a>
    );
  }

  if (as === 'div') {
    return <div className={wrapperClassname}>{body}</div>;
  }

  return <li className={wrapperClassname}>{body}</li>;
}

export default TileRow;
export type { TileRowProps };

import classNames from 'classnames';
import { useAccount } from 'context/AccountContext';
import { DayPartStartTime } from 'openapi';
import React, { ReactNode, useMemo } from 'react';
import { DayParts } from 'utilities/Planning';

// A constant that represents the height of an activity (for dayparts/fullday view).
export const ActivityHeight = 35;
export const ActivityHeightSpacious = 49;

export interface Props {
  dayParts?: DayPartStartTime[];
  heights?: number[];
  visibleRange?: { start: number; end: number };
  className?: string;
  width?: number;
  children?: ReactNode;
  showFirstGridLine?: boolean;
}

interface GridItem {
  period: string;
}

/**
 * Show a visual grid which divides a day into subparts (vertically).
 */
export function DayGrid({ heights, visibleRange, className, children, width, showFirstGridLine = false }: Props): JSX.Element {
  const visibleHeights = useMemo(() => {
    if (!heights) {
      return [];
    }
    if (!visibleRange) {
      return heights;
    }
    return heights?.slice(visibleRange.start, visibleRange.end);
  }, [heights, visibleRange]);

  const items = useMemo((): GridItem[] => {
    const result: GridItem[] = [];
    for (let i = 0; i < (visibleHeights.length ?? 0); i++) {
      result.push({ period: i.toString() });
    }
    return result;
  }, [visibleHeights]);

  return (
    <div style={{ width }} className={classNames('flex flex-col justify-stretch relative', className)}>
      {items.map((item, index, items) => (
        <div
          style={{ height: visibleHeights?.[index] }}
          className={classNames('text-xs grow', { 'border-b': index === items.length - 1, 'border-t': index !== 0 || showFirstGridLine })}
          key={item.period}
        />
      ))}
      {children}
    </div>
  );
}

/**
 * Divides a day into subparts and adds time labels (vertically).
 */
export function DayGridScale({ heights, className, dayParts, visibleRange }: Props): JSX.Element {
  const { formatTime } = useAccount();

  const labels = useMemo((): string[] => {
    // If the day parts array is set. Then use the start times of the day parts.
    if (dayParts) {
      return dayParts.map(dayPart => {
        const timeParts = dayPart.start_time.split(':');
        const date = new Date();
        date.setHours(Number(timeParts[0]));
        date.setMinutes(Number(timeParts[1]));
        return formatTime(date);
      });
    }

    // If the day parts are not set. Then evenly divide the day based on the heights var.
    const items = [];
    for (let hour = visibleRange?.start ?? 0; hour < (visibleRange?.end ?? 24); hour++) {
      items.push([hour, 0]);
      items.push([hour, 30]);
    }

    // TODO: Use the actual date?
    const date = new Date();
    date.setMonth(1);
    date.setDate(1);

    const range = items.map(time => {
      const [hour, minute] = time;
      date.setHours(hour);
      date.setMinutes(minute);
      return formatTime(date);
    });

    const itemFromRange = (index: number) => {
      switch (heights?.length) {
        case DayParts.HalfDay:
          return range[index * 24];
        case DayParts.QuarterDay:
          return range[index * 12];
        case DayParts.Hour:
          return range[index * 2];
        case DayParts.HalfHour:
          return range[index];
        default:
          return range[0];
      }
    };

    const result: string[] = [];
    for (let i = 0; i < (heights?.length ?? 0); i++) {
      result.push(itemFromRange(i));
    }
    return result;
  }, [heights, formatTime, dayParts, visibleRange]);

  return (
    <div className={classNames('flex flex-col justify-stretch select-none', className)}>
      {labels.map((item, index) => (
        <div className='grow pr-1' key={`${item}${index}`} style={{ height: heights?.[index] }}>
          <p className={classNames('translate-y-[-50%] text-xs text-gray-500 text-right whitespace-nowrap', { 'opacity-0': index === 0 })}>
            {item}
          </p>
        </div>
      ))}
    </div>
  );
}

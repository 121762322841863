import { Carrot } from '@phosphor-icons/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertShowcase, InputShowcase, ToastShowcase } from '../components/Showcase';
import Article from '../ui/Article';
import { AvatarInitials, AvatarSize } from '../ui/Avatar';
import Badge, { BadgeSize } from '../ui/Badge/Badge';
import Button, { ButtonSize, ButtonVariant } from '../ui/Button';
import DropdownMenu from '../ui/DropdownMenu';
import { Label } from '../ui/Label';
import { LoadingSection, Spinner } from '../ui/Loading';
import { SpinnerSize } from '../ui/Loading/Spinner';
import { ActionModal } from '../ui/Modals';
import { Switch } from '../ui/Switch';
import { AllColors } from '../utilities/colors';

export default function UiShowcase(): JSX.Element {
  const { t } = useTranslation();
  const [openActionModal, setOpenActionModal] = useState(false);
  return (
    <div className={'px-4'}>
      <Article
        title='Loaders'
        caption='Loaders are available in various sizes. They can also be used in a loading section or in a loading wrapper.'
      >
        <div className='flex gap-6 mb-6'>
          <div className={'w-16 h-16 border rounded flex items-center justify-center'}>
            <Spinner size={SpinnerSize.XSmall} />
          </div>
          <div className={'w-16 h-16 border rounded flex items-center justify-center'}>
            <Spinner size={SpinnerSize.Small} />
          </div>
          <div className={'w-16 h-16 border rounded flex items-center justify-center'}>
            <Spinner size={SpinnerSize.Normal} />
          </div>
          <div className={'w-16 h-16 border rounded flex items-center justify-center'}>
            <Spinner size={SpinnerSize.Large} />
          </div>
        </div>
        <div className={'w-full h-52 border rounded'}>
          <LoadingSection text={'Loading section with custom text...'} />
        </div>
      </Article>
      <Article
        title='Buttons'
        caption='Buttons are equipped with a lot of features. We have various sizes from "extra small" to "extra large". We can set an icon and give it a color to stand out from the rest.'
      >
        <div className='flex flex-col gap-8'>
          <div className='flex gap-2 items-center flex-wrap'>
            <Button size={ButtonSize.XSmall}>{t('button-xsmall', 'Extra small')}</Button>
            <Button size={ButtonSize.Small}>{t('button-small', 'Small')}</Button>
            <Button size={ButtonSize.Normal}>{t('button-normal', 'Normal')}</Button>
            <Button size={ButtonSize.Large}>{t('button-large', 'Large')}</Button>
            <Button size={ButtonSize.XLarge}>{t('button-xlarge', 'Extra large')}</Button>
          </div>
          <div className='flex gap-2 items-center flex-wrap'>
            <Button icon={<Carrot />} size={ButtonSize.XSmall}>
              {t('button-xsmall', 'Extra small')}
            </Button>
            <Button icon={<Carrot />} size={ButtonSize.Small}>
              {t('button-small', 'Small')}
            </Button>
            <Button icon={<Carrot />} size={ButtonSize.Normal}>
              {t('button-normal', 'Normal')}
            </Button>
            <Button icon={<Carrot />} size={ButtonSize.Large}>
              {t('button-large', 'Large')}
            </Button>
            <Button icon={<Carrot />} size={ButtonSize.XLarge}>
              {t('button-xlarge', 'Extra large')}
            </Button>
          </div>
          <div className='flex gap-2 items-center flex-wrap'>
            <Button loading size={ButtonSize.XSmall}>
              {t('button-xsmall', 'Extra small')}
            </Button>
            <Button loading size={ButtonSize.Small}>
              {t('button-small', 'Small')}
            </Button>
            <Button loading size={ButtonSize.Normal}>
              {t('button-normal', 'Normal')}
            </Button>
            <Button loading size={ButtonSize.Large}>
              {t('button-large', 'Large')}
            </Button>
            <Button loading size={ButtonSize.XLarge}>
              {t('button-xlarge', 'Extra large')}
            </Button>
          </div>
          <div className='flex gap-2 items-center flex-wrap'>
            <Button size={ButtonSize.Normal} variant={ButtonVariant.Default}>
              {t('button-default', 'Default')}
            </Button>
            <Button size={ButtonSize.Normal} variant={ButtonVariant.Primary}>
              {t('button-primary', 'Primary')}
            </Button>
            <Button size={ButtonSize.Normal} variant={ButtonVariant.Danger}>
              {t('button-danger', 'Danger')}
            </Button>
            <Button size={ButtonSize.Normal} variant={ButtonVariant.PrimaryDanger}>
              {t('button-primary-danger', 'Primary danger')}
            </Button>
            <Button size={ButtonSize.Normal} variant={ButtonVariant.Success}>
              {t('button-success', 'Success')}
            </Button>
            <Button size={ButtonSize.Normal} variant={ButtonVariant.PrimarySuccess}>
              {t('button-primary-success', 'Primary success')}
            </Button>
          </div>
          <div className='flex gap-2 items-center flex-wrap'>
            <Button icon={<Carrot />} size={ButtonSize.Normal} variant={ButtonVariant.Default}>
              {t('button-default', 'Default')}
            </Button>
            <Button icon={<Carrot />} size={ButtonSize.Normal} variant={ButtonVariant.Primary}>
              {t('button-primary', 'Primary')}
            </Button>
            <Button icon={<Carrot />} size={ButtonSize.Normal} variant={ButtonVariant.Danger}>
              {t('button-danger', 'Danger')}
            </Button>
            <Button icon={<Carrot />} size={ButtonSize.Normal} variant={ButtonVariant.PrimaryDanger}>
              {t('button-primary-danger', 'Primary danger')}
            </Button>
            <Button icon={<Carrot />} size={ButtonSize.Normal} variant={ButtonVariant.Success}>
              {t('button-success', 'Success')}
            </Button>
            <Button icon={<Carrot />} size={ButtonSize.Normal} variant={ButtonVariant.PrimarySuccess}>
              {t('button-primary-success', 'Primary success')}
            </Button>
          </div>
          <div className='flex gap-2 items-center flex-wrap'>
            <Button disabled icon={<Carrot />} size={ButtonSize.Normal} variant={ButtonVariant.Default}>
              {t('button-default', 'Default')}
            </Button>
            <Button disabled icon={<Carrot />} size={ButtonSize.Normal} variant={ButtonVariant.Primary}>
              {t('button-primary', 'Primary')}
            </Button>
            <Button disabled icon={<Carrot />} size={ButtonSize.Normal} variant={ButtonVariant.Danger}>
              {t('button-danger', 'Danger')}
            </Button>
            <Button disabled icon={<Carrot />} size={ButtonSize.Normal} variant={ButtonVariant.PrimaryDanger}>
              {t('button-primary-danger', 'Primary danger')}
            </Button>
            <Button disabled icon={<Carrot />} size={ButtonSize.Normal} variant={ButtonVariant.Success}>
              {t('button-success', 'Success')}
            </Button>
            <Button disabled icon={<Carrot />} size={ButtonSize.Normal} variant={ButtonVariant.PrimarySuccess}>
              {t('button-primary-success', 'Primary success')}
            </Button>
          </div>
        </div>
      </Article>
      <Article title='Modals' caption='Modals are overlays used for quick action prompts'>
        <div className='flex flex-col gap-8'>
          <div className='flex gap-2 items-center flex-wrap'>
            {openActionModal && (
              <ActionModal
                open={openActionModal}
                actions={[
                  {
                    text: 'Close',
                    variant: ButtonVariant.Danger,
                    onClick: () => {
                      setOpenActionModal(false);
                    },
                  },
                ]}
                onClose={() => setOpenActionModal(false)}
                title={'Demo Action Modal'}
              >
                <div>{t('demo-action-modal-text', 'This is the demo action modal')}</div>
              </ActionModal>
            )}
            <Button onClick={() => setOpenActionModal(true)} size={ButtonSize.XSmall}>
              {t('open-modal', 'Open Modal')}
            </Button>
          </div>
        </div>
      </Article>
      <Article title='Avatar' caption='An avatar represents a user or contact.'>
        <div className='flex flex-col gap-8'>
          <div className='flex gap-2 items-center flex-wrap'>
            <AvatarInitials initials='TU' size={AvatarSize.Normal} />
            <AvatarInitials initials='TU' size={AvatarSize.Small} />
            <AvatarInitials initials='TU' size={AvatarSize.XSmall} />
          </div>
          <div className='flex gap-2 items-center flex-wrap'>
            <AvatarInitials initials='TU' size={AvatarSize.Normal} color={'#FF00FF'} />
            <AvatarInitials initials='TU' size={AvatarSize.Normal} uuid={'uo834thbi7dftfasdfkj'} />
            <AvatarInitials initials='TU' size={AvatarSize.Normal} disabled={true} />
          </div>
        </div>
      </Article>
      <Article title='Badges' caption='Badges of different sizes and colors'>
        <div className='flex flex-col gap-8'>
          <div className='flex gap-2 items-center flex-wrap'>
            <Badge size={BadgeSize.Small}>{t('small', 'Small')}</Badge>
            <Badge size={BadgeSize.Normal}>{t('normal', 'Normal')}</Badge>
            <Badge size={BadgeSize.Large}>{t('large', 'Large')}</Badge>
          </div>
          <div className='flex gap-2 items-center flex-wrap'>
            {Object.values(AllColors).map((value: AllColors) => (
              <Badge key={value} color={value}>
                {value.toString()}
              </Badge>
            ))}
          </div>
          <div className='flex gap-2 items-center flex-wrap'>
            <Badge rounded={true}>{t('rounded', 'Rounded')}</Badge>
          </div>
        </div>
      </Article>
      <Article title='Dropdown Menu' caption='Dropdown menu with different placements'>
        <div className='flex justify-start gap-4'>
          <DropdownMenu
            menuItems={[
              [
                { element: t('profile', 'Profile') },
                { element: t('password', 'Password'), isDisabled: true },
                { element: t('email', 'Email') },
              ],
              [{ element: t('remove', 'Remove'), className: 'text-red-600' }],
            ]}
          >
            <Button>{t('auto-placment', 'Auto Placement')}</Button>
          </DropdownMenu>
          <DropdownMenu
            menuPlacement='top'
            menuItems={[
              [
                { element: t('profile', 'Profile') },
                { element: t('password', 'Password'), isDisabled: true },
                { element: t('email', 'Email') },
              ],
              [{ element: t('remove', 'Remove'), className: 'text-red-600' }],
            ]}
          >
            <Button>{t('top-placment', 'Top Placement')}</Button>
          </DropdownMenu>
          <DropdownMenu
            menuPlacement='right'
            menuItems={[
              [
                { element: t('profile', 'Profile') },
                { element: t('password', 'Password'), isDisabled: true },
                { element: t('email', 'Email') },
              ],
              [{ element: t('remove', 'Remove'), className: 'text-red-600' }],
            ]}
          >
            <Button>{t('right-placement', 'Right Placement')}</Button>
          </DropdownMenu>
          <DropdownMenu
            headerItem='Header item'
            menuItems={[
              [
                { element: t('profile', 'Profile') },
                { element: t('password', 'Password'), isDisabled: true },
                { element: t('email', 'Email') },
              ],
              [{ element: t('remove', 'Remove'), className: 'text-red-600' }],
            ]}
          >
            <Button>{t('header-item', 'Header item')}</Button>
          </DropdownMenu>
        </div>
      </Article>
      <Article title='Switch' caption='Switch in different styles'>
        <div className='flex justify-start gap-4'>
          <div className='flex flex-col'>
            <Label>{t('ui.showcase.default', 'Default')}</Label>
            <Switch onClick={() => console.log('clicked')} />
          </div>
          <div className='flex flex-col'>
            <Label>{t('ui.showcase.checked_state', 'With checked state')}</Label>
            <Switch onClick={() => console.log('clicked')} checked={true} />
          </div>
          <div className='flex flex-col'>
            <Label>{t('ui.showcase.loading_state', 'With loading state')}</Label>
            <Switch onClick={() => console.log('clicked')} loading={true} />
          </div>
        </div>
      </Article>
      <InputShowcase />
      <AlertShowcase />
      <ToastShowcase />
      <div className={'h-10'} />
    </div>
  );
}

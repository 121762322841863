import useScreenSize, { ScreenSize } from './UseScreenSize';

interface ReturnType {
  labelWidth: string;
  columnWidth: string;
  arrowWidth: string;
}

function UsePermissionWidths(): ReturnType {
  const { width } = useScreenSize();

  const labelWidth = width > ScreenSize.md ? '200px' : '125px';
  const columnWidth = '150px';
  const arrowWidth = 'w-10';

  return {
    labelWidth,
    columnWidth,
    arrowWidth,
  };
}

export default UsePermissionWidths;

import { CancelablePromise } from 'openapi';
import { Dispatch, SetStateAction } from 'react';

/**
 * Uniquely identify a cached entry. Username and organization allow us to stay
 * in scope. The name field sets a name for the data being stored. I.e. 'horses'.
 */
export interface CacheKey {
  userUid: string;
  organizationUid: string;
  name: string;
}

export type PaginatedList<T> = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<T>;
};

/**
 * Set the cached data into the given setState function
 *
 * @param key
 * @param setState
 */
function getCache<T>(key: string, setState: Dispatch<SetStateAction<T[] | undefined>> | Dispatch<SetStateAction<T[]>>) {
  const cachedData = sessionStorage?.getItem(key);
  if (cachedData) {
    try {
      console.log(`Loading api data from cache with key ${key}`);
      setState(JSON.parse(cachedData));
    } catch (e) {
      console.error(`Failed to load cached api data with key ${key}. Resetting cache.`);
      sessionStorage?.removeItem(key);
    }
  }
}

/**
 * Retrieve a cached version of your paginated API request. It will also update the cache
 * by doing a new request. But the user doesn't have to wait for this.
 *
 * @param id // Unique id for accessing the cache.
 * @param promise The data fetching promise.
 * @param setState The external method to call for setting the content.
 * @param disableCache Set to true if you don't want to preload your request with cached data.
 * @returns The passed in promise is returned
 */
export function cachedPaginatedApiData<T>(
  id: CacheKey,
  promise: CancelablePromise<PaginatedList<T>>,
  setState: Dispatch<SetStateAction<T[] | undefined>> | Dispatch<SetStateAction<T[]>>,
  disableCache = false,
): CancelablePromise<PaginatedList<T>> {
  const key = `${id.userUid}/${id.organizationUid}/${id.name}`;
  if (!disableCache) {
    getCache<T>(key, setState);
  }

  promise
    .then(data => {
      // We only set the cache if we have all Objects in this request. We don't
      // cache partial data because of the complexity it brings. The data.count
      // value should be equal to the amount of entries we've gotten to ensure
      // there is not still another page to fetch.
      if (data.count === data.results.length) {
        sessionStorage?.setItem(key, JSON.stringify(data.results));
      } else {
        console.warn('Cached api data does not support partial data sets from the api. Not adding it to the local cache.');
      }
      setState(data.results ?? []);
    })
    .catch(() => {
      // No need to catch here. We leave the error handling to the caller.
    });
  return promise;
}

/**
 * Retrieve a cached version of your API request. It will also update the cache
 * by doing a new request. But the user doesn't have to wait for this.
 *
 * @param id // Unique id for accessing the cache.
 * @param promise The data fetching promise.
 * @param setState The external method to call for setting the content.
 * @param disableCache Set to true if you don't want to preload your request with cached data.
 * @returns The passed in promise is returned
 */
export function cachedApiData<T>(
  id: CacheKey,
  promise: CancelablePromise<T[]>,
  setState: Dispatch<SetStateAction<T[] | undefined>> | Dispatch<SetStateAction<T[]>>,
  disableCache = false,
): CancelablePromise<T[]> {
  const key = `${id.userUid}/${id.organizationUid}/${id.name}`;
  if (!disableCache) {
    getCache<T>(key, setState);
  }

  promise
    .then(data => {
      // Do some sanity checks.
      if (!(data instanceof Array)) {
        console.error('Expected an Array<T>');
        setState([]);
        return;
      }

      sessionStorage?.setItem(key, JSON.stringify(data));
      setState(data as T[]);
    })
    .catch(() => {
      // No need to catch here. We leave the error handling to the caller.
    });
  return promise;
}

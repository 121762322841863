import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useModal from 'ui/Modals/UseModal';
import { Alert } from 'ui/Alert';
import { Severity } from 'utilities/severity';
import useUserInvitations from 'hooks/UseUserInvitations';
import AcceptInvitationModal from './AcceptInvitation';
import DeclineInvitationModal from './DeclineInvitation';
import { ReceivedInvitation } from 'openapi';
import Button, { ButtonSize, ButtonVariant } from 'ui/Button';
import { X } from '@phosphor-icons/react';

/**
 * Small componet to show an alert to the user if they have pending invitations
 */
export default function UserInvitationsAlert(): JSX.Element {
  const [selectedInvitation, setSelectedInvitation] = useState<ReceivedInvitation>();

  const { t } = useTranslation();
  const { loadInvitations, invitations } = useUserInvitations();

  const { closeModal: closeAcceptModal, modalIsVisible: acceptModalIsVisible, showModal: showAcceptModal } = useModal();
  const { closeModal: closeDeclineModal, modalIsVisible: declineModalIsVisible, showModal: showDeclineModal } = useModal();

  /**
   * Reload the invitations
   */
  useEffect(() => {
    const promise = loadInvitations();
    return () => promise.cancel();
  }, [loadInvitations]);

  if (!invitations || invitations.length === 0) {
    return <></>;
  }

  return (
    <>
      {invitations.map(invitation => (
        <Alert
          key={invitation.uuid}
          severity={Severity.Info}
          message={
            <div className='flex flex-col md:flex-row justify-between md:items-center gap-2'>
              <p>
                <Trans
                  i18nKey='invitation-alert'
                  defaults='You have been invited by <strong>{{name}}</strong> to join the organization <strong>{{organizationName}}</strong>.'
                  values={{
                    organizationName: invitation.inviter_organisation.name,
                    name: `${invitation.inviter.first_name} ${invitation.inviter.last_name}`,
                  }}
                />
              </p>

              <div className='flex items-center gap-x-3 md:divide-x md:divide-gray-300 mt-2 md:mt-0'>
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.Primary}
                  onClick={() => {
                    setSelectedInvitation(invitation);
                    showAcceptModal();
                  }}
                  className='grow'
                >
                  {t('accept', 'Accept')}
                </Button>
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.Default}
                  onClick={() => {
                    setSelectedInvitation(invitation);
                    showDeclineModal();
                  }}
                  className='grow md:hidden'
                >
                  <span>{t('decline', 'Decline')}</span>
                </Button>
                <div className='pl-3 hidden md:inline h-full'>
                  <button
                    onClick={() => {
                      setSelectedInvitation(invitation);
                      showDeclineModal();
                    }}
                  >
                    <X size={14} className='text-gray-500' />
                  </button>
                </div>
              </div>
            </div>
          }
        />
      ))}

      <AcceptInvitationModal
        invitation={selectedInvitation}
        isVisible={acceptModalIsVisible}
        onRequestClose={() => {
          setSelectedInvitation(undefined);
          closeAcceptModal();
        }}
        onSuccess={() => {
          loadInvitations().then(res => res.length === 0 && closeAcceptModal());
          setSelectedInvitation(undefined);
        }}
      />
      <DeclineInvitationModal
        invitation={selectedInvitation}
        isVisible={declineModalIsVisible}
        onRequestClose={() => {
          setSelectedInvitation(undefined);
          closeDeclineModal();
        }}
        onSuccess={() => {
          loadInvitations().then(res => res.length === 0 && closeDeclineModal());
          setSelectedInvitation(undefined);
        }}
      />
    </>
  );
}

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import usePublicInvoice from '../api/hooks/usePublicInvoice';
import InvoiceFooterBlock from '../components/Financial/InvoiceFooterBlock';
import InvoiceInfoBlock from '../components/Financial/InvoiceInfoBlock';
import InvoiceItemsBlock from '../components/Financial/InvoiceItemsBlock';
import InvoiceReceiverBlock from '../components/Financial/InvoiceReceiverBlock';
import InvoiceSenderLogoBlock from '../components/Financial/InvoiceSenderLogoBlock';
import InvoiceSenderBlock from '../components/Financial/InvoiceSenderBlock';

// Load some printer css that is used by topdf.ts
import './PublicInvoicePrint.css';

export default function PublicInvoicePrint(): JSX.Element {
  const { publicAccessUuid } = useParams();
  const [showPrintLoadFinished, setShowPrintLoadFinished] = useState<boolean>(false);

  const { invoice } = usePublicInvoice(publicAccessUuid);

  if (!invoice) {
    return <></>;
  }

  return (
    <div className='h-full flex flex-col gap-4'>
      <InvoiceSenderLogoBlock onLogoLoaded={() => setShowPrintLoadFinished(true)} organization={invoice.historic_supplier} />
      <InvoiceSenderBlock invoice={invoice} organization={invoice.historic_supplier} />
      <div className='pl-8 pb-8 flex'>
        <InvoiceReceiverBlock receiver={invoice.historic_customer} />
      </div>
      <InvoiceInfoBlock invoice={invoice} />
      <InvoiceItemsBlock invoice={invoice} />
      <div className='grow flex items-end'>
        <InvoiceFooterBlock invoice={invoice} />
      </div>
      {showPrintLoadFinished && <div id='print-load-finished' />}
    </div>
  );
}

import { toast, ToastOptions as RHTToastOptions } from 'react-hot-toast';

/**
 * ToastManager
 *
 * This module provides a wrapper around react-hot-toast's toast functionality.
 * It abstracts the underlying library and offers custom configurations for the application.
 *
 */

/**
 * ToastOptions
 *
 * Custom interface for toast configuration.
 *
 * @property {string} [id] - Unique ID for the toast. Prevents duplicate toasts.
 * @property {number} [duration] - Duration in ms. Set to -1 to make it permanent.
 * @property {string} [icon] - A React component that replaces the default icon.
 * @property {'left' | 'right'} [iconPosition] - Position of the icon within the toast.
 * @property {string} [style] - Custom styles as a CSS object.
 * @property {string} [role] - Aria role. Set it to status for screen readers.
 * @property {'text' | 'polite' | 'assertive'} [ariaLive] - Aria live region mode.
 */
type ToastOptions = RHTToastOptions;
const DefaultOptions: Partial<ToastOptions> = {
  duration: 5000,
};
/**
 * Display a success toast.
 *
 * @param {string} message - The message to display in the toast.
 * @param {ToastOptions} [options] - Optional configuration for the toast.
 */
export const successToast = (message: string, options: ToastOptions = DefaultOptions): void => {
  toast.success(message, options);
};

/**
 * Display an information toast.
 *
 * @param {string} message - The message to display in the toast.
 * @param {ToastOptions} [options] - Optional configuration for the toast.
 */
export const infoToast = (message: string, options: ToastOptions = DefaultOptions): void => {
  toast(message, options);
};

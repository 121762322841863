import { CaretRight, Checks, Invoice as InvoiceIcon, Plus, Swap } from '@phosphor-icons/react';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import { useOrganization } from 'context/OrganizationContext';
import { Contact, ContactsService, Invoice, InvoicesService, OAuth2Token, OauthService, PaymentStatusEnum, ProviderEnum } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ListFilterButton from '../../components/Common/ListFilter/ListFilterButton';
import useListFilter from '../../components/Common/ListFilter/useListFilter';
import SearchContactOrOther from '../../components/Common/SearchContactOrOther';
import { PageAction } from '../../context/PageContext';
import { ButtonVariant } from '../../ui/Button';
import Page from '../../ui/Layout/Page';
import { defaultApiPageSize, tableThead, tableTheadTdSticky } from 'ui/Const';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { ListFilterType } from 'components/Common/ListFilter';
import {
  table,
  tableHiddenColumnMd,
  tableHiddenColumnSm,
  tableHiddenHeaderSm,
  tableTbody,
  tableTbodyTr,
  tableTheadTd,
} from '../../ui/Const';
import { useAccount } from 'context/AccountContext';
import InvoiceNumber from 'components/Financial/InvoiceNumber';
import Badge from 'ui/Badge';
import { AllColors } from 'utilities/colors';
import { BadgeSize } from 'ui/Badge/Badge';
import { contactName } from 'utilities/Contact';
import { PaymentStatusToColor, PaymentStatusToString } from 'components/Financial/Helpers';
import { Tile } from 'ui/Layout/Tile';
import { ApiPromises } from 'utilities/ApiPromises';
import classNames from 'classnames';
import { integrationName } from 'utilities/Integrations';
import useModal from 'ui/Modals/UseModal';
import SendToBookkeepingModal from 'components/Financial/SendToBookkeepingModal';
import useSelection from 'hooks/UseSelection';

export default function InvoicesListPage(): JSX.Element {
  const { selectedOrganization, generateCacheKey: getCacheId } = useOrganization();
  const [contacts, setContacts] = useState<Contact[]>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { formatDate, parseAndFormatMoney } = useAccount();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const [invoices, setInvoices] = useState<Invoice[]>();
  const [integrations, setIntegrations] = useState<OAuth2Token[]>();

  // Search results from the SearchInvoice component.
  const [findContactId, setFindContactId] = useState<string | undefined>();
  const [findInvoiceNo, setFindInvoiceNo] = useState<string | undefined>();

  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
  const {
    closeModal: closeSendBookkeepingModal,
    modalIsVisible: sendBookkeepingModalIsVisible,
    showModal: showSendBookkeepingModal,
  } = useModal();

  // A list of types we can filter by.
  const filterTypes = useMemo((): ListFilterType[] => {
    const stateFilter: ListFilterType = {
      id: 'state',
      name: t('state', 'State'),
      options: [
        { id: 'draft', name: t('indraft', 'In draft') },
        { id: 'final', name: t('finalized', 'Finalized') },
      ],
    };

    const paidFilter: ListFilterType = {
      id: 'payment-status',
      name: t('payment-status', 'Payment status'),
      options: [
        { id: 'open', name: t('open', 'Open') },
        { id: 'processing', name: t('processing', 'Processing') },
        { id: 'paid', name: t('paid', 'Paid') },
      ],
    };

    return [stateFilter, paidFilter];
  }, [t]);

  const { filters } = useListFilter(filterTypes);

  const bookkeepingProviders = useMemo((): ProviderEnum[] => {
    return Object.values(ProviderEnum).filter(key => integrations?.find(integration => integration.name === key));
  }, [integrations]);

  const bookkeepingProvidersNameString = useMemo((): string => {
    if (bookkeepingProviders.length === 0) {
      return t('bookkeeping', 'bookkeeping');
    }
    return bookkeepingProviders.map(provider => integrationName(t, provider)).join(', ');
  }, [bookkeepingProviders, t]);

  // load the hook for the selection
  const {
    element: selectionElement,
    triggerAddToSelectionError,
    enable: enableSelection,
    enabled: selectionEnabled,
  } = useSelection({
    selectedItems: selectedInvoices.length,
    label: {
      singular: t('1-invoice-selected', '1 invoice selected'),
      plural: t('n-invoices-selected', '{{n}} invoices selected'),
      noResult: t('no-invoices-selected', 'No invoices selected'),
    },
    actions: [
      {
        text: t('send-to-bookkeeping', 'Send to {{bookkeeping}}', { bookkeeping: bookkeepingProvidersNameString }),
        onClick: showSendBookkeepingModal,
        disabled: selectedInvoices.length === 0,
      },
    ],
    onCancel: () => setSelectedInvoices([]),
  });

  const pageActions = useMemo((): PageAction[] => {
    if (selectionEnabled) {
      return [];
    }

    const list = [
      {
        text: t('add-invoice', 'Add invoice'),
        isMobileAddAction: true,
        icon: <Plus />,
        buttonVariant: ButtonVariant.Primary,
        onClick: () => {
          navigate('new');
        },
      },
    ];

    // Show button to sync with bookkeeping provider if we have at least one of them connected.
    if (bookkeepingProviders.length > 0) {
      list.push({
        text: t('sync-with-bookkeeping', 'Sync with {{bookkeeping}}', { bookkeeping: bookkeepingProvidersNameString }),
        isMobileAddAction: false,
        icon: <Swap />,
        buttonVariant: ButtonVariant.Default,
        onClick: enableSelection,
      });
    }

    return list.reverse();
  }, [selectionEnabled, t, bookkeepingProviders.length, navigate, bookkeepingProvidersNameString, enableSelection]);

  const isAlreadySentToBookkeeping = (invoice: Invoice) => {
    for (const provider of bookkeepingProviders) {
      if (provider === ProviderEnum.MONEYBIRD && invoice.moneybird_invoice_id) {
        return true;
      }
      if (provider === ProviderEnum.EXACTNL && invoice.exactnl_invoice_id) {
        return true;
      }
      if (provider === ProviderEnum.YUKI && invoice.yuki_invoice_id) {
        return true;
      }
    }
    return false;
  };

  const canSelect = (invoice: Invoice): boolean => {
    if (!invoice.finalized_on) {
      return false;
    }
    if (isAlreadySentToBookkeeping(invoice)) {
      return false;
    }
    return true;
  };

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    if (!selectedOrganization) {
      throw Error('Cannot fetch order list when selected organization is not set.');
    }

    // Filter for a specific customer
    let selectedCustomerUid: string | undefined = undefined;
    const customerFilter = filters?.find(f => f.type.id === 'customer');
    if (customerFilter && customerFilter.options.length === 1) {
      selectedCustomerUid = customerFilter.options[0].id;
    }

    if (selectedCustomerUid) console.log('Trying to filter for contact id but not supported by api.', selectedCustomerUid);

    // Draft / Finalized filter
    let draft: boolean | undefined = undefined;
    const stateFilter = filters?.find(f => f.type.id === 'state');
    if (stateFilter) {
      const hasDraft = stateFilter.options.find(opt => opt.id === 'draft') !== undefined;
      const hasFinal = stateFilter.options.find(opt => opt.id === 'final') !== undefined;
      if (hasDraft !== hasFinal) {
        draft = hasDraft;
      }
    }

    let paymentStatus: PaymentStatusEnum[] | undefined = undefined;
    const paymentStatusFilter = filters?.find(f => f.type.id === 'payment-status');
    if (paymentStatusFilter) {
      paymentStatus = [];
      if (paymentStatusFilter.options.find(opt => opt.id === 'open') !== undefined) {
        paymentStatus.push(PaymentStatusEnum.OPEN);
      }
      if (paymentStatusFilter.options.find(opt => opt.id === 'processing') !== undefined) {
        paymentStatus.push(PaymentStatusEnum.PROCESSING);
      }
      if (paymentStatusFilter.options.find(opt => opt.id === 'paid') !== undefined) {
        paymentStatus.push(PaymentStatusEnum.PAID);
      }
    }

    const promises = new ApiPromises();
    if (!selectedOrganization) {
      return promises;
    }
    promises.setPaginated<Invoice>(
      'invoices',
      apiPageNumber => {
        return InvoicesService.invoicesList({
          organisationUid: selectedOrganization.uid,
          page: apiPageNumber,
          pageSize: defaultApiPageSize,
          invoiceNo: findInvoiceNo,
          customerUid: findContactId,
          draft: draft,
          paymentStatusIn: paymentStatus,
          o: '-created_on,-id',
        });
      },
      setInvoices,
      !findInvoiceNo && !findContactId && !paymentStatus, // Only count as empty result when no filters are set.
    );

    // TODO for performance we should only load the promises below once we visit the page
    // See https://gitlab.qubis.nl/equinem/equiapp/-/issues/251

    // Load all contacts, including the removed once.
    promises.appendList<Contact>(
      'contacts',
      () =>
        ContactsService.contactsList({
          organisationUid: selectedOrganization.uid,
        }),
      setContacts,
      getCacheId('contacts'),
    );
    promises.appendList<OAuth2Token>(
      'integrations',
      () => {
        return OauthService.oauthTokensList({ organisationUid: selectedOrganization?.uid ?? '' });
      },
      setIntegrations,
    );
    setApiPromises(promises);
    return promises;
  }, [selectedOrganization, getCacheId, filters, findContactId, findInvoiceNo]);

  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization, findContactId, findInvoiceNo, filters]); //eslint-disable-line

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization, findContactId, findInvoiceNo]); //eslint-disable-line

  return (
    <Page title={t('Invoices', 'Invoices')} actions={pageActions} loading={apiPromises}>
      {selectionElement}
      <PullScrollWrapper apiPromises={apiPromises}>
        <Tile noBoxOnMobile={true}>
          {filterTypes && contacts && (
            <FilterWrapper listFilterTypes={filterTypes}>
              <ListFilterButton listFilterTypes={filterTypes} />
              <SearchContactOrOther
                onClear={() => {
                  setFindContactId(undefined);
                  setFindInvoiceNo(undefined);
                }}
                onContactSearch={contact => {
                  setFindContactId(contact.uid);
                  setFindInvoiceNo(undefined);
                }}
                onOtherSearch={searchString => {
                  setFindContactId(undefined);
                  setFindInvoiceNo(searchString);
                }}
                contacts={contacts}
                placeholderText={t('search-by-name-contact-invoiceno', 'Search by contact or invoice-number...')}
              />
            </FilterWrapper>
          )}
          <table className={table}>
            <thead>
              <tr className={tableHiddenHeaderSm}>
                <td className={classNames('w-10', selectionEnabled ? tableThead : tableTheadTdSticky)} />
                <td className={classNames(tableTheadTd, selectionEnabled ? tableThead : tableTheadTdSticky)}>{t('date', 'Date')}</td>
                <td className={classNames(tableTheadTd, selectionEnabled ? tableThead : tableTheadTdSticky)}>
                  {t('invoice-number', 'Invoice number')}
                </td>
                <td className={classNames(tableTheadTd, selectionEnabled ? tableThead : tableTheadTdSticky)}>
                  {t('customer', 'Customer')}
                </td>
                <td className={classNames(tableTheadTd, selectionEnabled ? tableThead : tableTheadTdSticky, tableHiddenColumnMd)}>
                  {t('totalExVat', 'Total (excl. VAT)')}
                </td>
                <td className={classNames(tableTheadTd, selectionEnabled ? tableThead : tableTheadTdSticky, tableHiddenColumnMd)}>
                  {t('status', 'Status')}
                </td>
                <td className={classNames(tableTheadTd, selectionEnabled ? tableThead : tableTheadTdSticky, tableHiddenColumnSm)}>
                  {t('paid', 'Paid')}
                </td>
                <td className='w-10 md:hidden' />
              </tr>
            </thead>
            <tbody className={tableTbody}>
              {(invoices ?? []).map(invoice => {
                const contact = contacts?.find(cont => cont.uid === invoice.customer_uid);
                return (
                  <tr
                    className={tableTbodyTr}
                    key={invoice.uid}
                    onClick={() => {
                      if (!selectionEnabled) {
                        navigate(invoice.uid);
                      } else {
                        if (isAlreadySentToBookkeeping(invoice)) {
                          // No need to select. We've already sent this invoice to bookkeeping.
                          return;
                        } else if (!canSelect(invoice)) {
                          // We do not send concept invoices.
                          triggerAddToSelectionError(t('make-selection-bookkeeping-hint-non-concept', 'Concepts cannot be sent'));
                          return;
                        }
                        if (selectedInvoices.includes(invoice.uid)) {
                          setSelectedInvoices(selectedInvoices.filter(uid => uid !== invoice.uid));
                        } else {
                          setSelectedInvoices([...selectedInvoices, invoice.uid]);
                        }
                      }
                    }}
                  >
                    <td className={classNames('text-center w-10', tableHiddenColumnMd)}>
                      {selectionEnabled ? (
                        <>
                          {isAlreadySentToBookkeeping(invoice) ? (
                            <Checks size={24} weight='bold' className='inline text-green-700' />
                          ) : (
                            <input
                              type='checkbox'
                              className={classNames({ 'opacity-50': !canSelect(invoice) })}
                              checked={selectedInvoices.includes(invoice.uid)}
                              onChange={() => {
                                // The onChange method should be defined. Otherwise we get console errors.
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <InvoiceIcon size={24} weight='light' className='inline' />
                      )}
                    </td>
                    <td className={tableHiddenColumnMd}>{invoice.date && formatDate(new Date(Date.parse(invoice.date)))}</td>
                    <td className={tableHiddenColumnMd}>
                      <InvoiceNumber invoice={invoice} />
                    </td>
                    <td className={tableHiddenColumnMd}>{contact ? contactName(contact) : '-'}</td>
                    <td className={tableHiddenColumnMd}>{parseAndFormatMoney(invoice.total, invoice.currency)}</td>
                    <td className={tableHiddenColumnMd}>{invoice.draft ? t('in-draft', 'In draft') : t('finalized', 'Finalized')}</td>
                    <td className={tableHiddenColumnSm}>
                      <Badge color={PaymentStatusToColor(invoice.payment_status)}>{PaymentStatusToString(t, invoice.payment_status)}</Badge>
                    </td>
                    <td className={classNames('md:hidden')}>
                      <div className='flex items-center px-2 gap-2'>
                        {selectionEnabled && (
                          <input
                            type='checkbox'
                            checked={selectedInvoices.includes(invoice.uid)}
                            onChange={() => {
                              // The onChange method should be defined. Otherwise we get console errors.
                            }}
                          />
                        )}
                        <div className='grow flex flex-col'>
                          <span className='line-clamp-1'>{contact ? contactName(contact) : '-'}</span>
                          <span className='text-xs'>
                            {invoice.draft && (
                              <Badge size={BadgeSize.Small} color={AllColors.Stone}>
                                {t('invoice-concept', 'Concept')}
                              </Badge>
                            )}
                            {!invoice.draft && (
                              <span>
                                {invoice.invoice_no} - {invoice.date && formatDate(new Date(Date.parse(invoice.date)))}
                              </span>
                            )}
                          </span>
                        </div>
                        <div className='flex flex-col items-end gap-0.5'>
                          {parseAndFormatMoney(invoice.total, invoice.currency)}
                          <Badge size={BadgeSize.Small} color={PaymentStatusToColor(invoice.payment_status)}>
                            {PaymentStatusToString(t, invoice.payment_status)}
                          </Badge>
                        </div>
                        <CaretRight size={22} weight='light' className='inline' />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Tile>
      </PullScrollWrapper>
      <SendToBookkeepingModal
        invoiceUids={selectedInvoices}
        bookkeepingProviders={bookkeepingProviders}
        open={sendBookkeepingModalIsVisible}
        onRequestClose={reload => {
          closeSendBookkeepingModal();
          setSelectedInvoices([]);
          if (reload) {
            loadApiData();
          }
        }}
      />
    </Page>
  );
}

import React, { useMemo } from 'react';
import { Contact } from 'openapi';
import { contactAddress, contactName } from 'utilities/Contact';
import { useTranslation } from 'react-i18next';
import Button from 'ui/Button';
import { Pencil, Warning } from '@phosphor-icons/react';
import UseCountries from 'hooks/UseCountries';

interface Props {
  contact: Contact;
  onEditAddress?: (contact: Contact) => void; // Shows edit button when defined
}

export default function ContactAddress({ contact, onEditAddress }: Props): JSX.Element {
  const { t } = useTranslation();
  const { countries } = UseCountries();

  const warningMessage = useMemo((): string | undefined => {
    if (!contact.address_line1 && !contact.address_line2 && !contact.address_line3) {
      return t('contact-no-address', 'No address set');
    }
    if (!contact.postcode || !contact.city) {
      return t('contact-no-postcode-or-city', 'No postcode or city set');
    }
    if (!contact.country) {
      return t('contact-no-country', 'No country is set');
    }
    return undefined;
  }, [t, contact]);

  return (
    <div className='border p-2 rounded-lg bg-gray-50 flex flex-row items-start'>
      <div className='grow flex flex-col items-start text-sm'>
        {warningMessage && (
          <div className='mb-2 flex flex-row gap-1 items-center'>
            <Warning className='h-5 w-5 text-amber-500' />
            <p className='text-amber-500'>{warningMessage}</p>
          </div>
        )}
        <p>{contactName(contact)}</p>
        {contactAddress(contact, countries).map((line, i) => (
          <p key={i}>{line}</p>
        ))}
      </div>
      {onEditAddress && (
        <Button
          onClick={e => {
            e.preventDefault();
            onEditAddress(contact);
          }}
          icon={<Pencil />}
        >
          {t('edit', 'Edit')}
        </Button>
      )}
    </div>
  );
}

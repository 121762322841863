import { Contact } from 'openapi';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { ErrorSection } from 'ui/Error';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import useFormError from 'api/hooks/useFormError';
import { useForm } from 'react-hook-form';
import { transformEmptyToUndefined } from 'utilities/zod';
import { SelectInput } from 'ui/Inputs';
import { contactName } from 'utilities/Contact';
import { ButtonVariant } from 'ui/Button';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';

interface Props {
  authorizedStables: Contact[];
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onSubmit: (stableUid: string) => void;
}

interface FormModel {
  stableUid: string;
}

function AskStableUidModal({ isVisible, onRequestCloseModal, authorizedStables, onSubmit }: Props): JSX.Element {
  // Form validation
  const schema = useMemo(() => {
    return z.object({
      stableUid: z.string(),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    clearErrors,
    reset,
  } = useForm<FormModel>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const { t } = useTranslation();

  /**
   * Submit the form.
   */
  const submit = async (data: FormModel) => {
    onSubmit(data.stableUid);
  };

  /**
   * Reset the form and clear errors when the modal is closed.
   */
  const onClosed = () => {
    clearErrors();
    setApiError(undefined);
    reset();
  };

  /**
   * build the options for the select input
   */
  const locationsOptions = useMemo((): OptionItemInterface[] => {
    return authorizedStables
      .filter(location => location.stable_location_uid)
      .map(location => ({
        id: location.stable_location_uid,
        name: location.business_name ? location.business_name : contactName(location) ?? '',
      }));
  }, [authorizedStables]);

  return (
    <PageModal
      open={isVisible}
      width={PageModalWidth.Sm}
      parentElement='form'
      parentProps={{ id: 'saveStableUid', noValidate: true, onSubmit: handleSubmit(submit) }}
      onClosed={onClosed}
    >
      <PageModalTitle
        title={t('rvo-sync-location-selection', 'Choose a location to import the horse locations.')}
        onClose={onRequestCloseModal}
      />
      <PageModalContent>
        {nonFieldErrors && <ErrorSection className='mb-4' errors={nonFieldErrors} />}

        <p className='mb-4'>
          {t(
            'rvo-import-horse-missing-stable',
            'In order to map the newly created horses to the correct location, we need to know the stable. Please select the stable below.',
          )}
        </p>
        <SelectInput
          label={t('stable', 'Stable')}
          nullable={true}
          required={true}
          options={locationsOptions}
          error={fieldError('stableUid')}
          nullableValue=''
          {...register('stableUid', { setValueAs: transformEmptyToUndefined() })}
        />
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            loading: isSubmitting,
            variant: ButtonVariant.Primary,
            text: t('submit', 'Submit'),
            type: 'submit',
            formId: 'saveStableUid',
          },
        ]}
      />
    </PageModal>
  );
}

export default AskStableUidModal;

import { zodResolver } from '@hookform/resolvers/zod';
import { Pencil } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useAccount } from 'context/AccountContext';
import UseCountries from 'hooks/UseCountries';
import { Account, AccountService, PatchedAccount } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { SelectInput, TextInput } from 'ui/Inputs';
import { Tile } from 'ui/Layout/Tile';
import TileDescriptionList from 'ui/Layout/Tile/TileDescriptionList';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { objectDiff } from 'utilities/compareObject';
import { stringArrayToString } from 'utilities/string.utility';

function AddressForm(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const { accountDetails, setAccountDetails } = useAccount();
  const { countries, countryById } = UseCountries();

  const schema = useMemo(() => {
    return schemas.Account.omit({
      color: true,
      not_searchable: true,
      preferred_organisation: true,
      preferred_horse_group: true,
      language: true,
      last_activity: true,
      disable_help_popup_portal: true,
      disable_help_popup_app: true,
      avatar_file: true,
      date_joined: true,
      username: true,
      agreed_to_terms_and_privacy: true,
      email: true,
      lookup: true,
      uid: true,
      first_name: true,
      last_name: true,
      date_of_birth: true,
      title: true,
      phone_number: true,
      initials: true,
    });
  }, []);

  const { showModal, closeModal, modalIsVisible } = useModal();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm<Account>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: accountDetails,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Close event for the modal
   */
  const onClose = (resetForm = true) => {
    if (resetForm) {
      reset(accountDetails);
    }
    // clear the errors
    clearErrors();
    setApiError(undefined);
    closeModal();
  };

  /**
   * Submit event handler, update the data via the API for this user
   */
  const onSubmit: SubmitHandler<PatchedAccount> = async (data: PatchedAccount) => {
    if (!accountDetails) return;

    // generate a diff of the form data and the profile
    // so we can send only the changed data
    const updatedFields = objectDiff<Account>(accountDetails, data as Account);

    setLoading(true);

    try {
      setAccountDetails(await AccountService.apiV5AccountPartialUpdate({ requestBody: updatedFields }));
      onClose(false); // close the modal
    } catch (error) {
      setApiError(new ApiErrorParser<PatchedAccount>(error));
    }

    setLoading(false);
  };

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo(() => {
    return [
      {
        onClick: showModal,
        text: t('edit', 'Edit'),
        buttonVariant: ButtonVariant.Default,
        icon: <Pencil />,
      },
    ];
  }, [showModal, t]);

  return (
    <Tile title={t('address', 'Address')} loading={!accountDetails} actions={tileActions}>
      <TileDescriptionList
        list={[
          {
            term: t('street-name', 'Street name'),
            definition: stringArrayToString([accountDetails?.address_line1, accountDetails?.address_line3], ' '),
          },
          { term: t('address-line2', 'Address line 2'), definition: accountDetails?.address_line2 || '' },
          { term: t('postcode', 'Postcode'), definition: accountDetails?.postcode || '' },
          { term: t('city', 'City'), definition: accountDetails?.city || '' },
          { term: t('state', 'State'), definition: accountDetails?.state || '' },
          { term: t('country', 'Country'), definition: accountDetails?.country ? countryById(accountDetails?.country)?.name : '-' },
        ]}
      />

      <PageModal
        open={modalIsVisible}
        parentElement='form'
        parentProps={{ id: 'updateAccount', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={t('update-address', 'Update address')} onClose={onClose} />
        <PageModalContent>
          <ErrorSection errors={nonFieldErrors} />
          <div className='space-y-4'>
            <div className='flex gap-x-4'>
              <TextInput
                className='grow'
                required={false}
                label={t('street', 'Street')}
                error={fieldError('address_line1')}
                {...register('address_line1')}
              />
              <TextInput
                className='w-1/3'
                error={fieldError('address_line3')}
                required={false}
                label={t('address-line3', 'House number')}
                {...register('address_line3')}
              />
            </div>
            <TextInput
              error={fieldError('address_line2')}
              required={false}
              label={t('address-line2', 'Address line 2')}
              {...register('address_line2')}
            />
            <TextInput error={fieldError('city')} label={t('city', 'City')} {...register('city')} />
            <TextInput
              required={false}
              error={fieldError('state')}
              label={t('state-or-province', 'State or province')}
              {...register('state')}
            />
            <TextInput error={fieldError('postcode')} required={false} label={t('postcode', 'Postcode')} {...register('postcode')} />
            <SelectInput
              required={true}
              error={fieldError('country')}
              options={countries}
              label={t('country', 'Country')}
              {...register('country')}
            />
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              onClick: onClose,
              variant: ButtonVariant.Default,
              type: 'button',
              text: t('cancel', 'Cancel'),
            },
            {
              formId: 'updateAccount',
              loading: loading,
              variant: ButtonVariant.Primary,
              type: 'submit',
              text: t('save', 'Save'),
            },
          ]}
        />
      </PageModal>
    </Tile>
  );
}

export default AddressForm;

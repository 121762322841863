import { Plus } from '@phosphor-icons/react';
import { ListFilterType } from 'components/Common/ListFilter';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import CreateContactModal from 'components/Contacts/CreateContactModal';
import { useOrganization } from 'context/OrganizationContext';
import { PageAction } from 'context/PageContext';
import { Contact, ModulePermissionsEnum } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultApiPageSize } from 'ui/Const';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import ListFilterButton from '../../components/Common/ListFilter/ListFilterButton';
import useListFilter from '../../components/Common/ListFilter/useListFilter';
import ContactList from '../../components/Contacts/ContactList';
import Page from '../../ui/Layout/Page';
import { ContactType, getContactType, listFilter, textFilter } from '../../utilities/Contact';
import { ButtonVariant } from 'ui/Button';
import { Tile } from 'ui/Layout/Tile';
import useModal from 'ui/Modals/UseModal';
import { ApiPromises } from 'utilities/ApiPromises';
import { activeContacts } from 'utilities/ApiRequests';
import usePermissions from 'hooks/UsePermissions';

export default function ContactsList(): JSX.Element {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>('');
  const { selectedOrganization, generateCacheKey } = useOrganization();
  const [contacts, setContacts] = useState<Contact[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const [itemsVisible, setItemsVisible] = useState<number>(defaultApiPageSize);

  const { closeModal: closeSaveContactModal, modalIsVisible: saveContactModalIsVisible, showModal: showSaveContactModal } = useModal();
  const { hasPermission } = usePermissions();

  // Filter out the contacts that we don't need in this list.
  const filteredContacts = useMemo(() => {
    return (
      contacts
        // we only interest in that are a business or personal contact and not a myExternalLocation contact
        // myExternalLocation is managed in the manage locations page
        ?.filter(contact => ![ContactType.Stable, ContactType.User].includes(getContactType(contact)) && !contact.external_location)
        // also only get the contacts that are not a user or an invitation
        .filter(contact => contact.user_uid === null && contact.invitation === null)
    );
  }, [contacts]);

  const filterTypes = useMemo((): ListFilterType[] => {
    if (!filteredContacts) {
      return [];
    }
    let businessCount = 0;
    let personalCount = 0;

    filteredContacts.forEach(contact => {
      if (ContactType.Business === getContactType(contact)) {
        businessCount++;
      } else {
        personalCount++;
      }
    });

    const typeFilter: ListFilterType = {
      id: 'type',
      name: t('type', 'Type'),
      options: [
        { id: 'business', name: t('business', 'Business'), count: businessCount },
        { id: 'personal', name: t('personal', 'Personal'), count: personalCount },
      ],
    };

    // todo: Add roles filter

    return [typeFilter];
  }, [t, filteredContacts]);

  const { filters } = useListFilter(filterTypes ?? []);

  // We don't show the full list directly. Make use of the fetch more strategy of PullToRefresh.
  const visibleContacts = useMemo((): { contacts: Contact[]; canFetchMore: boolean } => {
    if (!filteredContacts) {
      return { contacts: [], canFetchMore: false };
    }
    const all = textFilter(listFilter(filteredContacts ?? [], filters), searchText);
    return { contacts: all.slice(0, itemsVisible), canFetchMore: itemsVisible < all.length };
  }, [filteredContacts, itemsVisible, filters, searchText]);

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganization) {
      return promises;
    }
    setItemsVisible(defaultApiPageSize);
    promises.appendListObj<Contact>('contacts', setContacts, activeContacts(selectedOrganization.uid, generateCacheKey));

    setApiPromises(promises);
    return promises;
  }, [selectedOrganization, generateCacheKey]);

  /**
   * Invoke function when a contact has been saved
   */
  const onSavedContact = useCallback((savedContact: Contact) => {
    setContacts(prevState => {
      // there are no items in the list, just return this one
      if (!prevState || prevState.length === 0) {
        return [savedContact];
      }

      let updated = false;
      const newState = prevState.map(con => {
        // update the contact, by replace the current object with the new one
        if (con.uid === savedContact.uid) {
          updated = true;
          return savedContact;
        }

        return con;
      });

      // If not updated, push it to the array
      if (!updated) {
        newState.push(savedContact);
      }

      return newState;
    });
  }, []);

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  const actions = useMemo((): PageAction[] | undefined => {
    if (hasPermission(ModulePermissionsEnum.MANAGE_CONTACTS)) {
      return [
        {
          icon: <Plus />,
          isMobileAddAction: true,
          buttonVariant: ButtonVariant.Primary,
          text: t('create-new', 'Create new'),
          onClick: showSaveContactModal,
        },
      ];
    }
  }, [hasPermission, showSaveContactModal, t]);

  return (
    <Page title={t('contacts', 'Contacts')} loading={apiPromises} actions={actions} showEmptyListPlaceholder={contacts?.length === 0}>
      <PullScrollWrapper
        onRefresh={() => loadApiData().watchAll()}
        canFetchMore={visibleContacts.canFetchMore}
        onFetchMore={() => {
          setItemsVisible(itemsVisible + defaultApiPageSize);
          return Promise.resolve();
        }}
      >
        <Tile noBoxOnMobile={true}>
          {filterTypes && (
            <FilterWrapper listFilterTypes={filterTypes}>
              <ListFilterButton listFilterTypes={filterTypes} />
              <input
                type='search'
                onChange={e => setSearchText(e.currentTarget.value)}
                size={10}
                placeholder={t('search-by-name-email', 'Search by name or email...')}
                className='placeholder:italic placeholder:text-sm px-2 max-w-md grow h-10 rounded-md border'
              />
            </FilterWrapper>
          )}
          <ContactList contacts={visibleContacts.contacts} />
        </Tile>
      </PullScrollWrapper>
      <CreateContactModal onSaved={onSavedContact} isVisible={saveContactModalIsVisible} onRequestCloseModal={closeSaveContactModal} />
    </Page>
  );
}

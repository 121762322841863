import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { HorseOwner, HorseownersService } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  onDeleted: () => void;
  horseOwner: HorseOwner;
}

function DeleteHorseOwnerModal({ isVisible, onRequestClose, horseOwner, onDeleted }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorParser<HorseOwner> | undefined>();
  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const removeHorseOwner = async () => {
    if (!selectedOrganization) return;
    setSubmitting(true);

    try {
      const promise = HorseownersService.horseownersDestroy({
        horseOrganisationUid: selectedOrganization.uid,
        uid: horseOwner.uid,
      });
      await promise;

      // fire the onDeleted callback
      onDeleted();

      // close the modal
      onRequestClose();
    } catch (error) {
      setApiError(new ApiErrorParser<HorseOwner>(error));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ActionModal
      open={isVisible}
      onClose={onRequestClose}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: onRequestClose,
        },
        {
          text: t('delete', 'Delete'),
          loading: submitting,
          variant: ButtonVariant.Danger,
          onClick: removeHorseOwner,
        },
      ]}
      title={t('delete-horse-owner', 'Delete horse owner')}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2  w-full'>{t('delete-horse-owner-desc', 'Are you sure you want to remove this owner?')}</p>
    </ActionModal>
  );
}

export default DeleteHorseOwnerModal;

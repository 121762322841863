import { useCallback, useEffect, useState } from 'react';

export enum SelectionType {
  Folders = 'folders',
  Files = 'files',
  FoldersAndFiles = 'files / folders',
}

interface Props {
  onFilesSelected: (files: File[]) => void;
}

// Extract all `File`s form the request (FileList).
const fromInput = (files: FileList | null): File[] => {
  const processedFiles: File[] = [];
  if (files) {
    for (const file of files) {
      processedFiles.push(new File([file], file.webkitRelativePath || file.name));
    }
  }

  return processedFiles;
};

export default function useFileSelect({ onFilesSelected }: Props): {
  availableSelectionTypes: SelectionType[];
  openDialog: (selectionType: SelectionType, multiple: boolean, allowedFileTypes: string[]) => void;
} {
  const [availableSelectionTypes, setAvailableSelectionTypes] = useState<SelectionType[]>([]);

  useEffect(() => {
    /* eslint-disable */
    // prettier-ignore
    // @ts-ignore
    // Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    /* eslint-enable */

    if (isSafari) {
      // In Safari it's possible to select files and folders in the same dialog.
      setAvailableSelectionTypes([SelectionType.FoldersAndFiles]);
    } else {
      setAvailableSelectionTypes([SelectionType.Files, SelectionType.Folders]);
    }
  }, []);

  /**
   * Opens a file dialog to select files and/or folders. Will call the
   * onFilesSelected callback when files have been selected.
   *
   * @note We tried to make openDialog result a promise but there is not stable
   * way to detect if the file dialog is cancelled. See:
   * https://stackoverflow.com/questions/34855400/cancel-event-on-input-type-file
   */
  const openDialog = useCallback(
    (selectionType: SelectionType, multiple: boolean, allowedFileTypes: string[]) => {
      const idSuffix =
        selectionType === SelectionType.Files ? 'Files' : selectionType === SelectionType.Folders ? 'Folders' : 'FilesAndFolders';
      const multipleSuffix = multiple ? 'Multiple' : 'Single';

      // remove old element if exists
      // this will keep the onFilesSelected() method clean for rerender
      // otherwise it could not trigger a rerender as the old/previous onFilesSelected method is still applied to the element
      const currentElem = document.getElementById(`UseFileSelect${multipleSuffix}${idSuffix}`);
      if (currentElem) {
        currentElem.remove();
      }

      const element = document.createElement('input');
      element.type = 'file';
      element.id = `UseFileSelect${multipleSuffix}${idSuffix}`;
      element.hidden = true;
      element.multiple = multiple;
      if (allowedFileTypes) {
        element.accept = allowedFileTypes.join(',');
      }
      if (selectionType === SelectionType.Folders || selectionType === SelectionType.FoldersAndFiles) {
        element.webkitdirectory = true;
      }
      element.onchange = () => {
        if (element !== null && element.files) {
          const files = fromInput(element.files);

          // fire the callback
          onFilesSelected(files);
        }
      };
      document.body.appendChild(element);

      element.click();
    },
    [onFilesSelected],
  );

  return { availableSelectionTypes, openDialog };
}

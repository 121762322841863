/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `1` - arrival
 * * `2` - departure
 * * `3` - import
 * * `4` - born
 * * `5` - dead
 * * `6` - born dead
 * * `7` - export
 */
export enum ReportTypeEnum {
  '_1' = 1,
  '_2' = 2,
  '_3' = 3,
  '_4' = 4,
  '_5' = 5,
  '_6' = 6,
  '_7' = 7,
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HorseTelexListReponse } from '../models/HorseTelexListReponse';
import type { MicrosoftGroup } from '../models/MicrosoftGroup';
import type { PatchedMicrosoftActiveDirectoryOrganisation } from '../models/PatchedMicrosoftActiveDirectoryOrganisation';
import type { SearchHorseTelex } from '../models/SearchHorseTelex';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ExternalIntegrationsService {
  /**
   * The detail view has many sub-urls, for example {organisation_uid}/horses/, {organisation_uid}/stables/
   * @returns MicrosoftGroup
   * @throws ApiError
   */
  public static microsoftGroupsRetrieve({
    uid,
  }: {
    uid: string,
  }): CancelablePromise<MicrosoftGroup> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{uid}/microsoft/groups',
      path: {
        'uid': uid,
      },
    });
  }
  /**
   * The detail view has many sub-urls, for example {organisation_uid}/horses/, {organisation_uid}/stables/
   * @returns any No response body
   * @throws ApiError
   */
  public static microsoftSyncGroupMembersCreate({
    uid,
  }: {
    uid: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{uid}/microsoft/sync_group_members',
      path: {
        'uid': uid,
      },
    });
  }
  /**
   * The detail view has many sub-urls, for example {organisation_uid}/horses/, {organisation_uid}/stables/
   * @returns any No response body
   * @throws ApiError
   */
  public static setMicrosoftGroupIdPartialUpdate({
    uid,
    requestBody,
  }: {
    uid: string,
    requestBody?: PatchedMicrosoftActiveDirectoryOrganisation,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{uid}/set_microsoft_group_id',
      path: {
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * POSTS a request to Horsetelex to search for a horse.
   * @returns HorseTelexListReponse
   * @throws ApiError
   */
  public static apiV5SearchHorsetelexCreate({
    requestBody,
  }: {
    requestBody?: SearchHorseTelex,
  }): CancelablePromise<HorseTelexListReponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/search_horsetelex/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Contact } from '../models/Contact';
import type { MergeContacts } from '../models/MergeContacts';
import type { PaginatedContactList } from '../models/PaginatedContactList';
import type { PatchedContact } from '../models/PatchedContact';
import type { PatchedMergeContacts } from '../models/PatchedMergeContacts';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ContactsService {
  /**
   * @returns PaginatedContactList
   * @throws ApiError
   */
  public static contactsList({
    organisationUid,
    hidden,
    isSource,
    isStable,
    isUser,
    o,
    page,
    pageSize,
    userUid,
  }: {
    organisationUid: string,
    hidden?: boolean,
    isSource?: boolean,
    isStable?: boolean,
    isUser?: boolean,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    userUid?: string,
  }): CancelablePromise<PaginatedContactList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/contacts',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'hidden': hidden,
        'is_source': isSource,
        'is_stable': isStable,
        'is_user': isUser,
        'o': o,
        'page': page,
        'page_size': pageSize,
        'user__uid': userUid,
      },
    });
  }
  /**
   * @returns Contact
   * @throws ApiError
   */
  public static contactsCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody?: Contact,
  }): CancelablePromise<Contact> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/contacts',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns Contact
   * @throws ApiError
   */
  public static contactsRetrieve({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<Contact> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/contacts/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * @returns Contact
   * @throws ApiError
   */
  public static contactsUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: Contact,
  }): CancelablePromise<Contact> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/contacts/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns Contact
   * @throws ApiError
   */
  public static contactsPartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedContact,
  }): CancelablePromise<Contact> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/contacts/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public static contactsDestroy({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{organisation__uid}/contacts/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * Sets up authorization for doing reports to RvO.
   * UBN, postcode, address_line1, addres_line3, city, country should be given on the contact.
   * @returns any No response body
   * @throws ApiError
   */
  public static contactsAuthorizeRvoCreate({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/contacts/{uid}/authorize_rvo',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public static contactsAuthorizeRvoDestroy({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{organisation__uid}/contacts/{uid}/authorize_rvo',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * Checks the authorization by RvO of a UBN (from a contact).
   * @returns any No response body
   * @throws ApiError
   */
  public static contactsCheckAuthorizationRvoCreate({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/contacts/{uid}/check_authorization_rvo',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * Merge another contact into this resource and optionally update its fields. The other contact will be deleted.
   *
   * All the relations of the `merge_with_uid` contact - the 'alias' will be updated and reference this contact.
   * E.g. an invoice referencing the alias will reference this resource hereafter. This resource will keep its own
   * fields when set and get alias' values when empty, but set on alias. This can be overridden by supplying field
   * names and values like in a regular PATCH request.
   *
   * **NOTE** If the alias cannot be deleted (e.g. because the contact is the default stable contact), but this
   * resource can, then the roles are reversed: `merge_with_uid` is kept and this contact is deleted.
   * @returns MergeContacts
   * @throws ApiError
   */
  public static contactsMergeAndUpdatePartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedMergeContacts,
  }): CancelablePromise<MergeContacts> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/contacts/{uid}/merge_and_update',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}

import { IconContext } from '@phosphor-icons/react';
import classNames from 'classnames';
import React from 'react';
import { MenuItemConfig } from '../Layout';
import MenuItem from './MenuItem';

interface Props {
  menuOpened: boolean;
  topMenu: MenuItemConfig[];
  bottomMenu: MenuItemConfig[];
  setOpen: (open: boolean) => void;
}

export default function MenuSection({ menuOpened, topMenu, bottomMenu, setOpen }: Props): JSX.Element {
  return (
    <IconContext.Provider
      value={{
        size: 25,
        weight: 'light',
      }}
    >
      <div className={classNames('flex-1 flex flex-col pb-1', { 'overflow-y-auto': menuOpened })} id={'menu-items-wrapper'}>
        <div className='grow'>
          {topMenu
            .filter(menu => menu.isEnabled)
            .map((config, key) => (
              <MenuItem setOpen={setOpen} menuOpened={menuOpened} {...config} key={key} menuLocation='top' />
            ))}
        </div>
        <div>
          {bottomMenu
            .filter(menu => menu.isEnabled)
            .map((config, key) => (
              <MenuItem setOpen={setOpen} menuOpened={menuOpened} {...config} key={key} menuLocation='bottom' />
            ))}
        </div>
      </div>
    </IconContext.Provider>
  );
}

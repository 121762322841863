import React, { useMemo } from 'react';
import { usePlanning } from 'hooks/UsePlanning';
import { ActivityHeight, DayGrid, DayGridScale } from './DayGrid';
import { DayGridHeader } from './DayGridHeader';
import { DayNowIndicator } from './DayNowIndicator';
import useRefreshingNow from 'hooks/UseRefreshingNow';
import { CalendarView, DayParts, DragDropType, GroupBy, groupByAppliedGetName, TimeScale } from 'utilities/Planning';
import { isSameDay } from 'date-fns';
import classNames from 'classnames';
import { ActivityContainerTimeScale } from './ActivityContainerTimeScale';
import ActivityContainerDayParts from './ActivityContainerDayParts';
import { useTranslation } from 'react-i18next';

export interface Props {
  columnHeaderWidth: number;
  calendarView: CalendarView;
}

/** Header for the single day layout. Where calendar clusters are placed on the x-axis */
export function DayViewGroupByHeader({ columnHeaderWidth, calendarView }: Props): JSX.Element {
  const { now } = useRefreshingNow();
  const { t } = useTranslation();

  const day = calendarView?.current.days[0];
  if (!day) {
    return <></>;
  }
  const isToday = isSameDay(day, now);

  return (
    <div className='flex'>
      <div className='shrink flex justify-stretch'>
        <DayGridHeader columnHeaderWidth={columnHeaderWidth} className='border-r pb-1 grow' highlight={isToday} day={day} />
      </div>
      <div className='grow flex'>
        {calendarView.current.clusters.map(cluster => {
          return (
            <div key={cluster.id} className='select-none w-full flex flex-col items-center justify-end text-gray-600 h-full pb-1'>
              <p
                style={{ writingMode: calendarView.current.clusters.length > 10 ? 'vertical-rl' : 'inherit' }}
                className={classNames('text-sm break-all max-h-24 line-clamp-1')}
              >
                {groupByAppliedGetName(t, cluster.groupBy)}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );

  return <></>;
}

export interface BodyProps {
  columnHeaderWidth: number;
  calendarView: CalendarView;
}

/**
 * A single day layout. It places the calendar clusters (horse, staff, etc) on the x-axis and time on the y-axis
 */
export function DayBody({ columnHeaderWidth, calendarView }: BodyProps): JSX.Element {
  const { timeScale, dayParts } = usePlanning();
  const { now } = useRefreshingNow();

  /** Calculate the height of each day part. This differs for each view type.
   * For time scale view we divide into one hour blocks.
   * For day parts view we divide into day parts.
   * For full day view we only have one.
   * For dayparts/fullday, each height is adapted to the amount of activities (and leave one extra block for the blueprint).
   */
  const dayPartHeights = useMemo((): number[] => {
    const compartment = calendarView.current.clusters[0];
    if (!compartment) {
      return [];
    }
    if (timeScale === TimeScale.TimeScale) {
      return Array(DayParts.Hour).fill(ActivityHeight);
    }
    if (timeScale === TimeScale.DayParts) {
      const heights: number[] = Array(dayParts?.length).fill(ActivityHeight);
      heights.splice(
        0,
        calendarView.current.maxActivitiesInADayPart.length,
        ...calendarView.current.maxActivitiesInADayPart.map(count => (count + 1) * ActivityHeight),
      );
      return heights;
    }
    if (timeScale === TimeScale.FullDay) {
      return [calendarView.current.maxActivitiesInADay * ActivityHeight + ActivityHeight];
    }
    return [];
  }, [timeScale, calendarView, dayParts]);

  const day = calendarView?.current.days[0];
  if (!day) {
    return <></>;
  }

  return (
    <div className='flex'>
      <div
        style={{
          width: columnHeaderWidth,
          height: dayPartHeights.reduce((partialSum, height) => partialSum + height),
        }}
        className='flex h-full justify-end border-r'
      >
        {timeScale !== TimeScale.FullDay && (
          <>
            <DayGridScale
              className={`h-full border-b`}
              heights={dayPartHeights}
              dayParts={timeScale === TimeScale.DayParts ? dayParts : undefined}
            />
            <DayGrid className={`h-full w-2`} heights={dayPartHeights} />
          </>
        )}
      </div>
      {calendarView.current.clusters.map(compartment => (
        <div className='h-full grow' key={compartment.groupBy.subject?.uid}>
          <DayGrid className='h-full w-full border-r' heights={dayPartHeights}>
            {timeScale === TimeScale.TimeScale && (
              <>
                <DayNowIndicator heights={dayPartHeights} day={day} now={now} className='absolute inset-0' />
                <ActivityContainerTimeScale
                  dragDropType={DragDropType.Disabled}
                  heights={dayPartHeights}
                  day={day}
                  className='absolute inset-0'
                  activities={compartment.columns[0].activities}
                  appliedGroupBy={compartment.groupBy}
                  showContactAvatar={compartment.groupBy.groupBy === GroupBy.Horse}
                  showHorseName={compartment.groupBy.groupBy === GroupBy.Staff}
                />
              </>
            )}
            {timeScale !== TimeScale.TimeScale && (
              <ActivityContainerDayParts
                spacious={false}
                dragDropType={DragDropType.DayPart}
                heights={dayPartHeights}
                day={day}
                className='absolute inset-0'
                activities={compartment.columns[0].activities}
                appliedGroupBy={compartment.groupBy}
                showContactAvatar={compartment.groupBy.groupBy === GroupBy.Horse}
                showHorseName={compartment.groupBy.groupBy === GroupBy.Staff}
              />
            )}
          </DayGrid>
        </div>
      ))}
    </div>
  );
}

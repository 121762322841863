import { Check, Spinner } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useOrganization } from 'context/OrganizationContext';
import { Contact, ContactsService, ProviderEnum } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AvatarInitials, AvatarSize } from 'ui/Avatar';
import Badge from 'ui/Badge';
import Button from 'ui/Button';
import { table, tableTbody, tableTbodyTrNoClick, tableTheadTd, tableTheadTdSticky } from 'ui/Const';
import { PageModal } from 'ui/Modals';
import { PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { ApiPromises } from 'utilities/ApiPromises';
import { AllColors } from 'utilities/colors';
import { contactInitials, contactName, ContactType, getContactType } from 'utilities/Contact';
import UnlinkBookkeepingIntegrationModal from './UnlinkBookkeepingIntegrationModal';
import useApiPromises from 'api/hooks/useApiPromises';
import { SpinnerSize } from 'ui/Loading/Spinner';

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  integration: ProviderEnum.EXACTNL | ProviderEnum.MONEYBIRD;
}

function ContactSyncStatus({ isVisible, onRequestClose, integration }: Props): JSX.Element {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [selectedContact, setSelectedContact] = useState<Contact>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { loading } = useApiPromises({ apiPromises });
  const { closeModal: closeExactModal, modalIsVisible: exactModalIsVisible, showModal: showExactModal } = useModal();
  const { closeModal: closeMoneybirdModal, modalIsVisible: moneybirdModalIsVisible, showModal: showMoneybirdModal } = useModal();

  const { t } = useTranslation();
  const { selectedOrganization, generateCacheKey } = useOrganization();

  const integrationName = integration === ProviderEnum.EXACTNL ? 'Exact Online' : 'Moneybird';

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    if (!selectedOrganization) {
      return promises;
    }

    // Load all contacts, including the removed ones.
    promises.appendList<Contact>(
      'contacts',
      () =>
        ContactsService.contactsList({
          organisationUid: selectedOrganization.uid,
        }),
      setContacts,
      generateCacheKey('contacts'),
    );

    setApiPromises(promises);
    return promises;
  }, [selectedOrganization, generateCacheKey]);

  /**
   * We only need the users and contacts from the list of contacts
   */
  const filteredContacts = useMemo(() => {
    return contacts.filter(contact => [ContactType.User, ContactType.Contact].includes(getContactType(contact)));
  }, [contacts]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  return (
    <PageModal open={isVisible}>
      <PageModalTitle
        title={t('integration-sync-status-contacts', '{{integrationName}} sync status contacts', { integrationName })}
        onClose={onRequestClose}
      />
      <PageModalContent>
        {loading && (
          <div className='flex items-center gap-2 mb-5'>
            <Spinner size={SpinnerSize.XSmall} />
            <span className='text-sm'>{t('loading', 'Loading')}...</span>
          </div>
        )}

        {!loading && (
          <table className={table}>
            <thead>
              <tr>
                <td className={classNames('w-12 md:w-14', tableTheadTdSticky)} />
                <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('name', 'Name')}</td>
                <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('synced', 'Synced')}</td>
                <td className={classNames('w-10', tableTheadTdSticky)} />
              </tr>
            </thead>
            <tbody className={tableTbody}>
              {filteredContacts.map(contact => {
                return (
                  <tr className={tableTbodyTrNoClick} key={contact.uid}>
                    <td className='text-center w-12 md:w-14'>
                      {[ContactType.User, ContactType.Contact].includes(getContactType(contact)) && (
                        <AvatarInitials size={AvatarSize.Small} initials={contactInitials(contact)} uuid={contact.uid} />
                      )}
                    </td>
                    <td>
                      <span className='mr-2'>{contactName(contact)}</span>
                      {getContactType(contact) === ContactType.Business && (
                        <Badge color={AllColors.Amber}>{t('business', 'Business')}</Badge>
                      )}
                    </td>
                    <td>
                      {integration === ProviderEnum.EXACTNL && <>{contact.exactnl_contact_id !== '' ? <Check /> : '-'}</>}
                      {integration === ProviderEnum.MONEYBIRD && <>{contact?.moneybird_contact_id ? <Check /> : '-'}</>}
                    </td>
                    <td className={classNames('w-10 text-center')}>
                      {(contact.exactnl_contact_id !== '' || contact?.moneybird_contact_id) && (
                        <Button
                          onClick={() => {
                            setSelectedContact(contact);
                            if (integration === ProviderEnum.EXACTNL) {
                              showExactModal();
                            } else if (integration === ProviderEnum.MONEYBIRD) {
                              showMoneybirdModal();
                            }
                          }}
                        >
                          {t('disconnect', 'Disconnect')}
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        <UnlinkBookkeepingIntegrationModal
          unlinkIntegration={ProviderEnum.EXACTNL}
          onUnlinked={loadApiData}
          contact={selectedContact}
          isVisible={exactModalIsVisible}
          onRequestClose={closeExactModal}
        />

        <UnlinkBookkeepingIntegrationModal
          unlinkIntegration={ProviderEnum.MONEYBIRD}
          onUnlinked={loadApiData}
          contact={selectedContact}
          isVisible={moneybirdModalIsVisible}
          onRequestClose={closeMoneybirdModal}
        />
      </PageModalContent>
    </PageModal>
  );
}

export default ContactSyncStatus;

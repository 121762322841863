import { TFunction } from 'i18next';
import { writeXLSX, utils } from 'xlsx';
import { saveAs } from 'file-saver';
import { age, FilterHorse, gender } from 'utilities/Horse';
import { Contact, HorseGroup } from 'openapi';
import { getColorFromId } from 'utilities/string.utility';

const horseGroupName = (horseGroups: HorseGroup[], horseGroupUid?: string) => {
  if (!horseGroupUid) return '-';
  if (!horseGroups) return '';
  return horseGroups.find(group => group.uid === horseGroupUid)?.name ?? '<unknown>';
};

const stableName = (contacts: Contact[], stableUid?: string) => {
  if (!stableUid) return '-';
  if (!contacts) return '';
  return contacts.find(contact => contact.stable_location_uid === stableUid)?.business_name ?? '<unknown>';
};

const horseData = (t: TFunction, horses: FilterHorse[], horseGroups: HorseGroup[], contacts: Contact[]): string[][] => {
  return [
    [
      t('name', 'Name'),
      t('status', 'Status'),
      t('gender', 'Gender'),
      t('age', 'Age'),
      t('date-of-birth', 'Date of birth'),
      t('current-location', 'Current location'),
      t('date-of-arrival', 'Date of arrival'),
      t('stable', 'Stable'),
      t('color', 'Color'),
      t('UELN', 'UELN'),
      t('chip-nr', 'Chip number'),
      t('group', 'Group'),
      t('nick-name', 'Nick name'),
      t('sire', 'Sire'),
      t('dam', 'Dam'),
    ],
    ...horses.map((filterHorse: FilterHorse): string[] => {
      const horse = filterHorse.horse;
      const horseAge = age(horse);
      const birthDate = horse.date_of_birth ? new Date(Date.parse(horse.date_of_birth)) : undefined;
      const dateOfArrival = horse.current_location.arrival_date ? new Date(Date.parse(horse.current_location.arrival_date)) : undefined;
      return [
        horse.name,
        horse.hidden ? t('inactive', 'Inactive') : t('active', 'Active'),
        horse.sex ? gender(horse.sex, t) : '-',
        horseAge ? horseAge.toString() : '-',
        birthDate ? `${birthDate.getFullYear()}-${birthDate.getMonth()}-${birthDate.getDay()}` : '-',
        horse.current_location.name ?? '-',
        dateOfArrival ? `${dateOfArrival.getFullYear()}-${dateOfArrival.getMonth()}-${dateOfArrival.getDay()}` : '-',
        stableName(contacts, horse.stable_uid ?? undefined),
        horse.color ? getColorFromId(horse.color, t) : '-',
        horse.UELN ?? '-',
        horse.chip_nr ?? '-',
        horseGroupName(horseGroups, horse.group_uid),
        horse.nickname ?? '-',
        horse.sire ?? '-',
        horse.dam ?? '-',
      ];
    }),
  ];
};

// Export a horse list to xlsx and download it.
export const xlsxHorsesDownload = (t: TFunction, horses: FilterHorse[], horseGroups: HorseGroup[], contacts: Contact[]): void => {
  const workBook = utils.book_new();
  const workSheetInFilter = utils.aoa_to_sheet(
    horseData(
      t,
      horses.filter(horse => !horse.foundOutsideFilter),
      horseGroups,
      contacts,
    ),
  );
  const workSheetOutFilter = utils.aoa_to_sheet(
    horseData(
      t,
      horses.filter(horse => horse.foundOutsideFilter),
      horseGroups,
      contacts,
    ),
  );
  utils.book_append_sheet(workBook, workSheetInFilter, t('horses', 'Horses'));
  utils.book_append_sheet(workBook, workSheetOutFilter, t('horses-outside-filter', 'Horses outside filter'));
  const bytes = writeXLSX(workBook, { bookType: 'xlsx', type: 'buffer' }) as ArrayBuffer;
  saveAs(new Blob([bytes]), 'horses.xlsx');
};

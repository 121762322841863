/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JWT } from '../models/JWT';
import type { SocialLogin } from '../models/SocialLogin';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ApiService {
  /**
   * For example (for testing):
   * https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?response_type=code&client_id=cf028e96-8736-4aaa-bb45-1977d4e24634&redirect_uri=https://app.equinem.com/microsoft/login/callback&scope=User.ReadBasic.All+openid+offline_access&code_challenge_method:S256&code_challenge=nA04yKkP7OWo5OimF8PT-cELJZ5TmjC4W_85JYY4aCQ
   *
   *
   * Code challenge can be generated here: https://example-app.com/pkce
   * @returns JWT
   * @throws ApiError
   */
  public static apiV5OauthUserMicrosoftLoginCreate({
    requestBody,
  }: {
    requestBody?: SocialLogin,
  }): CancelablePromise<JWT> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/oauthUser/microsoft/login',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Bank` - Bank
 * * `Cash` - Cash
 * * `Mollie` - Mollie
 * * `PayPal` - PayPal
 * * `Pin` - Pin
 * * `CreditCard` - CreditCard
 */
export enum PaymentTypeEnum {
  BANK = 'Bank',
  CASH = 'Cash',
  MOLLIE = 'Mollie',
  PAY_PAL = 'PayPal',
  PIN = 'Pin',
  CREDIT_CARD = 'CreditCard',
}

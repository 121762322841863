import { ArrowSquareOut, DotsThreeVertical, Horse as HorseIcon, Plus, Truck } from '@phosphor-icons/react';
import { PencilSimple } from '@phosphor-icons/react/dist/ssr';
import { cachedPaginatedApiData } from 'api/ApiCache';
import ApiErrorParser from 'api/ApiErrorParser';
import classNames from 'classnames';
import SaveProductModal from 'components/Breeding/SaveProductModal';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { usePage } from 'context/PageContext';
import { CancelablePromise, CategoriesService, Category, Horse, HorsesService, ShippingServiceTypeEnum } from 'openapi';
import { PaginatedHorseList } from 'openapi/models/PaginatedHorseList';
import { PaginatedProductList } from 'openapi/models/PaginatedProductList';
import { Product } from 'openapi/models/Product';
import { SexEnum } from 'openapi/models/SexEnum';
import { ProductsService } from 'openapi/services/ProductsService';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'ui/Badge';
import { ButtonVariant } from 'ui/Button';
import { table, tableTbody, tableTbodyTrNoClick } from 'ui/Const';
import { Tile } from 'ui/Layout/Tile';
import { AllColors } from 'utilities/colors';
import Page, { PageMaxWidth } from '../../ui/Layout/Page';
import { TFunction } from 'i18next';
import DropdownMenu from 'ui/DropdownMenu';
import { ActionModal } from 'ui/Modals';
import { ErrorSection } from 'ui/Error';
import { useConfig } from 'context/ConfigContext';
import ShopTermsTile from 'components/Organization/Tiles/ShopTermsTile';
import SaveTrustedCountriesModal from 'components/Breeding/SaveTrustedCountriesModal';
import useModal from 'ui/Modals/UseModal';
import UseCountries from 'hooks/UseCountries';

/**
 * The status of a stallion in the catalogue. This basically tells us that the
 * stallion has an active breeding product or not.
 */
const enum CatalogueStatus {
  Inactive,
  Active,
  Future,
  ActiveAndFuture,
}

const catalogueStatusForProduct = (product: Product): CatalogueStatus => {
  if (product.current_price) {
    if (product.future_price) {
      return CatalogueStatus.ActiveAndFuture;
    } else {
      return CatalogueStatus.Active;
    }
  } else {
    if (product.future_price) {
      return CatalogueStatus.Future;
    } else {
      return CatalogueStatus.Inactive;
    }
  }
};
interface BreedingPriceModel {
  productCategory: Category;
  edit?: Product;
  modalOpen: boolean;
}

const shippingServiceTypeName = (t: TFunction, type?: ShippingServiceTypeEnum): string => {
  if (!type) {
    return '';
  }
  switch (type) {
    case ShippingServiceTypeEnum.REGULAR:
      return t('shipping-type-regular', 'Regular');
    case ShippingServiceTypeEnum.PICK_UP:
      return t('shipping-type-pick-up', 'Pick up');
    case ShippingServiceTypeEnum.SAME_DAY_DELIVERY:
      return t('shipping-type-same-day-delivery', 'Same day delivery');
    case ShippingServiceTypeEnum.NEXT_DAY_DELIVERY:
      return t('shipping-type-next-day-delivery', 'Next day delivery');
    case ShippingServiceTypeEnum.SUNDAY_HOLIDAY_DELIVERY:
      return t('shipping-type-sunday-holiday-delivery', 'Sunday and holiday delivery');
  }
};

// TODO: to avoid uneeded re-renders, we should split this component into smaller components e.g. a component per tile

export default function BreedingSettingsPage(): JSX.Element {
  const { t } = useTranslation();
  const [priceModalConf, setPriceModalConf] = useState<BreedingPriceModel | undefined>();
  const [horses, setHorses] = useState<Horse[]>();
  const [products, setProducts] = useState<Product[]>();
  const [financialCategories, setFinancialCategories] = useState<Category[]>();
  const { formatMoney } = useAccount();
  const { selectedOrganizationUid, selectedOrganizationDetails, generateCacheKey, refresh } = useOrganization();
  const { setApiError } = usePage();
  const [showRemoveProductDialog, setShowRemoveProductDialog] = useState<Product | undefined>();
  const [removeProductDialogError, setRemoveProductDialogError] = useState<ApiErrorParser<void> | undefined>();
  const { config } = useConfig();
  const { countryById } = UseCountries();

  const {
    showModal: showSaveTrustedCountriesModal,
    closeModal: closeSaveTrustedCountriesModal,
    modalIsVisible: saveTrustedCountriesModalIsVisible,
  } = useModal();

  const formatPrice = useCallback(
    (product: Product): string => {
      const amount = Number(product.current_price);
      if (isNaN(amount) || !product.current_price_currency) {
        return '-';
      }
      return formatMoney(amount, product.current_price_currency);
    },
    [formatMoney],
  );

  const myStallions = useMemo((): Horse[] => {
    if (!horses) return [];
    return horses.filter(horse => horse.sex === SexEnum._1);
  }, [horses]);

  const breedingProductCategory = useMemo((): Category | undefined => {
    return financialCategories?.find(cat => cat.default === 'BREEDING');
  }, [financialCategories]);

  const shippingProductCategory = useMemo((): Category | undefined => {
    return financialCategories?.find(cat => cat.default === 'SHIPPING');
  }, [financialCategories]);

  const breedingProducts = useMemo((): Product[] => {
    if (!products) return [];
    const result: Product[] = [];
    if (!breedingProductCategory) {
      console.error('Breeding product category not found');
      return [];
    }
    myStallions.forEach(stallion => {
      const productsForStallion = products.filter(
        product => product.stallion === stallion.uid && product.category === breedingProductCategory.uid,
      );
      if (productsForStallion.length === 0) return;
      if (productsForStallion.length > 1) {
        console.error('Multiple breeding prices for horse is not supported.');
      }
      result.push(productsForStallion[0]);
    });
    return result;
  }, [myStallions, products, breedingProductCategory]);

  const shippingProducts = useMemo((): Product[] | undefined => {
    if (!products) return undefined;
    if (!shippingProductCategory) {
      console.error('Shipping product category not found');
      return [];
    }
    return products.filter(product => product.category === shippingProductCategory.uid);
  }, [products, shippingProductCategory]);

  const webshopUrl = useMemo((): string => {
    if (!selectedOrganizationDetails) {
      return '';
    }
    return config?.getWebshopUrl(selectedOrganizationDetails.public_access_uuid) ?? '';
  }, [selectedOrganizationDetails, config]);

  const myStallionsWithoutProduct = useMemo((): Horse[] => {
    return myStallions.filter(stallion => breedingProducts.findIndex(prod => prod.stallion === stallion.uid) === -1);
  }, [myStallions, breedingProducts]);

  const catalogueStatusToText = useCallback(
    (status: CatalogueStatus) => {
      // status.
      switch (status) {
        case CatalogueStatus.Inactive:
          return t('status-inactive', 'Inactive');
        case CatalogueStatus.Active:
        case CatalogueStatus.ActiveAndFuture:
          return t('status-active', 'Active');
        case CatalogueStatus.Future:
          return t('status-future', 'Not yet active');
        default:
          console.error('Unknown catalogue status to string');
          return status;
      }
    },
    [t],
  );

  const loadProducts = useCallback((): CancelablePromise<PaginatedProductList> => {
    const promise = ProductsService.productsList({ organisationUid: selectedOrganizationUid ?? '' });

    promise
      .then(res => setProducts(res.results))
      .catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<Product[]>(e));
        }
      });
    return promise;
  }, [selectedOrganizationUid, setApiError]);

  const loadFinancialCategories = useCallback((): CancelablePromise<Category[]> => {
    const promise = CategoriesService.categoriesList({ organisationUid: selectedOrganizationUid ?? '', o: 'p_s_type,name' });

    promise
      .then(res => setFinancialCategories(res))
      .catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<Category[]>(e));
        }
      });
    return promise;
  }, [selectedOrganizationUid, setApiError]);

  // Load the horses from api and/or cache
  const loadHorses = useCallback(
    (disableCache: boolean): CancelablePromise<PaginatedHorseList> => {
      const promise = HorsesService.horsesList({
        organisationUid: selectedOrganizationUid ?? '',
        onUnknownLocation: false,
      });
      promise
        .then(() => setApiError(undefined))
        .catch(e => {
          if (!promise.isCancelled) {
            setApiError(new ApiErrorParser<Horse[]>(e), horses === undefined);
          }
        });
      cachedPaginatedApiData<Horse>(generateCacheKey('horses'), promise, setHorses, disableCache);
      return promise;
    },
    [selectedOrganizationUid, horses, generateCacheKey, setApiError],
  );

  const onAddedProduct = useCallback(
    (product: Product) => {
      if (products) {
        setProducts([...products, product]);
      } else {
        setProducts([product]);
      }
    },
    [products],
  );

  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadFinancialCategories();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadProducts();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  // Load the horses
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadHorses(false);
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  const trustedCountriesActions = useMemo(() => {
    return [
      {
        onClick: showSaveTrustedCountriesModal,
        buttonVariant: ButtonVariant.Default,
        icon: <PencilSimple />,
        text: t('edit', 'Edit'),
      },
    ];
  }, [t, showSaveTrustedCountriesModal]);

  return (
    <Page title={t('settings', 'Settings')} loading={!products} maxWidth={PageMaxWidth.Tile}>
      <div className='space-y-4'>
        <Tile
          title={t('breeding-stallions', 'Breeding stallions')}
          actions={[
            {
              onClick: () => {
                if (!breedingProductCategory) {
                  console.error('Breeding product category not found');
                  return;
                }
                setPriceModalConf({ productCategory: breedingProductCategory, edit: undefined, modalOpen: true });
              },
              buttonVariant: ButtonVariant.Default,
              icon: <Plus />,
              text: t('add-stallion', 'Add stallion'),
            },
          ]}
        >
          <p>
            {t('breeding-price-desc-1', 'Active stallions in this list are displayed to your customers through the private')}{' '}
            <a className='text-blue-500 inline-flex items-center gap-1 mt-1' href={webshopUrl} rel='noreferrer' target='_blank'>
              {t('webshop', 'Webshop')} <ArrowSquareOut />
            </a>
            {` ${t('the-webshop-of', 'of')} ${selectedOrganizationDetails?.name ?? '---'}.`}
          </p>
          <table className={table}>
            <tbody className={tableTbody}>
              {(breedingProducts ?? []).map(product => {
                const stallion = myStallions.find(horse => horse.uid === product.stallion);
                const status = catalogueStatusForProduct(product);
                return (
                  <tr className={tableTbodyTrNoClick} key={product.uid}>
                    <td className='text-center w-10'>
                      <HorseIcon size={22} weight='light' className='inline' />
                    </td>
                    <td>
                      {stallion?.name}{' '}
                      <Badge
                        color={
                          status === CatalogueStatus.Active || status === CatalogueStatus.ActiveAndFuture
                            ? AllColors.Green
                            : status === CatalogueStatus.Future
                              ? AllColors.Yellow
                              : AllColors.Red
                        }
                      >
                        {catalogueStatusToText(status)}
                      </Badge>
                    </td>
                    <td className={classNames({ 'line-through text-gray-500': status === CatalogueStatus.Inactive })}>
                      {formatPrice(product)}
                    </td>
                    <td className='w-14 pr-2'>
                      <DropdownMenu
                        menuPlacement='bottom-end'
                        menuItems={[
                          [
                            {
                              element: t('edit', 'Edit'),
                              onClick: () => {
                                if (!breedingProductCategory) {
                                  console.error('Breeding product category not found');
                                  return;
                                }
                                setPriceModalConf({ productCategory: breedingProductCategory, edit: product, modalOpen: true });
                              },
                            },
                            {
                              element: t('remove', 'Remove'),
                              onClick: () => setShowRemoveProductDialog(product),
                              className: 'text-red-600',
                            },
                          ],
                        ]}
                      >
                        <div className='w-full h-full text-center cursor-pointer pt-2 pb-1'>
                          <DotsThreeVertical size={24} weight='bold' className='inline' />
                        </div>
                      </DropdownMenu>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Tile>
        <Tile
          title={t('shipping', 'Shipping')}
          actions={[
            {
              onClick: () => {
                if (!shippingProductCategory) {
                  console.error('Shipping product category not found');
                  return;
                }
                setPriceModalConf({ productCategory: shippingProductCategory, edit: undefined, modalOpen: true });
              },
              buttonVariant: ButtonVariant.Default,
              icon: <Plus />,
              text: t('add', 'Add'),
            },
          ]}
        >
          <p>{t('shipping-info', 'Please configure here what kind of delivery (or pickup) services you offer.')}</p>
          <table className={table}>
            <tbody className={tableTbody}>
              {(shippingProducts ?? []).map(product => (
                <tr className={tableTbodyTrNoClick} key={product.uid}>
                  <td className='text-center w-10'>
                    <Truck size={22} weight='light' className='inline' />
                  </td>
                  <td>{product.shipping_provider_name}</td>
                  <td>{shippingServiceTypeName(t, product.shipping_service_type)}</td>
                  <td>{formatPrice(product)}</td>
                  <td className='w-14 pr-2'>
                    <DropdownMenu
                      menuPlacement='bottom-end'
                      menuItems={[
                        [
                          {
                            element: t('edit', 'Edit'),
                            onClick: () => {
                              if (!shippingProductCategory) {
                                console.error('Shipping product category not found');
                                return;
                              }
                              setPriceModalConf({ productCategory: shippingProductCategory, edit: product, modalOpen: true });
                            },
                          },
                          { element: t('remove', 'Remove'), onClick: () => setShowRemoveProductDialog(product), className: 'text-red-600' },
                        ],
                      ]}
                    >
                      <div className='w-full h-full text-center cursor-pointer pt-2 pb-1'>
                        <DotsThreeVertical size={24} weight='bold' className='inline' />
                      </div>
                    </DropdownMenu>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Tile>
        <ShopTermsTile />
        <Tile title={t('trusted-countries', 'Trusted countries')} actions={trustedCountriesActions}>
          <p className='pb-2'>
            {t(
              'trusted-countries-info',
              'Orders that are shipped to the following countries only need to pay the product down payment price upfront and can later pay the residual (by invoice).',
            )}
          </p>
          <div className='flex gap-1 flex-wrap'>
            {(selectedOrganizationDetails?.down_payment_permitted_countries ?? []).slice(0, 20).map(countryCode => (
              <Badge color={AllColors.Indigo} key={countryCode}>
                {countryById(countryCode)?.name}
              </Badge>
            ))}
            {(selectedOrganizationDetails?.down_payment_permitted_countries?.length ?? 0) > 20 && (
              <span>
                {t('trusted-countries-more', 'and {{count}} more.', {
                  count: (selectedOrganizationDetails?.down_payment_permitted_countries.length ?? 0) - 20,
                })}
              </span>
            )}
            {(selectedOrganizationDetails?.down_payment_permitted_countries?.length ?? 0) === 0 && (
              <span className='italic'>
                {t('no-trusted-countries-selected', 'No countries selected, all countries pay the full price upfront.')}
              </span>
            )}
          </div>
        </Tile>
        <SaveProductModal
          onSaved={onAddedProduct}
          stallions={myStallionsWithoutProduct}
          visible={priceModalConf?.modalOpen === true}
          category={priceModalConf?.productCategory}
          existingProduct={priceModalConf?.edit}
          closeModal={() => {
            if (!breedingProductCategory) {
              console.error('Breeding product category not found');
              return;
            }
            setPriceModalConf({ productCategory: breedingProductCategory, modalOpen: false, edit: priceModalConf?.edit });
            loadProducts();
          }}
        />
        <SaveTrustedCountriesModal
          visible={saveTrustedCountriesModalIsVisible}
          onRequestCloseModal={closeSaveTrustedCountriesModal}
          onSaved={refresh}
        />
        <ActionModal
          open={showRemoveProductDialog !== undefined}
          actions={[
            {
              text: t('cancel', 'Cancel'),
              variant: ButtonVariant.Default,
              onClick: () => setShowRemoveProductDialog(undefined),
            },
            {
              text: t('remove', 'Remove'),
              variant: ButtonVariant.PrimaryDanger,
              onClick: async () => {
                const promise = ProductsService.productsDestroy({
                  organisationUid: selectedOrganizationUid ?? '',
                  uid: showRemoveProductDialog?.uid ?? '',
                });

                try {
                  await promise;

                  // reload the products so it reflects the changes
                  await loadProducts();
                  setShowRemoveProductDialog(undefined);
                } catch (e) {
                  setRemoveProductDialogError(new ApiErrorParser(e));
                }
              },
            },
          ]}
          title={t('remove-product-confirm-title', 'Remove product')}
        >
          <>
            <ErrorSection errors={removeProductDialogError} />
            <p>{t('remove-product-confirm-text', 'Are you sure you want to remove this product?')}</p>
          </>
        </ActionModal>
      </div>
    </Page>
  );
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `1` - stallion
 * * `2` - mare
 * * `3` - gelding
 */
export enum SexEnum {
  '_1' = 1,
  '_2' = 2,
  '_3' = 3,
}

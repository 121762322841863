import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import ContactInputSelect from 'components/Contacts/ContactInputSelect';
import { useOrganization } from 'context/OrganizationContext';
import { HorseDetail, HorseOwner, HorseownersService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { DateInput, TextInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { zodInputIsRequired } from 'utilities/zod';

interface Props {
  horse: HorseDetail;
  horseOwner?: HorseOwner;
  isVisibile: boolean;
  onRequestClose: () => void;
  onSaved: (savedHorseOwner: HorseOwner) => void;
}

function SaveHorseOwnerModal({ isVisibile, onRequestClose, horse, horseOwner, onSaved }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();

  // Form validation
  const schema = useMemo(() => {
    return schemas.HorseOwner.omit({
      uid: true,
      horse_uid: true,
    });
  }, []);

  const defaultValues = useMemo((): Partial<HorseOwner> => {
    if (horseOwner) {
      return {
        contact_uid: horseOwner.contact_uid,
        percentage: horseOwner.percentage,
        owner_since: horseOwner.owner_since,
      };
    }

    return {
      // set the default as of today
      owner_since: new Date().toISOString().substr(0, 10),
    };
  }, [horseOwner]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    setValue,
    control,
  } = useForm<HorseOwner>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Function invoked after the modal has been closed
   */
  const onClosed = () => {
    // clear the errors
    clearErrors();
    setApiError(undefined);
    reset();
  };

  /**
   * Define the page title based on the selected horseOwner
   */
  const pageTitle = useMemo(() => {
    return horseOwner ? t('edit-horse-owner', 'Edit horse owner') : t('add-horse-owner', 'Add horse owner');
  }, [horseOwner, t]);

  /**
   * Submit event handler, update the data via the API for this user
   */
  const onSubmit: SubmitHandler<HorseOwner> = async (data: HorseOwner) => {
    if (!selectedOrganization) return;
    setSubmitting(true);

    try {
      // include the horse uid in the request body
      const requestBody = { ...data, horse_uid: horse.uid };

      let savedHorseOwner: HorseOwner;

      // update if we deal with an existing horse owner, otherwise create a new one
      if (horseOwner) {
        const promise = HorseownersService.horseownersUpdate({
          horseOrganisationUid: selectedOrganization.uid,
          uid: horseOwner.uid,
          requestBody,
        });
        savedHorseOwner = await promise;
      } else {
        const promise = HorseownersService.horseownersCreate({
          horseOrganisationUid: selectedOrganization.uid,
          requestBody,
        });
        savedHorseOwner = await promise;
      }

      // fire the onSaved event
      onSaved(savedHorseOwner);

      // close the modal
      onRequestClose();
    } catch (error) {
      setApiError(new ApiErrorParser<HorseOwner>(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Reset the form when the default values change
   */
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <PageModal
      open={isVisibile}
      parentElement='form'
      parentProps={{ id: 'createHorseOwner', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      onClosed={onClosed}
    >
      <PageModalTitle title={pageTitle} onClose={onRequestClose} />
      <PageModalContent>
        <ErrorSection className='mb-4' errors={nonFieldErrors} />

        <div className='py-3 space-y-4'>
          <ContactInputSelect
            name='contact_uid'
            control={control}
            contacts={null}
            onCreated={contact => setValue('contact_uid', contact.uid)}
            required={true}
            label={t('contact', 'Contact')}
            error={fieldError('contact_uid')}
          />

          <TextInput
            type='number'
            error={fieldError('percentage')}
            required={zodInputIsRequired<HorseOwner>(schema, 'percentage')}
            label={t('percentage', 'Percentage')}
            {...register('percentage')}
          />

          <DateInput
            control={control}
            required={true}
            label={t('owner-since', 'Owner since')}
            name='owner_since'
            error={fieldError('owner_since')}
          />
        </div>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            disabled: submitting,
            onClick: onRequestClose,
            variant: ButtonVariant.Default,
            type: 'button',
            text: t('cancel', 'Cancel'),
          },
          {
            formId: 'createHorseOwner',
            loading: submitting,
            variant: ButtonVariant.Primary,
            type: 'submit',
            text: t('save', 'Save'),
          },
        ]}
      />
    </PageModal>
  );
}

export default SaveHorseOwnerModal;

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `S` - Normal sale
 * * `AE` - Reverse-charging VAT
 * * `G` - Export
 */
export enum VatCategoryEnum {
  S = 'S',
  AE = 'AE',
  G = 'G',
}

import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { OrganisationService, PatchedOrganisation } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { TextInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { z } from 'zod';

const schema = schemas.Organisation.pick({ exactnl_sales_journal: true });

type SchemaType = z.infer<typeof schema>;

interface Props {
  visible: boolean;
  onRequestClose: () => void;
}

// Component and form to configure the Exact NL bookkeeping integration.
export default function ConfigureExactIntegrationModal({ visible, onRequestClose }: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganizationDetails, refresh } = useOrganization();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      exactnl_sales_journal: selectedOrganizationDetails?.exactnl_sales_journal,
    },
  });
  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const resetToDefaults = useCallback(() => {
    if (!selectedOrganizationDetails) {
      return;
    }
    reset({
      exactnl_sales_journal: selectedOrganizationDetails?.exactnl_sales_journal,
    });
  }, [reset, selectedOrganizationDetails]);

  const onSubmit = async (data: SchemaType) => {
    if (!selectedOrganizationDetails) return;

    try {
      await OrganisationService.rootPartialUpdate({ uid: selectedOrganizationDetails.uid, requestBody: data as PatchedOrganisation });
      // refresh the organization
      refresh();

      // close the modal
      onRequestClose();
    } catch (error) {
      setApiError(new ApiErrorParser<SchemaType>(error));
    }
  };

  // Reset to defaults when the modal opens.
  useEffect(() => {
    if (visible) {
      resetToDefaults();
    }
  }, [visible, resetToDefaults]);

  return (
    <PageModal
      open={visible}
      width={PageModalWidth.Xs}
      onClosed={resetToDefaults}
      parentElement='form'
      parentProps={{ className: 'space-y-8', id: 'Exact', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
    >
      <PageModalTitle title={t('configure-exact-integration', 'Configure Exact integration')} onClose={onRequestClose} />
      <PageModalContent>
        <div className='space-y-4'>
          <ErrorSection errors={nonFieldErrors} />
          <TextInput
            required={true}
            label={t('sales-journal-exact', 'Sales journal Exact ID')}
            {...register('exactnl_sales_journal')}
            error={fieldError('exactnl_sales_journal')}
            hint={t(
              'sales-journal-exact-hint',
              'The Exact identifier of the sales journal for Equinem. When left empty, the journal with the lowest identifier is chosen.',
            )}
          />
        </div>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: onRequestClose,
          },
          {
            text: t('save', 'Save'),
            variant: ButtonVariant.Primary,
            type: 'submit',
            formId: 'Exact',
            loading: isSubmitting,
          },
        ]}
      />
    </PageModal>
  );
}

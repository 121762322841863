/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatchedVATPercentageUpdate } from '../models/PatchedVATPercentageUpdate';
import type { VATPercentage } from '../models/VATPercentage';
import type { VATPercentageUpdate } from '../models/VATPercentageUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class VatpercentagesService {
  /**
   * VAT percentages are stored as relations to ordered products. The set of fields that may be changed is therefore
   * limited; you can't change a percentage, but you can make a new instance. So, whenever a country dictates a
   * different vat percentage, organizations are expected to make new instances that reflect that. A start_date
   * indicating when the respective vat percentage should become active must be included. At that date the previous
   * instance should no longer be applied to new or existing object e.g. new orders.
   *
   * This means an organization can have multiple vat percentages per country and per vat_level. The is_active
   * field will indicate which of them is applicable at the current date. Filtering by `country`, `vat_level` and
   * `is_active` will yield max one result.
   *
   * Note: A product of category 'SHIPPING' requires the existence of a VAT percentage for each of that product's
   * shipping countries. Also, products that can be ordered in general require that their respective product.category
   * has a vat_level for the respective country, and that a VATPercentage exists for that country with that vat_level.
   * This means that you may not delete a VAT percentage that would be needed for one or more or your organization's
   * products.
   * @returns VATPercentage
   * @throws ApiError
   */
  public static vatpercentagesList({
    organisationUid,
    countryIexact,
    isActive,
    o,
    vatCategory,
    vatLevel,
    vatLevelIn,
  }: {
    organisationUid: string,
    countryIexact?: string,
    /**
     * The vat percentage is currently applicable for this country and product category
     */
    isActive?: boolean,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * * `S` - Normal sale
     * * `AE` - Reverse-charging VAT
     * * `G` - Export
     */
    vatCategory?: 'AE' | 'G' | 'S',
    /**
     * * `STANDARD` - standard
     * * `REDUCED` - reduced
     * * `SECOND_REDUCED` - second reduced
     * * `SUPER_REDUCED` - super reduced
     * * `ZERO` - zero
     */
    vatLevel?: 'REDUCED' | 'SECOND_REDUCED' | 'STANDARD' | 'SUPER_REDUCED' | 'ZERO',
    /**
     * Multiple values may be separated by commas.
     */
    vatLevelIn?: Array<string>,
  }): CancelablePromise<Array<VATPercentage>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/vatpercentages',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'country__iexact': countryIexact,
        'is_active': isActive,
        'o': o,
        'vat_category': vatCategory,
        'vat_level': vatLevel,
        'vat_level__in': vatLevelIn,
      },
    });
  }
  /**
   * VAT percentages are stored as relations to ordered products. The set of fields that may be changed is therefore
   * limited; you can't change a percentage, but you can make a new instance. So, whenever a country dictates a
   * different vat percentage, organizations are expected to make new instances that reflect that. A start_date
   * indicating when the respective vat percentage should become active must be included. At that date the previous
   * instance should no longer be applied to new or existing object e.g. new orders.
   *
   * This means an organization can have multiple vat percentages per country and per vat_level. The is_active
   * field will indicate which of them is applicable at the current date. Filtering by `country`, `vat_level` and
   * `is_active` will yield max one result.
   *
   * Note: A product of category 'SHIPPING' requires the existence of a VAT percentage for each of that product's
   * shipping countries. Also, products that can be ordered in general require that their respective product.category
   * has a vat_level for the respective country, and that a VATPercentage exists for that country with that vat_level.
   * This means that you may not delete a VAT percentage that would be needed for one or more or your organization's
   * products.
   * @returns VATPercentage
   * @throws ApiError
   */
  public static vatpercentagesCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody: VATPercentage,
  }): CancelablePromise<VATPercentage> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/vatpercentages',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * VAT percentages are stored as relations to ordered products. The set of fields that may be changed is therefore
   * limited; you can't change a percentage, but you can make a new instance. So, whenever a country dictates a
   * different vat percentage, organizations are expected to make new instances that reflect that. A start_date
   * indicating when the respective vat percentage should become active must be included. At that date the previous
   * instance should no longer be applied to new or existing object e.g. new orders.
   *
   * This means an organization can have multiple vat percentages per country and per vat_level. The is_active
   * field will indicate which of them is applicable at the current date. Filtering by `country`, `vat_level` and
   * `is_active` will yield max one result.
   *
   * Note: A product of category 'SHIPPING' requires the existence of a VAT percentage for each of that product's
   * shipping countries. Also, products that can be ordered in general require that their respective product.category
   * has a vat_level for the respective country, and that a VATPercentage exists for that country with that vat_level.
   * This means that you may not delete a VAT percentage that would be needed for one or more or your organization's
   * products.
   * @returns VATPercentage
   * @throws ApiError
   */
  public static vatpercentagesRetrieve({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<VATPercentage> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/vatpercentages/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * VAT percentages are stored as relations to ordered products. The set of fields that may be changed is therefore
   * limited; you can't change a percentage, but you can make a new instance. So, whenever a country dictates a
   * different vat percentage, organizations are expected to make new instances that reflect that. A start_date
   * indicating when the respective vat percentage should become active must be included. At that date the previous
   * instance should no longer be applied to new or existing object e.g. new orders.
   *
   * This means an organization can have multiple vat percentages per country and per vat_level. The is_active
   * field will indicate which of them is applicable at the current date. Filtering by `country`, `vat_level` and
   * `is_active` will yield max one result.
   *
   * Note: A product of category 'SHIPPING' requires the existence of a VAT percentage for each of that product's
   * shipping countries. Also, products that can be ordered in general require that their respective product.category
   * has a vat_level for the respective country, and that a VATPercentage exists for that country with that vat_level.
   * This means that you may not delete a VAT percentage that would be needed for one or more or your organization's
   * products.
   * @returns VATPercentageUpdate
   * @throws ApiError
   */
  public static vatpercentagesUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: VATPercentageUpdate,
  }): CancelablePromise<VATPercentageUpdate> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/vatpercentages/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * VAT percentages are stored as relations to ordered products. The set of fields that may be changed is therefore
   * limited; you can't change a percentage, but you can make a new instance. So, whenever a country dictates a
   * different vat percentage, organizations are expected to make new instances that reflect that. A start_date
   * indicating when the respective vat percentage should become active must be included. At that date the previous
   * instance should no longer be applied to new or existing object e.g. new orders.
   *
   * This means an organization can have multiple vat percentages per country and per vat_level. The is_active
   * field will indicate which of them is applicable at the current date. Filtering by `country`, `vat_level` and
   * `is_active` will yield max one result.
   *
   * Note: A product of category 'SHIPPING' requires the existence of a VAT percentage for each of that product's
   * shipping countries. Also, products that can be ordered in general require that their respective product.category
   * has a vat_level for the respective country, and that a VATPercentage exists for that country with that vat_level.
   * This means that you may not delete a VAT percentage that would be needed for one or more or your organization's
   * products.
   * @returns VATPercentageUpdate
   * @throws ApiError
   */
  public static vatpercentagesPartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedVATPercentageUpdate,
  }): CancelablePromise<VATPercentageUpdate> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/vatpercentages/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * VAT percentages are stored as relations to ordered products. The set of fields that may be changed is therefore
   * limited; you can't change a percentage, but you can make a new instance. So, whenever a country dictates a
   * different vat percentage, organizations are expected to make new instances that reflect that. A start_date
   * indicating when the respective vat percentage should become active must be included. At that date the previous
   * instance should no longer be applied to new or existing object e.g. new orders.
   *
   * This means an organization can have multiple vat percentages per country and per vat_level. The is_active
   * field will indicate which of them is applicable at the current date. Filtering by `country`, `vat_level` and
   * `is_active` will yield max one result.
   *
   * Note: A product of category 'SHIPPING' requires the existence of a VAT percentage for each of that product's
   * shipping countries. Also, products that can be ordered in general require that their respective product.category
   * has a vat_level for the respective country, and that a VATPercentage exists for that country with that vat_level.
   * This means that you may not delete a VAT percentage that would be needed for one or more or your organization's
   * products.
   * @returns void
   * @throws ApiError
   */
  public static vatpercentagesDestroy({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{organisation__uid}/vatpercentages/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
}

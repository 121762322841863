import classNames from 'classnames';
import { usePlanning } from 'hooks/UsePlanning';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import Calendar from 'context/Calendar';
import { CalendarView, SelectedActivityState } from 'utilities/Planning';

interface Props {
  className?: string;

  // Render a calendar within the view
  render: (calendar: Calendar, availableWidth: number) => ReactNode;

  // The source calendars
  calendarView: CalendarView;
}

enum Direction {
  Forward,
  Backward,
  Still,
}

interface TouchTracking {
  start: number;
  x: number;
  xDiff: number;
  y: number;
  yDiff: number;
}

/**
 * A carousel-like slidable calendar view.
 * This is visible at this part (`x` marks the spot) of the calendar.
 * The view uses a SlidableCalendarView for extra smoothness.
 *
 *                                      ┌──────┌──────┌──────┌──────┌──────┌──────┌──────┐
 *                                      │  12  │  13  │  14  │  15  │  16  │  17  │  18  │
 *                                      │      │      │      │      │      │      │      │
 *                                      ┼──────┼──────┼──────┼──────┼──────┼──────┼──────┼
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 * xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
 *                                      └──────└──────└──────└──────└──────└──────└──────┘
 */
export default function SlidableCalendarView({ className, render, calendarView }: Props): JSX.Element {
  const { offset, setOffset, bluePrint, selectedActivity } = usePlanning();
  const ref = useRef<HTMLDivElement>(null);
  const [canvasWidth, setCanvasWidth] = useState<number>(0);
  const direction = useMemo((): Direction => {
    if (
      calendarView.previousOffset === undefined ||
      calendarView.left === undefined ||
      calendarView.offset === calendarView.previousOffset
    ) {
      return Direction.Still;
    }
    return calendarView.offset > calendarView.previousOffset ? Direction.Forward : Direction.Backward;
  }, [calendarView]);

  const startPosX = useMemo(() => {
    switch (direction) {
      case Direction.Still:
        return canvasWidth * -1;
      case Direction.Backward:
        return canvasWidth * 2 * -1;
      case Direction.Forward:
        return 0;
    }
  }, [direction, canvasWidth]);

  const instanceRef = useRef<TouchTracking | undefined>(undefined);

  const onSwipeLeft = () => setOffset(offset + 1);
  const onSwipeRight = () => setOffset(offset - 1);

  // Keep track of thw width of the div. We can pass this down to the render items so they know the available width of their component.
  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setCanvasWidth(ref.current?.clientWidth ?? 0);
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [calendarView]);

  return (
    <div
      // Have a default w-0 so that the it wants to be as small as it can.
      className={classNames(className, 'overflow-x-hidden w-0')}
      ref={ref}
      onTouchStart={e => {
        if (
          bluePrint ||
          selectedActivity?.selectedActivityState === SelectedActivityState.Edit ||
          selectedActivity?.selectedActivityState === SelectedActivityState.Info
        ) {
          return;
        }
        instanceRef.current = {
          start: Date.now(),
          x: e.touches[0].clientX,
          xDiff: 0,
          y: e.touches[0].clientY,
          yDiff: 0,
        };
      }}
      onTouchMove={e => {
        if (
          bluePrint ||
          selectedActivity?.selectedActivityState === SelectedActivityState.Edit ||
          selectedActivity?.selectedActivityState === SelectedActivityState.Info
        ) {
          return;
        }
        if (!instanceRef.current) {
          return;
        }
        instanceRef.current.xDiff = instanceRef.current.x - e.touches[0].clientX;
        instanceRef.current.yDiff = instanceRef.current.y - e.touches[0].clientY;
      }}
      onTouchEnd={e => {
        if (
          bluePrint ||
          selectedActivity?.selectedActivityState === SelectedActivityState.Edit ||
          selectedActivity?.selectedActivityState === SelectedActivityState.Info
        ) {
          return;
        }
        if (!instanceRef.current) {
          return;
        }
        const minDistance = 20;
        const timeout = 500;
        const maxDistance = Infinity;

        const timeDiff = Date.now() - instanceRef.current.start;
        const xDiffAbs = Math.abs(instanceRef.current.xDiff);
        const yDiffAbs = Math.abs(instanceRef.current.yDiff);

        if (xDiffAbs > yDiffAbs) {
          if (xDiffAbs >= minDistance && xDiffAbs <= maxDistance && timeDiff <= timeout) {
            // Prevent other swipeables
            e.stopPropagation();
            if (instanceRef.current.xDiff > 0) {
              onSwipeLeft();
            } else {
              onSwipeRight();
            }
          }
          // Vertical swipe
        }
      }}
    >
      <div
        key={`${calendarView?.offset}`}
        style={{ transform: `translateX(${startPosX}px)` }}
        className={classNames('flex h-full', {
          // This class is defined in index.css
          'slidable-calendar-view-animate-to-current': direction !== Direction.Still,
        })}
      >
        {canvasWidth !== 0 && calendarView && (
          <>
            {render(calendarView.left, canvasWidth)}
            {render(calendarView.current, canvasWidth)}
            {render(calendarView.right, canvasWidth)}
          </>
        )}
      </div>
    </div>
  );
}

import useScreenSize, { ScreenSize } from './UseScreenSize';

/**
 * Determine if we deal with a mobile device or not
 */
function useIsMobileDevice(): boolean | null {
  const { width } = useScreenSize();

  // TODO if we enabled Capacitor or another tool, we can use the detection check from there
  // if (IS_MOBILE_APP) {
  //   return true;
  // }

  if (width === undefined) {
    return null;
  }

  return width <= ScreenSize.md;
}

export default useIsMobileDevice;

import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { ExternalIntegrationsService, MicrosoftGroup, PatchedMicrosoftActiveDirectoryOrganisation } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { CheckboxInput, SelectInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { transformEmptyToUndefined } from 'utilities/zod';
import { z } from 'zod';

type MicrosoftAd = z.infer<typeof schemas.PatchedMicrosoftActiveDirectoryOrganisation>;

interface Props {
  visible: boolean;
  onRequestClose: () => void;
}

// Component and form to configure the Microsoft SSO configuration.
export default function ConfigureMicrosoftIntegrationModal({ visible, onRequestClose }: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganizationUid, selectedOrganizationDetails, refresh } = useOrganization();
  const [groups, setGroups] = useState<MicrosoftGroup[]>();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<MicrosoftAd>({
    resolver: zodResolver(schemas.PatchedMicrosoftActiveDirectoryOrganisation),
    reValidateMode: 'onChange',
    defaultValues: {
      microsoft_ad_group_id: selectedOrganizationDetails?.microsoft_ad_group_id,
      only_use_ad: selectedOrganizationDetails?.only_use_ad,
    },
  });
  const { fieldError, nonFieldErrors, setApiError } = useFormError(schemas.PatchedMicrosoftActiveDirectoryOrganisation, errors);

  const resetToDefaults = useCallback(() => {
    if (!selectedOrganizationDetails) {
      return;
    }
    reset({
      microsoft_ad_group_id: selectedOrganizationDetails.microsoft_ad_group_id,
      only_use_ad: selectedOrganizationDetails.only_use_ad,
    });
  }, [reset, selectedOrganizationDetails]);

  const onSubmit = async (data: MicrosoftAd) => {
    if (!selectedOrganizationUid) {
      console.error('Cannot submit Microsoft AD settings without a selected organization');
      return;
    }
    try {
      await ExternalIntegrationsService.setMicrosoftGroupIdPartialUpdate({ uid: selectedOrganizationUid, requestBody: data });
      await ExternalIntegrationsService.microsoftSyncGroupMembersCreate({ uid: selectedOrganizationUid });
      setApiError(undefined);
    } catch (e) {
      setApiError(new ApiErrorParser<PatchedMicrosoftActiveDirectoryOrganisation>(e));
      return;
    }
    onRequestClose();
    refresh();
  };

  // When the modal is opened, load the microsoft groups and reset the form to the defaults
  useEffect(() => {
    if (!selectedOrganizationUid || !visible) {
      return;
    }
    setApiError(undefined);
    const promise = ExternalIntegrationsService.microsoftGroupsRetrieve({ uid: selectedOrganizationUid });
    promise
      .then(result => {
        const groups: MicrosoftGroup[] = [];
        for (const [, value] of Object.entries(result)) {
          groups.push(value as unknown as MicrosoftGroup);
        }
        setGroups(groups);
        resetToDefaults();
        setApiError(undefined);
      })
      .catch(e => setApiError(new ApiErrorParser<MicrosoftGroup>(e)));

    return () => promise.cancel();
  }, [visible, selectedOrganizationUid, resetToDefaults, setApiError]);

  return (
    <PageModal
      open={visible}
      width={PageModalWidth.Sm}
      onClosed={resetToDefaults}
      parentElement='form'
      parentProps={{ className: 'space-y-8', id: 'MicrosoftAd', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
    >
      <PageModalTitle title={t('configure-microsoft-integration', 'Configure Microsoft integration')} onClose={onRequestClose} />
      <PageModalContent>
        <div className='space-y-4'>
          <ErrorSection errors={nonFieldErrors} />
          <SelectInput
            label={t('ad-group-with-access', 'Microsoft group with access')}
            nullable={true}
            required={true}
            options={groups?.map(group => ({ id: group.id, name: group.display_name }))}
            error={fieldError('microsoft_ad_group_id')}
            nullableValue=''
            hint={t('ad-group-with-access-hint', 'Please select the Microsoft Group which has access to EquineM.')}
            {...register('microsoft_ad_group_id', { setValueAs: transformEmptyToUndefined() })}
          />
          <CheckboxInput
            {...register('only_use_ad')}
            error={fieldError('only_use_ad')}
            labelElement={t('only-use-ad', 'Only allow users to login using their Microsoft AD account')}
            hint={t(
              'only-use-ad-hint',
              'Existing users (with email address logins) will be prohibited from logging in. The AD user that created the EquineM account will have EquineM administrator permissions.',
            )}
          />
        </div>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: onRequestClose,
          },
          {
            text: t('save', 'Save'),
            variant: ButtonVariant.Primary,
            type: 'submit',
            formId: 'MicrosoftAd',
            loading: isSubmitting,
          },
        ]}
      />
    </PageModal>
  );
}

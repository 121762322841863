import { IconContext } from '@phosphor-icons/react';
import { PageAction } from 'context/PageContext';
import React from 'react';

interface FooterActionProps {
  action: PageAction;
  cutoutStyle: boolean;
}

function FooterActionButton({ action, cutoutStyle }: FooterActionProps): JSX.Element {
  if (cutoutStyle) {
    return (
      <div className='flex h-full'>
        <div className='h-16 w-16 flex flex-col-reverse'>
          <div className='bg-neutral-100 h-12' />
          <div className='h-4 overflow-visible'>
            <div className='-mt-5 h-16 w-16 p-1.5 rounded-full bg-white'>
              <button
                title={action.text}
                className='w-full h-full flex items-center justify-center bg-blue-700 rounded-full text-white'
                key={action.text}
                onClick={action.onClick}
              >
                <IconContext.Provider
                  value={{
                    size: 25,
                  }}
                >
                  {action.icon}
                </IconContext.Provider>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='flex h-full bg-neutral-100'>
      <button
        title={action.text}
        className='-mt-3 h-14 w-14 flex items-center justify-center bg-blue-700 rounded-full text-white'
        key={action.text}
        onClick={action.onClick}
      >
        <IconContext.Provider
          value={{
            size: 25,
          }}
        >
          {action.icon}
        </IconContext.Provider>
      </button>
    </div>
  );
}

export default FooterActionButton;

import { Check } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface CategoryProps {
  step: number;
  totalSteps: number;
  children: React.ReactNode;
  expanded: boolean;
  stepsWithErrors: number[];
}

/**
 * For splitting up forms into multiple steps. This is a wrapper for each section.
 */
export default function Stepper({ step, totalSteps, children, expanded, stepsWithErrors }: CategoryProps): JSX.Element {
  const { t } = useTranslation();

  const headerSteps = useMemo(() => {
    const res: { name: string; current: boolean; step: number }[] = [];
    for (let i = 1; i <= totalSteps; i++) {
      res.push({ step: i, name: t('step', 'Step'), current: step === i });
    }
    return res;
  }, [totalSteps, t, step]);

  return (
    <div>
      {expanded && (
        <>
          <div className='flex my-4'>
            {headerSteps.map(item => (
              <div key={item.step} className={classNames('flex', { grow: item.step !== totalSteps })}>
                <div
                  className={classNames('flex flex-col items-center uppercase text-xs', {
                    'font-bold text-red-500': step === item.step && stepsWithErrors.includes(item.step),
                    'font-bold text-blue-500': step === item.step && !stepsWithErrors.includes(item.step),
                    'font-normal text-red-500': step !== item.step && stepsWithErrors.includes(item.step),
                    'font-normal text-gray-500': step !== item.step && !stepsWithErrors.includes(item.step),
                  })}
                >
                  <div
                    className={classNames('rounded-full text-sm w-7 h-7 flex items-center justify-center mb-1', {
                      'bg-blue-500 text-white border-0': item.step <= step && !stepsWithErrors.includes(item.step),
                      'bg-red-500 text-white border-0': item.step <= step && stepsWithErrors.includes(item.step),
                      'border-gray-300 text-gray-500 border-2': item.step > step && !stepsWithErrors.includes(item.step),
                      'border-red-300 text-red-500 border-2': item.step > step && stepsWithErrors.includes(item.step),
                    })}
                  >
                    {item.step >= step && item.step} {item.step < step && <Check size={18} />}
                  </div>
                  {item.name} {item.step}
                </div>
                {item.step !== totalSteps && (
                  <div
                    className={classNames('mt-3 grow h-0.5', {
                      'bg-blue-500': item.step < step,
                      'bg-gray-300': item.step >= step,
                    })}
                  />
                )}
              </div>
            ))}
          </div>
          <div className='flex flex-col gap-4'>{children}</div>
        </>
      )}
    </div>
  );
}

import { PageAction } from 'context/PageContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'ui/Button';

interface Props {
  title: string;
  addAction?: PageAction;
}

/**
 * A placeholder showing a graphic and an add button
 */
export default function EmptyListPlaceholder({ addAction, title }: Props): JSX.Element {
  const { t } = useTranslation();

  // small helper that creates an array of actual numbers
  const fillArray = (length: number) => Array.from({ length }, (_, index) => index);

  return (
    <div className='flex flex-col items-center mt-8'>
      <div className='shadow-2xl shadow-neutral-400/50 w-48 h-48 md:w-56 md:h-56 rounded-full bg-gradient-to-t from-neutral-200 flex flex-col gap-4 items-center justify-end px-4 md:px-6'>
        {fillArray(3).map(e => (
          <div key={e} className='w-full h-8 rounded border bg-white flex items-center gap-2 px-2'>
            <div className='w-4 h-4 rounded-full bg-neutral-200' />
            <div className='grow flex gap-1 flex-col p-2'>
              <div className='h-1.5 rounded bg-neutral-100' />
              <div className='h-1.5 rounded bg-neutral-100' />
            </div>
          </div>
        ))}
      </div>
      <h1 className='text-xl font-medium mt-6'>{title}</h1>
      {addAction && (
        <>
          <p className='text-gray-500 text-sm mb-6'>{t('empty-list', 'The list of {{title}} is empty.', { title })}</p>
          <Button onClick={addAction.onClick} disabled={addAction.disabled} icon={addAction.icon} variant={addAction.buttonVariant}>
            {addAction.text}
          </Button>
        </>
      )}
    </div>
  );
}

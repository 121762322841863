import { Params } from 'react-router-dom';

/**
 * Replace all placeholders in the given path
 *
 * This is the exact same function as https://reactrouter.com/en/6.21.0/utils/generate-path#generatepath
 * but this function does not trigger an error when there is a mismatch between the params and the placeholders
 * e.g. when in the params object {uid: string} is given, but in the path /:uid/ does not exists, the native
 * generatePath() will trigger an error. This function does not
 *
 * @param path
 * @param params
 * @returns string
 */
export function generatePath(path: string, params: Readonly<Params<string>>): string {
  let parsedPath = path;
  const matches = path.match(/:\w+/g);

  matches?.forEach(match => {
    const paramKey = match.replace(':', '');
    const paramValue = params[paramKey];
    if (params && paramValue !== undefined) {
      parsedPath = path.replace(match, paramValue);
    }
  });

  return parsedPath;
}

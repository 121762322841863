import { Calendar, CaretLeft, CaretRight } from '@phosphor-icons/react';
import { useAccount } from 'context/AccountContext';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import Button from 'ui/Button';
import { isToday } from 'utilities/date.utilities';

interface Props {
  selectDate: Date;
  onPrevDay: () => void;
  onNextDay: () => void;
  onSelectDate: (date: Date) => void;
}

interface ReturnType {
  element: JSX.Element;
}

function useDayNavigationFilter({ selectDate, onSelectDate, onPrevDay, onNextDay }: Props): ReturnType {
  const { formatDate: formatUserDate } = useAccount();
  const { t } = useTranslation();

  const selectedDayString = !isToday(selectDate) ? formatUserDate(selectDate) : t('today', 'Today');

  const element = (
    <div className='w-full flex gap-x-2 justify-between md:justify-normal'>
      <Button onClick={onPrevDay}>
        <CaretLeft />
      </Button>
      <ReactDatePicker
        wrapperClassName='w-2/3 md:w-auto'
        showPopperArrow={false}
        dateFormat='dd/MM/yyyy'
        placeholderText='dd/mm/yyyy'
        onChange={date => {
          const today = new Date();

          if (!date) {
            onSelectDate(today);
            return;
          }
          if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
            // Set to undefined for today
            onSelectDate(today);
          } else {
            onSelectDate(date);
          }
        }}
        customInput={
          <Button className='md:ml-0 w-full' icon={<Calendar />}>
            {selectedDayString}
          </Button>
        }
        // place the datapicker always on top
        popperClassName='!z-10'
        // place the picker in a portal with ID=date-picker-portal
        portalId='date-picker-portal'
        todayButton={t('today', 'Today')}
      />
      <Button onClick={onNextDay}>
        <CaretRight />
      </Button>
    </div>
  );

  return { element };
}

export default useDayNavigationFilter;

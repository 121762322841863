import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import useImageCrop from 'components/Common/ImageCrop/UseImageCrop';
import { useTranslation } from 'react-i18next';
import OptimizedImage from 'ui/OptimizedImage';
import { Camera, IconContext } from '@phosphor-icons/react';

interface Props {
  title: string;
  subTitle?: string;
  avatarUrl?: string;
  icon?: ReactNode;
  onAvatarEditRequest?: (imageData: ArrayBuffer, mimeType: string) => Promise<void>;
}

/**
 * A header component for mobile that shows an avatar, title and subtitle.
 */
export default function IdentityHeader({ title, subTitle, avatarUrl, icon, onAvatarEditRequest }: Props): JSX.Element {
  const { t } = useTranslation();

  // Number between 0 and 1 depending on the scroll position.
  const [scrollState, setScrollState] = useState(0);

  // set a default icon as placeholder in case there is no avatar
  const iconPlaceholder = icon ?? <Camera />;

  const handleScroll = () => {
    const offsetPin = 100; // From which scroll position do we calculate the scroll state.
    setScrollState(Math.min(offsetPin, window.scrollY) / offsetPin);
  };

  // Add (and remove) scroll event listener. This is used to grow and shrink the header based on the scroll state.
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Avatar implementation
  const { imageModal, upload } = useImageCrop({
    modalTitle: t('update-avatar', 'Update avatar'),
    onCropComplete: async (imageData, mimeType) => {
      if (onAvatarEditRequest) {
        return onAvatarEditRequest(imageData, mimeType);
      }
    },
  });

  // Width and height of the avatar based on the scroll state.
  const avatarSize = useMemo(() => {
    // We use pixel dimensions based on the default header height of 56 px (h-14).
    return 46 + 30 * (1 - scrollState);
  }, [scrollState]);

  // Height of the header based on the scroll state.
  const headerHeight = useMemo(() => {
    // We use pixel dimensions based on the default header height of 56 px (h-14).
    return 56 + 50 * (1 - scrollState);
  }, [scrollState]);

  return (
    <div className='pl-12 pr-16' style={{ height: headerHeight }}>
      <div className='flex flex-row gap-3 h-full items-center'>
        <div
          onClick={upload}
          className='rounded-full overflow-hidden relative bg-neutral-400 flex items-center justify-center shrink-0 group'
          style={{ height: avatarSize, width: avatarSize }}
        >
          {avatarUrl && <OptimizedImage className='w-full h-full object-cover' src={avatarUrl} width={64} />}
          {!avatarUrl && (
            <IconContext.Provider
              value={{
                className: 'opacity-40',
                size: 40,
              }}
            >
              {iconPlaceholder}
            </IconContext.Provider>
          )}
        </div>
        <div className='flex flex-col justify-center grow text-white'>
          <p className='text-lg font-medium line-clamp-1 -mb-1.5'>{title}</p>
          <p className='opacity-70 line-clamp-1'>{subTitle}</p>
        </div>
      </div>
      {imageModal}
    </div>
  );
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeclinedInvitation } from '../models/DeclinedInvitation';
import type { PatchedDeclinedInvitation } from '../models/PatchedDeclinedInvitation';
import type { ReceivedInvitation } from '../models/ReceivedInvitation';
import type { SentInvitation } from '../models/SentInvitation';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InvitationsService {
  /**
   * Shows a list of received invitations for logged in users and a detailed received invitation based on the uuid.
   *
   * invitee={pk}, status={pending|declined|accepted}
   * @returns ReceivedInvitation
   * @throws ApiError
   */
  public static apiV5InvitationsList({
    acceptedOnIcontains,
    contact,
    declinedOnIcontains,
    invitee,
    inviter,
    resendOnIcontains,
    sentOnIcontains,
    status,
  }: {
    acceptedOnIcontains?: string,
    contact?: number,
    declinedOnIcontains?: string,
    invitee?: string,
    inviter?: number,
    resendOnIcontains?: string,
    sentOnIcontains?: string,
    /**
     * status
     */
    status?: string,
  }): CancelablePromise<Array<ReceivedInvitation>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/invitations',
      query: {
        'accepted_on__icontains': acceptedOnIcontains,
        'contact': contact,
        'declined_on__icontains': declinedOnIcontains,
        'invitee': invitee,
        'inviter': inviter,
        'resend_on__icontains': resendOnIcontains,
        'sent_on__icontains': sentOnIcontains,
        'status': status,
      },
    });
  }
  /**
   * Shows a list of received invitations for logged in users and a detailed received invitation based on the uuid.
   *
   * invitee={pk}, status={pending|declined|accepted}
   * @returns ReceivedInvitation
   * @throws ApiError
   */
  public static apiV5InvitationsRetrieve({
    uuid,
  }: {
    uuid: string,
  }): CancelablePromise<ReceivedInvitation> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/invitations/{uuid}',
      path: {
        'uuid': uuid,
      },
    });
  }
  /**
   * Accept an invitation (needs to be logged in)
   * @returns any No response body
   * @throws ApiError
   */
  public static apiV5InvitationsAcceptPartialUpdate({
    uuid,
  }: {
    uuid: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/invitations/{uuid}/accept',
      path: {
        'uuid': uuid,
      },
    });
  }
  /**
   * Decline an invitation (needs to be logged in)
   * @returns DeclinedInvitation
   * @throws ApiError
   */
  public static apiV5InvitationsDeclinePartialUpdate({
    uuid,
    requestBody,
  }: {
    uuid: string,
    requestBody?: PatchedDeclinedInvitation,
  }): CancelablePromise<DeclinedInvitation> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/invitations/{uuid}/decline',
      path: {
        'uuid': uuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * General List View which will show the Sent Invitations
   * POST to this view to create a new invitation
   *
   * It is also possible to add filters:
   *
   * invitee={pk}, status={pending|declined|accepted}, send_only={True}, received_only={True}
   * @returns SentInvitation
   * @throws ApiError
   */
  public static invitationsList({
    inviterOrganisationUid,
    acceptedOnIcontains,
    contact,
    declinedOnIcontains,
    invitee,
    inviter,
    resendOnIcontains,
    sentOnIcontains,
    status,
  }: {
    inviterOrganisationUid: string,
    acceptedOnIcontains?: string,
    contact?: number,
    declinedOnIcontains?: string,
    invitee?: string,
    inviter?: number,
    resendOnIcontains?: string,
    sentOnIcontains?: string,
    /**
     * status
     */
    status?: string,
  }): CancelablePromise<Array<SentInvitation>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{inviter_organisation__uid}/invitations',
      path: {
        'inviter_organisation__uid': inviterOrganisationUid,
      },
      query: {
        'accepted_on__icontains': acceptedOnIcontains,
        'contact': contact,
        'declined_on__icontains': declinedOnIcontains,
        'invitee': invitee,
        'inviter': inviter,
        'resend_on__icontains': resendOnIcontains,
        'sent_on__icontains': sentOnIcontains,
        'status': status,
      },
    });
  }
  /**
   * General List View which will show the Sent Invitations
   * POST to this view to create a new invitation
   *
   * It is also possible to add filters:
   *
   * invitee={pk}, status={pending|declined|accepted}, send_only={True}, received_only={True}
   * @returns SentInvitation
   * @throws ApiError
   */
  public static invitationsCreate({
    inviterOrganisationUid,
    requestBody,
  }: {
    inviterOrganisationUid: string,
    requestBody: SentInvitation,
  }): CancelablePromise<SentInvitation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{inviter_organisation__uid}/invitations',
      path: {
        'inviter_organisation__uid': inviterOrganisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * General List View which will show the Sent Invitations
   * POST to this view to create a new invitation
   *
   * It is also possible to add filters:
   *
   * invitee={pk}, status={pending|declined|accepted}, send_only={True}, received_only={True}
   * @returns SentInvitation
   * @throws ApiError
   */
  public static invitationsRetrieve({
    inviterOrganisationUid,
    uuid,
  }: {
    inviterOrganisationUid: string,
    uuid: string,
  }): CancelablePromise<SentInvitation> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{inviter_organisation__uid}/invitations/{uuid}',
      path: {
        'inviter_organisation__uid': inviterOrganisationUid,
        'uuid': uuid,
      },
    });
  }
  /**
   * General List View which will show the Sent Invitations
   * POST to this view to create a new invitation
   *
   * It is also possible to add filters:
   *
   * invitee={pk}, status={pending|declined|accepted}, send_only={True}, received_only={True}
   * @returns void
   * @throws ApiError
   */
  public static invitationsDestroy({
    inviterOrganisationUid,
    uuid,
  }: {
    inviterOrganisationUid: string,
    uuid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{inviter_organisation__uid}/invitations/{uuid}',
      path: {
        'inviter_organisation__uid': inviterOrganisationUid,
        'uuid': uuid,
      },
    });
  }
  /**
   * Try a resend. There should be 1 days in between and a user may resend 2 times.
   * @returns any No response body
   * @throws ApiError
   */
  public static invitationsResendInvitationPartialUpdate({
    inviterOrganisationUid,
    uuid,
  }: {
    inviterOrganisationUid: string,
    uuid: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{inviter_organisation__uid}/invitations/{uuid}/resend_invitation',
      path: {
        'inviter_organisation__uid': inviterOrganisationUid,
        'uuid': uuid,
      },
    });
  }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  @page {
    size: A4 portrait; /* DIN A4 standard, Europe */
    margin: 20mm;
  }
  body {
    width: 210mm;
    background: #fff;
    overflow: visible;
    height: auto;
    font-family: 'Inter';
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/PublicInvoicePrint.css"],"names":[],"mappings":"AAAA;EACE;IACE,iBAAiB,EAAE,4BAA4B;IAC/C,YAAY;EACd;EACA;IACE,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,oBAAoB;EACtB;AACF","sourcesContent":["@media print {\n  @page {\n    size: A4 portrait; /* DIN A4 standard, Europe */\n    margin: 20mm;\n  }\n  body {\n    width: 210mm;\n    background: #fff;\n    overflow: visible;\n    height: auto;\n    font-family: 'Inter';\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import Article from '../../ui/Article';
import { DateInputNative, SelectInput, TextInput } from '../../ui/Inputs';
import { InputSize } from '../../ui/Inputs/TextInput';

function InputShowcase(): JSX.Element {
  return (
    <Article title='Inputs' caption='Inputs are available in various sizes.'>
      <div className='flex gap-6 mb-6'>
        <TextInput placeholder={'Input XSmall'} size={InputSize.XSmall} label={'Input XSmall'} />
        <TextInput placeholder={'Input Small'} size={InputSize.Small} label={'Input Small'} />
        <TextInput placeholder={'Input Normal'} label={'Input Normal'} />
        <TextInput placeholder={'Input Large'} label={'Input Large'} size={InputSize.Large} />
      </div>
      <div className='flex gap-6 mb-6'>
        <TextInput placeholder={'Input with Pre Text'} label={'Input with Pre Text'} preText={'$'} />
        <TextInput placeholder={'Input with Post Text'} label={'Input with Post Text'} postText={'USD'} />
        <TextInput placeholder={'Input with allow Clear'} label={'Input with allow Clear'} allowClear={true} />
      </div>
      <div className='flex gap-6 mb-6'>
        <TextInput placeholder={'Input Disabled'} label={'Input Disabled'} disabled={true} />
        <TextInput placeholder={'Input Invalid'} label={'Input Invalid'} invalid={true} />
      </div>
      <div className='flex gap-6 mb-6'>
        <DateInputNative
          onChange={e => {
            alert(`DateInputNative onChange ${e.target.value}`);
          }}
          label={'Date Input'}
        />
      </div>
      <div className='flex gap-6 mb-6'>
        <SelectInput
          options={[
            { id: 'value_1', name: 'value 1' },
            { id: 'value_2', name: 'value 2' },
            { id: 'value_3', name: 'value 3' },
          ]}
          label='Select'
        />
        <SelectInput
          error='error message'
          options={[
            { id: 'value_1', name: 'value 1' },
            { id: 'value_2', name: 'value 2' },
            { id: 'value_3', name: 'value 3' },
          ]}
          label='Select with error'
        />
        <SelectInput
          nullable={true}
          options={[
            { id: 'value_1', name: 'value 1' },
            { id: 'value_2', name: 'value 2' },
            { id: 'value_3', name: 'value 3' },
          ]}
          label='Select with nullable option'
        />
        <SelectInput
          hint='Small hint what the user should do'
          options={[
            { id: 'value_1', name: 'value 1' },
            { id: 'value_2', name: 'value 2' },
            { id: 'value_3', name: 'value 3' },
          ]}
          label='Select with hint'
        />
        <SelectInput
          allowClear={true}
          value='value_2'
          options={[
            { id: 'value_1', name: 'value 1' },
            { id: 'value_2', name: 'value 2' },
            { id: 'value_3', name: 'value 3' },
          ]}
          label='Select with clear button'
        />
      </div>
    </Article>
  );
}
export default InputShowcase;

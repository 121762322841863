import { PencilSimple } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  children: ReactNode;

  // This means we can't edit the content
  loading?: boolean;

  // Text that is show when `required` is true
  step?: {
    step: number;
    text: string;
  };

  required?: boolean;
  enabled?: boolean;
  onEdit?: () => void;
}

function EditableInvoiceItem({ children, onEdit, step, required = false, loading = false, enabled = true }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div
      className={classNames('cursor-pointer group border-l-2', {
        'border-blue-400 hover:cursor-not-allowed': required && !loading && !enabled,
        'border-blue-200 hover:cursor-not-allowed': !required && !loading && !enabled,

        'hover:bg-blue-50 border-blue-400 hover:border-blue-500': required && !loading && enabled,
        'hover:bg-blue-50 border-blue-200 hover:border-blue-500': !required && !loading && enabled,

        'border-neutral-200': required && loading,
        'border-neutral-100': !required && loading,
      })}
      onClick={() => {
        if (enabled && !loading && onEdit) {
          onEdit();
        }
      }}
    >
      <div
        className={classNames('inline-flex text-white text-sm font-medium p-1', {
          'group-hover:bg-blue-500': !loading && enabled,
          'pr-8 bg-blue-400': required && !loading,
          'bg-blue-200': !required && !loading,
          'pr-8 bg-neutral-200': required && loading,
          'bg-neutral-100': !required && loading,
        })}
      >
        {required && step && <>{`${step.step}: ${step.text}`}</>}
        {required && !step && <>{t('required', 'Required')}</>}
        {!required && <PencilSimple size={20} />}
      </div>
      <div className='px-2'>{children}</div>
    </div>
  );
}
export default EditableInvoiceItem;

import { currencies, Currency } from 'currencies.json';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';

// for now we are using a json file instead of the API
// @see https://gitlab.qubis.nl/equinem/equinemcore/-/issues/435

export function getCurrencyByCode(code: string | undefined): Currency | undefined {
  if (code === undefined) return undefined;
  return currencies.find(currency => currency.code.toLowerCase() === code.toLowerCase());
}

export function getCurrencyOptionList(): OptionItemInterface[] {
  return currencies.map(currency => ({ id: currency.code, name: `${currency.name} (${currency.symbol})` }));
}

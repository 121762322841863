import { PencilSimple, Plus, TrashSimple } from '@phosphor-icons/react';
import { cachedPaginatedApiData } from 'api/ApiCache';
import ApiErrorParser from 'api/ApiErrorParser';
import { AppRoutes } from 'AppRoutes';
import SaveSemenOrder from 'components/Breeding/SaveSemenOrder';
import ContactLink from 'components/Contacts/ContactLink';
import { PaymentStatusToColor, PaymentStatusToString } from 'components/Financial/Helpers';
import InvoiceNumber from 'components/Financial/InvoiceNumber';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { usePage } from 'context/PageContext';
import {
  CancelablePromise,
  CategoriesService,
  Category,
  Contact,
  ContactsService,
  Horse,
  HorsesService,
  Invoice,
  InvoicesService,
  OrdersService,
  PaginatedContactList,
  PaginatedHorseList,
  PaginatedInvoiceList,
  PaginatedProductList,
  Product,
  ProductsService,
  SupplierOrderDetail,
} from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, NavLink, useNavigate, useParams } from 'react-router-dom';
import Badge from 'ui/Badge';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import DescriptionList from 'ui/Layout/DescriptionList';
import Page, { navBackToThisPage, PageMaxWidth } from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import { ActionModal } from 'ui/Modals';
import { contactAddress, contactName } from 'utilities/Contact';
import OrderItemDetails from './OrderItemDetails';
import UseCountries from 'hooks/UseCountries';

enum OrderModalType {
  Edit,
  Repeat,
}

export default function OrderDetailsPage(): JSX.Element {
  const { t } = useTranslation();
  const [horses, setHorses] = useState<Horse[]>();
  const [categories, setCategories] = useState<Category[]>();
  const { selectedOrganization, generateCacheKey: getCacheId } = useOrganization();
  const [contacts, setContacts] = useState<Contact[]>();
  const [products, setProducts] = useState<Product[]>();
  const { formatDate, formatDateTime, parseAndFormatMoney } = useAccount();
  const [semenOrderModalOpen, setSemenOrderModalOpen] = useState<OrderModalType | undefined>();
  const { setApiError } = usePage();
  const [order, setOrder] = useState<SupplierOrderDetail>();
  const { uid } = useParams();
  const [showRemoveOrderDialog, setShowRemoveOrderDialog] = useState<boolean>();
  const [removeOrderDialogError, setRemoveOrderDialogError] = useState<ApiErrorParser<void> | undefined>();
  const [invoices, setInvoices] = useState<Invoice[]>();
  const navigate = useNavigate();
  const { countries } = UseCountries();

  // Load the order from api
  const loadOrder = useCallback((): CancelablePromise<SupplierOrderDetail> => {
    const promise = OrdersService.ordersSuppliedRetrieve({
      supplierUid: selectedOrganization?.uid ?? '',
      uid: uid ?? '',
    });
    promise
      .then(result => {
        setApiError(undefined);
        setOrder(result);
      })
      .catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<SupplierOrderDetail>(e));
        }
      });
    return promise;
  }, [selectedOrganization, setApiError, uid]);

  // Load the horses from api and/or cache
  const loadHorses = useCallback(
    (disableCache: boolean): CancelablePromise<PaginatedHorseList> => {
      const promise = HorsesService.horsesList({
        organisationUid: selectedOrganization?.uid ?? '',
        onUnknownLocation: false,
      });
      promise.catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<PaginatedContactList>(e), horses === undefined);
        }
      });
      cachedPaginatedApiData<Horse>(getCacheId('horses'), promise, setHorses, disableCache);
      return promise;
    },
    [selectedOrganization, horses, getCacheId, setApiError],
  );

  // Load the contacts from api and/or cache
  const loadContacts = useCallback(
    (disableCache: boolean): CancelablePromise<PaginatedContactList> => {
      const promise = ContactsService.contactsList({
        organisationUid: selectedOrganization?.uid ?? '',
      });
      promise.catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<PaginatedContactList>(e), false);
        }
      });
      cachedPaginatedApiData<Contact>(getCacheId('contacts'), promise, setContacts, disableCache);
      return promise;
    },
    [selectedOrganization, getCacheId, setApiError],
  );

  // Load the products from api and/or cache
  const loadProducts = useCallback((): CancelablePromise<PaginatedProductList> => {
    const promise = ProductsService.productsList({
      organisationUid: selectedOrganization?.uid ?? '',
    });
    promise
      .then(res => setProducts(res.results))
      .catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<Product[]>(e));
        }
      });
    return promise;
  }, [selectedOrganization, setApiError]);

  const loadCategories = useCallback((): CancelablePromise<Category[]> => {
    const promise = CategoriesService.categoriesList({
      organisationUid: selectedOrganization?.uid ?? '',
    });
    promise
      .then(res => setCategories(res))
      .catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<Category[]>(e));
        }
      });
    return promise;
  }, [selectedOrganization, setApiError]);

  // Load the invoices
  const loadInvoices = useCallback((): CancelablePromise<PaginatedInvoiceList> => {
    const promise = InvoicesService.invoicesList({
      organisationUid: selectedOrganization?.uid ?? '',
      orderUid: uid,
    });
    promise
      .then(res => setInvoices(res.results))
      .catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<PaginatedInvoiceList>(e));
        }
      });
    return promise;
  }, [selectedOrganization, setApiError, uid]);

  // Load related invoices
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadInvoices();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // Load the order
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadOrder();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // Load the contacts
  useEffect(() => {
    // We need to have a selected organization.
    if (selectedOrganization) {
      const promise = loadContacts(false);
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // Load the horses
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadHorses(false);
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // Load the products
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadProducts();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // Load the categories
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadCategories();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  const actions = useMemo(() => {
    return [
      {
        onClick: () => setSemenOrderModalOpen(OrderModalType.Edit),
        text: t('edit', 'Edit'),
        icon: <PencilSimple />,
      },
      {
        onClick: () => setSemenOrderModalOpen(OrderModalType.Repeat),
        text: t('new-repeat-order', 'New repeat order'),
        icon: <Plus />,
      },
      {
        onClick: () => setShowRemoveOrderDialog(true),
        text: t('remove-order', 'Remove order'),
        buttonVariant: ButtonVariant.Danger,
        icon: <TrashSimple />,
      },
    ];
  }, [t]);

  const breadCrumbs = useMemo(() => [AppRoutes.SemenOrderList], []);

  const createdBy = useMemo((): Contact | undefined => {
    return contacts?.find(contact => contact.user_uid === order?.created_by);
  }, [order, contacts]);

  return (
    <Page title={`${t('order', 'Order')} ${order?.uid ?? ''}`} breadCrumbs={breadCrumbs} maxWidth={PageMaxWidth.Tile} actions={actions}>
      <div className='space-y-10'>
        <Tile title={t('general', 'General')}>
          {order && (
            <DescriptionList
              list={[
                { term: t('order-number', 'Order number'), definition: order.uid },
                {
                  term: t('customer', 'Customer'),
                  definition: (
                    <div>
                      <NavLink
                        className='text-blue-500'
                        to={{
                          pathname: generatePath(AppRoutes.ContactDetails.path, { uid: order.customer_uid ?? '' }),
                          search: navBackToThisPage().toString(),
                        }}
                      >
                        {contactName(order.historic_customer)}
                      </NavLink>
                      {contactAddress(order.historic_customer, countries).map(line => (
                        <p key={line}>{line}</p>
                      ))}
                    </div>
                  ),
                },
                {
                  term: t('order-agent', 'Agent'),
                  definition: <ContactLink contacts={contacts} contactUid={order.requester_uid} />,
                },
                {
                  term: t('receiver', 'Receiver'),
                  definition: (
                    <div>
                      <NavLink
                        className='text-blue-500'
                        to={{
                          pathname: generatePath(AppRoutes.ContactDetails.path, { uid: order.receiver_uid ?? '' }),
                          search: navBackToThisPage().toString(),
                        }}
                      >
                        {contactName(order.historic_receiver)}
                      </NavLink>
                      {contactAddress(order.historic_receiver, countries).map(line => (
                        <p key={line}>{line}</p>
                      ))}
                    </div>
                  ),
                },
                { term: t('customer-note', 'Customer note'), definition: order.customer_note || '-' },
                { term: t('internal-note', 'Internal note'), definition: order.supplier_note || '-' },
                {
                  term: t('requested-shipping-date', 'Requested shipping date'),
                  definition: order.shipping_date ? formatDate(new Date(order.shipping_date)) : '',
                },
                {
                  term: t('created', 'Created'),
                  definition: (
                    <>
                      {createdBy ? (
                        <span>
                          <NavLink
                            className='text-blue-500'
                            to={{
                              pathname: generatePath(AppRoutes.ContactDetails.path, { uid: createdBy?.uid ?? '' }),
                              search: navBackToThisPage().toString(),
                            }}
                          >
                            {contactName(createdBy)}
                          </NavLink>{' '}
                          {t('on', 'on')} {formatDateTime(new Date(order.created_on))}
                        </span>
                      ) : (
                        <span>{formatDateTime(new Date(order.created_on))}</span>
                      )}
                    </>
                  ),
                },
                {
                  term: t('invoices', 'Invoices'),
                  definition: (
                    <ul className='space-y-2'>
                      {invoices?.map(invoice => (
                        <li
                          key={invoice.uid}
                          className='rounded border p-2 flex flex-row gap-4 cursor-pointer'
                          onClick={() =>
                            navigate({
                              pathname: generatePath(AppRoutes.Invoice.path, { invoiceId: invoice.uid }),
                              search: navBackToThisPage().toString(),
                            })
                          }
                        >
                          <InvoiceNumber invoice={invoice} />
                          <span className='grow'>{parseAndFormatMoney(invoice.total, invoice.currency)}</span>
                          <Badge color={PaymentStatusToColor(invoice.payment_status)}>
                            {PaymentStatusToString(t, invoice.payment_status)}
                          </Badge>
                        </li>
                      ))}
                    </ul>
                  ),
                },
              ]}
            />
          )}
        </Tile>
        {(order?.order_items ?? []).map(item => (
          <OrderItemDetails horses={horses ?? []} key={item.uid} products={products} orderItem={item} categories={categories} />
        ))}
      </div>
      {products && horses && contacts && categories && (
        <SaveSemenOrder
          existingOrder={semenOrderModalOpen === OrderModalType.Edit ? order : undefined}
          repeatOrder={semenOrderModalOpen === OrderModalType.Repeat ? order : undefined}
          products={products}
          categories={categories}
          horses={horses}
          contacts={contacts}
          open={semenOrderModalOpen !== undefined}
          onContactUpdate={() => loadContacts(false)}
          onRequestClose={reload => {
            if (reload) {
              loadOrder();
            }
            setSemenOrderModalOpen(undefined);
          }}
        />
      )}
      <ActionModal
        open={showRemoveOrderDialog === true}
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: () => setShowRemoveOrderDialog(false),
          },
          {
            text: t('remove', 'Remove'),
            variant: ButtonVariant.PrimaryDanger,
            onClick: () => {
              const promise = OrdersService.ordersSuppliedDestroy({
                supplierUid: selectedOrganization?.uid ?? '',
                uid: uid ?? '',
              });
              promise
                .then(() => {
                  setShowRemoveOrderDialog(false);
                  navigate(AppRoutes.SemenOrderList.path);
                })
                .catch(e => {
                  if (!promise.isCancelled) {
                    setRemoveOrderDialogError(new ApiErrorParser(e));
                  }
                });
            },
          },
        ]}
        title={t('remove-order-confirm-title', 'Remove order')}
      >
        <>
          <ErrorSection errors={removeOrderDialogError} />
          <p>{t('remove-order-confirm-text', 'Are you sure you want to remove this order?')}</p>
        </>
      </ActionModal>
    </Page>
  );
}

import { usePlanning } from 'hooks/UsePlanning';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ActionModal } from 'ui/Modals';

// Simple error message dialog that pops up when there is a Planning error.
export default function PlanningErrorModal(): JSX.Element {
  const { t } = useTranslation();
  const { modalError, clearModalError } = usePlanning();

  return (
    <ActionModal
      title={t('failure', 'Failure')}
      actions={[
        {
          variant: ButtonVariant.Primary,
          text: t('close', 'Close'),
          onClick: () => clearModalError(),
        },
      ]}
      open={modalError !== undefined}
      onClose={() => clearModalError()}
    >
      <p>{modalError}</p>
    </ActionModal>
  );
}

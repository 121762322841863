import classNames from 'classnames';
import ColorHash from 'color-hash';
import React, { useMemo } from 'react';
import { AvatarSize } from '.';

interface Props {
  size?: AvatarSize;
  children: React.ReactNode;
  color?: string;
  uuid?: string; // Generates a color from this string
  disabled?: boolean;
}

export default function Base({ children, color, size = AvatarSize.Normal, uuid, disabled }: Props): JSX.Element {
  const clr = useMemo(() => {
    // has a given color
    if (color) return color;

    if (disabled || !uuid) {
      return '#e5e7eb'; // Primary
    }

    const colorHash = new ColorHash({ saturation: 0.3 });
    return colorHash.hex(uuid.substring(0, 8));
  }, [color, disabled, uuid]);

  return (
    <div
      style={{ backgroundColor: clr }}
      className={classNames(
        'relative inline-flex items-center justify-center overflow-hidden rounded-full text-white font-medium text-opacity-90 shrink-0',
        {
          'w-10 h-10 text-lg': size === AvatarSize.Large,
          'w-9 h-9 text-base': size === AvatarSize.Normal,
          'w-8 h-8 text-sm': size === AvatarSize.Small,
          'w-7 h-7 text-xs': size === AvatarSize.XSmall,
        },
      )}
    >
      {children}
    </div>
  );
}

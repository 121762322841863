import { TFunction } from 'i18next';
import { ColorEnum, Contact } from 'openapi';

export enum Title {
  Mr = 'Mr',
  Miss = 'Miss',
  Mrs = 'Mrs',
  Ms = 'Ms',
  Dr = 'Dr',
}

export const titleToString = (title: Title, t: TFunction): string => {
  switch (title) {
    case Title.Mr:
      return t('title.mr', 'Mr');
    case Title.Miss:
      return t('title.miss', 'Miss');
    case Title.Mrs:
      return t('title.mrs', 'Mrs');
    case Title.Ms:
      return t('title.ms', 'Ms');
    case Title.Dr:
      return t('title.dr', 'Dr');
  }
};

export const getFullName = ({ first_name, last_name, business_name }: Contact): string => {
  const names: string[] = [];

  if (first_name) {
    names.push(first_name);
  }
  if (last_name) {
    names.push(last_name);
  }

  // if there are no personal names, use the business name
  if (names.length === 0 && business_name) {
    names.push(business_name);
  }

  return names.join(' ');
};

interface ColorItem {
  label: string;
  value: ColorEnum;
}

export const colorList = (t: TFunction): ColorItem[] => {
  return [
    {
      label: t('black', 'Black'),
      value: ColorEnum._1,
    },
    {
      label: t('brown', 'Brown'),
      value: ColorEnum._2,
    },
    {
      label: t('baybrown', 'Bay brown'),
      value: ColorEnum._3,
    },
    {
      label: t('darkbrown', 'Dark brown'),
      value: ColorEnum._4,
    },
    {
      label: t('chestnut', 'Chestnut'),
      value: ColorEnum._5,
    },
    {
      label: t('grey', 'Grey'),
      value: ColorEnum._6,
    },
    {
      label: t('strawberry', 'Strawberry'),
      value: ColorEnum._7,
    },
    {
      label: t('piebald', 'Piebald'),
      value: ColorEnum._8,
    },
    {
      label: t('skewbald', 'Skewbald'),
      value: ColorEnum._9,
    },
    {
      label: t('dun', 'Dun'),
      value: ColorEnum._10,
    },
    {
      label: t('cream', 'Cream'),
      value: ColorEnum._11,
    },
    {
      label: t('palomino', 'Palomino'),
      value: ColorEnum._12,
    },
    {
      label: t('appaloosa', 'Appaloosa'),
      value: ColorEnum._13,
    },
  ];
};

export const getColorFromId = (colorId: number, t: TFunction): string => {
  const colors = colorList(t);
  const color = colors.find(c => c.value === colorId);
  return color?.label ?? t('color-unknown', 'Unknown');
};

/**
 * Retrieve the hexadecimal color code associated with a given color ID.
 *
 * This function maps color IDs to their corresponding hexadecimal color values.
 * The color IDs represent different horse coat colors.
 *
 * @see <a href="https://www.colorhexa.com/">Color Reference from name</a>
 * @param {number} colorId - The ID representing the horse color.
 * @returns {string} - The hexadecimal color value.
 */
export const getColorCodeId = (colorId: number): string => {
  switch (colorId) {
    case 1:
      return '#000000'; // Black
    case 2:
      return '#8B4513'; // Brown
    case 3:
      return '#A52A2A'; // Bay brown
    case 4:
      return '#654321'; // Dark brown
    case 5:
      return '#CD5C5C'; // Chestnut
    case 6:
      return '#808080'; // Grey
    case 7:
      return '#FF4500'; // Strawberry
    case 8:
      return '#3D3B3C'; // Piebald
    case 9:
      return '#C0C0C0'; // Skewbald
    case 10:
      return '#C2B280'; // Dun
    case 11:
      return '#FFFDD0'; // Cream
    case 12:
      return '#FFD700'; // Palomino
    case 13:
      return '#D2691E'; // Appaloosa
    default:
      return '#FFFFFF'; // Unknown (represented as white)
  }
};

export const copyTextToClipboard = async (text: string): Promise<boolean> => {
  if ('clipboard' in navigator) {
    await navigator.clipboard.writeText(text);
    return true;
  } else {
    return document.execCommand('copy', true, text);
  }
};

/**
 * Render a string from an array of values
 */
export const stringArrayToString = (
  value: string | number | undefined | null | Array<string | undefined>,
  valueSeparator = '-',
): string | number => {
  const formatString = (value: string | number | undefined | null) =>
    (typeof value === 'string' && value !== '') || typeof value === 'number' ? value : '-';

  if (Array.isArray(value)) {
    const formattedArray = value
      .filter(Boolean)
      .filter(val => val !== '')
      .join(valueSeparator ? ` ${valueSeparator} ` : ' ');

    return formatString(formattedArray);
  }

  return formatString(value);
};

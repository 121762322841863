import classNames from 'classnames';
import React from 'react';
import { MenuItemConfig } from '../Layout';
import { Theme } from '../Theme';
import HeaderSection from './HeaderSection';
import MenuSection from './MenuSection';
import UserProfile from './UserProfile';

interface Props {
  topMenu: MenuItemConfig[];
  bottomMenu: MenuItemConfig[];
  profileMenuPath: string;
  theme: Theme;
  open: boolean;
  setOpen: (open: boolean) => void;
  hideOnMobile: boolean;
}

export default function Sidebar({ hideOnMobile, open, setOpen, topMenu, bottomMenu, profileMenuPath, theme }: Props): JSX.Element {
  const parentClassName = classNames('print:hidden transition-all duration-200 select-none shrink-0', {
    ['md:w-sidebar']: open,
    ['md:w-sidebar-collapsed']: !open,
    'hidden md:block': hideOnMobile,
  });
  const rootClassName = classNames(
    'fixed transition-all z-20 duration-200 h-full flex flex-col mt-safe-offset-14 pb-safe-offset-28 md:mt-0 md:pb-0 md:border-r border-neutral-200 bg-white',
    {
      'w-sidebar left-0': open,
      'md:w-sidebar-collapsed -left-[300px] md:left-0': !open,
    },
  );
  return (
    <div className={parentClassName}>
      {open && <div className='fixed bottom-0 top-0 right-0 z-20 left-0 bg-black bg-opacity-30 md:hidden' onClick={() => setOpen(!open)} />}
      <div className={rootClassName}>
        <HeaderSection open={open} setOpen={setOpen} theme={theme} />
        <MenuSection setOpen={setOpen} topMenu={topMenu} bottomMenu={bottomMenu} menuOpened={open} />
        <UserProfile profileMenuPath={profileMenuPath} menuOpened={open} setOpen={setOpen} />
      </div>
    </div>
  );
}

import { zodResolver } from '@hookform/resolvers/zod';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { ExternalIntegrationsService, HorseDetail, HorsesService, HorseTelexResponse } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from 'ui/Alert';
import { ButtonVariant } from 'ui/Button';
import RadioButtonGroup, { RadioButtonGroupOption } from 'ui/Inputs/RadioGroupInput';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';
import PageModal, { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { Severity } from 'utilities/severity';
import { z } from 'zod';

interface Props {
  isVisible: boolean;
  horse: HorseDetail | undefined;
  onImported: (horseDetail: HorseDetail) => void;
  onRequestClose: () => void;
}

interface FormModel {
  horseTelexId: number;
}

const schema = z.object({
  horseTelexId: z.number().min(1),
});

export default function ImportFromHorseTelexModal({ horse, isVisible, onImported, onRequestClose }: Props): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasLoadingError, setHasLoadingError] = useState<boolean>(false);
  const [hasSubmittingError, setHasSubmittingError] = useState<boolean>(false);
  const [horseTelex, setHorseTelex] = useState<HorseTelexResponse[]>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
  } = useForm<FormModel>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const { fieldError } = useFormError(schema, errors);

  const importFromHorseTelex = useCallback(async () => {
    setLoading(true);
    const promise = ExternalIntegrationsService.apiV5SearchHorsetelexCreate({
      requestBody: {
        name: horse?.name,
        chip_nr: horse?.chip_nr || undefined,
        UELN: horse?.UELN || undefined,
        FEI: horse?.FEI_pass_nr || undefined,
      },
    });

    try {
      const result = await promise;
      setHorseTelex(result.items);
      setHasLoadingError(false);
    } catch (error) {
      console.error(error);
      setHasLoadingError(true);
    } finally {
      setLoading(false);
    }
  }, [horse?.FEI_pass_nr, horse?.UELN, horse?.chip_nr, horse?.name]);

  const horseTelexOptions = useMemo((): RadioButtonGroupOption[] => {
    return (horseTelex ?? [])
      .filter(item => item.equinem_horse.dam !== null && item.equinem_horse.sire !== null)
      .slice(0, 10)
      .map<RadioButtonGroupOption>(data => ({ id: data.id, name: `${data.equinem_horse.sire} X ${data.equinem_horse.dam}` }));
  }, [horseTelex]);

  /**
   * Submit event handler, update the data via the API for this user
   */
  const onSubmit: SubmitHandler<FormModel> = async (data: FormModel) => {
    if (!horse || !selectedOrganization) return;

    const selectedHorseTelex = horseTelex?.find(item => item.id === data.horseTelexId);

    const updatedPedigree = {
      sire: selectedHorseTelex?.equinem_horse.sire ?? '',
      dam: selectedHorseTelex?.equinem_horse.dam ?? '',
      damsire: selectedHorseTelex?.equinem_horse.damsire ?? '',
    };

    try {
      const updateHorsePromise = HorsesService.horsesPartialUpdate({
        organisationUid: selectedOrganization.uid,
        uid: horse.uid,
        requestBody: updatedPedigree,
      });
      const updatedHorse = await updateHorsePromise;

      onImported(updatedHorse);

      onRequestClose();

      setHasSubmittingError(false);
    } catch (error) {
      console.error(error);
      setHasSubmittingError(true);
    }
  };

  /**
   * reset the modal
   */
  const onClosed = useCallback(() => {
    reset();
    setHorseTelex(undefined);
    setHasLoadingError(false);
    setHasSubmittingError(false);
  }, [reset]);

  /**
   * When we open the modal we do a query to the Horse Telex API
   */
  useEffect(() => {
    if (isVisible && horse) {
      importFromHorseTelex();
    }
  }, [horse, importFromHorseTelex, isVisible]);

  return (
    <PageModal
      open={isVisible}
      parentElement='form'
      parentProps={{ id: 'importFromHorseTelex', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      onClosed={onClosed}
    >
      <PageModalTitle title={t('import-from-horsetelex', 'Import from Horsetelex')} onClose={onRequestClose} />
      <PageModalContent>
        {(hasLoadingError || hasSubmittingError) && (
          <div className='mb-2'>
            {hasLoadingError && (
              <Alert
                severity={Severity.Warning}
                message='We cannot load the data from horseTelex. Please try again later or contact support'
              />
            )}
            {hasSubmittingError && (
              <Alert severity={Severity.Warning} message='We cannot save the data. Please try again later or contact support' />
            )}
          </div>
        )}

        {loading && (
          <div className='flex items-center gap-x-2'>
            <Spinner size={SpinnerSize.XSmall} /> <p>{t('loading-data-from-horsetelex', 'Loading data from Horsetelex')}...</p>
          </div>
        )}

        {!hasLoadingError && !loading && horseTelexOptions.length === 0 && (
          <>
            <div className='flex items-center gap-x-2'>
              <p>{t('no-match-from-horsetelex', 'We did not found a match in the HorseTelex database')}</p>
            </div>
          </>
        )}

        {!loading && horseTelexOptions.length > 0 && (
          <RadioButtonGroup
            flexType='col'
            className='my-4'
            name='horseTelexId'
            required={false}
            control={control}
            error={fieldError('horseTelexId')}
            options={horseTelexOptions}
            label={t('pedigree', 'Pedigree')}
            hint={t('horsetelex-import-hint', 'Select the pedigree you want to import')}
          />
        )}
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            disabled: horseTelex === undefined || horseTelex?.length === 0,
            variant: ButtonVariant.Primary,
            type: 'submit',
            loading: isSubmitting,
            text: t('import', 'Import'),
            formId: 'importFromHorseTelex',
          },
        ]}
      />
    </PageModal>
  );
}

import colors from 'tailwindcss/colors';
import { Severity } from './severity';

export enum AllColors {
  Slate = 'slate',
  Gray = 'gray',
  Zinc = 'zinc',
  Neutral = 'neutral',
  Stone = 'stone',
  Red = 'red',
  Orange = 'orange',
  Amber = 'amber',
  Yellow = 'yellow',
  Lime = 'lime',
  Green = 'green',
  Emerald = 'emerald',
  Teal = 'teal',
  Cyan = 'cyan',
  Sky = 'sky',
  Blue = 'blue',
  Indigo = 'indigo',
  Violet = 'violet',
  Purple = 'purple',
  Fuchsia = 'fuchsia',
  Pink = 'pink',
  Rose = 'rose',
}

type ColorShades = {
  '50': string;
  '100': string;
  '200': string;
  '300': string;
  '400': string;
  '500': string;
  '600': string;
  '700': string;
  '800': string;
  '900': string;
};

export const mapSeverityToColor = (severity: Severity): ColorShades => {
  switch (severity) {
    case Severity.Danger:
      return colors.red;
    case Severity.Warning:
      return colors.amber;
    case Severity.Info:
      return colors.blue;
    case Severity.Success:
      return colors.emerald;
    default:
      return colors.sky;
  }
};

import { Contact, ModulePermissionsEnum } from 'openapi';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utilities/date.utilities';
import { Tile } from 'ui/Layout/Tile';
import DescriptionList from 'ui/Layout/DescriptionList';
import { Pencil } from '@phosphor-icons/react';
import { stringArrayToString } from 'utilities/string.utility';
import { DefinitionItem } from 'ui/Layout/DescriptionList/DescriptionListItem';
import OwnedHorseListTile from './Tiles/OwnedHorseListTile';
import OrdersTile from './Tiles/OrdersTile';
import UseCountries from 'hooks/UseCountries';
import { PageAction } from 'context/PageContext';
import usePermissions from 'hooks/UsePermissions';

interface Props {
  contact: Contact;
  onRequestUpdateAddress: () => void;
  onRequestUpdateGeneral: () => void;
  onRequestUpdateBusiness: () => void;
}

function ContactDetails({ contact, onRequestUpdateAddress, onRequestUpdateBusiness, onRequestUpdateGeneral }: Props): JSX.Element {
  const { t } = useTranslation();
  const { countryById } = UseCountries();
  const { hasPermission } = usePermissions();

  // detect if the contact is a personal contact
  const isPersonalContact = contact.first_name && contact.last_name;
  /**
   * Get the name of the country
   */
  const country = useMemo((): string => {
    const countryResult = countryById(contact.country);
    if (countryResult) {
      return countryResult.name;
    }

    return '-';
  }, [contact.country, countryById]);

  // generate the general description list
  // also include the business name if it's a business contact
  const generalDescriptionList = useMemo(() => {
    const list: DefinitionItem[] = [
      {
        term: t('title', 'Title'),
        definition: contact.title || '-',
      },
      {
        term: t('first-name', 'First name'),
        definition: contact.first_name || '-',
      },
      {
        term: t('last-name', 'Last name'),
        definition: contact.last_name || '-',
      },
      {
        term: t('email', 'Email'),
        definition: contact.email || '-',
      },
      {
        term: t('phone-number', 'Phone number'),
        definition: contact.phone_number || '-',
      },
    ];

    if (contact.date_of_birth) {
      list.push({
        term: t('date-of-birth', 'Date of birth'),
        definition: formatDate(new Date(contact.date_of_birth)),
      });
    }

    if (isPersonalContact && contact.business_name) {
      list.push({
        term: t('business-name', 'Business name'),
        definition: contact.business_name,
      });
    }

    if (contact.note) {
      list.push({
        term: t('note', 'Note'),
        definition: contact.note,
      });
    }

    return list;
  }, [contact, isPersonalContact, t]);

  const addressDescriptionList = useMemo(() => {
    const list: DefinitionItem[] = [];
    list.push({
      term: t('street', 'Street'),
      definition: stringArrayToString([contact.address_line1, contact.address_line3], ''),
    });
    if (contact.address_line2) {
      list.push({
        term: t('address-line2', 'Address line 2'),
        definition: contact.address_line2,
      });
    }
    list.push({
      term: t('postcode', 'Postcode'),
      definition: contact.postcode || '-',
    });
    list.push({
      term: t('city', 'City'),
      definition: contact.city || '-',
    });
    if (contact.state) {
      list.push({
        term: t('state', 'State'),
        definition: contact.state,
      });
    }
    list.push({
      term: t('country', 'Country'),
      definition: country || '-',
    });
    return list;
  }, [contact, t, country]);

  /**
   * Actions for the general tile
   */
  const generalTileAction = useMemo((): PageAction[] | undefined => {
    if (hasPermission(ModulePermissionsEnum.MANAGE_CONTACTS)) {
      return [
        {
          icon: <Pencil />,
          text: t('edit', 'Edit'),
          onClick: onRequestUpdateGeneral,
        },
      ];
    }
  }, [hasPermission, onRequestUpdateGeneral, t]);

  /**
   * Actions for the general tile
   */
  const businessTileAction = useMemo((): PageAction[] | undefined => {
    if (hasPermission(ModulePermissionsEnum.MANAGE_CONTACTS)) {
      return [
        {
          icon: <Pencil />,
          text: t('edit', 'Edit'),
          onClick: onRequestUpdateBusiness,
        },
      ];
    }
  }, [hasPermission, onRequestUpdateBusiness, t]);

  /**
   * Actions for the address tile
   */
  const addressTileAction = useMemo((): PageAction[] | undefined => {
    if (hasPermission(ModulePermissionsEnum.MANAGE_CONTACTS)) {
      return [
        {
          icon: <Pencil />,
          text: t('edit', 'Edit'),
          onClick: onRequestUpdateAddress,
        },
      ];
    }
  }, [hasPermission, onRequestUpdateAddress, t]);

  return (
    <>
      {isPersonalContact && (
        <Tile title={t('general', 'General')} className='mb-4' actions={generalTileAction}>
          <DescriptionList list={generalDescriptionList} />
        </Tile>
      )}

      {!isPersonalContact && (
        <Tile title={t('business', 'Business')} className='mb-4' actions={businessTileAction}>
          <DescriptionList
            list={[
              {
                term: t('business-name', 'Business name'),
                definition: contact.business_name || '-',
              },
              {
                term: t('email', 'Email'),
                definition: contact.email || '-',
              },
              {
                term: t('phone-number', 'Phone number'),
                definition: contact.phone_number || '-',
              },
              {
                term: t('vat_number', 'VAT number'),
                definition: contact.vat_number || '-',
              },
            ]}
          />
        </Tile>
      )}

      <Tile title={t('address', 'Address')} className='mb-4' actions={addressTileAction}>
        <DescriptionList list={addressDescriptionList} />
      </Tile>

      <OwnedHorseListTile contact={contact} />
      <OrdersTile contact={contact} />
    </>
  );
}

export default ContactDetails;

import { zodResolver } from '@hookform/resolvers/zod';
import { Pencil } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import UseCountries from 'hooks/UseCountries';
import { Organisation, OrganisationService, PatchedOrganisation } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { SelectInput, TextInput } from 'ui/Inputs';
import { Tile } from 'ui/Layout/Tile';
import TileDescriptionList from 'ui/Layout/Tile/TileDescriptionList';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { stringArrayToString } from 'utilities/string.utility';
import { zodInputIsRequired } from 'utilities/zod';
import { z } from 'zod';

export default function AddressTile(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const { selectedOrganizationDetails, refresh } = useOrganization();
  const { countries, countryById } = UseCountries();

  /**
   * Define the default values
   */
  const defaultValues = useMemo(() => {
    return {
      address_line1: selectedOrganizationDetails?.address_line1,
      address_line2: selectedOrganizationDetails?.address_line2,
      address_line3: selectedOrganizationDetails?.address_line3,
      country: selectedOrganizationDetails?.country,
      state: selectedOrganizationDetails?.state,
      postcode: selectedOrganizationDetails?.postcode,
      city: selectedOrganizationDetails?.city,
    };
  }, [selectedOrganizationDetails]);

  /**
   * Schema for the form
   */
  const schema = useMemo(() => {
    return schemas.Organisation.pick({
      address_line1: true,
      address_line2: true,
      address_line3: true,
      country: true,
      state: true,
      postcode: true,
      city: true,
    }).extend({
      // We cannot add an empty string to the .country() schema therefore we need to implement a custom string validation
      country: z.string(),
    });
  }, []);

  const { showModal, closeModal, modalIsVisible } = useModal();
  const {
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    register,
  } = useForm<Organisation>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Close event for the modal
   */
  const onClose = (resetForm = true) => {
    if (resetForm) {
      reset(defaultValues);
    }
    // clear the errors
    clearErrors();
    setApiError(undefined);
    closeModal();
  };

  /**
   * Submit event handler, update the data via the API for this organization
   */
  const onSubmit: SubmitHandler<PatchedOrganisation> = async (data: PatchedOrganisation) => {
    if (!selectedOrganizationDetails) return;
    setLoading(true);

    try {
      await OrganisationService.rootPartialUpdate({ uid: selectedOrganizationDetails.uid, requestBody: data });
      // refresh the organization
      refresh();

      // close the modal
      onClose();
    } catch (error) {
      setApiError(new ApiErrorParser<PatchedOrganisation>(error));
    }
    setLoading(false);
  };

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo(() => {
    return [
      {
        onClick: showModal,
        text: t('edit', 'Edit'),
        buttonVariant: ButtonVariant.Default,
        icon: <Pencil />,
      },
    ];
  }, [showModal, t]);

  /**
   * Get the name of the country
   */
  const country = useMemo((): string => {
    const countryResult = countryById(selectedOrganizationDetails?.country);
    if (countryResult) {
      return countryResult.name;
    }

    return '-';
  }, [countryById, selectedOrganizationDetails?.country]);

  return (
    <Tile title={t('address', 'Address')} loading={!selectedOrganizationDetails} actions={tileActions}>
      <TileDescriptionList
        list={[
          {
            term: t('street', 'Street'),
            definition: stringArrayToString([selectedOrganizationDetails?.address_line1, selectedOrganizationDetails?.address_line3], ''),
          },
          {
            term: t('address-line2', 'Address line 2'),
            definition: selectedOrganizationDetails?.address_line2 || '-',
          },
          {
            term: t('postcode', 'Postcode'),
            definition: selectedOrganizationDetails?.postcode || '-',
          },
          {
            term: t('city', 'City'),
            definition: selectedOrganizationDetails?.city || '-',
          },
          {
            term: t('state', 'State'),
            definition: selectedOrganizationDetails?.state || '-',
          },
          {
            term: t('country', 'Country'),
            definition: country || '-',
          },
        ]}
      />

      <PageModal
        open={modalIsVisible}
        parentElement='form'
        parentProps={{ id: 'updateAddress', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={t('update-address', 'Update address')} onClose={onClose} />
        <PageModalContent>
          <ErrorSection className='mb-4' errors={nonFieldErrors} />

          <div className='grid gap-3 grid-cols-1'>
            <div className='flex flex-col md:flex-row gap-3'>
              <TextInput
                className='grow'
                error={fieldError('address_line1')}
                required={zodInputIsRequired<Organisation>(schema, 'address_line1')}
                label={t('address-line1', 'Street')}
                {...register('address_line1')}
              />
              <TextInput
                className='md:w-1/3'
                error={fieldError('address_line3')}
                required={zodInputIsRequired<Organisation>(schema, 'address_line3')}
                label={t('house-number', 'House number')}
                {...register('address_line3')}
              />
            </div>

            <TextInput
              error={fieldError('address_line2')}
              required={zodInputIsRequired<Organisation>(schema, 'address_line2')}
              label={t('address-line2', 'Address line 2')}
              {...register('address_line2')}
            />
            <TextInput
              error={fieldError('postcode')}
              required={zodInputIsRequired<Organisation>(schema, 'postcode')}
              label={t('postcode', 'Postcode')}
              {...register('postcode')}
            />
            <TextInput
              error={fieldError('city')}
              required={zodInputIsRequired<Organisation>(schema, 'city')}
              label={t('city', 'City')}
              {...register('city')}
            />
            <TextInput
              error={fieldError('state')}
              required={zodInputIsRequired<Organisation>(schema, 'state')}
              label={t('stateOrCounty', 'State or province')}
              {...register('state')}
            />
            <SelectInput
              error={fieldError('country')}
              required={zodInputIsRequired<Organisation>(schema, 'country')}
              options={countries}
              nullable={true}
              nullableValue=''
              label={t('country', 'Country')}
              {...register('country')}
            />
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              onClick: onClose,
              variant: ButtonVariant.Default,
              type: 'button',
              text: t('cancel', 'Cancel'),
            },
            {
              formId: 'updateAddress',
              loading: loading,
              variant: ButtonVariant.Primary,
              type: 'submit',
              text: t('save', 'Save'),
            },
          ]}
        />
      </PageModal>
    </Tile>
  );
}

import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import UseCountries from 'hooks/UseCountries';
import { Contact, ContactsService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { SelectInput, TextInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { objectDiff } from 'utilities/compareObject';
import { zodInputIsRequired } from 'utilities/zod';
import { z } from 'zod';

interface Props {
  contact: Contact;
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onUpdated: (contact: Contact) => void;
}

function UpdateAddressModal({ isVisible, onRequestCloseModal, onUpdated, contact }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();
  const { countries } = UseCountries();

  // Form validation
  const schema = useMemo(() => {
    return schemas.Contact.pick({
      address_line1: true,
      address_line2: true,
      address_line3: true,
      country: true,
      state: true,
      postcode: true,
      city: true,
    }).extend({
      // We cannot add an empty string to the .country() schema therefore we need to implement a custom string validation
      country: z.string(),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Contact>({
    resolver: zodResolver(schema),
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Submit handler
   */
  const onSubmit = async (data: Contact) => {
    if (!selectedOrganization) return;

    setSubmitting(true);

    try {
      // generate a diff so we can send only the changed data
      const updatedFields = objectDiff(contact, data);

      const updateContactPromise = ContactsService.contactsPartialUpdate({
        organisationUid: selectedOrganization.uid,
        uid: contact.uid,
        requestBody: updatedFields,
      });

      const updatedContact = await updateContactPromise;

      // fire the onUpdated event
      onUpdated(updatedContact);

      // close the modal
      onRequestCloseModal();
    } catch (error: unknown) {
      setApiError(new ApiErrorParser(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * event that will be fired after the modal has been closed
   */
  const onClosed = () => {
    setSubmitting(false);
    setApiError(undefined);
  };

  /**
   * Event that will be fired when the user closes the modal
   */
  const onClose = () => {
    onRequestCloseModal();
    setSubmitting(false);
    setApiError(undefined);
  };

  /**
   * Update the value of the modal with the contact data
   */
  useEffect(() => {
    reset(contact);
  }, [contact, reset, isVisible]);

  return (
    <PageModal open={isVisible} onClosed={onClosed}>
      <PageModalTitle title={t('update-contact-address', 'Update contact address')} onClose={onClose} />
      <PageModalContent>
        <form className='w-full space-y-6' id='updateContactForm' noValidate={true} onSubmit={handleSubmit(onSubmit)}>
          <ErrorSection className='mb-4' errors={nonFieldErrors} />

          <div className='grid gap-3 grid-cols-1'>
            <div className='flex flex-col md:flex-row gap-3'>
              <TextInput
                className='grow'
                error={fieldError('address_line1')}
                required={zodInputIsRequired<Contact>(schema, 'address_line1')}
                label={t('address-line1', 'Street')}
                {...register('address_line1')}
              />
              <TextInput
                className='md:w-1/3'
                error={fieldError('address_line3')}
                required={zodInputIsRequired<Contact>(schema, 'address_line3')}
                label={t('house-number', 'House number')}
                {...register('address_line3')}
              />
            </div>

            <TextInput
              error={fieldError('address_line2')}
              required={zodInputIsRequired<Contact>(schema, 'address_line2')}
              label={t('address-line2', 'Address line 2')}
              {...register('address_line2')}
            />
            <TextInput
              error={fieldError('postcode')}
              required={zodInputIsRequired<Contact>(schema, 'postcode')}
              label={t('postcode', 'Postcode')}
              {...register('postcode')}
            />
            <TextInput
              error={fieldError('city')}
              required={zodInputIsRequired<Contact>(schema, 'city')}
              label={t('city', 'City')}
              {...register('city')}
            />
            <TextInput
              error={fieldError('state')}
              required={zodInputIsRequired<Contact>(schema, 'state')}
              label={t('stateOrCounty', 'State or province')}
              {...register('state')}
            />
            <SelectInput
              error={fieldError('country')}
              required={zodInputIsRequired<Contact>(schema, 'country')}
              options={countries}
              nullable={true}
              nullableValue=''
              label={t('country', 'Country')}
              {...register('country')}
            />
          </div>
        </form>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            loading: submitting,
            variant: ButtonVariant.Primary,
            text: t('save', 'Save'),
            type: 'submit',
            formId: 'updateContactForm',
          },
        ]}
      />
    </PageModal>
  );
}

export default UpdateAddressModal;

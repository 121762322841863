import { Barn, BuildingOffice, Globe, PencilSimple } from '@phosphor-icons/react';
import { useAccount } from 'context/AccountContext';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tile, TileList, TileRow } from 'ui/Layout/Tile';
import useModal from 'ui/Modals/UseModal';
import { languageById } from 'utilities/language';
import UpdateLangModal from './UpdateLangModal';
import { useOrganization } from 'context/OrganizationContext';
import UpdatePreferredOrganizationModal from './UpdatePreferredOrganizationModal';
import { Stable, StablesService } from 'openapi';
import { contactName } from 'utilities/Contact';
import UpdatePreferredStableModal from './UpdatePreferredStableModal';

export default function GeneralPreferencesTitle(): JSX.Element {
  const { t } = useTranslation();
  const { accountDetails } = useAccount();
  const { showModal: showLangModal, closeModal: closeLangModal, modalIsVisible: langModalIsVisible } = useModal();
  const { showModal: showOrgModal, closeModal: closeOrgModal, modalIsVisible: orgModalIsVisible } = useModal();
  const { showModal: showStableModal, closeModal: closeStableModal, modalIsVisible: stableModalIsVisible } = useModal();
  const { organizations } = useOrganization();

  // Get the selected organization
  const selectedOrganization = organizations?.find(org => org.uid === accountDetails?.preferred_organisation);

  const [stables, setStables] = useState<Stable[]>([]);

  // Get all stables from all organizations and put them all in the `stable`s array.
  useEffect(() => {
    if (!organizations) {
      return;
    }
    if (organizations.length > 20) {
      // This can occur in 'super admin' mode. A super user of EquineM is part of every organization.
      console.log('Too many organizations. Preferred stable setting is disabled.');
    }
    for (const org of organizations) {
      StablesService.stablesList({
        organisationUid: org.uid,
      })
        .then(res => {
          const found = res.filter(resStable => {
            return stables.find(stable => stable.uid === resStable.uid) === undefined;
          });
          if (found.length > 0) {
            setStables([...stables, ...found]);
          }
        })
        .catch(e => console.error('Failed to fetch stables for organization', e));
    }
  }, [organizations, stables]);

  const preferredStableName = useMemo(() => {
    const preferredStableUid = accountDetails?.preferred_stable;
    if (!preferredStableUid) {
      return undefined;
    }
    const stable = stables?.find(stable => stable.uid === preferredStableUid);
    if (!stable) {
      return undefined;
    }
    return contactName(stable.location);
  }, [accountDetails, stables]);

  return (
    <Tile title={t('general-preferences', 'General Preferences')} loading={!accountDetails}>
      <span>{t('general-preferences-form-desc', 'Preferences for your EquineM account.')}</span>

      <TileList className='my-2'>
        <TileRow as='li' onClick={showLangModal} icon={<PencilSimple />}>
          <div className='flex items-center gap-x-2'>
            <Globe width={25} height={25} />
            <div className='flex flex-col'>
              <span className='font-light'>{t('language', 'Language')}</span>
              <span>{languageById(accountDetails?.language)?.name}</span>
            </div>
          </div>
        </TileRow>
        {organizations.length > 1 && (
          <TileRow as='li' onClick={showOrgModal} icon={<PencilSimple />}>
            <div className='flex items-center gap-x-2'>
              <BuildingOffice size={25} />
              <div className='flex flex-col'>
                <span className='font-light'>{t('preferred-organization', 'Preferred organization')}</span>
                <span>{selectedOrganization?.name ?? <span className='italic'>{t('none', 'None')}</span>}</span>
              </div>
            </div>
          </TileRow>
        )}
        <TileRow as='li' onClick={showStableModal} icon={<PencilSimple />}>
          <div className='flex items-center gap-x-2'>
            <Barn size={25} />
            <div className='flex flex-col'>
              <span className='font-light'>{t('preferred-stable', 'Preferred stable')}</span>
              <span>{preferredStableName ?? <span className='italic'>{t('none', 'None')}</span>}</span>
            </div>
          </div>
        </TileRow>
      </TileList>

      <UpdateLangModal isVisible={langModalIsVisible} onRequestClose={closeLangModal} />
      <UpdatePreferredOrganizationModal isVisible={orgModalIsVisible} onRequestClose={closeOrgModal} />
      <UpdatePreferredStableModal stables={stables} isVisible={stableModalIsVisible} onRequestClose={closeStableModal} />
    </Tile>
  );
}

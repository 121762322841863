/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `moneybird` - moneybird
 * * `exactnl` - exactnl
 * * `yuki` - yuki
 */
export enum ProviderEnum {
  MONEYBIRD = 'moneybird',
  EXACTNL = 'exactnl',
  YUKI = 'yuki',
}

// This file is generated by openapi-zod-client based in the equinem openapi file. import { z } from "zod";

import { z } from 'zod';

const TitleEnum = z.enum(['Mr', 'Miss', 'Mrs', 'Ms', 'Dr']);
const CountryEnum = z.enum([
  'AF',
  'AX',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BQ',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'BI',
  'CV',
  'KH',
  'CM',
  'CA',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CG',
  'CD',
  'CK',
  'CR',
  'CI',
  'HR',
  'CU',
  'CW',
  'CY',
  'CZ',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'SZ',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IL',
  'IT',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'KP',
  'MK',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RE',
  'RO',
  'RU',
  'RW',
  'BL',
  'SH',
  'KN',
  'LC',
  'MF',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SX',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'KR',
  'SS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'UM',
  'US',
  'UY',
  'UZ',
  'VU',
  'VE',
  'VN',
  'VG',
  'VI',
  'WF',
  'EH',
  'YE',
  'ZM',
  'ZW',
]);
const Account = z
  .object({
    uid: z.string(),
    lookup: z.string(),
    email: z.string().email(),
    agreed_to_terms_and_privacy: z.union([z.string(), z.null()]),
    username: z.string(),
    first_name: z.string().max(150).optional(),
    last_name: z.string().max(150),
    date_joined: z.string().datetime({ offset: true }),
    avatar_file: z.union([z.string(), z.null()]).optional(),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    title: TitleEnum.optional(),
    address_line1: z.string().max(255).optional(),
    address_line2: z.string().max(255).optional(),
    address_line3: z.string().max(255).optional(),
    city: z.string().max(255).optional(),
    state: z.string().max(255).optional(),
    postcode: z.string().max(64).optional(),
    country: CountryEnum,
    phone_number: z.string().max(128).optional(),
    disable_help_popup_app: z.boolean().optional(),
    disable_help_popup_portal: z.boolean().optional(),
    initials: z.string().max(3).optional(),
    last_activity: z.union([z.string(), z.null()]),
    language: z.string().max(10).optional(),
    preferred_horse_group: z.union([z.string(), z.null()]).optional(),
    preferred_organisation: z.union([z.string(), z.null()]).optional(),
    preferred_stable: z.union([z.string(), z.null()]).optional(),
    not_searchable: z.boolean().optional(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
  })
  .passthrough();
const PatchedAccount = z
  .object({
    uid: z.string(),
    lookup: z.string(),
    email: z.string().email(),
    agreed_to_terms_and_privacy: z.union([z.string(), z.null()]),
    username: z.string(),
    first_name: z.string().max(150),
    last_name: z.string().max(150),
    date_joined: z.string().datetime({ offset: true }),
    avatar_file: z.union([z.string(), z.null()]),
    date_of_birth: z.union([z.string(), z.null()]),
    title: TitleEnum,
    address_line1: z.string().max(255),
    address_line2: z.string().max(255),
    address_line3: z.string().max(255),
    city: z.string().max(255),
    state: z.string().max(255),
    postcode: z.string().max(64),
    country: CountryEnum,
    phone_number: z.string().max(128),
    disable_help_popup_app: z.boolean(),
    disable_help_popup_portal: z.boolean(),
    initials: z.string().max(3),
    last_activity: z.union([z.string(), z.null()]),
    language: z.string().max(10),
    preferred_horse_group: z.union([z.string(), z.null()]),
    preferred_organisation: z.union([z.string(), z.null()]),
    preferred_stable: z.union([z.string(), z.null()]),
    not_searchable: z.boolean(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
  })
  .passthrough();
const Email = z.object({ email: z.string().email() }).passthrough();
const SSO = z.object({ providers: z.union([z.string(), z.null()]) }).passthrough();
const InviterOrganisation = z.object({ name: z.string() }).passthrough();
const Inviter = z.object({ first_name: z.string(), last_name: z.string() }).passthrough();
const ContactData = z.object({ first_name: z.string().max(255), last_name: z.string().max(255) }).passthrough();
const ReceivedInvitation = z
  .object({
    uuid: z.string().uuid(),
    created_on: z.string().datetime({ offset: true }),
    sent_on: z.union([z.string(), z.null()]),
    resend_on: z.union([z.string(), z.null()]),
    resend_attempts: z.number().int(),
    accepted_on: z.union([z.string(), z.null()]),
    declined_on: z.union([z.string(), z.null()]),
    declined_reason: z.string(),
    inviter_organisation: InviterOrganisation,
    inviter: Inviter,
    contact: z.union([ContactData, z.null()]),
    invitee_email: z.union([z.string(), z.null()]),
  })
  .passthrough();
const PatchedDeclinedInvitation = z.object({ declined_reason: z.string().max(255) }).passthrough();
const DeclinedInvitation = z.object({ declined_reason: z.string().max(255) }).passthrough();
const PaymentStatusEnum = z.enum(['OPEN', 'PROCESSING', 'PAID']);
const PaymentSummary = z.object({ paid: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/) }).passthrough();
const NestedOrder = z
  .object({ uid: z.string(), number: z.string(), public_access_uuid: z.union([z.string(), z.null()]).optional() })
  .passthrough();
const PublicInvoiceOrganisation = z
  .object({
    name: z.string().max(45),
    vat_number: z.string().max(15).optional(),
    company_registration_number: z.string().max(20).optional(),
    email_address: z.string().max(254).email().optional(),
    website: z.string().max(200).url().optional(),
    logo: z.union([z.string(), z.null()]).optional(),
    bank_name: z.string().max(255).optional(),
    account_number: z.string().max(255).optional(),
    bank_code: z.string().max(255).optional(),
    address_line1: z.string().max(255).optional(),
    address_line2: z.string().max(255).optional(),
    address_line3: z.string().max(255).optional(),
    city: z.string().max(255).optional(),
    state: z.string().max(255).optional(),
    postcode: z.string().max(64).optional(),
    country: CountryEnum,
    phone_number: z.string().max(128).optional(),
    show_logo_on_invoice: z.boolean().optional(),
  })
  .passthrough();
const VatCategoryEnum = z.enum(['S', 'AE', 'G']);
const NestedVATPercentage = z
  .object({ percentage: z.string().regex(/^-?\d{0,2}(?:\.\d{0,2})?$/), vat_category: VatCategoryEnum })
  .passthrough();
const NamedHorse = z.object({ uid: z.string(), name: z.string().max(45) }).passthrough();
const Money = z.object({ amount: z.string(), currency: z.string() }).passthrough();
const PublicInvoiceItem = z
  .object({
    uid: z.string(),
    description: z.string(),
    unit_price: z.union([z.string(), z.null()]),
    unit_price_currency: z.union([z.string(), z.null()]),
    category: z.string(),
    quantity: z.union([z.string(), z.null()]),
    qualifier: z.string(),
    vat_percentage_uid: z.union([z.string(), z.null()]),
    vat_percentage: NestedVATPercentage,
    vat_included_in_price: z.boolean(),
    deferred: z.boolean(),
    realactivities: z.array(z.string()),
    service_uid: z.union([z.string(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    transactionitems: z.array(z.string()),
    last_modified_on: z.string().datetime({ offset: true }),
    horse_uid: z.union([z.string(), z.null()]),
    horse: NamedHorse.optional(),
    total_vat: Money,
  })
  .passthrough();
const NameAndAddress = z
  .object({
    initials: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    business_name: z.string(),
    phone_number: z.string(),
    email: z.string().email(),
    address_line1: z.string(),
    address_line2: z.string(),
    address_line3: z.string(),
    country: CountryEnum,
    state: z.string(),
    postcode: z.string(),
    city: z.string(),
    title: TitleEnum,
    vat_number: z.string(),
  })
  .passthrough();
const PublicInvoice = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    customer_uid: z.string(),
    total: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    currency: z.string(),
    total_incl_vat: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    invoice_no: z.string(),
    draft: z.boolean(),
    finalized_on: z.union([z.string(), z.null()]),
    date: z.string(),
    sent: z.boolean(),
    sent_on: z.union([z.string(), z.null()]),
    moneybird_invoice_id: z.union([z.number(), z.null()]),
    exactnl_invoice_id: z.string(),
    yuki_invoice_id: z.string(),
    payment_status: PaymentStatusEnum,
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    start_date_activities: z.union([z.string(), z.null()]),
    end_date_activities: z.union([z.string(), z.null()]),
    start_date_services: z.union([z.string(), z.null()]),
    end_date_services: z.union([z.string(), z.null()]),
    created_by_uid: z.string(),
    expiration_date: z.union([z.string(), z.null()]),
    first_due_interval: z.number().int(),
    payment_summary: PaymentSummary,
    order: NestedOrder.optional(),
    historic_supplier: PublicInvoiceOrganisation,
    language: z.string(),
    items: z.array(PublicInvoiceItem),
    historic_customer: NameAndAddress,
    exactnl_url: z.union([z.string(), z.null()]),
    exactnl_entry_no: z.string(),
    dry_run: z.boolean().optional(),
    can_create_payment_link: z.boolean(),
  })
  .passthrough();
const PaymentLink = z.object({ redirectUrl: z.string().url() }).passthrough();
const Redirect = z.object({ redirect_uri: z.string().url() }).passthrough();
const TokenObtainPair = z.object({ username: z.string(), password: z.string(), access: z.string(), refresh: z.string() }).passthrough();
const TokenRefresh = z.object({ access: z.string(), refresh: z.string() }).passthrough();
const OrganisationOAuth = z.object({ organisation_uid: z.string(), provider_name: z.string() }).passthrough();
const SocialLogin = z.object({ access_token: z.string(), code: z.string(), id_token: z.string() }).passthrough();
const UserDetails = z
  .object({
    pk: z.number().int(),
    username: z
      .string()
      .max(150)
      .regex(/^[\w.@+-]+$/),
    email: z.string().email(),
    first_name: z.string().max(150).optional(),
    last_name: z.string().max(150).optional(),
  })
  .passthrough();
const JWT = z.object({ access: z.string(), refresh: z.string(), user: UserDetails }).passthrough();
const SemenTypeEnum = z.enum(['FROZEN', 'FRESH']);
const UsageTypeEnum = z.enum(['KI', 'ET', 'ICSI']);
const StudbookEnum = z.enum([
  'ACE',
  'AHHA',
  'RHEIN US',
  'ATA',
  'AWR',
  'AES',
  'AWÖ',
  'AAFE',
  'AECCAá',
  'PRE',
  'CDE',
  'BH',
  'PSH',
  'LUSIT',
  'AA',
  'RHEIN AU',
  'AWHA',
  'BWB',
  'BWP',
  'BE/SIES',
  'RHEIN GB',
  'BSH',
  'CSHA',
  'CWHBA',
  'CCDM',
  'CABSH',
  'DWB',
  'EBHA',
  'ESH',
  'HANN',
  'HHSA',
  'HSH',
  'ISR-OLD NA',
  'TB',
  'ISH',
  'MASAF',
  'KFPS',
  'KWPN',
  'KWPN NA',
  'DSP (BAVAR)',
  'LWB',
  'LHBA',
  'NRPS',
  'NZHS',
  'RHEIN NZ',
  'NZWB',
  'NAS',
  'NDWB',
  'NWB',
  'DSP (BAD-WÜ)',
  'DSP (BRAND)',
  'DSP (ZWEIB)',
  'SA',
  'SACHS',
  'DSP (SATHÜ)',
  'RHEIN',
  'SAWHS',
  'SHBGB',
  'OS',
  'SF',
  'SCSL',
  'SBS',
  'ZANG',
  'ChS',
  'PZHK (M)',
  'PZHK (SP)',
  'PZHK (WLKP)',
  'SSW',
  'SLS',
  'CZEWB',
  'SWB',
  'AHS',
  'BHHS',
  'FWB',
  'ZCHKS',
  'TAF',
  'MECKL',
  'THU',
  'HOLST',
  'OLDBG',
  'TRAK',
  'HESS',
  'BWBS',
  'WSI',
  'WESTF',
  'CH',
  'ZfDP',
]);
const PickedStallionMount = z.object({ stallion_mount: z.string(), amount: z.number().int().gte(0).lte(65535) }).passthrough();
const SupplierOrderItemDetail = z
  .object({
    uid: z.string().optional(),
    order_uid: z.string(),
    product_uid: z.string(),
    unit_price: z
      .string()
      .regex(/^-?\d{0,8}(?:\.\d{0,2})?$/)
      .optional(),
    unit_price_currency: z.string(),
    upfront_payment: z.union([z.string(), z.null()]).optional(),
    upfront_payment_currency: z.union([z.string(), z.null()]),
    vat_percentage: NestedVATPercentage,
    quantity: z.number().int().gte(0).lte(65535).optional(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    last_modified_on: z.string().datetime({ offset: true }),
    picked_on: z.union([z.string(), z.null()]),
    picked_by_uid: z.union([z.string(), z.null()]),
    mare_uid: z.union([z.string(), z.null()]).optional(),
    semen_type: SemenTypeEnum.optional(),
    usage_type: UsageTypeEnum.optional(),
    studbook: StudbookEnum.optional(),
    traces: z.string().max(64).optional(),
    previous_semen_order_item_uid: z.union([z.string(), z.null()]).optional(),
    total_vat: Money,
    pickedstallionmount_set: z.array(PickedStallionMount),
  })
  .passthrough();
const SupplierOrderDetail = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    number: z.string(),
    customer_uid: z.string(),
    requester_uid: z.union([z.string(), z.null()]).optional(),
    receiver_uid: z.string().optional(),
    supplier_note: z.string().optional(),
    customer_note: z.string(),
    created_on: z.string().datetime({ offset: true }),
    created_by: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    last_modified_by: z.string(),
    shipping_date: z.union([z.string(), z.null()]).optional(),
    payment_summary: PaymentSummary,
    dry_run: z.boolean().optional(),
    supplier_uid: z.string(),
    order_items: z.array(SupplierOrderItemDetail).optional(),
    historic_receiver: NameAndAddress,
    historic_customer: NameAndAddress,
    historic_requester: NameAndAddress,
  })
  .passthrough();
const PurchaserOrderItem = z
  .object({
    uid: z.string(),
    order_uid: z.string(),
    product_uid: z.string().optional(),
    unit_price: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    unit_price_currency: z.string(),
    upfront_payment: z.union([z.string(), z.null()]),
    upfront_payment_currency: z.union([z.string(), z.null()]),
    vat_percentage: NestedVATPercentage,
    quantity: z.number().int().gte(0).lte(65535).optional(),
    last_modified_by_uid: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    picked_on: z.union([z.string(), z.null()]),
    picked_by_uid: z.string(),
    mare_uid: z.string().optional(),
    semen_type: SemenTypeEnum.optional(),
    usage_type: UsageTypeEnum.optional(),
    studbook: StudbookEnum.optional(),
    traces: z.string().max(64).optional(),
    previous_semen_order_item_uid: z.string().optional(),
    total_vat: Money,
  })
  .passthrough();
const PurchaserOrderUpdate = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    number: z.string(),
    customer_uid: z.string(),
    receiver_uid: z.string(),
    purchaser_uid: z.union([z.string(), z.null()]).optional(),
    supplier: z.string().uuid(),
    customer_note: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    payment_summary: PaymentSummary,
    shipping_date: z.union([z.string(), z.null()]),
    order_items: z.array(PurchaserOrderItem),
    dry_run: z.boolean().optional(),
    historic_receiver: NameAndAddress,
  })
  .passthrough();
const PatchedPurchaserOrderUpdate = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    number: z.string(),
    customer_uid: z.string(),
    receiver_uid: z.string(),
    purchaser_uid: z.union([z.string(), z.null()]),
    supplier: z.string().uuid(),
    customer_note: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    payment_summary: PaymentSummary,
    shipping_date: z.union([z.string(), z.null()]),
    order_items: z.array(PurchaserOrderItem),
    dry_run: z.boolean(),
    historic_receiver: NameAndAddress,
  })
  .passthrough();
const RoleNames = z.object({ uid: z.string(), name: z.string(), default_id: z.union([z.number(), z.null()]) }).passthrough();
const StableNames = z.object({ uid: z.string(), name: z.string() }).passthrough();
const OrganisationContact = z.object({ uid: z.string(), roles: z.array(RoleNames), stables: z.array(StableNames) }).passthrough();
const OrganisationList = z.object({ uid: z.string(), name: z.string().max(45), me: OrganisationContact }).passthrough();
const TimezoneEnum = z.enum([
  'Asia/Macau',
  'Africa/Algiers',
  'Asia/Seoul',
  'Europe/Kaliningrad',
  'Turkey',
  'Africa/Accra',
  'America/Vancouver',
  'Greenwich',
  'Asia/Vientiane',
  'Africa/Monrovia',
  'America/Detroit',
  'Europe/Kyiv',
  'GB',
  'Asia/Ujung_Pandang',
  'Europe/Samara',
  'Africa/Banjul',
  'Chile/EasterIsland',
  'Africa/Libreville',
  'Antarctica/Rothera',
  'Asia/Katmandu',
  'Brazil/East',
  'Pacific/Midway',
  'Etc/GMT-12',
  'America/Mazatlan',
  'Africa/Asmara',
  'Asia/Kamchatka',
  'Pacific/Kwajalein',
  'Asia/Bangkok',
  'America/Rainy_River',
  'Asia/Bahrain',
  'Indian/Cocos',
  'Etc/GMT+12',
  'Etc/GMT+6',
  'America/Asuncion',
  'America/Aruba',
  'Etc/Zulu',
  'America/Dawson_Creek',
  'Europe/Vatican',
  'America/Metlakatla',
  'America/El_Salvador',
  'America/Tijuana',
  'Asia/Rangoon',
  'Europe/Zaporozhye',
  'Atlantic/Madeira',
  'America/Lima',
  'Europe/Jersey',
  'Antarctica/Troll',
  'Africa/Kigali',
  'America/Menominee',
  'America/Indiana/Knox',
  'Kwajalein',
  'US/Central',
  'America/Costa_Rica',
  'Pacific/Pohnpei',
  'HST',
  'WET',
  'Asia/Harbin',
  'Asia/Khandyga',
  'Etc/GMT0',
  'America/Buenos_Aires',
  'America/St_Thomas',
  'America/Ciudad_Juarez',
  'Asia/Kolkata',
  'Asia/Ashkhabad',
  'Etc/GMT-9',
  'Pacific/Kiritimati',
  'America/Adak',
  'America/Indiana/Indianapolis',
  'America/Argentina/Mendoza',
  'Brazil/DeNoronha',
  'Mexico/BajaNorte',
  'Etc/GMT-7',
  'America/Dominica',
  'Australia/Canberra',
  'Africa/Lagos',
  'Australia/Lord_Howe',
  'America/Phoenix',
  'Etc/GMT+10',
  'Pacific/Kanton',
  'Africa/Niamey',
  'America/Creston',
  'EET',
  'Europe/Istanbul',
  'Africa/El_Aaiun',
  'America/Thule',
  'America/Cordoba',
  'Asia/Tbilisi',
  'Eire',
  'CET',
  'Asia/Baku',
  'Asia/Jakarta',
  'Africa/Mogadishu',
  'America/Halifax',
  'Atlantic/Canary',
  'Europe/Minsk',
  'America/St_Kitts',
  'Africa/Nouakchott',
  'Australia/LHI',
  'America/Indiana/Petersburg',
  'Antarctica/Palmer',
  'Asia/Sakhalin',
  'Asia/Singapore',
  'UTC',
  'Asia/Oral',
  'Canada/Eastern',
  'Europe/Skopje',
  'America/Yellowknife',
  'GMT',
  'Africa/Maputo',
  'America/Sao_Paulo',
  'Asia/Qostanay',
  'Australia/Melbourne',
  'Etc/GMT',
  'Asia/Magadan',
  'Atlantic/Stanley',
  'America/Anguilla',
  'Pacific/Rarotonga',
  'Pacific/Pago_Pago',
  'GMT+0',
  'Indian/Mahe',
  'Asia/Qyzylorda',
  'Singapore',
  'Europe/Ljubljana',
  'Pacific/Guam',
  'Pacific/Johnston',
  'America/Kentucky/Louisville',
  'Pacific/Pitcairn',
  'Pacific/Chuuk',
  'ROK',
  'Brazil/West',
  'Chile/Continental',
  'Asia/Chungking',
  'Asia/Yerevan',
  'America/Argentina/Salta',
  'Africa/Juba',
  'America/Grand_Turk',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'America/Argentina/Buenos_Aires',
  'America/Sitka',
  'Arctic/Longyearbyen',
  'America/Jamaica',
  'Europe/Simferopol',
  'America/Atikokan',
  'Africa/Tunis',
  'Pacific/Nauru',
  'America/Pangnirtung',
  'Canada/Atlantic',
  'Africa/Conakry',
  'Pacific/Apia',
  'Antarctica/Casey',
  'Atlantic/Reykjavik',
  'Africa/Lome',
  'America/Shiprock',
  'Europe/Berlin',
  'Antarctica/Vostok',
  'Europe/Tallinn',
  'Pacific/Palau',
  'Europe/Sarajevo',
  'Atlantic/Bermuda',
  'Europe/Malta',
  'America/Guatemala',
  'America/Inuvik',
  'Europe/Belgrade',
  'Pacific/Enderbury',
  'Etc/GMT+2',
  'America/Argentina/Rio_Gallegos',
  'Zulu',
  'Asia/Aqtau',
  'Poland',
  'US/Michigan',
  'America/Belem',
  'Australia/Broken_Hill',
  'EST5EDT',
  'Asia/Barnaul',
  'Pacific/Galapagos',
  'America/Rankin_Inlet',
  'Asia/Shanghai',
  'NZ-CHAT',
  'America/Maceio',
  'America/Indiana/Vevay',
  'Pacific/Tarawa',
  'US/Alaska',
  'Africa/Malabo',
  'US/Pacific',
  'America/Mendoza',
  'Europe/Nicosia',
  'America/Barbados',
  'America/Danmarkshavn',
  'Asia/Thimphu',
  'Indian/Mayotte',
  'Hongkong',
  'Asia/Dushanbe',
  'Asia/Pontianak',
  'America/Indiana/Vincennes',
  'Asia/Karachi',
  'Australia/South',
  'Africa/Mbabane',
  'America/Montreal',
  'Africa/Casablanca',
  'Asia/Aqtobe',
  'Asia/Atyrau',
  'America/Ensenada',
  'America/Argentina/San_Luis',
  'America/Bahia_Banderas',
  'Asia/Beirut',
  'Europe/Athens',
  'America/Caracas',
  'Africa/Lubumbashi',
  'Australia/Brisbane',
  'America/St_Barthelemy',
  'Africa/Harare',
  'Portugal',
  'Asia/Hong_Kong',
  'Indian/Kerguelen',
  'America/Port_of_Spain',
  'Europe/Saratov',
  'America/Recife',
  'Africa/Bangui',
  'America/Santo_Domingo',
  'Libya',
  'America/Nome',
  'Asia/Tel_Aviv',
  'America/Glace_Bay',
  'Australia/Eucla',
  'Africa/Freetown',
  'Asia/Hebron',
  'America/Argentina/Catamarca',
  'America/Punta_Arenas',
  'Asia/Jayapura',
  'US/Aleutian',
  'America/Araguaina',
  'GMT-0',
  'MST7MDT',
  'Pacific/Fiji',
  'Canada/Central',
  'America/Santarem',
  'Indian/Christmas',
  'America/Ojinaga',
  'Asia/Baghdad',
  'Europe/Luxembourg',
  'Asia/Ust-Nera',
  'Europe/Zagreb',
  'Asia/Macao',
  'Antarctica/Macquarie',
  'W-SU',
  'Asia/Makassar',
  'Etc/GMT+8',
  'Etc/GMT-3',
  'Asia/Ulaanbaatar',
  'Etc/GMT-1',
  'America/Swift_Current',
  'America/Dawson',
  'Europe/Paris',
  'Europe/San_Marino',
  'Pacific/Yap',
  'Africa/Lusaka',
  'Etc/GMT+5',
  'Etc/GMT-5',
  'Africa/Luanda',
  'Europe/Brussels',
  'America/Indianapolis',
  'Pacific/Wake',
  'America/Argentina/ComodRivadavia',
  'Asia/Srednekolymsk',
  'America/Argentina/Jujuy',
  'Pacific/Truk',
  'Asia/Famagusta',
  'America/Tortola',
  'Jamaica',
  'Africa/Bamako',
  'America/Porto_Velho',
  'Africa/Porto-Novo',
  'Asia/Saigon',
  'Asia/Muscat',
  'Pacific/Niue',
  'Asia/Taipei',
  'Asia/Yakutsk',
  'Pacific/Kosrae',
  'Asia/Dacca',
  'America/North_Dakota/Center',
  'Europe/Vaduz',
  'Canada/Mountain',
  'Asia/Urumqi',
  'Australia/NSW',
  'America/Paramaribo',
  'Europe/Moscow',
  'Europe/Prague',
  'Asia/Choibalsan',
  'America/Eirunepe',
  'America/Boise',
  'America/Thunder_Bay',
  'America/Hermosillo',
  'Pacific/Wallis',
  'Asia/Dhaka',
  'Africa/Ndjamena',
  'Asia/Ashgabat',
  'Asia/Amman',
  'Africa/Maseru',
  'America/Guyana',
  'Atlantic/Cape_Verde',
  'Cuba',
  'Europe/London',
  'GMT0',
  'America/Guadeloupe',
  'America/Curacao',
  'Antarctica/Davis',
  'America/Matamoros',
  'localtime',
  'Pacific/Saipan',
  'Asia/Calcutta',
  'Atlantic/Azores',
  'Pacific/Port_Moresby',
  'Africa/Kinshasa',
  'Pacific/Chatham',
  'America/Martinique',
  'Asia/Kathmandu',
  'Mexico/BajaSur',
  'America/Guayaquil',
  'Australia/Currie',
  'Indian/Mauritius',
  'Asia/Jerusalem',
  'Africa/Brazzaville',
  'America/Resolute',
  'Asia/Tehran',
  'Africa/Nairobi',
  'Africa/Djibouti',
  'Asia/Anadyr',
  'Europe/Stockholm',
  'America/Atka',
  'America/Montserrat',
  'America/Kralendijk',
  'America/Iqaluit',
  'Pacific/Tongatapu',
  'Africa/Johannesburg',
  'America/Knox_IN',
  'Australia/Darwin',
  'America/Virgin',
  'America/Kentucky/Monticello',
  'America/Cayenne',
  'America/Montevideo',
  'Etc/GMT-14',
  'Mexico/General',
  'Europe/Kirov',
  'Africa/Cairo',
  'Asia/Yekaterinburg',
  'Asia/Chongqing',
  'Pacific/Ponape',
  'America/North_Dakota/Beulah',
  'Africa/Ouagadougou',
  'Antarctica/South_Pole',
  'Etc/GMT+9',
  'Africa/Gaborone',
  'America/Campo_Grande',
  'PST8PDT',
  'Asia/Ulan_Bator',
  'MST',
  'Asia/Irkutsk',
  'Africa/Asmera',
  'America/Toronto',
  'Australia/Queensland',
  'Europe/Madrid',
  'America/La_Paz',
  'Asia/Qatar',
  'Asia/Chita',
  'Asia/Phnom_Penh',
  'America/Winnipeg',
  'America/Anchorage',
  'Etc/GMT-2',
  'America/Fort_Wayne',
  'America/Rio_Branco',
  'EST',
  'US/Indiana-Starke',
  'Asia/Kuwait',
  'America/Tegucigalpa',
  'Etc/GMT-4',
  'America/Manaus',
  'Etc/GMT+3',
  'Australia/West',
  'America/Indiana/Marengo',
  'Europe/Chisinau',
  'Asia/Tomsk',
  'Europe/Copenhagen',
  'Indian/Comoro',
  'Asia/Krasnoyarsk',
  'Atlantic/Jan_Mayen',
  'Europe/Belfast',
  'America/Port-au-Prince',
  'America/Fortaleza',
  'Canada/Newfoundland',
  'Australia/Tasmania',
  'Europe/Bucharest',
  'America/Managua',
  'Europe/Tirane',
  'America/Chicago',
  'Australia/Hobart',
  'Africa/Ceuta',
  'America/Argentina/San_Juan',
  'America/Nuuk',
  'US/Samoa',
  'America/Lower_Princes',
  'Canada/Yukon',
  'Africa/Khartoum',
  'Europe/Podgorica',
  'America/Godthab',
  'America/Noronha',
  'Asia/Samarkand',
  'America/Juneau',
  'Pacific/Auckland',
  'America/Marigot',
  'Asia/Bishkek',
  'America/Moncton',
  'PRC',
  'Europe/Zurich',
  'Pacific/Efate',
  'America/Jujuy',
  'Australia/Adelaide',
  'Africa/Kampala',
  'CST6CDT',
  'Egypt',
  'Pacific/Tahiti',
  'Canada/Saskatchewan',
  'Antarctica/Syowa',
  'Australia/North',
  'Africa/Blantyre',
  'America/Bahia',
  'America/Cambridge_Bay',
  'America/Belize',
  'Indian/Reunion',
  'America/Miquelon',
  'Etc/GMT+1',
  'Europe/Dublin',
  'US/Arizona',
  'America/Boa_Vista',
  'Japan',
  'Pacific/Bougainville',
  'Africa/Addis_Ababa',
  'Asia/Aden',
  'Africa/Bissau',
  'America/Argentina/La_Rioja',
  'America/Argentina/Ushuaia',
  'America/Grenada',
  'America/Coral_Harbour',
  'Europe/Monaco',
  'Europe/Volgograd',
  'Antarctica/DumontDUrville',
  'Israel',
  'Pacific/Funafuti',
  'Etc/UTC',
  'Canada/Pacific',
  'Asia/Vladivostok',
  'Antarctica/Mawson',
  'America/Santa_Isabel',
  'Etc/GMT-13',
  'Asia/Kabul',
  'Asia/Colombo',
  'America/Nipigon',
  'Australia/Lindeman',
  'Iceland',
  'Pacific/Guadalcanal',
  'America/Edmonton',
  'Europe/Vilnius',
  'America/St_Johns',
  'Europe/Riga',
  'Europe/Warsaw',
  'Europe/Gibraltar',
  'America/Nassau',
  'Atlantic/Faroe',
  'Africa/Abidjan',
  'Asia/Kashgar',
  'Europe/Astrakhan',
  'US/Hawaii',
  'Asia/Nicosia',
  'America/Cayman',
  'America/Cancun',
  'America/Argentina/Tucuman',
  'Asia/Yangon',
  'America/Indiana/Winamac',
  'Asia/Ho_Chi_Minh',
  'America/Indiana/Tell_City',
  'Etc/GMT-6',
  'Africa/Windhoek',
  'Pacific/Gambier',
  'Europe/Mariehamn',
  'America/Denver',
  'America/Mexico_City',
  'America/Cuiaba',
  'Europe/Guernsey',
  'America/Bogota',
  'Asia/Brunei',
  'Etc/GMT-0',
  'America/Scoresbysund',
  'America/Monterrey',
  'Europe/Rome',
  'Pacific/Fakaofo',
  'US/Mountain',
  'Indian/Antananarivo',
  'Etc/GMT-8',
  'America/Fort_Nelson',
  'Africa/Timbuktu',
  'America/Merida',
  'Etc/GMT+7',
  'Asia/Pyongyang',
  'America/Havana',
  'Asia/Dili',
  'Asia/Tokyo',
  'Australia/Perth',
  'Australia/Yancowinna',
  'Atlantic/Faeroe',
  'Etc/GMT-10',
  'Australia/ACT',
  'Europe/Andorra',
  'Pacific/Noumea',
  'America/St_Lucia',
  'Asia/Almaty',
  'America/Yakutat',
  'America/Louisville',
  'GB-Eire',
  'Etc/GMT+4',
  'Europe/Ulyanovsk',
  'Australia/Victoria',
  'America/Chihuahua',
  'Africa/Dar_es_Salaam',
  'America/Puerto_Rico',
  'Asia/Riyadh',
  'Australia/Sydney',
  'Asia/Thimbu',
  'America/Catamarca',
  'America/Blanc-Sablon',
  'America/Los_Angeles',
  'Asia/Novokuznetsk',
  'Europe/Amsterdam',
  'Asia/Kuala_Lumpur',
  'Pacific/Majuro',
  'Etc/Greenwich',
  'Iran',
  'Europe/Oslo',
  'Asia/Manila',
  'Europe/Sofia',
  'Europe/Vienna',
  'Europe/Busingen',
  'Indian/Maldives',
  'MET',
  'Africa/Tripoli',
  'Europe/Lisbon',
  'Africa/Dakar',
  'Atlantic/St_Helena',
  'America/St_Vincent',
  'Atlantic/South_Georgia',
  'America/Panama',
  'US/East-Indiana',
  'Asia/Istanbul',
  'America/Antigua',
  'UCT',
  'Etc/UCT',
  'Europe/Bratislava',
  'Pacific/Easter',
  'Europe/Uzhgorod',
  'America/New_York',
  'America/Rosario',
  'Asia/Omsk',
  'Europe/Tiraspol',
  'Universal',
  'Africa/Sao_Tome',
  'Pacific/Honolulu',
  'Asia/Hovd',
  'Asia/Dubai',
  'America/Regina',
  'Europe/Kiev',
  'Etc/Universal',
  'Antarctica/McMurdo',
  'Etc/GMT+11',
  'America/Porto_Acre',
  'Pacific/Samoa',
  'Asia/Damascus',
  'Africa/Bujumbura',
  'America/Santiago',
  'Asia/Kuching',
  'Africa/Douala',
  'Europe/Budapest',
  'ROC',
  'America/North_Dakota/New_Salem',
  'Asia/Novosibirsk',
  'US/Eastern',
  'Brazil/Acre',
  'Indian/Chagos',
  'America/Argentina/Cordoba',
  'Asia/Tashkent',
  'Navajo',
  'Pacific/Norfolk',
  'NZ',
  'America/Whitehorse',
  'America/Goose_Bay',
  'Asia/Gaza',
  'Etc/GMT-11',
  'Pacific/Marquesas',
  'Etc/GMT+0',
]);
const TypeOfOrganisationEnum = z.union([z.literal(0), z.literal(1), z.literal(2)]);
const EnabledModulesEnum = z.enum(['horses', 'breeding', 'shop', 'financial', 'contacts', 'activities', 'feed']);
const Organisation = z
  .object({
    uid: z.string(),
    created_by_uid: z.string(),
    last_modified_by_uid: z.string(),
    public_access_uuid: z.string().uuid(),
    name: z.string().max(45),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    is_paid: z.boolean(),
    is_bp: z.boolean(),
    shop_terms_conditions: z.union([z.string(), z.null()]).optional(),
    timezone: TimezoneEnum.optional(),
    expected_no_horses: z.union([z.number(), z.null()]),
    weeks_between_insemination_and_vaccination: z.union([z.number(), z.null()]).optional(),
    days_between_insemination_and_pregnancy_check: z.union([z.number(), z.null()]).optional(),
    equiboard_refresh_rate: z.number().int().gte(0).lte(60).optional(),
    equiboard_screensaver_time: z.number().gte(0).lte(60).optional(),
    equiboard_lockscreendarkness: z.number().gte(0).lte(1).optional(),
    vat_number: z.string().max(15).optional(),
    email_address: z.string().max(254).email().optional(),
    also_send_invoice_to_email: z.string().max(254).email().optional(),
    website: z.string().max(200).url().optional(),
    logo: z.union([z.string(), z.null()]).optional(),
    invoice_subject: z.string().max(45).optional(),
    invoice_text: z.string().optional(),
    currency: z.string().max(3).optional(),
    bank_name: z.string().max(255).optional(),
    account_number: z.string().max(255).optional(),
    bank_code: z.string().max(255).optional(),
    invoice_footer_1: z.string().optional(),
    invoice_footer_2: z.string().optional(),
    max_no_horses: z.union([z.number(), z.null()]).optional(),
    last_information_send: z.union([z.string(), z.null()]),
    number_of_information_send_last_day: z.union([z.number(), z.null()]),
    relatienummer_rvo: z.string(),
    type_of_organisation: TypeOfOrganisationEnum,
    enabled_modules: z.array(EnabledModulesEnum),
    invoice_email_by_equinem: z.boolean().optional(),
    primary_color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
    secondary_color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
    show_logo_on_invoice: z.boolean().optional(),
    suggested_breeding_product_down_payment: z
      .string()
      .regex(/^-?\d{0,8}(?:\.\d{0,2})?$/)
      .optional(),
    down_payment_permitted_countries: z.array(CountryEnum).max(249),
    invoice_first_due_interval: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    can_create_payment_link: z.boolean(),
    company_registration_number: z.string().max(20).optional(),
    address_line1: z.string().max(255).optional(),
    address_line2: z.string().max(255).optional(),
    address_line3: z.string().max(255).optional(),
    city: z.string().max(255).optional(),
    state: z.string().max(255).optional(),
    postcode: z.string().max(64).optional(),
    country: CountryEnum,
    phone_number: z.string().max(128).optional(),
    only_use_ad: z.boolean(),
    microsoft_ad_group_id: z.string(),
    exactnl_sales_journal: z.string().max(100).optional(),
  })
  .passthrough();
const ActivityTypePrice = z
  .object({
    uid: z.string(),
    activity_type_uid: z.string(),
    price: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    price_currency: z.string(),
    vat_percentage_uid: z.string(),
    category: z.string(),
    vat_included_in_price: z.boolean().optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
  })
  .passthrough();
const PatchedActivityTypePrice = z
  .object({
    uid: z.string(),
    activity_type_uid: z.string(),
    price: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    price_currency: z.string(),
    vat_percentage_uid: z.string(),
    category: z.string(),
    vat_included_in_price: z.boolean(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
  })
  .passthrough();
const FertilizerBudgetTypeEnum = z.enum(['ANIMAL_NITROGEN', 'TOTAL_NITROGEN', 'TOTAL_PHOSPHORUS']);
const FertilizerConsumer = z
  .object({
    uid: z.string(),
    contact: z.string(),
    description: z.string().max(64),
    fertilizer_budget_type: FertilizerBudgetTypeEnum,
    is_follow_up_crop: z.boolean().optional(),
    year: z.number().int().gte(2000).lte(2099),
    has_derogation: z.boolean().optional(),
    area: z.number().int().gte(0).lte(65535),
    consumption_norm: z.number().int().gte(0).lte(65535),
    total: z.number().int(),
  })
  .passthrough();
const PaginatedFertilizerConsumerList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(FertilizerConsumer),
  })
  .passthrough();
const PatchedFertilizerConsumer = z
  .object({
    uid: z.string(),
    contact: z.string(),
    description: z.string().max(64),
    fertilizer_budget_type: FertilizerBudgetTypeEnum,
    is_follow_up_crop: z.boolean(),
    year: z.number().int().gte(2000).lte(2099),
    has_derogation: z.boolean(),
    area: z.number().int().gte(0).lte(65535),
    consumption_norm: z.number().int().gte(0).lte(65535),
    total: z.number().int(),
  })
  .passthrough();
const FertilizerOriginEnum = z.enum([
  'ANIMAL_MANURE_SUPPLY',
  'MILK_PRODUCING_COWS',
  'GRAZING_ANIMALS',
  'STABLE_ANIMALS_FEED',
  'OTHER_ORGANIC_SUPPLY',
  'ANORGANIC_SUPPLY',
]);
const MutationTypeEnum = z.enum(['BEGIN_AMOUNT', 'IMPORT', 'PRODUCTION', 'EXPORT', 'END_AMOUNT']);
const OperatorEnum = z.enum(['ADD', 'SUBTRACT']);
const ProductionBalanceItem = z
  .object({
    uid: z.string(),
    contact: z.string(),
    year: z.number().int().gte(2000).lte(2099),
    description: z.string().max(32),
    fertilizer_origin: FertilizerOriginEnum,
    mutation_type: MutationTypeEnum,
    nitrogen_weight: z.number().int().gte(0).lte(65535).optional(),
    phosphor_weight: z.number().int().gte(0).lte(65535).optional(),
    operator: OperatorEnum,
    is_animal_manure: z.boolean(),
  })
  .passthrough();
const PaginatedProductionBalanceItemList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(ProductionBalanceItem),
  })
  .passthrough();
const PatchedProductionBalanceItem = z
  .object({
    uid: z.string(),
    contact: z.string(),
    year: z.number().int().gte(2000).lte(2099),
    description: z.string().max(32),
    fertilizer_origin: FertilizerOriginEnum,
    mutation_type: MutationTypeEnum,
    nitrogen_weight: z.number().int().gte(0).lte(65535),
    phosphor_weight: z.number().int().gte(0).lte(65535),
    operator: OperatorEnum,
    is_animal_manure: z.boolean(),
  })
  .passthrough();
const NitrogenApplication = z
  .object({
    uid: z.string(),
    contact: z.string(),
    year: z.number().int().gte(2000).lte(2099),
    fertilizer_description: z.string().max(128),
    application_description: z.string().max(128).optional(),
    is_animal_manure: z.boolean(),
    nitrogen_weight: z.number().int().gte(0).lte(65535),
    application_coefficient: z.number().int().gte(0).lte(100).optional(),
    effective_nitrogen_weight: z.number().int(),
  })
  .passthrough();
const PaginatedNitrogenApplicationList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(NitrogenApplication),
  })
  .passthrough();
const PatchedNitrogenApplication = z
  .object({
    uid: z.string(),
    contact: z.string(),
    year: z.number().int().gte(2000).lte(2099),
    fertilizer_description: z.string().max(128),
    application_description: z.string().max(128),
    is_animal_manure: z.boolean(),
    nitrogen_weight: z.number().int().gte(0).lte(65535),
    application_coefficient: z.number().int().gte(0).lte(100),
    effective_nitrogen_weight: z.number().int(),
  })
  .passthrough();
const ServiceContact = z
  .object({
    uid: z.string(),
    start_date: z.string(),
    end_date: z.union([z.string(), z.null()]).optional(),
    price: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    price_currency: z.string(),
    vat_percentage_uid: z.string(),
    customer_uid: z.string(),
    service_uid: z.string(),
    vat_included_in_price: z.boolean().optional(),
    horse_uid: z.union([z.string(), z.null()]).optional(),
    created_by_uid: z.union([z.string(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
  })
  .passthrough();
const PatchedServiceContact = z
  .object({
    uid: z.string(),
    start_date: z.string(),
    end_date: z.union([z.string(), z.null()]),
    price: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    price_currency: z.string(),
    vat_percentage_uid: z.string(),
    customer_uid: z.string(),
    service_uid: z.string(),
    vat_included_in_price: z.boolean(),
    horse_uid: z.union([z.string(), z.null()]),
    created_by_uid: z.union([z.string(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
  })
  .passthrough();
const PrivateEnum = z.union([z.literal(1), z.literal(2), z.literal(3)]);
const HorseMedia = z
  .object({
    uid: z.string(),
    file: z.union([z.string(), z.null()]).optional(),
    file_download_url: z.string(),
    filename: z.string().max(255),
    description: z.string().max(255).optional(),
    YTVim: z.union([z.string(), z.null()]).optional(),
    private: PrivateEnum.optional(),
    horse_uid: z.string(),
    content_type: z.string().max(255).optional(),
    created_on: z.string().datetime({ offset: true }),
  })
  .passthrough();
const PaginatedHorseMediaList = z
  .object({ count: z.number().int(), next: z.string().url().nullish(), previous: z.string().url().nullish(), results: z.array(HorseMedia) })
  .passthrough();
const HorseMediaUpdate = z
  .object({
    uid: z.string(),
    description: z.string().max(255).optional(),
    content_type: z.string().max(255).optional(),
    filename: z.string().max(255),
  })
  .passthrough();
const PatchedHorseMediaUpdate = z
  .object({ uid: z.string(), description: z.string().max(255), content_type: z.string().max(255), filename: z.string().max(255) })
  .passthrough();
const HorseOwner = z
  .object({
    uid: z.string(),
    horse_uid: z.string(),
    contact_uid: z.string(),
    percentage: z
      .string()
      .regex(/^-?\d{0,3}(?:\.\d{0,2})?$/)
      .optional(),
    owner_since: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough();
const PaginatedHorseOwnerList = z
  .object({ count: z.number().int(), next: z.string().url().nullish(), previous: z.string().url().nullish(), results: z.array(HorseOwner) })
  .passthrough();
const PatchedHorseOwner = z
  .object({
    uid: z.string(),
    horse_uid: z.string(),
    contact_uid: z.string(),
    percentage: z.string().regex(/^-?\d{0,3}(?:\.\d{0,2})?$/),
    owner_since: z.union([z.string(), z.null()]),
  })
  .passthrough();
const ReportTypeEnum = z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4), z.literal(5), z.literal(6), z.literal(7)]);
const ReportedHorseRelocationStatusEnum = z.enum(['IC', 'DG', 'VG', 'IT', 'ID', 'HE']);
const ReportedHorseRelocation = z
  .object({
    uid: z.string(),
    horse_uid: z.string(),
    horselocation_uid: z.union([z.string(), z.null()]).optional(),
    report_type: ReportTypeEnum,
    meldingnummer: z.string().max(30).optional(),
    last_modified_by_uid: z.union([z.string(), z.null()]).optional(),
    created_by_uid: z.union([z.string(), z.null()]).optional(),
    last_modified_on: z.string().datetime({ offset: true }),
    external_location: z.string().max(100).optional(),
    status: ReportedHorseRelocationStatusEnum.optional(),
    statusOms: z.string().optional(),
    created_on: z.string().datetime({ offset: true }),
    date: z.string(),
  })
  .passthrough();
const PaginatedReportedHorseRelocationList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(ReportedHorseRelocation),
  })
  .passthrough();
const CreateReportedHorseRelocation = z
  .object({
    horselocation_uid: z.string(),
    report_type: ReportTypeEnum,
    submit: z.boolean().optional().default(false),
    dierHaarKleur: z.string().max(3).optional(),
    dierHerkomstLandCode: z.string().max(3).optional(),
    dierBestemmingLandCode: z.string().max(3).optional(),
  })
  .passthrough();
const RvOEUCountry = z
  .object({ code: z.string(), naam: z.string(), isoNummerCode: z.string(), datumIngang: z.string(), datumEinde: z.string().optional() })
  .passthrough();
const RvOHorseHairColor = z
  .object({
    code: z.string(),
    omschrijving: z.string(),
    dierSoort: z.number().int(),
    datumIngang: z.string(),
    datumEinde: z.string().optional(),
  })
  .passthrough();
const SentInvitation = z
  .object({
    uuid: z.string().uuid(),
    created_on: z.string().datetime({ offset: true }),
    sent_on: z.union([z.string(), z.null()]),
    resend_on: z.union([z.string(), z.null()]),
    resend_attempts: z.number().int(),
    accepted_on: z.union([z.string(), z.null()]),
    declined_on: z.union([z.string(), z.null()]),
    declined_reason: z.string(),
    contact: z.union([ContactData, z.null()]),
    invitee_email: z.union([z.string(), z.null()]),
    contact_uid: z.union([z.string(), z.null()]).optional(),
    roles: z.array(z.string()),
    stables: z.array(z.string()),
    inviter_organisation_uid: z.string(),
    inviter_uid: z.string(),
  })
  .passthrough();
const InvoiceItem = z
  .object({
    uid: z.string().optional(),
    description: z.string().max(100),
    unit_price: z.union([z.string(), z.null()]).optional(),
    unit_price_currency: z.union([z.string(), z.null()]),
    category: z.string(),
    quantity: z.union([z.string(), z.null()]).optional(),
    qualifier: z.string().max(15).optional(),
    vat_percentage_uid: z.union([z.string(), z.null()]).optional(),
    vat_percentage: NestedVATPercentage,
    vat_included_in_price: z.boolean().optional(),
    deferred: z.boolean().optional(),
    realactivities: z.array(z.string()).optional(),
    service_uid: z.union([z.string(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    transactionitems: z.array(z.string()).optional(),
    last_modified_on: z.string().datetime({ offset: true }),
    horse_uid: z.union([z.string(), z.null()]).optional(),
    horse: NamedHorse.optional(),
    total_vat: Money,
    invoice_uid: z.string(),
  })
  .passthrough();
const PatchedInvoiceItem = z
  .object({
    uid: z.string(),
    description: z.string().max(100),
    unit_price: z.union([z.string(), z.null()]),
    unit_price_currency: z.union([z.string(), z.null()]),
    category: z.string(),
    quantity: z.union([z.string(), z.null()]),
    qualifier: z.string().max(15),
    vat_percentage_uid: z.union([z.string(), z.null()]),
    vat_percentage: NestedVATPercentage,
    vat_included_in_price: z.boolean(),
    deferred: z.boolean(),
    realactivities: z.array(z.string()),
    service_uid: z.union([z.string(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    transactionitems: z.array(z.string()),
    last_modified_on: z.string().datetime({ offset: true }),
    horse_uid: z.union([z.string(), z.null()]),
    horse: NamedHorse,
    total_vat: Money,
    invoice_uid: z.string(),
  })
  .passthrough();
const DepartureReasonEnum = z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4), z.literal(5), z.literal(6)]);
const BestemmingLandCodeEnum = z.enum([
  'BE',
  'BG',
  'CY',
  'DK',
  'DE',
  'EE',
  'FI',
  'FR',
  'EL',
  'UK',
  'HU',
  'IE',
  'IT',
  'HR',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'XI',
  'AT',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'ES',
  'ES2',
  'CZ',
  'SE',
]);
const HorseLocation = z
  .object({
    uid: z.string(),
    location_uid: z.string(),
    origin_uid: z.union([z.string(), z.null()]).optional(),
    arrival_date: z.string(),
    arrival_is_import: z.boolean().optional(),
    departure_reason: DepartureReasonEnum.optional(),
    destination_uid: z.union([z.string(), z.null()]).optional(),
    departure_is_export: z.boolean().optional(),
    departure_is_dead: z.boolean().optional(),
    departure_date: z.union([z.string(), z.null()]).optional(),
    horse_uid: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    arrival_connected_to_departure: z.union([z.string(), z.null()]),
    departure_connected_to_arrival: z.union([z.string(), z.null()]),
    hide_arrival_report_notification: z.boolean().optional(),
    hide_departure_report_notification: z.boolean().optional(),
    herkomstLandCode: BestemmingLandCodeEnum.optional(),
    bestemmingLandCode: BestemmingLandCodeEnum.optional(),
    reportedhorserelocation_set: z.array(ReportedHorseRelocation),
    is_current: z.boolean(),
  })
  .passthrough();
const PaginatedHorseLocationList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(HorseLocation),
  })
  .passthrough();
const PatchedHorseLocation = z
  .object({
    uid: z.string(),
    location_uid: z.string(),
    origin_uid: z.union([z.string(), z.null()]),
    arrival_date: z.string(),
    arrival_is_import: z.boolean(),
    departure_reason: DepartureReasonEnum,
    destination_uid: z.union([z.string(), z.null()]),
    departure_is_export: z.boolean(),
    departure_is_dead: z.boolean(),
    departure_date: z.union([z.string(), z.null()]),
    horse_uid: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    arrival_connected_to_departure: z.union([z.string(), z.null()]),
    departure_connected_to_arrival: z.union([z.string(), z.null()]),
    hide_arrival_report_notification: z.boolean(),
    hide_departure_report_notification: z.boolean(),
    herkomstLandCode: BestemmingLandCodeEnum,
    bestemmingLandCode: BestemmingLandCodeEnum,
    reportedhorserelocation_set: z.array(ReportedHorseRelocation),
    is_current: z.boolean(),
  })
  .passthrough();
const ArrivalOrDepartureEnum = z.enum(['arrival', 'departure']);
const HorseLocationMutation = z
  .object({
    uid: z.string(),
    location_uid: z.string(),
    origin_uid: z.union([z.string(), z.null()]).optional(),
    destination_uid: z.union([z.string(), z.null()]).optional(),
    arrival_date: z.string(),
    departure_date: z.union([z.string(), z.null()]).optional(),
    horse_uid: z.string(),
    arrival_goal_exception: z.boolean().optional(),
    days_left_for_registration: z.string(),
    arrival_or_departure: ArrivalOrDepartureEnum,
  })
  .passthrough();
const PaginatedHorseLocationMutationList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(HorseLocationMutation),
  })
  .passthrough();
const OrderItemStallionMountPicker = z.object({ pickedstallionmount_set: z.array(PickedStallionMount) }).passthrough();
const EventStatus = z.enum(['confirmed', 'tentative', 'cancelled']);
const ActivityContactRole = z.object({ role_uid: z.string(), contact_uid: z.string(), primary: z.boolean().optional() }).passthrough();
const WorkloadEnum = z.union([z.literal(1), z.literal(2), z.literal(3)]);
const ShowStatusEnum = z.enum(['PLA', 'SIG', 'OK', 'NOK', 'POS']);
const DatetimeOrDateField = z
  .object({ datetime: z.string().datetime({ offset: true }).optional(), date: z.string().optional(), timezone: TimezoneEnum })
  .passthrough();
const RealActivities = z
  .object({
    id: z.number().int(),
    uid: z.string(),
    horse_uid: z.string(),
    activity_type_uid: z.string(),
    remind: z.boolean().optional(),
    done_on: z.union([z.string(), z.null()]),
    done_by_uid: z.union([z.string(), z.null()]),
    daypart: z.number().int().gte(1).lte(10).optional(),
    ordering: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    status: EventStatus.optional(),
    action_holder_uid: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    activitycontactrole_set: z.array(ActivityContactRole).optional(),
    level: z.string().max(45).optional(),
    extra_info: z.string().optional(),
    intensity: z.union([z.number(), z.null()]).optional(),
    estimated_intensity: WorkloadEnum.optional(),
    show: z.union([z.string(), z.null()]).optional(),
    sire: z.string().max(60).optional(),
    external_url: z.string().max(2000).url().optional(),
    heart_rate: z.union([z.number(), z.null()]).optional(),
    race_time: z.union([z.string(), z.null()]).optional(),
    show_status: ShowStatusEnum.optional(),
    workload: WorkloadEnum.optional(),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().optional().default(false),
  })
  .passthrough();
const PatchedRealActivities = z
  .object({
    id: z.number().int(),
    uid: z.string(),
    horse_uid: z.string(),
    activity_type_uid: z.string(),
    remind: z.boolean(),
    done_on: z.union([z.string(), z.null()]),
    done_by_uid: z.union([z.string(), z.null()]),
    daypart: z.number().int().gte(1).lte(10),
    ordering: z.number().int().gte(-2147483648).lte(2147483647),
    status: EventStatus,
    action_holder_uid: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    activitycontactrole_set: z.array(ActivityContactRole),
    level: z.string().max(45),
    extra_info: z.string(),
    intensity: z.union([z.number(), z.null()]),
    estimated_intensity: WorkloadEnum,
    show: z.union([z.string(), z.null()]),
    sire: z.string().max(60),
    external_url: z.string().max(2000).url(),
    heart_rate: z.union([z.number(), z.null()]),
    race_time: z.union([z.string(), z.null()]),
    show_status: ShowStatusEnum,
    workload: WorkloadEnum,
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().default(false),
  })
  .passthrough();
const PatchedRealActivitiesSetDoneAndUnDone = z
  .object({
    id: z.number().int(),
    uid: z.string(),
    horse_uid: z.string(),
    activity_type_uid: z.string(),
    remind: z.boolean(),
    done_on: z.union([z.string(), z.null()]),
    done_by_uid: z.union([z.string(), z.null()]),
    daypart: z.number().int(),
    ordering: z.number().int(),
    status: EventStatus,
    action_holder_uid: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    activitycontactrole_set: z.array(ActivityContactRole),
    level: z.string().max(45),
    extra_info: z.string(),
    intensity: z.union([z.number(), z.null()]),
    estimated_intensity: WorkloadEnum,
    show: z.union([z.string(), z.null()]),
    sire: z.string().max(60),
    external_url: z.string().max(2000).url(),
    heart_rate: z.union([z.number(), z.null()]),
    race_time: z.union([z.string(), z.null()]),
    show_status: ShowStatusEnum,
    workload: WorkloadEnum,
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().default(false),
  })
  .passthrough();
const RealActivitiesSetDoneAndUnDone = z
  .object({
    id: z.number().int(),
    uid: z.string(),
    horse_uid: z.string(),
    activity_type_uid: z.string(),
    remind: z.boolean(),
    done_on: z.union([z.string(), z.null()]).optional(),
    done_by_uid: z.union([z.string(), z.null()]).optional(),
    daypart: z.number().int(),
    ordering: z.number().int(),
    status: EventStatus,
    action_holder_uid: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    activitycontactrole_set: z.array(ActivityContactRole).optional(),
    level: z.string().max(45).optional(),
    extra_info: z.string().optional(),
    intensity: z.union([z.number(), z.null()]).optional(),
    estimated_intensity: WorkloadEnum.optional(),
    show: z.union([z.string(), z.null()]).optional(),
    sire: z.string().max(60).optional(),
    external_url: z.string().max(2000).url().optional(),
    heart_rate: z.union([z.number(), z.null()]).optional(),
    race_time: z.union([z.string(), z.null()]).optional(),
    show_status: ShowStatusEnum.optional(),
    workload: WorkloadEnum.optional(),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().optional().default(false),
  })
  .passthrough();
const InvoiceLanguageEnum = z.enum(['nl', 'en', 'de']);
const BusinessTypeEnum = z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]);
const Contact = z
  .object({
    uid: z.string(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
    user_uid: z.union([z.string(), z.null()]),
    stables: z.array(z.union([z.string(), z.null()])).optional(),
    initials: z.string().max(3).optional(),
    hidden: z.boolean(),
    roles: z.array(z.union([z.string(), z.null()])).optional(),
    first_name: z.string().max(255).optional(),
    last_name: z.string().max(255).optional(),
    business_name: z.string().max(255).optional(),
    daily_activity_types: z.array(z.union([z.string(), z.null()])).optional(),
    phone_number: z.string().max(128).optional(),
    email: z.string().max(254).email().optional(),
    external_location: z.boolean().optional(),
    customer_id: z.string().max(7).optional(),
    moneybird_contact_id: z.union([z.number(), z.null()]).optional(),
    exactnl_contact_id: z.string().max(100).optional(),
    vat_number: z.string().max(15).optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    address_line1: z.string().max(255).optional(),
    address_line2: z.string().max(255).optional(),
    address_line3: z.string().max(255).optional(),
    country: CountryEnum.optional(),
    state: z.string().max(255).optional(),
    postcode: z.string().max(64).optional(),
    city: z.string().max(255).optional(),
    title: TitleEnum.optional(),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    show_in_daily: z.boolean().optional(),
    show_in_work_schedule: z.boolean().optional(),
    note: z.string().optional(),
    stable_location_uid: z.string(),
    UBN: z.string().max(30).optional(),
    invoice_language: InvoiceLanguageEnum.optional(),
    business_type: BusinessTypeEnum.optional(),
    lookup: z.string(),
    manage_horse_location_history: z.boolean(),
    machtiging_rvo_gegeven: z.union([z.string(), z.null()]),
    machtiging_rvo_gegeven_by_uid: z.union([z.string(), z.null()]),
    invitation: z.union([SentInvitation, z.null()]),
    create_user_invitation: z.boolean().optional().default(false),
    parent_stable: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough();
const PaginatedContactList = z
  .object({ count: z.number().int(), next: z.string().url().nullish(), previous: z.string().url().nullish(), results: z.array(Contact) })
  .passthrough();
const DefaultEnum = z.enum([
  'RIDING',
  'PADDOCK',
  'LUNGING',
  'FARRIER',
  'VACCINATION',
  'VETERINARY',
  'SHOW',
  'CUSTOMER',
  'DEWORM',
  'DENTIST',
  'LESSON',
  'INSEMINATION',
  'FOALING',
  'IN_HEAT',
  'PREGNANCY_CHECK',
  'RHINO',
]);
const CategoryEnum = z.enum(['SPORT', 'BREEDING', 'CARE']);
const ExtraInfoFieldsEnum = z.enum([
  'level',
  'extra_info',
  'intensity',
  'estimated_intensity',
  'show',
  'sire',
  'external_url',
  'heart_rate',
  'race_time',
  'show_status',
  'workload',
]);
const ActivityType = z
  .object({
    uid: z.string(),
    name: z.string().max(45).optional(),
    order: z.union([z.number(), z.null()]).optional(),
    default: DefaultEnum.optional(),
    hide_order: z.boolean().optional(),
    hidden: z.boolean().optional(),
    enable_quick_add: z.boolean().optional(),
    stable_settings_activity_types_general: z.array(z.string()).optional(),
    reminder_enabled_by_default: z.boolean().optional(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
    category: CategoryEnum.optional(),
    notify_before: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    extra_info_fields: z.array(ExtraInfoFieldsEnum).optional(),
    possible_roles: z.array(z.union([z.string(), z.null()])).optional(),
  })
  .passthrough();
const PatchedActivityType = z
  .object({
    uid: z.string(),
    name: z.string().max(45),
    order: z.union([z.number(), z.null()]),
    default: DefaultEnum,
    hide_order: z.boolean(),
    hidden: z.boolean(),
    enable_quick_add: z.boolean(),
    stable_settings_activity_types_general: z.array(z.string()),
    reminder_enabled_by_default: z.boolean(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
    category: CategoryEnum,
    notify_before: z.number().int().gte(-2147483648).lte(2147483647),
    extra_info_fields: z.array(ExtraInfoFieldsEnum),
    possible_roles: z.array(z.union([z.string(), z.null()])),
  })
  .passthrough();
const PSTypeEnum = z.enum(['PURCHASE', 'SALE']);
const VatLevelCountryVatLevelEnum = z.enum(['REDUCED', 'SECOND_REDUCED', 'SUPER_REDUCED', 'ZERO']);
const VatLevelCountry = z.object({ vat_level: VatLevelCountryVatLevelEnum, country: CountryEnum }).passthrough();
const Category = z
  .object({
    uid: z.string(),
    p_s_type: PSTypeEnum.optional(),
    name: z.string().max(255),
    exactnl_ledger_code: z.string().max(90).optional(),
    moneybird_ledger_code: z.string().max(90).optional(),
    yuki_ledger_code: z.string().max(90).optional(),
    default: z.union([z.string(), z.null()]),
    vatLevelCountry: z.array(VatLevelCountry),
  })
  .passthrough();
const PatchedCategory = z
  .object({
    uid: z.string(),
    p_s_type: PSTypeEnum,
    name: z.string().max(255),
    exactnl_ledger_code: z.string().max(90),
    moneybird_ledger_code: z.string().max(90),
    yuki_ledger_code: z.string().max(90),
    default: z.union([z.string(), z.null()]),
    vatLevelCountry: z.array(VatLevelCountry),
  })
  .passthrough();
const PatchedContact = z
  .object({
    uid: z.string(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
    user_uid: z.union([z.string(), z.null()]),
    stables: z.array(z.union([z.string(), z.null()])),
    initials: z.string().max(3),
    hidden: z.boolean(),
    roles: z.array(z.union([z.string(), z.null()])),
    first_name: z.string().max(255),
    last_name: z.string().max(255),
    business_name: z.string().max(255),
    daily_activity_types: z.array(z.union([z.string(), z.null()])),
    phone_number: z.string().max(128),
    email: z.string().max(254).email(),
    external_location: z.boolean(),
    customer_id: z.string().max(7),
    moneybird_contact_id: z.union([z.number(), z.null()]),
    exactnl_contact_id: z.string().max(100),
    vat_number: z.string().max(15),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    address_line1: z.string().max(255),
    address_line2: z.string().max(255),
    address_line3: z.string().max(255),
    country: CountryEnum,
    state: z.string().max(255),
    postcode: z.string().max(64),
    city: z.string().max(255),
    title: TitleEnum,
    date_of_birth: z.union([z.string(), z.null()]),
    show_in_daily: z.boolean(),
    show_in_work_schedule: z.boolean(),
    note: z.string(),
    stable_location_uid: z.string(),
    UBN: z.string().max(30),
    invoice_language: InvoiceLanguageEnum,
    business_type: BusinessTypeEnum,
    lookup: z.string(),
    manage_horse_location_history: z.boolean(),
    machtiging_rvo_gegeven: z.union([z.string(), z.null()]),
    machtiging_rvo_gegeven_by_uid: z.union([z.string(), z.null()]),
    invitation: z.union([SentInvitation, z.null()]),
    create_user_invitation: z.boolean().default(false),
    parent_stable: z.union([z.string(), z.null()]),
  })
  .passthrough();
const PatchedMergeContacts = z
  .object({
    uid: z.string(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
    user_uid: z.union([z.string(), z.null()]),
    stables: z.array(z.union([z.string(), z.null()])),
    initials: z.string().max(3),
    hidden: z.boolean(),
    roles: z.array(z.union([z.string(), z.null()])),
    first_name: z.string().max(255),
    last_name: z.string().max(255),
    business_name: z.string().max(255),
    daily_activity_types: z.array(z.union([z.string(), z.null()])),
    phone_number: z.string().max(128),
    email: z.string().max(254).email(),
    external_location: z.boolean(),
    customer_id: z.string().max(7),
    moneybird_contact_id: z.union([z.number(), z.null()]),
    exactnl_contact_id: z.string().max(100),
    vat_number: z.string().max(15),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    address_line1: z.string().max(255),
    address_line2: z.string().max(255),
    address_line3: z.string().max(255),
    country: CountryEnum,
    state: z.string().max(255),
    postcode: z.string().max(64),
    city: z.string().max(255),
    title: TitleEnum,
    date_of_birth: z.union([z.string(), z.null()]),
    show_in_daily: z.boolean(),
    show_in_work_schedule: z.boolean(),
    note: z.string(),
    stable_location_uid: z.string(),
    UBN: z.string().max(30),
    invoice_language: InvoiceLanguageEnum,
    business_type: BusinessTypeEnum,
    lookup: z.string(),
    manage_horse_location_history: z.boolean(),
    machtiging_rvo_gegeven: z.union([z.string(), z.null()]),
    machtiging_rvo_gegeven_by_uid: z.union([z.string(), z.null()]),
    invitation: z.union([SentInvitation, z.null()]),
    create_user_invitation: z.boolean().default(false),
    parent_stable: z.union([z.string(), z.null()]),
    merge_with_uid: z.string(),
  })
  .passthrough();
const MergeContacts = z
  .object({
    uid: z.string(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
    user_uid: z.union([z.string(), z.null()]),
    stables: z.array(z.union([z.string(), z.null()])).optional(),
    initials: z.string().max(3).optional(),
    hidden: z.boolean(),
    roles: z.array(z.union([z.string(), z.null()])).optional(),
    first_name: z.string().max(255).optional(),
    last_name: z.string().max(255).optional(),
    business_name: z.string().max(255).optional(),
    daily_activity_types: z.array(z.union([z.string(), z.null()])).optional(),
    phone_number: z.string().max(128).optional(),
    email: z.string().max(254).email().optional(),
    external_location: z.boolean().optional(),
    customer_id: z.string().max(7).optional(),
    moneybird_contact_id: z.union([z.number(), z.null()]).optional(),
    exactnl_contact_id: z.string().max(100).optional(),
    vat_number: z.string().max(15).optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    address_line1: z.string().max(255).optional(),
    address_line2: z.string().max(255).optional(),
    address_line3: z.string().max(255).optional(),
    country: CountryEnum.optional(),
    state: z.string().max(255).optional(),
    postcode: z.string().max(64).optional(),
    city: z.string().max(255).optional(),
    title: TitleEnum.optional(),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    show_in_daily: z.boolean().optional(),
    show_in_work_schedule: z.boolean().optional(),
    note: z.string().optional(),
    stable_location_uid: z.string(),
    UBN: z.string().max(30).optional(),
    invoice_language: InvoiceLanguageEnum.optional(),
    business_type: BusinessTypeEnum.optional(),
    lookup: z.string(),
    manage_horse_location_history: z.boolean(),
    machtiging_rvo_gegeven: z.union([z.string(), z.null()]),
    machtiging_rvo_gegeven_by_uid: z.union([z.string(), z.null()]),
    invitation: z.union([SentInvitation, z.null()]),
    create_user_invitation: z.boolean().optional().default(false),
    parent_stable: z.union([z.string(), z.null()]).optional(),
    merge_with_uid: z.string(),
  })
  .passthrough();
const DailyNote = z
  .object({
    uid: z.string(),
    title: z.string().max(80).optional(),
    text: z.string().max(340),
    stable_uid: z.union([z.string(), z.null()]).optional(),
    executable: z.boolean().optional(),
    done_on: z.union([z.string(), z.null()]).optional(),
    done_by_uid: z.union([z.string(), z.null()]).optional(),
    color: z.union([z.string(), z.null()]).optional(),
    order: z.union([z.number(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().optional().default(false),
  })
  .passthrough();
const PatchedDailyNote = z
  .object({
    uid: z.string(),
    title: z.string().max(80),
    text: z.string().max(340),
    stable_uid: z.union([z.string(), z.null()]),
    executable: z.boolean(),
    done_on: z.union([z.string(), z.null()]),
    done_by_uid: z.union([z.string(), z.null()]),
    color: z.union([z.string(), z.null()]),
    order: z.union([z.number(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().default(false),
  })
  .passthrough();
const PatchedDailyNoteSetDoneAndUnDone = z
  .object({
    uid: z.string(),
    title: z.string(),
    text: z.string(),
    stable_uid: z.union([z.string(), z.null()]),
    executable: z.boolean(),
    done_on: z.union([z.string(), z.null()]),
    done_by_uid: z.union([z.string(), z.null()]),
    color: z.union([z.string(), z.null()]),
    order: z.union([z.number(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().default(false),
  })
  .passthrough();
const DailyNoteSetDoneAndUnDone = z
  .object({
    uid: z.string(),
    title: z.string(),
    text: z.string(),
    stable_uid: z.union([z.string(), z.null()]),
    executable: z.boolean(),
    done_on: z.union([z.string(), z.null()]).optional(),
    done_by_uid: z.union([z.string(), z.null()]).optional(),
    color: z.union([z.string(), z.null()]),
    order: z.union([z.number(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    created_by_uid: z.string(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    start: DatetimeOrDateField,
    end: DatetimeOrDateField,
    all_day_event: z.boolean().optional().default(false),
  })
  .passthrough();
const DayPartStartTime = z.object({ uid: z.string(), start_time: z.string(), name: z.string().max(255).optional() }).passthrough();
const PatchedDayPartStartTime = z.object({ uid: z.string(), start_time: z.string(), name: z.string().max(255) }).passthrough();
const PossibleCalendarHashObjects = z.object({ activity_type_uids: z.string(), horse_uids: z.string() }).passthrough();
const EventFeeds = z
  .object({
    uid: z.string(),
    stable: z.union([z.string(), z.null()]),
    user: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    possible_calendar_hash_objs: PossibleCalendarHashObjects.optional(),
    created_on: z.string().datetime({ offset: true }),
    url: z.string(),
  })
  .passthrough();
const PatchedEventFeeds = z
  .object({
    uid: z.string(),
    stable: z.union([z.string(), z.null()]),
    user: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    possible_calendar_hash_objs: PossibleCalendarHashObjects,
    created_on: z.string().datetime({ offset: true }),
    url: z.string(),
  })
  .passthrough();
const HorseGroup = z
  .object({ uid: z.string(), name: z.string().max(45).optional(), default_id: z.union([z.number(), z.null()]) })
  .passthrough();
const PatchedHorseGroup = z
  .object({ uid: z.string(), name: z.string().max(45), default_id: z.union([z.number(), z.null()]) })
  .passthrough();
const ColorEnum = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
  z.literal(6),
  z.literal(7),
  z.literal(8),
  z.literal(9),
  z.literal(10),
  z.literal(11),
  z.literal(12),
  z.literal(13),
]);
const SexEnum = z.union([z.literal(1), z.literal(2), z.literal(3)]);
const Location = z
  .object({ uid: z.union([z.string(), z.null()]), name: z.string(), arrival_date: z.union([z.string(), z.null()]) })
  .passthrough();
const Horse = z
  .object({
    uid: z.string(),
    display_name: z.union([z.string(), z.null()]).optional(),
    avatar_file: z.union([z.string(), z.null()]).optional(),
    group_uid: z.string(),
    stable_uid: z.union([z.string(), z.null()]).optional(),
    hidden: z.boolean(),
    name: z.string().max(45),
    nickname: z.string().max(45).optional(),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    color: ColorEnum.optional(),
    sex: SexEnum.optional(),
    sire: z.string().max(65).optional(),
    dam: z.string().max(65).optional(),
    damsire: z.string().max(45).optional(),
    siredam: z.string().max(45).optional(),
    UELN: z
      .string()
      .max(100)
      .regex(/^[A-Za-z0-9-]*$/)
      .optional(),
    chip_nr: z
      .string()
      .max(45)
      .regex(/^[0-9]*$/)
      .optional(),
    current_location: Location,
    future_location: Location,
    source: z.union([z.string(), z.null()]),
    use_in_breeding: z.boolean().optional(),
    use_in_sport: z.boolean().optional(),
    use_in_care: z.boolean().optional(),
    owner_uids: z.array(z.string()),
    default_rider_uid: z.union([z.string(), z.null()]).optional(),
    default_groom_uid: z.union([z.string(), z.null()]).optional(),
    default_farrier_uid: z.union([z.string(), z.null()]).optional(),
    default_vet_uid: z.union([z.string(), z.null()]).optional(),
    default_trainer_uid: z.union([z.string(), z.null()]).optional(),
    dentist_weeks: z.union([z.number(), z.null()]).optional(),
    deworm_weeks: z.union([z.number(), z.null()]).optional(),
    rhino_weeks: z.union([z.number(), z.null()]).optional(),
    vaccination_rules_uid: z.string().optional(),
    farrier_weeks: z.union([z.number(), z.null()]).optional(),
  })
  .passthrough();
const PaginatedHorseList = z
  .object({ count: z.number().int(), next: z.string().url().nullish(), previous: z.string().url().nullish(), results: z.array(Horse) })
  .passthrough();
const NestedHorseLocation = z
  .object({
    uid: z.string(),
    location_uid: z.string(),
    origin_uid: z.union([z.string(), z.null()]).optional(),
    arrival_date: z.string(),
    arrival_is_import: z.boolean().optional(),
    departure_reason: DepartureReasonEnum.optional(),
    destination_uid: z.union([z.string(), z.null()]).optional(),
    departure_is_export: z.boolean().optional(),
    departure_is_dead: z.boolean().optional(),
    departure_date: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough();
const HorseLocationMove = z
  .object({
    departure_reason: DepartureReasonEnum.optional(),
    destination_uid: z.union([z.string(), z.null()]).optional(),
    departure_is_export: z.boolean().optional(),
    departure_is_dead: z.boolean().optional(),
    arrival_is_import: z.boolean().optional(),
    origin_uid: z.union([z.string(), z.null()]).optional(),
    date: z.string(),
    set_inactive: z.boolean().optional(),
    set_active: z.boolean().optional(),
    stop_services: z.boolean().optional(),
    restart_services: z.boolean().optional(),
  })
  .passthrough();
const HorseDetail = z
  .object({
    uid: z.string(),
    owner_uids: z.array(z.string()),
    display_name: z.union([z.string(), z.null()]).optional(),
    group_uid: z.string(),
    stable_uid: z.union([z.string(), z.null()]).optional(),
    hidden: z.boolean(),
    dentist_weeks: z.union([z.number(), z.null()]).optional(),
    deworm_weeks: z.union([z.number(), z.null()]).optional(),
    rhino_weeks: z.union([z.number(), z.null()]).optional(),
    vaccination_rules_uid: z.string().optional(),
    farrier_weeks: z.union([z.number(), z.null()]).optional(),
    name: z.string().max(45),
    nickname: z.string().max(45).optional(),
    box_no: z.union([z.number(), z.null()]).optional(),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    color: ColorEnum.optional(),
    sex: SexEnum.optional(),
    weight: z.union([z.string(), z.null()]).optional(),
    temperature: z.union([z.string(), z.null()]).optional(),
    sire: z.string().max(65).optional(),
    dam: z.string().max(65).optional(),
    damsire: z.string().max(45).optional(),
    breeder: z.string().max(45).optional(),
    studbook: z.string().max(190).optional(),
    note: z.string().optional(),
    warning: z.string().optional(),
    damdam: z.string().max(45).optional(),
    siresire: z.string().max(45).optional(),
    siredam: z.string().max(45).optional(),
    siregrandsire1: z.string().max(45).optional(),
    siregrandsire2: z.string().max(45).optional(),
    siregranddam1: z.string().max(45).optional(),
    siregranddam2: z.string().max(45).optional(),
    damgranddam1: z.string().max(45).optional(),
    damgranddam2: z.string().max(45).optional(),
    damgrandsire1: z.string().max(45).optional(),
    damgrandsire2: z.string().max(45).optional(),
    UELN: z
      .string()
      .max(100)
      .regex(/^[A-Za-z0-9-]*$/)
      .optional(),
    FEI_pass_nr: z.string().max(20).optional(),
    local_federation_number: z.string().max(30).optional(),
    chip_nr: z
      .string()
      .max(45)
      .regex(/^[0-9]*$/)
      .optional(),
    FEI_expiry_date: z.union([z.string(), z.null()]).optional(),
    default_rider_uid: z.union([z.string(), z.null()]).optional(),
    default_groom_uid: z.union([z.string(), z.null()]).optional(),
    default_farrier_uid: z.union([z.string(), z.null()]).optional(),
    default_vet_uid: z.union([z.string(), z.null()]).optional(),
    default_trainer_uid: z.union([z.string(), z.null()]).optional(),
    transport_instruction: z.union([z.string(), z.null()]).optional(),
    id_horsetelex: z.string().max(45).optional(),
    purchase_date: z.union([z.string(), z.null()]).optional(),
    withers_height: z.union([z.number(), z.null()]).optional(),
    heart_rate: z.union([z.number(), z.null()]).optional(),
    respiratory_rate: z.union([z.number(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    avatar_file: z.union([z.string(), z.null()]).optional(),
    current_horselocation: NestedHorseLocation,
    future_horselocation: NestedHorseLocation,
    register_move: HorseLocationMove.optional(),
    new_owners: z.array(z.string()).optional(),
    use_in_breeding: z.boolean().optional(),
    use_in_sport: z.boolean().optional(),
    use_in_care: z.boolean().optional(),
  })
  .passthrough();
const PatchedHorseDetail = z
  .object({
    uid: z.string(),
    owner_uids: z.array(z.string()),
    display_name: z.union([z.string(), z.null()]),
    group_uid: z.string(),
    stable_uid: z.union([z.string(), z.null()]),
    hidden: z.boolean(),
    dentist_weeks: z.union([z.number(), z.null()]),
    deworm_weeks: z.union([z.number(), z.null()]),
    rhino_weeks: z.union([z.number(), z.null()]),
    vaccination_rules_uid: z.string(),
    farrier_weeks: z.union([z.number(), z.null()]),
    name: z.string().max(45),
    nickname: z.string().max(45),
    box_no: z.union([z.number(), z.null()]),
    date_of_birth: z.union([z.string(), z.null()]),
    color: ColorEnum,
    sex: SexEnum,
    weight: z.union([z.string(), z.null()]),
    temperature: z.union([z.string(), z.null()]),
    sire: z.string().max(65),
    dam: z.string().max(65),
    damsire: z.string().max(45),
    breeder: z.string().max(45),
    studbook: z.string().max(190),
    note: z.string(),
    warning: z.string(),
    damdam: z.string().max(45),
    siresire: z.string().max(45),
    siredam: z.string().max(45),
    siregrandsire1: z.string().max(45),
    siregrandsire2: z.string().max(45),
    siregranddam1: z.string().max(45),
    siregranddam2: z.string().max(45),
    damgranddam1: z.string().max(45),
    damgranddam2: z.string().max(45),
    damgrandsire1: z.string().max(45),
    damgrandsire2: z.string().max(45),
    UELN: z
      .string()
      .max(100)
      .regex(/^[A-Za-z0-9-]*$/),
    FEI_pass_nr: z.string().max(20),
    local_federation_number: z.string().max(30),
    chip_nr: z
      .string()
      .max(45)
      .regex(/^[0-9]*$/),
    FEI_expiry_date: z.union([z.string(), z.null()]),
    default_rider_uid: z.union([z.string(), z.null()]),
    default_groom_uid: z.union([z.string(), z.null()]),
    default_farrier_uid: z.union([z.string(), z.null()]),
    default_vet_uid: z.union([z.string(), z.null()]),
    default_trainer_uid: z.union([z.string(), z.null()]),
    transport_instruction: z.union([z.string(), z.null()]),
    id_horsetelex: z.string().max(45),
    purchase_date: z.union([z.string(), z.null()]),
    withers_height: z.union([z.number(), z.null()]),
    heart_rate: z.union([z.number(), z.null()]),
    respiratory_rate: z.union([z.number(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    avatar_file: z.union([z.string(), z.null()]),
    current_horselocation: NestedHorseLocation,
    future_horselocation: NestedHorseLocation,
    register_move: HorseLocationMove,
    new_owners: z.array(z.string()),
    use_in_breeding: z.boolean(),
    use_in_sport: z.boolean(),
    use_in_care: z.boolean(),
  })
  .passthrough();
const PatchedMergeHorses = z
  .object({
    uid: z.string(),
    owner_uids: z.array(z.string()),
    display_name: z.string().max(190),
    group_uid: z.string(),
    stable_uid: z.union([z.string(), z.null()]),
    hidden: z.boolean(),
    dentist_weeks: z.union([z.number(), z.null()]),
    deworm_weeks: z.union([z.number(), z.null()]),
    rhino_weeks: z.union([z.number(), z.null()]),
    vaccination_rules_uid: z.string(),
    farrier_weeks: z.union([z.number(), z.null()]),
    name: z.string().max(45),
    nickname: z.string().max(45),
    box_no: z.union([z.number(), z.null()]),
    date_of_birth: z.union([z.string(), z.null()]),
    color: ColorEnum,
    sex: SexEnum,
    weight: z.union([z.string(), z.null()]),
    temperature: z.union([z.string(), z.null()]),
    sire: z.string().max(65),
    dam: z.string().max(65),
    damsire: z.string().max(45),
    breeder: z.string().max(45),
    studbook: z.string().max(190),
    note: z.string(),
    warning: z.string(),
    damdam: z.string().max(45),
    siresire: z.string().max(45),
    siredam: z.string().max(45),
    siregrandsire1: z.string().max(45),
    siregrandsire2: z.string().max(45),
    siregranddam1: z.string().max(45),
    siregranddam2: z.string().max(45),
    damgranddam1: z.string().max(45),
    damgranddam2: z.string().max(45),
    damgrandsire1: z.string().max(45),
    damgrandsire2: z.string().max(45),
    UELN: z
      .string()
      .max(100)
      .regex(/^[A-Za-z0-9-]*$/),
    FEI_pass_nr: z.string().max(20),
    local_federation_number: z.string().max(30),
    chip_nr: z
      .string()
      .max(45)
      .regex(/^[0-9]*$/),
    FEI_expiry_date: z.union([z.string(), z.null()]),
    default_rider_uid: z.union([z.string(), z.null()]),
    default_groom_uid: z.union([z.string(), z.null()]),
    default_farrier_uid: z.union([z.string(), z.null()]),
    default_vet_uid: z.union([z.string(), z.null()]),
    default_trainer_uid: z.union([z.string(), z.null()]),
    transport_instruction: z.union([z.string(), z.null()]),
    id_horsetelex: z.string().max(45),
    purchase_date: z.union([z.string(), z.null()]),
    withers_height: z.union([z.number(), z.null()]),
    heart_rate: z.union([z.number(), z.null()]),
    respiratory_rate: z.union([z.number(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    avatar_file: z.union([z.string(), z.null()]),
    current_horselocation: NestedHorseLocation,
    future_horselocation: NestedHorseLocation,
    register_move: HorseLocationMove,
    new_owners: z.array(z.string()),
    use_in_breeding: z.boolean(),
    use_in_sport: z.boolean(),
    use_in_care: z.boolean(),
    merge_with_uid: z.string(),
  })
  .passthrough();
const MergeHorses = z
  .object({
    uid: z.string(),
    owner_uids: z.array(z.string()),
    display_name: z.string().max(190).optional(),
    group_uid: z.string().optional(),
    stable_uid: z.union([z.string(), z.null()]).optional(),
    hidden: z.boolean(),
    dentist_weeks: z.union([z.number(), z.null()]).optional(),
    deworm_weeks: z.union([z.number(), z.null()]).optional(),
    rhino_weeks: z.union([z.number(), z.null()]).optional(),
    vaccination_rules_uid: z.string().optional(),
    farrier_weeks: z.union([z.number(), z.null()]).optional(),
    name: z.string().max(45).optional(),
    nickname: z.string().max(45).optional(),
    box_no: z.union([z.number(), z.null()]).optional(),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    color: ColorEnum.optional(),
    sex: SexEnum.optional(),
    weight: z.union([z.string(), z.null()]).optional(),
    temperature: z.union([z.string(), z.null()]).optional(),
    sire: z.string().max(65).optional(),
    dam: z.string().max(65).optional(),
    damsire: z.string().max(45).optional(),
    breeder: z.string().max(45).optional(),
    studbook: z.string().max(190).optional(),
    note: z.string().optional(),
    warning: z.string().optional(),
    damdam: z.string().max(45).optional(),
    siresire: z.string().max(45).optional(),
    siredam: z.string().max(45).optional(),
    siregrandsire1: z.string().max(45).optional(),
    siregrandsire2: z.string().max(45).optional(),
    siregranddam1: z.string().max(45).optional(),
    siregranddam2: z.string().max(45).optional(),
    damgranddam1: z.string().max(45).optional(),
    damgranddam2: z.string().max(45).optional(),
    damgrandsire1: z.string().max(45).optional(),
    damgrandsire2: z.string().max(45).optional(),
    UELN: z
      .string()
      .max(100)
      .regex(/^[A-Za-z0-9-]*$/)
      .optional(),
    FEI_pass_nr: z.string().max(20).optional(),
    local_federation_number: z.string().max(30).optional(),
    chip_nr: z
      .string()
      .max(45)
      .regex(/^[0-9]*$/)
      .optional(),
    FEI_expiry_date: z.union([z.string(), z.null()]).optional(),
    default_rider_uid: z.union([z.string(), z.null()]).optional(),
    default_groom_uid: z.union([z.string(), z.null()]).optional(),
    default_farrier_uid: z.union([z.string(), z.null()]).optional(),
    default_vet_uid: z.union([z.string(), z.null()]).optional(),
    default_trainer_uid: z.union([z.string(), z.null()]).optional(),
    transport_instruction: z.union([z.string(), z.null()]).optional(),
    id_horsetelex: z.string().max(45).optional(),
    purchase_date: z.union([z.string(), z.null()]).optional(),
    withers_height: z.union([z.number(), z.null()]).optional(),
    heart_rate: z.union([z.number(), z.null()]).optional(),
    respiratory_rate: z.union([z.number(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    avatar_file: z.union([z.string(), z.null()]).optional(),
    current_horselocation: NestedHorseLocation,
    future_horselocation: NestedHorseLocation,
    register_move: HorseLocationMove.optional(),
    new_owners: z.array(z.string()).optional(),
    use_in_breeding: z.boolean().optional(),
    use_in_sport: z.boolean().optional(),
    use_in_care: z.boolean().optional(),
    merge_with_uid: z.string(),
  })
  .passthrough();
const HorseMove = z
  .object({
    group_uid: z.string().optional(),
    departure_reason: DepartureReasonEnum.optional(),
    destination_uid: z.union([z.string(), z.null()]).optional(),
    departure_is_export: z.boolean().optional(),
    departure_is_dead: z.boolean().optional(),
    arrival_is_import: z.boolean().optional(),
    origin_uid: z.union([z.string(), z.null()]).optional(),
    date: z.string(),
    set_inactive: z.boolean().optional(),
    set_active: z.boolean().optional(),
    stop_services: z.boolean().optional(),
    restart_services: z.boolean().optional(),
  })
  .passthrough();
const HorseMoveResponse = z
  .object({
    message: z.string(),
    horse_uid: z.string(),
    task_id: z.string().optional(),
    code: z.string(),
    background_task: z.boolean().optional().default(false),
  })
  .passthrough();
const RvOHorseCreate = z.object({ create_new: z.boolean().default(false), location_uid: z.string() }).passthrough();
const EquineMHorseSearchDetail = z
  .object({
    display_name: z.string().max(190),
    name: z.string().max(45),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    sex: SexEnum.optional(),
    sire: z.string().max(65).optional(),
    dam: z.string().max(65).optional(),
    damsire: z.string().max(45).optional(),
    studbook: z.string().max(190).optional(),
    UELN: z
      .string()
      .max(100)
      .regex(/^[A-Za-z0-9-]*$/)
      .optional(),
    chip_nr: z
      .string()
      .max(45)
      .regex(/^[0-9]*$/)
      .optional(),
    FEI_pass_nr: z.string().max(20).optional(),
    id_horsetelex: z.string().max(45).optional(),
    current_location_arrival_date: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough();
const GetHorseUnprocessable = z
  .object({
    no_horses_from_rvo: z.number().int(),
    rvo_horses_not_found: z.array(EquineMHorseSearchDetail),
    horses_wo_chipnr: z.array(Horse),
  })
  .passthrough();
const CannotCreateHorse = z.object({ horse: EquineMHorseSearchDetail, soortFoutIndicator: z.string() }).passthrough();
const GetHorsesFromRvo = z
  .object({
    message: z.string(),
    unprocessable_request: GetHorseUnprocessable.optional(),
    not_found_horse_creation_errors: z.union([z.array(CannotCreateHorse), z.null()]).optional(),
  })
  .passthrough();
const Progress = z.object({ percentage: z.number(), description: z.string() }).passthrough();
const DbEnum = z.enum(['RvO', 'HorseTelex']);
const SearchHorse = z.object({ q: z.string(), db: DbEnum.optional() }).passthrough();
const Invoice = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    customer_uid: z.string(),
    order_uid: z.union([z.string(), z.null()]),
    total: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    currency: z.string(),
    total_incl_vat: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    invoice_no: z.string().max(255).optional(),
    draft: z.boolean(),
    finalized_on: z.union([z.string(), z.null()]),
    date: z.string().optional(),
    sent: z.boolean(),
    sent_on: z.union([z.string(), z.null()]),
    moneybird_invoice_id: z.union([z.number(), z.null()]),
    exactnl_invoice_id: z.string(),
    yuki_invoice_id: z.string(),
    payment_status: PaymentStatusEnum,
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    start_date_activities: z.union([z.string(), z.null()]).optional(),
    end_date_activities: z.union([z.string(), z.null()]).optional(),
    start_date_services: z.union([z.string(), z.null()]).optional(),
    end_date_services: z.union([z.string(), z.null()]).optional(),
    created_by_uid: z.string(),
    expiration_date: z.union([z.string(), z.null()]),
    first_due_interval: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    payment_summary: PaymentSummary,
  })
  .passthrough();
const PaginatedInvoiceList = z
  .object({ count: z.number().int(), next: z.string().url().nullish(), previous: z.string().url().nullish(), results: z.array(Invoice) })
  .passthrough();
const NestedInvoiceItem = z
  .object({
    uid: z.string().optional(),
    description: z.string().max(100),
    unit_price: z.union([z.string(), z.null()]).optional(),
    unit_price_currency: z.union([z.string(), z.null()]),
    category: z.string(),
    quantity: z.union([z.string(), z.null()]).optional(),
    qualifier: z.string().max(15).optional(),
    vat_percentage_uid: z.union([z.string(), z.null()]).optional(),
    vat_percentage: NestedVATPercentage,
    vat_included_in_price: z.boolean().optional(),
    deferred: z.boolean().optional(),
    realactivities: z.array(z.string()).optional(),
    service_uid: z.union([z.string(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    transactionitems: z.array(z.string()).optional(),
    last_modified_on: z.string().datetime({ offset: true }),
    horse_uid: z.union([z.string(), z.null()]).optional(),
    horse: NamedHorse.optional(),
    total_vat: Money,
  })
  .passthrough();
const InvoiceDetail = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    customer_uid: z.string(),
    total: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    currency: z.string(),
    total_incl_vat: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    invoice_no: z.string().max(255).optional(),
    draft: z.boolean(),
    finalized_on: z.union([z.string(), z.null()]),
    date: z.string().optional(),
    sent: z.boolean(),
    sent_on: z.union([z.string(), z.null()]),
    moneybird_invoice_id: z.union([z.number(), z.null()]),
    exactnl_invoice_id: z.string(),
    yuki_invoice_id: z.string(),
    payment_status: PaymentStatusEnum,
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    start_date_activities: z.union([z.string(), z.null()]).optional(),
    end_date_activities: z.union([z.string(), z.null()]).optional(),
    start_date_services: z.union([z.string(), z.null()]).optional(),
    end_date_services: z.union([z.string(), z.null()]).optional(),
    created_by_uid: z.string(),
    expiration_date: z.union([z.string(), z.null()]),
    first_due_interval: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    payment_summary: PaymentSummary,
    order: NestedOrder.optional(),
    historic_supplier: PublicInvoiceOrganisation,
    language: z.string(),
    items: z.array(NestedInvoiceItem).optional(),
    historic_customer: NameAndAddress,
    exactnl_url: z.union([z.string(), z.null()]),
    exactnl_entry_no: z.string(),
    dry_run: z.boolean().optional(),
  })
  .passthrough();
const PatchedInvoiceDetail = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    customer_uid: z.string(),
    total: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    currency: z.string(),
    total_incl_vat: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    invoice_no: z.string().max(255),
    draft: z.boolean(),
    finalized_on: z.union([z.string(), z.null()]),
    date: z.string(),
    sent: z.boolean(),
    sent_on: z.union([z.string(), z.null()]),
    moneybird_invoice_id: z.union([z.number(), z.null()]),
    exactnl_invoice_id: z.string(),
    yuki_invoice_id: z.string(),
    payment_status: PaymentStatusEnum,
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    start_date_activities: z.union([z.string(), z.null()]),
    end_date_activities: z.union([z.string(), z.null()]),
    start_date_services: z.union([z.string(), z.null()]),
    end_date_services: z.union([z.string(), z.null()]),
    created_by_uid: z.string(),
    expiration_date: z.union([z.string(), z.null()]),
    first_due_interval: z.number().int().gte(-2147483648).lte(2147483647),
    payment_summary: PaymentSummary,
    order: NestedOrder,
    historic_supplier: PublicInvoiceOrganisation,
    language: z.string(),
    items: z.array(NestedInvoiceItem),
    historic_customer: NameAndAddress,
    exactnl_url: z.union([z.string(), z.null()]),
    exactnl_entry_no: z.string(),
    dry_run: z.boolean(),
  })
  .passthrough();
const InvoiceFinalize = z.object({ send_email: z.boolean(), send_to_accounting_software: z.boolean() }).passthrough();
const OAuth2Token = z
  .object({
    uid: z.string(),
    name: z.string(),
    created_by_uid: z.string(),
    created_on: z.string().datetime({ offset: true }),
    token_type: z.string(),
  })
  .passthrough();
const PaymentTypeEnum = z.enum(['Bank', 'Cash', 'Mollie', 'PayPal', 'Pin', 'CreditCard']);
const Payment = z
  .object({
    uid: z.string(),
    type: PaymentTypeEnum.optional(),
    date: z.union([z.string(), z.null()]).optional(),
    amount: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    amount_currency: z.string(),
    status: PaymentStatusEnum.optional(),
    created_on: z.string().datetime({ offset: true }),
    mollie_payment_id: z.union([z.string(), z.null()]).optional(),
    invoice: z.union([z.string(), z.null()]).optional(),
    order: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough();
const PaginatedPaymentList = z
  .object({ count: z.number().int(), next: z.string().url().nullish(), previous: z.string().url().nullish(), results: z.array(Payment) })
  .passthrough();
const PatchedPayment = z
  .object({
    uid: z.string(),
    type: PaymentTypeEnum,
    date: z.union([z.string(), z.null()]),
    amount: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    amount_currency: z.string(),
    status: PaymentStatusEnum,
    created_on: z.string().datetime({ offset: true }),
    mollie_payment_id: z.union([z.string(), z.null()]),
    invoice: z.union([z.string(), z.null()]),
    order: z.union([z.string(), z.null()]),
  })
  .passthrough();
const shipping_service_type = z
  .union([z.enum(['NEXT_DAY_DELIVERY', 'PICK_UP', 'REGULAR', 'SAME_DAY_DELIVERY', 'SUNDAY_HOLIDAY_DELIVERY']), z.null()])
  .optional();
const ShippingServiceTypeEnum = z.enum(['REGULAR', 'PICK_UP', 'SAME_DAY_DELIVERY', 'NEXT_DAY_DELIVERY', 'SUNDAY_HOLIDAY_DELIVERY']);
const Product = z
  .object({
    uid: z.string(),
    name: z.string().max(100).optional(),
    hidden: z.boolean().optional(),
    description: z.string().max(255).optional(),
    available_until: z.union([z.string(), z.null()]).optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    category: z.string(),
    qualifier: z.string().max(15).optional(),
    current_price: z.union([z.string(), z.null()]).optional(),
    current_price_currency: z.string().optional(),
    future_price: z.union([z.string(), z.null()]).optional(),
    future_price_currency: z.string().optional(),
    future_price_application_date: z.union([z.string(), z.null()]).optional(),
    upfront_cost: z
      .string()
      .regex(/^-?\d{0,8}(?:\.\d{0,2})?$/)
      .optional(),
    upfront_cost_currency: z.string(),
    requires_shipping: z.boolean(),
    stallion: z.union([z.string(), z.null()]).optional(),
    shipping_provider_name: z.union([z.string(), z.null()]).optional(),
    shipping_service_type: ShippingServiceTypeEnum.optional(),
    shipping_countries: z
      .union([
        z.array(
          z.enum([
            'AF',
            'AX',
            'AL',
            'DZ',
            'AS',
            'AD',
            'AO',
            'AI',
            'AQ',
            'AG',
            'AR',
            'AM',
            'AW',
            'AU',
            'AT',
            'AZ',
            'BS',
            'BH',
            'BD',
            'BB',
            'BY',
            'BE',
            'BZ',
            'BJ',
            'BM',
            'BT',
            'BO',
            'BQ',
            'BA',
            'BW',
            'BV',
            'BR',
            'IO',
            'BN',
            'BG',
            'BF',
            'BI',
            'CV',
            'KH',
            'CM',
            'CA',
            'KY',
            'CF',
            'TD',
            'CL',
            'CN',
            'CX',
            'CC',
            'CO',
            'KM',
            'CG',
            'CD',
            'CK',
            'CR',
            'CI',
            'HR',
            'CU',
            'CW',
            'CY',
            'CZ',
            'DK',
            'DJ',
            'DM',
            'DO',
            'EC',
            'EG',
            'SV',
            'GQ',
            'ER',
            'EE',
            'SZ',
            'ET',
            'FK',
            'FO',
            'FJ',
            'FI',
            'FR',
            'GF',
            'PF',
            'TF',
            'GA',
            'GM',
            'GE',
            'DE',
            'GH',
            'GI',
            'GR',
            'GL',
            'GD',
            'GP',
            'GU',
            'GT',
            'GG',
            'GN',
            'GW',
            'GY',
            'HT',
            'HM',
            'VA',
            'HN',
            'HK',
            'HU',
            'IS',
            'IN',
            'ID',
            'IR',
            'IQ',
            'IE',
            'IM',
            'IL',
            'IT',
            'JM',
            'JP',
            'JE',
            'JO',
            'KZ',
            'KE',
            'KI',
            'KW',
            'KG',
            'LA',
            'LV',
            'LB',
            'LS',
            'LR',
            'LY',
            'LI',
            'LT',
            'LU',
            'MO',
            'MG',
            'MW',
            'MY',
            'MV',
            'ML',
            'MT',
            'MH',
            'MQ',
            'MR',
            'MU',
            'YT',
            'MX',
            'FM',
            'MD',
            'MC',
            'MN',
            'ME',
            'MS',
            'MA',
            'MZ',
            'MM',
            'NA',
            'NR',
            'NP',
            'NL',
            'NC',
            'NZ',
            'NI',
            'NE',
            'NG',
            'NU',
            'NF',
            'KP',
            'MK',
            'MP',
            'NO',
            'OM',
            'PK',
            'PW',
            'PS',
            'PA',
            'PG',
            'PY',
            'PE',
            'PH',
            'PN',
            'PL',
            'PT',
            'PR',
            'QA',
            'RE',
            'RO',
            'RU',
            'RW',
            'BL',
            'SH',
            'KN',
            'LC',
            'MF',
            'PM',
            'VC',
            'WS',
            'SM',
            'ST',
            'SA',
            'SN',
            'RS',
            'SC',
            'SL',
            'SG',
            'SX',
            'SK',
            'SI',
            'SB',
            'SO',
            'ZA',
            'GS',
            'KR',
            'SS',
            'ES',
            'LK',
            'SD',
            'SR',
            'SJ',
            'SE',
            'CH',
            'SY',
            'TW',
            'TJ',
            'TZ',
            'TH',
            'TL',
            'TG',
            'TK',
            'TO',
            'TT',
            'TN',
            'TR',
            'TM',
            'TC',
            'TV',
            'UG',
            'UA',
            'AE',
            'GB',
            'UM',
            'US',
            'UY',
            'UZ',
            'VU',
            'VE',
            'VN',
            'VG',
            'VI',
            'WF',
            'EH',
            'YE',
            'ZM',
            'ZW',
            '',
          ]),
        ),
        z.null(),
      ])
      .optional(),
  })
  .passthrough();
const PaginatedProductList = z
  .object({ count: z.number().int(), next: z.string().url().nullish(), previous: z.string().url().nullish(), results: z.array(Product) })
  .passthrough();
const PatchedProduct = z
  .object({
    uid: z.string(),
    name: z.string().max(100),
    hidden: z.boolean(),
    description: z.string().max(255),
    available_until: z.union([z.string(), z.null()]),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    category: z.string(),
    qualifier: z.string().max(15),
    current_price: z.union([z.string(), z.null()]),
    current_price_currency: z.string(),
    future_price: z.union([z.string(), z.null()]),
    future_price_currency: z.string(),
    future_price_application_date: z.union([z.string(), z.null()]),
    upfront_cost: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    upfront_cost_currency: z.string(),
    requires_shipping: z.boolean(),
    stallion: z.union([z.string(), z.null()]),
    shipping_provider_name: z.union([z.string(), z.null()]),
    shipping_service_type: ShippingServiceTypeEnum,
    shipping_countries: z.union([
      z.array(
        z.enum([
          'AF',
          'AX',
          'AL',
          'DZ',
          'AS',
          'AD',
          'AO',
          'AI',
          'AQ',
          'AG',
          'AR',
          'AM',
          'AW',
          'AU',
          'AT',
          'AZ',
          'BS',
          'BH',
          'BD',
          'BB',
          'BY',
          'BE',
          'BZ',
          'BJ',
          'BM',
          'BT',
          'BO',
          'BQ',
          'BA',
          'BW',
          'BV',
          'BR',
          'IO',
          'BN',
          'BG',
          'BF',
          'BI',
          'CV',
          'KH',
          'CM',
          'CA',
          'KY',
          'CF',
          'TD',
          'CL',
          'CN',
          'CX',
          'CC',
          'CO',
          'KM',
          'CG',
          'CD',
          'CK',
          'CR',
          'CI',
          'HR',
          'CU',
          'CW',
          'CY',
          'CZ',
          'DK',
          'DJ',
          'DM',
          'DO',
          'EC',
          'EG',
          'SV',
          'GQ',
          'ER',
          'EE',
          'SZ',
          'ET',
          'FK',
          'FO',
          'FJ',
          'FI',
          'FR',
          'GF',
          'PF',
          'TF',
          'GA',
          'GM',
          'GE',
          'DE',
          'GH',
          'GI',
          'GR',
          'GL',
          'GD',
          'GP',
          'GU',
          'GT',
          'GG',
          'GN',
          'GW',
          'GY',
          'HT',
          'HM',
          'VA',
          'HN',
          'HK',
          'HU',
          'IS',
          'IN',
          'ID',
          'IR',
          'IQ',
          'IE',
          'IM',
          'IL',
          'IT',
          'JM',
          'JP',
          'JE',
          'JO',
          'KZ',
          'KE',
          'KI',
          'KW',
          'KG',
          'LA',
          'LV',
          'LB',
          'LS',
          'LR',
          'LY',
          'LI',
          'LT',
          'LU',
          'MO',
          'MG',
          'MW',
          'MY',
          'MV',
          'ML',
          'MT',
          'MH',
          'MQ',
          'MR',
          'MU',
          'YT',
          'MX',
          'FM',
          'MD',
          'MC',
          'MN',
          'ME',
          'MS',
          'MA',
          'MZ',
          'MM',
          'NA',
          'NR',
          'NP',
          'NL',
          'NC',
          'NZ',
          'NI',
          'NE',
          'NG',
          'NU',
          'NF',
          'KP',
          'MK',
          'MP',
          'NO',
          'OM',
          'PK',
          'PW',
          'PS',
          'PA',
          'PG',
          'PY',
          'PE',
          'PH',
          'PN',
          'PL',
          'PT',
          'PR',
          'QA',
          'RE',
          'RO',
          'RU',
          'RW',
          'BL',
          'SH',
          'KN',
          'LC',
          'MF',
          'PM',
          'VC',
          'WS',
          'SM',
          'ST',
          'SA',
          'SN',
          'RS',
          'SC',
          'SL',
          'SG',
          'SX',
          'SK',
          'SI',
          'SB',
          'SO',
          'ZA',
          'GS',
          'KR',
          'SS',
          'ES',
          'LK',
          'SD',
          'SR',
          'SJ',
          'SE',
          'CH',
          'SY',
          'TW',
          'TJ',
          'TZ',
          'TH',
          'TL',
          'TG',
          'TK',
          'TO',
          'TT',
          'TN',
          'TR',
          'TM',
          'TC',
          'TV',
          'UG',
          'UA',
          'AE',
          'GB',
          'UM',
          'US',
          'UY',
          'UZ',
          'VU',
          'VE',
          'VN',
          'VG',
          'VI',
          'WF',
          'EH',
          'YE',
          'ZM',
          'ZW',
          '',
        ]),
      ),
      z.null(),
    ]),
  })
  .passthrough();
const Role = z
  .object({
    uid: z.string(),
    name: z.string().max(45).optional(),
    default_id: z.union([z.number(), z.null()]),
    stable_uid: z.union([z.string(), z.null()]).optional(),
    last_modified_on: z.string().datetime({ offset: true }),
    created_on: z.string().datetime({ offset: true }),
    hidden: z.boolean().optional(),
  })
  .passthrough();
const PatchedRole = z
  .object({
    uid: z.string(),
    name: z.string().max(45),
    default_id: z.union([z.number(), z.null()]),
    stable_uid: z.union([z.string(), z.null()]),
    last_modified_on: z.string().datetime({ offset: true }),
    created_on: z.string().datetime({ offset: true }),
    hidden: z.boolean(),
  })
  .passthrough();
const ModulePermissionsEnum = z.enum([
  'view_horses',
  'view_own_horses',
  'manage_horses',
  'view_horse_files',
  'edit_horse_files',
  'view_horse_owners',
  'medical_information',
  'manage_semen_journal',
  'manage_orders',
  'pick_orders',
  'manage_financial',
  'view_contacts',
  'manage_contacts',
  'view_schedule',
  'manage_schedule',
  'set_to_done',
]);
const ModulePermissions = z.object({ uid: z.string(), module_permissions: z.array(ModulePermissionsEnum) }).passthrough();
const Module = z.object({ id: z.string(), name: z.string() }).passthrough();
const ModulePermissionsObject = z.object({ id: z.string(), name: z.string(), module: Module }).passthrough();
const ModulePermissionsList = z.object({ uid: z.string(), module_permissions: z.array(ModulePermissionsObject) }).passthrough();
const Service = z
  .object({
    uid: z.string(),
    name: z.string().max(255),
    price: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    price_currency: z.string(),
    vat_percentage_uid: z.string(),
    category: z.string(),
    vat_included_in_price: z.boolean().optional(),
    description: z.string().max(255),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
  })
  .passthrough();
const PatchedService = z
  .object({
    uid: z.string(),
    name: z.string().max(255),
    price: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    price_currency: z.string(),
    vat_percentage_uid: z.string(),
    category: z.string(),
    vat_included_in_price: z.boolean(),
    description: z.string().max(255),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
  })
  .passthrough();
const StableContact = z
  .object({
    uid: z.string(),
    color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
    user_uid: z.union([z.string(), z.null()]),
    stables: z.array(z.union([z.string(), z.null()])).optional(),
    initials: z.string().max(3).optional(),
    hidden: z.boolean(),
    roles: z.array(z.union([z.string(), z.null()])).optional(),
    first_name: z.string().max(255).optional(),
    last_name: z.string().max(255).optional(),
    business_name: z.string().max(255),
    daily_activity_types: z.array(z.union([z.string(), z.null()])).optional(),
    phone_number: z.string().max(128),
    email: z.string().max(254).email().optional(),
    external_location: z.boolean().optional(),
    customer_id: z.string().max(7).optional(),
    moneybird_contact_id: z.union([z.number(), z.null()]).optional(),
    exactnl_contact_id: z.string().max(100).optional(),
    vat_number: z.string().max(15).optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    address_line1: z.string().max(255),
    address_line2: z.string().max(255).optional(),
    address_line3: z.string().max(255).optional(),
    country: CountryEnum.optional(),
    state: z.string().max(255).optional(),
    postcode: z.string().max(64),
    city: z.string().max(255),
    title: TitleEnum.optional(),
    date_of_birth: z.union([z.string(), z.null()]).optional(),
    show_in_daily: z.boolean().optional(),
    show_in_work_schedule: z.boolean().optional(),
    note: z.string().optional(),
    stable_location_uid: z.string(),
    UBN: z.string().max(30).optional(),
    invoice_language: InvoiceLanguageEnum.optional(),
    business_type: BusinessTypeEnum.optional(),
    lookup: z.string(),
    manage_horse_location_history: z.boolean(),
    machtiging_rvo_gegeven: z.union([z.string(), z.null()]),
    machtiging_rvo_gegeven_by_uid: z.union([z.string(), z.null()]),
    invitation: z.union([SentInvitation, z.null()]),
    create_user_invitation: z.boolean().optional().default(false),
    parent_stable: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough();
const Stable = z
  .object({
    uid: z.string(),
    created_by_uid: z.string(),
    location_uid: z.string(),
    timezone: TimezoneEnum.optional(),
    location: StableContact,
  })
  .passthrough();
const PatchedStable = z
  .object({ uid: z.string(), created_by_uid: z.string(), location_uid: z.string(), timezone: TimezoneEnum, location: StableContact })
  .passthrough();
const VaccinationRule = z
  .object({
    uid: z.string(),
    name: z.string().max(100).optional(),
    organisation_uid: z.union([z.string(), z.null()]),
    months_between_vaccination: z.number().int().gte(-2147483648).lte(2147483647),
    optional_extra_days: z.number().int().gte(-2147483648).lte(2147483647).optional(),
    number_of_days_not_competing_after_vaccination: z.number().int().gte(-2147483648).lte(2147483647),
  })
  .passthrough();
const PatchedVaccinationRule = z
  .object({
    uid: z.string(),
    name: z.string().max(100),
    organisation_uid: z.union([z.string(), z.null()]),
    months_between_vaccination: z.number().int().gte(-2147483648).lte(2147483647),
    optional_extra_days: z.number().int().gte(-2147483648).lte(2147483647),
    number_of_days_not_competing_after_vaccination: z.number().int().gte(-2147483648).lte(2147483647),
  })
  .passthrough();
const VATPercentageVatLevelEnum = z.enum(['STANDARD', 'REDUCED', 'SECOND_REDUCED', 'SUPER_REDUCED', 'ZERO']);
const VATPercentage = z
  .object({
    uid: z.string(),
    percentage: z.string().regex(/^-?\d{0,2}(?:\.\d{0,2})?$/),
    vat_category: VatCategoryEnum,
    hidden: z.boolean().optional(),
    created_on: z.string().datetime({ offset: true }),
    vat_level: VATPercentageVatLevelEnum.optional(),
    start_date: z.string().optional(),
    is_active: z.boolean(),
    country: CountryEnum.optional(),
  })
  .passthrough();
const VATPercentageUpdate = z.object({ hidden: z.boolean() }).passthrough();
const PatchedVATPercentageUpdate = z.object({ hidden: z.boolean() }).passthrough();
const PurchaserOrder = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    number: z.string(),
    customer_uid: z.string(),
    receiver_uid: z.string(),
    purchaser_uid: z.union([z.string(), z.null()]),
    supplier: z.string().uuid(),
    customer_note: z.string().optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    payment_summary: PaymentSummary,
    shipping_date: z.union([z.string(), z.null()]).optional(),
    order_items: z.array(PurchaserOrderItem),
    dry_run: z.boolean().optional(),
    terms_accepted: z.boolean(),
    historic_receiver: NameAndAddress,
  })
  .passthrough();
const PaginatedPurchaserOrderList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(PurchaserOrder),
  })
  .passthrough();
const PurchaserOrderDetail = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    number: z.string(),
    customer_uid: z.string(),
    receiver_uid: z.string(),
    purchaser_uid: z.union([z.string(), z.null()]),
    supplier: z.string().uuid(),
    customer_note: z.string().optional(),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    payment_summary: PaymentSummary,
    shipping_date: z.union([z.string(), z.null()]).optional(),
    order_items: z.array(PurchaserOrderItem),
    dry_run: z.boolean().optional(),
    terms_accepted: z.boolean(),
    historic_receiver: NameAndAddress,
    historic_customer: NameAndAddress,
    historic_requester: NameAndAddress,
  })
  .passthrough();
const NIFAStrawColorEnum = z.enum([
  'CLEAR',
  'PASTEL_GRAY',
  'YELLOW',
  'PASTEL_YELLOW',
  'PUTTY',
  'PASTEL_ORANGE',
  'SALMON',
  'PASTEL_RED',
  'PINK',
  'RED',
  'PASTEL_BLUE',
  'PURPLE',
  'PASTEL_PISTACHIO',
  'GREEN',
  'PASTEL_GREEN',
  'PISTACHIO_GREEN',
]);
const StallionMount = z
  .object({
    uid: z.string(),
    stallion: z.string(),
    collection_date: z.string().optional(),
    volume: z.number().int().gte(0).lte(65535).optional(),
    concentration: z.number().int().gte(0).lte(65535).optional(),
    moving_cells_percentage: z.number().int().gte(0).lte(100).optional(),
    storage_container: z.string().max(64).optional(),
    storage_canister: z.string().max(64).optional(),
    portions_fresh: z.number().int().gte(0).lte(65535).optional(),
    portions_fresh_available: z.number().int().optional(),
    portions_fresh_destroyed_amount: z.number().int(),
    portions_fresh_destroyed_on: z.union([z.string(), z.null()]),
    straws_frozen: z.number().int().gte(0).lte(65535).optional(),
    straws_frozen_available: z.number().int().optional(),
    straws_frozen_destroyed_amount: z.number().int(),
    straws_frozen_destroyed_on: z.union([z.string(), z.null()]),
    NIFA_straw_color: NIFAStrawColorEnum.optional(),
  })
  .passthrough();
const PaginatedStallionMountList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(StallionMount),
  })
  .passthrough();
const SupplierOrderItem = z
  .object({
    uid: z.string().optional(),
    order_uid: z.string(),
    product_uid: z.string(),
    unit_price: z
      .string()
      .regex(/^-?\d{0,8}(?:\.\d{0,2})?$/)
      .optional(),
    unit_price_currency: z.string(),
    upfront_payment: z.union([z.string(), z.null()]).optional(),
    upfront_payment_currency: z.union([z.string(), z.null()]),
    vat_percentage: NestedVATPercentage,
    quantity: z.number().int().gte(0).lte(65535).optional(),
    last_modified_by_uid: z.union([z.string(), z.null()]),
    last_modified_on: z.string().datetime({ offset: true }),
    picked_on: z.union([z.string(), z.null()]),
    picked_by_uid: z.union([z.string(), z.null()]),
    mare_uid: z.union([z.string(), z.null()]).optional(),
    semen_type: SemenTypeEnum.optional(),
    usage_type: UsageTypeEnum.optional(),
    studbook: StudbookEnum.optional(),
    traces: z.string().max(64).optional(),
    previous_semen_order_item_uid: z.union([z.string(), z.null()]).optional(),
    total_vat: Money,
  })
  .passthrough();
const SupplierOrder = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    number: z.string(),
    customer_uid: z.string(),
    requester_uid: z.union([z.string(), z.null()]).optional(),
    receiver_uid: z.string().optional(),
    supplier_note: z.string().optional(),
    customer_note: z.string(),
    created_on: z.string().datetime({ offset: true }),
    created_by: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    last_modified_by: z.string(),
    shipping_date: z.union([z.string(), z.null()]).optional(),
    payment_summary: PaymentSummary,
    dry_run: z.boolean().optional(),
    supplier_uid: z.string(),
    order_items: z.array(SupplierOrderItem).optional(),
    historic_receiver: NameAndAddress,
  })
  .passthrough();
const StallionMountDetail = z
  .object({
    uid: z.string(),
    stallion: z.string(),
    collection_date: z.string().optional(),
    volume: z.number().int().gte(0).lte(65535).optional(),
    concentration: z.number().int().gte(0).lte(65535).optional(),
    moving_cells_percentage: z.number().int().gte(0).lte(100).optional(),
    storage_container: z.string().max(64).optional(),
    storage_canister: z.string().max(64).optional(),
    portions_fresh: z.number().int().gte(0).lte(65535).optional(),
    portions_fresh_available: z.number().int().optional(),
    portions_fresh_destroyed_amount: z.number().int(),
    portions_fresh_destroyed_on: z.union([z.string(), z.null()]),
    straws_frozen: z.number().int().gte(0).lte(65535).optional(),
    straws_frozen_available: z.number().int().optional(),
    straws_frozen_destroyed_amount: z.number().int(),
    straws_frozen_destroyed_on: z.union([z.string(), z.null()]),
    NIFA_straw_color: NIFAStrawColorEnum.optional(),
    used_in_orders: z.array(SupplierOrder),
  })
  .passthrough();
const PatchedStallionMount = z
  .object({
    uid: z.string(),
    stallion: z.string(),
    collection_date: z.string(),
    volume: z.number().int().gte(0).lte(65535),
    concentration: z.number().int().gte(0).lte(65535),
    moving_cells_percentage: z.number().int().gte(0).lte(100),
    storage_container: z.string().max(64),
    storage_canister: z.string().max(64),
    portions_fresh: z.number().int().gte(0).lte(65535),
    portions_fresh_available: z.number().int(),
    portions_fresh_destroyed_amount: z.number().int(),
    portions_fresh_destroyed_on: z.union([z.string(), z.null()]),
    straws_frozen: z.number().int().gte(0).lte(65535),
    straws_frozen_available: z.number().int(),
    straws_frozen_destroyed_amount: z.number().int(),
    straws_frozen_destroyed_on: z.union([z.string(), z.null()]),
    NIFA_straw_color: NIFAStrawColorEnum,
  })
  .passthrough();
const PaginatedSupplierOrderList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(SupplierOrder),
  })
  .passthrough();
const PatchedSupplierOrderDetail = z
  .object({
    uid: z.string(),
    public_access_uuid: z.union([z.string(), z.null()]),
    number: z.string(),
    customer_uid: z.string(),
    requester_uid: z.union([z.string(), z.null()]),
    receiver_uid: z.string(),
    supplier_note: z.string(),
    customer_note: z.string(),
    created_on: z.string().datetime({ offset: true }),
    created_by: z.string(),
    last_modified_on: z.string().datetime({ offset: true }),
    last_modified_by: z.string(),
    shipping_date: z.union([z.string(), z.null()]),
    payment_summary: PaymentSummary,
    dry_run: z.boolean(),
    supplier_uid: z.string(),
    order_items: z.array(SupplierOrderItemDetail),
    historic_receiver: NameAndAddress,
    historic_customer: NameAndAddress,
    historic_requester: NameAndAddress,
  })
  .passthrough();
const PatchedOrganisation = z
  .object({
    uid: z.string(),
    created_by_uid: z.string(),
    last_modified_by_uid: z.string(),
    public_access_uuid: z.string().uuid(),
    name: z.string().max(45),
    created_on: z.string().datetime({ offset: true }),
    last_modified_on: z.string().datetime({ offset: true }),
    is_paid: z.boolean(),
    is_bp: z.boolean(),
    shop_terms_conditions: z.union([z.string(), z.null()]),
    timezone: TimezoneEnum,
    expected_no_horses: z.union([z.number(), z.null()]),
    weeks_between_insemination_and_vaccination: z.union([z.number(), z.null()]),
    days_between_insemination_and_pregnancy_check: z.union([z.number(), z.null()]),
    equiboard_refresh_rate: z.number().int().gte(0).lte(60),
    equiboard_screensaver_time: z.number().gte(0).lte(60),
    equiboard_lockscreendarkness: z.number().gte(0).lte(1),
    vat_number: z.string().max(15),
    email_address: z.string().max(254).email(),
    also_send_invoice_to_email: z.string().max(254).email(),
    website: z.string().max(200).url(),
    logo: z.union([z.string(), z.null()]),
    invoice_subject: z.string().max(45),
    invoice_text: z.string(),
    currency: z.string().max(3),
    bank_name: z.string().max(255),
    account_number: z.string().max(255),
    bank_code: z.string().max(255),
    invoice_footer_1: z.string(),
    invoice_footer_2: z.string(),
    max_no_horses: z.union([z.number(), z.null()]),
    last_information_send: z.union([z.string(), z.null()]),
    number_of_information_send_last_day: z.union([z.number(), z.null()]),
    relatienummer_rvo: z.string(),
    type_of_organisation: TypeOfOrganisationEnum,
    enabled_modules: z.array(EnabledModulesEnum),
    invoice_email_by_equinem: z.boolean(),
    primary_color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
    secondary_color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
    show_logo_on_invoice: z.boolean(),
    suggested_breeding_product_down_payment: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    down_payment_permitted_countries: z.array(CountryEnum).max(249),
    invoice_first_due_interval: z.number().int().gte(-2147483648).lte(2147483647),
    can_create_payment_link: z.boolean(),
    company_registration_number: z.string().max(20),
    address_line1: z.string().max(255),
    address_line2: z.string().max(255),
    address_line3: z.string().max(255),
    city: z.string().max(255),
    state: z.string().max(255),
    postcode: z.string().max(64),
    country: CountryEnum,
    phone_number: z.string().max(128),
    only_use_ad: z.boolean(),
    microsoft_ad_group_id: z.string(),
    exactnl_sales_journal: z.string().max(100),
  })
  .passthrough();
const MicrosoftGroup = z.object({ id: z.string().uuid(), display_name: z.string() }).passthrough();
const PatchedMicrosoftActiveDirectoryOrganisation = z
  .object({ microsoft_ad_group_id: z.string().max(255), only_use_ad: z.boolean() })
  .passthrough();
const ProviderEnum = z.enum(['moneybird', 'exactnl', 'yuki']);
const VATPercentageExternalProviderId = z
  .object({
    uid: z.string(),
    vat_percentage_uid: z.string(),
    provider: ProviderEnum,
    external_provider_sales_id: z.string().max(254).optional(),
    external_provider_purchases_id: z.string().max(254).optional(),
  })
  .passthrough();
const PatchedVATPercentageExternalProviderId = z
  .object({
    uid: z.string(),
    vat_percentage_uid: z.string(),
    provider: ProviderEnum,
    external_provider_sales_id: z.string().max(254),
    external_provider_purchases_id: z.string().max(254),
  })
  .passthrough();
const PasswordChange = z
  .object({ old_password: z.string().max(128), new_password1: z.string().max(128), new_password2: z.string().max(128) })
  .passthrough();
const RestAuthDetail = z.object({ detail: z.string() }).passthrough();
const EquineMPasswordReset = z.object({ email: z.string().email() }).passthrough();
const PasswordResetConfirm = z
  .object({ new_password1: z.string().max(128), new_password2: z.string().max(128), uid: z.string(), token: z.string() })
  .passthrough();
const PublicCategory = z
  .object({ uid: z.string(), p_s_type: PSTypeEnum, name: z.string(), default: z.union([z.string(), z.null()]) })
  .passthrough();
const PublicHorse = z
  .object({
    uid: z.string(),
    display_name: z.string(),
    name: z.string(),
    date_of_birth: z.union([z.string(), z.null()]),
    color: ColorEnum,
    sex: SexEnum,
    sire: z.string(),
    dam: z.string(),
    damsire: z.string(),
    siredam: z.string(),
    breeder: z.string(),
    studbook: z.string(),
    UELN: z.string(),
    chip_nr: z.string(),
    id_horsetelex: z.string(),
    avatar_file: z.union([z.string(), z.null()]),
  })
  .passthrough();
const CatalogueProduct = z
  .object({
    uid: z.string(),
    name: z.string(),
    description: z.string(),
    available_until: z.union([z.string(), z.null()]),
    category: PublicCategory,
    qualifier: z.string(),
    requires_shipping: z.boolean(),
    price: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    price_currency: z.string(),
    upfront_cost: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
    upfront_cost_currency: z.string(),
    shipping_provider_name: z.union([z.string(), z.null()]),
    shipping_service_type: ShippingServiceTypeEnum,
    shipping_countries: z.array(CountryEnum),
    stallion: z.union([PublicHorse, z.null()]),
  })
  .passthrough();
const PaginatedCatalogueProductList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(CatalogueProduct),
  })
  .passthrough();
const PaginatedVATPercentageList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullish(),
    previous: z.string().url().nullish(),
    results: z.array(VATPercentage),
  })
  .passthrough();
const PublicOrganisation = z
  .object({
    public_access_uuid: z.string().uuid(),
    name: z.string().max(45),
    country: CountryEnum,
    logo: z.union([z.string(), z.null()]).optional(),
    primary_color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
    secondary_color: z
      .string()
      .max(25)
      .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
      .optional(),
    shop_terms_conditions: z.union([z.string(), z.null()]).optional(),
    can_create_payment_link: z.boolean(),
  })
  .passthrough();
const SearchHorseTelex = z
  .object({
    names: z.string(),
    name: z.string(),
    sire: z.string(),
    dam: z.string(),
    damsire: z.string(),
    year_of_birth: z.string(),
    UELN: z.string(),
    FEI: z.string(),
    chip_nr: z.string(),
    page: z.number().int(),
  })
  .passthrough();
const HorseTelexResponse = z
  .object({
    id: z.union([z.number(), z.null()]),
    name: z.union([z.string(), z.null()]),
    alias1: z.union([z.string(), z.null()]),
    alias2: z.union([z.string(), z.null()]),
    alias3: z.union([z.string(), z.null()]),
    gek: z.union([z.unknown(), z.null()]),
    predicates: z.union([z.unknown(), z.null()]),
    studbookId: z.union([z.number(), z.null()]),
    father: z.union([z.string(), z.null()]),
    fatherAlias1: z.union([z.string(), z.null()]),
    fatherAlias2: z.union([z.string(), z.null()]),
    fatherAlias3: z.union([z.string(), z.null()]),
    fatherId: z.union([z.number(), z.null()]),
    mother: z.union([z.string(), z.null()]),
    motherAlias1: z.union([z.string(), z.null()]),
    motherAlias2: z.union([z.string(), z.null()]),
    motherAlias3: z.union([z.string(), z.null()]),
    motherId: z.union([z.number(), z.null()]),
    fatherOfMother: z.union([z.string(), z.null()]),
    fatherOfMotherAlias1: z.union([z.string(), z.null()]),
    fatherOfMotherAlias2: z.union([z.string(), z.null()]),
    fatherOfMotherAlias3: z.union([z.string(), z.null()]),
    fatherOfMotherId: z.union([z.number(), z.null()]),
    year: z.union([z.number(), z.null()]),
    studbook: z.union([z.string(), z.null()]),
    studbookShortname: z.union([z.string(), z.null()]),
    reg: z.union([z.string(), z.null()]),
    fei: z.union([z.string(), z.null()]),
    usef: z.union([z.string(), z.null()]),
    ueln: z.union([z.string(), z.null()]),
    lifeNumberAlpha2: z.union([z.string(), z.null()]),
    lifeNumberAlpha3: z.union([z.string(), z.null()]),
    registrationNumbers: z.union([z.unknown(), z.null()]),
    redId: z.union([z.number(), z.null()]),
    sex: z.union([z.number(), z.null()]),
    chipnumber: z.union([z.string(), z.null()]),
    notCompleted: z.union([z.boolean(), z.null()]),
    notCompletedDate: z.union([z.string(), z.null()]),
    isDeceased: z.union([z.boolean(), z.null()]),
    isBaseMare: z.union([z.number(), z.null()]),
    hasResults: z.union([z.number(), z.null()]),
    riders: z.union([z.unknown(), z.null()]),
    psrJumping: z.union([z.string(), z.null()]),
    psrDressage: z.union([z.string(), z.null()]),
    dominantSportType: z.union([z.string(), z.null()]),
    approved: z.union([z.boolean(), z.null()]),
    jumpscore: z.union([z.number(), z.null()]),
    dressagescore: z.union([z.number(), z.null()]),
    eventingscore: z.union([z.number(), z.null()]),
    sponsors: z.union([z.unknown(), z.null()]),
    modified: z.union([z.number(), z.null()]),
    mare: z.union([z.boolean(), z.null()]),
    equinem_horse: EquineMHorseSearchDetail,
  })
  .passthrough();
const HorseTelexListReponse = z
  .object({ items: z.array(HorseTelexResponse), totalItems: z.number().int(), page: z.number().int(), itemsPerPage: z.number().int() })
  .passthrough();
const EquineMRegister = z
  .object({
    username: z.string().min(2).max(150).optional(),
    email: z.string().email(),
    password1: z.string(),
    password2: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    agreed_to_terms_and_privacy: z.boolean(),
    UBN: z.string().max(30).optional(),
    phone_number: z.string().max(128),
    postcode: z.string().max(64).optional(),
    country: CountryEnum,
    coupon_code: z.string().optional(),
    type_of_organisation: TypeOfOrganisationEnum.optional(),
    address_line1: z.string().max(255).optional(),
    city: z.string().max(255).optional(),
    expected_no_horses: z.union([z.number(), z.null()]).optional(),
    address_line3: z.string().max(255).optional(),
    business_name: z.string().optional(),
    signup_supplier_shop: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough();
const SignupWithInvitation = z
  .object({
    password1: z.string(),
    password2: z.string(),
    invitation_uuid: z.string().uuid(),
    first_name: z.string(),
    last_name: z.string(),
    agreed_to_terms_and_privacy: z.boolean(),
  })
  .passthrough();
const SignupUser = z
  .object({
    id: z.number().int(),
    username: z
      .string()
      .max(150)
      .regex(/^[\w.@+-]+$/),
    email: z.string().email(),
    first_name: z.string().max(150).optional(),
    last_name: z.string().max(150).optional(),
  })
  .passthrough();
const SignupResponse = z.object({ access: z.string(), refresh: z.string(), user: SignupUser }).passthrough();
const VerifyEmail = z.object({ key: z.string() }).passthrough();

export const schemas = {
  TitleEnum,
  CountryEnum,
  Account,
  PatchedAccount,
  Email,
  SSO,
  InviterOrganisation,
  Inviter,
  ContactData,
  ReceivedInvitation,
  PatchedDeclinedInvitation,
  DeclinedInvitation,
  PaymentStatusEnum,
  PaymentSummary,
  NestedOrder,
  PublicInvoiceOrganisation,
  VatCategoryEnum,
  NestedVATPercentage,
  NamedHorse,
  Money,
  PublicInvoiceItem,
  NameAndAddress,
  PublicInvoice,
  PaymentLink,
  Redirect,
  TokenObtainPair,
  TokenRefresh,
  OrganisationOAuth,
  SocialLogin,
  UserDetails,
  JWT,
  SemenTypeEnum,
  UsageTypeEnum,
  StudbookEnum,
  PickedStallionMount,
  SupplierOrderItemDetail,
  SupplierOrderDetail,
  PurchaserOrderItem,
  PurchaserOrderUpdate,
  PatchedPurchaserOrderUpdate,
  RoleNames,
  StableNames,
  OrganisationContact,
  OrganisationList,
  TimezoneEnum,
  TypeOfOrganisationEnum,
  EnabledModulesEnum,
  Organisation,
  ActivityTypePrice,
  PatchedActivityTypePrice,
  FertilizerBudgetTypeEnum,
  FertilizerConsumer,
  PaginatedFertilizerConsumerList,
  PatchedFertilizerConsumer,
  FertilizerOriginEnum,
  MutationTypeEnum,
  OperatorEnum,
  ProductionBalanceItem,
  PaginatedProductionBalanceItemList,
  PatchedProductionBalanceItem,
  NitrogenApplication,
  PaginatedNitrogenApplicationList,
  PatchedNitrogenApplication,
  ServiceContact,
  PatchedServiceContact,
  PrivateEnum,
  HorseMedia,
  PaginatedHorseMediaList,
  HorseMediaUpdate,
  PatchedHorseMediaUpdate,
  HorseOwner,
  PaginatedHorseOwnerList,
  PatchedHorseOwner,
  ReportTypeEnum,
  ReportedHorseRelocationStatusEnum,
  ReportedHorseRelocation,
  PaginatedReportedHorseRelocationList,
  CreateReportedHorseRelocation,
  RvOEUCountry,
  RvOHorseHairColor,
  SentInvitation,
  InvoiceItem,
  PatchedInvoiceItem,
  DepartureReasonEnum,
  BestemmingLandCodeEnum,
  HorseLocation,
  PaginatedHorseLocationList,
  PatchedHorseLocation,
  ArrivalOrDepartureEnum,
  HorseLocationMutation,
  PaginatedHorseLocationMutationList,
  OrderItemStallionMountPicker,
  EventStatus,
  ActivityContactRole,
  WorkloadEnum,
  ShowStatusEnum,
  DatetimeOrDateField,
  RealActivities,
  PatchedRealActivities,
  PatchedRealActivitiesSetDoneAndUnDone,
  RealActivitiesSetDoneAndUnDone,
  InvoiceLanguageEnum,
  BusinessTypeEnum,
  Contact,
  PaginatedContactList,
  DefaultEnum,
  CategoryEnum,
  ExtraInfoFieldsEnum,
  ActivityType,
  PatchedActivityType,
  PSTypeEnum,
  VatLevelCountryVatLevelEnum,
  VatLevelCountry,
  Category,
  PatchedCategory,
  PatchedContact,
  PatchedMergeContacts,
  MergeContacts,
  DailyNote,
  PatchedDailyNote,
  PatchedDailyNoteSetDoneAndUnDone,
  DailyNoteSetDoneAndUnDone,
  DayPartStartTime,
  PatchedDayPartStartTime,
  PossibleCalendarHashObjects,
  EventFeeds,
  PatchedEventFeeds,
  HorseGroup,
  PatchedHorseGroup,
  ColorEnum,
  SexEnum,
  Location,
  Horse,
  PaginatedHorseList,
  NestedHorseLocation,
  HorseLocationMove,
  HorseDetail,
  PatchedHorseDetail,
  PatchedMergeHorses,
  MergeHorses,
  HorseMove,
  HorseMoveResponse,
  RvOHorseCreate,
  EquineMHorseSearchDetail,
  GetHorseUnprocessable,
  CannotCreateHorse,
  GetHorsesFromRvo,
  Progress,
  DbEnum,
  SearchHorse,
  Invoice,
  PaginatedInvoiceList,
  NestedInvoiceItem,
  InvoiceDetail,
  PatchedInvoiceDetail,
  InvoiceFinalize,
  OAuth2Token,
  PaymentTypeEnum,
  Payment,
  PaginatedPaymentList,
  PatchedPayment,
  shipping_service_type,
  ShippingServiceTypeEnum,
  Product,
  PaginatedProductList,
  PatchedProduct,
  Role,
  PatchedRole,
  ModulePermissionsEnum,
  ModulePermissions,
  Module,
  ModulePermissionsObject,
  ModulePermissionsList,
  Service,
  PatchedService,
  StableContact,
  Stable,
  PatchedStable,
  VaccinationRule,
  PatchedVaccinationRule,
  VATPercentageVatLevelEnum,
  VATPercentage,
  VATPercentageUpdate,
  PatchedVATPercentageUpdate,
  PurchaserOrder,
  PaginatedPurchaserOrderList,
  PurchaserOrderDetail,
  NIFAStrawColorEnum,
  StallionMount,
  PaginatedStallionMountList,
  SupplierOrderItem,
  SupplierOrder,
  StallionMountDetail,
  PatchedStallionMount,
  PaginatedSupplierOrderList,
  PatchedSupplierOrderDetail,
  PatchedOrganisation,
  MicrosoftGroup,
  PatchedMicrosoftActiveDirectoryOrganisation,
  ProviderEnum,
  VATPercentageExternalProviderId,
  PatchedVATPercentageExternalProviderId,
  PasswordChange,
  RestAuthDetail,
  EquineMPasswordReset,
  PasswordResetConfirm,
  PublicCategory,
  PublicHorse,
  CatalogueProduct,
  PaginatedCatalogueProductList,
  PaginatedVATPercentageList,
  PublicOrganisation,
  SearchHorseTelex,
  HorseTelexResponse,
  HorseTelexListReponse,
  EquineMRegister,
  SignupWithInvitation,
  SignupUser,
  SignupResponse,
  VerifyEmail,
};

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HorseMedia } from '../models/HorseMedia';
import type { HorseMediaUpdate } from '../models/HorseMediaUpdate';
import type { PaginatedHorseMediaList } from '../models/PaginatedHorseMediaList';
import type { PatchedHorseMediaUpdate } from '../models/PatchedHorseMediaUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class HorsemediaService {
  /**
   * List all Horsemedia that the user has access to.
   * If you want to upload a file, use multipart/form-data as content_type instead of application/json.
   * Example here: https://dev.to/thomz/uploading-images-to-django-rest-framework-from-forms-in-react-3jhj
   * @returns PaginatedHorseMediaList
   * @throws ApiError
   */
  public static horsemediaList({
    horseOrganisationUid,
    createdOnGte,
    createdOnLte,
    horseUid,
    horseUidIn,
    o,
    page,
    pageSize,
  }: {
    horseOrganisationUid: string,
    createdOnGte?: string,
    createdOnLte?: string,
    horseUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    horseUidIn?: Array<string>,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
  }): CancelablePromise<PaginatedHorseMediaList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{horse__organisation__uid}/horsemedia',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
      },
      query: {
        'created_on__gte': createdOnGte,
        'created_on__lte': createdOnLte,
        'horse__uid': horseUid,
        'horse__uid__in': horseUidIn,
        'o': o,
        'page': page,
        'page_size': pageSize,
      },
    });
  }
  /**
   * List all Horsemedia that the user has access to.
   * If you want to upload a file, use multipart/form-data as content_type instead of application/json.
   * Example here: https://dev.to/thomz/uploading-images-to-django-rest-framework-from-forms-in-react-3jhj
   * @returns HorseMedia
   * @throws ApiError
   */
  public static horsemediaCreate({
    horseOrganisationUid,
    requestBody,
  }: {
    horseOrganisationUid: string,
    requestBody: HorseMedia,
  }): CancelablePromise<HorseMedia> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{horse__organisation__uid}/horsemedia',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List all Horsemedia that the user has access to.
   * If you want to upload a file, use multipart/form-data as content_type instead of application/json.
   * Example here: https://dev.to/thomz/uploading-images-to-django-rest-framework-from-forms-in-react-3jhj
   * @returns HorseMedia
   * @throws ApiError
   */
  public static horsemediaRetrieve({
    horseOrganisationUid,
    uid,
  }: {
    horseOrganisationUid: string,
    uid: string,
  }): CancelablePromise<HorseMedia> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{horse__organisation__uid}/horsemedia/{uid}',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * List all Horsemedia that the user has access to.
   * If you want to upload a file, use multipart/form-data as content_type instead of application/json.
   * Example here: https://dev.to/thomz/uploading-images-to-django-rest-framework-from-forms-in-react-3jhj
   * @returns HorseMediaUpdate
   * @throws ApiError
   */
  public static horsemediaUpdate({
    horseOrganisationUid,
    uid,
    requestBody,
  }: {
    horseOrganisationUid: string,
    uid: string,
    requestBody: HorseMediaUpdate,
  }): CancelablePromise<HorseMediaUpdate> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{horse__organisation__uid}/horsemedia/{uid}',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List all Horsemedia that the user has access to.
   * If you want to upload a file, use multipart/form-data as content_type instead of application/json.
   * Example here: https://dev.to/thomz/uploading-images-to-django-rest-framework-from-forms-in-react-3jhj
   * @returns HorseMediaUpdate
   * @throws ApiError
   */
  public static horsemediaPartialUpdate({
    horseOrganisationUid,
    uid,
    requestBody,
  }: {
    horseOrganisationUid: string,
    uid: string,
    requestBody?: PatchedHorseMediaUpdate,
  }): CancelablePromise<HorseMediaUpdate> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{horse__organisation__uid}/horsemedia/{uid}',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List all Horsemedia that the user has access to.
   * If you want to upload a file, use multipart/form-data as content_type instead of application/json.
   * Example here: https://dev.to/thomz/uploading-images-to-django-rest-framework-from-forms-in-react-3jhj
   * @returns void
   * @throws ApiError
   */
  public static horsemediaDestroy({
    horseOrganisationUid,
    uid,
  }: {
    horseOrganisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{horse__organisation__uid}/horsemedia/{uid}',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'uid': uid,
      },
    });
  }
}

import { useOrganization } from 'context/OrganizationContext';
import { HorseLocation } from 'openapi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tile, TileList, TileRow } from 'ui/Layout/Tile';
import ListItem from './ListItem';
import ApiErrorParser from 'api/ApiErrorParser';
import useHorseDetail from 'hooks/UseHorseDetail';

/**
 * Tile that show the location and history for a given horse
 */
function LocationHistoryTile(): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<HorseLocation>>();

  const { loadHorseLocations, horseLocations, horse, contacts } = useHorseDetail();
  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();

  /**
   * Load the horse locations
   */
  useEffect(() => {
    if (selectedOrganization && horse) {
      const promise = loadHorseLocations(selectedOrganization.uid, horse.uid);
      promise
        .then(() => setApiError(undefined))
        .catch(error => {
          if (!promise.isCancelled) {
            setApiError(new ApiErrorParser<HorseLocation>(error));
            console.error(error);
          }
        });
      return () => promise.cancel();
    }
  }, [selectedOrganization, horse]); //eslint-disable-line

  return (
    <Tile title={t('location-history', 'Location history')} loading={horse === undefined} apiError={apiError}>
      {horse && (
        <TileList>
          {horseLocations && (
            <>
              {horseLocations.map((location, index) => (
                <TileRow key={location.uid}>
                  <ListItem isCurrent={index === 0} location={location} contacts={contacts} />
                </TileRow>
              ))}
              {horseLocations.length === 0 && (
                <p className='text-gray-500 italic'>{t('no-location-history', 'There is currently no location history.')}</p>
              )}
            </>
          )}
        </TileList>
      )}
    </Tile>
  );
}

export default LocationHistoryTile;

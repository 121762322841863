import { Invoice, InvoiceDetail } from 'openapi';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Badge, { BadgeSize } from '../../ui/Badge/Badge';
import { AllColors } from '../../utilities/colors';

interface Props {
  invoice?: InvoiceDetail | Invoice;
}

function InvoiceNumber({ invoice }: Props): JSX.Element {
  const { t } = useTranslation();

  const hasInvoiceNo = useMemo((): boolean => {
    return invoice !== undefined && invoice.invoice_no !== undefined && invoice.invoice_no !== null && invoice.invoice_no !== '';
  }, [invoice]);

  if (hasInvoiceNo) {
    return <span>{invoice?.invoice_no}</span>;
  }
  return (
    <Badge size={BadgeSize.Normal} color={AllColors.Stone}>
      {t('invoice-concept', 'Concept')}
    </Badge>
  );
}
export default InvoiceNumber;

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvoiceDetail } from '../models/InvoiceDetail';
import type { InvoiceFinalize } from '../models/InvoiceFinalize';
import type { InvoiceItem } from '../models/InvoiceItem';
import type { PaginatedInvoiceList } from '../models/PaginatedInvoiceList';
import type { PatchedInvoiceDetail } from '../models/PatchedInvoiceDetail';
import type { PatchedInvoiceItem } from '../models/PatchedInvoiceItem';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InvoicesService {
  /**
   * @returns InvoiceItem
   * @throws ApiError
   */
  public static invoicesInvoiceitemsList({
    invoiceOrganisationUid,
    invoiceUid,
    o,
  }: {
    invoiceOrganisationUid: string,
    invoiceUid: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
  }): CancelablePromise<Array<InvoiceItem>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{invoice__organisation__uid}/invoices/{invoice__uid}/invoiceitems',
      path: {
        'invoice__organisation__uid': invoiceOrganisationUid,
        'invoice__uid': invoiceUid,
      },
      query: {
        'o': o,
      },
    });
  }
  /**
   * @returns InvoiceItem
   * @throws ApiError
   */
  public static invoicesInvoiceitemsCreate({
    invoiceOrganisationUid,
    invoiceUid,
    requestBody,
  }: {
    invoiceOrganisationUid: string,
    invoiceUid: string,
    requestBody: InvoiceItem,
  }): CancelablePromise<InvoiceItem> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{invoice__organisation__uid}/invoices/{invoice__uid}/invoiceitems',
      path: {
        'invoice__organisation__uid': invoiceOrganisationUid,
        'invoice__uid': invoiceUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns InvoiceItem
   * @throws ApiError
   */
  public static invoicesInvoiceitemsRetrieve({
    invoiceOrganisationUid,
    invoiceUid,
    uid,
  }: {
    invoiceOrganisationUid: string,
    invoiceUid: string,
    uid: string,
  }): CancelablePromise<InvoiceItem> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{invoice__organisation__uid}/invoices/{invoice__uid}/invoiceitems/{uid}',
      path: {
        'invoice__organisation__uid': invoiceOrganisationUid,
        'invoice__uid': invoiceUid,
        'uid': uid,
      },
    });
  }
  /**
   * @returns InvoiceItem
   * @throws ApiError
   */
  public static invoicesInvoiceitemsUpdate({
    invoiceOrganisationUid,
    invoiceUid,
    uid,
    requestBody,
  }: {
    invoiceOrganisationUid: string,
    invoiceUid: string,
    uid: string,
    requestBody: InvoiceItem,
  }): CancelablePromise<InvoiceItem> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{invoice__organisation__uid}/invoices/{invoice__uid}/invoiceitems/{uid}',
      path: {
        'invoice__organisation__uid': invoiceOrganisationUid,
        'invoice__uid': invoiceUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns InvoiceItem
   * @throws ApiError
   */
  public static invoicesInvoiceitemsPartialUpdate({
    invoiceOrganisationUid,
    invoiceUid,
    uid,
    requestBody,
  }: {
    invoiceOrganisationUid: string,
    invoiceUid: string,
    uid: string,
    requestBody?: PatchedInvoiceItem,
  }): CancelablePromise<InvoiceItem> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{invoice__organisation__uid}/invoices/{invoice__uid}/invoiceitems/{uid}',
      path: {
        'invoice__organisation__uid': invoiceOrganisationUid,
        'invoice__uid': invoiceUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public static invoicesInvoiceitemsDestroy({
    invoiceOrganisationUid,
    invoiceUid,
    uid,
  }: {
    invoiceOrganisationUid: string,
    invoiceUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{invoice__organisation__uid}/invoices/{invoice__uid}/invoiceitems/{uid}',
      path: {
        'invoice__organisation__uid': invoiceOrganisationUid,
        'invoice__uid': invoiceUid,
        'uid': uid,
      },
    });
  }
  /**
   * sent_on: When is the invoice last sent
   * @returns PaginatedInvoiceList
   * @throws ApiError
   */
  public static invoicesList({
    organisationUid,
    createdByUid,
    createdOn,
    createdOnGt,
    createdOnGte,
    createdOnLt,
    createdOnLte,
    customerUid,
    customerUidIn,
    date,
    dateGt,
    dateGte,
    dateLt,
    dateLte,
    draft,
    endDateActivities,
    endDateActivitiesGt,
    endDateActivitiesGte,
    endDateActivitiesLt,
    endDateActivitiesLte,
    endDateServices,
    endDateServicesGt,
    endDateServicesGte,
    endDateServicesLt,
    endDateServicesLte,
    exactnlInvoiceId,
    expirationDate,
    expirationDateGt,
    expirationDateGte,
    expirationDateLt,
    expirationDateLte,
    finalizedOn,
    finalizedOnGt,
    finalizedOnGte,
    finalizedOnLt,
    finalizedOnLte,
    invoiceNo,
    lastModifiedOn,
    lastModifiedOnGt,
    lastModifiedOnGte,
    lastModifiedOnLt,
    lastModifiedOnLte,
    moneybirdInvoiceId,
    o,
    orderUid,
    page,
    pageSize,
    paymentStatus,
    paymentStatusIn,
    sent,
    sentToExactnl,
    sentToMoneybird,
    sentToYuki,
    startDateActivities,
    startDateActivitiesGt,
    startDateActivitiesGte,
    startDateActivitiesLt,
    startDateActivitiesLte,
    startDateServices,
    startDateServicesGt,
    startDateServicesGte,
    startDateServicesLt,
    startDateServicesLte,
    yukiInvoiceId,
  }: {
    organisationUid: string,
    createdByUid?: string,
    createdOn?: string,
    createdOnGt?: string,
    createdOnGte?: string,
    createdOnLt?: string,
    createdOnLte?: string,
    customerUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    customerUidIn?: Array<string>,
    date?: string,
    dateGt?: string,
    dateGte?: string,
    dateLt?: string,
    dateLte?: string,
    draft?: boolean,
    endDateActivities?: string,
    endDateActivitiesGt?: string,
    endDateActivitiesGte?: string,
    endDateActivitiesLt?: string,
    endDateActivitiesLte?: string,
    endDateServices?: string,
    endDateServicesGt?: string,
    endDateServicesGte?: string,
    endDateServicesLt?: string,
    endDateServicesLte?: string,
    exactnlInvoiceId?: string,
    expirationDate?: string,
    expirationDateGt?: string,
    expirationDateGte?: string,
    expirationDateLt?: string,
    expirationDateLte?: string,
    finalizedOn?: string,
    finalizedOnGt?: string,
    finalizedOnGte?: string,
    finalizedOnLt?: string,
    finalizedOnLte?: string,
    invoiceNo?: string,
    lastModifiedOn?: string,
    lastModifiedOnGt?: string,
    lastModifiedOnGte?: string,
    lastModifiedOnLt?: string,
    lastModifiedOnLte?: string,
    moneybirdInvoiceId?: number,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    orderUid?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    /**
     * * `OPEN` - Open
     * * `PROCESSING` - Processing
     * * `PAID` - Paid
     */
    paymentStatus?: 'OPEN' | 'PAID' | 'PROCESSING',
    /**
     * Multiple values may be separated by commas.
     */
    paymentStatusIn?: Array<string>,
    sent?: boolean,
    sentToExactnl?: boolean,
    sentToMoneybird?: boolean,
    sentToYuki?: boolean,
    startDateActivities?: string,
    startDateActivitiesGt?: string,
    startDateActivitiesGte?: string,
    startDateActivitiesLt?: string,
    startDateActivitiesLte?: string,
    startDateServices?: string,
    startDateServicesGt?: string,
    startDateServicesGte?: string,
    startDateServicesLt?: string,
    startDateServicesLte?: string,
    yukiInvoiceId?: string,
  }): CancelablePromise<PaginatedInvoiceList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/invoices',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'created_by__uid': createdByUid,
        'created_on': createdOn,
        'created_on__gt': createdOnGt,
        'created_on__gte': createdOnGte,
        'created_on__lt': createdOnLt,
        'created_on__lte': createdOnLte,
        'customer__uid': customerUid,
        'customer__uid__in': customerUidIn,
        'date': date,
        'date__gt': dateGt,
        'date__gte': dateGte,
        'date__lt': dateLt,
        'date__lte': dateLte,
        'draft': draft,
        'end_date_activities': endDateActivities,
        'end_date_activities__gt': endDateActivitiesGt,
        'end_date_activities__gte': endDateActivitiesGte,
        'end_date_activities__lt': endDateActivitiesLt,
        'end_date_activities__lte': endDateActivitiesLte,
        'end_date_services': endDateServices,
        'end_date_services__gt': endDateServicesGt,
        'end_date_services__gte': endDateServicesGte,
        'end_date_services__lt': endDateServicesLt,
        'end_date_services__lte': endDateServicesLte,
        'exactnl_invoice_id': exactnlInvoiceId,
        'expiration_date': expirationDate,
        'expiration_date__gt': expirationDateGt,
        'expiration_date__gte': expirationDateGte,
        'expiration_date__lt': expirationDateLt,
        'expiration_date__lte': expirationDateLte,
        'finalized_on': finalizedOn,
        'finalized_on__gt': finalizedOnGt,
        'finalized_on__gte': finalizedOnGte,
        'finalized_on__lt': finalizedOnLt,
        'finalized_on__lte': finalizedOnLte,
        'invoice_no': invoiceNo,
        'last_modified_on': lastModifiedOn,
        'last_modified_on__gt': lastModifiedOnGt,
        'last_modified_on__gte': lastModifiedOnGte,
        'last_modified_on__lt': lastModifiedOnLt,
        'last_modified_on__lte': lastModifiedOnLte,
        'moneybird_invoice_id': moneybirdInvoiceId,
        'o': o,
        'order__uid': orderUid,
        'page': page,
        'page_size': pageSize,
        'payment_status': paymentStatus,
        'payment_status__in': paymentStatusIn,
        'sent': sent,
        'sent_to_exactnl': sentToExactnl,
        'sent_to_moneybird': sentToMoneybird,
        'sent_to_yuki': sentToYuki,
        'start_date_activities': startDateActivities,
        'start_date_activities__gt': startDateActivitiesGt,
        'start_date_activities__gte': startDateActivitiesGte,
        'start_date_activities__lt': startDateActivitiesLt,
        'start_date_activities__lte': startDateActivitiesLte,
        'start_date_services': startDateServices,
        'start_date_services__gt': startDateServicesGt,
        'start_date_services__gte': startDateServicesGte,
        'start_date_services__lt': startDateServicesLt,
        'start_date_services__lte': startDateServicesLte,
        'yuki_invoice_id': yukiInvoiceId,
      },
    });
  }
  /**
   * sent_on: When is the invoice last sent
   * @returns InvoiceDetail
   * @throws ApiError
   */
  public static invoicesCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody: InvoiceDetail,
  }): CancelablePromise<InvoiceDetail> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/invoices',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * sent_on: When is the invoice last sent
   * @returns InvoiceDetail
   * @throws ApiError
   */
  public static invoicesRetrieve({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<InvoiceDetail> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/invoices/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * sent_on: When is the invoice last sent
   * @returns InvoiceDetail
   * @throws ApiError
   */
  public static invoicesUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody: InvoiceDetail,
  }): CancelablePromise<InvoiceDetail> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/invoices/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * sent_on: When is the invoice last sent
   * @returns InvoiceDetail
   * @throws ApiError
   */
  public static invoicesPartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedInvoiceDetail,
  }): CancelablePromise<InvoiceDetail> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/invoices/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * sent_on: When is the invoice last sent
   * @returns void
   * @throws ApiError
   */
  public static invoicesDestroy({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{organisation__uid}/invoices/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * Will finalize an invoice and possibly send it to the accounting software.
   * If the invoice is already finalized, it will not change the finalized_on, but you can still send it to the accounting software.
   * @returns InvoiceFinalize
   * @throws ApiError
   */
  public static invoicesFinalizeCreate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: InvoiceFinalize,
  }): CancelablePromise<InvoiceFinalize> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/invoices/{uid}/finalize',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Removes the link with ALL accounting software for this invoice.
   * @returns any No response body
   * @throws ApiError
   */
  public static invoicesRemoveLinkWithAccountingSoftwareCreate({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/invoices/{uid}/remove_link_with_accounting_software',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * sent_on: When is the invoice last sent
   * @returns any No response body
   * @throws ApiError
   */
  public static invoicesSendToAccountingSoftwareCreate({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/invoices/{uid}/send_to_accounting_software',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * sent_on: When is the invoice last sent
   * @returns any No response body
   * @throws ApiError
   */
  public static invoicesSendToClientCreate({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/invoices/{uid}/send_to_client',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * sent_on: When is the invoice last sent
   * @returns any No response body
   * @throws ApiError
   */
  public static invoicesUnfinalizeCreate({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/invoices/{uid}/unfinalize',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
}

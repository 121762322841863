/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `STANDARD` - standard
 * * `REDUCED` - reduced
 * * `SECOND_REDUCED` - second reduced
 * * `SUPER_REDUCED` - super reduced
 * * `ZERO` - zero
 */
export enum VATPercentageVatLevelEnum {
  STANDARD = 'STANDARD',
  REDUCED = 'REDUCED',
  SECOND_REDUCED = 'SECOND_REDUCED',
  SUPER_REDUCED = 'SUPER_REDUCED',
  ZERO = 'ZERO',
}

import { X } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ListFilterType } from '.';
import useListFilter from './useListFilter';

interface Props {
  listFilterTypes: ListFilterType[];
}

// A compact overview of the applied filters with an option to remove
export default function ListFilterBar({ listFilterTypes }: Props): JSX.Element {
  const { filters, setFilters } = useListFilter(listFilterTypes);
  const { t } = useTranslation();

  const removeFilter = useCallback(
    (filterType: ListFilterType) => {
      setFilters(filters.filter(f => f.type !== filterType));
    },
    [filters, setFilters],
  );

  return (
    <div className={classNames('select-none', { ['py-4']: filters.length > 0 })}>
      {filters.map(filter => {
        return (
          <div
            key={filter.type.id}
            className='inline-flex items-center justify-center pl-3 rounded-full bg-neutral-600 text-white mr-2 mb-1'
          >
            <div className='inline-block max-w-[200px] truncate'>
              <span className='text-sm mr-1 font-medium'>{filter.type.name}:</span>
              <ul className='text-sm inline'>
                {filter.options.map(option => {
                  return (
                    <li key={option.id} className={`inline mr-0.5 [&:not(:last-child)]:after:content-[',']`}>
                      {option.name}
                    </li>
                  );
                })}
              </ul>
            </div>

            <span
              className='inline pl-2 pr-3 py-2 cursor-pointer'
              onClick={() => {
                removeFilter(filter.type);
              }}
            >
              <X />
            </span>
          </div>
        );
      })}

      {filters.length > 0 && (
        <span
          className='font-bold text-neutral-600 text-sm cursor-pointer ml-4'
          onClick={() => {
            setFilters([]);
          }}
        >
          {t('clear-filter', 'Clear All')}
        </span>
      )}
    </div>
  );
}

import { ColorEnum } from 'openapi';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getColorCodeId, getColorFromId } from '../../utilities/string.utility';
/**
 * A functional component that displays a color block with its corresponding name
 * based on the provided colorId.
 *
 * @param {Object} props - Component props.
 * @param {number} props.colorId - The ID representing the color.
 * @returns {JSX.Element} - A JSX element rendering the color block and name.
 */
export default function ColorWithName({ colorId }: { colorId: ColorEnum }): JSX.Element {
  // Use the translation hook.
  const { t } = useTranslation();

  // If no valid colorId is provided, display a placeholder ('-').
  if (!colorId) {
    return <>{'-'}</>;
  }

  // Render a color block using the getColorCodeId function and display its name using the getColorFromId function.
  return (
    <div className={'gap-1 flex items-center'}>
      {/* Color block */}
      <div className={'w-4 h-4 rounded'} style={{ backgroundColor: getColorCodeId(colorId) }} />
      {/* Color name */}
      <div>{getColorFromId(colorId, t)}</div>
    </div>
  );
}

import ApiErrorParser from 'api/ApiErrorParser';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Severity } from '../../utilities/severity';
import { Alert } from '../Alert';
import parse from 'html-react-parser';

interface Props<T extends object = Record<string, unknown>> {
  errors?: string | ApiErrorParser<T> | Array<ApiErrorParser<T> | string | undefined> | undefined;
  severity?: Severity;
  className?: string;
}

function ErrorSection<T extends object>({ errors, severity = Severity.Danger, className }: Props<T>): JSX.Element {
  const [parsedErrors, setParsedErrors] = useState<ReactNode>();

  /**
   * Parse the errors
   */
  const parseError = useCallback(() => {
    const errorLines: ReactNode[] = [];

    if (Array.isArray(errors) && errors.length > 0) {
      errors.forEach(error => {
        if (error instanceof ApiErrorParser) {
          const nonFieldErrors = error.nonFieldErrorsStrings();
          nonFieldErrors.forEach(nonFieldError => errorLines.push(parse(nonFieldError)));
        } else if (error !== undefined) {
          errorLines.push(error);
        }
      });
    } else if (typeof errors === 'string') {
      errorLines.push(errors);
    } else if (errors instanceof ApiErrorParser && errors.nonFieldErrorsStrings().length > 0) {
      const nonfieldErrors = errors.nonFieldErrorsStrings();
      nonfieldErrors.forEach(nonFieldError => errorLines.push(parse(nonFieldError)));
    }

    if (errorLines.length === 0) {
      return;
    }

    if (errorLines.length === 1) {
      return errorLines[0];
    }

    return errorLines.map((error, index) => <li key={index}>{error}</li>);
  }, [errors]);

  /**
   * Set the errors based on the given error
   */
  useEffect(() => {
    setParsedErrors(() => parseError());
  }, [errors, parseError]);

  return (
    <>
      {parsedErrors !== undefined && (
        <div className={className}>
          <Alert severity={severity} message={parsedErrors} />
        </div>
      )}
    </>
  );
}
export default ErrorSection;

import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { ReceivedInvitation } from 'openapi';

interface Props {
  invitations?: ReceivedInvitation[];
  children: React.ReactNode;
}

type InvitationContextType = {
  invitations: Props['invitations'];
  setInvitations: Dispatch<SetStateAction<Props['invitations']>>;
};

const InvitationContext = createContext<InvitationContextType>({
  invitations: undefined,
  setInvitations: () => console.warn('no Invitation provider'),
});

export function useInvitationContext(): InvitationContextType {
  return useContext(InvitationContext);
}

export function InvitationProvider({ children }: Props): JSX.Element {
  const [invitations, setInvitations] = useState<Props['invitations']>();

  return <InvitationContext.Provider value={{ invitations, setInvitations }}>{children}</InvitationContext.Provider>;
}

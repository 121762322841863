/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateReportedHorseRelocation } from '../models/CreateReportedHorseRelocation';
import type { PaginatedReportedHorseRelocationList } from '../models/PaginatedReportedHorseRelocationList';
import type { ReportedHorseRelocation } from '../models/ReportedHorseRelocation';
import type { RvOEUCountry } from '../models/RvOEUCountry';
import type { RvOHorseHairColor } from '../models/RvOHorseHairColor';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ReportedhorserelocationsService {
  /**
   * @returns PaginatedReportedHorseRelocationList
   * @throws ApiError
   */
  public static reportedhorserelocationsList({
    horseOrganisationUid,
    arrivalOrDepartureType,
    horse,
    horselocation,
    o,
    page,
    pageSize,
  }: {
    horseOrganisationUid: string,
    /**
     * Filter by arrival (a) or departure (d)
     */
    arrivalOrDepartureType?: string,
    horse?: number,
    horselocation?: number,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
  }): CancelablePromise<PaginatedReportedHorseRelocationList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{horse__organisation__uid}/reportedhorserelocations',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
      },
      query: {
        'arrival_or_departure_type': arrivalOrDepartureType,
        'horse': horse,
        'horselocation': horselocation,
        'o': o,
        'page': page,
        'page_size': pageSize,
      },
    });
  }
  /**
   * Create a location report from a horse location.
   * @returns CreateReportedHorseRelocation
   * @throws ApiError
   */
  public static reportedhorserelocationsCreate({
    horseOrganisationUid,
    requestBody,
  }: {
    horseOrganisationUid: string,
    requestBody: CreateReportedHorseRelocation,
  }): CancelablePromise<CreateReportedHorseRelocation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{horse__organisation__uid}/reportedhorserelocations',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns ReportedHorseRelocation
   * @throws ApiError
   */
  public static reportedhorserelocationsRetrieve({
    horseOrganisationUid,
    uid,
  }: {
    horseOrganisationUid: string,
    uid: string,
  }): CancelablePromise<ReportedHorseRelocation> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{horse__organisation__uid}/reportedhorserelocations/{uid}',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * Checks status for an existing report
   * @returns ReportedHorseRelocation
   * @throws ApiError
   */
  public static reportedhorserelocationsCheckStatusCreate({
    horseOrganisationUid,
    uid,
  }: {
    horseOrganisationUid: string,
    uid: string,
  }): CancelablePromise<ReportedHorseRelocation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{horse__organisation__uid}/reportedhorserelocations/{uid}/check_status',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * Withdraws an existing report
   * @returns ReportedHorseRelocation
   * @throws ApiError
   */
  public static reportedhorserelocationsWithdrawReportCreate({
    horseOrganisationUid,
    uid,
  }: {
    horseOrganisationUid: string,
    uid: string,
  }): CancelablePromise<ReportedHorseRelocation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{horse__organisation__uid}/reportedhorserelocations/{uid}/withdraw_report',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * @returns RvOEUCountry
   * @throws ApiError
   */
  public static reportedhorserelocationsRvOeuCountriesList({
    horseOrganisationUid,
  }: {
    horseOrganisationUid: string,
  }): CancelablePromise<Array<RvOEUCountry>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{horse__organisation__uid}/reportedhorserelocations/RvOEUCountries',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
      },
    });
  }
  /**
   * @returns RvOHorseHairColor
   * @throws ApiError
   */
  public static reportedhorserelocationsRvOHorseHairColorsList({
    horseOrganisationUid,
  }: {
    horseOrganisationUid: string,
  }): CancelablePromise<Array<RvOHorseHairColor>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{horse__organisation__uid}/reportedhorserelocations/RvOHorseHairColors',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
      },
    });
  }
}

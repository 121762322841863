import { PublicInvoice, PublicService } from 'openapi';
import { useCallback, useEffect, useState } from 'react';
import { useConfig } from '../../context/ConfigContext';

interface ReturnType {
  loading: boolean;
  load: (abort?: AbortSignal) => void;
  invoice?: PublicInvoice;
  error: string | undefined;
}

const usePublicInvoice = (publicInvoiceUuid?: string): ReturnType => {
  const [error, setError] = useState<string>();
  const [invoice, setInvoice] = useState<PublicInvoice>();
  const [loading, setLoading] = useState<boolean>(true);
  const { config } = useConfig();

  const load = useCallback(
    async (abort?: AbortSignal) => {
      if (!publicInvoiceUuid) {
        console.error('Cannot load invoice without an public invoice uuid');
        setLoading(false);
        return;
      }
      if (!config) {
        console.error('Config is not set when trying to receive public invoice');
        setLoading(false);
        return;
      }
      if (abort && abort.aborted) {
        setLoading(false);
        return;
      }
      const promise = PublicService.apiV5InvoicesRetrieve({ publicAccessUuid: publicInvoiceUuid });
      if (abort) abort.onabort = () => promise.cancel();
      return await promise;
    },
    [config, publicInvoiceUuid],
  );

  useEffect(() => {
    const abortController = new AbortController();
    load(abortController.signal)
      .then(invoice => setInvoice(invoice))
      .catch((e: Error) => setError(e.name));
    return () => abortController.abort();
  }, [load, publicInvoiceUuid]);

  return {
    loading,
    error,
    invoice,
    load,
  };
};

export default usePublicInvoice;

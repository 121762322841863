import { ArrowLeft, ArrowRight, ChatText, Check, Megaphone } from '@phosphor-icons/react';
import { usePlanning } from 'hooks/UsePlanning';
import React, { useMemo } from 'react';
import ActivityModal from './ActivityModal';
import { BluePrintState, CalendarActivity, CalendarActivityType, SelectedActivityState } from 'utilities/Planning';
import { areIntervalsOverlapping, differenceInDays, endOfDay, getOverlappingDaysInIntervals, Interval, isSameDay } from 'date-fns';
import BluePrintModal from './BluePrintModal';
import classNames from 'classnames';
import { contactName } from 'utilities/Contact';

const borderRadius = 4;

export interface Props {
  activities: CalendarActivity[];
  availableWidth: number;
  className?: string;
  days: Date[];
  selectedStableUids?: string[];
  enableBluePrint?: boolean;
  showAnnouncements?: boolean;
  spacious?: boolean;
}

interface ActivityPosition {
  activity: CalendarActivity;
  width: number;
  height: number;
  left: number;
  top: number;

  // True when the activity starts earlier then this calendar view can display.
  // E.g. The calendar shows monday to sunday but the event is from saturday (previous week) to tuesday.
  overflowsStart: boolean;

  // True when the activity end later then this calendar view can display.
  // E.g. The calendar shows monday to sunday but the event is from saturday to tuesday (next week).
  overflowsEnd: boolean;
}

/**
 * Lays out DailyNote calendar activities in the calendar header.
 * This is visible at this part (`x` marks the spot) of the calendar.
 * It shows the full/multi day activities in the header section.
 *
 * ┌──────┌──────┌──────┌──────┌──────┌──────┌──────┐
 * │  12  │  13  │  14  │  15  │  16  │  17  │  18  │
 * │      │      │      │      │      │      │      │
 * │xxxxxx│xxxxxx│xxxxxx│xxxxxx│xxxxxx│xxxxxx│xxxxxx│
 * ┼──────┼──────┼──────┼──────┼──────┼──────┼──────┼
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * └──────└──────└──────└──────└──────└──────└──────┘
 *
 */
export default function ActivityContainerDailyNotes({
  activities,
  availableWidth,
  days,
  className,
  selectedStableUids,
  enableBluePrint,
  showAnnouncements = true,
  spacious = false,
}: Props): JSX.Element {
  const { bluePrint, requestBluePrint, unsetBluePrint, selectedActivity, setSelectedActivity, clearSelectedActivity, stables } =
    usePlanning();

  const bluePrintClasses = 'w-full bg-blue-500 bg-opacity-10 rounded-lg border-blue-500 border-2';

  const dailyNoteHeight = useMemo(() => {
    return spacious ? 35 : 25;
  }, [spacious]);

  const itemSpacing = useMemo(() => {
    return spacious ? 4 : 1;
  }, [spacious]);

  /**
   * Group the activities into rows. It tries to show activities side by side if they don't overlap.
   * This reduces the amount of height the header takes.
   */
  const rows = useMemo((): CalendarActivity[][] => {
    // Sort by start date first
    const sorted = activities
      .filter(act => {
        if (!act.stableUid && !showAnnouncements) {
          // Announcements are daily notes without a stable uid. This means everybody can see them.
          return false;
        }
        // Only show the daily notes that are part of the selectedStableUids.
        return !selectedStableUids || !act.stableUid || selectedStableUids.includes(act.stableUid);
      })
      .sort((a, b) => a.startTime.valueOf() - b.startTime.valueOf());
    const rows: CalendarActivity[][] = [];

    sorted.forEach((act: CalendarActivity) => {
      for (const row of rows) {
        let hasOverlap = false;
        for (const rowActivity of row) {
          if (
            areIntervalsOverlapping(
              { start: act.startTime, end: act.endTime },
              { start: rowActivity.startTime, end: endOfDay(rowActivity.endTime) },
            )
          ) {
            hasOverlap = true;
          }
        }
        if (!hasOverlap) {
          row.push(act);
          return;
        }
      }
      rows.push([act]);
    });

    return rows;
  }, [activities, selectedStableUids, showAnnouncements]);

  /**
   * Build a nice human readable text for the individual activity.
   */
  const activityText = (activity: CalendarActivity): string => {
    let text = '';
    if (activity.stableUid && (selectedStableUids?.length ?? 0) > 1) {
      const stable = stables?.find(stable => stable.uid === activity.stableUid);
      if ((stables?.length ?? 0) > 1) {
        text += stable ? contactName(stable.location) : activity.stableUid;
        text += ': ';
      }
    }
    if (activity.title) {
      text += activity.title;
    } else if (activity.text) {
      text += activity.text;
    } else {
      text += '--';
    }

    return text;
  };

  /**
   * Convert the rows into actual pixel positioning for each activity.
   */
  const layout = useMemo((): ActivityPosition[] => {
    return rows.flatMap((row, rowIndex): ActivityPosition[] => {
      return row.map((activity): ActivityPosition => {
        const endOfLastDay = endOfDay(days[days.length - 1]);
        const cappedActivityStart = activity.startTime < days[0] ? days[0] : activity.startTime;
        const cappedActivityEnd = activity.endTime > endOfLastDay ? endOfLastDay : activity.endTime;
        const daysInterval: Interval = { start: days[0], end: endOfLastDay };
        const activityInterval: Interval = { start: cappedActivityStart, end: cappedActivityEnd };
        const columnWidth = availableWidth / days.length;
        return {
          activity,
          left: differenceInDays(cappedActivityStart, days[0]) * columnWidth,
          width: getOverlappingDaysInIntervals(daysInterval, activityInterval) * columnWidth,
          height: dailyNoteHeight,
          top: rowIndex * dailyNoteHeight,
          overflowsStart: days[0].valueOf() > activity.startTime.valueOf(),
          overflowsEnd: endOfLastDay.valueOf() < activity.endTime.valueOf(),
        };
      });
    });
  }, [rows, availableWidth, days, dailyNoteHeight]);

  const totalHeight = rows.length * dailyNoteHeight + (enableBluePrint ? dailyNoteHeight : 0);

  return (
    <div className={className}>
      <div className='w-full relative' style={{ width: availableWidth, height: totalHeight }}>
        <div className='absolute inset-0 flex' style={{ height: totalHeight }}>
          {days.map((day, index) => (
            <div
              key={day.valueOf()}
              style={{ width: availableWidth / days.length }}
              className={classNames({ 'border-white border-opacity-20 md:border-inherit border-r': index !== days.length - 1 })}
              onClick={e => {
                e.stopPropagation();
                if (selectedActivity && selectedActivity.selectedActivityState !== SelectedActivityState.Selected) {
                  setSelectedActivity(selectedActivity.activity, SelectedActivityState.Selected, selectedActivity?.groupByUid);
                } else if (bluePrint) {
                  // We already have a blueprint active then close it. Otherwise create a new blueprint for the given day part.
                  unsetBluePrint();
                  clearSelectedActivity();
                } else {
                  clearSelectedActivity();
                  requestBluePrint({
                    day,
                    duration: 0,
                    startPeriodOffset: 0,
                    stableUid: (selectedStableUids?.length ?? 0) === 1 ? selectedStableUids?.[0] : undefined,
                    state: BluePrintState.EditCompact,
                    preferredType: CalendarActivityType.Task,
                  });
                }
              }}
            />
          ))}
        </div>
        {layout.map(layoutActivity => (
          <ActivityModal
            key={layoutActivity.activity.uid}
            activity={layoutActivity.activity}
            style={{
              position: 'absolute',
              height: layoutActivity.height,
              width: layoutActivity.width,
              left: layoutActivity.left,
              top: layoutActivity.top,
              paddingBottom: itemSpacing,
            }}
          >
            <div
              onClick={e => {
                setSelectedActivity(layoutActivity.activity, SelectedActivityState.Info);
                unsetBluePrint();
                e.stopPropagation();
              }}
              className={classNames('flex justify-center items-center cursor-pointer select-none h-full mr-[1px] border-neutral-500', {
                'px-[4px]': selectedActivity?.activity !== layoutActivity.activity,
                'border px-[3px]': selectedActivity?.activity === layoutActivity.activity, // Add border and adapt padding for border width
              })}
              style={{
                backgroundColor: layoutActivity.activity.secondaryColor,
                borderTopLeftRadius: layoutActivity.overflowsStart ? dailyNoteHeight * 0.5 : borderRadius,
                borderBottomLeftRadius: layoutActivity.overflowsStart ? dailyNoteHeight * 0.5 : borderRadius,
                borderTopRightRadius: layoutActivity.overflowsEnd ? dailyNoteHeight * 0.5 : borderRadius,
                borderBottomRightRadius: layoutActivity.overflowsEnd ? dailyNoteHeight * 0.5 : borderRadius,
              }}
            >
              <div className='grow truncate flex items-center'>
                {layoutActivity.overflowsStart && <ArrowLeft className='inline' />}
                <span className='text-sm truncate grow flex gap-0.5 items-center'>
                  <div>{!layoutActivity.activity.executable && !layoutActivity.activity.stableUid && <Megaphone />}</div>
                  <div>{!layoutActivity.activity.executable && layoutActivity.activity.stableUid && <ChatText />}</div>
                  {layoutActivity.activity.done && (
                    <div className='shrink-0 inline text-white bg-emerald-600 rounded-full w-4 h-4 flex items-center justify-center text-xs'>
                      <Check />
                    </div>
                  )}
                  {activityText(layoutActivity.activity)}
                </span>
                {layoutActivity.overflowsEnd && <ArrowRight className='inline' />}
              </div>
            </div>
          </ActivityModal>
        ))}
        <div className='absolute inset-x-0 bottom-0 flex pointer-events-none' style={{ height: dailyNoteHeight }}>
          {days.map(day => (
            <div key={day.valueOf()} style={{ width: availableWidth / days.length }} className='hover:bg-blue-500 pr-[1px]'>
              {enableBluePrint &&
                bluePrint &&
                bluePrint.day &&
                isSameDay(bluePrint.day, day) &&
                (bluePrint.type === CalendarActivityType.Task ||
                  bluePrint.type === CalendarActivityType.Message ||
                  bluePrint.preferredType === CalendarActivityType.Task ||
                  bluePrint.preferredType === CalendarActivityType.Message) && (
                  <BluePrintModal>
                    <div className={bluePrintClasses} style={{ height: dailyNoteHeight }} onClick={e => e.stopPropagation()} />
                  </BluePrintModal>
                )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import { zodResolver } from '@hookform/resolvers/zod';
import { Pencil } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { Organisation, OrganisationService, PatchedOrganisation } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { TextInput } from 'ui/Inputs';
import { Tile } from 'ui/Layout/Tile';
import TileDescriptionList from 'ui/Layout/Tile/TileDescriptionList';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { zodInputIsRequired } from 'utilities/zod';

export default function EquiBoardTile(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const { selectedOrganizationDetails, refresh } = useOrganization();

  /**
   * Define the default values
   */
  const defaultValues = useMemo(() => {
    return {
      equiboard_refresh_rate: selectedOrganizationDetails?.equiboard_refresh_rate,
      equiboard_screensaver_time: selectedOrganizationDetails?.equiboard_screensaver_time,
      equiboard_lockscreendarkness: selectedOrganizationDetails?.equiboard_lockscreendarkness,
    };
  }, [selectedOrganizationDetails]);

  /**
   * Schema for the form
   */
  const schema = useMemo(() => {
    return schemas.Organisation.pick({
      equiboard_refresh_rate: true,
      equiboard_screensaver_time: true,
      equiboard_lockscreendarkness: true,
    });
  }, []);

  const { showModal, closeModal, modalIsVisible } = useModal();
  const {
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    register,
  } = useForm<Organisation>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Close event for the modal
   */
  const onClose = (resetForm = true) => {
    if (resetForm) {
      reset(defaultValues);
    }
    // clear the errors
    clearErrors();
    setApiError(undefined);
    closeModal();
  };

  /**
   * Submit event handler, update the data via the API for this organization
   */
  const onSubmit: SubmitHandler<PatchedOrganisation> = async (data: PatchedOrganisation) => {
    if (!selectedOrganizationDetails) return;
    setLoading(true);

    try {
      await OrganisationService.rootPartialUpdate({ uid: selectedOrganizationDetails.uid, requestBody: data });
      // refresh the organization
      refresh();

      // close the modal
      onClose();
    } catch (error) {
      setApiError(new ApiErrorParser<PatchedOrganisation>(error));
    }
    setLoading(false);
  };

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo(() => {
    return [
      {
        onClick: showModal,
        text: t('edit', 'Edit'),
        buttonVariant: ButtonVariant.Default,
        icon: <Pencil />,
      },
    ];
  }, [showModal, t]);

  return (
    <Tile title={t('equiboard-settings', 'EquiBoard settings')} loading={!selectedOrganizationDetails} actions={tileActions}>
      <TileDescriptionList
        classNameDT='sm:w-2/5'
        list={[
          {
            term: t('refresh-rate', 'Refresh rate'),
            definition: selectedOrganizationDetails
              ? t('number-of-minutes', '{{n}} minutes', { n: selectedOrganizationDetails.equiboard_refresh_rate })
              : '-',
          },
          {
            term: t('screensaver-time', 'Screensaver time'),
            definition: selectedOrganizationDetails
              ? t('number-of-minutes', '{{n}} minutes', { n: selectedOrganizationDetails.equiboard_screensaver_time })
              : '-',
          },
          {
            term: t('lockscreendarkness', 'Darkness of lockscreen'),
            definition:
              selectedOrganizationDetails && selectedOrganizationDetails.equiboard_lockscreendarkness
                ? `${selectedOrganizationDetails.equiboard_lockscreendarkness * 100}%`
                : '-',
          },
        ]}
      />

      <PageModal
        open={modalIsVisible}
        parentElement='form'
        parentProps={{ id: 'updateEquiBoard', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={t('update-equiboard-settings', 'Update EquiBoard settings')} onClose={onClose} />
        <PageModalContent>
          <ErrorSection className='mb-4' errors={nonFieldErrors} />

          <div className='grid gap-3 grid-cols-1'>
            <TextInput
              type='number'
              error={fieldError('equiboard_refresh_rate')}
              required={zodInputIsRequired<Organisation>(schema, 'equiboard_refresh_rate')}
              label={t('refresh-rate', 'Refresh rate')}
              hint={t('refresh-rate-hint', "The default, and minimum, is 10 minutes. Set 0 if you don't want automatic refresh to happen.")}
              postText={t('minutes', 'minutes')}
              {...register('equiboard_refresh_rate', { valueAsNumber: true })}
            />
            <TextInput
              type='number'
              error={fieldError('equiboard_screensaver_time')}
              required={zodInputIsRequired<Organisation>(schema, 'equiboard_screensaver_time')}
              label={t('screensaver-time', 'Screensaver time')}
              hint={t(
                'screensaver-time-hint',
                'Time of inactivity after which the screensaver should show on all EquiBoards in your organisation. The default is 10 minutes. A screensaver elongates the lifetime of your display and saves the environment by using less energy.',
              )}
              postText={t('minutes', 'minutes')}
              {...register('equiboard_screensaver_time', { valueAsNumber: true })}
            />
            <TextInput
              type='number'
              error={fieldError('equiboard_lockscreendarkness')}
              required={zodInputIsRequired<Organisation>(schema, 'equiboard_lockscreendarkness')}
              label={t('lockscreendarkness', 'Darkness of lockscreen')}
              hint={t('lockscreendarkness-hint', 'The default is 0.2. 0 means completely light, 1 means completely dark.')}
              {...register('equiboard_lockscreendarkness', { valueAsNumber: true })}
            />
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              onClick: onClose,
              variant: ButtonVariant.Default,
              type: 'button',
              text: t('cancel', 'Cancel'),
            },
            {
              formId: 'updateEquiBoard',
              loading: loading,
              variant: ButtonVariant.Primary,
              type: 'submit',
              text: t('save', 'Save'),
            },
          ]}
        />
      </PageModal>
    </Tile>
  );
}

import { CaretRight, Wallet } from '@phosphor-icons/react';
import { cachedPaginatedApiData } from 'api/ApiCache';
import { useOrganization } from 'context/OrganizationContext';
import { CancelablePromise, Contact, ContactsService, PaginatedPaymentList, Payment, PaymentsService } from 'openapi';
import { PaginatedContactList } from 'openapi/models/PaginatedContactList';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { usePage } from '../../context/PageContext';
import Page, { navBackToThisPage, PageMaxWidth } from '../../ui/Layout/Page';
import { defaultApiPageSize, tableTbodyTrNoClick, tableTheadTdSticky } from 'ui/Const';
import ApiErrorParser from 'api/ApiErrorParser';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { table, tableHiddenColumnMd, tableHiddenHeaderSm, tableTbody, tableTbodyTr, tableTheadTd } from '../../ui/Const';
import classNames from 'classnames';
import { useAccount } from 'context/AccountContext';
import { PaymentMethodToString, PaymentStatusToColor, PaymentStatusToString } from 'components/Financial/Helpers';
import Badge from 'ui/Badge';
import { AppRoutes } from 'AppRoutes';
import { Tile } from 'ui/Layout/Tile';

export default function PaymentsListPage(): JSX.Element {
  const { selectedOrganization, generateCacheKey: getCacheId } = useOrganization();
  const [contacts, setContacts] = useState<Contact[]>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { formatDateTime, parseAndFormatMoney } = useAccount();

  const [page, setPage] = useState<number>(0);
  const [payments, setPayments] = useState<Payment[]>();
  const { setApiError } = usePage();

  // Load the payments from api
  const loadPayments = useCallback(
    (page = 1): CancelablePromise<PaginatedPaymentList> => {
      if (!selectedOrganization) {
        throw Error('Cannot fetch order list when selected organization is not set.');
      }

      const promise: CancelablePromise<PaginatedPaymentList> = PaymentsService.paymentsList({
        organisationUid: selectedOrganization.uid,
        page: page,
        pageSize: defaultApiPageSize,
        o: '-created_on,-id',
      });

      promise
        .then(res => {
          // Set the page to -1 if we have no more items to fetch.
          setPage(res.next ? page : -1);
          if (payments && page > 1) {
            setPayments([...payments].concat(res.results ?? []));
          } else {
            setPayments(res.results ?? []);
          }
          setApiError(undefined);
        })
        .catch(e => {
          if (!promise.isCancelled) {
            setApiError(new ApiErrorParser<PaginatedPaymentList>(e));
          }
        });
      return promise;
    },
    [payments, selectedOrganization, setApiError],
  );

  // Load the contacts from api and/or cache
  const loadContacts = useCallback(
    (disableCache: boolean): CancelablePromise<PaginatedContactList> => {
      const promise = ContactsService.contactsList({
        organisationUid: selectedOrganization?.uid ?? '',
      });
      promise.catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<PaginatedContactList>(e), contacts === undefined);
        }
      });

      cachedPaginatedApiData<Contact>(getCacheId('contacts'), promise, setContacts, disableCache);
      return promise;
    },
    [selectedOrganization, contacts, getCacheId, setApiError],
  );

  // Load the payments
  useEffect(() => {
    // We need to have a selected organization.
    if (selectedOrganization) {
      const promise = loadPayments();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // Load the contacts
  useEffect(() => {
    // We need to have a selected organization.
    if (selectedOrganization) {
      const promise = loadContacts(false);
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  return (
    <>
      <Page
        title={t('payments', 'Payments')}
        loading={!payments}
        showEmptyListPlaceholder={payments?.length === 0}
        maxWidth={PageMaxWidth.Tile}
      >
        <PullScrollWrapper
          onRefresh={() => Promise.all([loadPayments(), loadContacts(true)])}
          canFetchMore={page > 0 && selectedOrganization !== undefined}
          onFetchMore={() => loadPayments(page + 1)}
        >
          <Tile noBoxOnMobile={true}>
            <table className={table}>
              <thead>
                <tr className={tableHiddenHeaderSm}>
                  <td className={classNames('w-10', tableTheadTdSticky)} />
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('date', 'Date')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('payment-ref', 'Reference')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('payment-type', 'Type')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('payment-amount', 'Amount')}</td>
                  <td className='w-10 md:hidden' />
                </tr>
              </thead>
              <tbody className={tableTbody}>
                {(payments ?? []).map(payment => {
                  const navLink = payment.invoice
                    ? generatePath(AppRoutes.Invoice.path, { invoiceId: payment.invoice })
                    : payment.order
                      ? generatePath(AppRoutes.SemenOrderDetails.path, { uid: payment.order })
                      : undefined;
                  return (
                    <tr
                      className={navLink ? tableTbodyTr : tableTbodyTrNoClick}
                      key={payment.uid}
                      onClick={() => {
                        if (navLink) {
                          navigate({
                            pathname: navLink,
                            search: navBackToThisPage().toString(),
                          });
                        }
                      }}
                    >
                      <td className='text-center w-10'>
                        <Wallet size={22} weight='light' className='inline' />
                      </td>
                      <td>{payment.created_on && formatDateTime(new Date(Date.parse(payment.date ?? payment.created_on)))}</td>
                      <td className={tableHiddenColumnMd}>
                        {payment.order && (
                          <span>
                            {t('order', 'Order')} {payment.order}
                          </span>
                        )}
                        {payment.invoice && <span>{t('invoice', 'Invoice')}</span>}
                      </td>
                      <td className={tableHiddenColumnMd}>
                        {payment.type ? PaymentMethodToString(t, payment.type) : t('undefined', 'Undefined')}
                      </td>
                      <td>
                        <div className='space-x-1'>
                          <span>{parseAndFormatMoney(payment.amount, payment.amount_currency)}</span>
                          {payment.status && (
                            <Badge color={PaymentStatusToColor(payment.status)}>{PaymentStatusToString(t, payment.status)}</Badge>
                          )}
                        </div>
                      </td>
                      <td className={classNames('md:hidden')}>
                        <div className='flex items-center px-2 gap-2'>
                          <CaretRight size={22} weight='light' className='inline' />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Tile>
        </PullScrollWrapper>
      </Page>
    </>
  );
}

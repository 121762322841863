import { useCallback, useEffect, useState } from 'react';
import { useOrganization } from 'context/OrganizationContext';
import { CancelablePromise, Stable, StablesService } from 'openapi';
import ApiErrorParser from 'api/ApiErrorParser';
import { cachedApiData } from 'api/ApiCache';

interface ReturnType {
  stables: Stable[] | undefined;
  loading: boolean;
  error: ApiErrorParser<Stable> | undefined;
  refresh: () => void;
}

/**
 * Custom hooks to load the stables
 */
const useStables = (): ReturnType => {
  const [stables, setStables] = useState<Stable[]>();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<ApiErrorParser<Stable> | undefined>();

  const { selectedOrganization, generateCacheKey } = useOrganization();

  /**
   * Load the roles from api and/or cache
   */
  const loadStables = useCallback(
    (organisationUid: string): CancelablePromise<Stable[]> => {
      setLoading(true);
      const promise = StablesService.stablesList({
        organisationUid,
      });

      promise
        .catch(e => {
          // if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<Stable>(e));
          // }
        })
        .finally(() => setLoading(false));

      cachedApiData<Stable>(generateCacheKey('stables'), promise, setStables);
      return promise;
    },
    [generateCacheKey],
  );

  /**
   * Refresh the stables
   */
  const refresh = useCallback(async () => {
    if (!selectedOrganization) return;
    loadStables(selectedOrganization.uid);
  }, [loadStables, selectedOrganization]);

  /**
   * Load the Stables
   */
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadStables(selectedOrganization.uid);
      return () => promise.cancel();
    }
  }, [loadStables, selectedOrganization]);

  return {
    stables,
    loading,
    error: apiError,
    refresh,
  };
};

export default useStables;

import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { Role, RolesService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { TextInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { transformEmptyToUndefined } from 'utilities/zod';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onSaved?: (role: Role) => void;
  role?: Role;
}

export default function SaveRoleModal({ isVisible, onRequestCloseModal, onSaved, role: exisitingRole }: Props): JSX.Element {
  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();

  const schema = useMemo(() => {
    return schemas.Role.pick({
      name: true,
    }).required();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<Role>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const onClosed = () => {
    reset();
  };

  const onSubmit = async (data: Role) => {
    if (!selectedOrganization) return console.error('selectedOrganization is not defined');

    try {
      let savedRole: Role;

      if (exisitingRole) {
        const promiseUpdate = RolesService.rolesPartialUpdate({
          organisationUid: selectedOrganization.uid,
          uid: exisitingRole.uid,
          requestBody: data,
        });
        savedRole = (await promiseUpdate) as Role;
      } else {
        const promiseCreate = RolesService.rolesCreate({
          organisationUid: selectedOrganization.uid,
          requestBody: data,
        });
        savedRole = (await promiseCreate) as Role;
      }

      onRequestCloseModal();
      onSaved?.(savedRole);
    } catch (error) {
      setApiError(new ApiErrorParser<Role>(error));
    }
  };

  useEffect(() => {
    if (exisitingRole) {
      reset(exisitingRole);
    }
  }, [exisitingRole, reset]);

  return (
    <PageModal
      open={isVisible}
      width={PageModalWidth.Xs}
      parentElement='form'
      parentProps={{ id: 'saveRoleForm', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      onClosed={onClosed}
    >
      <PageModalTitle
        title={exisitingRole ? t('edit-role-title', 'Edit role') : t('new-role-title', 'New role')}
        onClose={onRequestCloseModal}
      />
      <PageModalContent>
        <ErrorSection errors={nonFieldErrors} className='mb-1' />

        <div className='flex flex-col gap-4 grow'>
          <TextInput
            required={true}
            label={t('name', 'Name')}
            {...register('name', {
              setValueAs: transformEmptyToUndefined(),
            })}
            error={fieldError('name')}
          />
        </div>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            loading: isSubmitting,
            variant: ButtonVariant.Primary,
            text: t('save', 'Save'),
            type: 'submit',
            formId: 'saveRoleForm',
          },
        ]}
      />
    </PageModal>
  );
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Organisation } from '../models/Organisation';
import type { OrganisationList } from '../models/OrganisationList';
import type { PatchedOrganisation } from '../models/PatchedOrganisation';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OrganisationService {
  /**
   * The detail view has many sub-urls, for example {organisation_uid}/horses/, {organisation_uid}/stables/
   * @returns OrganisationList
   * @throws ApiError
   */
  public static rootList({
    o,
  }: {
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
  }): CancelablePromise<Array<OrganisationList>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations',
      query: {
        'o': o,
      },
    });
  }
  /**
   * The detail view has many sub-urls, for example {organisation_uid}/horses/, {organisation_uid}/stables/
   * @returns Organisation
   * @throws ApiError
   */
  public static rootCreate({
    requestBody,
  }: {
    requestBody: Organisation,
  }): CancelablePromise<Organisation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * The detail view has many sub-urls, for example {organisation_uid}/horses/, {organisation_uid}/stables/
   * @returns Organisation
   * @throws ApiError
   */
  public static rootRetrieve({
    uid,
  }: {
    uid: string,
  }): CancelablePromise<Organisation> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{uid}',
      path: {
        'uid': uid,
      },
    });
  }
  /**
   * The detail view has many sub-urls, for example {organisation_uid}/horses/, {organisation_uid}/stables/
   * @returns Organisation
   * @throws ApiError
   */
  public static rootUpdate({
    uid,
    requestBody,
  }: {
    uid: string,
    requestBody: Organisation,
  }): CancelablePromise<Organisation> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{uid}',
      path: {
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * The detail view has many sub-urls, for example {organisation_uid}/horses/, {organisation_uid}/stables/
   * @returns Organisation
   * @throws ApiError
   */
  public static rootPartialUpdate({
    uid,
    requestBody,
  }: {
    uid: string,
    requestBody?: PatchedOrganisation,
  }): CancelablePromise<Organisation> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{uid}',
      path: {
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * The detail view has many sub-urls, for example {organisation_uid}/horses/, {organisation_uid}/stables/
   * @returns void
   * @throws ApiError
   */
  public static rootDestroy({
    uid,
  }: {
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{uid}',
      path: {
        'uid': uid,
      },
    });
  }
}

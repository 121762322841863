import React, { ReactNode } from 'react';
import { Dna, Heartbeat, Timer } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import Badge, { BadgeSize } from 'ui/Badge/Badge';
import { AllColors } from 'utilities/colors';
import { Horse, HorseDetail } from 'openapi';
import classNames from 'classnames';

interface BadgeProps {
  iconOnly?: boolean;
  size?: BadgeSize;
}

export const CareIcon: ReactNode = <Heartbeat />;
export const SportIcon: ReactNode = <Timer />;
export const BreedingIcon: ReactNode = <Dna />;

/** Badge that represents Care usage for a horse */
export function CareBadge({ iconOnly, size = BadgeSize.Small }: BadgeProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Badge
      tooltip={iconOnly ? t('activity-type-category-care', 'Care') : undefined}
      className='flex gap-0.5 items-center'
      size={size}
      color={AllColors.Purple}
    >
      <Heartbeat weight='bold' />
      {!iconOnly && <>{t('activity-type-category-care', 'Care')}</>}
    </Badge>
  );
}

/** Badge that represents Sport usage for a horse */
export function SportBadge({ iconOnly, size = BadgeSize.Small }: BadgeProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Badge
      tooltip={iconOnly ? t('activity-type-category-sport', 'Sport') : undefined}
      className='flex gap-0.5 items-center'
      size={size}
      color={AllColors.Emerald}
    >
      <Timer weight='bold' />
      {!iconOnly && <>{t('activity-type-category-sport', 'Sport')}</>}
    </Badge>
  );
}

/** Badge that represents Breeding usage for a horse */
export function BreedingBadge({ iconOnly, size = BadgeSize.Small }: BadgeProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Badge
      tooltip={iconOnly ? t('activity-type-category-breeding', 'Breeding') : undefined}
      className='flex gap-0.5 items-center'
      size={size}
      color={AllColors.Amber}
    >
      <Dna weight='bold' />
      {!iconOnly && <>{t('activity-type-category-breeding', 'Breeding')}</>}
    </Badge>
  );
}

interface Props {
  horse: Horse | HorseDetail;
  flexType?: 'row' | 'col';
  iconOnly?: boolean;
  size?: BadgeSize;
  className?: string;
}

/**
 * An element that shows the enabled usage badges for a horse.
 */
export function HorseUsageBadges({ horse, flexType, iconOnly, className, size = BadgeSize.Normal }: Props): JSX.Element {
  return (
    <div className={classNames(className, 'inline-flex gap-1', { 'flex-col': flexType === 'col' })}>
      {horse.use_in_care && <CareBadge iconOnly={iconOnly} size={size} />}
      {horse.use_in_sport && <SportBadge iconOnly={iconOnly} size={size} />}
      {horse.use_in_breeding && <BreedingBadge iconOnly={iconOnly} size={size} />}
    </div>
  );
}

import { Check, Envelope } from '@phosphor-icons/react';
import classNames from 'classnames';
import React from 'react';

export interface Props {
  message: React.ReactNode;
  type: 'success' | 'mail';
}

/**
 * A simple badge showing an icon and text in a colorful way. This can be used
 * to inform the user (outside the logged in gui) that a verification email has
 * been sent.
 * This component is designed to be used outside a logged in account.
 */
export default function Inform({ message, type }: Props): JSX.Element {
  return (
    <div
      className={classNames('flex gap-4 items-stretch mb-8 rounded-xl pr-4', {
        'bg-emerald-100': type === 'success',
        'bg-blue-50': type === 'mail',
      })}
    >
      <div
        className={classNames('p-4 rounded-l-xl flex flex-col justify-center', {
          'bg-emerald-400 text-emerald-700': type === 'success',
          'bg-blue-100 text-blue-500': type === 'mail',
        })}
      >
        {type === 'success' && <Check size={60} />}
        {type === 'mail' && <Envelope weight='light' size={60} />}
      </div>
      <p className='py-2'>{message}</p>
    </div>
  );
}

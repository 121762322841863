import { DotsThreeVertical, Plus } from '@phosphor-icons/react';
import { HorseGroup } from 'openapi';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { Tile } from 'ui/Layout/Tile';
import useModal from 'ui/Modals/UseModal';
import { table, tableHiddenHeaderMd, tableTbody, tableTbodyTrNoClick, tableThead, tableTheadTd } from 'ui/Const';
import DropdownMenu from 'ui/DropdownMenu';
import SaveGroupModal from './SaveGroupModal';
import DeleteGroupModal from './DeleteGroupModal';

interface Props {
  groups: HorseGroup[] | undefined;
  onSaved: (horseGroup: HorseGroup) => void;
  onDeleted: () => void;
}

function HorseGroupTile({ groups, onSaved, onDeleted }: Props): JSX.Element {
  const [selectedGroup, setSelectedGroup] = useState<HorseGroup>();

  const { t } = useTranslation();
  const { showModal: showSaveModal, closeModal: closeSaveModal, modalIsVisible: saveModalIsVisible } = useModal();
  const { showModal: showDeleteModal, closeModal: closeDeleteModal, modalIsVisible: deleteModalIsVisible } = useModal();

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo(() => {
    return [
      {
        onClick: showSaveModal,
        text: t('add', 'Add'),
        buttonVariant: ButtonVariant.Default,
        icon: <Plus />,
      },
    ];
  }, [showSaveModal, t]);

  return (
    <Tile title={t('groups', 'Groups')} loading={groups === undefined} actions={tileActions}>
      {groups && groups.length === 0 ? (
        <p className='text-gray-500 italic'>{t('no-horse-groups', 'There are currently no groups.')}</p>
      ) : (
        <div className='mx-3 md:mx-0'>
          <table className={table}>
            <thead className={tableThead}>
              <tr className={tableHiddenHeaderMd}>
                <td className={tableTheadTd}>{t('name', 'Name')}</td>
                <td className='w-10' />
              </tr>
            </thead>
            <tbody className={tableTbody}>
              {groups?.map(group => (
                <tr className={tableTbodyTrNoClick} key={group.uid}>
                  <td>{group.name}</td>
                  <td align='right'>
                    <DropdownMenu
                      menuPlacement='right-end'
                      menuItems={[
                        [
                          {
                            element: t('edit', 'Edit'),
                            onClick: () => {
                              setSelectedGroup(group);
                              showSaveModal();
                            },
                          },
                        ],
                        [
                          {
                            element: t('remove', 'Remove'),
                            className: 'text-red-600',
                            onClick: () => {
                              setSelectedGroup(group);
                              showDeleteModal();
                            },
                          },
                        ],
                      ]}
                    >
                      <button className='px-2'>
                        <DotsThreeVertical size={20} weight='bold' />
                      </button>
                    </DropdownMenu>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <SaveGroupModal
        isVisibile={saveModalIsVisible}
        onRequestClose={() => {
          closeSaveModal();
          setSelectedGroup(undefined);
        }}
        onSaved={onSaved}
        group={selectedGroup}
      />

      <DeleteGroupModal
        isVisible={deleteModalIsVisible}
        onRequestClose={() => {
          closeDeleteModal();
          setSelectedGroup(undefined);
        }}
        onDeleted={onDeleted}
        group={selectedGroup}
      />
    </Tile>
  );
}

export default HorseGroupTile;

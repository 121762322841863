import { zodResolver } from '@hookform/resolvers/zod';
import { Pencil } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { CancelablePromise, ColorEnum, HorseGroup, HorsegroupsService, HorsesService, ModulePermissionsEnum } from 'openapi';
import { HorseDetail } from 'openapi/models/HorseDetail';
import { PatchedHorseDetail } from 'openapi/models/PatchedHorseDetail';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import ColorWithName from 'ui/ColorWithName';
import { ErrorSection } from 'ui/Error';
import { CheckboxInput, DateInput, SelectInput, TextInput } from 'ui/Inputs';
import { InputSize, OptionItemInterface } from 'ui/Inputs/SelectInput';
import { Tile } from 'ui/Layout/Tile';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { objectDiff } from 'utilities/compareObject';
import { contactName } from 'utilities/Contact';
import { age, gender, genderList } from 'utilities/Horse';
import { colorList } from 'utilities/string.utility';
import { transformEmptyNumber, transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';
import useHorseDetail from 'hooks/UseHorseDetail';
import { z } from 'zod';
import TileDescriptionList from 'ui/Layout/Tile/TileDescriptionList';
import Badge from 'ui/Badge';
import { AllColors } from 'utilities/colors';
import { useAccount } from 'context/AccountContext';
import classNames from 'classnames';
import { PageAction } from 'context/PageContext';
import usePermissions from 'hooks/UsePermissions';
import { BreedingBadge, CareBadge, HorseUsageBadges, SportBadge } from '../HorseUsageBadges';

interface Props {
  showLocation?: boolean;
}

function GeneralHorseForm({ showLocation }: Props): JSX.Element {
  const [horseGroups, setHorseGroups] = useState<HorseGroup[]>();
  const [horseGroupsApiError, setHorseGroupsOwnerApiError] = useState<ApiErrorParser<HorseGroup> | undefined>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  const { horse, setHorse, contacts } = useHorseDetail();

  // convert the color list to a selectList
  const colors = useMemo((): OptionItemInterface[] => colorList(t).map<OptionItemInterface>(c => ({ id: c.value, name: c.label })), [t]);
  const genders = useMemo((): OptionItemInterface[] => genderList(t).map<OptionItemInterface>(c => ({ id: c.value, name: c.label })), [t]);
  const { formatDate } = useAccount();
  const { hasPermission } = usePermissions();

  // Form validation
  const schema = useMemo(() => {
    const res = schemas.HorseDetail.pick({
      name: true,
      nickname: true,
      withers_height: true,
      color: true,
      sex: true,
      date_of_birth: true,
      use_in_care: true,
      use_in_breeding: true,
      use_in_sport: true,
    }).extend({
      // We should have a nullable type for both color and sex
      // as we are sure about the value, it is a number, we could add also a nullable validator
      color: z.number().nullable(),
      sex: z.number().nullable(),
    });

    if (horse?.hidden) {
      return res.omit({
        use_in_care: true,
        use_in_breeding: true,
        use_in_sport: true,
      });
    } else {
      return res;
    }
  }, [horse]);

  // Construct the default values
  const defaultValues = useMemo((): Partial<HorseDetail> => {
    return {
      name: horse?.name,
      nickname: horse?.nickname,
      withers_height: horse?.withers_height,
      color: horse?.color,
      sex: horse?.sex,
      date_of_birth: horse?.date_of_birth,
      group_uid: horse?.group_uid,
      use_in_breeding: horse?.use_in_breeding,
      use_in_care: horse?.use_in_care,
      use_in_sport: horse?.use_in_sport,
    };
  }, [horse]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    control,
  } = useForm<HorseDetail>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const { showModal, closeModal, modalIsVisible } = useModal();

  // get the current location
  const currentLocation = contacts?.find(contact => contact.uid === horse?.current_horselocation?.location_uid);
  // get the name of the current location
  const locationName = currentLocation ? contactName(currentLocation) : t('unknown', 'Unknown');

  // build the option list for the select
  const horseGroupOptions: OptionItemInterface[] = (horseGroups ?? []).map(group => ({
    id: group.uid,
    name: group.name ?? group.uid,
  }));

  /**
   * Get the current horse group name
   */
  const currentHorseGroupName = useMemo(() => {
    return horseGroups?.find(group => group.uid === horse?.group_uid)?.name ?? t('unknown', 'Unknown');
  }, [horse?.group_uid, horseGroups, t]);

  /**
   * Close event for the modal
   */
  const onClose = (resetForm = true) => {
    closeModal();
    if (resetForm) {
      reset(defaultValues);
    }
  };

  /**
   * Function invoked after the modal has been closed
   */
  const onClosed = () => {
    // clear the errors
    clearErrors();
    setApiError(undefined);
  };

  /**
   * Submit event handler, update the data via the API for this user
   */
  const onSubmit: SubmitHandler<HorseDetail> = async (data: HorseDetail) => {
    if (!horse || !selectedOrganization) return;
    setSubmitting(true);
    try {
      // generate a diff of the given form data and the current horse object
      // only the changed values are pushed to the server
      const updateRequest = objectDiff<PatchedHorseDetail>(horse, data);
      const updateHorsePromise = HorsesService.horsesPartialUpdate({
        organisationUid: selectedOrganization.uid,
        uid: horse.uid,
        requestBody: updateRequest,
      });
      const updatedHorse = await updateHorsePromise;

      // update the horse
      setHorse(updatedHorse);

      // close the modal
      closeModal();
    } catch (error) {
      setApiError(new ApiErrorParser<HorseDetail>(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Load the horse from api
   */
  const loadHorseGroups = useCallback((selectedOrganizationUid: string): CancelablePromise<HorseGroup[]> => {
    const promise = HorsegroupsService.horsegroupsList({
      organisationUid: selectedOrganizationUid,
    });
    promise
      .then(res => setHorseGroups(res))
      .catch(error => {
        if (!promise.isCancelled) {
          setHorseGroupsOwnerApiError(new ApiErrorParser<HorseGroup>(error));
        }
      });

    return promise;
  }, []);

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo((): PageAction[] | undefined => {
    if (hasPermission(ModulePermissionsEnum.MANAGE_HORSES)) {
      return [
        {
          onClick: showModal,
          text: t('edit', 'Edit'),
          buttonVariant: ButtonVariant.Default,
          icon: <Pencil />,
        },
      ];
    }
  }, [hasPermission, showModal, t]);

  /**
   * Update the form when the horse has been updated (triggered mostly by an update of the form)
   */
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  /**
   * Load the ownership from the api
   */
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadHorseGroups(selectedOrganization.uid);
      return () => promise.cancel();
    }
  }, [loadHorseGroups, selectedOrganization]);

  return (
    <Tile title={t('overview', 'Overview')} loading={horse === undefined} actions={tileActions} apiError={horseGroupsApiError}>
      {horse && (
        <TileDescriptionList
          list={[
            {
              term: t('name', 'Name'),
              definition: (
                <>
                  {horse.nickname ? `(${horse.nickname}) ${horse.name}` : horse.name}{' '}
                  {horse.hidden && <Badge color={AllColors.Red}>{t('inactive', 'Inactive')}</Badge>}
                </>
              ),
            },
            { term: t('withers-height', 'Withers height'), definition: horse.withers_height ? `${horse.withers_height} cm` : '-' },
            { term: t('color', 'Color'), definition: horse.color ? <ColorWithName colorId={horse.color as ColorEnum} /> : '-' },
            { term: t('gender', 'Gender'), definition: horse.sex ? gender(horse.sex, t) : '-' },
            { term: t('location', 'Location'), definition: locationName, isVisible: showLocation === true },
            {
              term: t('date-of-birth', 'Date of birth'),
              definition: horse.date_of_birth ? (
                <>
                  {formatDate(new Date(horse.date_of_birth))}
                  <p className='inline-block ml-2 text-gray-400 before:content-["("] after:content-[")"]'>{age(horse)}</p>
                </>
              ) : (
                '-'
              ),
            },
            { term: t('group', 'Group'), definition: currentHorseGroupName },
            { term: t('usage', 'Usage'), definition: horse.hidden ? <p>-</p> : <HorseUsageBadges horse={horse} /> },
          ]}
        />
      )}

      <PageModal
        open={modalIsVisible}
        parentElement='form'
        width={PageModalWidth.Sm}
        parentProps={{ id: 'updateHorse', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
        onClosed={onClosed}
      >
        <PageModalTitle title={t('update-horse', 'Update Horse')} onClose={onClose} />
        <PageModalContent>
          <ErrorSection className='mb-4' errors={nonFieldErrors} />

          <div className='py-3 space-y-4'>
            <div className='flex gap-4'>
              <TextInput
                className='grow'
                error={fieldError('name')}
                required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'name')}
                label={t('name', 'Name')}
                {...register('name')}
              />
              <TextInput
                className='grow'
                error={fieldError('nickname')}
                required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'nickname')}
                label={t('nickname', 'Nickname')}
                {...register('nickname')}
              />
            </div>

            <TextInput
              postText='cm'
              error={fieldError('withers_height')}
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'withers_height')}
              label={t('withers-height', 'Withers height')}
              {...register('withers_height', { setValueAs: transformEmptyNumber(null) })}
            />

            <SelectInput
              error={fieldError('color')}
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'color')}
              options={colors}
              nullable={true}
              nullableValue=''
              label={t('color', 'Color')}
              {...register('color', { setValueAs: transformEmptyNumber(null) })}
            />

            <SelectInput
              error={fieldError('sex')}
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'sex')}
              options={genders}
              nullable={true}
              nullableValue=''
              label={t('gender', 'Gender')}
              {...register('sex', { setValueAs: transformEmptyNumber(null) })}
            />
            <DateInput
              control={control}
              error={fieldError('date_of_birth')}
              required={zodInputIsRequired<HorseDetail>(schema, 'date_of_birth')}
              label={t('date_of_birth', 'Date of birth')}
              {...register('date_of_birth', { setValueAs: transformEmptyToUndefined() })}
            />
            {!horse?.hidden && (
              <div>
                <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>{t('horse-usage', 'Usage')}</p>
                <CheckboxInput
                  size={InputSize.XSmall}
                  {...register('use_in_care')}
                  error={fieldError('use_in_care')}
                  labelElement={
                    <div className='flex items-center gap-1'>
                      {t('use-for', 'Use for')} <CareBadge />
                    </div>
                  }
                />
                <CheckboxInput
                  size={InputSize.XSmall}
                  {...register('use_in_sport')}
                  error={fieldError('use_in_sport')}
                  labelElement={
                    <div className='flex items-center gap-1'>
                      {t('use-for', 'Use for')} <SportBadge />
                    </div>
                  }
                />
                <CheckboxInput
                  size={InputSize.XSmall}
                  {...register('use_in_breeding')}
                  error={fieldError('use_in_breeding')}
                  labelElement={
                    <div className='flex items-center gap-1'>
                      {t('use-for', 'Use for')} <BreedingBadge />
                    </div>
                  }
                />
              </div>
            )}
            <SelectInput
              className={classNames({
                hidden: (horseGroups ?? []).length === 1,
              })}
              error={fieldError('group_uid')}
              nullable={true}
              nullableValue=''
              required={zodInputIsRequired<HorseDetail>(schema, 'group_uid')}
              options={horseGroupOptions}
              label={t('group', 'Group')}
              {...register('group_uid', { setValueAs: transformEmptyToUndefined() })}
            />
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              disabled: submitting,
              onClick: onClose,
              variant: ButtonVariant.Default,
              type: 'button',
              text: t('cancel', 'Cancel'),
            },
            {
              formId: 'updateHorse',
              loading: submitting,
              variant: ButtonVariant.Primary,
              type: 'submit',
              text: t('save', 'Save'),
            },
          ]}
        />
      </PageModal>
    </Tile>
  );
}

export default GeneralHorseForm;

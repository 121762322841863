import React, { useEffect, useMemo } from 'react';
import { AddressBook, Calendar, CalendarCheck, Dna, Horse, ShoppingCart, User, Wallet, Wrench } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { MenuItemConfig } from 'ui/Layout/Layout';
import usePermissions from './UsePermissions';
import { ModulePermissionsEnum } from 'openapi';
import { useAccount } from 'context/AccountContext';

interface ReturnType {
  EquinemSideBarTopMenu: MenuItemConfig[];
  EquinemSideBarBottomMenu: MenuItemConfig[];
  EquinemFooterMenu: MenuItemConfig[];
  WebshopSideBarTopMenu: MenuItemConfig[];
  WebshopFooterMenu: MenuItemConfig[];
}

const useMenu = (webshopUrlPrefix: string): ReturnType => {
  const { loadPermissions, isAdmin, hasPermission } = usePermissions();
  const { accountDetails } = useAccount();
  const { t } = useTranslation();

  /**
   * Load the permissions
   */
  useEffect(() => {
    const promise = loadPermissions();
    return () => promise?.cancel();
  }, [loadPermissions]);

  const EquinemSideBarTopMenu = useMemo((): MenuItemConfig[] => {
    const preferredStableUid = accountDetails?.preferred_stable;
    let urlSuffix = '';
    if (preferredStableUid) {
      const stableSearchParam = new URLSearchParams();
      stableSearchParam.append('stable', preferredStableUid);
      urlSuffix = `?${stableSearchParam.toString()}`;
    }

    return [
      {
        name: t('menu-activities', 'Activities'),
        path: '/planning',
        icon: <Calendar />,
        isEnabled: hasPermission([ModulePermissionsEnum.VIEW_SCHEDULE, ModulePermissionsEnum.MANAGE_SCHEDULE]),
        subMenu: [
          {
            name: t('menu-my-planning', 'My planning'),
            path: '/planning/my',
            isEnabled: hasPermission(ModulePermissionsEnum.VIEW_SCHEDULE),
          },
          {
            name: t('menu-horse-planning', 'Horse planning'),
            path: '/planning/horses' + urlSuffix,
            isEnabled: hasPermission(ModulePermissionsEnum.VIEW_SCHEDULE),
          },
          {
            name: t('menu-staff-planning', 'Staff planning'),
            path: '/planning/staff' + urlSuffix,
            isEnabled: hasPermission(ModulePermissionsEnum.VIEW_SCHEDULE),
          },

          {
            name: t('menu-stable-planning', 'Stable planning'),
            path: '/planning/stable' + urlSuffix,
            isEnabled: hasPermission(ModulePermissionsEnum.VIEW_SCHEDULE),
          },
          {
            name: t('menu-settings', 'Settings'),
            path: '/planning/settings',
            isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_SCHEDULE),
          },
        ],
      },
      {
        name: t('menu-horses', 'Horses'),
        path: '/horses',
        icon: <Horse />,
        isEnabled: hasPermission([ModulePermissionsEnum.MANAGE_HORSES, ModulePermissionsEnum.VIEW_HORSES]),
        subMenu: [
          {
            name: t('menu-horse-list', 'Horse list'),
            path: '/horses/list',
            isEnabled: hasPermission([ModulePermissionsEnum.MANAGE_HORSES, ModulePermissionsEnum.VIEW_HORSES]),
          },
          {
            name: t('menu-location-history', 'Location history'),
            path: '/horses/location-history',
            isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_HORSES),
          },
          {
            name: t('menu-settings', 'Settings'),
            path: '/horses/settings',
            isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_HORSES),
          },
        ],
      },
      // {
      //   name: t('menu-food-and-medicine', 'Food and medicine'),
      //   path: '/food-and-medicine',
      //   icon: <Carrot />,
      //   isEnabled: isAdmin,
      //   subMenu: [
      //     {
      //       name: t('menu-schedule', 'Schedule'),
      //       path: '/food-and-medicine/schedule',
      //       isEnabled: isAdmin,
      //     },
      //     {
      //       name: t('menu-inventory', 'Inventory'),
      //       path: '/food-and-medicine/inventory',
      //       isEnabled: isAdmin,
      //     },
      //     {
      //       name: t('menu-settings', 'Settings'),
      //       path: '/food-and-medicine/settings',
      //       isEnabled: isAdmin,
      //     },
      //   ],
      // },
      // {
      //   name: t('menu-availability', 'Availability'),
      //   path: '/availability',
      //   icon: <CalendarCheck />,
      //   isEnabled: isAdmin,
      //   subMenu: [
      //     {
      //       name: t('menu-overview', 'Overview'),
      //       path: '/availability/overview',
      //       isEnabled: isAdmin,
      //     },
      //     {
      //       name: t('menu-settings', 'Settings'),
      //       path: '/availability/settings',
      //       isEnabled: isAdmin,
      //     },
      //   ],
      // },
      {
        name: t('menu-breeding', 'Breeding'),
        path: '/breeding',
        icon: <Dna />,
        isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_SEMEN_JOURNAL),
        subMenu: [
          {
            name: t('menu-semen-journal', 'Semen journal'),
            path: '/breeding/stallion-mounts',
            isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_SEMEN_JOURNAL),
          },
        ],
      },
      {
        name: t('menu-shop', 'Shop'),
        path: '/shop',
        icon: <ShoppingCart />,
        isEnabled: hasPermission([ModulePermissionsEnum.PICK_ORDERS, ModulePermissionsEnum.MANAGE_ORDERS]),
        subMenu: [
          {
            name: t('menu-breeding-orders', 'Breeding orders'),
            path: '/shop/orders',
            isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_ORDERS),
          },
          {
            name: t('menu-breeding-picklist', 'Breeding picklist'),
            path: '/shop/picklist',
            isEnabled: hasPermission(ModulePermissionsEnum.PICK_ORDERS),
          },
          {
            name: t('menu-settings', 'Settings'),
            path: '/shop/settings',
            isEnabled: isAdmin,
          },
        ],
      },
      // {
      //   name: t('menu-bookings', 'Bookings'),
      //   path: '/bookings',
      //   icon: <StackSimple />,
      //   isEnabled: isAdmin,
      // },
      // {
      //   name: t('menu-manure', 'Manure'),
      //   path: '/manure',
      //   icon: <WheelBarrow />,
      //   isEnabled: isAdmin,
      // },
      // {
      //   name: t('menu-reports-and-communication', 'Reports and communication'),
      //   path: '/reports-and-communication',
      //   icon: <ChartLine />,
      //   isEnabled: isAdmin,
      //   subMenu: [
      //     {
      //       name: t('menu-overview', 'Overview'),
      //       path: '/reports-and-communication/overview',
      //       isEnabled: isAdmin,
      //     },
      //     {
      //       name: t('menu-settings', 'Settings'),
      //       path: '/reports-and-communication/settings',
      //       isEnabled: isAdmin,
      //     },
      //   ],
      // },
      {
        name: t('menu-financial-administration', 'Financial administration'),
        path: '/financial-administration',
        icon: <Wallet />,
        isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_FINANCIAL),
        subMenu: [
          {
            name: t('menu-invoices', 'Invoices'),
            path: '/financial-administration/invoices',
            isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_FINANCIAL),
          },
          // {
          //   name: t('menu-services', 'Services'),
          //   path: '/financial-administration/services',
          //   isEnabled: isAdmin,
          // },
          // {
          //   name: t('menu-purchasing', 'Purchasing'),
          //   path: '/financial-administration/purchasing',
          //   isEnabled: isAdmin,
          // },
          {
            name: t('menu-payments', 'Payments'),
            path: '/financial-administration/payments',
            isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_FINANCIAL),
          },
          {
            name: t('menu-vat', 'VAT percentages'),
            path: '/financial-administration/vat',
            isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_FINANCIAL),
          },
          {
            name: t('settings', 'Settings'),
            path: '/financial-administration/settings',
            isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_FINANCIAL),
          },
        ],
      },
    ];
  }, [hasPermission, isAdmin, t, accountDetails]);

  const EquinemSideBarBottomMenu = useMemo((): MenuItemConfig[] => {
    return [
      {
        name: t('contacts', 'Contacts'),
        path: '/contacts/list',
        icon: <AddressBook />,
        isEnabled: hasPermission([ModulePermissionsEnum.MANAGE_CONTACTS, ModulePermissionsEnum.VIEW_CONTACTS]),
      },
      {
        name: t('menu-organization-admin', 'Organization admin'),
        path: '/admin',
        icon: <Wrench />,
        isEnabled: isAdmin,
        subMenu: [
          {
            name: t('menu-user', 'Users'),
            path: '/admin/users',
            isEnabled: isAdmin,
          },
          {
            name: t('menu-integrations', 'Integrations'),
            path: '/admin/integrations',
            isEnabled: isAdmin,
          },
          {
            name: t('manage-locations', 'Manage locations'),
            path: '/admin/locations',
            isEnabled: isAdmin,
          },
          {
            name: t('menu-permissions', 'Permissions'),
            path: '/admin/permissions',
            isEnabled: isAdmin,
          },
          {
            name: t('settings', 'Settings'),
            path: '/admin/settings',
            isEnabled: isAdmin,
          },
        ],
      },
    ];
  }, [hasPermission, isAdmin, t]);

  // Menu items for mobile navigation in the Equinem app
  const EquinemFooterMenu = useMemo((): MenuItemConfig[] => {
    return [
      {
        name: t('horses', 'Horses'),
        path: '/horses/list',
        icon: <Horse />,
        isEnabled: hasPermission([ModulePermissionsEnum.MANAGE_HORSES, ModulePermissionsEnum.VIEW_HORSES]),
      },
      {
        name: t('menu-my-planning', 'My planning'),
        path: '/planning/my',
        icon: <Calendar />,
        isEnabled: hasPermission(ModulePermissionsEnum.VIEW_SCHEDULE),
      },
      {
        name: t('contacts', 'Contacts'),
        path: '/contacts/list',
        icon: <AddressBook />,
        isEnabled: hasPermission([ModulePermissionsEnum.MANAGE_CONTACTS, ModulePermissionsEnum.VIEW_CONTACTS]),
      },
      {
        name: t('availability', 'Availability'),
        path: '/availability/overview',
        icon: <CalendarCheck />,
        isEnabled: true,
      },
    ];
  }, [hasPermission, t]);

  const WebshopSideBarTopMenu = useMemo((): MenuItemConfig[] => {
    return [
      {
        name: t('menu-mares', 'Mares'),
        path: webshopUrlPrefix + 'mares',
        icon: <Horse />,
        isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_ORDERS),
      },
      {
        name: t('menu-orders', 'Orders'),
        path: webshopUrlPrefix + 'orders',
        icon: <ShoppingCart />,
        isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_ORDERS),
      },
    ];
  }, [hasPermission, t, webshopUrlPrefix]);

  // Menu items for mobile navigation in the webshop
  const WebshopFooterMenu = useMemo((): MenuItemConfig[] => {
    return [
      {
        name: t('menu-mares', 'Mares'),
        path: webshopUrlPrefix + 'mares',
        icon: <Horse />,
        isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_ORDERS),
      },
      {
        name: t('menu-orders', 'Orders'),
        path: webshopUrlPrefix + 'orders',
        icon: <ShoppingCart />,
        isEnabled: hasPermission(ModulePermissionsEnum.MANAGE_ORDERS),
      },
      {
        name: t('account', 'Account'),
        path: webshopUrlPrefix + 'profile',
        icon: <User />,
        isEnabled: true,
      },
    ];
  }, [hasPermission, t, webshopUrlPrefix]);

  return {
    EquinemSideBarTopMenu,
    EquinemSideBarBottomMenu,
    EquinemFooterMenu,
    WebshopSideBarTopMenu,
    WebshopFooterMenu,
  };
};

export default useMenu;

import { Combobox, Transition } from '@headlessui/react';
import { Barn, House, X } from '@phosphor-icons/react';
import classNames from 'classnames';
import { Contact } from 'openapi';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AvatarIcon, AvatarInitials, AvatarSize } from '../../ui/Avatar';
import { contactInitials, contactName, ContactType, getContactType, textFilter } from '../../utilities/Contact';
import { useSearchParams } from 'react-router-dom';

interface Props {
  contacts: Contact[];
  onContactSearch: (contact: Contact) => void;
  onOtherSearch: (search: string) => void;
  onClear: () => void;
  placeholderText: string;
}

// A component that is shown on top of a page for searching contacts and other strings.
export default function SearchContactOrOther({ onContactSearch, onOtherSearch, onClear, contacts, placeholderText }: Props): JSX.Element {
  const { t } = useTranslation();
  const [findContactQuery, setFindContactQuery] = useState<string>('');
  const [focused, setFocused] = useState(false);
  // flag that indicates if we have consumed the query params
  // if consumed, we don't need to check the query params again
  const [consumedQueryParams, setConsumedQueryParams] = useState(false);
  // the current value holds the parsed value of the findContactQuery variable
  // this is either a string or a contact and set as the value of the combobox
  const [currentValue, setCurrentValue] = useState<string | Contact>('');

  const inputElementRef = useRef<HTMLInputElement>(null);
  const [searchParams] = useSearchParams();

  // Return a contact name in a human readable way. If the contact doesn't have
  // a name, then we return 'Nameless contact' as text.
  const nameContact = useCallback(
    (contact: Contact): string => {
      return contactName(contact) ?? `<${t('nameless-contact', 'Nameless contact')}>`;
    },
    [t],
  );

  // Return a list of contacts filtered by the findContactQuery text.
  const filteredContacts = useMemo((): Contact[] => {
    if (findContactQuery.length === 0) {
      // We don't display any results on an empty search query.
      return [];
    }
    return textFilter(contacts ?? [], findContactQuery);
  }, [contacts, findContactQuery]);

  /**
   * Detect if we have a search_contact_uid or search in the URL and set the current value
   * based on that.
   */
  useEffect(() => {
    // we only want to consume the query params once
    if (!consumedQueryParams) {
      const contactUid = searchParams.get('search_contact_uid');
      const searchValue = searchParams.get('search');

      // in case we deal with a contactUid, we need to find the contact in the list of contacts
      const contact = contactUid && contacts.find(contact => contact.uid === contactUid);

      if (contact) {
        setCurrentValue(contact);
        onContactSearch(contact);
      } else if (searchValue) {
        setCurrentValue(searchValue);
        onOtherSearch(searchValue);
      }

      // mark as consumed
      setConsumedQueryParams(true);
    }
  }, [contacts, searchParams]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Combobox
      value={currentValue}
      onChange={searchFor => {
        if (!searchFor) {
          onClear();
        } else if (typeof searchFor === 'string' || searchFor instanceof String) {
          onOtherSearch(searchFor as string);
          setCurrentValue(searchFor as string);
        } else {
          onContactSearch(searchFor as Contact);
          setCurrentValue(searchFor as Contact);
        }
      }}
    >
      <div className='relative grow max-w-md'>
        <div
          className={classNames('flex relative w-full cursor-default overflow-hidden rounded-lg border bg-white focus:outline-none', {
            'border-primary': focused,
          })}
        >
          <Combobox.Input
            ref={inputElementRef}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            placeholder={placeholderText}
            className='placeholder:italic placeholder:text-sm h-10 w-full border-none p-2 text-sm leading-5 text-gray-900 focus:outline-none focus:ring-0'
            displayValue={(searchFor: unknown) => {
              if (typeof searchFor === 'string' || searchFor instanceof String) {
                return searchFor as string;
              } else {
                return nameContact(searchFor as Contact);
              }
            }}
            onChange={event => {
              setFindContactQuery(event.target.value);
              if (!event.target.value) {
                onClear();
              }
            }}
          />
          {(findContactQuery !== '' || inputElementRef.current?.value !== '') && (
            <button
              className='text-gray-500 px-2 focus:outline-none focus:bg-gray-50'
              onClick={() => {
                if (inputElementRef.current) {
                  inputElementRef.current.value = '';
                  setFindContactQuery('');
                  onClear();
                }
              }}
            >
              <X size={25} />
            </button>
          )}
        </div>
        <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
          <Combobox.Options className='z-30 absolute mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            {filteredContacts.length === 0 && findContactQuery !== '' ? (
              <Combobox.Option
                className={({ active }) =>
                  classNames('relative cursor-default select-none py-2 px-4', {
                    'bg-blue-600 text-white': active,
                    'text-gray-900': !active,
                  })
                }
                value={findContactQuery}
              >
                <span className='text-sm italic mr-1 block'>
                  {t('search', 'Search')}
                  {':'}
                </span>
                <span>{findContactQuery}</span>
              </Combobox.Option>
            ) : (
              filteredContacts.map(contact => (
                <Combobox.Option
                  key={contact.uid}
                  className={({ active }) =>
                    classNames('relative cursor-default select-none py-2 px-3', {
                      'bg-blue-600 text-white': active,
                      'text-gray-900': !active,
                    })
                  }
                  value={contact}
                >
                  {({ selected }) => (
                    <div className='flex gap-2 items-center'>
                      {[ContactType.Business].includes(getContactType(contact)) && (
                        <AvatarIcon size={AvatarSize.XSmall} icon={<House />} uuid={contact.uid} />
                      )}
                      {[ContactType.Stable].includes(getContactType(contact)) && (
                        <AvatarIcon size={AvatarSize.XSmall} icon={<Barn />} uuid={contact.uid} />
                      )}
                      {[ContactType.User, ContactType.Contact].includes(getContactType(contact)) && (
                        <AvatarInitials size={AvatarSize.XSmall} initials={contactInitials(contact)} uuid={contact.uid} />
                      )}
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{nameContact(contact)}</span>
                    </div>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}

import 'rc-slider/assets/index.css';
import { Minus, Plus } from '@phosphor-icons/react';
import classNames from 'classnames';
import Slider from 'rc-slider';
import React, { useState } from 'react';
import Cropper, { Area, Point } from 'react-easy-crop';

interface Props {
  imageUrl: string;
  className?: string;
  onCropComplete: (croppedAreaPixels: Area) => void;
}

function ImageCrop({ className, imageUrl, onCropComplete: eventOnCropComplete }: Props): JSX.Element {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);

  const onCropChange = (crop: Point) => {
    setCrop(crop);
  };

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    eventOnCropComplete(croppedAreaPixels);
  };

  const onZoomChange = (zoom: number) => {
    const zoomFixed = Number(Number(zoom).toFixed(1));
    if (zoomFixed >= 1 && zoomFixed <= 3) {
      setZoom(zoomFixed);
    }
  };

  return (
    <>
      <div className={classNames('relative', className)}>
        <Cropper
          image={imageUrl}
          crop={crop}
          zoom={zoom}
          aspect={1 / 1}
          cropShape='round'
          showGrid={true}
          objectFit='contain'
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={onZoomChange}
        />
      </div>
      <div className='flex gap-x-4 items-center'>
        <button disabled={zoom < 1} onClick={() => onZoomChange(zoom - 0.1)}>
          <Minus />
        </button>
        <Slider value={zoom} min={1} max={3} step={0.1} onChange={zoom => onZoomChange(Number(zoom))} />
        <button disabled={zoom > 3} onClick={() => onZoomChange(zoom + 0.1)}>
          <Plus />
        </button>
      </div>
    </>
  );
}

export default ImageCrop;

import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PageModal } from 'ui/Modals';
import { PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import LocatieUbn from 'assets/rvo/locatie-ubn.png';
import Machtiging from 'assets/rvo/machtiging.png';
import Inloggen from 'assets/rvo/inloggen.png';
import Verstrekken from 'assets/rvo/verstrekken.png';
import Vastleggen from 'assets/rvo/vastleggen.png';
import Terugkoppeling from 'assets/rvo/terugkoppeling.png';
import classNames from 'classnames';

interface Step {
  title: string;
  description: ReactNode;
  image: string;
}

interface StepProps {
  direction?: 'left' | 'right';
  isVisible: boolean;
  step: Step | undefined;
}

/**
 * Render a single step
 */
function Step({ isVisible: givenIsVisible, step, direction }: StepProps): JSX.Element {
  const [isVisible, setIsVisible] = useState<boolean>(givenIsVisible);

  /**
   * For the item that was active, we delay the hiding of the item so the animation can fullfill
   */
  useEffect(() => {
    if (isVisible) {
      setTimeout(() => setIsVisible(givenIsVisible), 250);
    } else {
      setIsVisible(givenIsVisible);
    }
  }, [givenIsVisible, isVisible]);

  if (!step) return <></>;

  return (
    <div
      className={classNames('absolute left-0 animate-faster w-full pb-10', {
        'animate-fadeOutLeft': !givenIsVisible && direction === 'left',
        'animate-fadeInRight': givenIsVisible && direction === 'left',
        'animate-fadeOutRight': !givenIsVisible && direction === 'right',
        'animate-fadeInLeft': givenIsVisible && direction === 'right',
        hidden: !isVisible,
      })}
    >
      <div className='space-y-5'>
        <h2 className='text-lg font-medium'>{step.title}</h2>
        <p>{step.description}</p>
        <div className='flex justify-center w-full'>
          <img className='max-h-[350px] max-w-[85%]' src={step.image} alt='Locatie UBN' />
        </div>
      </div>
    </div>
  );
}

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onSuccess?: () => void;
}

function RvoAuthorizationGuideModal({ isVisible, onRequestCloseModal }: Props): JSX.Element {
  const { t } = useTranslation();
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [prevStepIndex, setPrevStepIndex] = useState<number>(0);

  const steps = useMemo((): Step[] => {
    return [
      {
        title: t('step-1', 'Step 1'),
        description: (
          <Trans
            i18nKey='rvo-guide-step-1'
            defaults='Go to <0>mijn.rvo.nl</0>. Click on <strong>Dierlocatie UBN registreren en wijzigen</strong>.'
            components={[
              <a key='link' href='https://mijn.rvo.nl' target='_blank' rel='noreferrer noopener' className='text-blue-500 underline'>
                mijn.rvo.nl
              </a>,
            ]}
          />
        ),
        image: LocatieUbn,
      },
      {
        title: t('step-2', 'Step 2'),
        description: (
          <Trans
            i18nKey='rvo-guide-step-2'
            defaults='Go to <strong>direct regelen</strong> and then <strong>Machtiging Registreren</strong>'
          />
        ),
        image: Machtiging,
      },
      {
        title: t('step-3', 'Step 3'),
        description: t('rvo-guide-step-3', 'Log in and use the same login method as when requesting the UBN'),
        image: Inloggen,
      },
      {
        title: t('step-4', 'Step 4'),
        description: (
          <Trans
            i18nKey='rvo-guide-step-4'
            defaults="In the next screen, copy the following data: Relationship number <strong>208554948</strong>, postal code <strong>7531HS</strong> and house number <strong>42</strong>. As the start date, enter today's date."
          />
        ),
        image: Verstrekken,
      },
      {
        title: t('step-5', 'Step 5'),
        description: (
          <Trans
            i18nKey='rvo-guide-step-5'
            defaults='in the next screen you will see the filled in data again. Press <strong>vastleggen</strong>.'
          />
        ),
        image: Vastleggen,
      },
      {
        title: t('step-6', 'Step 6'),
        description: t('rvo-guide-step-6', 'You will now receive feedback that the authorization has been registered.'),
        image: Terugkoppeling,
      },
    ];
  }, [t]);

  /**
   * Go to the next step
   */
  const goNext = () => {
    const newState = currentStepIndex < steps.length - 1 ? currentStepIndex + 1 : currentStepIndex;
    setPrevStepIndex(currentStepIndex);
    setCurrentStepIndex(newState);
  };

  /**
   * Go to the prev step
   */
  const goPrev = () => {
    const newState = currentStepIndex > 0 ? currentStepIndex - 1 : currentStepIndex;
    setPrevStepIndex(currentStepIndex);
    setCurrentStepIndex(newState);
  };

  // flag that indicate if we can go to the next/prev step
  const canGoNext = currentStepIndex < steps.length - 1;
  const canGoPrev = currentStepIndex > 0;

  /**
   * Event that will be fired after the modal has been closed
   */
  const onClosed = () => {
    setCurrentStepIndex(0);
  };

  return (
    <PageModal open={isVisible} width={PageModalWidth.Base} onClosed={onClosed}>
      {/* check of we de adres velden en ubn hebben gezet */}
      <PageModalTitle title={t('rvo-authorization-guide', 'RVO authorization guide')} onClose={onRequestCloseModal} />
      <PageModalContent>
        <div className='h-[500px] w-full relative'>
          {canGoNext && (
            <button className='absolute right-0 top-[50%] -translate-y-[50%] z-50' onClick={goNext}>
              <CaretRight size={30} />
            </button>
          )}
          {canGoPrev && (
            <button className='absolute left-0 top-[50%] -translate-y-[50%] z-50' onClick={goPrev}>
              <CaretLeft size={30} />
            </button>
          )}
          {steps.map((step, index) => {
            return (
              <Step
                isVisible={index === currentStepIndex}
                direction={prevStepIndex <= currentStepIndex ? 'left' : 'right'}
                key={step.title}
                step={step}
              />
            );
          })}
        </div>
      </PageModalContent>
    </PageModal>
  );
}

export default RvoAuthorizationGuideModal;

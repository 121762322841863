import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { CountryEnum, OrganisationService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import MultiSelectInput from 'ui/Inputs/MultiSelectInput';
import { z } from 'zod';
import { ButtonVariant } from 'ui/Button';
import UseCountries from 'hooks/UseCountries';

interface Props {
  visible: boolean;
  onRequestCloseModal: () => void;
  onSaved?: () => void;
}

const schema = schemas.Organisation.pick({ down_payment_permitted_countries: true });
type SchemaType = z.infer<typeof schema>;

export default function SaveTrustedCountriesModal({ visible, onRequestCloseModal, onSaved }: Props): JSX.Element {
  const { selectedOrganizationDetails } = useOrganization();
  const { t } = useTranslation();
  const { countries } = UseCountries();

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      down_payment_permitted_countries: selectedOrganizationDetails?.down_payment_permitted_countries ?? [],
    },
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Close modal action
   */
  const close = () => {
    onRequestCloseModal();
    setApiError(undefined);
  };

  /**
   * Submit handler
   */
  const onSubmit = async (data: SchemaType) => {
    if (!selectedOrganizationDetails) return console.error('selectedOrganization is not defined');
    try {
      await OrganisationService.rootPartialUpdate({
        uid: selectedOrganizationDetails.uid,
        requestBody: {
          down_payment_permitted_countries: data.down_payment_permitted_countries as CountryEnum[],
        },
      });
      close();
      onSaved?.();
    } catch (error) {
      setApiError(new ApiErrorParser<SchemaType>(error));
    }
  };

  /**
   * event that will be fired after the modal has been closed
   */
  const resetForm = () => {
    setApiError(undefined);
    reset({
      down_payment_permitted_countries: selectedOrganizationDetails?.down_payment_permitted_countries ?? [],
    });
  };

  /**
   * Reset the form when a new 'existing product' is set.
   */
  useEffect(() => {
    reset({
      down_payment_permitted_countries: selectedOrganizationDetails?.down_payment_permitted_countries ?? [],
    });
  }, [selectedOrganizationDetails, reset]);

  return (
    <>
      <PageModal open={visible} onClosed={resetForm}>
        <PageModalTitle title={t('trusted-countries', 'Trusted countries')} onClose={() => close()} />
        <PageModalContent>
          <ErrorSection errors={nonFieldErrors} />
          <form noValidate={true} id='SaveTrustedCountriesForm' onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-4 grow'>
              <MultiSelectInput<SchemaType>
                name='down_payment_permitted_countries'
                required={true}
                control={control}
                error={fieldError('down_payment_permitted_countries')}
                label={t('trusted-countries-label', 'Trusted countries for down payment')}
                hint={t(
                  'trusted-countries-hint',
                  'Select countries are trusted for down payments. Countries that are not selected need to pay the full price upfront.',
                )}
                options={countries}
                searchPlaceholder={t('search-country-name', 'Search country name...')}
                showToolbar={true}
              />
            </div>
          </form>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              loading: isSubmitting,
              variant: ButtonVariant.Primary,
              text: t('save', 'Save'),
              type: 'submit',
              formId: 'SaveTrustedCountriesForm',
            },
          ]}
        />
      </PageModal>
    </>
  );
}

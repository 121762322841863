import { House } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { Contact, HorsesService, GetHorsesFromRvo } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AvatarIcon, AvatarSize } from 'ui/Avatar';
import Button, { ButtonVariant } from 'ui/Button';
import { tableTbodyTrNoClick } from 'ui/Const';
import useModal from 'ui/Modals/UseModal';
import { contactName } from 'utilities/Contact';
import AskStableUidModal from './AskStableUidModal';

interface Props {
  location: Contact;
  authorizedStables: Contact[];
  onError: (error: ApiErrorParser<GetHorsesFromRvo>) => void;
}

function Row({ location, onError, authorizedStables }: Props): JSX.Element {
  const [syncing, setSyncing] = useState<boolean>(false);

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  const { closeModal, modalIsVisible, showModal } = useModal();

  /**
   * Sync the horses from the RVO to EquineM
   * If no stable UID is given, we should ask the user for it
   */
  const sync = async (stableUid: string | null) => {
    if (stableUid) {
      setSyncing(true);

      const promise = HorsesService.horsesGetHorsesFromRvoCreate({
        organisationUid: selectedOrganization?.uid ?? '',
        requestBody: {
          // only send the location UID if it is not the stable location
          location_uid: location?.stable_location_uid === null ? location?.uid : undefined,
          create_new: true,
        },
      });
      try {
        await promise;
      } catch (e) {
        onError(new ApiErrorParser(e));
      } finally {
        setSyncing(false);
      }
    } else {
      // when nog stable UID is given, we should ask the user for it
      showModal();
    }
  };

  return (
    <tr className={tableTbodyTrNoClick} key={location.uid}>
      <td className='text-center w-12 md:w-14'>
        <AvatarIcon size={AvatarSize.Small} icon={<House />} uuid={location.uid} />
      </td>
      <td>
        <div className='flex gap-x-2 items-center'>{location.business_name || contactName(location)}</div>
      </td>
      <td>
        <Button loading={syncing} onClick={() => sync(location.stable_location_uid)} variant={ButtonVariant.Primary}>
          {t('import', 'Import')}
        </Button>

        <AskStableUidModal
          isVisible={modalIsVisible}
          onRequestCloseModal={closeModal}
          authorizedStables={authorizedStables}
          onSubmit={stableUid => {
            closeModal();
            sync(stableUid);
          }}
        />
      </td>
    </tr>
  );
}

export default Row;

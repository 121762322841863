import { useCallback, useState } from 'react';

export default function useModal(): { modalIsVisible: boolean; toggleModal: () => void; showModal: () => void; closeModal: () => void } {
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const toggleModal = useCallback(() => {
    setModalIsVisible(prevState => !prevState);
  }, []);

  const showModal = useCallback(() => {
    setModalIsVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalIsVisible(false);
  }, []);

  return {
    modalIsVisible,
    toggleModal,
    showModal,
    closeModal,
  };
}

import { XCircle } from '@phosphor-icons/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../ui/Layout';

export default function NotFound(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Page title={t('not-found', 'Not found')}>
      <div className={'flex flex-col gap-2 items-center pt-20 text-sm text-neutral-500 select-none'}>
        <XCircle size={60} />
        404 {'-'} {t('page-not-found', 'Page not found')}
      </div>
    </Page>
  );
}

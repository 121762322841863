import { zodResolver } from '@hookform/resolvers/zod';
import { ArrowRight } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { AppRoutes, generateWebshopPath, WebshopRoutes } from 'AppRoutes';
import SplashWrapper, { WrapperStyle } from 'components/Common/Splash/Wrapper';
import { AccountService, EquineMRegister, CountryEnum, TypeOfOrganisationEnum } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import Button, { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { CheckboxInput, SelectInput, TextInput } from 'ui/Inputs';
import RadioButtonGroup, { RadioButtonGroupOption } from 'ui/Inputs/RadioGroupInput';
import Inform from 'ui/Layout/Inform';
import { transformEmptyNumber, transformEmptyToUndefined, transformValue } from 'utilities/zod';
import { z } from 'zod';
import { LoginType } from './Login';
import Stepper from 'ui/Stepper';
import useRedirectToHomeWhenLoggedIn from 'hooks/UseRedirectToHomeWhenLoggedIn';
import UseCountries from 'hooks/UseCountries';

interface Props {
  loginType: LoginType;
}

export default function Signup({ loginType }: Props): JSX.Element {
  const { t } = useTranslation();
  const [success, setSuccess] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showCoupons, setShowCoupons] = useState(false);
  const [step, setStep] = useState<number>(1);
  const [stepsWithError, setStepsWithError] = useState<number[]>([]);
  const [schema, setSchema] = useState<z.ZodType>(z.object({}));
  const { publicAccessUuid } = useParams();
  const { countries } = UseCountries();

  // Redirect to home if the user is already logged in.
  useRedirectToHomeWhenLoggedIn(loginType);

  const hasNextStep = useMemo(() => {
    return step < 3;
  }, [step]);

  const hasPreviousStep = useMemo(() => {
    return step > 1;
  }, [step]);

  const atLastStep = useMemo(() => {
    return step === 3;
  }, [step]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    getFieldState,
    watch,
  } = useForm<EquineMRegister>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });
  const organizationType = watch('type_of_organisation');
  const selectedCountry = watch('country');

  // Get an altered version of the EquineMRegister schema based upon
  const getSchemaForStep = useCallback(
    (step: number) => {
      switch (step) {
        case 1: // First step of the form wizard shows personal info
        default:
          if (loginType === LoginType.Equinem) {
            return schemas.EquineMRegister.pick({
              first_name: true,
              last_name: true,
              email: true,
              type_of_organisation: true,
              country: true,
            }).merge(z.object({ type_of_organisation: z.enum([String(TypeOfOrganisationEnum._1), String(TypeOfOrganisationEnum._2)]) }));
          } else {
            return schemas.EquineMRegister.pick({
              first_name: true,
              last_name: true,
              email: true,
              country: true,
            });
          }
        case 2: {
          // Second step shows company and address info
          const result = schemas.EquineMRegister.pick({
            business_name:
              String(organizationType) === String(TypeOfOrganisationEnum._2) && loginType !== LoginType.Webshop ? true : undefined,
            phone_number: true,
            UBN:
              String(organizationType) === String(TypeOfOrganisationEnum._2) && selectedCountry === 'NL' && loginType !== LoginType.Webshop
                ? true
                : undefined,
            postcode: true,
            address_line1: true,
            city: true,
            address_line3: true,
          });

          if (loginType === LoginType.Webshop) {
            return result.required({
              postcode: true,
              address_line1: true,
              city: true,
              address_line3: true,
            });
          } else if (String(organizationType) === String(TypeOfOrganisationEnum._2)) {
            return result.required({ business_name: String(organizationType) === String(TypeOfOrganisationEnum._2) ? true : undefined });
          } else {
            return result;
          }
        }
        case 3: // Third step is password, discount coupon and therms.
          return schemas.EquineMRegister.pick({
            password1: true,
            password2: true,
            coupon_code: loginType === LoginType.Equinem ? true : undefined,
            agreed_to_terms_and_privacy: true,
          })
            .refine(data => data.password1 === data.password2, {
              message: t('password-mismatch', "Passwords don't match"),
              path: ['password2'],
            })
            .refine(data => data.agreed_to_terms_and_privacy === true, {
              message: t('agree-terms-error', 'You must agree to the terms'),
              path: ['agreed_to_terms_and_privacy'],
            });
      }
    },
    [t, organizationType, selectedCountry, loginType],
  );

  const { fieldError, nonFieldErrors, setApiError, hasApiError } = useFormError(schema, errors);

  // Check if a signup step has field errors.
  const stepErrors = useCallback(
    async (step: number, includeApiErrors = true): Promise<boolean> => {
      const fields: (keyof EquineMRegister)[][] = [];
      fields[1] = ['first_name', 'last_name', 'email', 'type_of_organisation', 'country'];
      fields[2] = ['phone_number', 'UBN', 'business_name', 'postcode', 'address_line1', 'city', 'address_line3'];
      fields[3] = ['password1', 'password2', 'coupon_code', 'agreed_to_terms_and_privacy'];
      for await (const field of fields[step]) {
        await trigger(field);
        if ((includeApiErrors && fieldError(field)) || getFieldState(field).invalid) {
          return true;
        }
      }
      return false;
    },
    [fieldError, trigger, getFieldState],
  );

  // Go to the next step
  const nextStep = useCallback(async () => {
    if (!(await stepErrors(step, false))) {
      setStep(step + 1);
    }
  }, [step, stepErrors]);

  // Go to the previous step
  const previousStep = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const onSubmit = async (data: EquineMRegister) => {
    if (publicAccessUuid) {
      // If it's a webshop registration, the let the api know for which webshop we signed up for.
      data.signup_supplier_shop = publicAccessUuid;
    }
    const promise = AccountService.apiV5SignupCreate({
      requestBody: data,
    });
    try {
      setSubmitting(true);
      await promise;
      setSuccess(true);
    } catch (e) {
      setApiError(new ApiErrorParser<EquineMRegister>(e));
    } finally {
      setSubmitting(false);
    }
  };

  // Set the schema based on the current step.
  useEffect(() => {
    setSchema(getSchemaForStep(step));
  }, [step, getSchemaForStep, organizationType]);

  // Update the category errors when we've gotten a validation error.
  useEffect(() => {
    const validateSteps = async (): Promise<number[]> => {
      const stepsErr: number[] = [];
      if (await stepErrors(1)) stepsErr.push(1);
      if (await stepErrors(2)) stepsErr.push(2);
      if (await stepErrors(3)) stepsErr.push(3);
      return stepsErr;
    };
    // We only set the steps with errors when we've gotten an api error. Otherwise
    // the zod form validation does it's job.
    if (hasApiError) {
      validateSteps().then(res => {
        setStepsWithError(res);
        if (res.length > 0) {
          // Go to the first step/group with an error.
          setStep(res[0]);
        }
      });
    }
  }, [hasApiError, setStepsWithError]); //eslint-disable-line

  const typeOfOrganizationOptions = useMemo((): RadioButtonGroupOption[] => {
    // See OpenApi generated type 'TypeOfOrganisationEnum' for more info.
    return [
      { id: String(TypeOfOrganisationEnum._1), name: t('signup-private', 'Private') },
      { id: String(TypeOfOrganisationEnum._2), name: t('signup-professional', 'Professional') },
    ];
  }, [t]);

  return (
    <SplashWrapper
      styleType={loginType === LoginType.Equinem ? WrapperStyle.Equinem : WrapperStyle.Anonymous}
      title={success ? t('signup-success-title', 'Thanks for signing up') : t('signup-title', 'Create your account')}
    >
      {success && (
        <div className='mt-8'>
          <p className='mb-4'>
            {success ? (
              <>
                {loginType === LoginType.Equinem
                  ? t('signup-success-subtitle', 'Your digital stable has been created.')
                  : t('signup-success-subtitle-webshop', 'Your signup was successful.')}
              </>
            ) : (
              <>
                {t('signup.subTitle2', 'Already having an account? Then please')}{' '}
                <Link
                  to={
                    loginType === LoginType.Webshop
                      ? generateWebshopPath(WebshopRoutes.Login.path, publicAccessUuid ?? '')
                      : AppRoutes.Login.path
                  }
                  className={'font-semibold text-blue-500 hover:text-primary'}
                >
                  {t('signup.login', 'Login')}
                </Link>
              </>
            )}
          </p>
          <Inform
            type='mail'
            message={t(
              'signup-success',
              'We have sent you an e-mail to verify the e-mail address belongs to you. Please follow the instructions in the e-mail and then login.',
            )}
          />
          <div className='mt-8 flex flex-col items-center'>
            <Link
              className={'text-blue-500 font-medium flex gap-1 items-center'}
              to={
                loginType === LoginType.Webshop
                  ? generateWebshopPath(WebshopRoutes.Login.path, publicAccessUuid ?? '')
                  : AppRoutes.Login.path
              }
              replace={true}
            >
              {t('login', 'Login')} <ArrowRight />
            </Link>
          </div>
        </div>
      )}
      {!success && (
        <>
          <form noValidate={true} id='signup-form' className='flex flex-col' onSubmit={handleSubmit(onSubmit)}>
            <Stepper stepsWithErrors={stepsWithError} totalSteps={3} step={1} expanded={step === 1}>
              <TextInput
                required={true}
                label={t('signup-first-name', 'First name')}
                {...register('first_name', { setValueAs: transformEmptyToUndefined() })}
                error={fieldError('first_name')}
              />
              <TextInput
                required={true}
                label={t('signup-last-name', 'Last name')}
                {...register('last_name', { setValueAs: transformEmptyToUndefined() })}
                error={fieldError('last_name')}
              />
              <TextInput
                required={true}
                label={t('signup-email', 'E-mail address')}
                {...register('email', { setValueAs: transformEmptyToUndefined() })}
                error={fieldError('email')}
              />
              {loginType === LoginType.Equinem && (
                <RadioButtonGroup<EquineMRegister>
                  name='type_of_organisation'
                  required={true}
                  control={control}
                  options={typeOfOrganizationOptions}
                  error={fieldError('type_of_organisation')}
                  label={t('signup-usage-type', 'Usage')}
                />
              )}
              <SelectInput
                label={t('country', 'Country')}
                nullable={true}
                required={true}
                nullableValue=''
                options={countries}
                error={fieldError('country')}
                {...register('country', { setValueAs: transformEmptyToUndefined() })}
              />
            </Stepper>
            <Stepper stepsWithErrors={stepsWithError} totalSteps={3} step={2} expanded={step === 2}>
              {String(organizationType) === String(TypeOfOrganisationEnum._2) && ( // Professional
                <>
                  <TextInput
                    required={true}
                    label={t('signup-business-name', 'Business name')}
                    {...register('business_name', { setValueAs: transformEmptyToUndefined() })}
                    error={fieldError('business_name')}
                  />
                  {selectedCountry === CountryEnum.NL && (
                    <TextInput
                      label={t('signup-ubn', 'UBN')}
                      {...register('UBN', { setValueAs: transformEmptyToUndefined() })}
                      error={fieldError('UBN')}
                    />
                  )}
                </>
              )}
              <TextInput
                required={true}
                label={t('signup-phone-number', 'Phone number')}
                {...register('phone_number', { setValueAs: transformEmptyToUndefined() })}
                error={fieldError('phone_number')}
              />
              <TextInput
                required={loginType === LoginType.Webshop}
                label={t('signup-street-name', 'Street name')}
                {...register('address_line1', { setValueAs: transformEmptyToUndefined() })}
                error={fieldError('address_line1')}
              />
              <TextInput
                required={loginType === LoginType.Webshop}
                label={t('signup-house-number', 'House number')}
                {...register('address_line3', { setValueAs: transformEmptyToUndefined() })}
                error={fieldError('address_line3')}
              />
              <TextInput
                required={loginType === LoginType.Webshop}
                label={t('signup-postcode', 'Postcode')}
                {...register('postcode', { setValueAs: transformEmptyToUndefined() })}
                error={fieldError('postcode')}
              />
              <TextInput
                required={loginType === LoginType.Webshop}
                label={t('signup-city', 'City')}
                {...register('city', { setValueAs: transformEmptyToUndefined() })}
                error={fieldError('city')}
              />
            </Stepper>
            <Stepper stepsWithErrors={stepsWithError} totalSteps={3} step={3} expanded={step === 3}>
              <TextInput
                required={true}
                type='password'
                label={t('signup-password1', 'Password')}
                {...register('password1', { setValueAs: transformEmptyToUndefined() })}
                error={fieldError('password1')}
              />
              <TextInput
                required={true}
                type='password'
                label={t('signup-password2', 'Password (again)')}
                {...register('password2', { setValueAs: transformEmptyToUndefined() })}
                error={fieldError('password2')}
              />
              {loginType !== LoginType.Webshop && (
                <>
                  <TextInput
                    label={t('signup-expected-nr-horses', 'Expected number of horses')}
                    {...register('expected_no_horses', { setValueAs: transformEmptyNumber(undefined) })}
                    error={fieldError('expected_no_horses')}
                    hint={t('signup-expected-nr-horses-hint', 'How many horses do you expect to add to your digital stable?')}
                  />
                  {showCoupons && (
                    <TextInput
                      label={t('signup-coupon-code', 'Coupon code')}
                      {...register('coupon_code', { setValueAs: transformEmptyNumber(undefined) })}
                      error={fieldError('coupon_code')}
                    />
                  )}
                  {!showCoupons && (
                    <div
                      onClick={() => {
                        setShowCoupons(true);
                      }}
                      className={'cursor-pointer text-blue-500 text-sm'}
                    >
                      {t('signup-apply-coupon', 'Apply Coupon')}
                    </div>
                  )}
                </>
              )}
              <CheckboxInput
                required={true}
                {...register('agreed_to_terms_and_privacy', { setValueAs: transformValue(false, undefined) })}
                error={fieldError('agreed_to_terms_and_privacy')}
                labelElement={
                  <>
                    {loginType === LoginType.Webshop ? (
                      <span>
                        {t('signup-agree-terms', 'Yes, I agree with the')}{' '}
                        <a href={'https://equinem.com/legal/general-terms/'} target={'_blank'} className={'text-blue-500'} rel='noreferrer'>
                          {t('signup-terms', 'terms and conditions')}
                        </a>
                        {', '}
                        <a href={'https://equinem.com/privacy-policy/'} target={'_blank'} className={'text-blue-500'} rel='noreferrer'>
                          {t('signup-terms-privacy', 'privacy policy')}
                        </a>{' '}
                        {t('signup-agree-terms-and', 'and the')}{' '}
                        <a
                          href={'https://equinem.com/legal/purchasing-from-other-sellers/'}
                          target={'_blank'}
                          className={'text-blue-500'}
                          rel='noreferrer'
                        >
                          {t('signup-terms-purchasing', 'terms for purchasing from other sellers')}
                        </a>
                        {'.'}
                      </span>
                    ) : (
                      <span>
                        {t('signup-agree-terms', 'Yes, I agree with the')}{' '}
                        <a href={'https://equinem.com/legal/general-terms/'} target={'_blank'} className={'text-blue-500'} rel='noreferrer'>
                          {t('signup-terms', 'terms and conditions')}
                        </a>{' '}
                        {t('signup-agree-terms-and', 'and the')}{' '}
                        <a href={'https://equinem.com/privacy-policy/'} target={'_blank'} className={'text-blue-500'} rel='noreferrer'>
                          {t('signup-terms-privacy', 'privacy policy')}
                        </a>
                        {'.'}
                      </span>
                    )}
                  </>
                }
              />
            </Stepper>
          </form>
          <div className={'mt-8 flex flex-row justify-end gap-2 w-full'}>
            {hasPreviousStep && (
              <Button variant={ButtonVariant.Default} onClick={previousStep}>
                {t('signup-previous-step', 'Previous step')}
              </Button>
            )}
            {hasNextStep && (
              <Button variant={ButtonVariant.Primary} onClick={nextStep}>
                {t('signup-next-step', 'Next step')}
              </Button>
            )}
            {atLastStep && (
              <Button variant={ButtonVariant.Primary} type='submit' form='signup-form' loading={submitting}>
                {t('signup-submit', 'Start Now')}
              </Button>
            )}
          </div>
        </>
      )}
      <ErrorSection className='my-4' errors={nonFieldErrors} />
    </SplashWrapper>
  );
}

import React, { ReactNode } from 'react';

interface TileListProps {
  children: ReactNode;
  className?: string;
}

/**
 * A wrapper component for the TileRow that generate the <ul> element
 */
function TileList({ children, className }: TileListProps): JSX.Element {
  return <ul className={className}>{children}</ul>;
}

export default TileList;

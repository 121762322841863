import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import OptimizedImage from 'ui/OptimizedImage';
import { useAccount } from '../../../context/AccountContext';
import { AvatarInitials, AvatarSize } from '../../../ui/Avatar';
import PingAnimation from 'ui/Animations/PingAnimation';
import useUserInvitations from 'hooks/UseUserInvitations';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';

interface Props {
  menuOpened: boolean;
  profileMenuPath: string;
  setOpen: (open: boolean) => void;
}

export default function UserProfile({ menuOpened, profileMenuPath, setOpen }: Props): JSX.Element {
  const { accountDetails } = useAccount();
  const navigate = useNavigate();
  const { invitations } = useUserInvitations();
  const { width } = useScreenSize();

  const avatarChars = useMemo(() => {
    let chars = '';
    if (!accountDetails) {
      return chars;
    }
    if (accountDetails.first_name && accountDetails.first_name.length > 0) {
      chars += accountDetails.first_name[0];
    }
    if (accountDetails.last_name && accountDetails.last_name.length > 0) {
      chars += accountDetails.last_name[0];
    }
    return chars;
  }, [accountDetails]);

  return (
    <div className='border-t py-2'>
      <div
        className={classNames(
          'relative items-center flex justify-between h-[48px] duration-200 transition-all text-primary pl-5 py-0.5 cursor-pointer hover:bg-neutral-200 mx-1 rounded',
          {
            'space-x-2': menuOpened,
          },
        )}
        onClick={() => {
          if (width < ScreenSize.md) {
            setOpen(false);
          }
          navigate(profileMenuPath);
        }}
      >
        {accountDetails?.avatar_file ? (
          <div className='w-10 h-10 rounded-full overflow-hidden'>
            <OptimizedImage className='w-full h-full' src={accountDetails.avatar_file} width={128} />
          </div>
        ) : (
          <AvatarInitials initials={avatarChars} size={AvatarSize.Large} color={'#22243F'} />
        )}
        {menuOpened && (
          <div className={classNames('flex-1 text-sm')}>
            {accountDetails?.first_name} {accountDetails?.last_name}
          </div>
        )}
        {invitations && invitations.length > 0 && <PingAnimation className='right-3' />}
      </div>
    </div>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-animate {
  background-size: 400%;
  -webkit-animation: BackgroundGradientAnimation 3s ease infinite;
  -moz-animation: BackgroundGradientAnimation 3s ease infinite;
  animation: BackgroundGradientAnimation 3s ease infinite;
}

@keyframes BackgroundGradientAnimation {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/animations.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,+DAA+D;EAC/D,4DAA4D;EAC5D,uDAAuD;AACzD;;AAEA;EACE;;IAEE,2BAA2B;EAC7B;EACA;IACE,6BAA6B;EAC/B;AACF","sourcesContent":[".background-animate {\n  background-size: 400%;\n  -webkit-animation: BackgroundGradientAnimation 3s ease infinite;\n  -moz-animation: BackgroundGradientAnimation 3s ease infinite;\n  animation: BackgroundGradientAnimation 3s ease infinite;\n}\n\n@keyframes BackgroundGradientAnimation {\n  0%,\n  100% {\n    background-position: 0% 50%;\n  }\n  50% {\n    background-position: 100% 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

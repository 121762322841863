import { DotsThreeVertical, Plus } from '@phosphor-icons/react';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import useHorseDetail from 'hooks/UseHorseDetail';
import { CancelablePromise, HorseOwner, HorseownersService, ModulePermissionsEnum, PaginatedHorseOwnerList } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { Tile } from 'ui/Layout/Tile';
import useModal from 'ui/Modals/UseModal';
import { contactName } from 'utilities/Contact';
import SaveHorseOwnerModal from './SaveHorseOwnerModal';
import { table, tableHiddenHeaderMd, tableTbody, tableTbodyTrNoClick, tableThead, tableTheadTd } from 'ui/Const';
import DropdownMenu from 'ui/DropdownMenu';
import DeleteHorseOwnerModal from './DeleteHorseOwnerModal';
import ApiErrorParser from 'api/ApiErrorParser';
import usePermissions from 'hooks/UsePermissions';

function OwnershipTile(): JSX.Element {
  const [horseOwners, setHorseOwners] = useState<HorseOwner[]>();
  const [horseOwnerApiError, setHorseOwnerApiError] = useState<ApiErrorParser<HorseOwner> | undefined>();
  const [selectedHorseOwner, setSelectedHorseOwner] = useState<HorseOwner>();

  const { horse, contacts } = useHorseDetail();
  const { t } = useTranslation();
  const { showModal: showSaveModal, closeModal: closeSaveModal, modalIsVisible: saveModalIsVisible } = useModal();
  const { showModal: showDeleteModal, closeModal: closeDeleteModal, modalIsVisible: deleteModalIsVisible } = useModal();
  const { selectedOrganization } = useOrganization();
  const { formatDate, formatNumber } = useAccount();
  const { hasPermission } = usePermissions();

  /**
   * Load the horse from api
   */
  const loadOwnership = useCallback((selectedOrganizationUid: string, horseUid: string): CancelablePromise<PaginatedHorseOwnerList> => {
    const promise = HorseownersService.horseownersList({
      horseOrganisationUid: selectedOrganizationUid,
      horseUid,
    });
    promise
      .then(res => setHorseOwners(res.results))
      .catch(error => {
        if (!promise.isCancelled) {
          setHorseOwnerApiError(new ApiErrorParser<PaginatedHorseOwnerList>(error));
        }
      });

    return promise;
  }, []);

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo(() => {
    return [
      {
        onClick: showSaveModal,
        text: t('add', 'Add'),
        buttonVariant: ButtonVariant.Default,
        icon: <Plus />,
      },
    ];
  }, [showSaveModal, t]);

  /**
   * Get the correct name based on the contact UID
   */
  const getContactName = (ownerUid: string) => {
    const contact = contacts?.find(contact => contact.uid === ownerUid);
    return contact ? contactName(contact) : ownerUid;
  };

  /**
   * Load the ownership from the api
   */
  useEffect(() => {
    if (selectedOrganization && horse) {
      const promise = loadOwnership(selectedOrganization.uid, horse.uid);
      return () => promise.cancel();
    }
  }, [horse, loadOwnership, selectedOrganization]);

  // permission check
  if (!hasPermission(ModulePermissionsEnum.VIEW_HORSE_OWNERS)) {
    return <></>;
  }

  return (
    <Tile title={t('ownership', 'Ownership')} loading={horse === undefined} actions={tileActions} apiError={horseOwnerApiError}>
      {horseOwners && horseOwners.length === 0 ? (
        <p className='text-gray-500 italic'>{t('no-horse-ownerships', 'There are currently no horse owners.')}</p>
      ) : (
        <div className='mx-3 md:mx-0'>
          <table className={table}>
            <thead className={tableThead}>
              <tr className={tableHiddenHeaderMd}>
                <td className={tableTheadTd}>{t('contact', 'Contact')}</td>
                <td className={tableTheadTd}>{t('percentage', 'Percentage')}</td>
                <td className={tableTheadTd}>{t('since', 'Since')}</td>
                <td />
              </tr>
            </thead>
            <tbody className={tableTbody}>
              {horseOwners?.map(owner => (
                <tr className={tableTbodyTrNoClick} key={owner.uid}>
                  <td>{getContactName(owner.contact_uid)}</td>
                  <td>{formatNumber(Number(owner.percentage))}%</td>
                  <td>{owner.owner_since ? formatDate(owner.owner_since) : '-'}</td>
                  <td align='right'>
                    <DropdownMenu
                      menuItems={[
                        [
                          {
                            element: t('edit', 'Edit'),
                            onClick: () => {
                              setSelectedHorseOwner(owner);
                              showSaveModal();
                            },
                          },
                        ],
                        [
                          {
                            element: t('remove', 'Remove'),
                            className: 'text-red-600',
                            onClick: () => {
                              setSelectedHorseOwner(owner);
                              showDeleteModal();
                            },
                          },
                        ],
                      ]}
                    >
                      <button className='px-2'>
                        <DotsThreeVertical size={20} weight='bold' />
                      </button>
                    </DropdownMenu>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {horse && selectedOrganization && (
        <>
          <SaveHorseOwnerModal
            isVisibile={saveModalIsVisible}
            onSaved={horseOwner => {
              // update the state asap to show the new owner
              setHorseOwners(prevState => {
                if (selectedHorseOwner) {
                  return (prevState ?? []).map(owner => (owner.uid === selectedHorseOwner.uid ? selectedHorseOwner : owner));
                } else {
                  return [...(prevState ?? []), horseOwner];
                }
              });
              // in the background reload the data from the api
              loadOwnership(selectedOrganization.uid, horse.uid);
            }}
            horseOwner={selectedHorseOwner}
            horse={horse}
            onRequestClose={() => {
              closeSaveModal();
              setSelectedHorseOwner(undefined);
            }}
          />
          {selectedHorseOwner && (
            <DeleteHorseOwnerModal
              isVisible={deleteModalIsVisible}
              horseOwner={selectedHorseOwner}
              onRequestClose={() => {
                closeDeleteModal();
                setSelectedHorseOwner(undefined);
              }}
              onDeleted={() => {
                // update the state asap to remove owner
                setHorseOwners(prevState => (prevState ?? []).filter(owner => owner.uid !== selectedHorseOwner?.uid));
                // in the background reload the data from the api
                loadOwnership(selectedOrganization.uid, horse.uid);
              }}
            />
          )}
        </>
      )}
    </Tile>
  );
}

export default OwnershipTile;

import { Category, InvoiceDetail, InvoicesService, NestedInvoiceItem, PSTypeEnum, VatCategoryEnum, VATPercentage } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'context/OrganizationContext';
import Button, { ButtonVariant } from 'ui/Button';
import { TextAreaInput, TextInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { transformEmptyToUndefined } from 'utilities/zod';
import { schemas } from 'openapi/zod-schemas';
import { CaretDown, CaretUp, Plus, Trash } from '@phosphor-icons/react';
import { useAccount } from 'context/AccountContext';
import SelectInput, { OptionItemInterface } from 'ui/Inputs/SelectInput';
import ApiErrorParser from 'api/ApiErrorParser';
import { ErrorSection } from 'ui/Error';
import { RadioButtonGroupOption } from 'ui/Inputs/RadioGroupInput';

interface Props {
  invoice?: InvoiceDetail;
  vatPercentages?: VATPercentage[];
  financialCategories?: Category[];
  open: boolean;

  // The invoice is the created or updated invoice
  onRequestClose: (reload: boolean, invoice?: InvoiceDetail) => void;
}

// Add a calculated total field. This way we can show a 'total' column.
const calculatedTotal = z.object({ total: z.number().optional() });
const itemSchema = schemas.NestedInvoiceItem.omit({
  vat_percentage: true,
  last_modified_on: true,
  created_on: true,
  horse: true,
}).merge(calculatedTotal);
const schema = z.object({
  items: z.array(itemSchema),
});

export type InvoiceItems = z.infer<typeof schema>;
export type InvoiceItem = z.infer<typeof itemSchema>;

// Generate the default values. Add one empty line if we don't have any invoice items.
const defaults = (invoice: InvoiceDetail): InvoiceItems => {
  const items: InvoiceItem[] | undefined = invoice.items?.map(item => {
    const total = Number(item.quantity) * Number(item.unit_price);
    return { ...item, total: total };
  });
  if (!items || items.length === 0) {
    return {
      items: [
        {
          description: '',
          quantity: '1',
          unit_price: '0',
          unit_price_currency: invoice.currency,
          category: '',
          total_vat: { amount: '0', currency: '' },
        },
      ],
    };
  }
  return { items };
};

function EditInvoiceItems({ invoice, open, vatPercentages, financialCategories, onRequestClose }: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganization, selectedOrganizationDetails } = useOrganization();
  const { accountDetails, formatNumber, formatMoney } = useAccount();
  const [apiError, setApiError] = useState<ApiErrorParser<InvoiceDetail>>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm<InvoiceItems>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: invoice ? defaults(invoice) : undefined,
  });

  // Use a fieldArray for the invoice items.
  const { fields, append, remove, move } = useFieldArray({
    name: 'items',
    control,
  });

  /**
   * Creates a list of items to show for the vat percentages.
   *
   * This should be a function as we should check if the vat percentage is active or not.
   * For an existing item it could be that the vat percentage is not active anymore. But we should still show it.
   */
  const getVatPercentageOptions = useCallback(
    (vatUid?: string | null | undefined): OptionItemInterface[] => {
      const name = (vatPercentage: VATPercentage): string => {
        let text = `${formatNumber(Number(vatPercentage.percentage))}% `;
        switch (vatPercentage.vat_category) {
          case VatCategoryEnum.AE:
            text += t('vat-reverse-charge', 'Reverse-charge');
            break;
          case VatCategoryEnum.G:
            text += t('vat-export', 'Export');
            break;
          default:
            text += t('vat', 'VAT');
            break;
        }
        if (vatPercentage.country) {
          text += ` ${vatPercentage.country}`;
        }
        return text;
      };

      // TODO: Filter out percentages from countries we don't need.
      return (
        vatPercentages
          ?.filter(percentage => percentage.is_active || percentage.uid === vatUid)
          .map(percentage => {
            return { id: percentage.uid, name: name(percentage) };
          }) ?? []
      );
    },
    [vatPercentages, formatNumber, t],
  );

  // List of sales categories to choose from
  const financialCategoriesOptions = useMemo((): RadioButtonGroupOption[] => {
    if (!financialCategories) {
      return [];
    }
    return financialCategories
      .filter(cat => cat.p_s_type === PSTypeEnum.SALE)
      .map(cat => {
        return {
          id: cat.uid,
          name: cat.name,
        };
      });
  }, [financialCategories]);

  // Update the 'total' field of a row when the price or amount is changed.
  useEffect(() => {
    const { unsubscribe } = watch((value, info) => {
      if (info.name?.startsWith('items') && (info.name?.endsWith('unit_price') || info.name?.endsWith('quantity'))) {
        const index = parseInt(info.name.split('.')[1]);
        const price = Number(value.items?.at(index)?.unit_price);
        const quantity = Number(value.items?.at(index)?.quantity);
        if (price && quantity) setValue(`items.${index}.total`, price * quantity);
      }
    });
    return () => unsubscribe();
  }, [watch, setValue]);

  const onSubmit = async (data: InvoiceItems) => {
    if (!selectedOrganization) {
      console.error('Cannot save invoice without a selected organization');
      return;
    }
    if (!invoice) {
      console.error('Invoice is not set when saving invoice items');
      return;
    }

    try {
      const updatedInvoice = await InvoicesService.invoicesPartialUpdate({
        organisationUid: selectedOrganization.uid,
        uid: invoice?.uid,
        requestBody: {
          items: data.items as NestedInvoiceItem[],
        },
      });
      setApiError(undefined);
      onRequestClose(true, updatedInvoice);
    } catch (e) {
      setApiError(new ApiErrorParser<InvoiceDetail>(e));
    }
  };

  // Reset the form to its defaults when the dialog gets opened.
  useEffect(() => {
    if (open) {
      reset(invoice ? defaults(invoice) : undefined);
    }
  }, [open, reset]); //eslint-disable-line

  return (
    <PageModal
      open={open}
      parentElement='form'
      onClose={() => setApiError(undefined)}
      parentProps={{ id: 'editInvoiceItems', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
    >
      <PageModalTitle title={t('invoice-items', 'Invoice items')} onClose={() => onRequestClose(false)} />
      <PageModalContent>
        <div className='flex flex-col gap-4'>
          <ErrorSection errors={apiError} />
          <div className='flex flex-col'>
            <div className='gap-2 border-b pb-1 hidden md:flex'>
              <div className='w-8' />
              <div className='w-16'>{t('amount', 'Amount')}</div>
              <div className='w-32 grow'>{t('description', 'Description')}</div>
              <div className='w-32'>{t('price', 'Price')}</div>
              <div className='w-24 text-right'>{t('total', 'Total')}</div>
              <div className='w-36' />
              <div className='w-8' />
            </div>
            {fields.map((field, index) => {
              return (
                <div className='flex border-b gap-2 py-4' key={field.id}>
                  <div className='w-8 flex flex-col gap-1 justify-start'>
                    <button
                      type='button'
                      onClick={() => move(index, Math.max(index - 1, 0))}
                      className='h-8 w-8 inline text-center cursor-pointer rounded p-0.5 hover:bg-blue-700 hover:text-white focus:ring-0 focus:ring-offset-0'
                    >
                      <CaretUp className='inline' />
                    </button>
                    <button
                      type='button'
                      onClick={() => move(index, Math.min(index + 1, fields.length - 1))}
                      className='h-8 w-8 inline text-center cursor-pointer rounded p-0.5 hover:bg-blue-700 hover:text-white focus:ring-0 focus:ring-offset-0'
                    >
                      <CaretDown className='inline' />
                    </button>
                  </div>
                  <div className='flex gap-2 grow flex-row flex-wrap md:flex-row'>
                    <TextInput
                      className='w-16'
                      postText='x'
                      required={false}
                      type='number'
                      lang={accountDetails?.language}
                      step='1.00'
                      error={get(errors, `items.[${index}].quantity`)?.message}
                      {...register(`items.${index}.quantity`, { setValueAs: transformEmptyToUndefined() })}
                    />
                    <TextAreaInput
                      className='w-full md:w-auto md:grow order-3 md:order-none'
                      required={false}
                      error={get(errors, `items.[${index}].description`)?.message}
                      {...register(`items.${index}.description`, { setValueAs: transformEmptyToUndefined() })}
                    />
                    <TextInput
                      className='w-32 grow md:grow-0'
                      required={false}
                      postText={invoice?.currency}
                      error={get(errors, `items.[${index}].unit_price`)?.message}
                      {...register(`items.${index}.unit_price`, { setValueAs: transformEmptyToUndefined() })}
                      type='number'
                      lang={accountDetails?.language}
                      step='0.01'
                    />
                    <div className='w-24 text-right pt-2 hidden md:block'>
                      <Controller
                        name={`items.${index}.total`}
                        control={control}
                        render={({ field }) => (
                          <p>{formatMoney(field.value ?? 0, invoice?.currency ?? selectedOrganizationDetails?.currency ?? 'EUR')}</p>
                        )}
                      />
                    </div>
                    <div className='w-full md:w-36 space-y-2'>
                      <SelectInput
                        nullable={true}
                        options={financialCategoriesOptions}
                        error={get(errors, `items.[${index}].category`)?.message}
                        nullableValue=''
                        {...register(`items.${index}.category`, { setValueAs: transformEmptyToUndefined() })}
                      />
                      <SelectInput
                        nullable={true}
                        options={getVatPercentageOptions(field.vat_percentage_uid)}
                        error={get(errors, `items.[${index}].vat_percentage_uid`)?.message}
                        nullableValue=''
                        {...register(`items.${index}.vat_percentage_uid`, { setValueAs: transformEmptyToUndefined() })}
                      />
                    </div>
                  </div>
                  <div className='w-8'>
                    <button
                      onClick={e => {
                        e.preventDefault();
                        remove(index);
                      }}
                      className='inline text-center cursor-pointer rounded hover:bg-red-700 hover:text-white px-2 py-1.5 focus:ring-0 focus:ring-offset-0'
                    >
                      <Trash className='inline' />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <Button
              icon={<Plus />}
              onClick={e => {
                e.preventDefault();
                append({
                  description: '',
                  quantity: '1',
                  unit_price: '0',
                  unit_price_currency: invoice?.currency ?? selectedOrganizationDetails?.currency ?? 'EUR',
                  category: '',
                  total_vat: { amount: '0', currency: '' },
                });
              }}
            >
              {t('add-row', 'Add row')}
            </Button>
          </div>
        </div>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            variant: ButtonVariant.Primary,
            text: t('save', 'Save'),
            type: 'submit',
            formId: 'editInvoiceItems',
          },
        ]}
      />
    </PageModal>
  );
}
export default EditInvoiceItems;

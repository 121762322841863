export interface Studbook {
  code: string;
  name: string;
}

// This list is generated from openapi/models/StudbookEnum.ts and should be kept in sync
export const studBookMap: Studbook[] = [
  { code: 'ACE', name: 'ACE Studbook - Australian Continental Equestrian Group Inc. (ACE)' },
  { code: 'AHHA', name: 'American Holsteiner Horse Association' },
  { code: 'RHEIN US', name: 'American Rhinelander in the American Hanoverian Society (AHS-RHEIN US)' },
  { code: 'ATA', name: 'American Trakehner Association (ATA)' },
  { code: 'AWR', name: 'American Warmblood Registry & North American Sportpony Registry (AWR)' },
  { code: 'AES', name: 'Anglo European Studbook (AES)' },
  { code: 'AWÖ', name: 'Arbeitsgemeinschaft für Warmblutzucht in Österreich (AWÖ)' },
  { code: 'AAFE', name: 'Asociacion Argentina de Fomento Equino (AAFE)' },
  { code: 'AECCAá', name: 'Asociación Española de Criadores de Caballos Anglo-árabes (AECCAá)' },
  { code: 'PRE', name: 'Asociación Nacional De Criadores De Caballos de Pura Raza Española / National Purebred Spanish Breed' },
  { code: 'CDE', name: 'Asociación Nacional de Criadores del Caballo de Deporte Español (ANCADES)' },
  { code: 'BH', name: 'Associação Brasileira de Criadores do Cavalo de Hipismo (ABCCH)' },
  { code: 'PSH', name: 'Associação Portuguesa de Criadores de Racas Selectas / Portuguese Sport Horse Studbook (APCRS)' },
  { code: 'LUSIT', name: 'Associação Portuguesa De Criadores do Cavalo Puro Sangue Lusitano (APSL)' },
  { code: 'AA', name: 'Association Nationale Anglo-Arabe (ANAA)' },
  { code: 'RHEIN AU', name: 'Australian Rhinlander in the Hanoverian Horse Society of Australia (HHSA-RHEIN AU)' },
  { code: 'AWHA', name: 'Australian Warmblood Horse Association Ltd (AWHA)' },
  { code: 'BWB', name: 'Baltic Horse Breeders Association (BHBA)' },
  { code: 'BWP', name: 'Belgian Warmblood Studbook (BWP)' },
  { code: 'BE/SIES', name: 'Breeders Elite Studbook for Irish & European Sporthorses Limited (BE-SIES)' },
  { code: 'RHEIN GB', name: 'British Rhinelander in the British Hanoverian Horse Society (BHHS-RHEIN GB)' },
  { code: 'BSH', name: 'Bulgarian Sport Horse Association (BSHA)' },
  { code: 'CSHA', name: 'Canadian Sport Horse Association (CSHA)' },
  { code: 'CWHBA', name: 'Canadian Warmblood Horse Breeders Association (CWHBA)' },
  { code: 'CCDM', name: 'Criadores de Caballos Deportivos Mexicanos, A.C. (CCDM)' },
  { code: 'CABSH', name: 'Croatian Association of Breeders of Sport Horses / Hrvatska Udruga Uzgajivaca Sportskih Konja (HUUSK)' },
  { code: 'DWB', name: 'Danish Warmblood Society / Dansk Varmblod (DWB)' },
  { code: 'EBHA', name: 'East Bulgarian Horse Association (EBHA)' },
  { code: 'ESH', name: 'Estonian Sport Horse Breeders Society / Eesti Sporthobuste Kasvatajate Selts (ESHBS)' },
  { code: 'HANN', name: 'Hannoveraner Verband e.V. (HANN)' },
  { code: 'HHSA', name: 'Hanoverian Horse Society of Australia (HHSA)' },
  { code: 'HSH', name: 'Hungarian Sport Horse Studbook / Magyar Sportlótenyésztok Országos Egyesülete (MSLT)' },
  { code: 'ISR-OLD NA', name: 'International Sporthorse Registry Oldenburg Registry North America (ISREG)' },
  { code: 'TB', name: 'International Studbook Committee of Thoroughbreds (ISBC) (Affiliate recognition)' },
  { code: 'ISH', name: 'Irish Sport Horse Studbook (ISH)' },
  { code: 'MASAF', name: "Italian Sport Horse Studbook / Ministero dell'agricoltura, della sovranità alimentare e delle forest" },
  { code: 'KFPS', name: 'Koninklijk Friesch Paarden-Stamboek (KFPS)' },
  { code: 'KWPN', name: 'Koninklijk Warmbloed Paarden Stamboek Nederland / Royal Warmblood Studbook of the Netherlands (KWPN)' },
  { code: 'KWPN NA', name: 'KWPN of North America, Inc. (KWPN NA)' },
  { code: 'DSP (BAVAR)', name: 'Landesverband Bayerischer Pferdezüchter e.V. (DSP BAVAR)' },
  { code: 'LWB', name: 'Latvian Horse Breeding Association / Latvian Warmblood Studbook (LWB)' },
  { code: 'LHBA', name: 'Lithuanian Horse Breeders Association (LHBA)' },
  { code: 'NRPS', name: 'Nederlands Rijpaarden en Pony Stamboek (NRPS)' },
  { code: 'NZHS', name: 'New Zealand Hanoverian Society (NZHS)' },
  { code: 'RHEIN NZ', name: 'New Zealand Rhinelander in the New Zealand Hanoverian Society (NZHS-RHEIN NZ)' },
  { code: 'NZWB', name: 'New Zealand Warmblood Association (NZWA)' },
  { code: 'NAS', name: 'North American Studbook (NAS)' },
  { code: 'NDWB', name: 'Norwegian Danish Warmblood Society (NDWB)' },
  { code: 'NWB', name: 'Norwegian Warmblood Association/Norsk Varmblod (NWB)' },
  { code: 'DSP (BAD-WÜ)', name: 'Pferdezuchtverband Baden-Württemberg e.V. (DSP BAD-WÜ)' },
  { code: 'DSP (BRAND)', name: 'Pferdezuchtverband Brandenburg-Anhalt (DSP BRAND)' },
  { code: 'DSP (ZWEIB)', name: 'Pferdezuchtverband Rheinland-Pfalz-Saar e.V. (DSP ZWEIB)' },
  { code: 'SA', name: 'Pferdezuchtverband Sachsen Anhalt e.V.' },
  { code: 'SACHS', name: 'Pferdezuchtverband Sachsen e.V.' },
  { code: 'DSP (SATHÜ)', name: 'Pferdezuchtverband Sachsen-Thüringen e.V (DSP SATHÜ)' },
  { code: 'RHEIN', name: 'Rheinisches Reitpferd im Hannoveraner Verband (HANN-RHEIN)' },
  { code: 'SAWHS', name: 'South African Warmblood Horse Society (SAWHS)' },
  { code: 'SHBGB', name: 'Sport Horse Breeding of Great Britain (SHBGB)' },
  { code: 'OS', name: 'Springpferdezuchtverband Oldenburg-International e.V (OS)' },
  { code: 'SF', name: 'Stud Book du Cheval Selle Français (SBSF)' },
  { code: 'SCSL', name: 'Stud-book du Cheval de Selle Luxembourgeois (SCSL)' },
  { code: 'SBS', name: 'Stud-book sBs, Le Cheval de Sport Belge (SBS)' },
  { code: 'ZANG', name: 'Stud-book Zangersheide (ZANG)' },
  { code: 'ChS', name: 'Studbook Cheval Suisse (ChS)' },
  { code: 'PZHK (M)', name: 'Studbook for Polish Malopolska Horses - Polish Horse Breeders Association (PZHK M)' },
  { code: 'PZHK (SP)', name: 'Studbook for Polish Sport Horses - Polish Horse Breeders Association (PZHK SP)' },
  { code: 'PZHK (WLKP)', name: 'Studbook for Polish WielkopolskaHorses - Polish Horse Breeders Association (PZHK WLKP)' },
  { code: 'SSW', name: 'Studbook for Slovenian Warmblood Horses (SSW)' },
  { code: 'SLS', name: 'Studbook La Silla (SLS)' },
  { code: 'CZEWB', name: 'Svaz chovatelu ceskéhoteplorevníka / Czech Warmblood Breeders Society (SCHCT)' },
  { code: 'SWB', name: 'Swedish Warmblood Association (SWB)' },
  { code: 'AHS', name: 'The American Hanovarian Society (AHS)' },
  { code: 'BHHS', name: 'The British Hanoverian Horse Society (BHHS)' },
  { code: 'FWB', name: 'The Finnish Horse Breeding Association/Suomen Hippos ry (FWB)' },
  { code: 'ZCHKS', name: 'The Slovak Warmblood Studbook (ZCHKS)' },
  { code: 'TAF', name: 'Trakehner Avlsforbundet i Danmark (TAF)' },
  { code: 'MECKL', name: 'Verband der Pferdezüchter Mecklenburg-Vorpommern e.V. (MECKL)' },
  { code: 'THU', name: 'Verband der Thüringen Pferdezüchter' },
  { code: 'HOLST', name: 'Verband der Züchter des Holsteiner Pferdes e.V. (HOLST)' },
  { code: 'OLDBG', name: 'Verband der Züchter des Oldenburger Pferdes e.V. (OL)' },
  { code: 'TRAK', name: 'Verband der Züchter und Freunde des Ostpreussischen Warmblutpferdes Trakehner Abstammung e.V. (TRAK)' },
  { code: 'HESS', name: 'Verband Hessischer Pferdezüchter e.V.' },
  { code: 'BWBS', name: 'Warmblood Breeders Studbook (WBS-UK)' },
  { code: 'WSI', name: 'Warmblood Studbook of Ireland (WSI)' },
  { code: 'WESTF', name: 'Westfälisches Pferdestammbuch e.V. (WESTF)' },
  { code: 'CH', name: 'Zuchtverband CH-Sportpferde Swiss Warmblood (ZVCH)' },
  { code: 'ZfDP', name: 'Zuchtverband für Deutsche Pferde e.v. (ZfDP)' },
];

/**
 * Get a studBook by code
 *
 * @param code
 * @returns
 */
export function getStudbookByCode(code: string): Studbook | undefined {
  return studBookMap.find(studbook => studbook.code === code);
}

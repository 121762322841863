/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `SPORT` - sport
 * * `BREEDING` - breeding
 * * `CARE` - care
 */
export enum CategoryEnum {
  SPORT = 'SPORT',
  BREEDING = 'BREEDING',
  CARE = 'CARE',
}

import { usePlanning } from 'hooks/UsePlanning';
import React, { CSSProperties, Fragment, ReactNode, useCallback, useMemo, useState } from 'react';
import { Float } from '@headlessui-float/react';
import { ButtonVariant } from 'ui/Button';
import { useTranslation } from 'react-i18next';
import { Check, Horse, PencilSimple, Trash } from '@phosphor-icons/react';
import Badge from 'ui/Badge';
import { AllColors } from 'utilities/colors';
import { ActionModal } from 'ui/Modals';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import TitleBarButtons, { TitleBarActionProps } from 'ui/Modals/TitleBarButtons';
import { ActionButton, ActionProps } from 'ui/Modals/ActionModal';
import { AvatarInitials, AvatarSize } from 'ui/Avatar';
import { contactInitials, contactName } from 'utilities/Contact';
import { ErrorSection } from 'ui/Error';
import ApiErrorParser from 'api/ApiErrorParser';
import { CalendarActivity, CalendarActivityType, SelectedActivityState } from 'utilities/Planning';

export interface Props {
  children: ReactNode;
  activity: CalendarActivity;
  // It could be that an activity is shown more than once in a view (staff view only).
  // Therefor we need to know for which contact we have to show the modal.
  groupByUid?: string;
  style?: CSSProperties | undefined;
}

export interface ContentProps {
  activity: CalendarActivity;
}

/**
 * An inline modal for showing an existing calendar item.
 */
function ActivityModalContent({ activity }: ContentProps): JSX.Element {
  const { t } = useTranslation();
  const { stables } = usePlanning();

  const targetText = useMemo(() => {
    if (activity.stableUid) {
      const stable = stables?.find(stable => stable.uid === activity.stableUid);
      if (stable) {
        return contactName(stable.location);
      } else {
        return t('unknown-stable', 'Unknown stable');
      }
    } else {
      return t('all-staff', 'All staff');
    }
  }, [t, stables, activity]);

  if (activity.type === CalendarActivityType.Activity) {
    return (
      <div className='space-y-3'>
        <div className='grow'>
          <p>
            <span className='font-medium mr-1'>{activity.activityType?.name}</span>
            <span>
              - <Horse className='inline-block mb-1 mr-0.5' />
              {activity.horse?.name}
            </span>
          </p>
          <p className='text-sm'>{activity.startEndText}</p>
          {activity.done && <Badge color={AllColors.Green}>{t('activity-done', 'Done')}</Badge>}
        </div>
        <div>
          {activity.assignedTo.map((assignee, index) => (
            <div key={assignee.contact?.uid ?? index} className='flex flex-row gap-1.5 mt-1.5'>
              <AvatarInitials
                size={AvatarSize.XSmall}
                initials={assignee.contact ? contactInitials(assignee.contact) : '?'}
                uuid={assignee.contact?.uid}
              />
              <div className='-mt-1.5'>
                <p>{assignee.contact ? contactName(assignee.contact) : 'unknown contact'}</p>
                <p className='text-sm -mt-1.5'>{assignee.role?.name}</p>
              </div>
            </div>
          ))}
        </div>
        <p className='line-clamp-6'>{activity.extraInfo}</p>
      </div>
    );
  } else {
    return (
      <div>
        <div className='flex pr-2'>
          <p className='grow text-sm'>{activity.startEndText}</p>
          <Badge>{targetText}</Badge>
        </div>
        {activity.title && <p className='font-medium'>{activity.title}</p>}
        {activity.text && <p className='whitespace-pre-line max-h-36 overflow-y-auto'>{activity.text}</p>}
      </div>
    );
  }
}

export default function ActivityModal({ children, activity, groupByUid, style }: Props): JSX.Element {
  const { t } = useTranslation();
  const { width } = useScreenSize();
  const [isMarkingAsFinished, setIsMarkingAsFinished] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorParser<void>>();
  const { markActivityAsDone, removeActivity, selectedActivity, setSelectedActivity } = usePlanning();

  const title = () => {
    if (selectedActivity) {
      if (selectedActivity.activity.type === CalendarActivityType.Activity) {
        return t('activity', 'Activity');
      } else {
        if (selectedActivity.activity.executable) {
          return t('task', 'Task');
        } else {
          return t('message', 'Message');
        }
      }
    } else {
      return '';
    }
  };

  const markAsFinished = useCallback(
    (done = true) => {
      setIsMarkingAsFinished(true);
      markActivityAsDone(activity, done)
        .catch(e => setApiError(new ApiErrorParser<void>(e)))
        .finally(() => setIsMarkingAsFinished(false));
    },
    [markActivityAsDone, activity],
  );

  const titleBarActions = useMemo((): TitleBarActionProps[] => {
    return [
      {
        text: t('remove', 'Remove'),
        icon: <Trash />,
        onClick: () => removeActivity(activity).catch(e => setApiError(new ApiErrorParser<void>(e))),
      },
      {
        text: t('edit', 'Edit'),
        icon: <PencilSimple />,
        onClick: () => setSelectedActivity({ ...activity }, SelectedActivityState.Edit),
      },
    ];
  }, [t, setSelectedActivity, removeActivity, activity]);

  const modalActions = useMemo((): ActionProps[] => {
    if (!activity.executable) {
      return [];
    }
    if (activity.done) {
      return [
        {
          variant: ButtonVariant.Default,
          text: t('mark-as-undone', 'Mark as undone'),
          onClick: () => markAsFinished(false),
          loading: isMarkingAsFinished,
        },
      ];
    } else {
      return [
        {
          variant: ButtonVariant.Primary,
          text: t('mark-as-done', 'Mark as done'),
          onClick: () => markAsFinished(true),
          loading: isMarkingAsFinished,
          icon: <Check />,
        },
      ];
    }
  }, [t, activity, isMarkingAsFinished, markAsFinished]);

  const showModal = useMemo(() => {
    if (selectedActivity?.activity.uid !== activity.uid) {
      return false;
    }
    // Don't show when we're having the full modal open.
    if (selectedActivity.selectedActivityState !== SelectedActivityState.Info) {
      return false;
    }

    // It could be that an activity is shown more than once in a view (staff view only).
    // Therefor we need to know for which contact we have to show the modal.
    if (selectedActivity.groupByUid && groupByUid) {
      return selectedActivity.groupByUid === groupByUid;
    }
    return true;
  }, [selectedActivity, activity, groupByUid]);

  if (width <= ScreenSize.md) {
    return (
      <div style={style}>
        {children}
        <ActionModal actions={modalActions} open={showModal}>
          <TitleBarButtons
            title={title()}
            actions={titleBarActions}
            onClose={() => {
              if (selectedActivity) {
                setSelectedActivity(selectedActivity.activity, SelectedActivityState.Selected, selectedActivity?.groupByUid);
              }
            }}
          />
          <ErrorSection errors={apiError} />
          <ActivityModalContent activity={activity} />
        </ActionModal>
      </div>
    );
  }

  return (
    <Float
      offset={4}
      show={showModal}
      placement={selectedActivity?.activity.type !== CalendarActivityType.Activity ? 'bottom-start' : 'right-start'}
      flip={true}
      composable
      portal={true}
      as={Fragment}
      enter='transition duration-200 ease-out'
      enter-from='opacity-0 -translate-y-1'
      enter-to='opacity-100 translate-y-0'
      leave='transition ease-in duration-100'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
      onHide={() => setApiError(undefined)}
    >
      <Float.Reference>
        <div style={style}>{children}</div>
      </Float.Reference>
      <Float.Content>
        <div
          className='w-64 rounded-lg bg-white pl-4 pb-4 pt-2 pr-2 shadow-[0_8px_30px_rgb(0,0,0,0.22)]'
          onClick={e => {
            // Don't hide when the modal is clicked
            e.stopPropagation();
          }}
        >
          <TitleBarButtons
            actions={titleBarActions}
            title={title()}
            onClose={() => {
              if (selectedActivity) {
                setSelectedActivity(selectedActivity.activity, SelectedActivityState.Selected, selectedActivity?.groupByUid);
              }
            }}
          />
          <ErrorSection errors={apiError} />
          <ActivityModalContent activity={activity} />
          <div className='pr-2 mt-5 sm:mt-6 gap-4 flex'>
            {modalActions.map((action, index) => (
              <ActionButton className='w-full' key={index + action.text} action={action} />
            ))}
          </div>
        </div>
      </Float.Content>
    </Float>
  );
}
